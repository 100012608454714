export class OfferItem {
  constructor({ id, item, price, offerId, proposalItem, proposalItemInfo, enviroData }) {
    this.id = id
    this.itemId = item
    this.price = price
    this.offerId = offerId
    this.proposalItem = proposalItem
    this.proposalItemInfo = proposalItemInfo
    this.enviroData = enviroData
  }

  static create({ id, item, price, offerId, proposalItem, proposalItemInfo, enviroData }) {
    return new OfferItem({ id, item, price, offerId, proposalItem, proposalItemInfo, enviroData })
  }

  getTotal(measure = 0) {
    return (this.price / 100) * (measure / 1000) || 0
  }
}
