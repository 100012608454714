import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Button, ButtonTabs } from '@proveoeng/uikit/dist/atoms/Button'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Info, WarningOutline } from '@proveoeng/uikit/dist/atoms/Icons'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Grid } from '@proveoeng/uikit/dist/atoms/Layout/Layout'
import { Tooltip } from '@proveoeng/uikit/dist/atoms/Tooltip'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { DataTable } from '@proveoeng/uikit/dist/molecules/DataTable'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal/Modal'

import { Helper } from '../../../../common/components/Helper/Helper'
import { FormatDate } from '../../../../common/mappers/FormatDate'
import { colorState, copyState } from '../../config'

const Items = ({ items }) =>
  items.map((item, i) => (
    <Box paddingBottom={4}>
      <Flex minWidth="264px" key={i}>
        <Text as="span" sizeText="display16" color="black" fontWeight="600" paddingRight={2}>
          {item.title}:
        </Text>
        <Text
          as="span"
          sizeText="display16"
          color="gray"
          style={{
            whiteSpace: 'pre-wrap',
          }}>
          {item.children || '-'}
        </Text>
      </Flex>
    </Box>
  ))

const TooltipWrapper = ({ children, type }) => {
  const [active, setActive] = useState(false)
  return (
    <Tooltip
      active={active}
      setActive={setActive}
      bgColor="gray3"
      textColor="black"
      padding={4}
      width="497px"
      content={<Helper type={type} />}>
      {children}
    </Tooltip>
  )
}

export const ModalSellerDetails = ({
  seller,
  isOpen,
  setIsOpened,
  title = 'Detalles del proveedor',
  detailsTabOpen = 'company',
  proposalsBySeller = [],
}) => {
  const [activeTab, setActiveTab] = useState('company')

  useEffect(() => {
    setActiveTab(detailsTabOpen)
  }, [])

  const tabItems = [
    {
      id: 'company',
      children: <>Datos de la compañia</>,
      isActive: activeTab === 'company',
      action: () => setActiveTab('company'),
    },
    {
      id: 'activity',
      children: <>Perfil y actividad</>,
      isActive: activeTab === 'activity',
      action: () => setActiveTab('activity'),
    },
    {
      id: 'contact',
      children: <>Contacto</>,
      isActive: activeTab === 'contact',
      action: () => setActiveTab('contact'),
    },
    {
      id: 'proposals',
      children: <>Procesos de compra</>,
      isActive: activeTab === 'proposals',
      action: () => setActiveTab('proposals'),
    },
  ]

  const companyDataItems = [
    {
      title: 'Nombre',
      children: seller.companyData?.name,
    },
    {
      title: 'NIF',
      children: seller.companyData?.taxCode,
    },
    {
      title: 'Dirección',
      children: seller.companyData?.address,
    },
    {
      title: 'Número de teléfono',
      children: seller.companyData?.phone,
    },
    {
      title: 'Dirección de correo',
      children: seller.companyData?.email,
    },
  ]

  const activityDataItems = [
    {
      title: 'Descripción',
      children: seller.activityData?.description,
    },
    {
      title: 'Categorías',
      children: seller.activityData?.categories,
    },
    {
      title: 'Año fundación',
      children: seller.activityData?.since,
    },
    {
      title: 'Facturación',
      children: seller.activityData?.turnover,
    },
    {
      title: 'Empleados',
      children: seller.activityData?.staff,
    },
    {
      title: 'Ámbito de acción',
      children: seller.activityData?.scope,
    },
  ]

  const contactDataItems = [
    {
      title: 'Nombre de contacto',
      children: seller.contactData?.contact,
    },
    {
      title: 'Cargo',
      children: seller.contactData?.position,
    },
    {
      title: 'Dirección de correo',
      /* eslint-disable-next-line */
      children: !seller?.contactData?.contact ? (
        '-'
      ) : seller?.contactData?.email?.includes('notienecorreo') ||
        seller?.contactData?.email?.includes('@conkau.io') ? (
        <TooltipWrapper type="no-mail">
          <Flex justifyContent="center" alignItems="center">
            <Icon sizeIcon="display24" color="gray1">
              <Info />
            </Icon>
            <Text as="span" sizeText="display16">
              No disponible
            </Text>
          </Flex>
        </TooltipWrapper>
      ) : (
        <Text as="span" sizeText="display16">
          {seller.contactData?.email}
        </Text>
      ),
    },
    {
      title: 'Número de teléfono',
      children: seller.contactData?.phone,
    },
  ]

  const proposalsColums = [
    {
      name: 'Obra',
      selector: (row) => row.projectName,
      sortable: true,
      cell: (row) => (
        <Text as="span" sizeText="display14" fontWeight="medium" style={{ cursor: 'pointer' }}>
          <Link
            target="_blank"
            to={`/constructora/tus-obras/${row.projectId}`}
            style={{ color: 'inherit' }}>
            {row.projectName}
          </Link>
        </Text>
      ),
    },
    {
      name: 'Propuesta',
      selector: (row) => row.bidPackageName,
      cell: (row) => (
        <Text as="span" sizeText="display14" fontWeight="medium" style={{ cursor: 'pointer' }}>
          <Link
            target="_blank"
            to={`/constructora/tus-obras/${row.projectId}/${row.bidPackageId}`}
            style={{ color: 'inherit' }}>
            {row.bidPackageName}
          </Link>
        </Text>
      ),
      sortable: true,
    },
    {
      name: 'Provincia',
      selector: (row) => row.projectProvince,
      sortable: true,
    },
    {
      name: 'Estado',
      selector: (row) => copyState[row.state],
      cell: (row) => (
        <>
          <Flex borderRadius="20px" bg={colorState[row.state]} padding={2}>
            <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="medium">
              {copyState[row.state]}
            </Text>
          </Flex>
        </>
      ),
      sortable: true,
    },
    {
      name: 'Última actualización',
      selector: (row) => row.updatedAt,
      cell: (row) => (row.updatedAt ? FormatDate.hydrate(row.updatedAt).readableReverseDate : '-'),
      sortable: true,
    },
  ]

  return (
    <Modal
      isOpen={isOpen}
      id="seller-details-modal"
      onModalClose={() => setIsOpened(false)}
      title={title}
      closeWithButton
      width="75vw"
      maxWidth="1024px">
      <Modal.Content>
        <Flex paddingY={4}>
          <ButtonTabs items={tabItems} />
        </Flex>
        {activeTab === 'company' && <Items items={companyDataItems} />}
        {activeTab === 'activity' && <Items items={activityDataItems} />}
        {activeTab === 'contact' && (
          <>
            {!seller?.contactData?.contact && (
              <Flex alignItems="center" paddingBottom={5}>
                <Icon sizeIcon="display24" color="gray1">
                  <WarningOutline />
                </Icon>
                <Text as="span" sizeText="display16" color="black">
                  Esta empresa no tiene ningún contacto asociado.
                </Text>
              </Flex>
            )}
            <Items items={contactDataItems} />
            {!seller?.contactData?.contact && (
              <Flex
                alignItems="center"
                paddingBottom={5}
                backgroundColor="gray4"
                width="100%"
                borderRadius="8px"
                borderColor="gray3"
                flexDirection="column"
                padding={4}
                marginTop={2}>
                <Flex justifyContent="flex-start" width="100%" paddingBottom={2}>
                  <Text sizeText="display16" fontWeight="600">
                    ¿Cómo puedo añadir un contacto?
                  </Text>
                </Flex>
                <Flex justifyContent="flex-start" width="100%">
                  <Text sizeText="display16" color="black">
                    Envía un correo a{' '}
                    <a href="mailto:soporte@conkau.io">
                      <Text as="span" color="blue1" sizeText="display16">
                        soporte@conkau.io
                      </Text>
                    </a>{' '}
                    con los datos que quieras actualizar y nos pondremos en contacto contigo cuando
                    la información esté actualizada.
                  </Text>
                </Flex>
              </Flex>
            )}
          </>
        )}
        {activeTab === 'proposals' && (
          <DataTable
            columns={proposalsColums}
            data={proposalsBySeller.data}
            pagination={proposalsBySeller.data.length > 10}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Grid gridTemplateColumns="184px" gridTemplateRows="1fr">
            <Button
              colorType="transparent"
              fullWidth
              width="184px"
              action={() => setIsOpened(false)}>
              Hecho
            </Button>
          </Grid>
        </Flex>
      </Modal.Actions>
    </Modal>
  )
}
