import { Accordion } from '@proveoeng/uikit/dist/atoms/Accordion'
import { Box, Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import styled from 'styled-components'

import { parseToString } from '../../../../../lib/utils/Format'
import { getParentNames, getTotalECo2, getTotalMeasurement } from './utils'

const BoxScrollStyled = styled(Box)`
  overflow-x: auto;
  max-height: 450px;
`

const Item = ({ code, measurement, unit, summary, description, eCo2 }) => (
  <Flex width="100%" flexDirection="column" position="relative" marginBottom={2}>
    <Grid
      gridTemplateAreas="'summary unit measurement ceco2'"
      gridTemplateColumns="1fr 120px 120px 120px">
      <GridItem gridArea="summary">
        <Box width="100%">
          <Flex>
            {code && (
              <Text as="h3" color="black" sizeText="display14" marginBottom={2}>
                {code}
              </Text>
            )}
            <Box paddingX={2}>
              <Text as="h3" color="black" sizeText="display14" marginBottom={2}>
                {summary}
              </Text>
              <Text
                color="gray1"
                sizeText="display12"
                textAlign="justify"
                style={{
                  whiteSpace: 'pre-wrap',
                }}>
                {description}
              </Text>
              <Text color="gray1" sizeText="display12" textAlign="justify">
                {eCo2}
              </Text>
            </Box>
          </Flex>
        </Box>
      </GridItem>
      <GridItem gridArea="unit">
        <Text sizeText="display12" color="gray1" textAlign="right">
          {unit}
        </Text>
      </GridItem>
      <GridItem gridArea="measurement">
        <Text sizeText="display12" color="gray1" textAlign="right" paddingRight={2}>
          {parseToString(measurement, 3)}
        </Text>
      </GridItem>
      <GridItem gridArea="ceco2">
        <Text sizeText="display12" color="gray1" paddingLeft={5}>
          {eCo2 ? `${parseToString(eCo2, 2)} kg` : '-'}
        </Text>
      </GridItem>
    </Grid>
  </Flex>
)

/**
 *
 * @param {{ data: Record<string, Preview[]> }} props
 * @returns
 */
export const PreviewConfirm = ({ data }) => {
  if (!data) return <></>

  return (
    <>
      <Flex width="100%" flexDirection="column" position="relative">
        <BoxScrollStyled>
          <Grid
            gridTemplateAreas="'summary unit measurement ceco2'"
            gridTemplateColumns="1fr 120px 120px 120px"
            marginBottom={4}
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 9,
            }}>
            <GridItem gridArea="summary" bg="gray4" borderTopLeftRadius={2} paddingY={4} />
            <GridItem gridArea="unit" bg="gray4" borderRightColor="gray3" paddingY={4}>
              <Text
                sizeText="display14"
                color="gray1"
                overflow="hidden"
                fontWeight="medium"
                textAlign="right">
                Unidad
              </Text>
            </GridItem>
            <GridItem
              gridArea="measurement"
              bg="gray4"
              borderRightColor="gray3"
              paddingY={4}
              borderTopRightRadius={2}
              paddingX={2}>
              <Text
                sizeText="display14"
                color="gray1"
                overflow="hidden"
                fontWeight="medium"
                textAlign="right">
                Medición
              </Text>
            </GridItem>
            <GridItem
              gridArea="ceco2"
              bg="gray4"
              borderRightColor="gray3"
              paddingY={4}
              borderTopRightRadius={2}
              paddingX={2}>
              <Text
                sizeText="display14"
                color="gray1"
                overflow="hidden"
                fontWeight="medium"
                paddingX={2}>
                CeCO2 por unidad
              </Text>
            </GridItem>
          </Grid>
          {Object.values(data).map((items) => (
            <Accordion
              isOpened
              customTitle={
                <Item
                  key={items[0].code}
                  unit={items[0].unit}
                  measurement={getTotalMeasurement(items)}
                  summary={items[0].summary}
                  description={items[0].description}
                  code={items[0].code}
                  eCo2={getTotalECo2(items)}
                />
              }>
              {items.map((item) => (
                <Item
                  key={item.code}
                  measurement={item.measurement}
                  unit={item.unit}
                  summary={getParentNames(item.parents)}
                />
              ))}
            </Accordion>
          ))}
        </BoxScrollStyled>
      </Flex>
    </>
  )
}
