import { getParamsSerializer } from '../../../../lib/request'

export const constants = {
  GET_BID_PACKAGE: 'GET_BID_PACKAGE',
  GET_BID_PACKAGE_SUCCESS: 'GET_BID_PACKAGE_SUCCESS',
  CREATE_BID_PACKAGE: 'CREATE_BID_PACKAGE',
  CREATE_BID_PACKAGE_SUCCESS: 'CREATE_BID_PACKAGE_SUCCESS',
  UPDATE_BID_PACKAGE: 'UPDATE_BID_PACKAGE',
  UPDATE_BID_PACKAGE_SUCCESS: 'UPDATE_BID_PACKAGE_SUCCESS',
  DELETE_BID_PACKAGE: 'DELETE_BID_PACKAGE',
  DELETE_BID_PACKAGE_SUCCESS: 'DELETE_BID_PACKAGE_SUCCESS',
  GET_ALL_BID_PACKAGES: 'GET_ALL_BID_PACKAGES',
  GET_ALL_BID_PACKAGES_SUCCESS: 'GET_ALL_BID_PACKAGES_SUCCESS',
  CREATE_ATTACHMENT_BID_PACKAGE: 'CREATE_ATTACHMENT_BID_PACKAGE',
  CREATE_ATTACHMENT_BID_PACKAGE_SUCCESS: 'CREATE_ATTACHMENT_BID_PACKAGE_SUCCESS',
  DELETE_ATTACHMENT_BID_PACKAGE: 'DELETE_ATTACHMENT_BID_PACKAGE',
  DELETE_ATTACHMENT_BID_PACKAGE_SUCCESS: 'DELETE_ATTACHMENT_BID_PACKAGE_SUCCESS',
  CREATE_LINK_BID_PACKAGE: 'CREATE_LINK_BID_PACKAGE',
  CREATE_LINK_BID_PACKAGE_SUCCESS: 'CREATE_LINK_BID_PACKAGE_SUCCESS',
  DELETE_LINK_BID_PACKAGE: 'DELETE_LINK_BID_PACKAGE',
  DELETE_LINK_BID_PACKAGE_SUCCESS: 'DELETE_LINK_BID_PACKAGE_SUCCESS',
  CREATE_ATTACHMENT_PROJECT_OFFER_SUCCESS: 'CREATE_ATTACHMENT_PROJECT_OFFER_SUCCESS',
  CREATE_ATTACHMENT_BID_PACKAGE_OFFER_SUCCESS: 'CREATE_ATTACHMENT_BID_PACKAGE_OFFER_SUCCESS',
  CREATE_ATTACHMENT_BID_PACKAGE_PROPOSAL_SUCCESS: 'CREATE_ATTACHMENT_BID_PACKAGE_PROPOSAL_SUCCESS',
  CREATE_ATTACHMENT_BID_PACKAGE_PROPOSAL_ITEM_SUCCESS:
    'CREATE_ATTACHMENT_BID_PACKAGE_PROPOSAL_ITEM_SUCCESS',
  CREATE_ATTACHMENT_BID_ITEM_OFFER_SUCCESS: 'CREATE_ATTACHMENT_BID_ITEM_OFFER_SUCCESS',
  ACTION_BID_PACKAGE: 'ACTION_BID_PACKAGE',
  ACTION_BID_PACKAGE_SUCCESS: 'ACTION_BID_PACKAGE_SUCCESS',
  SET_STORE_BID_PACKAGE: 'SET_STORE_BID_PACKAGE',
  SET_STORE_BID_PACKAGES: 'SET_STORE_BID_PACKAGES',
  BID_PACKAGE_FAIL: 'BID_PACKAGE_FAIL',
  BID_PACKAGES_FAIL: 'BID_PACKAGES_FAIL',
  GET_BID_ITEM: 'GET_BID_ITEM',
  GET_BID_ITEM_SUCCESS: 'GET_BID_ITEM_SUCCESS',
  CREATE_BID_ITEM: 'CREATE_BID_ITEM',
  CREATE_BID_ITEM_SUCCESS: 'CREATE_BID_ITEM_SUCCESS',
  CLEAR_BID_PACKAGE: 'CLEAR_BID_PACKAGE',
  UPDATE_BID_ITEM: 'UPDATE_BID_ITEM',
  UPDATE_BID_ITEM_SUCCESS: 'UPDATE_BID_ITEM_SUCCESS',
  DELETE_BID_ITEM: 'DELETE_BID_ITEM',
  DELETE_BID_ITEM_SUCCESS: 'DELETE_BID_ITEM_SUCCESS',
  GET_ALL_BID_ITEMS: 'GET_ALL_BID_ITEMS',
  GET_ALL_BID_ITEMS_SUCCESS: 'GET_ALL_BID_ITEMS_SUCCESS',
  CREATE_ATTACHMENT_BID_ITEM: 'CREATE_ATTACHMENT_BID_ITEM',
  CREATE_ATTACHMENT_BID_ITEM_SUCCESS: 'CREATE_ATTACHMENT_BID_ITEM_SUCCESS',
  CREATE_LINK_BID_ITEM: 'CREATE_LINK_BID_ITEM',
  CREATE_LINK_BID_ITEM_SUCCESS: 'CREATE_LINK_BID_ITEM_SUCCESS',
  DELETE_ATTACHMENT_BID_ITEM: 'DELETE_ATTACHMENT_BID_ITEM',
  DELETE_ATTACHMENT_BID_ITEM_SUCCESS: 'DELETE_ATTACHMENT_BID_ITEM_SUCCESS',
  DELETE_LINK_BID_ITEM: 'DELETE_LINK_BID_ITEM',
  DELETE_LINK_BID_ITEM_SUCCESS: 'DELETE_LINK_BID_ITEM_SUCCESS',
  ACTION_BID_ITEM: 'ACTION_BID_ITEM',
  ACTION_BID_ITEM_SUCCESS: 'ACTION_BID_ITEM_SUCCESS',
  ACTION_BID_PACKAGE_OFFER: 'ACTION_BID_PACKAGE_OFFER',
  ACTION_BID_PACKAGE_OFFER_SUCCESS: 'ACTION_BID_PACKAGE_OFFER_SUCCESS',
  SET_STORE_BID_ITEM: 'SET_STORE_BID_ITEM',
  SET_STORE_BID_ITEMS: 'SET_STORE_BID_ITEMS',
  BID_ITEM_FAIL: 'BID_ITEM_FAIL',
  BID_ITEMS_FAIL: 'BID_ITEMS_FAIL',
  GET_BID_PACKAGE_OFFER: 'GET_BID_PACKAGE_OFFER',
  GET_BID_PACKAGE_OFFER_SUCCESS: 'GET_BID_PACKAGE_OFFER_SUCCESS',
  GET_BID_PACKAGE_OFFERS: 'GET_BID_PACKAGE_OFFERS',
  GET_BID_PACKAGE_OFFERS_SUCCESS: 'GET_BID_PACKAGE_OFFERS_SUCCESS',
  GET_BID_PACKAGE_PROPOSAL_LOWEST: 'GET_BID_PACKAGE_PROPOSAL_LOWEST',
  GET_BID_PACKAGE_PROPOSAL_LOWEST_SUCCESS: 'GET_BID_PACKAGE_PROPOSAL_LOWEST_SUCCESS',
  GET_BID_PACKAGE_PROPOSAL_MINIMAL_ENVELOPE: 'GET_BID_PACKAGE_PROPOSAL_MINIMAL_ENVELOPE',
  GET_BID_PACKAGE_PROPOSAL_MINIMAL_ENVELOPE_SUCCESS:
    'GET_BID_PACKAGE_PROPOSAL_MINIMAL_ENVELOPE_SUCCESS',
  GET_BID_PACKAGE_PROPOSALS: 'GET_BID_PACKAGE_PROPOSALS',
  GET_BID_PACKAGE_PROPOSALS_SUCCESS: 'GET_BID_PACKAGE_PROPOSALS_SUCCESS',
  GET_BID_PACKAGE_PROPOSAL: 'GET_BID_PACKAGE_PROPOSAL',
  GET_BID_PACKAGE_PROPOSAL_SUCCESS: 'GET_BID_PACKAGE_PROPOSAL_SUCCESS',
  GET_BID_PACKAGE_PROPOSAL_ITEMS: 'GET_BID_PACKAGE_PROPOSALGET_BID_PACKAGE_PROPOSAL_ITEMS',
  GET_BID_PACKAGE_PROPOSAL_ITEMS_SUCCESS: 'GET_BID_PACKAGE_PROPOSAL_ITEMS_SUCCESS',
  GET_BID_PACKAGE_COMPARATIVE: 'GET_BID_PACKAGE_COMPARATIVE',
  GET_BID_PACKAGE_COMPARATIVE_SUCCESS: 'GET_BID_PACKAGE_COMPARATIVE_SUCCESS',
  RESET_STORE_COMPARATIVES: 'RESET_STORE_COMPARATIVES',
  AWARD_PROPOSAL: 'AWARD_PROPOSAL',
  AWARD_PROPOSAL_SUCCESS: 'AWARD_PROPOSAL_SUCCESS',
}

export const services = {
  getBidPackages: () => ({
    route: `/bid-packages`,
    method: 'get',
  }),
  getBidPackage: (bidPackageId) => ({
    route: `/bid-packages/${bidPackageId}`,
    method: 'get',
  }),
  getBidItem: (bidPackageId, bidItemId) => ({
    route: `/bid-packages/${bidPackageId}/bid-items/${bidItemId}`,
    method: 'get',
  }),
  createBidPackage: (bidPackageId, body) => ({
    route: `/bid-packages/${bidPackageId}`,
    method: 'put',
    body,
  }),
  createBidItem: (bidPackageId, bidItemId, body) => ({
    route: `/bid-packages/${bidPackageId}/bid-items/${bidItemId}`,
    method: 'put',
    body,
  }),
  updateBidPackage: (bidPackageId, body) => ({
    route: `/bid-packages/${bidPackageId}`,
    method: 'post',
    body,
  }),
  updateBidItem: (bidPackageId, bidItemId, body) => ({
    route: `/bid-packages/${bidPackageId}/bid-items/${bidItemId}`,
    method: 'post',
    body,
  }),
  deleteBidPackage: (bidPackageId) => ({
    route: `/bid-packages/${bidPackageId}`,
    method: 'delete',
  }),
  deleteBidItem: (bidPackageId, bidItemId) => ({
    route: `/bid-packages/${bidPackageId}/bid-items/${bidItemId}`,
    method: 'delete',
  }),
  getAll: () => ({
    route: `/bid-packages`,
    method: 'get',
  }),
  getAllItems: (bidPackageId) => ({
    route: `/bid-packages/${bidPackageId}/bid-items`,
    method: 'get',
  }),
  createAttachmentBidPackage: (bidPackageId, fileId, body) => ({
    route: `/bid-packages/${bidPackageId}/files/${fileId}`,
    method: 'post',
    headers: {
      accept: '*/*',
    },
    formData: true,
    body,
  }),
  createAttachmentBidItem: (bidPackageId, bidItemId, fileId, body) => ({
    route: `/bid-packages/${bidPackageId}/bid-items/${bidItemId}/files/${fileId}`,
    method: 'post',
    headers: {
      accept: '*/*',
    },
    formData: true,
    body,
  }),
  createLinkBidPackage: (bidPackageId, linkId, body) => ({
    route: `/bid-packages/${bidPackageId}/links/${linkId}`,
    method: 'post',
    body,
  }),
  createLinkBidItem: (bidPackageId, bidItemId, linkId, body) => ({
    route: `/bid-packages/${bidPackageId}/bid-items/${bidItemId}/links/${linkId}`,
    method: 'post',
    body,
  }),
  actionBidItem: (bidPackageId, bidItemId, body) => ({
    route: `/bid-packages/${bidPackageId}/bid-items/${bidItemId}/action`,
    method: 'post',
    body,
  }),
  deleteAttachmentBidPackage: (bidPackageId, fileId) => ({
    route: `/bid-packages/${bidPackageId}/files/${fileId}`,
    method: 'delete',
  }),
  deleteAttachmentBidItem: (bidPackageId, bidItemId, fileId) => ({
    route: `/bid-packages/${bidPackageId}/bid-items/${bidItemId}/files/${fileId}`,
    method: 'delete',
  }),
  deleteLinkBidPackage: (bidPackageId, linkId) => ({
    route: `/bid-packages/${bidPackageId}/links/${linkId}`,
    method: 'delete',
  }),
  deleteLinkBidItem: (bidPackageId, bidItemId, linkId) => ({
    route: `/bid-packages/${bidPackageId}/bid-items/${bidItemId}/links/${linkId}`,
    method: 'delete',
  }),
  actionBidPackage: (bidPackageId, body) => ({
    route: `/bid-packages/${bidPackageId}/action`,
    method: 'post',
    body,
  }),
  getBidPackageAllOffers: (bidPackageId, params = {}) => ({
    route: `/bid-packages/${bidPackageId}/offers`,
    method: 'get',
    params,
  }),
  getBidPackageOffer: (bidPackageId, offerId) => ({
    route: `/bid-packages/${bidPackageId}/offers/${offerId}`,
    method: 'get',
  }),
  actionBidPackageOffer: (bidPackageId, offerId, body) => ({
    route: `/bid-packages/${bidPackageId}/offers/${offerId}/action`,
    method: 'post',
    body,
  }),
  getBidPackageLowest: (bidPackageId) => ({
    route: `/bid-packages/${bidPackageId}/proposals/lowest`,
    method: 'get',
  }),
  getBidPackageMinimalEnvelope: (bidPackageId) => ({
    route: `/bid-packages/${bidPackageId}/proposals/minenvelope`,
    method: 'get',
  }),
  getBidPackageProposals: (bidPackageId) => ({
    route: `/bid-packages/${bidPackageId}/proposals`,
    method: 'get',
  }),
  getBidPackageProposal: (bidPackageId, proposalId) => ({
    route: `/bid-packages/${bidPackageId}/proposals/${proposalId}`,
    method: 'get',
  }),
  getBidPackageProposalItems: (bidPackageId, proposalId) => ({
    route: `/bid-packages/${bidPackageId}/proposals/${proposalId}/proposal-items`,
    method: 'get',
  }),
  getBidPackageEvents: (bidPackageId, params = {}) => ({
    route: `/bid-packages/${bidPackageId}/events`,
    method: 'get',
    params,
    paramsSerializer: getParamsSerializer,
  }),
  // Enviro
  updateBidItemEnviro: (bidPackageId, bidItemId, body) => ({
    route: `/enviro/bid-packages/${bidPackageId}/bid-items/${bidItemId}`,
    method: 'post',
    body,
  }),
  getBidItemsEnviro: (bidPackageId) => ({
    route: `/enviro/bid-packages/${bidPackageId}/bid-items`,
    method: 'get',
  }),
  getBidItemEnviro: (bidPackageId, bidItemId) => ({
    route: `/enviro/bid-packages/${bidPackageId}/bid-items/${bidItemId}`,
    method: 'get',
  }),
}
