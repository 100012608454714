import { constants } from '../config'
import { constants as constantsBidPackage } from '../../bidPackages/config'

const initialState = {
  proposalUpdated: false,
  done: false,
  loading: false,
  errorRequest: false,
  error: null,
  manageAttachmentProposalUpdated: false,
  manageAttachmentProposalItemsUpdated: false,
  manageLinkProposalUpdated: false,
  manageLinkProposalItemsUpdated: false,
  data: {
    files: [],
    proposalItems: [],
    bidItems: [],
    bidPackage: {
      files: [],
    },
  },
}

export default function setProposalReducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_PROPOSAL:
      return { ...state, loading: true, done: false, errorRequest: false, error: null }

    case constants.GET_PROPOSAL_SUCCESS: {
      return {
        ...state,
        loading: false,
        done: true,
        data: {
          ...state.data,
          ...action.payload,
        },
      }
    }

    case constants.UPDATE_PROPOSAL: {
      return {
        ...state,
        loading: false,
        done: true,
        proposalUpdated: false,
        data: {
          ...state.data,
        },
      }
    }

    case constants.UPDATE_PROPOSAL_SUCCESS: {
      return {
        ...state,
        loading: false,
        done: true,
        proposalUpdated: true,
        data: {
          ...state.data,
        },
      }
    }

    case constants.SET_PROPOSAL_BID_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        done: true,
        data: {
          ...state.data,
          bidPackage: {
            ...state.data.bidPackage,
            ...action.payload,
          },
        },
      }

    case constants.GET_PROPOSAL_ITEMS:
      return { ...state, loading: true, done: false, errorRequest: false, error: null }

    case constants.GET_PROPOSAL_ITEMS_SUCCESS: {
      return {
        ...state,
        loading: false,
        done: true,
        data: {
          ...state.data,
          bidItems: action.payload,
          proposalItems: action.payload,
        },
      }
    }

    case constants.GET_PROPOSAL_ITEM_SUCCESS: {
      return {
        ...state,
        loading: false,
        done: true,
        data: {
          ...state.data,
          proposalItems: [...state.data.proposalItems, action.payload],
        },
      }
    }

    case constants.CREATE_ATTACHMENT_PROPOSAL: {
      return {
        ...state,
        data: {
          ...state.data,
          filesLoading: true,
          filesUpdated: false,
        },
      }
    }

    case constants.CREATE_ATTACHMENT_PROPOSAL_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          files: [...state.data.files, action.payload],
          // files: [...state.data.project?.bidPackage?.files, action.payload],
          filesUpdated: true,
          filesLoading: false,
        },
      }
    }

    case constants.CREATE_ATTACHMENT_PROPOSAL_ITEM_SUCCESS: {
      const itemToUpdate = state?.data?.proposalItems?.findIndex(
        (element) => element?.proposalItemId === action?.payload?.proposalItemId,
      )
      const updatedProposalItem = {
        ...state?.data?.proposalItems[itemToUpdate],
        files: state?.data?.proposalItems[itemToUpdate]?.files
          ? [...state?.data?.proposalItems[itemToUpdate]?.files, action?.payload]
          : [action?.payload],
      }

      const newState = state?.data?.proposalItems
      newState.splice(itemToUpdate, 1, updatedProposalItem)

      return {
        ...state,
        data: {
          ...state.data,
          proposalItems: newState,
        },
      }
    }

    case constants.CREATE_ATTACHMENT_PROPOSAL_BID_PACKAGE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          bidPackage: {
            ...state.data.bidPackage,
            files: [...state.data.bidPackage?.files, action.payload],
            filesUpdated: true,
            filesLoading: false,
          },
        },
      }
    }

    case constants.SET_STORE_PROPOSAL: {
      return {
        ...state,
        data: {
          ...action.payload,
        },
      }
    }

    case constants.RESET_STORE_PROPOSAL: {
      return {
        ...state,
        loading: false,
        done: false,
        errorRequest: false,
        error: null,
        proposalUpdated: false,
        data: {
          files: [],
          proposalItems: [],
          bidItems: [],
          bidPackage: {
            files: [],
          },
        },
      }
    }

    case constants.PROPOSAL_FAIL:
      return { ...state, loading: false, errorRequest: true, error: { ...action.error } }

    case constants.CANCEL_PROPOSAL:
      return {
        ...state,
        proposalUpdated: false,
      }

    case constants.CANCEL_PROPOSAL_SUCCESS:
      return {
        ...state,
        proposalUpdated: true,
      }

    case constants.REQUEST_IMPROVEMENT_PROPOSAL:
      return {
        ...state,
        proposalUpdated: false,
      }

    case constants.REQUEST_IMPROVEMENT_PROPOSAL_SUCCESS:
      return {
        ...state,
        proposalUpdated: true,
      }

    case constantsBidPackage.AWARD_PROPOSAL:
      return {
        ...state,
        proposalUpdated: false,
      }

    case constantsBidPackage.AWARD_PROPOSAL_SUCCESS:
      return {
        ...state,
        proposalUpdated: true,
      }

    case constants.MANAGE_ATTACHEMENT_PROPOSAL_SUCCESS:
      return {
        ...state,
        manageAttachmentProposalUpdated: action.payload,
      }

    case constants.MANAGE_ATTACHEMENT_PROPOSAL_ITEMS_SUCCESS:
      return {
        ...state,
        manageAttachmentProposalItemsUpdated: action.payload,
      }

    case constants.MANAGE_LINK_PROPOSAL_SUCCESS:
      return {
        ...state,
        manageLinkProposalUpdated: action.payload,
      }

    case constants.MANAGE_LINK_PROPOSAL_ITEMS_SUCCESS:
      return {
        ...state,
        manageLinkProposalItemsUpdated: action.payload,
      }

    default:
      return state
  }
}
