import { Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { currencyFormat, parseToString } from '../../../../lib/utils/Format'

export const SummaryTotal = ({ description, data, columns, tableWidthCSS }) => (
  <>
    <Grid
      gridTemplateAreas={columns.area}
      gridTemplateColumns={columns.columns}
      style={{ width: tableWidthCSS }}>
      <GridItem
        gridArea="description"
        bg="white2"
        borderRightColor="gray3"
        borderRightStyle="solid"
        borderRightWidth="1px"
        padding={2}
        paddingTop={4}
        paddingLeft="30px"
        style={{
          position: 'sticky',
          left: 0,
          zIndex: 9,
        }}>
        <Text color="gray1" overflow="hidden" fontWeight="700" sizeText="display16">
          {description}
        </Text>
      </GridItem>

      <GridItem
        gridArea="budgetK"
        bg="white2"
        style={{
          position: 'sticky',
          left: 510,
          zIndex: 9,
        }}
      />
      <GridItem
        gridArea="budget"
        bg="white2"
        borderRightColor="gray3"
        borderRightStyle="solid"
        borderRightWidth="1px"
        padding={2}
        paddingTop={4}
        style={{
          position: 'sticky',
          left: 600,
          zIndex: 9,
        }}>
        <Text
          textAlign="right"
          color="gray1"
          overflow="hidden"
          fontWeight="700"
          sizeText="display12">
          {currencyFormat(data.getTotalBudgetCost())}
        </Text>
      </GridItem>

      <GridItem
        gridArea="expectedCostsK"
        bg="white2"
        style={{
          position: 'sticky',
          left: 730,
          zIndex: 9,
        }}
      />
      <GridItem
        gridArea="expectedCosts"
        bg="white2"
        borderRightColor="gray3"
        borderRightStyle="solid"
        borderRightWidth="1px"
        padding={2}
        paddingTop={4}
        style={{
          position: 'sticky',
          left: 820,
          zIndex: 9,
        }}>
        <Text
          textAlign="right"
          color="gray1"
          overflow="hidden"
          fontWeight="700"
          sizeText="display12">
          {currencyFormat(data.getTotalExpectedCost())}
        </Text>
      </GridItem>
      <GridItem
        gridArea="minEnvelopeK"
        bg="white2"
        style={{
          position: 'sticky',
          left: 950,
          zIndex: 9,
        }}
      />
      <GridItem
        gridArea="minEnvelope"
        bg="white2"
        borderRightColor="gray3"
        borderRightStyle="solid"
        borderRightWidth="1px"
        padding={2}
        paddingTop={4}
        style={{
          position: 'sticky',
          left: 1040,
          zIndex: 9,
        }}>
        <Text
          textAlign="right"
          color="gray1"
          overflow="hidden"
          fontWeight="700"
          sizeText="display12">
          {parseToString(data?.getTotalMinEnvelope())} €
        </Text>
      </GridItem>

      {data.offers.map((offer) => (
        <>
          <GridItem gridArea={`offer-k-${offer.id}`} bg="white" />
          <GridItem
            gridArea={`offer-margin-${offer.id}`}
            bg="white"
            borderRightColor="gray3"
            borderRightStyle="solid"
            borderRightWidth="1px"
            padding={2}
            paddingTop={4}>
            <Text
              textAlign="right"
              color="gray1"
              overflow="hidden"
              fontWeight="700"
              sizeText="display12">
              {currencyFormat(offer?.getTotal(data.sections))}
            </Text>
          </GridItem>
        </>
      ))}
    </Grid>
  </>
)
