import PropTypes from 'prop-types'
import { Grid, GridItem, Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Card } from '@proveoeng/uikit/dist/atoms/Card'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Header } from '../Header/Header'

export const Layout2 = (props) => {
  const { children, history, routesGo, step, onValidate, type } = props

  return (
    <Grid
      gridTemplateColumns="88px 1fr 1fr"
      gridTemplateRows="60px 1fr"
      gridTemplateAreas={
        '"header header header" "sidebar content content" "sidebar content content""sidebar actionBar actionBar"'
      }>
      <GridItem gridArea="header" display="flex">
        <Header type={type} />
      </GridItem>
      <GridItem gridArea="sidebar">
        <Box width="88px" />
      </GridItem>
      <GridItem gridArea="content" minHeight="calc(100vh - 131px)">
        <Card
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          height="100%"
          borderRadius={0}
          borderBottomLeftRadius={0}
          borderBottomRightRadius={0}
          borderTopLeftRadius={2}
          borderTopRightRadius={0}
          borderColor="white">
          {children}
        </Card>
      </GridItem>
      <GridItem gridArea="actionBar" bg="white">
        <Flex
          justifyContent="space-between"
          borderTopStyle="solid"
          borderTopWidth={1}
          borderTopColor="gray3"
          padding={3}>
          <Button colorType="transparent" sizeButton="medium" action={() => history.goBack()}>
            Volver
          </Button>
          <Button
            colorType="transparent"
            sizeButton="medium"
            action={() => {
              onValidate()
            }}>
            {routesGo[step] !== '/perfil' ? 'Siguiente' : 'Finalizar'}
          </Button>
        </Flex>
      </GridItem>
    </Grid>
  )
}

Layout2.propTypes = {
  children: PropTypes.element.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({}).isRequired,
  onValidate: PropTypes.func,
}

Layout2.defaultProps = {
  onValidate: () => true,
}
