import { useFormik } from 'formik'

import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Box, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Radiobox } from '@proveoeng/uikit/dist/atoms/Radiobox'
import { SelectInput } from '@proveoeng/uikit/dist/atoms/Select'
import { Textarea } from '@proveoeng/uikit/dist/atoms/Textarea'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import {
  ActionButton,
  ActionContainerStyled,
  FormContainer,
  InputContainer,
} from '../../styles/ProfileStyles'
import { profileActivitySchema } from '../../../../common/validationSchemes/profileSchemes'
import { SelectAvatar } from '../../../../common/components/selectAvatar/SelectAvatar'

/**
 *
 * @param {object} props
 * @param {ActivityData} props.activityData
 * @param {void} props.handleChangeAvatar
 * @param {void} props.onSubmit
 */
export const ProfileActivity = ({ activityData, avatar, handleChangeAvatar, onSubmit }) => {
  const initialValues = {
    description: activityData.description || '',
    creationYear: activityData.creationYear || '',
    turnover: activityData.turnover || '',
    staff: activityData.staff || '',
    scope: activityData.scope || '',
  }

  const { values, errors, handleBlur, touched, handleChange, setFieldValue, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: profileActivitySchema,
      onSubmit,
    })

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <GridItem gridArea="description" marginBottom={4}>
          <Text sizeText="display18" color="black" fontWeight="bold" marginBottom={2}>
            Perfil y actividad
          </Text>
          <Text sizeText="display14" color="gray">
            Estos datos nos servirán para poder enviarte las ofertas acorde a tu perfil.
          </Text>
        </GridItem>
        <GridItem gridArea="fields">
          <Box>
            <Text sizeText="display16" color="black" fontWeight="600" marginBottom={3}>
              Logo
            </Text>
            <SelectAvatar
              avatar={avatar?.base64}
              handleChangeAvatar={handleChangeAvatar}
              description="Cambiar logo"
            />
          </Box>
          <InputContainer>
            <Box>
              <Field
                label="Descripción"
                required
                sizeText="display16"
                marginBottom={3}
                errorMessage={touched.name && errors.name}>
                <Textarea
                  placeholderMessage="Escribe una descripción"
                  name="description"
                  ariaLabel="description"
                  maxLength="auto"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.description && !!errors.description}
                />
              </Field>
            </Box>
            .
            <Box>
              <Field
                required
                label="Ámbito de acción"
                sizeText="display16"
                marginBottom={3}
                marginTop={5}>
                <Grid gridTemplateColumns="1fr 1fr" gridGap={4}>
                  <Radiobox
                    onChange={(_, value) => setFieldValue('scope', value)}
                    items={[
                      {
                        id: 'Local',
                        name: 'scope',
                        defaultChecked: values.scope === 'Local',
                        isDisabled: false,
                        hasError: false,
                        label: 'Local',
                        defaultValue: 'Local',
                      },
                      {
                        id: 'Regional',
                        name: 'scope',
                        defaultChecked: values.scope === 'Regional',
                        isDisabled: false,
                        hasError: false,
                        label: 'Regional',
                        defaultValue: 'Regional',
                      },
                      {
                        id: 'National',
                        name: 'scope',
                        defaultChecked: values.scope === 'National',
                        isDisabled: false,
                        hasError: false,
                        label: 'Nacional',
                        defaultValue: 'National',
                      },
                      {
                        id: 'International',
                        name: 'scope',
                        defaultChecked: values.scope === 'International',
                        isDisabled: false,
                        hasError: false,
                        label: 'Internacional',
                        defaultValue: 'International',
                      },
                    ]}
                  />
                </Grid>
              </Field>
            </Box>
          </InputContainer>
          <InputContainer>
            <Box>
              <Field
                label="Año de fundación"
                sizeText="display16"
                marginBottom={3}
                marginTop={5}
                errorMessage={touched.creationYear && errors.creationYear}>
                <Input
                  type="text"
                  placeholderMessage="Escribe el año de fundación"
                  ariaLabel="creationYear"
                  name="creationYear"
                  value={values.creationYear}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.creationYear && !!errors.creationYear}
                />
              </Field>
            </Box>
            <Box>
              <Field label="Facturación anual" sizeText="display16" marginBottom={3} marginTop={5}>
                <SelectInput
                  isSearchable
                  name="turnover"
                  id="turnover"
                  onChange={(_, v) => setFieldValue('turnover', v.value)}
                  options={[
                    { value: '0-0.3M', label: '0-0.3K' },
                    { value: '300K-1M', label: '300K-1M' },
                    { value: '1M-5M', label: '1M-5M' },
                    { value: '5M-20M', label: '5M-20M' },
                    { value: '20M-100M', label: '20M-100M' },
                    { value: '>100M', label: '>100M' },
                  ]}
                  placeholderMessage="Facturación anual"
                  ariaLabel="turnover"
                  hasError={touched.position && !!errors.position}
                  defaultValue={{ value: values.turnover, label: values.turnover }}
                />
              </Field>
            </Box>
            <Box>
              <Field
                label="Número de empleados"
                sizeText="display16"
                marginBottom={3}
                marginTop={5}>
                <SelectInput
                  isSearchable
                  name="staff"
                  id="staff"
                  onChange={(_, v) => setFieldValue('staff', v.value)}
                  options={[
                    { value: '<10', label: '<10' },
                    { value: '10-49', label: '10-49' },
                    { value: '50-99', label: '50-99' },
                    { value: '100-250', label: '100-250' },
                    { value: '>250', label: '>250' },
                  ]}
                  placeholderMessage="Número de empleados"
                  ariaLabel="staff"
                  hasError={touched.staff && !!errors.staff}
                  defaultValue={{ value: values.staff, label: values.staff }}
                />
              </Field>
            </Box>
          </InputContainer>
        </GridItem>
      </FormContainer>
      <ActionContainerStyled borderTopColor="gray3" style={{ zIndex: 2 }}>
        <ActionButton type="submit" colorType="orange" sizeButton="medium">
          Guardar
        </ActionButton>
      </ActionContainerStyled>
    </form>
  )
}
