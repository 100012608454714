import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { parseEditorMessage } from '../../../../../lib/utils/parseEditorMessage'

import { useProposal } from '../../../../common/hooks/useProposal'
import { useUtils } from '../../../../common/hooks/useUtils'
import { DetailProposalTemplate } from '../template/DetailProposalTemplate'

const DetailProposalPage = () => {
  const history = useHistory()
  const { hash } = useParams()
  const proposal = useSelector((state) => state?.proposals)
  const [isModalContactOpened, setIsModalContactOpened] = useState(false)
  const [sendModalIsOpen, setSendModalIsOpen] = useState(false)

  const {
    getProposal,
    resetProposal,
    getProposalItems,
    cancelRequest,
    exportBidPackagePrices,
    sendMessage,
  } = useProposal()

  const { setAlert } = useUtils()

  const goToEditProposal = () => {
    resetProposal()
    history.push({
      pathname: `/proveedor/tus-aplicaciones/${proposal?.data?.proposalId}/editar`,
      state: {
        status: 'published',
      },
    })
  }

  const handleExportBidPackagePrices = () =>
    exportBidPackagePrices(proposal?.data?.proposalId, 'Petición de Presupuesto.xlsx')

  useEffect(() => {
    const loadProposal = async () => {
      await getProposal(hash)
      await getProposalItems(hash)
    }
    resetProposal()
    if (hash) loadProposal()

    return () => {
      cancelRequest()
    }
  }, [])

  const handleSendMessage = async ({ message }) => {
    const messageParsed = parseEditorMessage(message)
    if (!messageParsed) {
      setAlert('error', 'El mensaje debe tener al menos 5 caracteres.')
    } else {
      await sendMessage(proposal?.data?.proposalId, {
        message: messageParsed,
        parentMessageId: null,
      })
    }
    setSendModalIsOpen(false)
  }

  return (
    <DetailProposalTemplate
      proposal={proposal?.data}
      goBack={history.goBack}
      isModalContactOpened={isModalContactOpened}
      setIsModalContactOpened={setIsModalContactOpened}
      goToEditProposal={goToEditProposal}
      handleExportBidPackagePrices={handleExportBidPackagePrices}
      sendModalIsOpen={sendModalIsOpen}
      setSendModalIsOpen={setSendModalIsOpen}
      handleSendMessage={handleSendMessage}
    />
  )
}

export default DetailProposalPage
