import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { requestApi } from '../../../lib/request'
import { companyFails } from '../schemes/companies/actions'
import { services } from '../schemes/companies/config'

import { showAlert } from '../states/alerts'
import { showLoading } from '../states/loading'

export const useDashboard = () => {
  const dispatch = useDispatch()

  const [statsUrl, setStatsUrl] = useState('')

  const getDashboard = async (companyId) => {
    if (!companyId) return
    dispatch(showLoading(1))
    try {
      const {
        data: { data },
      } = await requestApi(services.stats(companyId))
      setStatsUrl(data.statsUrl)
    } catch (error) {
      dispatch(companyFails(error))
      dispatch(
        showAlert('error', 1, error?.message || error?.detail || 'Algo ha salido mal.'),
        setTimeout(() => {
          dispatch(showAlert('', -1, ''))
        }, 5000),
      )
    } finally {
      dispatch(showLoading(-1))
    }
  }

  return {
    getDashboard,
    statsUrl,
  }
}
