import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { LoginRoutes, routes as loginRoutes } from './login'
import { RegisterRoutes, routes as registerRoutes } from './register'
import { FinishSignupRoutes, routes as finishSignupRoutes } from './finish'
import { ProfileRoutes, routes as profileRoutes } from './profile'
import { BuyerRoutes, routes as buyerRoutes } from './buyer'
import { SellerRoutes, routes as sellerRoutes } from './seller'
import { PrivateRoute } from '../../pages/auth/components/PrivateRoute'

export const appRoutes = {
  login: loginRoutes('/login'),
  register: registerRoutes('/registro'),
  finish: finishSignupRoutes('/finalizar'),
  profile: profileRoutes('/perfil'),
  buyer: buyerRoutes('/constructora'),
  seller: sellerRoutes('/proveedor'),
}

const getDefaultAuthHomePage = (type) => {
  let defaultAuthHomePage = '/login'
  if (type === 'Buyer') {
    defaultAuthHomePage = appRoutes.buyer.path
  } else if (type === 'Seller') {
    defaultAuthHomePage = appRoutes.seller.path
  }

  return defaultAuthHomePage
}

export const OwnerRoutes = ({ type, auth }) => (
  <Switch>
    <Route
      exact
      path="/"
      render={() => <Redirect to={auth ? getDefaultAuthHomePage(type) : appRoutes.login.path} />}
    />
    <Route path={appRoutes.login.path} component={LoginRoutes} exact />
    <Route path={appRoutes.register.path} component={RegisterRoutes} />
    <Route path={appRoutes.finish.path} component={FinishSignupRoutes} />
    <PrivateRoute path={appRoutes.profile.path} auth={auth}>
      <ProfileRoutes match={{ url: appRoutes.profile.path }} />
    </PrivateRoute>
    <PrivateRoute path={appRoutes.buyer.path} auth={auth}>
      <BuyerRoutes match={{ url: appRoutes.buyer.path }} />
    </PrivateRoute>
    <PrivateRoute path={appRoutes.seller.path} auth={auth}>
      <SellerRoutes match={{ url: appRoutes.seller.path }} />
    </PrivateRoute>
    <Redirect to={appRoutes.login.path} />
  </Switch>
)
