import { useState } from 'react'
import { useFormikContext } from 'formik'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import LinkIcon from '@proveoeng/uikit/dist/atoms/Icons/Link'
import { Checkbox } from '@proveoeng/uikit/dist/atoms/Checkbox'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { SelectInput } from '@proveoeng/uikit/dist/atoms/Select'
import { Textarea } from '@proveoeng/uikit/dist/atoms/Textarea'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Uploader } from '@proveoeng/uikit/dist/atoms/Uploader'
import { InputNumber } from '@proveoeng/uikit/dist/atoms/InputNumber'

import { ModalEditLinks } from './ModalEditLinks'
import { AttachmentList } from '../../../../common/components/attachmentList/Attachment'
import { Links } from '../../../../common/components/links/Links'

import { paymentDaysOptions } from '../../../buyer/config'
import { onImagesLoad } from '../../../../common/functions/data'
import { errorsImages } from '../../common/config'

export const EditProposalConditions = ({ bidPackage }) => {
  const [modalLinksIsOpened, setModalLinksIsOpened] = useState(false)
  const { values, errors, handleBlur, touched, handleChange, setFieldValue, setFieldTouched } =
    useFormikContext()

  const handleModifyDeliveryDate = (value) => {
    setFieldValue('modifyDeliveryDates', value)
    setFieldTouched('start', false)
    setFieldTouched('end', false)
  }

  const handleModifyDeliveryTime = (value) => {
    setFieldValue('modifyDeliveryTime', value)
    setFieldTouched('deliveryTime', false)
  }

  const handleAcceptRetentionConditions = (value) => {
    setFieldValue('acceptRetentionConditions', value)
    setFieldTouched('retentionCriteria', false)
    setFieldTouched('retention', false)
  }

  const handleAcceptPaymentConditions = (value) => {
    setFieldValue('acceptPaymentConditions', value)
    setFieldTouched('paymentDays', false)
    setFieldTouched('paymentConditions', false)
  }

  const handleAddFile = (files) => setFieldValue('files', [...values.files, ...files])

  const handleDeleteFile = (file) => {
    const updateFiles = file?.fileId
      ? values.files?.filter((element) => element?.fileId !== file.fileId)
      : values.files?.filter((element) => element?.name !== file.name)
    setFieldValue('files', updateFiles)
    if (file?.fileId) {
      setFieldValue('filesToDelete', [...values.filesToDelete, file])
    }
  }

  const handleChangeLinks = (linksList, deleteLinksId) => {
    setFieldValue('links', linksList)
    setFieldValue('linksToDelete', deleteLinksId)
  }

  return (
    <>
      <Flex
        flexDirection="column"
        alignItems="baseline"
        paddingX={0}
        margin="0 auto"
        width="100%"
        height="100%">
        <Flex paddingBottom="20px" width="100%">
          <Flex minWidth="264px" maxWidth="264px" paddingLeft={2}>
            <Text as="span" sizeText="display18" color="black" fontWeight="600">
              Descripción
            </Text>
          </Flex>
          <Flex paddingLeft={4}>
            <Text
              as="span"
              sizeText="display14"
              color="black"
              style={{
                whiteSpace: 'pre-wrap',
              }}>
              {bidPackage?.description || '-'}
            </Text>
          </Flex>
        </Flex>
        <Flex paddingBottom="20px" width="100%">
          <Flex flexDirection="column">
            <Flex minWidth="264px" maxWidth="264px" paddingLeft={2} flexDirection="column">
              <Text as="span" sizeText="display18" color="black" fontWeight="600" paddingBottom={2}>
                Fechas y plazo de entrega
              </Text>
              <Text as="span" sizeText="display14" color="gray1">
                Fechas indicadas por el contratista para el inicio y fin de entrega del material o
                servicio.
              </Text>
            </Flex>
          </Flex>
          <Flex paddingLeft={4} flexDirection="column" width="100%">
            <Flex paddingBottom={4}>
              <Text as="span" sizeText="display14" color="black">
                {bidPackage?.start?.readableReverseDate || 'No informada'} -{' '}
                {bidPackage?.end?.readableReverseDate || 'No informada'}
              </Text>
            </Flex>
            <Flex flexDirection="column">
              <Flex paddingBottom={4} flexDirection="column">
                <Checkbox
                  ariaLabel="modifyDeliveryDates"
                  action={(_, value) => handleModifyDeliveryDate(value)}
                  defaultChecked={values.modifyDeliveryDates}
                  name="modifyDeliveryDates"
                  hasError={touched.modifyDeliveryDates && !!errors.modifyDeliveryDates}
                  label="Modificar fechas de entrega"
                  fontWeight="medium"
                />
                {values.modifyDeliveryDates && (
                  <Flex>
                    <Flex flexDirection="column" paddingRight={5}>
                      <Field
                        label="Fecha inicio de entrega"
                        color="gray"
                        sizeText="display14"
                        marginBottom={1}
                        fontWeight="medium"
                        errorMessage={touched.start && errors.start}>
                        <Box width="184px">
                          <Input
                            type="date"
                            ariaLabel="start"
                            name="start"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={values.start}
                            hasError={touched.start && !!errors.start}
                            min={new Date().toISOString().split('T')[0]}
                            style={{ width: '184px' }}
                          />
                        </Box>
                      </Field>
                    </Flex>
                    <Flex flexDirection="column">
                      <Field
                        label="Fecha fin de entrega"
                        color="gray"
                        sizeText="display14"
                        marginBottom={1}
                        fontWeight="medium"
                        errorMessage={touched.end && errors.end}>
                        <Box width="184px">
                          <Input
                            type="date"
                            ariaLabel="end"
                            name="end"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={values.end}
                            hasError={touched.end && !!errors.end}
                            min={values.start || new Date().toISOString().split('T')[0]}
                          />
                        </Box>
                      </Field>
                    </Flex>
                  </Flex>
                )}
              </Flex>
              <Flex paddingBottom={4}>
                <Flex paddingBottom={4} flexDirection="column">
                  <Checkbox
                    ariaLabel="modifyDeliveryTime"
                    action={(_, value) => handleModifyDeliveryTime(value)}
                    defaultChecked={values.modifyDeliveryTime}
                    name="modifyDeliveryTime"
                    hasError={touched.modifyDeliveryTime && !!errors.modifyDeliveryTime}
                    label="Informar el plazo de entrega"
                    fontWeight="medium"
                  />
                  {values.modifyDeliveryTime && (
                    <Field
                      label="Plazo de entrega"
                      color="gray"
                      sizeText="display14"
                      marginBottom={1}
                      fontWeight="medium"
                      errorMessage={touched.deliveryTime && errors.deliveryTime}>
                      <Box width="184px">
                        <Input
                          required
                          ariaLabel="deliveryTime"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          defaultValue={values.deliveryTime}
                          name="deliveryTime"
                          hasError={touched.deliveryTime && !!errors.deliveryTime}
                          placeholderMessage="Ingrese el plazo"
                          maxLength="200"
                        />
                      </Box>
                    </Field>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex paddingBottom="20px" width="100%">
          <Flex minWidth="264px" maxWidth="264px" paddingLeft={2} flexDirection="column">
            <Text as="span" sizeText="display18" color="black" fontWeight="600" paddingBottom={2}>
              Retención
            </Text>
            <Text as="span" sizeText="display14" color="gray1">
              % de retención indicado por el contratista. Si no estás de acuerdo con la retención,
              puedes indicarlo desmarcando la aceptación y añadiendo tus comentarios.
            </Text>
          </Flex>
          <Flex paddingLeft={4} flexDirection="column" width="100%">
            <Flex paddingBottom={4}>
              <Text as="span" sizeText="display14" color="black">
                {bidPackage?.retention === null ? '-' : `${bidPackage?.retention} %`}
              </Text>
            </Flex>
            <Flex flexDirection="column" marginBottom={5}>
              <Flex paddingBottom={4}>
                <Checkbox
                  ariaLabel="acceptRetentionConditions"
                  action={(_, value) => handleAcceptRetentionConditions(value)}
                  defaultChecked={values.acceptRetentionConditions}
                  name="acceptRetentionConditions"
                  hasError={touched.acceptRetentionConditions && !!errors.acceptRetentionConditions}
                  label="Acepto la retención"
                  fontWeight="medium"
                />
              </Flex>
              {!values.acceptRetentionConditions && (
                <Flex paddingTop={5}>
                  <Flex flexDirection="column" paddingRight={5}>
                    <Field
                      label="% de retención"
                      required
                      color="gray"
                      sizeText="display14"
                      marginBottom={1}
                      fontWeight="medium"
                      errorMessage={touched.retention && errors.retention}>
                      <Box width="184px">
                        <InputNumber
                          placeholderMessage="Ingrese tu % de retención"
                          ariaLabel="retention"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.retention}
                          name="retention"
                          hasError={touched.retention && !!errors.retention}
                        />
                      </Box>
                    </Field>
                  </Flex>
                  <Flex flexDirection="column" width="100%">
                    <Field
                      label="Comentario de retención"
                      color="gray"
                      sizeText="display14"
                      marginBottom={1}
                      fontWeight="medium"
                      errorMessage={touched.retentionCriteria && errors.retentionCriteria}>
                      <Input
                        placeholderMessage="Ingrese tu comentario sobre la forma de pago"
                        ariaLabel="retentionCriteria"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.retentionCriteria}
                        name="retentionCriteria"
                        hasError={touched.retentionCriteria && !!errors.retentionCriteria}
                      />
                    </Field>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex paddingBottom="20px" width="100%">
          <Flex minWidth="264px" maxWidth="264px" paddingLeft={2} flexDirection="column">
            <Text as="span" sizeText="display18" color="black" fontWeight="600" paddingBottom={2}>
              Condiciones de pago
            </Text>
            <Text as="span" sizeText="display14" color="gray1">
              Condiciones de pago ofertadas por el contratista. Si quieres proponer una alternativa,
              puedes indicarlo desmarcando la aceptación e introduciendo unas nuevas condiciones de
              pago.
            </Text>
          </Flex>
          <Flex paddingLeft={4} flexDirection="column" width="100%">
            <Flex paddingBottom={4}>
              <Text as="span" sizeText="display14" color="black">
                <Text as="span" sizeText="display18" color="black">{`${
                  bidPackage?.paymentDays || '-'
                } días`}</Text>{' '}
                - {bidPackage?.paymentConditions || 'Forma de pago no informada'}
              </Text>
            </Flex>
            <Flex flexDirection="column" marginBottom={5}>
              <Flex paddingBottom={4}>
                <Checkbox
                  ariaLabel="acceptPaymentConditions"
                  name="acceptPaymentConditions"
                  action={(_, value) => handleAcceptPaymentConditions(value)}
                  defaultChecked={values.acceptPaymentConditions}
                  hasError={touched.acceptPaymentConditions && !!errors.acceptPaymentConditions}
                  label="Acepto las condiciones de pago del contratista"
                  fontWeight="medium"
                />
              </Flex>
              {!values.acceptPaymentConditions && (
                <Flex paddingTop={5}>
                  <Flex flexDirection="column" paddingRight={5}>
                    <Field
                      label="Días de pago"
                      required
                      color="gray"
                      sizeText="display14"
                      marginBottom={1}
                      fontWeight="medium"
                      errorMessage={touched.paymentDays && errors.paymentDays}>
                      <Box width="184px">
                        <SelectInput
                          isSearchable
                          name="paymentDays"
                          id="paymentDays"
                          onChange={(_, v) => setFieldValue('paymentDays', v.value)}
                          options={paymentDaysOptions}
                          placeholderMessage="Seleccionar"
                          ariaLabel="turnover"
                          hasError={touched.paymentDays && !!errors.paymentDays}
                          defaultValue={
                            values.paymentDays &&
                            paymentDaysOptions.find((el) => el.value === Number(values.paymentDays))
                          }
                        />
                      </Box>
                    </Field>
                  </Flex>
                  <Flex flexDirection="column" width="100%">
                    <Field
                      label="Condiciones de pago"
                      color="gray"
                      sizeText="display14"
                      marginBottom={1}
                      fontWeight="medium"
                      errorMessage={touched.paymentConditions && errors.paymentConditions}>
                      <Input
                        placeholderMessage="Ingrese tu comentario sobre la forma de pago"
                        ariaLabel="paymentConditions"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.paymentConditions}
                        name="paymentConditions"
                        hasError={touched.paymentConditions && !!errors.paymentConditions}
                      />
                    </Field>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex paddingBottom="20px" width="100%">
          <Flex minWidth="264px" maxWidth="264px" paddingLeft={2} flexDirection="column">
            <Text as="span" sizeText="display18" color="black" fontWeight="600" paddingBottom={2}>
              Información adicional
            </Text>
            <Text as="span" sizeText="display14" color="gray1">
              Contienen información adicional sobre la petición de presupuesto.
            </Text>
          </Flex>
          <Flex paddingLeft={4} flexDirection="column" width="100%">
            <Text as="span" sizeText="display14" color="black">
              <AttachmentList files={bidPackage?.files} withDownload />
              <Links links={bidPackage?.links} />
            </Text>
          </Flex>
        </Flex>
        <Flex paddingBottom="20px" width="100%">
          <Flex minWidth="264px" maxWidth="264px" paddingLeft={2} flexDirection="column">
            <Text as="span" sizeText="display18" color="black" fontWeight="600" paddingBottom={2}>
              Condiciones generales
            </Text>
            <Text as="span" sizeText="display14" color="gray1">
              Estas son las condiciones generales de la oferta que ha indicado el contratista.
            </Text>
          </Flex>
          <Flex paddingLeft={4} flexDirection="column" width="100%">
            <Flex paddingBottom={4}>
              <Text
                as="span"
                sizeText="display14"
                color="black"
                style={{
                  whiteSpace: 'pre-wrap',
                }}>
                {bidPackage?.generalConditions || '-'}
              </Text>
            </Flex>
            <Flex flexDirection="column" width="100%" paddingBottom={3} paddingRight={3}>
              <Text fontWeight="medium" color="gray" sizeText="display14" marginBottom={2}>
                Comentarios generales de la oferta para la constructora{' '}
              </Text>
              <Textarea
                placeholderMessage="Ingrese tus comentarios generales"
                name="comments"
                onChange={handleChange}
                hasError={touched.comments && !!errors.comments}
                defaultValue={values.comments}
                maxLength="auto"
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex paddingBottom="20px" width="100%">
          <Flex minWidth="264px" maxWidth="264px" paddingLeft={2} flexDirection="column">
            <Text as="span" sizeText="display18" color="black" fontWeight="600" paddingBottom={2}>
              Ficheros
            </Text>
            <Text as="span" sizeText="display14" color="gray1">
              Puedes adjuntar ficheros o enlaces para completar tu propuesta.
            </Text>
          </Flex>
          <Flex flexDirection="column" marginBottom={7} paddingLeft={4} width="100%">
            <AttachmentList files={values.files} withDelete onDeleteFile={handleDeleteFile} />
            <Flex alignItems="center" width="100%">
              <Box width="392px" paddingTop={3}>
                <Uploader
                  multiple
                  name="files"
                  marginBottom={1}
                  errors={errorsImages}
                  maxNumFiles={5}
                  onImagesLoad={(files) => onImagesLoad(files, handleAddFile)}
                  descriptionMessage="Arrastra o haz click para subir tus ficheros aquí"
                  accept="all"
                  padding={4}
                />
              </Box>
              <Flex paddingLeft={4}>
                <Icon color="gray2" sizeIcon="display12" paddingRight={2}>
                  <LinkIcon />
                </Icon>
                <Text
                  as="span"
                  onClick={() => setModalLinksIsOpened(true)}
                  sizeText="display14"
                  color="blue1"
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                  Añadir enlaces externos
                </Text>
                <Text
                  as="span"
                  fontWeight="medium"
                  color="black"
                  sizeText="display14"
                  onClick={() => setModalLinksIsOpened(true)}
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    paddingLeft: 4,
                  }}>
                  {values.links?.length || ''}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <ModalEditLinks
          data={values.links}
          modalIsOpened={modalLinksIsOpened}
          setModalIsOpened={setModalLinksIsOpened}
          handleChange={(linksList, deleteLinksId) =>
            handleChangeLinks(linksList, deleteLinksId, 'proposal')
          }
        />
      </Flex>
    </>
  )
}
