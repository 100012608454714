import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ProjectListTemplate } from '../template/ProjectListTemplate'

import { useSearchOrderParams } from '../../../../common/hooks/useSearchOrderParams'
import { useProject } from '../../../../common/hooks/useProject'

const optionsOrder = [
  {
    label: 'Alfabéticamente',
    value: { field: 'name', type: 'asc' },
  },
  {
    label: 'Fecha de creación: Más recientes',
    value: { field: 'created_at', type: 'desc' },
  },
  {
    label: 'Fecha de creación: Más antiguos',
    value: { field: 'created_at', type: 'asc' },
  },
  {
    label: 'Fecha de modificación: Más recientes',
    value: { field: 'updated_at', type: 'desc' },
  },
  {
    label: 'Fecha de modificación: Más antiguos',
    value: { field: 'updated_at', type: 'asc' },
  },
  {
    label: 'Presupuesto de venta: Más alto',
    value: { field: 'budget', type: 'desc' },
  },
  {
    label: 'Presupuesto de venta: Más bajo',
    value: { field: 'budget', type: 'asc' },
  },
]

// Refactored
const ProjectListPage = () => {
  const history = useHistory()

  const [deleteProjectModalIsOpened, setDeleteProjectModalIsOpened] = useState(false)
  const [selectId, setSelectId] = useState(null)

  const {
    data: { projects },
    getAllProjects,
    deleteProject,
    actionProject,
    cancelRequest,
  } = useProject()

  const whoIsActive = history.location.pathname.includes('obras') ? 'projects' : 'offers'

  const [optionsFilter, setOptionsFilter] = useState([])
  const { order, setOrder, filters, setFilters, getOptionStates, resetOrder, resetfilters } =
    useSearchOrderParams({ optionsOrder, defaultOption: 1 })

  const goToCreateProject = () => history.push('/constructora/crear-obra')

  const goToProjectDetails = (projectId) => history.push(`/constructora/tus-obras/${projectId}`)

  const goToProjectOffer = (projectId) => history.push(`/constructora/ofertas/${projectId}`)

  const selectProjectToDelete = (projectId) => {
    setSelectId(projectId)
    setDeleteProjectModalIsOpened(true)
  }

  const closeModal = () => {
    setDeleteProjectModalIsOpened(false)
    setSelectId(null)
  }

  const handleDeleteProject = async () => {
    setDeleteProjectModalIsOpened(false)
    await deleteProject(selectId)
    setSelectId(null)
  }

  useEffect(() => {
    if (!filters.length) {
      const states = projects.data.map(({ state }) => state)
      setOptionsFilter(getOptionStates(states, 'proposal'))
    }
  }, [projects])

  useEffect(() => {
    if (filters.length) resetfilters()
    if (order.value !== optionsOrder[1].value) resetOrder()
  }, [history.location])

  useEffect(() => {
    getAllProjects({ order: order.value, filters })
  }, [order, filters])

  useEffect(
    () => () => {
      cancelRequest()
    },
    [],
  )

  return (
    <ProjectListTemplate
      projects={projects}
      onActionProject={actionProject}
      optionsOrder={optionsOrder}
      optionsFilter={optionsFilter}
      setOrder={setOrder}
      order={order}
      filters={filters}
      setFilters={setFilters}
      whoIsActive={whoIsActive}
      deleteProjectModalIsOpened={deleteProjectModalIsOpened}
      selectProjectToDelete={selectProjectToDelete}
      closeModal={closeModal}
      handleDeleteProject={handleDeleteProject}
      goToCreateProject={goToCreateProject}
      goToProjectDetails={goToProjectDetails}
      goToProjectOffer={goToProjectOffer}
    />
  )
}

export default ProjectListPage
