import { useState } from 'react'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Help } from '@proveoeng/uikit/dist/atoms/Icons'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Tooltip } from '@proveoeng/uikit/dist/atoms/Tooltip'

const getPaymentDaysCopy = (paymentDays) => (paymentDays ? `${paymentDays} días` : '-')

export const ProposalPaymentDaysTooltip = ({ paymentDays, paymentConditions }) => {
  const [paymentConditionsActive, setPaymentConditionsActive] = useState(false)

  if ((paymentDays && !paymentConditions) || !paymentDays) return getPaymentDaysCopy(paymentDays)

  return (
    <Tooltip
      active={paymentConditionsActive}
      content={paymentConditions}
      setActive={setPaymentConditionsActive}>
      <Flex justifyContent="center" alignItems="center">
        {getPaymentDaysCopy(paymentDays)}
        <Icon sizeIcon="display18">
          <Help />
        </Icon>
      </Flex>
    </Tooltip>
  )
}
