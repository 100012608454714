import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { services as servicesCompanies } from '../schemes/companies/config'
import { services as servicesEmployees } from '../schemes/employees/config'
import { services as servicesHelper } from '../schemes/helpers/config'
import { requestApi } from '../../../lib/request'
import { useUtils } from './useUtils'
import { EmployeeMapper } from '../schemes/employees/mapper'
import { getEmployeeSuccess } from '../schemes/employees'
import { getBase64 } from '../functions/serialize'

export const useManageEmployees = () => {
  const dispatch = useDispatch()
  const { setError, setLoading } = useUtils()

  const { companyId } = useSelector((state) => state.employee?.data)

  const [employees, setEmployees] = useState([])

  const adapterEmployees = (data = []) =>
    data.map(({ resource }) => EmployeeMapper.hydrate(resource))

  const resetEmployees = () => setEmployees([])

  const getFileEmployee = async (avatar) => {
    try {
      const response = await requestApi(servicesHelper.getFile(avatar))
      const { data, headers } = response
      dispatch(
        getEmployeeSuccess({
          avatar: `data:${headers['content-type']};base64,${getBase64(data)}`,
        }),
      )
    } catch (error) {
      setError(error)
    }
  }

  const getEmployee = async () => {
    setLoading(true)
    try {
      const {
        data: {
          data: { avatar, ...rest },
        },
      } = await requestApi(servicesEmployees.getEmployee())
      if (avatar) {
        getFileEmployee(avatar)
      }
      dispatch(getEmployeeSuccess({ ...rest }))
    } catch (error) {
      setLoading(false)
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const getEmployees = async () => {
    setLoading(true)
    try {
      const {
        data: { data },
      } = await requestApi(servicesCompanies.getEmployees(companyId))
      setEmployees(adapterEmployees(data))
      setLoading(false)
    } catch (error) {
      resetEmployees()
      setLoading(false)
      setError(error)
    }
  }

  const createEmployee = async (employeeId, body) => {
    setLoading(true)
    try {
      return await requestApi(servicesCompanies.createEmployee(companyId, employeeId, body))
    } catch (error) {
      return false
    } finally {
      setLoading(false)
    }
  }

  const deleteEmployee = async (employeeId) => {
    setLoading(true)
    try {
      return await requestApi(servicesCompanies.deleteEmployee(companyId, employeeId))
    } catch (error) {
      return false
    } finally {
      setLoading(false)
    }
  }

  const actionEmployee = async (employeeId, body) => {
    setLoading(true)
    try {
      return await requestApi(servicesCompanies.actionEmployee(companyId, employeeId, body))
    } catch (error) {
      return false
    } finally {
      setLoading(false)
    }
  }

  const updateExistingEmployee = async (employeeId, body) => {
    setLoading(true)
    try {
      return await requestApi(servicesEmployees.updateExistingEmployee(employeeId, body))
    } catch (error) {
      return false
    } finally {
      setLoading(false)
    }
  }

  const updateAvatarExistingEmployee = async (employeeId, body) => {
    setLoading(true)
    try {
      return await requestApi(servicesEmployees.updateAvatarExistingEmployee(employeeId, body))
    } catch (error) {
      return false
    } finally {
      setLoading(false)
    }
  }

  return {
    employees,
    getEmployee,
    getEmployees,
    resetEmployees,
    updateExistingEmployee,
    updateAvatarExistingEmployee,
    createEmployee,
    deleteEmployee,
    actionEmployee,
  }
}
