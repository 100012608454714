export const FormatDate = {
  hydrate(date) {
    if (date) {
      const dateFormatISO = new Date((!date?.includes('T') && date.split('-').join('/')) || date)
      const formattedMonth = dateFormatISO.getMonth() + 1

      const optionsAll = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }

      const optionsNoYear = {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
      }

      const optionsSemi = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }

      const optionsMonth = {
        month: 'long',
      }
      const optionsDay = {
        weekday: 'long',
      }

      const hours = dateFormatISO.getHours()
      const redableHours = hours > 9 ? hours : `0${hours}`

      const minuts = dateFormatISO.getMinutes()
      const redableMinuts = minuts > 9 ? minuts : `0${minuts}`

      return {
        isoFormat: dateFormatISO,
        readableStringDate: dateFormatISO.toLocaleDateString('es-ES', optionsSemi),
        readableStringDateNoYear: dateFormatISO.toLocaleDateString('es-ES', optionsNoYear),
        readableStringDateLarge: dateFormatISO.toLocaleDateString('es-ES', optionsAll),
        stringMonth: dateFormatISO.toLocaleDateString('es-ES', optionsMonth),
        stringDay: dateFormatISO.toLocaleDateString('es-ES', optionsDay),
        dateNumberFormat: dateFormatISO.getDate(),
        dayNumberFormat: dateFormatISO.getDay(),
        timeStamp: dateFormatISO.getTime(),
        hours: dateFormatISO.getHours(),
        day: `${
          dateFormatISO.getDate() < 10 ? `0${dateFormatISO.getDate()}` : dateFormatISO.getDate()
        }`,
        month: `${formattedMonth < 10 ? `0${formattedMonth}` : formattedMonth}`,
        year: dateFormatISO.getFullYear(),
        minutes: dateFormatISO.getMinutes(),
        seconds: dateFormatISO.getSeconds(),
        readableTime: `${redableHours}:${redableMinuts}`,
        readableReverseDate: `${
          dateFormatISO.getDate() < 10 ? `0${dateFormatISO.getDate()}` : dateFormatISO.getDate()
        }/${
          formattedMonth < 10 ? `0${formattedMonth}` : formattedMonth
        }/${dateFormatISO.getFullYear()}`,
        readableDate: `${dateFormatISO.getFullYear()}-${
          formattedMonth < 10 ? `0${formattedMonth}` : formattedMonth
        }-${
          dateFormatISO.getDate() < 10 ? `0${dateFormatISO.getDate()}` : dateFormatISO.getDate()
        }`,
        readableReverseDateDotted: `${
          dateFormatISO.getDate() < 10 ? `0${dateFormatISO.getDate()}` : dateFormatISO.getDate()
        }.${
          formattedMonth < 10 ? `0${formattedMonth}` : formattedMonth
        }.${dateFormatISO.getFullYear()}`,
        eventLogsMessagesFormat: `${
          dateFormatISO.getDate() < 10 ? `0${dateFormatISO.getDate()}` : dateFormatISO.getDate()
        }/${
          formattedMonth < 10 ? `0${formattedMonth}` : formattedMonth
        }, ${redableHours}:${redableMinuts}`,
      }
    }
    return undefined
  },

  dehydrate(date, hour, minute) {
    const separatedDate = date.split('-')
    return JSON.stringify(
      new Date(
        parseInt(separatedDate[0], 10),
        parseInt(separatedDate[1], 10) - 1,
        parseInt(separatedDate[2], 10),
        parseInt(hour, 10),
        parseInt(minute, 10),
        0,
      ),
    ).replace(/"/g, '')
  },
}
