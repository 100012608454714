/* eslint-disable no-await-in-loop */
import { useDispatch } from 'react-redux'

import { useState } from 'react'
import { requestApi } from '../../../lib/request'
import { getBase64 } from '../functions/serialize'
import { getCompanySuccess } from '../schemes/companies'
import { services } from '../schemes/companies/config'
import { CompanyMapper } from '../schemes/companies/mapper'
import { services as servicesHelper } from '../schemes/helpers/config'
import { useUtils } from './useUtils'

export const useCompany = () => {
  const dispatch = useDispatch()
  const { setError, setAlert, setLoading } = useUtils()
  const [company, setCompany] = useState({
    companyId: '',
    logo: '',
    generalData: {},
    billingData: {},
    activityData: {},
    type: '',
    categories: [],
    isSameBillingData: false,
    tenderingData: {},
  })

  const getFileCompany = async (logoId) => {
    try {
      const response = await requestApi(servicesHelper.getFile(logoId))
      const { data, headers } = response
      const logo = `data:${headers['content-type']};base64,${getBase64(data)}`
      return logo
    } catch (error) {
      setError(error)
      throw error
    }
  }

  const getCompany = async (id) => {
    setLoading(true)
    try {
      const {
        data: { data },
      } = await requestApi(services.getCompany(id))
      const logo = data?.logo ? await getFileCompany(data.logo) : ''
      setLoading(false)
      const company = CompanyMapper.hydrate(data)
      setCompany({ ...company, logo })
      dispatch(getCompanySuccess({ ...company, logo }))
      return company
    } catch (error) {
      setLoading(false)
      setError(error)

      return false
    }
  }

  const setCompanyActivity = async (id, activity) => {
    setLoading(true)
    try {
      await requestApi(
        services.postCompanyActivityData(id, CompanyMapper.dehydrateActivity(activity)),
      )
      dispatch(getCompanySuccess(activity))
    } catch (error) {
      setError(error)
      throw error
    } finally {
      setLoading(false)
    }
  }

  const setCompanyCategories = async (id, categories) => {
    setLoading(true)
    try {
      await requestApi(services.postCompanyCategoriesData(id, categories))
      await getCompany(id)
    } catch (error) {
      setError(error)
      throw error
    } finally {
      setLoading(false)
    }
  }

  const setCompanyLogo = async (id, logo) => {
    setLoading(true)
    try {
      await requestApi(services.postCompanyLogo(id, logo))
      dispatch(getCompanySuccess({ logo }))
    } catch (error) {
      setError(error)
      throw error
    } finally {
      setLoading(false)
    }
  }

  const setCompanyGeneral = async (id, body) => {
    setLoading(true)
    try {
      await requestApi(services.postCompanyGeneralData(id, CompanyMapper.dehydrateGeneral(body)))
      dispatch(getCompanySuccess(body))
    } catch (error) {
      setError(error)
      throw error
    } finally {
      setLoading(false)
    }
  }

  /**
   *
   * @param {string} id
   * @param {billindData} body
   */
  const setCompanyBilling = async (id, body) => {
    setLoading(true)
    try {
      await requestApi(services.postCompanyBillingData(id, CompanyMapper.dehydrateBilling(body)))
      dispatch(getCompanySuccess({ billing: body }))
      setAlert('success', 'Guardado correctamente')
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const setCompanyTendering = async (id, rate) => {
    setLoading(true)
    try {
      await requestApi(services.postCompanyTendering(id, rate))
      dispatch(getCompanySuccess({ tendering: rate }))
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  return {
    data: {
      company,
    },
    getCompany,
    setCompanyActivity,
    setCompanyCategories,
    setCompanyLogo,
    setCompanyGeneral,
    setCompanyBilling,
    setCompanyTendering,
  }
}
