import { ButtonTabs } from '@proveoeng/uikit/dist/atoms/Button'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { DataTable } from '@proveoeng/uikit/dist/molecules/DataTable'
import { useEffect, useState } from 'react'

import { currencyFormat, parseToInteger, parseToString } from '../../../../../lib/utils/Format'
import { parseQuality } from '../../../../../lib/utils/parseQuality'
import { AttachmentList } from '../../../../common/components/attachmentList/Attachment'
import { Links } from '../../../../common/components/links/Links'
import { ModalEmissionsDetails } from '../../common/components/ModalEmissionsDetails'

/**
 *
 * @param {{
 *  data: ProposalItem
 * }} props
 */
const OfferItemListExpanded = ({ data: { bidItem } }) => {
  const [whoIsActive, setWhoIsActive] = useState('')

  const isActive = (tab) => tab === whoIsActive

  const tabItems = []
  if (bidItem?.comments) {
    tabItems.push({
      id: 'comments',
      children: <>Comentario</>,
      isActive: isActive('comments'),
      action: (_, target) => setWhoIsActive(target?.id),
    })
  }
  if (bidItem?.files?.length) {
    tabItems.push({
      id: 'attachments',
      children: <>Adjuntos</>,
      isActive: isActive('attachments'),
      action: (_, target) => setWhoIsActive(target?.id),
    })
  }
  if (bidItem?.links?.length) {
    tabItems.push({
      id: 'links',
      children: <>Enlaces externos</>,
      isActive: isActive('links'),
      action: (_, target) => setWhoIsActive(target?.id),
    })
  }

  useEffect(() => {
    setWhoIsActive(tabItems[0]?.id || '')
  }, [bidItem])

  return (
    <Flex paddingX={4} flexDirection="column" alignItems="baseline" paddingY={4}>
      <ButtonTabs items={tabItems} />
      <Box paddingY={4}>
        {whoIsActive === 'attachments' && <AttachmentList files={bidItem?.files} withDownload />}
        {whoIsActive === 'links' && <Links links={bidItem?.links} />}
        {whoIsActive === 'comments' && (
          <Flex marginY={3} flexWrap="wrap">
            <Text
              sizeText="display14"
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              marginLeft={5}
              style={{
                whiteSpace: 'pre-wrap',
              }}>
              {bidItem?.comments || '-'}
            </Text>
          </Flex>
        )}
      </Box>
    </Flex>
  )
}

/**
 *
 * @param {{
 *  proposalItems: ProposalItem[],
 *  total: string,
 * }} props
 */
export const OfferEmissions = ({ proposalItems = [], total }) => {
  const [proposalSelected, setProposalSelected] = useState()
  const [modalDetailsIsOpen, setModalDetailsIsOpen] = useState(false)

  const handleDetails = (proposalItem) => {
    setProposalSelected(proposalItem)
    setModalDetailsIsOpen(true)
  }

  const columns = [
    {
      name: 'Partida',
      selector: (row) => row?.bidItem?.name?.toLowerCase(),
      cell: (row) => (
        <Text
          as="span"
          sizeText="display14"
          color="black"
          fontWeight="regular"
          style={{
            whiteSpace: 'pre-wrap',
          }}>
          {row?.bidItem?.name}
        </Text>
      ),
      sortable: true,
      minWidth: '418px',
    },
    {
      name: 'Unidad',
      selector: (row) => row?.bidItem?.unit,
      sortable: true,
      maxWidth: '110px',
    },
    {
      name: 'Medición',
      selector: (row) => parseToInteger(row?.bidItem?.measurement, 3),
      cell: (row) => (
        <Text
          as="span"
          sizeText="display14"
          color="black"
          fontWeight="regular"
          style={{
            whiteSpace: 'pre-wrap',
          }}>
          {row?.bidItem?.measurement}
        </Text>
      ),
      sortable: true,
      maxWidth: '150px',
      right: true,
    },
    {
      name: 'Calidad',
      selector: (row) => row?.dataQuality,
      cell: (row) => (
        <Text
          as="span"
          sizeText="display12"
          color="black"
          fontWeight="medium"
          textAlign="right"
          style={{
            whiteSpace: 'pre-wrap',
          }}>
          {parseQuality(row?.dataQuality) || '-'}
        </Text>
      ),
      sortable: true,
      maxWidth: '80px',
      right: true,
    },
    {
      name: (
        <Box marginBottom={2} width="100%">
          <Box marginBottom={2} paddingTop={4}>
            <Text textAlign="center" sizeText="display14" overflow="hidden" fontWeight="600">
              Equivalente de CO2
            </Text>
          </Box>
          <Flex width="100%" justifyContent="space-between">
            <Text
              width="104px"
              textAlign="right"
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              sizeText="display10">
              Por unidad (kg)
            </Text>
            <Text
              width="116px"
              textAlign="right"
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              sizeText="display10"
              paddinx={2}>
              Total
            </Text>
          </Flex>
        </Box>
      ),
      selector: (row) => parseToInteger(row?.eCo2),
      cell: (row) => (
        <Flex width="100%" justifyContent="space-between">
          <Box width="110px" textAlign="right">
            {parseToString(parseToInteger(row?.eCo2))}
          </Box>
          <Box width="190px" textAlign="right">
            {row?.eCo2
              ? parseToString(
                  parseToInteger(row?.eCo2) * (parseToInteger(row?.bidItem?.measurement, 3) / 1000),
                )
              : '-'}
          </Box>
        </Flex>
      ),
      sortable: true,
      maxWidth: '300px',
    },
    {
      name: '',
      button: true,
      cell: (row) => {
        const item = proposalItems?.find((item) => item?.proposalItemId === row?.proposalItemId)
        return (
          <>
            {item?.comments || !!item?.files?.length || !!item?.links?.length ? (
              <Text
                color="blue1"
                textAlign="right"
                sizeText="display14"
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => handleDetails(item)}>
                Ver detalles
              </Text>
            ) : (
              '-'
            )}
          </>
        )
      },
    },
  ]

  const isDisabled = ({ bidItem }) =>
    !bidItem?.comments && !bidItem?.files?.length && !bidItem?.links?.length

  return (
    <Box>
      <DataTable
        columns={columns}
        data={proposalItems?.map((proposalItem) => proposalItem)}
        pagination={proposalItems?.length > 10}
        expandableRows
        expandableRowsComponent={OfferItemListExpanded}
        expandableRowDisabled={isDisabled}
      />
      <Flex justifyContent="space-between" marginTop={4} marginBottom={8}>
        <Box>
          <Text sizeText="display24" fontWeight="bold" paddingLeft={4}>
            Total
          </Text>
        </Box>
        <Text sizeText="display24" fontWeight="bold" marginRight="100px">
          {currencyFormat(parseToInteger(total) / 100)}
        </Text>
      </Flex>

      <ModalEmissionsDetails
        isOpen={modalDetailsIsOpen}
        setIsOpen={setModalDetailsIsOpen}
        files={proposalSelected?.files}
        links={proposalSelected?.links}
        comments={proposalSelected?.comments}
      />
    </Box>
  )
}
