import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'

import { AttachmentList } from '../../../../common/components/attachmentList/Attachment'
import { Links } from '../../../../common/components/links/Links'

export const ModalDetails = ({
  isOpen,
  setIsOpen,
  comments = 'No hay comentarios.',
  files = [],
  links = [],
}) => (
  <>
    <Modal
      isOpen={isOpen}
      id="details-modal"
      onModalClose={() => setIsOpen(false)}
      closeWithButton
      minWidth="300px"
      width="570px"
      title="Información adicional">
      <Modal.Content>
        <Box marginBottom={8}>
          <Text sizeText="display18" color="black" fontWeight="600" marginBottom={4}>
            Comentarios
          </Text>
          <Text
            style={{
              whiteSpace: 'pre-wrap',
            }}>
            {comments}
          </Text>
        </Box>
        <Box marginBottom={8}>
          <Text sizeText="display18" color="black" fontWeight="600" marginBottom={4}>
            Adjuntos
          </Text>
          {!files.length && '-'}
          <AttachmentList files={files} withDownload />
        </Box>
        <Box>
          <Text sizeText="display18" color="black" fontWeight="600" marginBottom={4}>
            Enlaces externos
          </Text>
          {!links.length && '-'}
          <Links links={links} />
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Button colorType="transparent" action={() => setIsOpen(false)}>
            Hecho
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  </>
)
