import { useFormikContext } from 'formik'

import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { InputNumber } from '@proveoeng/uikit/dist/atoms/InputNumber'
import { Box, Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { DataTable } from '@proveoeng/uikit/dist/molecules/DataTable'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'

import { parseToInteger, parseToString } from '../../../../../lib/utils/Format'

export const ModalCalcCosts = ({ costs = [], isOpened, closeModal, onSubmit }) => {
  const { values, handleChange, errors, handleBlur, touched } = useFormikContext()

  const getTotal = () =>
    costs.reduce(
      (acc, prev) =>
        acc + prev.eCo2 * (parseToInteger(values[`measurement-${prev.reference}`], 3) / 1000),
      0,
    )

  const isDisabed = Object.keys(errors).find((key) => key.includes('measurement'))

  const columns = [
    {
      name: 'Código de referencia',
      selector: (row) => row.reference,
      sortable: true,
      width: '180px',
      cell: (row) => (
        <Text as="span" sizeText="display14" fontWeight="regular">
          {row.reference}
        </Text>
      ),
    },
    {
      name: 'Partida',
      selector: (row) => row.name,
      sortable: true,
      width: '350px',
      cell: (row) => (
        <Text as="span" sizeText="display14" fontWeight="regular">
          {row.name}
        </Text>
      ),
    },
    {
      name: 'Unidad',
      selector: (row) => row.unit,
      sortable: true,
      width: '120px',
      cell: (row) => (
        <Text as="span" sizeText="display14" fontWeight="regular">
          {row.unit}
        </Text>
      ),
    },
    {
      name: 'CeCO2/unidad',
      selector: (row) => row.eCo2,
      sortable: true,
      width: '150px',
      cell: (row) => (
        <Text as="span" sizeText="display14" fontWeight="regular">
          {parseToString(row.eCo2)} kg/m2
        </Text>
      ),
    },
    {
      name: 'Medicción',
      selector: (row) => row.measurement,
      width: '140px',
      cell: (row) => {
        const key = `measurement-${row.reference}`
        return (
          <Box maxWidth="100%">
            <InputNumber
              required
              name={key}
              placeholderMessage="0,000"
              ariaLabel="measurement"
              numeralDecimalScale={3}
              hasError={touched[key] && !!errors[key]}
              value={values[key]}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {!!errors[key] && touched[key] && (
              <Text sizeText="display10" color="red1">
                {errors[key]}
              </Text>
            )}
          </Box>
        )
      },
    },
    {
      name: 'CeCO2 total estimado',
      selector: (row) => row.eCo2,
      sortable: true,
      right: true,
      cell: (row) => {
        const key = `measurement-${row.reference}`
        const mesaurement = parseToInteger(values[key], 3) / 1000
        const total = mesaurement * row.eCo2
        return (
          <Text as="span" sizeText="display14" fontWeight="regular">
            {total ? `${parseToString(total)} kg` : '-'}
          </Text>
        )
      },
    },
  ]

  return (
    <Modal
      id="calculate-cost-modal"
      width="95vw"
      maxWidth="1300px"
      isOpen={isOpened}
      onModalClose={closeModal}
      closeWithButton
      title="Previsión de costes ambientales - Producción">
      <Modal.Content>
        <Box mb={4}>
          <DataTable columns={columns} data={costs} highlightOnHover />
        </Box>
        <Flex justifyContent="space-between" paddingX={4}>
          <Box>
            <Text
              sizeText="display16"
              color="black"
              overflow="hidden"
              fontWeight="medium"
              textAlign="left">
              Total
            </Text>
          </Box>
          <Box>
            <Text
              sizeText="display16"
              color="black"
              overflow="hidden"
              fontWeight="medium"
              textAlign="right">
              {parseToString(getTotal())} kg CO2
            </Text>
          </Box>
        </Flex>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Grid gridTemplateColumns="1fr 1fr" gridTemplateRows="1fr" gridColumnGap={5}>
            <Button id="cancel-button" width="184px" colorType="transparent" action={closeModal}>
              Hecho
            </Button>
            <Button colorType="orange" width="184px" action={onSubmit} disabled={isDisabed}>
              Crear compra
            </Button>
          </Grid>
        </Flex>
      </Modal.Actions>
    </Modal>
  )
}
