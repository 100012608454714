import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Box } from '@proveoeng/uikit/dist/atoms/Layout'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { InputNumber } from '@proveoeng/uikit/dist/atoms/InputNumber'

export const CreateProjectCostTemplate = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  companyName,
}) => (
  <>
    <Text as="span" sizeText="display32" color="black" marginBottom={3} fontWeight="bold">
      Introduce tu presupuesto y costes
    </Text>
    <Box paddingY={4}>
      <Text as="span" sizeText="display16" color="black" marginBottom={3}>
        Esta información es para uso interno y no se compartirá con los proveedores ni con nadie
        externo a{' '}
        <Text as="span" sizeText="display16" color="black" fontWeight="bold">
          {companyName}
        </Text>
        .
      </Text>
    </Box>
    <Box as="form" maxWidth={648}>
      <Field
        label="Presupuesto de venta"
        sizeText="display16"
        marginBottom={3}
        marginLeft={2}
        errorMessage={touched.budget && errors.budget}>
        <InputNumber
          name="budget"
          ariaLabel="budget"
          marginBottom={3}
          placeholderMessage="Pon tu presupuesto de venta para la obra"
          hasError={touched.budget && !!errors.budget}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.budget}
        />
      </Field>

      <Field
        label="Coste Previsto"
        sizeText="display16"
        marginBottom={3}
        marginLeft={2}
        errorMessage={touched.expectedCosts && errors.expectedCosts}>
        <InputNumber
          ariaLabel="expectedCosts"
          marginBottom={3}
          placeholderMessage="Pon tu coste previsto para la obra"
          hasError={touched.expectedCosts && !!errors.expectedCosts}
          onChange={handleChange}
          name="expectedCosts"
          value={values.expectedCosts}
          onBlur={handleBlur}
        />
      </Field>
      <Field
        label="K de margen 0"
        sizeText="display16"
        marginBottom={3}
        marginLeft={2}
        errorMessage={touched.kZero && errors.kZero}>
        <InputNumber
          ariaLabel="kZero"
          marginBottom={3}
          placeholderMessage="Pon tu K de margen 0 para la obra"
          hasError={touched.kZero && !!errors.kZero}
          onChange={handleChange}
          name="kZero"
          value={values.kZero}
          onBlur={handleBlur}
        />
      </Field>
    </Box>
  </>
)
