import { Card } from '@proveoeng/uikit/dist/atoms/Card'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { ArrowDown, ArrowRight } from '@proveoeng/uikit/dist/atoms/Icons'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { useState } from 'react'
import { FormatDate } from '../../../../common/mappers/FormatDate'

const OwnerMessageData = ({ name, email, employeeEmail }) => (
  <>
    <Text as="span" fontWeight="bold" color="gray" sizeText="display14">
      {name}
    </Text>
    {email !== employeeEmail && `(${email})`}
  </>
)

export const MessageWrapper = ({ messageData, bg, employeeEmail }) => {
  const [active, setActive] = useState(false)
  const { sendAt, senderName, senderEmail, receiverName, receiverEmail, message } = messageData
  const messageDateFormatted = `${FormatDate.hydrate(sendAt)?.day}/
  ${FormatDate.hydrate(sendAt)?.month}, ${FormatDate.hydrate(sendAt)?.readableTime}`

  return (
    <Card
      bg={bg}
      borderRadius={2}
      borderWidth={1}
      borderStyle="solid"
      borderColor="gray3"
      maxHeight={active ? '100%' : '122px'}
      overflow={active ? 'auto' : 'hidden'}
      position="relative"
      marginBottom={4}>
      <Flex
        flexDirection="column"
        width="100%"
        paddingTop={4}
        paddingRight={4}
        paddingBottom={4}
        paddingLeft={8}>
        <Box>
          <Flex marginBottom={2} justifyContent="space-between">
            <Box position="absolute" left={4}>
              <Icon
                style={{ cursor: 'pointer' }}
                color="black"
                fontSize="display18"
                onClick={() => setActive(!active)}>
                {active ? <ArrowDown /> : <ArrowRight />}
              </Icon>
            </Box>
            <Flex alignItems="center" justifyContent="center">
              <Text as="span" fontWeight="medium" color="gray" sizeText="display14">
                De:{' '}
                <OwnerMessageData
                  name={senderName}
                  email={senderEmail}
                  employeeEmail={employeeEmail}
                />
              </Text>
            </Flex>
            <Text as="span" fontWeight="medium" color="gray" sizeText="display14">
              {messageDateFormatted}
            </Text>
          </Flex>
          <Box marginBottom={2}>
            <Text as="span" fontWeight="medium" color="gray" sizeText="display14">
              Para:{' '}
              <OwnerMessageData
                name={receiverName}
                email={receiverEmail}
                employeeEmail={employeeEmail}
              />
            </Text>
          </Box>
        </Box>
        <Box marginBottom={2} maxWidth="524px">
          <Text
            as="span"
            fontWeight="medium"
            color="gray"
            sizeText="display14"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </Box>
      </Flex>
      {!active && (
        <Box
          position="absolute"
          background={
            bg === 'white'
              ? 'linear-gradient(0deg, white, transparent)'
              : 'linear-gradient(0deg, #FAFAFA, transparent)'
          }
          left={0}
          right={0}
          bottom={0}
          height="40px"
        />
      )}
    </Card>
  )
}
