import PropTypes from 'prop-types'
import { ButtonTabs } from '@proveoeng/uikit/dist/atoms/Button'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

const HomeDashboardTemplate = ({ statsUrl, tabItems = [], title }) => (
  <Flex height="100%" width="100%" flexDirection="column">
    <>
      <Flex width="100%" alignItems="center" justifyContent="space-between" marginBottom={5}>
        <Text sizeText="display30" color="black" fontWeight="bold" maxWidth={856}>
          {title}
        </Text>
      </Flex>
      <Flex marginBottom={7}>
        <ButtonTabs items={tabItems} />
      </Flex>
      {statsUrl && (
        <Flex>
          <iframe
            width="100%"
            title="metabase"
            src={statsUrl}
            style={{ position: 'relative', height: 'calc(100vh - 300px)' }}
          />
        </Flex>
      )}
    </>
  </Flex>
)

HomeDashboardTemplate.propTypes = {
  tabItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
}

HomeDashboardTemplate.defaultProps = {
  title: 'Estadísticas de tu compañia',
}

export default HomeDashboardTemplate
