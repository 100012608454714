import { Box, Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import styled from 'styled-components'

export const FormContainer = styled(Grid)`
  margin-top: 42px;
  grid-template-columns: 264px 602px;
  grid-template-areas: 'description fields';
  grid-gap: 48px;
  @media (max-width: 1024px) {
    display: block;
  }
`

export const TitleStyled = styled(Text)`
  font-weight: bold;
  max-width: 856px;
  margin-bottom: 32px;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }
`

export const InputContainer = styled(Box)`
  display: flex;
  & > div {
    flex: 1;
    margin-right: 24px;
  }

  & > div:last-child {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    display: block;
    & > div {
      margin-right: 0;
    }
  }
`

export const CategoriesListContainer = styled(Box)`
  width: 100%;
  max-height: 45vh;
  overflow-y: auto;
  overflow-x: hidden;
`

export const CategoriesList = styled(Box)`
  column-count: 2;
  max-width: 100%;
  @media (max-width: 768px) {
    column-count: 1;
  }
`
