import * as React from 'react'

function SvgLogo(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 268.88" {...props}>
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <path
            className="cls-1"
            fill="#ff7e00"
            d="M171.91,152.58c-.42.59-20.6,25.06-30.65,37.51-.15.18-5.41,7.44-7.16,9.67-1.88,2.46-3.78,4.84-5.68,7.07a60.64,60.64,0,0,1-14.51,12q-16.72,9.58-34.36,5t-27.8-22.26a49.65,49.65,0,0,1-5.26-36,46.84,46.84,0,0,1,7.58-16.56,43.56,43.56,0,0,1,14.15-12.66,53.81,53.81,0,0,1,16.68-6.1c6.15-1.19,11.74-1,17.11-1,6.69,0,9.26-1.89,9.26-6.07l.11-16.29.06-11.42c0-4.78-1.81-8.52-10.73-8.65A130.6,130.6,0,0,0,72,88.52a80.26,80.26,0,0,0-26.16,9.66,88.41,88.41,0,0,0-27.23,23.9A91.6,91.6,0,0,0,12.28,223.3,90.24,90.24,0,0,0,67.6,265.84a93.79,93.79,0,0,0,34.93,2.38,88.38,88.38,0,0,0,34.36-11.51,83.35,83.35,0,0,0,22.83-19,122.21,122.21,0,0,0,15.37-23.07c1-1.78,2.9-7,3.77-9.36,5.68-18.14,8.89-31.69,11.21-47.48C192.72,139.89,180.62,140.06,171.91,152.58Z"
          />
          <path d="M136.56,0H53.92a6.5,6.5,0,0,0-6.51,6.48V39.83a6.48,6.48,0,0,0,6.51,6.48h82.64A6.48,6.48,0,0,0,143,39.83V6.48A6.5,6.5,0,0,0,136.56,0Z" />
          <path d="M374.47,112.84A93.63,93.63,0,0,0,273.05,93.2a93.9,93.9,0,0,0-29.46,19.64,90.88,90.88,0,0,0-27,65,89.22,89.22,0,0,0,27,64.45,95.49,95.49,0,0,0,29.46,19.46,94.68,94.68,0,0,0,72.14,0,92.07,92.07,0,0,0,49.09-48.38,87.08,87.08,0,0,0,7.32-35.53,88.35,88.35,0,0,0-7.32-35.71A94.23,94.23,0,0,0,374.47,112.84ZM350.9,196.22A49.83,49.83,0,0,1,341.25,211,42.8,42.8,0,0,1,327,221a46.41,46.41,0,0,1-35.7,0A42.84,42.84,0,0,1,277,211a48.1,48.1,0,0,1,0-65.71,45.33,45.33,0,0,1,14.29-10.18,44.47,44.47,0,0,1,35.7,0,45.29,45.29,0,0,1,14.28,10.18,49.83,49.83,0,0,1,9.65,14.82,47.34,47.34,0,0,1,0,36.07Z" />
          <path d="M582.46,107.48a63.79,63.79,0,0,0-21.78-14.64Q547.83,87.48,531,87.48a62.9,62.9,0,0,0-31.42,7.86q-13.92,7.86-22.14,17.14-1.07-4.64-1.79-8.75a31,31,0,0,0-2.14-7.32,11.49,11.49,0,0,0-4.1-5q-2.68-1.78-8-1.78H442.13q-10.71,0-10.71,10.71V257.1q0,4.29,1.6,6.07t7,1.79h32.13q5.36,0,7.14-1.61t1.79-6.25V165.33a31.39,31.39,0,0,1,4.82-12.14,39.83,39.83,0,0,1,9.11-9.82,49,49,0,0,1,11.6-6.61,32.28,32.28,0,0,1,12-2.5q13.92,0,23.2,8.75T551,166.4v90.34c0,3.34.41,5.54,1.25,6.61s3.15,1.61,7,1.61H592.1q4.29,0,6.43-1.79t2.14-6.78v-98.2A84.16,84.16,0,0,0,596,129.8,61.54,61.54,0,0,0,582.46,107.48Z" />
          <path d="M809.6,252.46,738.54,161.4,795,101.77a10.32,10.32,0,0,0,2.15-2.68,7.83,7.83,0,0,0,.35-2.68q0-5.7-7.5-5.71H747.83a11.17,11.17,0,0,0-8.57,3.93l-49.28,55V8.93a10.82,10.82,0,0,0-1.79-6.43C687,.84,684.62,0,681.05,0H649.63q-5,0-6.43,2.86a16.82,16.82,0,0,0-1.43,7.49V256a10.1,10.1,0,0,0,2,6.43c1.3,1.66,3.62,2.5,7,2.5h31.42q5.71,0,7-3.4a17.51,17.51,0,0,0,1.25-5.89V210l15.35-15.71,44.64,63.56q5,7.15,8.93,7.15h46.42a10.55,10.55,0,0,0,5.35-1.08q1.79-1.06,1.79-4.28c0-2.38-1.2-4.76-3.57-7.14Z" />
          <path d="M983,89.63l-20-.72q-5,0-6.78,2t-3.22,8l-3.57,13.57a91.51,91.51,0,0,0-18.74-17.85q-10.55-7.5-29.46-7.5a86.74,86.74,0,0,0-35.18,7,82.62,82.62,0,0,0-27.31,19.1,85.15,85.15,0,0,0-17.68,28.75,101,101,0,0,0-6.25,35.89,97.28,97.28,0,0,0,6.43,35.71,90,90,0,0,0,17.85,28.74,81.37,81.37,0,0,0,27.32,19.28,85.83,85.83,0,0,0,34.82,7h.71a60.07,60.07,0,0,0,17.14-2.15,49.63,49.63,0,0,0,12.5-5.53,40.41,40.41,0,0,0,9.28-8q3.93-4.65,7.15-9.29c.47,2.38.83,4.35,1.07,5.89s.59,3.52,1.07,5.9a16.06,16.06,0,0,0,2.68,7c1.3,1.78,3.75,2.68,7.32,2.68H983a11.83,11.83,0,0,0,7.14-2.33,7.62,7.62,0,0,0,3.21-6.6V99.27a8.59,8.59,0,0,0-3.21-7A10.85,10.85,0,0,0,983,89.63Zm-47.31,122.3q-11.61,13.4-31.61,13.39a41.78,41.78,0,0,1-17.49-3.57,40.18,40.18,0,0,1-13.57-10,44.66,44.66,0,0,1-8.75-15,55.05,55.05,0,0,1-3-18.57,54,54,0,0,1,3-18.21,48.61,48.61,0,0,1,8.57-15.17,38.85,38.85,0,0,1,13.57-10.36,41.28,41.28,0,0,1,17.67-3.75,41.8,41.8,0,0,1,18.21,3.75,41.17,41.17,0,0,1,13.57,10.36,44.18,44.18,0,0,1,8.58,15.35,59,59,0,0,1,2.85,18.39Q947.29,198.55,935.69,211.93Z" />
          <path d="M1197.32,92.48c-1.78-1.9-4.35-2.85-7.68-2.85h-28.92q-10.37,0-10.36,10v90a20.37,20.37,0,0,1-4.28,12.5,40,40,0,0,1-10.36,9.64,56.25,56.25,0,0,1-13.21,6.25,42,42,0,0,1-12.85,2.32q-13.94,0-22.32-8.57T1079,188.54V100q0-5-2.15-7.67T1069,89.63h-29.28a10.09,10.09,0,0,0-10.36,10.71v96.41A88.65,88.65,0,0,0,1033.6,225a62,62,0,0,0,12.85,22.32,57.57,57.57,0,0,0,21.25,14.64q12.67,5.18,29.1,5.18a75.79,75.79,0,0,0,32.67-7.14q15.18-7.14,23.39-16.43c.72,3.1,1.37,5.95,2,8.57a29.62,29.62,0,0,0,2.32,6.79,10.34,10.34,0,0,0,4.28,4.46q2.86,1.61,8.22,1.61h19.28q11.05,0,11.07-10.72V99.62A10,10,0,0,0,1197.32,92.48Z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgLogo
