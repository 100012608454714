import { ButtonTabs } from '@proveoeng/uikit/dist/atoms/Button'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { ProfileActivity } from '../components/ProfileActivity'
import { ProfileCategories } from '../components/ProfileCategories'
import { ProfileCompany } from '../components/ProfileCompany'
import { ProfileUser } from '../components/ProfileUser'
import { TitleStyled } from '../../styles/ProfileStyles'

/**
 *
 * @param {Object} props
 * @param {Company} props.company
 * @param {Array} props.tabs
 * @param {string} props.activeTab
 * @param {Array} props.categories
 * @param {void} props.handleSubmitEmployee
 * @param {void} props.handleSubmitCompany
 * @param {void} props.handleSubmitActivity
 * @param {void} props.handleSubmitCompanyCategories
 * @param {void} props.onProfileImageChange
 * @param {void} props.onLogoImageChange
 */
export const ProfileTemplate = ({
  company,
  tabs = [],
  activetab,
  categories,
  employee,
  handleSubmitEmployee,
  handleSubmitCompany,
  handleSubmitCompanyActivity,
  handleSubmitCompanyCategories,
  profileImage,
  logoImage,
  onProfileImageChange,
  onLogoImageChange,
}) => (
  <>
    <Text sizeText="display14" color="gray2" marginBottom={7}>
      Configuración
      <Text as="span" sizeText="display14" color="black" marginBottom={7} marginLeft={2}>
        Tu perfil
      </Text>
    </Text>
    <TitleStyled sizeText="display52" color="gray">
      Configura tu perfil
    </TitleStyled>
    <Flex>
      <ButtonTabs items={tabs} />
    </Flex>
    {activetab === 'user-data' && (
      <ProfileUser
        avatar={profileImage}
        employee={employee}
        onSubmit={handleSubmitEmployee}
        handleChangeAvatar={onProfileImageChange}
      />
    )}
    {activetab === 'company-data' && (
      <ProfileCompany
        isSameBillingData={company.isSameBillingData}
        generalData={company.generalData}
        billingData={company.billingData}
        onSubmit={handleSubmitCompany}
      />
    )}
    {activetab === 'profile-activity' && (
      <ProfileActivity
        avatar={logoImage}
        activityData={company.activityData}
        onSubmit={handleSubmitCompanyActivity}
        handleChangeAvatar={onLogoImageChange}
      />
    )}
    {activetab === 'categories' && (
      <ProfileCategories
        categories={categories}
        selectedCategories={company.categories}
        onSubmit={handleSubmitCompanyCategories}
      />
    )}
  </>
)
