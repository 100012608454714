import { useFormik } from 'formik'

import { useEffect } from 'react'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Box, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

import {
  ActionButton,
  ActionContainerStyled,
  FormContainer,
  InputContainer,
} from '../../styles/ProfileStyles'
import { profileUserSchema } from '../../../../common/validationSchemes/profileSchemes'
import { SelectAvatar } from '../../../../common/components/selectAvatar/SelectAvatar'

const initialValues = {
  name: '',
  email: '',
  phone: '',
  position: '',
}

export const ProfileUser = ({ avatar, employee, handleChangeAvatar, onSubmit }) => {
  const { values, setValues, errors, handleBlur, touched, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema: profileUserSchema,
    onSubmit,
  })

  useEffect(() => {
    setValues({
      name: employee.name || '',
      email: employee.email || '',
      phone: employee.phone || '',
      position: employee.position || '',
    })
  }, [employee])

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <GridItem gridArea="description" marginBottom={4}>
          <Text sizeText="display18" color="black" fontWeight="bold" marginBottom={2}>
            Tus datos
          </Text>
          <Text sizeText="display14" color="gray">
            Estos datos servirán para poder acceder en la plataforma y nos ayudarán para poder
            contactarte.
          </Text>
        </GridItem>
        <GridItem gridArea="fields">
          <Box>
            <Text sizeText="display16" color="black" fontWeight="600" marginBottom={3}>
              Foto de perfil
            </Text>
            <SelectAvatar
              avatar={avatar?.base64}
              handleChangeAvatar={handleChangeAvatar}
              description="Cambiar foto de perfil"
            />
          </Box>
          <InputContainer>
            <Box>
              <Field
                label="Nombre"
                required
                sizeText="display16"
                marginBottom={3}
                errorMessage={touched.name && errors.name}>
                <Input
                  type="text"
                  placeholderMessage="Escribe el nombre de la compañia"
                  ariaLabel="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.name && !!errors.name}
                />
              </Field>
            </Box>
            <Box>
              <Field
                label="Teléfono"
                required
                sizeText="display16"
                marginBottom={3}
                errorMessage={touched.phone && errors.phone}>
                <Input
                  type="tel"
                  placeholderMessage="Escribe tu teléfono "
                  ariaLabel="phone"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.phone && !!errors.phone}
                />
              </Field>
            </Box>
          </InputContainer>
          <InputContainer>
            <Box>
              <Field
                label="Email"
                required
                sizeText="display16"
                marginBottom={3}
                errorMessage={touched.email && errors.email}>
                <Input
                  disabled
                  type="email"
                  placeholderMessage="Escribe tu email"
                  ariaLabel="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.email && !!errors.email}
                />
              </Field>
            </Box>
            <Box>
              <Field
                label="Cargo"
                sizeText="display16"
                marginBottom={3}
                errorMessage={touched.position && errors.position}>
                <Input
                  type="text"
                  placeholderMessage="Escribe el cargo"
                  ariaLabel="position"
                  name="position"
                  value={values.position}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.position && !!errors.position}
                />
              </Field>
            </Box>
          </InputContainer>
        </GridItem>
      </FormContainer>
      <ActionContainerStyled borderTopColor="gray3" style={{ zIndex: 2 }}>
        <ActionButton type="submit" colorType="orange" sizeButton="medium">
          Guardar
        </ActionButton>
      </ActionContainerStyled>
    </form>
  )
}
