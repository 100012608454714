import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { LinkText, Text } from '@proveoeng/uikit/dist/atoms/Typography'
import LinkIcon from '@proveoeng/uikit/dist/atoms/Icons/Link'

export const Links = ({ links = [] }) => (
  <Flex marginBottom={3} flexWrap="wrap">
    {links?.map((link, key) => (
      <Box marginTop={5} width="100%" key={key}>
        <Flex>
          <Box marginRight={2}>
            <Icon color="gray2" sizeIcon="display12">
              <LinkIcon />
            </Icon>
          </Box>
          <LinkText
            href={link.url}
            title={link.url}
            target="_blank"
            sizeText="display16"
            color="blue1"
            display="inline-block"
            overflow="hidden"
            maxWidth="500px"
            style={{
              clear: 'both',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}>
            {link.url}
          </LinkText>
        </Flex>
        {link.description && (
          <Flex marginTop={1} flexDirection="column">
            <Text sizeText="display14" fontWeight="normal" paddingLeft="22px">
              Descripción
            </Text>
            <Text
              sizeText="display14"
              paddingLeft="22px"
              style={{
                whiteSpace: 'pre-wrap',
              }}>
              {link.description}
            </Text>
          </Flex>
        )}
      </Box>
    ))}
  </Flex>
)
