import { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Pdf, Xls } from '@proveoeng/uikit/dist/atoms/Icons'
import { isProduction } from '../../../../lib/utils/isProduction'
import { useComparative } from '../../../common/hooks/useComparative'
import { ComparativeData } from '../../../common/domain/comparativeData/comparativeData'
import { ComparatorTemplate } from '../template/ComparatorTemplate'

const ComparatorPage = () => {
  const history = useHistory()
  const location = useLocation()
  const { hash: comparisonId, bidPackageId, snapshotId, projectId } = useParams()

  const {
    data: { comparativeData },
    getComparativeData,
    saveComparative,
    saveAndExport,
    saveAndDownload,
    getSavedComparative,
    actions: {
      addItem,
      setItem,
      setOfferItem,
      addSection,
      editSection,
      removeItems,
      removeSection,
      updateImprovementRate,
    },
  } = useComparative()
  const [comparative, setComparative] = useState()
  const [offerColumns, setOfferColumns] = useState('')
  const [addSectionModalIsOpened, setAddSectionModalIsOpened] = useState(false)
  const [saveComparatorModalIsOpened, setSaveComparatorModalIsOpened] = useState(false)
  const [exportModalIsOpened, setExportModalIsOpened] = useState(false)
  const [removeSectionModalIsOpened, setRemoveSectionModalIsOpened] = useState({
    show: false,
    id: '',
  })
  const [editSectionModalIsOpened, setEditSectionModalIsOpened] = useState({
    show: false,
    id: '',
    name: '',
  })
  const [firstLoad, setFirstLoad] = useState(false)

  const [activeTab, setActiveTab] = useState('company')

  const exportActions = [
    {
      id: 'xls',
      content: (
        <Flex alignItems="center" paddingTop={4}>
          <Icon>
            <Xls />
          </Icon>
          <Text sizeText="display14" color="black">
            Formato .xls
          </Text>
        </Flex>
      ),
      action: () => saveAndDownload(activeTab),
    },
  ]

  if (activeTab === 0) {
    exportActions.push({
      id: 'integration',
      content: (
        <Text sizeText="display14" color="black" paddingY={4}>
          Fichero de integración
        </Text>
      ),
      action: () => setExportModalIsOpened(true),
    })
  }

  if (!isProduction() && activeTab === 0) {
    exportActions.unshift({
      id: 'pdf',
      content: (
        <Flex alignItems="center" paddingTop={4}>
          <Icon>
            <Pdf />
          </Icon>
          <Text sizeText="display14" color="black">
            Formato .pdf
          </Text>
        </Flex>
      ),
      action: () => undefined,
    })
  }
  const handleEditSection = ({ title }) => {
    editSection({
      id: editSectionModalIsOpened.id,
      title,
    })
    setEditSectionModalIsOpened({ show: false, id: '', name: '' })
  }

  const handleRemoveSection = () => {
    removeSection(removeSectionModalIsOpened.id)
    setRemoveSectionModalIsOpened({ show: false, id: '' })
  }

  const onSubmitSaveCompative = async (values, formikHelpers) => {
    await saveComparative(values, formikHelpers)
    setSaveComparatorModalIsOpened(false)
  }

  const closeRemoveSection = () => setRemoveSectionModalIsOpened({ show: false, id: '' })

  const setTableWidthCSS = (fixedWidth) => {
    const offersWidth = comparativeData?.offers?.length * 220
    const width = fixedWidth + offersWidth

    return `${width}px`
  }

  const goBackLink = () => {
    history.push(`/constructora/tus-obras/`)
  }

  useEffect(() => {
    const getData = async () => {
      let getComparativeResponse = null

      if (comparisonId) {
        getComparativeResponse = await getSavedComparative(bidPackageId, comparisonId, snapshotId)
      } else {
        if (!location?.state?.proposalIds) goBackLink()

        getComparativeResponse = await getComparativeData(
          bidPackageId,
          location?.state?.proposalIds,
        )
      }
      setFirstLoad(true)
      if (!getComparativeResponse) goBackLink()
    }
    getData()
  }, [])

  useEffect(() => {
    if (comparativeData) {
      setComparative(
        ComparativeData.create({
          title: comparativeData.title,
          sections: comparativeData.sections,
          defaultSection: comparativeData.defaultSection,
          offers: comparativeData.offers,
          retention: comparativeData.retention,
          retentionCriteria: comparativeData.retentionCriteria,
          improvementRate: comparativeData.improvementRate,
          kZero: comparativeData.kZero,
          bidPackageName: comparativeData.bidPackageName,
          projectName: comparativeData.project,
          paymentDays: comparativeData.paymentDays,
        }),
      )
      setOfferColumns(
        comparativeData.offers
          .map((offer) => `offer-unit-${offer.id} offer-total-${offer.id}`)
          .join(' '),
      )
    }
  }, [comparativeData])

  useEffect(() => {
    setActiveTab(0)
  }, [])

  const tabItems = [
    {
      id: 'prices',
      children: <>Precios</>,
      isActive: activeTab === 0,
      action: () => setActiveTab(0),
    },
    {
      id: 'environmentalCosts',
      children: <>Costes ambientales</>,
      isActive: activeTab === 1,
      action: () => setActiveTab(1),
    },
  ]

  if (!firstLoad) return <></>

  return (
    <ComparatorTemplate
      saveAndExport={saveAndExport}
      handleEditSection={handleEditSection}
      projectId={projectId}
      addItem={addItem}
      setItem={setItem}
      setOfferItem={setOfferItem}
      addSection={addSection}
      removeItems={removeItems}
      updateImprovementRate={updateImprovementRate}
      comparative={comparative}
      offerColumns={offerColumns}
      addSectionModalIsOpened={addSectionModalIsOpened}
      setAddSectionModalIsOpened={setAddSectionModalIsOpened}
      saveComparatorModalIsOpened={saveComparatorModalIsOpened}
      exportModalIsOpened={exportModalIsOpened}
      handleRemoveSection={handleRemoveSection}
      onSubmitSaveCompative={onSubmitSaveCompative}
      closeRemoveSection={closeRemoveSection}
      setTableWidthCSS={setTableWidthCSS}
      setSaveComparatorModalIsOpened={setSaveComparatorModalIsOpened}
      exportActions={exportActions}
      setEditSectionModalIsOpened={setEditSectionModalIsOpened}
      setRemoveSectionModalIsOpened={setRemoveSectionModalIsOpened}
      editSectionModalIsOpened={editSectionModalIsOpened}
      removeSectionModalIsOpened={removeSectionModalIsOpened}
      setExportModalIsOpened={setExportModalIsOpened}
      history={history}
      comparativeData={comparativeData}
      tabItems={tabItems}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  )
}

export default ComparatorPage
