import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'
import { Box, Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Avatar } from '@proveoeng/uikit/dist/atoms/Avatar'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Radiobox } from '@proveoeng/uikit/dist/atoms/Radiobox'
import Search from '@proveoeng/uikit/dist/atoms/Icons/Search'
import { Loader } from '@proveoeng/uikit/dist/atoms/Loader'

const initialValues = {
  projectId: '',
}

export const ModalDuplicateProject = ({ isOpened, onClose, projects, onSubmitDuplication }) => {
  const [listProjects, setListProjects] = useState()
  const [projectsFiltered, setProjectsFiltered] = useState([])

  useEffect(() => {
    if (projects) {
      setProjectsMapped(projects)
    }
  }, [projects])

  const onSubmit = async (values, formikHelper) => {
    await onSubmitDuplication(values?.projectId)
    formikHelper.setSubmitting(false)
    onClose()
  }

  const { values, setFieldValue, submitForm, isSubmitting, resetForm } = useFormik({
    initialValues,
    onSubmit,
  })

  const onClear = () => {
    resetForm()
    onClose(false)
  }

  const setProjectsMapped = (projectsReceived) => {
    const projectMapped = projectsReceived?.map((item) => ({
      id: item.projectId,
      name: 'duplicate',
      nameLabel: item.name,
      defaultChecked: values?.projectId === item.projectId,
      isDisabled: false,
      hasError: false,
      label: (
        <Flex alignItems="center" marginY={2}>
          <Avatar src={item.thumb} marginRight={2} borderRadius={2} placeholder={item.name} />{' '}
          {item.name}
        </Flex>
      ),
      defaultValue: item.projectId,
    }))
    setListProjects(projectMapped)
  }

  return (
    <Modal
      isOpen={isOpened}
      id="ask-reminders-modal"
      onModalClose={onClear}
      title="Duplicar una obra"
      maxWidth="808px">
      <Modal.Content>
        {isSubmitting && <Loader />}
        <Box margin="0 auto">
          <Input
            action={(name, value) => {
              setProjectsFiltered(
                listProjects.filter((el) =>
                  el.nameLabel?.toUpperCase().includes(value.toUpperCase()),
                ),
              )
            }}
            type="search"
            placeholderMessage="Buscar una obra"
            iconLeft={
              <Icon sizeIcon="display18" color="gray3">
                <Search />
              </Icon>
            }
          />
          <Box paddingTop={5}>
            {!!listProjects && (
              <Radiobox
                onChange={(_, value) => setFieldValue('projectId', value)}
                items={(projectsFiltered?.length && projectsFiltered) || listProjects}
              />
            )}
          </Box>
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Grid gridTemplateColumns="1fr 1fr" gridTemplateRows="1fr" gridColumnGap={5}>
            <Button id="cancel-button" colorType="transparent" action={onClear}>
              Cancelar
            </Button>
            <Button
              colorType="orange"
              disabled={
                !!listProjects && !listProjects?.filter((item) => item?.defaultChecked).length
              }
              action={submitForm}>
              Duplicar
            </Button>
          </Grid>
        </Flex>
      </Modal.Actions>
    </Modal>
  )
}
