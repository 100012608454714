import { v4 as uuidv4 } from 'uuid'
import { FormatDate } from '../../mappers/FormatDate'
import { BidItem } from '../bidItem/bidItem'
import { Offer } from '../offer/offer'
import { Section } from '../section/section'

export class ComparativeData {
  constructor(
    title,
    sections,
    defaultSection,
    offers,
    retention,
    retentionCriteria,
    improvementRate,
    kZero,
    paymentDays,
  ) {
    this.title = title
    this.sections = sections
    this.defaultSection = defaultSection
    this.offers = offers
    this.retention = retention
    this.retentionCriteria = retentionCriteria
    this.improvementRate = improvementRate
    this.kZero = kZero
    this.paymentDays = paymentDays
  }

  static create({
    title,
    sections,
    defaultSection,
    offers,
    retention,
    retentionCriteria,
    improvementRate,
    kZero,
    paymentDays,
  }) {
    const offersParsed = offers.map(
      ({
        id,
        title: name,
        position,
        items: offerItems,
        proposal,
        proposalInfo,
        paymentDays,
        paymentConditions,
        retention: retentionOffer,
        retentionCriteria: retentionCriteriaOffer,
        startDate,
        endDate,
        discount,
        enviroData,
      }) =>
        Offer.create({
          id,
          name,
          position,
          proposalId: proposal,
          offerItems,
          proposalInfo,
          paymentDays,
          paymentConditions,
          retention: retentionOffer,
          retentionCriteria: retentionCriteriaOffer,
          startDate: FormatDate.hydrate(startDate),
          endDate: FormatDate.hydrate(endDate),
          discount,
          enviroData,
        }),
    )

    const sectionsParsed = sections.map((section) => {
      const bidItems = []
      section.items.forEach((item) => {
        const bidItem = BidItem.create({
          id: item.id,
          description: item.description,
          measure: item.measure,
          unit: item.unit,
          budgetCost: item.budgetCost || 0,
          expectedCost: item.expectedCost || 0,
          selected: item.selected,
          minPrice: item.minPrice || 0,
          position: item.position,
          enviroData: item.enviroData,
        })

        bidItems.push(bidItem)
      })
      return Section.create({ id: section.id, name: section.title, bidItems })
    })
    return new ComparativeData(
      title,
      sectionsParsed,
      defaultSection,
      offersParsed,
      retention,
      retentionCriteria,
      improvementRate,
      kZero,
      paymentDays,
    )
  }

  getTotalBudgetCost() {
    return this.sections?.reduce(
      (prevItem, currentItem) => prevItem + currentItem.getTotalBudgetCost(),
      0,
    )
  }

  getTotalExpectedCost() {
    return this.sections?.reduce(
      (prevItem, currentItem) => prevItem + currentItem.getTotalExpectedCost(),
      0,
    )
  }

  getTotalCeco2() {
    return this.sections?.reduce(
      (prevItem, currentItem) => prevItem + currentItem.getTotalCeco2(),
      0,
    )
  }

  getTotalOfferCost(offerId) {
    return this.sections?.reduce(
      (prevItem, currentItem) => prevItem + currentItem.getTotalOfferCost(offerId),
      0,
    )
  }

  addSection({ name }) {
    const newSection = Section.create({ id: uuidv4(), name, bidItems: [] })
    this.sections.push(newSection)
  }

  editSection({ id, name }) {
    this.sections.find((section) => section.id === id).name = name
  }

  removeSection(id) {
    this.sections = this.sections.filter((section) => section.id !== id)
  }

  getTotalExpectedCostK() {
    return (this.getTotalBudgetCost() / this.getTotalExpectedCost()).toFixed(2)
  }

  getTotalExpectedCostDiff() {
    return this.getTotalBudgetCost() - this.getTotalExpectedCost()
  }

  getTotalMinEnvelope() {
    return this.sections?.reduce(
      (prevItem, currentItem) => prevItem + currentItem.getTotalMinEnvelope(this.offers),
      0,
    )
  }

  getTotalMinEnvelopeK() {
    return (this.getTotalBudgetCost() / (this.getTotalMinEnvelope() / 100)).toFixed(2)
  }

  getTotalMinEnvelopeDiff() {
    return this.getTotalBudgetCost() - this.getTotalMinEnvelope() / 100
  }
}
