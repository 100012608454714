import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { BidItemListTemplate } from '../template/BidItemListTemplate'

import { todayDate } from '../../../../../../lib/utils/getTodayDateFormatted'

import { onImagesLoad } from '../../../../../common/functions/data'

import { useForms } from '../../../../../common/hooks/forms'
import { useAttachmentLinks } from '../../../../../common/hooks/useAttachmentLinks'
import { useBidPackage } from '../../../../../common/hooks/useBidPackage'
import { useUtils } from '../../../../../common/hooks/useUtils'

import { errorsImages } from '../../../config'
import { useHelpers } from '../../../../../common/hooks/useHelpers'
import { useProject } from '../../../../../common/hooks/useProject'
import { parseToInteger } from '../../../../../../lib/utils/Format'

const BidItemListPage = ({ project, bidPackage, bidItems, getAllBidItems }) => {
  const { bidPackageId } = useParams()

  const [formCreateBidItemIsOpened, setFormCreateBidItemIsOpened] = useState(false)
  const [formEditBidItemIsOpened, setFormEditBidItemIsOpened] = useState(false)
  const [errorsData, setErrorsData] = useState({ init: true })
  const [files, setFiles] = useState([])
  const [deleteFiles, setDeleteFiles] = useState([])

  const [createdBidItem, setCreatedBidItem] = useState(false)

  const [previewModalIsOpened, setPreviewModalIsOpened] = useState(false)
  const [importModalIsOpened, setImportModalIsOpened] = useState(false)
  const [previewData, setPreviewData] = useState({})

  const {
    data: {
      categories: { data: categories },
    },
    getCategories,
  } = useHelpers()

  const {
    data: { bidItem },
    bidItems: {
      createBidItem,
      deleteBidItem,
      getBidItem,
      actionBidItem,
      updateBidItem,
      deleteFilesBidItem,
      deleteLinksBidItem,
      setFilesBidItem,
      setLinksBidItem,
      createBidItems,
    },
  } = useBidPackage()

  const { setPreviewBidPackages } = useProject()

  const { setAlert } = useUtils()

  const {
    links,
    inputLink,
    setLinks,
    setInputLink,
    addLink,
    handleAddDescription,
    removeLink,
    deleteLinksId,
    verifyUrl,
    setDeleteLinksId,
  } = useAttachmentLinks([])

  useEffect(() => {
    setLinks(bidItem?.links || [])
    setDeleteLinksId([])
  }, [formCreateBidItemIsOpened, formEditBidItemIsOpened, bidItem])

  const { values, handleChange } = useForms()

  useEffect(() => {
    if (bidPackageId) {
      getAllBidItems(bidPackageId)
    }
  }, [bidPackageId])

  useEffect(() => {
    if (!formCreateBidItemIsOpened) {
      handleChange()
    } else {
      setFiles([])
    }
  }, [formCreateBidItemIsOpened, formEditBidItemIsOpened])

  useEffect(() => {
    if (formEditBidItemIsOpened) {
      setFiles(bidItem?.files)
    }
  }, [formEditBidItemIsOpened, bidItem])

  useEffect(() => {
    if (!formCreateBidItemIsOpened) {
      setCreatedBidItem(false)
    }
  }, [formCreateBidItemIsOpened])

  useEffect(() => {
    const loadCategoryData = async () => {
      await getCategories()
    }
    loadCategoryData()
  }, [])

  const onValidate = () => {
    if (!values) return false
    const formValidation = ['name', 'measurement', 'unit']
    if (errorsData.init) {
      setErrorsData({})
    }
    let errors = {}

    if (values?.purchasePlanEndsAt) formValidation.push('purchasePlanEndsAt')

    formValidation.map((key) => {
      if (values[key]?.length || values[key] === true) {
        if (key === 'bidEndsAt' && values.bidEndsAt < todayDate) {
          setAlert('error', 'La fecha fin de oferta no puede ser anterior al día de hoy.')

          setErrorsData((prev) => ({
            ...prev,
            bidEndsAt: true,
          }))
          errors = {
            ...errors,
            bidEndsAt: true,
          }
        } else if (
          key === 'purchasePlanEndsAt' &&
          values.purchasePlanEndsAt &&
          values.bidEndsAt > values.purchasePlanEndsAt
        ) {
          setAlert(
            'error',
            'La fecha límite selección debe ser posterior a la fecha de fin de oferta.',
          )

          setErrorsData((prev) => ({
            ...prev,
            bidEndsAt: true,
            purchasePlanEndsAt: true,
          }))
          errors = {
            ...errors,
            bidEndsAt: true,
            purchasePlanEndsAt: true,
          }
        } else {
          setErrorsData((prev) => ({
            ...prev,
            [key]: false,
          }))
          errors = {
            ...errors,
            [key]: false,
          }
        }
      } else {
        setErrorsData((prev) => ({
          ...prev,
          [key]: true,
        }))

        errors = {
          ...errors,
          [key]: true,
        }
      }
      return false
    })

    if (Object.values(errors)?.includes(true)) return false

    return true
  }

  const actionBidItemAPI = (itemId, actionText) => actionBidItem(bidPackageId, itemId, actionText)

  const onEditBidItem = async (id) => {
    setFormCreateBidItemIsOpened(false)
    await getBidItem(bidPackageId, id)
    setFormEditBidItemIsOpened(true)
  }

  const onDeleteFile = (file) => {
    if (file?.fileId) {
      const updateFiles = files.filter((element) => element.fileId !== file.fileId)
      setFiles(updateFiles)
      setDeleteFiles((prev) => [...prev, file])
    } else {
      const updateFiles = files.filter((element) => element.name !== file.name)
      setFiles(updateFiles)
      setDeleteFiles((prev) => [...prev, file])
    }
  }

  const onDeleteBidItem = async (bidItemElement) => {
    await deleteBidItem(bidPackageId, bidItemElement)
    await getAllBidItems(bidPackageId)
  }

  const handleAttachmentsBidItem = async () => {
    await setFilesBidItem(bidPackageId, bidItem.bidItemId, files)
    await setLinksBidItem(bidPackageId, bidItem.bidItemId, links)
    await deleteFilesBidItem(bidPackageId, bidItem.bidItemId, deleteFiles)
    await deleteLinksBidItem(bidPackageId, bidItem.bidItemId, deleteLinksId)
    await getAllBidItems(bidPackageId)
  }

  const onSubmitFiles = async () => {
    await handleAttachmentsBidItem()
    setDeleteLinksId([])
    setFiles([])
    setDeleteFiles([])
  }

  const onSubmit = async () => {
    if (values) {
      if (!onValidate()) {
        setAlert('error', 'Por favor, complete todos los campos requeridos')
        return
      }
      if (formEditBidItemIsOpened) {
        await updateBidItem(bidPackageId, bidItem.bidItemId, values)
        await getAllBidItems(bidPackageId)
      }
      if (formCreateBidItemIsOpened && !createdBidItem) {
        await createBidItem(bidPackageId, values)
        setAlert('success', '¡Felicidades! Nueva partida creada')
        await getAllBidItems(bidPackageId)
        setCreatedBidItem(true)
        return
      }
    }
    onSubmitFiles()
    if (formEditBidItemIsOpened) setFormEditBidItemIsOpened(false)
  }

  const openUploadFiles = () => {
    setFiles([])
    setImportModalIsOpened(true)
  }

  const importFromPreview = async (file) => {
    const data = await setPreviewBidPackages(project.projectId, file)
    if (data) {
      setPreviewData(data)
      setPreviewModalIsOpened(true)
    }
  }

  const onSubmitImport = async () => {
    const file = [files.at(-1)]
    const extension = `${file[0].name.split('.').pop()}`
    if (extension === 'bc3') {
      await importFromPreview(file)
      return
    }

    setAlert('error', 'Tipo de documento no soportado')
  }

  /**
   *
   * @param {Object[]} bidItemsToSave
   */
  const onSubmitPreview = async (bidItemsToSave) => {
    setPreviewModalIsOpened(false)
    const body = bidItemsToSave.map((item) => ({
      name: item.summary,
      description: item.description,
      unit: item.unit,
      budget: item.priceCi,
      expectedCosts: item.price,
      measurement: item.measurement,
      eCo2: item.eCo2,
    }))
    await createBidItems(bidPackageId, body)
    await getAllBidItems(bidPackageId)
  }

  const totalCosts = bidItems?.reduce(
    (accumulator, currentValue) => {
      const totalBudget =
        accumulator.budget +
        ((parseToInteger(currentValue.budget) / 100) *
          parseToInteger(currentValue.measurement, 3)) /
          1000

      const totalExpectedCosts =
        accumulator.expectedCosts +
        ((parseToInteger(currentValue.expectedCosts) / 100) *
          parseToInteger(currentValue.measurement, 3)) /
          1000

      return { budget: totalBudget, expectedCosts: totalExpectedCosts }
    },
    { budget: 0, expectedCosts: 0 },
  )

  return (
    <BidItemListTemplate
      bidPackage={bidPackage}
      setData={handleChange}
      setFormCreateBidItemIsOpened={setFormCreateBidItemIsOpened}
      formEditBidItemIsOpened={formEditBidItemIsOpened}
      setFormEditBidItemIsOpened={setFormEditBidItemIsOpened}
      formCreateBidItemIsOpened={formCreateBidItemIsOpened}
      bidItem={bidItem || values}
      onSubmit={onSubmit}
      errorsData={errorsData}
      onDeleteBidItem={onDeleteBidItem}
      onDeleteFile={onDeleteFile}
      onSubmitFiles={onSubmitFiles}
      errorsFiles={errorsImages}
      onFilesLoad={(imgs) => onImagesLoad(imgs, setFiles, files)}
      files={files}
      createdBidItem={createdBidItem}
      onEditBidItem={onEditBidItem}
      onActionBidItem={actionBidItemAPI}
      links={links}
      addLink={addLink}
      removeLink={removeLink}
      inputLink={inputLink}
      setInputLink={setInputLink}
      handleAddDescription={handleAddDescription}
      verifyUrl={verifyUrl}
      setCreatedBidItem={setCreatedBidItem}
      bidItems={bidItems}
      importModalIsOpened={importModalIsOpened}
      setImportModalIsOpened={setImportModalIsOpened}
      onSubmitImport={onSubmitImport}
      previewModalIsOpened={previewModalIsOpened}
      setPreviewModalIsOpened={setPreviewModalIsOpened}
      categories={categories}
      onSubmitPreview={onSubmitPreview}
      previewData={previewData}
      openUploadFiles={openUploadFiles}
      totalCosts={totalCosts}
    />
  )
}

export default BidItemListPage
