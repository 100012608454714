import { useState } from 'react'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { ArrowDown, ArrowRight } from '@proveoeng/uikit/dist/atoms/Icons'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { DetailsModal } from './DetailsModal'
import { useComparative } from '../../../common/hooks/useComparative'
import { EnvironmentalCostsSectionBidItem } from './EnvironmentalCostsSectionBidItem'
import { EnvironmentalCostsSectionHead } from './EnvironmentalCostsSectionHead'
import { EnvironmentalCostsProductionPhaseTotal } from './EnvironmentalCostsProductionPhaseTotal'
import { parseToInteger, parseToString } from '../../../../lib/utils/Format'

export const EnvironmentalCostsSection = ({
  data,
  setItem,
  setOfferItem,
  offers,
  tableWidthCSS,
}) => {
  const [isOpened, setIsOpened] = useState(true)
  const [isBidItemDetailsModalOpened, setIsBidItemDetailsModalOpened] = useState({
    show: false,
    data: '',
  })
  const { getBidItemOfferDetailsEnviro } = useComparative()
  const [itemsChecked, setItemsChecked] = useState([])
  const offerColumnsHead = offers
    .sort((a, b) => (a.position > b.position ? 1 : -1))
    .map((offer) => `offer-${offer.id}`)
    .join(' ')

  const columnsHead = {
    area: `"bidItem unit measure ceco2 ${offerColumnsHead}"`,
    columns: `488px 88px 88px 104px repeat(${offers.length}, 220px)`,
  }

  const offerColumns = offers
    .sort((a, b) => (a.position > b.position ? 1 : -1))
    .map((offer) => `offer-unit-${offer.id} offer-total-${offer.id}`)
    .join(' ')

  const totalColumns = offers
    .sort((a, b) => (a.position > b.position ? 1 : -1))
    .map((offer) => `total-${offer.id}`)
    .join(' ')

  const columns = {
    area: `"bidItem unit measure ceco2 ${offerColumns}"`,
    columns: `488px 88px 88px 104px repeat(${offers.length * 2}, 90px 130px)`,
  }

  const handleSelectedBidItems = (id, value) => {
    if (value) {
      setItemsChecked((prev) => [...prev, id])
    } else {
      setItemsChecked((prev) => prev.filter((item) => item !== id))
    }
  }

  const getBidItemOfferDetailsRequest = async (offer, offerItem) => {
    const { proposalId } = offer
    const proposalItem = offer?.offerItems.find((itemOffer) => itemOffer.itemId === offerItem.id)

    const bidItemOfferDetailsData = await getBidItemOfferDetailsEnviro(
      proposalId,
      proposalItem.proposalItem,
    )

    setIsBidItemDetailsModalOpened({ show: true, data: bidItemOfferDetailsData })
  }

  const BidItemsSections = () =>
    data?.bidItems?.map((item) => (
      <EnvironmentalCostsSectionBidItem
        item={item}
        setItem={setItem}
        setOfferItem={setOfferItem}
        handleSelectedBidItems={handleSelectedBidItems}
        itemsChecked={itemsChecked}
        offers={offers}
        columns={columns}
        getBidItemOfferDetailsRequest={getBidItemOfferDetailsRequest}
      />
    ))

  const getTotal = (offer) => {
    let total = 0
    data?.bidItems?.forEach((bidItem) => {
      offer?.offerItems?.forEach((offerItem) => {
        if (bidItem.id === offerItem.itemId) {
          total +=
            parseToInteger(offerItem?.enviroData?.eCo2) * (parseToInteger(bidItem.measure) / 1000)
        }
      })
    })
    return total ? `${parseToString(total)} kg` : '-'
  }

  return (
    <Flex
      paddingBottom={6}
      flexDirection="column"
      position="relative"
      style={{ width: tableWidthCSS }}>
      <Flex
        justifyContent="space-between"
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 11,
          backgroundColor: 'white',
        }}
        paddingBottom={4}>
        <Flex
          alignItems="center"
          paddingX={3}
          style={{
            position: 'sticky',
            left: 0,
            zIndex: 9,
          }}>
          <Icon
            style={{ cursor: 'pointer' }}
            sizeIcon="display18"
            color="black"
            marginRight={1}
            onClick={() => setIsOpened(!isOpened)}>
            {isOpened ? <ArrowDown /> : <ArrowRight />}
          </Icon>
          <Flex alignItems="center">
            <Text
              as="span"
              marginLeft={1}
              sizeText="display18"
              color="black"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setIsOpened(!isOpened)
                setItemsChecked([])
              }}>
              Fase de producción
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <DetailsModal
        isOpen={isBidItemDetailsModalOpened.show}
        onClose={() => setIsBidItemDetailsModalOpened({ show: false, data: '' })}
        data={isBidItemDetailsModalOpened.data}
      />

      {isOpened && (
        <>
          <EnvironmentalCostsSectionHead
            offers={offers}
            columns={columns}
            columnsHead={columnsHead}
          />
          {BidItemsSections()}
          <EnvironmentalCostsProductionPhaseTotal
            offers={offers}
            totalColumns={totalColumns}
            width={tableWidthCSS}
            getTotal={getTotal}
          />
        </>
      )}
    </Flex>
  )
}
