import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { BidPackageInfoTemplate } from '../template/BidPackageInfoTemplate'

import { todayDate } from '../../../../../../lib/utils/getTodayDateFormatted'

import { onImagesLoad } from '../../../../../common/functions/data'
import { useForms } from '../../../../../common/hooks/forms'
import { useAttachmentLinks } from '../../../../../common/hooks/useAttachmentLinks'
import { useBidPackage } from '../../../../../common/hooks/useBidPackage'
import { useHelpers } from '../../../../../common/hooks/useHelpers'
import { useUtils } from '../../../../../common/hooks/useUtils'

import { errorsImages } from '../../../config'

const BidPackageInfoPage = ({ bidPackage, getBidPackage }) => {
  const history = useHistory()
  const { hash: projectId, bidPackageId } = useParams()

  const [formEditBidpackageIsOpened, setFormEditBidpackageIsOpenedOpened] = useState(false)
  const [errorsData, setErrorsData] = useState({ init: true })
  const [images, setImages] = useState([])
  const [deleteFiles, setDeleteFiles] = useState([])
  const [deleteModalIsOpened, setDeleteModalIsOpened] = useState(false)
  const [cancelNotificationIsOpen, setCancelNotificationIsOpen] = useState(false)

  const {
    updateBidPackage,
    deleteFilesBidPackage,
    deleteLinksBidPackage,
    setFilesBidPackage,
    setLinksBidPackage,
    deleteBidPackage,
    actionBidPackage,
  } = useBidPackage()

  const {
    data: { categories },
    getCategories,
  } = useHelpers()

  const { setAlert } = useUtils()

  const { values, handleChange } = useForms()

  const {
    links,
    inputLink,
    setLinks,
    setInputLink,
    addLink,
    handleAddDescription,
    removeLink,
    deleteLinksId,
    verifyUrl,
    setDeleteLinksId,
  } = useAttachmentLinks([])

  useEffect(() => {
    setLinks(bidPackage?.links || [])
    setDeleteLinksId([])
  }, [formEditBidpackageIsOpened, bidPackage])

  useEffect(() => {
    getCategories()
  }, [])

  const categoriesFormatted = categories.data.map((el) => ({
    value: el?.categoryId,
    label: el?.name,
  }))

  useEffect(() => {
    if (bidPackage?.files?.length) {
      setImages(bidPackage?.files)
    }
  }, [bidPackage?.files])

  useEffect(() => {
    handleChange()
    setImages([])
  }, [formEditBidpackageIsOpened])

  const onValidate = () => {
    if (!values) return false
    const formValidation = ['name', 'categoryId', 'bidEndsAt', 'paymentDays']
    if (errorsData.init) {
      setErrorsData({})
    }
    let errors = {}

    if (values?.purchasePlanEndsAt) formValidation.push('purchasePlanEndsAt')

    formValidation.map((key) => {
      if (values[key]?.length || values[key] === true) {
        if (key === 'bidEndsAt' && values.bidEndsAt < todayDate) {
          setAlert('error', 'La fecha fin de oferta no puede ser anterior al día de hoy.')

          setErrorsData((prev) => ({
            ...prev,
            bidEndsAt: true,
          }))
          errors = {
            ...errors,
            bidEndsAt: true,
          }
        } else if (
          key === 'purchasePlanEndsAt' &&
          values.purchasePlanEndsAt &&
          values.bidEndsAt > values.purchasePlanEndsAt
        ) {
          setAlert(
            'error',
            'La fecha límite selección debe ser posterior a la fecha de fin de oferta.',
          )

          setErrorsData((prev) => ({
            ...prev,
            bidEndsAt: true,
            purchasePlanEndsAt: true,
          }))
          errors = {
            ...errors,
            bidEndsAt: true,
            purchasePlanEndsAt: true,
          }
        } else {
          setErrorsData((prev) => ({
            ...prev,
            [key]: false,
          }))
          errors = {
            ...errors,
            [key]: false,
          }
        }
      } else {
        setErrorsData((prev) => ({
          ...prev,
          [key]: true,
        }))

        errors = {
          ...errors,
          [key]: true,
        }
      }
      return false
    })

    if (Object.values(errors)?.includes(true)) return false

    return true
  }

  const cancelBidPackage = async (actionData) => {
    setCancelNotificationIsOpen(false)
    await actionBidPackage(bidPackageId, 'Cancel', actionData)
    await getBidPackage(bidPackageId)
  }

  const copyBidPackage = async () => {
    await actionBidPackage(bidPackageId, 'Copy')
    history.push(`/constructora/tus-obras/${projectId}`)
  }

  const onEditBidPackage = () => setImages(bidPackage?.files)

  const onDeleteFile = (file) => {
    if (file?.fileId) {
      const updateFiles = images.filter((element) => element.fileId !== file.fileId)
      setImages(updateFiles)
      setDeleteFiles((prev) => [...prev, file])
    } else {
      const updateFiles = images.filter((element) => element.name !== file.name)
      setImages(updateFiles)
      setDeleteFiles((prev) => [...prev, file])
    }
  }

  const onDeleteBidPackage = async () => {
    setDeleteModalIsOpened(false)
    await deleteBidPackage(bidPackageId)
    history.goBack()
  }

  const handleAttachmentsBidPackage = async () => {
    await setFilesBidPackage(bidPackageId, images)
    await setLinksBidPackage(bidPackageId, links)
    await deleteFilesBidPackage(bidPackageId, deleteFiles)
    await deleteLinksBidPackage(bidPackageId, deleteLinksId)
    await getBidPackage(bidPackageId)
  }

  const onSubmitFiles = async () => {
    await handleAttachmentsBidPackage()
    setDeleteLinksId([])
    setImages([])
    setDeleteFiles([])
  }

  const onSubmit = async () => {
    if (values) {
      if (!onValidate()) {
        setAlert('error', 'Por favor, complete todos los campos requeridos')
        return
      }
      await updateBidPackage(bidPackageId, values)
    }
    onSubmitFiles()
    setFormEditBidpackageIsOpenedOpened(false)
    getBidPackage(bidPackageId)
  }

  return (
    <BidPackageInfoTemplate
      bidPackage={bidPackage}
      formIsOpened={formEditBidpackageIsOpened}
      setFormIsOpened={setFormEditBidpackageIsOpenedOpened}
      setData={handleChange}
      setDeleteModalIsOpened={setDeleteModalIsOpened}
      onSubmit={onSubmit}
      errorsData={errorsData}
      categories={categoriesFormatted}
      deleteModalIsOpened={deleteModalIsOpened}
      onEditBidPackage={onEditBidPackage}
      onDeleteBidPackage={onDeleteBidPackage}
      onDeleteFile={onDeleteFile}
      onSubmitFiles={onSubmitFiles}
      errorsImages={errorsImages}
      onImagesLoad={(imgs) => {
        onImagesLoad(imgs, setImages, images)
      }}
      files={images}
      onCopyBidPackage={copyBidPackage}
      onCancelBidPackage={cancelBidPackage}
      links={links}
      addLink={addLink}
      removeLink={removeLink}
      inputLink={inputLink}
      setInputLink={setInputLink}
      handleAddDescription={handleAddDescription}
      verifyUrl={verifyUrl}
      cancelNotificationIsOpen={cancelNotificationIsOpen}
      setCancelNotificationIsOpen={setCancelNotificationIsOpen}
    />
  )
}

export default BidPackageInfoPage
