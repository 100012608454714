export const comparativeSectionItemMapper = {
  hydrate({
    id,
    description,
    bidItem,
    position,
    unit,
    measure,
    expectedCost,
    budgetCost,
    minPrice,
    enviroData,
  }) {
    return {
      id,
      description,
      bidItem,
      position: position || 0,
      unit,
      measure,
      expectedCost: expectedCost || 0,
      budgetCost: budgetCost || 0,
      minPrice: minPrice || 0,
      enviroData,
    }
  },
}

export const comparativeSectionMapper = {
  hydrate({ id, title, position, items }) {
    return {
      id,
      title,
      position,
      items: items ? items.map(comparativeSectionItemMapper.hydrate) : [],
    }
  },
}

export const comparativeOfferItemMapper = {
  hydrate({ id, item, price, proposalItem, proposalItemInfo, enviroData }) {
    return {
      id,
      item,
      price: price || 0,
      proposalItem,
      proposalItemInfo,
      enviroData,
    }
  },
}

export const comparativeOfferMapper = {
  hydrate({
    id,
    version,
    issuingDate,
    title,
    position,
    seller,
    sellerTaxCode,
    discount,
    retention,
    retentionCriteria,
    proposal,
    proposalInfo,
    paymentConditions,
    paymentDays,
    startDate,
    endDate,
    items,
    enviroData,
  }) {
    return {
      id,
      version,
      issuingDate,
      title,
      position,
      seller,
      sellerTaxCode,
      discount,
      retention,
      retentionCriteria,
      proposal,
      proposalInfo,
      paymentConditions,
      paymentDays: paymentDays || null,
      startDate,
      endDate,
      items: items ? items.map(comparativeOfferItemMapper.hydrate) : [],
      enviroData,
    }
  },
}

export const comparativeMapper = {
  hydrate({
    id,
    kZero,
    improvementRate,
    comments,
    title,
    bidPackageId,
    project,
    legalConditions,
    paymentConditions,
    paymentDays,
    retention,
    retentionCriteria,
    sections,
    defaultSection,
    offers,
    version,
    bidPackageName,
  }) {
    return {
      id,
      kZero: kZero || 0,
      improvementRate: improvementRate || 0,
      comments,
      title,
      bidPackageId,
      bidPackageName,
      project,
      legalConditions,
      paymentConditions,
      paymentDays: paymentDays || null,
      retention,
      retentionCriteria,
      sections: sections ? sections.map(comparativeSectionMapper.hydrate) : [],
      defaultSection,
      offers: offers ? offers.map(comparativeOfferMapper.hydrate) : [],
      version: version || 1,
    }
  },
}
