export const copyState = {
  DRAFT: 'Borrador',
  'AWAITING RESPONSE': 'Oferta enviada',
  'AWAITING IMPROVEMENT': 'Cambio solicitado',
  'CANCELLED BY SELLER': 'Cancelada',
  'CANCELLED BY BUYER': 'Cancelada por la constructora',
  'FINISHED AWARDED': 'Seleccionada',
  'FINISHED REJECTED': 'Descartada',
  REJECTED: 'Descartada',
  DELETED: 'Eliminada',
  AWARDED: 'Seleccionada',
  'NOT AWARDED': 'No seleccionada',
}

export const colorState = {
  DRAFT: 'orange1',
  'AWAITING RESPONSE': 'blue1',
  'AWAITING IMPROVEMENT': 'orange1',
  'CANCELLED BY SELLER': 'red1',
  'CANCELLED BY BUYER': 'red1',
  'FINISHED AWARDED': 'green1',
  'FINISHED REJECTED': 'red1',
  REJECTED: 'red1',
  DELETED: 'red1',
  AWARDED: 'green1',
  'NOT AWARDED': 'red1',
}

export const errorsImages = [
  {
    type: 'size',
    message: (fileNames) =>
      `Algunos documentos superan el límite máximo permitido por archivo (hasta 10mb por archivo): ${fileNames}`,
  },
  {
    type: 'wrongType',
    message: (fileNames) =>
      `Algunos documento tienen un formato de archivo no permitido: ${fileNames}`,
  },
  {
    type: 'numFiles',
    message: (numFiles) => `Has arrastrado más de ${numFiles} archivos en un único intento.`,
  },
]
