export const proposalInitialValues = {
  message: '',
  publish: false,
  retention: '',
  modifyDeliveryDates: false,
  start: '',
  end: '',
  modifyDeliveryTime: false,
  deliveryTime: '',
  acceptRetentionConditions: true,
  retentionCriteria: '',
  acceptPaymentConditions: true,
  paymentDays: '',
  paymentConditions: '',
  comments: '',
  files: [],
  filesToDelete: [],
  links: [],
  linksToDelete: [],

  // Enviro
  distanceToBuildingSite: '',
  recycledMaterials: '',
  commentsEnviro: '',
  filesEnviro: [],
  filesEnviroToDelete: [],
  linksEnviro: [],
  linksEnviroToDelete: [],
}
