import { useState } from 'react'
import { requestApi } from '../../../lib/request'
import { services } from '../schemes/enviro/config'
import { catalogMapper } from '../schemes/enviro/mapper'
import { useUtils } from './useUtils'

export const useEnviro = () => {
  const { setError, setLoading } = useUtils()
  const [catalog, setCatalog] = useState({
    data: [],
    meta: {},
  })

  const getCatalog = async (params = {}) => {
    setLoading(true)
    try {
      const {
        data: { data, meta },
      } = await requestApi(services.getCatalog(params))
      setCatalog({
        meta,
        data: data.map((element) => catalogMapper.hydrate(element.resource)),
      })
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }
  return {
    data: {
      catalog,
    },
    getCatalog,
  }
}
