import { requestApi } from '../../../../lib/request'
import { services } from '../../schemes/helpers/config'
import { getBase64 } from '../serialize'

export const downloadFile = async (element) => {
  const { name } = element
  try {
    const { data, headers } = await requestApi(services.getFile(element.fileId))
    const url = `data:${headers['content-type']};base64,${getBase64(data)}`
    const a = document.createElement('A')
    a.href = url
    a.download = name
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

export const exportFile = (url, name) => {
  const a = document.createElement('A')
  a.href = url
  a.download = name
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
