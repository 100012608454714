import { Button } from '@proveoeng/uikit/dist/atoms/Button/Button'
import { Box, Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography/Typography'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal/Modal'

export const DeleteEmployee = ({
  isOpen,
  employeeName,
  setFormDeleteEmployee,
  deleteExistingEmployee,
}) => (
  <Modal
    isOpen={isOpen}
    id="delete-employee"
    onModalClose={() => {
      setFormDeleteEmployee({ show: false, name: null, id: null })
    }}
    minWidth="300"
    width="390px"
    paddingTop={6}>
    <Modal.Content>
      <Box>
        <Text
          sizeText="display16"
          fontWeight="medium">{`¿Seguro que quieres eliminar ${employeeName} de la empresa?`}</Text>
        <Box paddingTop={4}>
          <Text sizeText="display14" fontWeight="regular">
            Una vez desactivado, no tendrá ninguna función.
          </Text>
          <Text sizeText="display14" fontWeight="regular">
            Podrás reactivarlo siempre que quieras.
          </Text>
        </Box>
      </Box>
    </Modal.Content>
    <Modal.Actions>
      <Flex justifyContent="flex-end">
        <Grid gridTemplateColumns="1fr 1fr" gridTemplateRows="1fr" gridColumnGap={5}>
          <Button
            colorType="orange"
            action={() => {
              deleteExistingEmployee()
            }}>
            Sí, desactivarlo
          </Button>
          <Button
            id="cancel-button"
            colorType="black"
            action={() => {
              setFormDeleteEmployee({ show: false, name: null, id: null })
            }}>
            No
          </Button>
        </Grid>
      </Flex>
    </Modal.Actions>
  </Modal>
)
