/* eslint-disable arrow-body-style */
/* eslint-disable consistent-return */
import { useEffect } from 'react'
import styled from 'styled-components'

import { Accordion } from '@proveoeng/uikit/dist/atoms/Accordion'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import Search from '@proveoeng/uikit/dist/atoms/Icons/Search'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Box, Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

import { parseToString } from '../../../../../lib/utils/Format'

const BoxScrollStyled = styled(Box)`
  overflow-y: auto;
  max-height: 350px;
  overflow-x: hidden;
  /* size of table */
  width: 1292px;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Item = ({
  code,
  summary,
  unit,
  measurement,
  level,
  priceCi,
  amountCi,
  description,
  ceco2,
}) => (
  <Flex width="100%" flexDirection="column">
    <Grid
      gridTemplateAreas="'summary unit measurement priceCi amountCi ceco2'"
      gridTemplateColumns={`${645 - 32 * level}px 120px 120px 90px 90px 180px`}>
      <GridItem gridArea="summary">
        <Flex>
          <Text as="h3" color="black" sizeText="display14" marginBottom={2}>
            {code}
          </Text>
          <Box paddingX={2}>
            <Text as="h3" color="black" sizeText="display14" marginBottom={2}>
              {summary}
            </Text>
            <Text color="gray1" sizeText="display12" textAlign="justify">
              {description}
            </Text>
          </Box>
        </Flex>
      </GridItem>
      <GridItem gridArea="unit">
        <Text sizeText="display12" color="gray1" textAlign="right">
          {unit}
        </Text>
      </GridItem>
      <GridItem gridArea="measurement">
        <Text sizeText="display12" color="gray1" textAlign="right">
          {parseToString(measurement, 3)}
        </Text>
      </GridItem>
      <GridItem gridArea="priceCi">
        <Text sizeText="display12" color="gray1" textAlign="right">
          {parseToString(priceCi)}
        </Text>
      </GridItem>
      <GridItem gridArea="amountCi">
        <Text sizeText="display12" color="gray1" textAlign="right">
          {parseToString(amountCi)}
        </Text>
      </GridItem>
      {/* <GridItem gridArea="price">
        <Text sizeText="display12" color="gray1" textAlign="right">
          {parseToString(price)}
        </Text>
      </GridItem>
      <GridItem gridArea="amount">
        <Text sizeText="display12" color="gray1" textAlign="right">
          {parseToString(amount)}
        </Text>
      </GridItem> */}
      <GridItem gridArea="ceco2" paddingLeft={6}>
        <Text sizeText="display12" color="gray1">
          {ceco2 ? `${parseToString(ceco2)} kg` : '-'}
        </Text>
      </GridItem>
    </Grid>
  </Flex>
)

/**
 * @name RecursiveAccordion
 * @param {object} props
 * @param {Preview} props.item
 * @param {function} props.handleSelect
 * @param {boolean} props.isOpened
 */
const RecursiveAccordion = (props) => {
  const {
    item: {
      summary,
      children,
      code,
      isBidItem,
      isSelected,
      unit,
      price,
      amount,
      measurement,
      level,
      amountCi,
      priceCi,
      description,
      eCo2,
      isVisible,
    },
    isOpened,
    handleSelect,
  } = props

  if (isVisible === false) return <></>

  const customTitle = isBidItem ? (
    <Item
      code={code}
      summary={summary}
      unit={unit}
      price={price}
      amount={amount}
      measurement={measurement}
      level={level}
      priceCi={priceCi}
      amountCi={amountCi}
      description={description}
      ceco2={eCo2}
    />
  ) : (
    <Text as="h3" color="black" sizeText="display16" fontWeight="600">
      {summary}
    </Text>
  )

  return (
    <Accordion
      customTitle={customTitle}
      isExpandabled={!!children?.length && !isBidItem}
      isSelectable
      onSelect={() => handleSelect(!isSelected, code)}
      isChecked={isSelected}
      style={{ paddingLeft: '16px !important' }}
      isOpened={!!isOpened}>
      {!isBidItem && (
        <>
          {children?.map((child) => (
            <RecursiveAccordion key={child?.code} item={child} handleSelect={handleSelect} />
          ))}
        </>
      )}
    </Accordion>
  )
}

export const PreviewTable = ({
  info,
  setInfo,
  itemsSelected = 0,
  selectedTotal = 0,
  selectTotalECO2 = 0,
  onChange = () => {},
}) => {
  useEffect(() => {
    return () => {
      setInfo((prev) => markVisible(prev, ''))
    }
  }, [])
  /**
   * @description Filter the selected item recursively
   * @param {Preview} item
   * @param {Preview[]} items
   * @returns {Preview[]}
   */
  const filterSelected = (item, items = {}, parents = []) => {
    if (item.isSelected && item.isBidItem) {
      const prevItem = items[item.code] || []
      // eslint-disable-next-line no-param-reassign
      items[item.code] = [...prevItem, { ...item, parents }]
      return true
    }
    if (item.children && item.children.length) {
      item.children.filter((child) =>
        filterSelected(child, items, [
          ...parents,
          { code: item.code, summary: item.summary, level: item.level },
        ]),
      )
    }
    return items
  }

  useEffect(() => {
    const selectedItems = filterSelected(info)
    onChange(selectedItems)
  }, [info])

  /**
   *
   * @param {boolean} value
   * @param {Preview} item
   */
  const markSelected = (item, value, code) => {
    // The condition of escape is when children is empty

    // Mark or unmark the item and children
    if ((item.code === code || code === 'all') && item.isVisible !== false) {
      const items = item.children.map((child) => markSelected(child, value, 'all'))
      return { ...item, children: items, isSelected: value }
    }
    const items = item.children.map((child) => markSelected(child, value, code))
    return { ...item, children: items }
  }

  const handleSelect = (value, code) => setInfo((prevState) => markSelected(prevState, value, code))

  if (!info) return <></>

  /**
   *
   * @param {Preview} item
   * @param {string} search
   * @returns
   */
  const markVisible = (item, search) => {
    if (item.isBidItem) {
      const isVisible =
        search.length < 3 ||
        item.summary.toLowerCase().includes(search) ||
        item.code.toLowerCase().includes(search)
      return { ...item, isVisible }
    }

    if (item.children?.length) {
      // Is some child visible?
      const items = item.children.map((child) => markVisible(child, search))
      const isVisible = items.some((child) => child.isVisible)
      return { ...item, children: items, isVisible }
    }

    return { ...item, isVisible: false }
  }

  /**
   *
   * @param {string} value
   */
  const handleSearch = (value) =>
    setInfo((prevState) => markVisible(prevState, value.toLowerCase().trim()))

  return (
    <>
      <Flex marginBottom={3} alignItems="center" justifyContent="space-between">
        <Box width="calc(100% - 180px)">
          <Input
            action={(_, value) => handleSearch(value)}
            type="search"
            name="searchBar"
            title=""
            placeholderMessage="Buscar partidas por código de referencia o nombre"
            defaultValue=""
            iconLeft={
              <Icon sizeIcon="display18" color="gray3">
                <Search />
              </Icon>
            }
          />
        </Box>
        <Text as="h4" sizeText="display14" color="black" marginBottom={2} textAlign="end">
          {itemsSelected} partidas seleccionadas
        </Text>
      </Flex>
      <Flex
        width="100%"
        minWidth="800px"
        minHeight="300px"
        maxWidth="90vw"
        overflow="auto"
        flexDirection="column"
        position="relative"
        justifyContent="space-between">
        <Box>
          <Grid
            gridTemplateAreas="'summary unit measurement price ceco2 empty'"
            gridTemplateColumns="682px 120px 120px 180px 180px 10px">
            <GridItem gridArea="summary" bg="gray4" borderTopLeftRadius={2} paddingY={4} />
            <GridItem gridArea="unit" bg="gray4" borderRightColor="gray3" paddingY={4}>
              <Text
                sizeText="display14"
                color="gray1"
                overflow="hidden"
                fontWeight="medium"
                textAlign="right">
                Unidad
              </Text>
            </GridItem>
            <GridItem gridArea="measurement" bg="gray4" borderRightColor="gray3" paddingY={4}>
              <Text
                sizeText="display14"
                color="gray1"
                overflow="hidden"
                fontWeight="medium"
                textAlign="right">
                Medición
              </Text>
            </GridItem>
            <GridItem gridArea="price" bg="gray4" borderRightColor="gray3" paddingY={4}>
              <Text
                sizeText="display14"
                color="gray1"
                overflow="hidden"
                fontWeight="medium"
                textAlign="right">
                Ppto. de venta
              </Text>
            </GridItem>
            {/* <GridItem gridArea="amount" bg="gray4" paddingY={4}>
              <Text
                sizeText="display14"
                color="gray1"
                overflow="hidden"
                fontWeight="medium"
                textAlign="right">
                Coste previsto
              </Text>
            </GridItem> */}
            <GridItem gridArea="ceco2" bg="gray4" paddingY={4}>
              <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium" pl={6}>
                CeCO2
              </Text>
            </GridItem>
            <GridItem gridArea="empty" bg="gray4" borderTopRightRadius={2} paddingY={4} />
          </Grid>
          <Grid
            gridTemplateAreas="'summary priceCi amountCi ceco2 empty'"
            gridTemplateColumns="922px 90px 90px 180px 10px">
            <GridItem gridArea="summary" bg="light" paddingY={2} borderBottomLeftRadius={2} />
            <GridItem gridArea="priceCi" bg="light" paddingY={2}>
              <Text textAlign="right" color="gray2" overflow="hidden" sizeText="display10">
                €/ud.
              </Text>
            </GridItem>
            <GridItem gridArea="amountCi" bg="light" paddingY={2}>
              <Text textAlign="right" color="gray2" overflow="hidden" sizeText="display10">
                Importe
              </Text>
            </GridItem>
            {/* <GridItem gridArea="price" bg="light" paddingY={2}>
              <Text textAlign="right" color="gray2" overflow="hidden" sizeText="display10">
                €/ud.
              </Text>
            </GridItem>
            <GridItem gridArea="amount" bg="light" paddingY={2}>
              <Text textAlign="right" color="gray2" overflow="hidden" sizeText="display10">
                Importe
              </Text>
            </GridItem> */}
            <GridItem gridArea="ceco2" bg="light" paddingY={2}>
              <Text pl={6} color="gray2" overflow="hidden" sizeText="display10">
                Equivalente por unidad
              </Text>
            </GridItem>
            <GridItem gridArea="empty" bg="light" paddingY={2} borderBottomRightRadius={2} />
          </Grid>
          <BoxScrollStyled>
            <RecursiveAccordion item={info} handleSelect={handleSelect} isOpened />
          </BoxScrollStyled>
        </Box>
        <BoxScrollStyled>
          <Flex bg="white2" padding={4} justifyContent="space-between">
            <Text sizeText="display16" fontWeight="600">
              Totales
            </Text>
            <Flex paddingRight={4}>
              <Text
                sizeText="display16"
                fontWeight="600"
                width="120px"
                textAlign="right"
                paddingRight={6}>
                {parseToString(selectedTotal)}
              </Text>
              <Text sizeText="display16" fontWeight="600" width="120px">
                {parseToString(selectTotalECO2)} kg
              </Text>
            </Flex>
          </Flex>
        </BoxScrollStyled>
      </Flex>
    </>
  )
}
