import { Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { currencyFormat, parseToString } from '../../../../lib/utils/Format'

export const ComparatorTotal = ({ comparative, offerColumns, width }) => (
  <Grid
    gridTemplateAreas={`"bidpackage cant budgetCost budget expectedCostsUnit expectedCosts minEnvelopeUnit minEnvelope ${offerColumns}"`}
    gridTemplateColumns={`330px 180px 90px 130px 90px 130px 90px 130px repeat(${
      comparative?.offers?.length * 2
    }, 90px 130px)`}
    style={{ width }}>
    <GridItem
      gridArea="bidpackage"
      bg="white2"
      borderRightWidth="1px"
      padding={4}
      style={{
        paddingLeft: '30px',
        position: 'sticky',
        left: 0,
        zIndex: 9,
      }}>
      <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="bold">
        Totales finales
      </Text>
    </GridItem>
    <GridItem
      gridArea="cant"
      bg="white2"
      borderRightWidth="1px"
      paddingRight={2}
      paddingY={4}
      style={{
        position: 'sticky',
        left: 330,
        zIndex: 9,
      }}
    />
    <GridItem
      gridArea="budgetCost"
      bg="white2"
      borderRightWidth="1px"
      paddingRight={2}
      paddingY={4}
      style={{
        position: 'sticky',
        left: 510,
        zIndex: 9,
      }}
    />
    <GridItem
      gridArea="budget"
      bg="white2"
      borderRightWidth="1px"
      paddingRight={2}
      paddingY={4}
      style={{
        position: 'sticky',
        left: 600,
        zIndex: 9,
      }}>
      <Text
        textAlign="right"
        color="gray1"
        overflow="hidden"
        fontWeight="bold"
        sizeText="display12">
        {currencyFormat(comparative?.getTotalBudgetCost())}
      </Text>
    </GridItem>
    <GridItem
      gridArea="expectedCostsUnit"
      bg="white2"
      borderRightWidth="1px"
      paddingRight={2}
      paddingY={4}
      style={{
        position: 'sticky',
        left: 730,
        zIndex: 9,
      }}
    />
    <GridItem
      gridArea="expectedCosts"
      bg="white2"
      borderRightWidth="1px"
      paddingRight={2}
      paddingY={4}
      style={{
        position: 'sticky',
        left: 820,
        zIndex: 9,
      }}>
      <Text
        textAlign="right"
        color="gray1"
        overflow="hidden"
        fontWeight="bold"
        sizeText="display12">
        {currencyFormat(comparative?.getTotalExpectedCost())}
      </Text>
    </GridItem>
    <GridItem
      gridArea="minEnvelopeUnit"
      bg="white2"
      borderRightWidth="1px"
      paddingRight={2}
      paddingY={4}
      style={{
        position: 'sticky',
        left: 950,
        zIndex: 9,
      }}
    />
    <GridItem
      gridArea="minEnvelope"
      bg="white2"
      borderRightWidth="1px"
      paddingRight={2}
      paddingY={4}
      style={{
        position: 'sticky',
        left: 1040,
        zIndex: 9,
      }}>
      <Text
        textAlign="right"
        color="gray1"
        overflow="hidden"
        fontWeight="bold"
        sizeText="display12">
        {parseToString(comparative?.getTotalMinEnvelope())} €
      </Text>
    </GridItem>
    {comparative?.offers.map((offer) => (
      <>
        <GridItem
          gridArea={`offer-unit-${offer.id}`}
          bg="white2"
          borderRightWidth="1px"
          paddingRight={2}
          paddingY={4}
        />
        <GridItem
          gridArea={`offer-total-${offer.id}`}
          bg="white2"
          borderRightWidth="1px"
          paddingRight={2}
          paddingY={4}>
          <Text
            textAlign="right"
            color="gray1"
            overflow="hidden"
            fontWeight="bold"
            sizeText="display12">
            {currencyFormat(offer?.getTotal(comparative.sections))}
          </Text>
        </GridItem>
      </>
    ))}
  </Grid>
)
