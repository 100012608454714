import { useFormikContext } from 'formik'
import { useState } from 'react'

import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { LinkIcon } from '@proveoeng/uikit/dist/atoms/Icons'
import { InputNumber } from '@proveoeng/uikit/dist/atoms/InputNumber'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Textarea } from '@proveoeng/uikit/dist/atoms/Textarea'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Uploader } from '@proveoeng/uikit/dist/atoms/Uploader'
import { AttachmentList } from '../../../../common/components/attachmentList/Attachment'
import { onImagesLoad } from '../../../../common/functions/data'
import { errorsImages } from '../../common/config'
import { ModalEditLinks } from './ModalEditLinks'

export const EnvironmentalStatement = () => {
  const [modalLinksIsOpened, setModalLinksIsOpened] = useState(false)

  const { values, errors, handleChange, handleBlur, touched, setFieldValue } = useFormikContext()

  const handleAddFile = (files) => setFieldValue('filesEnviro', [...values.filesEnviro, ...files])

  const handleDeleteFile = (file) => {
    const updateFiles = file?.fileId
      ? values.filesEnviro?.filter((element) => element?.fileId !== file.fileId)
      : values.filesEnviro?.filter((element) => element?.name !== file.name)
    setFieldValue('filesEnviro', updateFiles)
    if (file?.fileId) {
      setFieldValue('filesEnviroToDelete', [...values.filesToDelete, file])
    }
  }

  const handleChangeLinks = (linksList, deleteLinksId) => {
    setFieldValue('linksEnviro', linksList)
    setFieldValue('linksEnviroToDelete', deleteLinksId)
  }

  return (
    <>
      <Flex
        flexDirection="column"
        alignItems="baseline"
        paddingX={0}
        margin="0 auto"
        width="100%"
        height="100%">
        <Flex paddingBottom="40px" width="100%">
          <Flex minWidth="264px" maxWidth="264px" alignItems="center">
            <Text as="span" sizeText="display18" color="black" fontWeight="600">
              Distancia a obra (km)
            </Text>
          </Flex>
          <Flex paddingLeft={4}>
            <InputNumber
              required
              name="distanceToBuildingSite"
              placeholderMessage="0,00"
              ariaLabel="distanceToBuildingSite"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.distanceToBuildingSite}
              hasError={touched.distanceToBuildingSite && !!errors.distanceToBuildingSite}
              numeralDecimalScale={3}
            />
          </Flex>
        </Flex>
        <Flex paddingBottom="40px" width="100%">
          <Flex minWidth="264px" maxWidth="264px" alignItems="center">
            <Text as="span" sizeText="display18" color="black" fontWeight="600">
              Uso de materiales reciclados (kg)
            </Text>
          </Flex>
          <Flex paddingLeft={4}>
            <InputNumber
              required
              name="recycledMaterials"
              placeholderMessage="0,00"
              ariaLabel="recycledMaterials"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.recycledMaterials}
              hasError={touched.recycledMaterials && !!errors.recycledMaterials}
              numeralDecimalScale={3}
            />
          </Flex>
        </Flex>
        <Flex paddingBottom="40px" width="100%">
          <Flex minWidth="264px">
            <Text as="span" sizeText="display18" color="black" fontWeight="600">
              Comentarios
            </Text>
          </Flex>
          <Flex paddingLeft={4} width="100%">
            <Textarea
              placeholderMessage="Escribe aquí los comentarios"
              name="commentsEnviro"
              onChange={handleChange}
              hasError={touched.commentsEnviro && !!errors.commentsEnviro}
              defaultValue={values.commentsEnviro}
            />
          </Flex>
        </Flex>
        <Flex paddingBottom="40px" width="100%">
          <Flex minWidth="264px" maxWidth="264px" flexDirection="column">
            <Text as="span" sizeText="display18" color="black" fontWeight="600" paddingBottom={2}>
              Certificaciones
            </Text>
            <Text as="span" sizeText="display14" color="gray1">
              Puedes adjuntar ficheros o enlaces referentes a la declaración ambiental
            </Text>
          </Flex>
          <Flex flexDirection="column" marginBottom={7} paddingLeft={4} width="100%">
            <Flex alignItems="center" width="100%" paddingBottom={4}>
              <Box width="392px" paddingTop={3}>
                <Uploader
                  multiple
                  name="files"
                  marginBottom={1}
                  errors={errorsImages}
                  maxNumFiles={5}
                  onImagesLoad={(files) => onImagesLoad(files, handleAddFile)}
                  descriptionMessage="Arrastra o haz click para subir tus ficheros aquí"
                  accept="all"
                  padding={4}
                />
              </Box>
              <Flex paddingLeft={4}>
                <Icon color="gray2" sizeIcon="display12" paddingRight={2}>
                  <LinkIcon />
                </Icon>
                <Text
                  as="span"
                  onClick={() => setModalLinksIsOpened(true)}
                  sizeText="display14"
                  color="blue1"
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                  Añadir enlaces externos
                </Text>
                <Text
                  as="span"
                  fontWeight="medium"
                  color="black"
                  sizeText="display14"
                  onClick={() => setModalLinksIsOpened(true)}
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    paddingLeft: 4,
                  }}>
                  {values.linksEnviro?.length || ''}
                </Text>
              </Flex>
            </Flex>
            <AttachmentList files={values.filesEnviro} withDelete onDeleteFile={handleDeleteFile} />
          </Flex>
        </Flex>
      </Flex>
      <ModalEditLinks
        data={values.linksEnviro}
        modalIsOpened={modalLinksIsOpened}
        setModalIsOpened={setModalLinksIsOpened}
        handleChange={handleChangeLinks}
      />
    </>
  )
}
