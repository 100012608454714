// import PropTypes from 'prop-types';
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Logo from '../../assets/logo'
import LogoIso from '../../assets/logoIsotipo'

export const Header = ({ type, miniLogo }) => {
  const history = useHistory()
  const {
    projects: { data },
  } = useSelector((state) => state.projects?.data)

  return (
    <Flex alignItems="center" justifyContent="space-between" width="100%" paddingX={5}>
      <Flex
        alignItems="center"
        paddingX={5}
        style={{ cursor: 'pointer' }}
        onClick={() =>
          // eslint-disable-next-line no-nested-ternary
          type === 'Buyer'
            ? data
              ? history.push('/constructora/tus-obras')
              : history.push('/constructora/crear-obra')
            : type === 'Seller' && history.push('/proveedor/compras-nuevas')
        }>
        {miniLogo ? <LogoIso /> : <Logo height="20px" width="90px" />}
      </Flex>
    </Flex>
  )
}

Header.propTypes = {}

Header.defaultProps = {}
