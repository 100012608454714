import { Box } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { parseToInteger } from '../../../../../lib/utils/Format'

import { AttachmentList } from '../../../../common/components/attachmentList/Attachment'
import { Items } from '../../../../common/components/item/Items'
import { Links } from '../../../../common/components/links/Links'

/**
 *
 * @param {{ proposal: Proposal }} props
 */
export const OfferEnvironmentalStatement = ({ proposal, environmentalStatement }) => {
  if (!proposal?.proposalId) return <></>

  const items = [
    {
      title: 'Distancia a obra',
      children: (
        <Text
          as="p"
          sizeText="display14"
          color="black"
          style={{
            whiteSpace: 'pre-wrap',
          }}>
          {environmentalStatement?.distanceToBuildingSite
            ? parseToInteger(environmentalStatement?.distanceToBuildingSite / 100)
            : '-'}
        </Text>
      ),
    },
    {
      title: 'Uso de materiales reciclados (kg)',
      children: (
        <Text
          as="p"
          sizeText="display14"
          color="black"
          style={{
            whiteSpace: 'pre-wrap',
          }}>
          {environmentalStatement?.distanceToBuildingSite
            ? `${parseToInteger(environmentalStatement?.distanceToBuildingSite / 100)} kg`
            : '-'}
        </Text>
      ),
    },
    {
      title: 'Comentarios',
      children: (
        <Text
          as="span"
          sizeText="display18"
          color="black"
          style={{
            whiteSpace: 'pre-wrap',
          }}>
          {!environmentalStatement?.comments
            ? 'No hay comentarios.'
            : environmentalStatement?.comments}
        </Text>
      ),
    },
    {
      title: 'Certificaciones',
      children: (
        <Box>
          {!!environmentalStatement.links?.length || !!environmentalStatement.files?.length ? (
            <>
              <AttachmentList files={environmentalStatement.files} withDownload />
              <Links links={environmentalStatement.links} />
            </>
          ) : (
            '-'
          )}
        </Box>
      ),
    },
  ]
  return (
    <>
      <Items items={items} />
    </>
  )
}
