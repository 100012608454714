import * as React from 'react'

function SvgLogoIsotipo(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 849.92 1200" {...props}>
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <path
            className="cls-1"
            fill="#ff7e00"
            d="M767.21,681c-1.86,2.65-91.93,111.84-136.78,167.43-.66.79-24.14,33.16-32,43.11-8.36,11-16.85,21.63-25.34,31.58-16,19-37.55,36.88-64.74,53.73Q433.76,1019.58,355,999.35T231,900a221.42,221.42,0,0,1-23.48-160.79,208.63,208.63,0,0,1,33.83-73.9c16.32-22.95,37.28-41.79,63.15-56.51q33-19.11,74.43-27.2c27.46-5.31,52.42-4.62,76.38-4.62,29.84,0,41.29-8.41,41.29-27.09l.53-72.7.27-50.94c0-21.36-8.12-38.05-47.9-38.61q-68.06-3.18-128.15,7.43T204.57,438.17q-73.23,42-121.52,106.66a408,408,0,0,0-69,140.63q-20.7,76.21-11,156.95,9.75,81,51.74,154.16c28.12,48.82,63.54,89.28,106.66,121.52s89.69,55.06,140.23,68.32S404.1,1203.26,457.57,1197s104.54-23.22,153.36-51.35a372.39,372.39,0,0,0,101.89-84.9,543.54,543.54,0,0,0,68.59-102.95c4.3-8,12.92-31.34,16.85-41.79,25.34-80.93,39.66-141.43,50-211.87C860.08,624.3,806.08,625.1,767.21,681Z"
          />
          <path d="M609.44,0H240.62a29,29,0,0,0-29.05,28.92V177.77a28.93,28.93,0,0,0,29.05,28.93H609.44a28.91,28.91,0,0,0,28.92-28.93V28.92A29,29,0,0,0,609.44,0Z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgLogoIsotipo
