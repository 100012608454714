import * as Yup from 'yup'

export const profileUserSchema = Yup.object().shape({
  name: Yup.string()
    .required('El nombre es obligatorio')
    .min(3, 'El nombre debe tener al menos 3 caracteres')
    .max(100, 'El nombre debe tener como máximo 100 caracteres'),
  phone: Yup.string()
    .required('El teléfono es obligatorio')
    .min(9, 'El teléfono debe tener al menos 9 caracteres')
    .max(25, 'El teléfono debe tener como máximo 50 caracteres'),
  email: Yup.string().required('El email es obligatorio').email('El email no es válido'),
  position: Yup.string(),
})

export const profileCompanySchema = Yup.object().shape({
  name: Yup.string()
    .required('El nombre de la compañia es obligatorio')
    .min(3, 'El nombre debe tener al menos 3 caracteres')
    .max(50, 'El nombre debe tener como máximo 50 caracteres'),
  taxCode: Yup.string().required('El NIF/CIF/VAT es obligatorio'),
  address: Yup.string()
    .required('La dirección es obligatoria')
    .min(5, 'La dirección debe tener al menos 5 caracteres')
    .max(80, 'La dirección debe tener como máximo 80 caracteres'),
  city: Yup.string()
    .required('La ciudad es obligatoria')
    .min(2, 'La ciudad debe tener al menos 2 caracteres')
    .max(50, 'La ciudad debe tener como máximo 50 caracteres'),
  postCode: Yup.string()
    .required('El código postal es obligatorio')
    .matches(/^[0-9]{5}$/, 'El código postal es inválido'),
  email: Yup.string().email('El email no es válido'),
  phone: Yup.string()
    .required('El teléfono es obligatorio')
    .min(9, 'El teléfono debe tener al menos 9 caracteres')
    .max(25, 'El teléfono debe tener como máximo 50 caracteres'),
  website: Yup.string().url('La url no es válida'),
  billingName: Yup.string().when('isSameBillingData', (isSameBillingData, scheme) =>
    isSameBillingData
      ? scheme
      : scheme
          .required('El nombre de la compañia es obligatorio')
          .min(3, 'El nombre debe tener al menos 3 caracteres')
          .max(50, 'El nombre debe tener como máximo 50 caracteres'),
  ),
  billingAddress: Yup.string().when('isSameBillingData', (isSameBillingData, scheme) =>
    isSameBillingData
      ? scheme
      : scheme
          .required('La dirección es obligatoria')
          .min(5, 'La dirección debe tener al menos 5 caracteres')
          .max(80, 'La dirección debe tener como máximo 80 caracteres'),
  ),
  billingCity: Yup.string().when('isSameBillingData', (isSameBillingData, scheme) =>
    isSameBillingData
      ? scheme
      : scheme
          .required('La ciudad es obligatoria')
          .min(2, 'La ciudad debe tener al menos 2 caracteres')
          .max(50, 'La ciudad debe tener como máximo 50 caracteres'),
  ),
  billingPostCode: Yup.string().when('isSameBillingData', (isSameBillingData, scheme) =>
    isSameBillingData
      ? scheme
      : scheme
          .required('El código postal es obligatorio')
          .matches(/^[0-9]{5}$/, 'El código postal es inválido'),
  ),
})

export const profileActivitySchema = Yup.object().shape({
  description: Yup.string(),
  scope: Yup.string(),
  creationYear: Yup.string(),
  turnover: Yup.string(),
  staff: Yup.string(),
})

export const profileCompanyMergeSchema = Yup.object().shape({
  ...profileCompanySchema.fields,
  ...profileActivitySchema.fields,
})
