import { useState } from 'react'

import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon/Icon'
import Help from '@proveoeng/uikit/dist/atoms/Icons/Help'
import { Box, Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { Tooltip } from '@proveoeng/uikit/dist/atoms/Tooltip'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography/Typography'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'

export const BidPackagesImportGuide = ({ withTooltip }) => {
  const [importGuideOpened, setImportGuideOpened] = useState(false)
  const [contentIsOpened, setContentIsOpened] = useState(false)

  const Content = () => (
    <Box>
      <Text as="h4" sizeText="display18" color="black" fontWeight="medium" mb={2}>
        ¿Cómo importar mis procesos de compra?
      </Text>
      <Text sizeText="display14" color="black" fontWeight="regular">
        ¡Es muy fácil! Solo necesitas descargar nuestra plantilla y agregar todas tus partidas.
      </Text>
      <Box mt={4} mb={2}>
        <Button colorType="transparent" action={() => setImportGuideOpened(true)}>
          Guía de importación
        </Button>
      </Box>
    </Box>
  )

  return (
    <>
      {withTooltip ? (
        <Tooltip
          content={<Content />}
          active={contentIsOpened}
          setActive={setContentIsOpened}
          disableHover
          bgColor="gray4"
          width="450px">
          <Button colorType="none" sizeButton="none">
            <Icon sizeIcon="display30">
              <Help />
            </Icon>
          </Button>
        </Tooltip>
      ) : (
        <Content />
      )}
      <Modal
        isOpen={importGuideOpened}
        id="import-guide-modal"
        onModalClose={() => setImportGuideOpened(false)}
        closeWithButton
        title="Guía de importación"
        maxWidth="808px">
        <Modal.Content>
          <Box margin="0 auto">
            <Button
              colorType="orange"
              action={() => {
                const a = document.createElement('A')
                a.href = `${process.env.PUBLIC_URL}/downloads/Plantilla_import_compra.xlsx`
                a.download = 'Plantilla_import_compra.xlsx'
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
              }}>
              Descargar Plantilla
            </Button>
            <Box pl={8} pr={2} paddingY={6}>
              <Text as="h4" fontWeight="bold">
                Aclaraciones
              </Text>
              <Text as="ul" style={{ listStyle: 'disc' }} paddingY={2} fontWeight="400">
                <li>
                  Al importar este fichero en la plataforma, se generarán uno o varios procesos de
                  compra.
                </li>
                <li>
                  Cada proceso de compra define a las naturalezas de los proveedores existentes en
                  el sector y se indica en la columna Categoría.
                </li>
                <li>
                  El valor de la columna categoría debe escogerse desde la lista proporcionada, se
                  convertirá en el nombre del proceso de compra al importar el fichero y define al
                  tipo de proveedor que ofertará para esa compra.
                </li>
                <li>
                  Todas las filas que tengan la misma categoría, se agruparán bajo el mismo proceso
                  de compra
                </li>
                <li>
                  El nombre de la partida tiene 50 caracteres de longitud máxima. En caso de
                  sobrepasarlo, se cogerán sólo los caracteres del principio. En caso de estar vacío
                  se tomará como nombre de la partida los primeros 50 caracteres de la descripción
                  de la partida.
                </li>
                <li>
                  Las columnas con * son obligatorias, por tanto debes rellenarlas para que funcione
                  la importación.
                </li>
                <li>
                  No debes dejar filas vacías como separador de los datos, si lo haces considerarmos
                  que no hay más datos en el fichero y sólo se importará lo anterior.
                </li>
                <li>
                  Todos los costes introducidos deben ser unitarios, no totales. Los totales se
                  calcularán como la multiplicación del medición x unidad
                </li>
                <li>Hay un ejemplo en la pestaña Ejemplo, ¡por si tienes dudas! </li>
              </Text>
            </Box>
            <Box pl={8} pr={2}>
              <Text as="h4" fontWeight="bold">
                Definición de términos
              </Text>
              <Text as="ul" style={{ listStyle: 'disc' }} paddingY={2} fontWeight="400">
                <li>
                  Coste unitario Previsto - es el coste unitario de referencia que usamos para
                  calcular la desviación respecto al presupuesto de venta y las ofertas recibidas
                  <li>
                    Código es la referencia interna que define a cada partida, es un valor opcional
                  </li>
                </li>
                <li>
                  Coste unitario Objetivo - es el coste unitario objetivo que usamos para calcular
                  la desviación respecto al presupuesto de venta y las ofertas recibidas
                </li>
                <li>
                  Precio unitario presupuesto de venta - es el precio presupuestado por el cliente
                  final, que define el importe de cada unidad que compone la partida
                </li>
              </Text>
            </Box>
          </Box>
        </Modal.Content>
        <Modal.Actions>
          <Flex justifyContent="flex-end">
            <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr">
              <Button colorType="transparent" action={() => setImportGuideOpened(false)}>
                Hecho
              </Button>
            </Grid>
          </Flex>
        </Modal.Actions>
      </Modal>
    </>
  )
}
