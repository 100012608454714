import { useEffect } from 'react'
import Cleave from 'cleave.js/react'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Box, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Textarea } from '@proveoeng/uikit/dist/atoms/Textarea'

import { useEditMode } from '../../../../common/hooks/useEditMode'

export const ItemEditable = ({
  bg,
  gridArea,
  name,
  placeholderMessage,
  value,
  handleChange = () => {},
  onSubmit = () => {},
  children,
  textAlign = 'left',
  style = {},
  error = '',
  hasBorder,
  isDecimal,
  isTextArea,
  numeralDecimalScale = 2,
  defaultMode,
  ...props
}) => {
  const { editMode, handleEditMode, ref } = useEditMode({ onSubmit, defaultMode })

  useEffect(() => {
    if (!editMode && error) handleEditMode()
  }, [editMode, error])

  const InputText = (
    <Input
      sizeInput="small"
      required
      placeholderMessage={placeholderMessage}
      hasError={!!error}
      autoFocus
      render={({ style: inputStyle, ...inputProps }) => (
        <Cleave
          {...inputProps}
          onChange={handleChange}
          name={name}
          value={value}
          options={{
            numeral: isDecimal,
            numeralDecimalMark: ',',
            delimiter: '.',
            numeralPositiveOnly: true,
            numeralDecimalScale,
          }}
          style={inputStyle}
        />
      )}
    />
  )

  const InputTextArea = (
    <Textarea
      name={name}
      placeholderMessage={placeholderMessage}
      required
      autoFocus
      onChange={handleChange}
      value={value}
      hasError={!!error}
      maxLength="auto"
    />
  )

  return (
    <GridItem
      gridArea={gridArea}
      bg={bg || 'white2'}
      {...(hasBorder
        ? {
            borderRightColor: 'gray3',
            borderRightStyle: 'solid',
            borderRightWidth: '1px',
          }
        : {})}
      paddingRight={2}
      paddingY={4}
      ref={ref}
      style={style}
      {...props}>
      <Box textAlign={textAlign}>
        {editMode || error ? (
          <Box paddingX={2}>
            {!isTextArea ? InputText : InputTextArea}
            <Text sizeText="display10" color="red1">
              {error}
            </Text>
          </Box>
        ) : (
          <Box onClick={handleEditMode}>
            {typeof children === 'string' || typeof children === 'number' ? (
              <Text
                sizeText="display12"
                color="gray1"
                overflow="hidden"
                fontWeight="regular"
                style={{
                  cursor: 'pointer',
                }}>
                {children}
              </Text>
            ) : (
              children
            )}
          </Box>
        )}
      </Box>
    </GridItem>
  )
}
