import { useState } from 'react'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Info } from '@proveoeng/uikit/dist/atoms/Icons'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Tooltip } from '@proveoeng/uikit/dist/atoms/Tooltip'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

export const ProposalCommentsTooltip = ({ comments }) => {
  const [active, setActive] = useState(false)

  if (!comments || comments?.length === 0) return '-'

  return (
    <Tooltip active={active} setActive={setActive} content={comments}>
      <Flex alignItems="center">
        <Text as="label" marginTop={3} marginBottom={3} sizeText="display14" display="block">
          Tiene comentarios
        </Text>
        <Icon sizeIcon="display18">
          <Info />
        </Icon>
      </Flex>
    </Tooltip>
  )
}
