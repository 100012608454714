import { useEffect } from 'react'
import { useFormikContext } from 'formik'

import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { SelectInput } from '@proveoeng/uikit/dist/atoms/Select'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'

import { useHelpers } from '../../../../common/hooks/useHelpers'

export const ModalCreateBidPackage = ({ isOpened, closeModal, goBack }) => {
  const {
    data: { categories },
    getCategories,
  } = useHelpers()

  const { values, handleChange, errors, setFieldValue, handleBlur, touched, submitForm } =
    useFormikContext()

  const categoriesOptions = categories.data.map(({ categoryId, name }) => ({
    value: categoryId,
    label: name,
  }))

  useEffect(() => {
    getCategories()
    if (!isOpened) resetForm()
  }, [isOpened])

  const resetForm = () => {
    setFieldValue('name', '')
    setFieldValue('categoryId', '')
    setFieldValue('bidEndsAt', '')
  }

  return (
    <Modal
      id="create-bidpackage-modal"
      width="95vw"
      maxWidth="575px"
      isOpen={isOpened}
      onModalClose={closeModal}
      closeWithButton
      title="Detalles de la compra">
      <Modal.Content>
        <Field
          label="Nombre de la compra"
          required
          sizeText="display16"
          marginBottom={3}
          errorMessage={touched.name && errors.name}>
          <Input
            required
            placeholderMessage="Da un nombre a tu proceso de compra"
            ariaLabel="Nombre"
            name="name"
            hasError={touched.name && !!errors.name}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Field>
        <Field
          label="Naturaleza"
          required
          sizeText="display16"
          marginBottom={3}
          marginTop={5}
          errorMessage={touched.categoryId && errors.categoryId}>
          <SelectInput
            isSearchable
            required
            name="categoryId"
            id="categoryId"
            onChange={(_, v) => setFieldValue('categoryId', v.value)}
            options={categoriesOptions}
            placeholderMessage="Selecciona a que naturaleza pertenece esta compra"
            ariaLabel="turnover"
            hasError={touched.categoryId && !!errors.categoryId}
            defaultValue={{ value: values.categoryId }}
            onBlur={handleBlur}
          />
        </Field>

        <Field
          required
          label="Fecha límite para presentar oferta"
          sizeText="display16"
          marginBottom={3}
          marginTop={5}
          errorMessage={touched.bidEndsAt && errors.bidEndsAt}>
          <Input
            required
            type="date"
            name="bidEndsAt"
            ariaLabel="bidEndsAt"
            placeholderMessage="Fecha límite para presentar oferta"
            hasError={touched.bidEndsAt && !!errors.bidEndsAt}
            value={values.bidEndsAt}
            onChange={handleChange}
            min={new Date().toISOString().split('T')[0]}
            onBlur={handleBlur}
          />
        </Field>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr" mr={4}>
            <Button colorType="transparent" type="button" action={goBack}>
              Volver
            </Button>
          </Grid>
          <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr">
            <Button colorType="orange" type="button" action={submitForm}>
              Crear compra
            </Button>
          </Grid>
        </Flex>
      </Modal.Actions>
    </Modal>
  )
}
