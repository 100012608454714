import { useReducer, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { requestApi } from '../../../lib/request'
import { getBase64 } from '../functions/serialize'
import { services } from '../schemes/comparative/config'
import { services as servicesProposal } from '../schemes/proposals/config'
import { services as servicesHelper } from '../schemes/helpers/config'
import { services as servicesBidPackage } from '../schemes/bidPackages/config'
import { comparativeReduce, initialStateComparative } from '../schemes/comparative/reducer'
import { comparativeActions } from '../schemes/comparative/actions'
import { exportFile } from '../functions/downloadFile'
import { useUtils } from './useUtils'
import { getFileNameFromHeaders } from '../../../lib/utils/getFileNameFromHeaders'

export const useComparative = () => {
  const { setAlert, setLoading, setError } = useUtils()

  const [comparativesList, setComparativesList] = useState([])

  const [comparativeData, dispatchComparative] = useReducer(
    comparativeReduce,
    initialStateComparative,
  )

  const { setComparative, ...actions } = comparativeActions(dispatchComparative)

  /**
   * @param {*} values: FormikValues,
   * @param {*} formikHelpers: FormikHelpers<FormikValues>
   * @returns {Promise<string | false>}
   */
  const saveComparative = async (
    { name },
    { setSubmitting, resetForm } = {
      setSubmitting: () => {},
      resetForm: () => {},
    },
  ) => {
    try {
      setLoading(true)
      const snapshotId = uuidv4()
      await requestApi(
        services.saveComparative({
          bidPackageId: comparativeData.bidPackageId,
          snapshotId,
          title: name,
          data: comparativeData,
          comparisonId: comparativeData.id,
        }),
      )
      resetForm()
      setSubmitting(false)
      setAlert('success', 'Comparativo guardado con éxito')
      return snapshotId
    } catch (error) {
      setError(error)
      return false
    } finally {
      setLoading(false)
    }
  }

  const getComparativeData = async (bidpackageId, proposalIds) => {
    try {
      setLoading()
      let queryString = ''
      for (let i = 0; i < proposalIds?.length; i += 1) {
        queryString += `proposalId[]=${proposalIds[i]}&`
      }
      const {
        data: { data },
      } = await requestApi(services.getComparatives(bidpackageId, queryString))
      setComparative(data)

      return true
    } catch (error) {
      setError(error)
      return false
    } finally {
      setLoading(false)
    }
  }

  const getThumb = async (fileId) => {
    try {
      setLoading()

      const { data, headers } = await requestApi(servicesHelper.getThumb(fileId))
      return `data:${headers['content-type']};base64,${getBase64(data)}`
    } catch (error) {
      setError(error)
      return false
    } finally {
      setLoading(false)
    }
  }

  const getThumbAllOfferDetails = async (files = []) =>
    Promise.all(
      files.map(async (file) => {
        const fileThumbed = file
        fileThumbed.base64 = await getThumb(file.fileId)
        return fileThumbed
      }),
    )

  const getOfferDetails = async (bidpackageId, offerId) => {
    try {
      setLoading()
      const {
        data: {
          data: { comments, links, files },
        },
      } = await requestApi(servicesBidPackage.getBidPackageProposal(bidpackageId, offerId))

      let base64Files = []

      base64Files = await getThumbAllOfferDetails(files)

      return { comments, links, files: base64Files }
    } catch (error) {
      setError(error)
      return false
    } finally {
      setLoading(false)
    }
  }

  const getOfferDetailsEnviro = async (proposalId) => {
    try {
      setLoading()
      const {
        data: {
          data: { comments, links, files },
        },
      } = await requestApi(servicesProposal.getProposalEnviro(proposalId))

      let base64Files = []

      base64Files = await getThumbAllOfferDetails(files)

      return { comments, links, files: base64Files }
    } catch (error) {
      setError(error)
      return false
    } finally {
      setLoading(false)
    }
  }

  const getBidItemOfferDetails = async (proposalId, proposalItemId) => {
    try {
      setLoading()
      const {
        data: {
          data: { comments, links, files },
        },
      } = await requestApi(servicesProposal.getProposalItem(proposalId, proposalItemId))

      let base64Files = []

      base64Files = await getThumbAllOfferDetails(files)

      return { comments, links, files: base64Files }
    } catch (error) {
      setError(error)
      return false
    } finally {
      setLoading(false)
    }
  }

  const getBidItemOfferDetailsEnviro = async (proposalId, proposalItemId) => {
    try {
      setLoading()
      const {
        data: {
          data: { comments, links, files },
        },
      } = await requestApi(servicesProposal.getProposalItemEnviro(proposalId, proposalItemId))

      let base64Files = []

      base64Files = await getThumbAllOfferDetails(files)

      return { comments, links, files: base64Files }
    } catch (error) {
      setError(error)
      return false
    } finally {
      setLoading(false)
    }
  }

  const exportComparative = async (snapshotId) => {
    try {
      setLoading()
      const { id, bidPackageId } = comparativeData
      const { data, headers } = await requestApi(
        services.exportComparative(bidPackageId, id, snapshotId),
      )
      const url = window.URL.createObjectURL(new Blob([data]))
      const fileName = getFileNameFromHeaders(headers) || `comparativo-${id}-${snapshotId}.xlsx`
      exportFile(url, fileName)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const saveAndExport = async ({ electedSeller, electionReason }, formikHelpers) => {
    try {
      actions.exportComparative({ electedSeller, electionReason })
      const body = { ...comparativeData, electedSeller, electionReason }
      const snapshotId = uuidv4()
      await requestApi(
        services.saveComparative({
          bidPackageId: comparativeData.bidPackageId,
          snapshotId,
          title: comparativeData.title,
          data: body,
          comparisonId: comparativeData.id,
        }),
      )
      await exportComparative(snapshotId)
      formikHelpers.resetForm()
      formikHelpers.setSubmitting(false)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const saveAndDownload = async (enviroData) => {
    setLoading(true)
    try {
      const snapshotId = uuidv4()
      await requestApi(
        services.saveComparative({
          bidPackageId: comparativeData.bidPackageId,
          snapshotId,
          title: comparativeData.title,
          data: comparativeData,
          comparisonId: comparativeData.id,
        }),
      )
      const { id, bidPackageId } = comparativeData
      const { data, headers } = await requestApi(
        services.donwloadComparative(bidPackageId, id, enviroData),
      )
      const url = window.URL.createObjectURL(new Blob([data]))
      const fileName = getFileNameFromHeaders(headers) || `comparativo-${id}-${snapshotId}.xlsx`
      exportFile(url, fileName)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const getComparativesList = async (bidpackageId) => {
    try {
      setLoading()
      const {
        data: { data },
      } = await requestApi(services.getComparativesList(bidpackageId))

      setComparativesList(data)
    } catch (error) {
      setError(error)
      throw error
    } finally {
      setLoading(false)
    }
  }

  const getSavedComparative = async (bidpackageId, comparisonId, snapshotId) => {
    try {
      setLoading()
      const {
        data: { data },
      } = await requestApi(services.getComparative(bidpackageId, comparisonId, snapshotId))
      setComparative(data)

      return true
    } catch (error) {
      setError(error)
      return false
    } finally {
      setLoading(false)
    }
  }

  return {
    data: {
      comparativesList,
      comparativeData,
    },
    getComparativeData,
    getOfferDetails,
    getOfferDetailsEnviro,
    getBidItemOfferDetails,
    getBidItemOfferDetailsEnviro,
    saveComparative,
    saveAndExport,
    actions,
    getComparativesList,
    getSavedComparative,
    saveAndDownload,
  }
}
