import { useState, useEffect } from 'react'
import { Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Checkbox } from '@proveoeng/uikit/dist/atoms/Checkbox'
import { Textarea } from '@proveoeng/uikit/dist/atoms/Textarea'
import { Radiobox } from '@proveoeng/uikit/dist/atoms/Radiobox'

let formData
export const DataCompanyTemplate = ({ title, setData, data, errorsData, errorsKeys, values }) => {
  const [useSameDataCompany, setUseSameDataCompany] = useState(true)
  const [dataForm, setDataForm] = useState(data)

  useEffect(() => {
    setData(formData)
  }, [])

  useEffect(() => {
    setDataForm(values)
  }, [values])

  return (
    <Flex
      as="form"
      ref={(form) => {
        formData = form
      }}
      height="100%"
      width="100%"
      paddingY={11}
      flexDirection="column"
      paddingX={14}>
      <Text sizeText="display14" color="gray2" marginBottom={7}>
        Finaliza tu perfil
        <Text as="span" sizeText="display14" color="black" marginBottom={7} marginLeft={2}>
          La compañia
        </Text>
      </Text>
      <Text sizeText="display52" color="black" marginBottom={7} fontWeight="bold" maxWidth={856}>
        {title}
      </Text>
      <Grid
        gridTemplateColumns={['1fr', '1fr', '220px 1fr']}
        gridTemplateAreas={[
          "'description' 'fields'",
          "'description' 'fields'",
          "'description fields'",
        ]}
        gridGap={[7, 7, 7, 13]}
        marginTop={11}>
        <GridItem gridArea="description">
          <Text sizeText="display18" color="black" fontWeight="bold" marginBottom={2}>
            Datos de la compañía
          </Text>
          <Text sizeText="display16" color="gray" maxWidth="221px">
            Estos datos nos servirán para poder enviarte las ofertas acorde a tu perfil y
            localización.
          </Text>
        </GridItem>
        <GridItem gridArea="fields" display="flex">
          <Grid
            gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}
            gridTemplateAreas={[
              "'fields1' 'fields2' 'fields3'",
              "'fields1' 'fields2' 'fields3'",
              "'fields1 fields2' 'fields3 fields3'",
            ]}
            gridColumnGap={13}
            width="100%"
            paddingRight={14}>
            <GridItem gridArea="fields1">
              <Field
                required
                errorMessage={errorsKeys?.name}
                label="Nombre"
                sizeText="display16"
                marginBottom={3}
                marginTop={0}>
                <Input
                  placeholderMessage="Nombre de la empresa"
                  ariaLabel="Nombre"
                  required
                  action={() => setData(formData)}
                  defaultValue={data?.companyName}
                  name="companyName"
                  hasError={errorsData?.companyName || !!errorsKeys?.name}
                  pattern="^.{3,50}$"
                />
              </Field>
              <Field
                required
                label="Código postal"
                sizeText="display16"
                marginBottom={3}
                errorMessage={errorsKeys?.postCode}
                marginTop={5}>
                <Input
                  type="text"
                  pattern="^\d{5}$"
                  required
                  inputMode="numeric"
                  placeholderMessage="Introduce tu código postal"
                  action={() => setData(formData)}
                  ariaLabel="postal"
                  name="companyPostCode"
                  defaultValue={data?.companyPostCode}
                  hasError={errorsData?.companyPostCode || !!errorsKeys?.postCode}
                />
              </Field>
              <Field
                required
                errorMessage={errorsKeys?.phone}
                label="Número de teléfono"
                sizeText="display16"
                marginBottom={3}
                marginTop={5}>
                <Input
                  required
                  placeholderMessage="Introduce tu número de teléfono"
                  action={() => setData(formData)}
                  ariaLabel="teléfono"
                  name="companyPhone"
                  hasError={errorsData?.companyPhone || errorsKeys?.phone}
                  defaultValue={data?.companyPhone}
                  pattern="^.{9,25}$"
                />
              </Field>
            </GridItem>
            <GridItem gridArea="fields2">
              <Field
                errorMessage={errorsKeys?.address}
                required
                label="Dirección"
                sizeText="display16"
                marginBottom={3}
                marginTop={0}>
                <Input
                  placeholderMessage="Dirección de la empresa"
                  action={() => setData(formData)}
                  ariaLabel="dirección"
                  required
                  name="companyAddress"
                  defaultValue={data?.companyAddress}
                  hasError={errorsData?.companyAddress || !!errorsKeys?.address}
                  pattern="^.{5,80}$"
                />
              </Field>
              <Field required label="Ciudad" sizeText="display16" marginBottom={3} marginTop={5}>
                <Input
                  placeholderMessage="Introduce tu ciudad"
                  action={() => setData(formData)}
                  ariaLabel="ciudad"
                  required
                  name="companyCity"
                  defaultValue={data?.companyCity}
                  hasError={errorsData?.companyCity}
                  pattern="^.{2,50}$"
                />
              </Field>
              <Field
                required
                label="Ámbito de acción"
                sizeText="display16"
                marginBottom={3}
                marginTop={5}>
                <Grid gridTemplateColumns="1fr 1fr" gridGap={4}>
                  <Radiobox
                    required
                    onChange={(name, value) => {
                      for (let i = 0; i < formData?.length; i += 1) {
                        if (formData[i]?.value === value) {
                          formData[i].checked = true
                        }
                      }
                      setData(formData)
                    }}
                    hasError={errorsData?.scope}
                    items={[
                      {
                        id: 'Local',
                        name: 'scope',
                        defaultChecked: dataForm?.scope === 'Local',
                        isDisabled: false,
                        hasError: false,
                        label: 'Local',
                        defaultValue: 'Local',
                      },
                      {
                        id: 'Regional',
                        name: 'scope',
                        defaultChecked: dataForm?.scope === 'Regional',
                        isDisabled: false,
                        hasError: false,
                        label: 'Regional',
                        defaultValue: 'Regional',
                      },
                      {
                        id: 'National',
                        name: 'scope',
                        defaultChecked: dataForm?.scope === 'National',
                        isDisabled: false,
                        hasError: false,
                        label: 'Nacional',
                        defaultValue: 'National',
                      },
                      {
                        id: 'International',
                        name: 'scope',
                        defaultChecked: dataForm?.scope === 'International',
                        isDisabled: false,
                        hasError: false,
                        label: 'Internacional',
                        defaultValue: 'International',
                      },
                    ]}
                  />
                </Grid>
              </Field>
            </GridItem>
            <GridItem gridArea="fields3">
              <Field
                required
                label="Descripción"
                sizeText="display16"
                marginBottom={3}
                marginTop={5}>
                <Textarea
                  required
                  name="companyDescription"
                  typeAction={() => setData(formData)}
                  hasError={errorsData?.companyDescription}
                  defaultValue={data?.companyDescription}
                  maxLength="auto"
                />
              </Field>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
      <Grid
        gridTemplateColumns={['1fr', '1fr', '220px 1fr']}
        gridTemplateAreas={[
          "'description' 'fields'",
          "'description' 'fields'",
          "'description fields'",
        ]}
        gridGap={[7, 7, 7, 13]}
        marginTop={11}>
        <GridItem gridArea="description">
          <Text sizeText="display18" color="black" fontWeight="bold" marginBottom={2}>
            Datos de facturación
          </Text>
          <Text sizeText="display16" color="gray" maxWidth="221px">
            Estos datos nos servirán para poder enviarte las ofertas acorde a tu perfil y
            localización.
          </Text>
        </GridItem>
        <GridItem gridArea="fields" display="flex">
          <Grid
            gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}
            gridTemplateAreas={[
              "'fields1' 'fields2' 'fields3'",
              "'fields1' 'fields2' 'fields3'",
              "'fields1 fields1' 'fields2 fields3'",
            ]}
            gridColumnGap={13}
            width="100%"
            paddingRight={14}>
            <GridItem gridArea="fields1" marginBottom={5}>
              <Checkbox
                label="Usar los datos de la compañía como datos de facturación"
                action={() => {
                  setData(formData)
                  setUseSameDataCompany(!useSameDataCompany)
                }}
                defaultChecked={useSameDataCompany}
                name="isSameBillingData"
              />
            </GridItem>
            {!useSameDataCompany ? (
              <>
                <GridItem gridArea="fields2">
                  <Field label="Nombre" sizeText="display16" marginBottom={3} marginTop={0}>
                    <Input
                      placeholderMessage="Nombre de la empresa"
                      ariaLabel="Nombre"
                      name="billingName"
                      defaultValue={data.billingName}
                      action={() => setData(formData)}
                      pattern="^.{3,50}$"
                    />
                  </Field>
                  <Field label="Código postal" sizeText="display16" marginBottom={3} marginTop={5}>
                    <Input
                      type="text"
                      pattern="^\d{5}$"
                      placeholderMessage="Introduce tu código postal"
                      action={() => setData(formData)}
                      ariaLabel="postal"
                      defaultValue={data.billingPostCode}
                      name="billingPostCode"
                    />
                  </Field>
                </GridItem>

                <GridItem gridArea="fields3">
                  <Field label="Dirección" sizeText="display16" marginBottom={3} marginTop={0}>
                    <Input
                      placeholderMessage="Dirección de la empresa"
                      action={() => setData(formData)}
                      ariaLabel="dirección"
                      defaultValue={data.billingAddress}
                      name="billingAddress"
                      pattern="^.{5,80}$"
                    />
                  </Field>
                  <Field label="Ciudad" sizeText="display16" marginBottom={3} marginTop={5}>
                    <Input
                      placeholderMessage="Introduce tu ciudad"
                      action={() => setData(formData)}
                      ariaLabel="ciudad"
                      defaultValue={data.billingCity}
                      name="billingCity"
                      pattern="^.{2,50}$"
                    />
                  </Field>
                  <Field
                    required
                    errorMessage={errorsKeys?.taxCode}
                    label="NIF/CIF/VAT"
                    sizeText="display16"
                    marginBottom={3}
                    marginTop={5}>
                    <Input
                      required
                      placeholderMessage="NIF/CIF/VAT de la empresa"
                      action={() => setData(formData)}
                      ariaLabel="NIF/CIF/VAT"
                      defaultValue={data.taxCode}
                      name="taxCode"
                      pattern="^.{5,15}$"
                    />
                  </Field>
                </GridItem>
              </>
            ) : (
              <GridItem gridArea="fields2">
                <Field
                  required
                  errorMessage={errorsKeys?.taxCode}
                  label="NIF/CIF/VAT"
                  sizeText="display16"
                  marginBottom={3}
                  marginTop={0}>
                  <Input
                    required
                    placeholderMessage="NIF/CIF/VAT de la empresa"
                    action={() => setData(formData)}
                    ariaLabel="NIF/CIF/VAT"
                    defaultValue={data.taxCode}
                    name="taxCode"
                    hasError={errorsData?.taxCode}
                    pattern="^.{5,15}$"
                  />
                </Field>
              </GridItem>
            )}
          </Grid>
        </GridItem>
      </Grid>
    </Flex>
  )
}
