import { useFormik } from 'formik'
import { useState } from 'react'

import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Checkbox } from '@proveoeng/uikit/dist/atoms/Checkbox'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Help } from '@proveoeng/uikit/dist/atoms/Icons'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { InputNumber } from '@proveoeng/uikit/dist/atoms/InputNumber'
import { Box, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Radiobox } from '@proveoeng/uikit/dist/atoms/Radiobox'
import { SelectInput } from '@proveoeng/uikit/dist/atoms/Select'
import { Textarea } from '@proveoeng/uikit/dist/atoms/Textarea'
import { Tooltip } from '@proveoeng/uikit/dist/atoms/Tooltip'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

import { SelectAvatar } from '../../../../common/components/selectAvatar/SelectAvatar'
import { profileCompanyMergeSchema } from '../../../../common/validationSchemes/profileSchemes'
import {
  ActionButton,
  ActionContainerStickyStyled,
  FormContainer,
  InputContainer,
} from '../../styles/ProfileStyles'

/**
 *
 * @param {object} props
 * @param {ActivityData} props.activityData
 * @param {void} props.handleChangeAvatar
 * @param {void} props.onSubmit
 */
export const ProfileCompanyForm = ({ company, avatar, handleChangeAvatar, onSubmit }) => {
  const { companyId, activityData, generalData, billingData, isSameBillingData, tenderingData } =
    company

  if (!companyId) return <></>

  const initialValues = {
    description: activityData.description || '',
    creationYear: activityData.creationYear || '',
    turnover: activityData.turnover || '',
    staff: activityData.staff || '',
    scope: activityData.scope || '',
    name: generalData.name || '',
    email: generalData.email || '',
    website: generalData.website || '',
    phone: generalData.phone || '',
    address: generalData.address || '',
    postCode: generalData.postCode || '',
    city: generalData.city || '',
    taxCode: billingData.taxCode || '',
    isSameBillingData: isSameBillingData ?? true,
    billingName: billingData.name || '',
    billingAddress: billingData.address || '',
    billingCity: billingData.city || '',
    billingPostCode: billingData.postCode || '',
    financialCostsRatio: tenderingData.financialCostsRatio || '',
  }

  const [tooltipActive, setTooltipActive] = useState(false)

  const { values, errors, handleBlur, touched, handleChange, setFieldValue, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: profileCompanyMergeSchema,
      onSubmit,
    })

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <GridItem gridArea="description" marginBottom={4}>
          <Text sizeText="display18" color="black" fontWeight="bold" marginBottom={2}>
            Logo
          </Text>
          <Text sizeText="display14" color="gray">
            Puedes elegir una foto y establecerla como tu imagen de perfil.
          </Text>
        </GridItem>
        <GridItem gridArea="fields">
          <Box>
            <Text sizeText="display16" color="black" fontWeight="600" marginBottom={3}>
              Logo
            </Text>
            <SelectAvatar
              avatar={avatar?.base64}
              handleChangeAvatar={handleChangeAvatar}
              description="Cambiar logo"
            />
          </Box>
        </GridItem>
      </FormContainer>
      <FormContainer>
        <GridItem gridArea="description" marginBottom={4}>
          <Text sizeText="display18" color="black" fontWeight="bold" marginBottom={2}>
            Perfil y actividad
          </Text>
          <Text sizeText="display14" color="gray">
            Estos datos nos servirán para poder enviarte las ofertas acorde a tu perfil.
          </Text>
        </GridItem>
        <GridItem gridArea="fields">
          <InputContainer>
            <Box>
              <Field
                label="Descripción"
                required
                sizeText="display16"
                marginBottom={3}
                errorMessage={touched.name && errors.name}>
                <Textarea
                  placeholderMessage="Escribe una descripción"
                  name="description"
                  ariaLabel="description"
                  maxLength="auto"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.description && !!errors.description}
                />
              </Field>
            </Box>
            <Box>
              <Field
                required
                label="Ámbito de acción"
                sizeText="display16"
                marginBottom={3}
                marginTop={5}>
                <Grid gridTemplateColumns="1fr 1fr" gridGap={4}>
                  <Radiobox
                    onChange={(_, value) => setFieldValue('scope', value)}
                    items={[
                      {
                        id: 'Local',
                        name: 'scope',
                        defaultChecked: values.scope === 'Local',
                        isDisabled: false,
                        hasError: false,
                        label: 'Local',
                        defaultValue: 'Local',
                      },
                      {
                        id: 'Regional',
                        name: 'scope',
                        defaultChecked: values.scope === 'Regional',
                        isDisabled: false,
                        hasError: false,
                        label: 'Regional',
                        defaultValue: 'Regional',
                      },
                      {
                        id: 'National',
                        name: 'scope',
                        defaultChecked: values.scope === 'National',
                        isDisabled: false,
                        hasError: false,
                        label: 'Nacional',
                        defaultValue: 'National',
                      },
                      {
                        id: 'International',
                        name: 'scope',
                        defaultChecked: values.scope === 'International',
                        isDisabled: false,
                        hasError: false,
                        label: 'Internacional',
                        defaultValue: 'International',
                      },
                    ]}
                  />
                </Grid>
              </Field>
            </Box>
          </InputContainer>
          <InputContainer>
            <Box>
              <Field
                label="Año de fundación"
                sizeText="display16"
                marginBottom={3}
                marginTop={5}
                errorMessage={touched.creationYear && errors.creationYear}>
                <Input
                  type="text"
                  placeholderMessage="Escribe el año de fundación"
                  ariaLabel="creationYear"
                  name="creationYear"
                  value={values.creationYear}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.creationYear && !!errors.creationYear}
                />
              </Field>
            </Box>
            <Box>
              <Field label="Facturación anual" sizeText="display16" marginBottom={3} marginTop={5}>
                <SelectInput
                  isSearchable
                  name="turnover"
                  id="turnover"
                  onChange={(_, v) => setFieldValue('turnover', v.value)}
                  options={[
                    { value: '0-0.3M', label: '0-0.3K' },
                    { value: '300K-1M', label: '300K-1M' },
                    { value: '1M-5M', label: '1M-5M' },
                    { value: '5M-20M', label: '5M-20M' },
                    { value: '20M-100M', label: '20M-100M' },
                    { value: '>100M', label: '>100M' },
                  ]}
                  placeholderMessage="Facturación anual"
                  ariaLabel="turnover"
                  hasError={touched.position && !!errors.position}
                  defaultValue={{ value: values.turnover, label: values.turnover }}
                />
              </Field>
            </Box>
          </InputContainer>
          <InputContainer>
            <Box>
              <Field
                label="Número de empleados"
                sizeText="display16"
                marginBottom={3}
                marginTop={5}>
                <SelectInput
                  isSearchable
                  name="staff"
                  id="staff"
                  onChange={(_, v) => setFieldValue('staff', v.value)}
                  options={[
                    { value: '<10', label: '<10' },
                    { value: '10-49', label: '10-49' },
                    { value: '50-99', label: '50-99' },
                    { value: '100-250', label: '100-250' },
                    { value: '>250', label: '>250' },
                  ]}
                  placeholderMessage="Número de empleados"
                  ariaLabel="staff"
                  hasError={touched.staff && !!errors.staff}
                  defaultValue={{ value: values.staff, label: values.staff }}
                />
              </Field>
            </Box>
            <Box>
              <Field marginRight={5}>
                <Text
                  as="label"
                  sizeText="display16"
                  marginBottom={3}
                  marginTop={5}
                  display="flex"
                  alignItems="baseline">
                  Coste financiero anual (%)
                  <Tooltip
                    active={tooltipActive}
                    setActive={setTooltipActive}
                    content="El coste financiero anual se utiliza para calcular el coste adicional que supone trabajar con proveedores que tengan un periodo de pago inferior al ofertado en el proceso de compra. Dicho coste se mostrará en el comparativo.">
                    <Button
                      colorType="none"
                      sizeButton="none"
                      action={() => setTooltipActive((prevState) => !prevState)}
                      style={{
                        position: 'absolute',
                        bottom: '-6px',
                      }}>
                      <Icon sizeIcon="display30">
                        <Help />
                      </Icon>
                    </Button>
                  </Tooltip>
                </Text>

                <InputNumber
                  placeholderMessage="Escribe el porcentaje de coste financiero (%)"
                  ariaLabel="financialCostsRatio"
                  name="financialCostsRatio"
                  value={values.financialCostsRatio}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.financialCostsRatio && !!errors.financialCostsRatio}
                />
              </Field>
            </Box>
          </InputContainer>
        </GridItem>
      </FormContainer>

      <FormContainer>
        <GridItem gridArea="description" marginBottom={4}>
          <Text sizeText="display18" color="black" fontWeight="bold" marginBottom={2}>
            Datos de la compañia
          </Text>
          <Text sizeText="display14" color="gray">
            Estos datos nos servirán para poder enviarte las ofertas acorde a tu perfil y
            localización.
          </Text>
        </GridItem>
        <GridItem gridArea="fields">
          <InputContainer>
            <Box>
              <Field
                label="Compañia"
                required
                sizeText="display16"
                marginBottom={3}
                errorMessage={touched.name && errors.name}>
                <Input
                  type="text"
                  placeholderMessage="Escribe el nombre de la compañia"
                  ariaLabel="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.name && !!errors.name}
                />
              </Field>
            </Box>
            <Box>
              <Field
                label="NIF/CIF/VAT"
                required
                sizeText="display16"
                marginBottom={3}
                errorMessage={touched.taxCode && errors.taxCode}>
                <Input
                  disabled
                  type="text"
                  placeholderMessage="Escribe el NIF/CIF/VAT de la compañia"
                  ariaLabel="taxCode"
                  name="taxCode"
                  value={values.taxCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.taxCode && !!errors.taxCode}
                />
              </Field>
            </Box>
          </InputContainer>
          <Box>
            <Field
              label="Dirección"
              required
              sizeText="display16"
              marginBottom={3}
              errorMessage={touched.address && errors.address}>
              <Input
                type="text"
                placeholderMessage="Escribe la dirección de la compañia"
                ariaLabel="address"
                name="address"
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                hasError={touched.address && !!errors.address}
              />
            </Field>
          </Box>
          <InputContainer>
            <Box>
              <Field
                label="Cuidad"
                required
                sizeText="display16"
                marginBottom={3}
                errorMessage={touched.city && errors.city}>
                <Input
                  type="text"
                  placeholderMessage="Escribe la cuidad de la compañia"
                  ariaLabel="city"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.city && !!errors.city}
                />
              </Field>
            </Box>
            <Box>
              <Field
                label="Código postal"
                required
                sizeText="display16"
                marginBottom={3}
                errorMessage={touched.postCode && errors.postCode}>
                <Input
                  type="text"
                  placeholderMessage="Escribe el Código postal de la compañia"
                  ariaLabel="postCode"
                  name="postCode"
                  value={values.postCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.postCode && !!errors.postCode}
                />
              </Field>
            </Box>
          </InputContainer>
          <Box>
            <Field
              label="Email"
              sizeText="display16"
              marginBottom={3}
              errorMessage={touched.email && errors.email}>
              <Input
                type="email"
                placeholderMessage="Escribe el email de la compañia"
                ariaLabel="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                hasError={touched.email && !!errors.email}
              />
            </Field>
          </Box>
          <InputContainer>
            <Box>
              <Field
                label="Teléfono de contacto principal"
                sizeText="display16"
                marginBottom={3}
                errorMessage={touched.phone && errors.phone}>
                <Input
                  type="tel"
                  placeholderMessage="Escribe el teléfono de la compañia"
                  ariaLabel="phone"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.phone && !!errors.phone}
                />
              </Field>
            </Box>
            <Box>
              <Field
                label="Página web"
                sizeText="display16"
                marginBottom={3}
                errorMessage={touched.website && errors.website}>
                <Input
                  type="text"
                  placeholderMessage="Escribe la página web de la compañia"
                  ariaLabel="website"
                  name="website"
                  value={values.website}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.website && !!errors.website}
                />
              </Field>
            </Box>
          </InputContainer>
        </GridItem>
      </FormContainer>

      <FormContainer>
        <GridItem gridArea="description" marginBottom={4}>
          <Text sizeText="display18" color="black" fontWeight="bold" marginBottom={2}>
            Datos de facturación
          </Text>
          <Text sizeText="display14" color="gray">
            Estos datos nos servirán para poder enviarte las ofertas acorde a tu perfil y
            localización.
          </Text>
        </GridItem>
        <GridItem gridArea="fields">
          <Checkbox
            label="Usar los datos de la compañía como datos de facturación"
            action={(_, value) => setFieldValue('isSameBillingData', value)}
            defaultChecked={values.isSameBillingData}
            name="isSameBillingData"
          />
          {!values.isSameBillingData && (
            <>
              <InputContainer>
                <Box>
                  <Field
                    label="Nombre"
                    required
                    sizeText="display16"
                    marginBottom={3}
                    errorMessage={touched.billingName && errors.billingName}>
                    <Input
                      type="text"
                      placeholderMessage="Escribe el nombre de la compañia"
                      ariaLabel="billingName"
                      name="billingName"
                      value={values.billingName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      hasError={touched.billingName && !!errors.billingName}
                    />
                  </Field>
                </Box>
              </InputContainer>
              <Box>
                <Field
                  label="Dirección"
                  required
                  sizeText="display16"
                  marginBottom={3}
                  errorMessage={touched.billingAddress && errors.billingAddress}>
                  <Input
                    type="text"
                    placeholderMessage="Dirección de la empresa"
                    ariaLabel="billingAddress"
                    name="billingAddress"
                    value={values.billingAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched.billingAddress && !!errors.billingAddress}
                  />
                </Field>
              </Box>
              <InputContainer>
                <Box>
                  <Field
                    label="Cuidad"
                    required
                    sizeText="display16"
                    marginBottom={3}
                    errorMessage={touched.billingCity && errors.billingCity}>
                    <Input
                      type="text"
                      placeholderMessage="Escribe la cuidad de la compañia"
                      ariaLabel="billingCity"
                      name="billingCity"
                      value={values.billingCity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      hasError={touched.billingCity && !!errors.billingCity}
                    />
                  </Field>
                </Box>
                <Box>
                  <Field
                    label="Código postal"
                    required
                    sizeText="display16"
                    marginBottom={3}
                    errorMessage={touched.billingPostCode && errors.billingPostCode}>
                    <Input
                      type="text"
                      placeholderMessage="Escribe el Código postal de la compañia"
                      ariaLabel="billingPostCode"
                      name="billingPostCode"
                      value={values.billingPostCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      hasError={touched.billingPostCode && !!errors.billingPostCode}
                    />
                  </Field>
                </Box>
              </InputContainer>
            </>
          )}
        </GridItem>
      </FormContainer>
      <ActionContainerStickyStyled borderTopColor="gray3" style={{ zIndex: 2 }}>
        <ActionButton type="submit" colorType="orange" sizeButton="medium">
          Guardar
        </ActionButton>
      </ActionContainerStickyStyled>
    </form>
  )
}
