import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useOffer } from '../../../../common/hooks/useOffer'
import { useSeller } from '../../../../common/hooks/useSeller'
import { OffersTemplate } from '../template/OffersTemplate'

const OffersPage = () => {
  const history = useHistory()
  const {
    offers: { pending, pendingMeta, imagespending },
  } = useSelector((state) => state.employee)

  const { cancelRequest, resetStoreOpenedOffer, resetStoreProposal } = useSeller()

  const { getPendingOffers } = useOffer()

  useEffect(() => {
    getPendingOffers()
  }, [])

  useEffect(() => {
    resetStoreOpenedOffer()
    resetStoreProposal()

    return () => {
      cancelRequest()
    }
  }, [])

  return (
    <OffersTemplate
      offers={pending}
      pendingMeta={pendingMeta}
      imagespending={imagespending}
      history={history}
    />
  )
}

export default OffersPage
