export const CategorySeller = {
  hydrate({ companyId, name, address, taxCode, contact, staff, turnover, sellerSubtypes }) {
    return {
      companyId,
      name,
      taxCode,
      contact,
      staff,
      turnover,
      sellerSubtypes,
      ...address,
    }
  },
}
