import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { FormatDate } from '../../../mappers/FormatDate'
import { PreviewTable } from './PreviewTable'

export const ContentSelection = ({
  name,
  categoryName,
  bidEndsAt,
  info,
  setInfo,
  setSelectedBidItems,
  selectedBidItems,
  seletedLength,
  selectedTotal,
  selectTotalECO2,
}) => (
  <>
    <Flex justifyContent="space-between" marginBottom={2}>
      <Box>
        <Text sizeText="display16" color="black" marginBottom={2}>
          Nombre de la compra: <strong>{name}</strong>
        </Text>
        <Text sizeText="display16" color="black" marginBottom={2}>
          Naturaleza: <strong>{categoryName}</strong>
        </Text>
      </Box>
      <Box textAlign="end">
        <Text sizeText="display16" color="black" marginBottom={2}>
          Fecha límite para presentar oferta
        </Text>
        <Text sizeText="display18" color="black" fontWeight="600">
          {FormatDate.hydrate(bidEndsAt)?.readableStringDate}
        </Text>
      </Box>
    </Flex>
    <PreviewTable
      info={info}
      setInfo={setInfo}
      onChange={setSelectedBidItems}
      selectedBidItems={selectedBidItems}
      itemsSelected={seletedLength}
      selectedTotal={selectedTotal}
      selectTotalECO2={selectTotalECO2}
    />
  </>
)
