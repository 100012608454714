import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

import { ButtonTabs } from '@proveoeng/uikit/dist/atoms/Button'
import { InputNumber } from '@proveoeng/uikit/dist/atoms/InputNumber'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { DataTable } from '@proveoeng/uikit/dist/molecules/DataTable'
import { PlusCircle } from '@proveoeng/uikit/dist/atoms/Icons'

import { parseToInteger, parseToString } from '../../../../../lib/utils/Format'
import { AttachmentList } from '../../../../common/components/attachmentList/Attachment'
import { Links } from '../../../../common/components/links/Links'
import { ModalDetailsCeCo2 } from './ModalDetailsCeCo2'

export const EditProposalCostEmission = ({ proposalItems }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [selectedProposalItem, setSelectedProposalItem] = useState(null)

  const { values, handleChange, errors, handleBlur, touched } = useFormikContext()

  const calcTotal = (row) => {
    const key = `eCo2-${row.proposalItemId}`
    if (!row || !row.bidItem.measurement || !values[key]) return 0
    const cost = parseToInteger(values[key], 3) / 1000
    const measurement = parseToInteger(row.bidItem.measurement, 3)
    const total = cost * measurement

    return total
  }

  const getTotal = (row) => {
    const value = calcTotal(row)
    if (!value) return '-'

    return `${parseToString(value, 3)} kg`
  }

  const getTotals = () => {
    const totals = proposalItems?.reduce((acc, prev) => acc + calcTotal(prev), 0)
    return `${parseToString(totals, 3)} kg CO2`
  }

  const handleEditItemDetails = (item) => {
    setSelectedProposalItem(item)
    setModalIsOpen(true)
  }

  const handleCloseEditItemDetails = () => {
    setModalIsOpen(false)
    setSelectedProposalItem(null)
  }

  const columns = [
    {
      name: 'Partida',
      selector: (row) => row.bidItem.name.toLowerCase(),
      cell: (row) => (
        <Text as="span" sizeText="display14" fontWeight="400">
          {row.bidItem.name}
        </Text>
      ),
      sortable: true,
      width: '280px',
    },
    {
      name: 'Unidad',
      selector: (row) => row.bidItem.unit,
      cell: (row) => (
        <Text as="span" sizeText="display12" fontWeight="400">
          {row.bidItem.unit}
        </Text>
      ),
      sortable: true,
      width: '92px',
    },
    {
      name: 'Medición',
      selector: (row) => row.bidItem.measurement,
      cell: (row) => (
        <Text as="span" sizeText="display12" fontWeight="400">
          {row.bidItem.measurement}
        </Text>
      ),
      sortable: true,
      width: '106px',
      right: true,
    },
    {
      name: 'Descripción',
      selector: (row) => row.bidItem.description,
      cell: (row) => (
        <Text sizeText="display12" fontWeight="400" color="black">
          {row.bidItem.description}
        </Text>
      ),
      minWidth: '360px',
    },
    {
      name: (
        <Box marginBottom={2} width="100%">
          <Box marginBottom={2} paddingTop={4}>
            <Text textAlign="right" sizeText="display14" overflow="hidden" fontWeight="600">
              Equivalente de CO2
            </Text>
          </Box>
          <Flex width="100%" justifyContent="space-between">
            <Text
              width="120px"
              textAlign="right"
              color="gray2"
              overflow="hidden"
              fontWeight="400"
              sizeText="display10">
              Por unidad (kg)
            </Text>
            <Text
              width="120px"
              textAlign="right"
              color="gray2"
              overflow="hidden"
              fontWeight="400"
              sizeText="display10">
              Total
            </Text>
          </Flex>
        </Box>
      ),
      selector: () => 0,
      cell: (row) => {
        const name = `eCo2-${row.proposalItemId}`
        return (
          <Flex width="100%" justifyContent="space-between" alignItems="center">
            <Box textAlign="right" width="120px">
              <InputNumber
                required
                name={name}
                placeholderMessage="0,000"
                ariaLabel="measurement"
                numeralDecimalScale={3}
                hasError={touched[name] && !!errors[name]}
                value={values[name]}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
            <Box width="120px" textAlign="right">
              <Text as="span" sizeText="display14" fontWeight="regular">
                {getTotal(row)}
              </Text>
            </Box>
          </Flex>
        )
      },
      sortable: true,
      width: '260px',
    },
    {
      name: '',
      button: true,
      cell: (row) => {
        const comments = values[`commentsEnviro-${row?.proposalItemId}`]
        const files = values[`filesEnviro-${row?.proposalItemId}`] || []
        const links = values[`linksEnviro-${row?.proposalItemId}`] || []
        const dataQuality = values[`dataQuality-${row?.proposalItemId}`]
        return (
          <>
            {files?.length || links?.length || comments || dataQuality ? (
              <Text
                color="blue1"
                textAlign="right"
                sizeText="display14"
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => handleEditItemDetails(row)}>
                Ver detalles
              </Text>
            ) : (
              <Flex alignItems="center">
                <PlusCircle sizeIcon="display18" />
                <Text
                  color="gray1"
                  sizeText="display14"
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => handleEditItemDetails(row)}>
                  Añadir detalles
                </Text>
              </Flex>
            )}
          </>
        )
      },
      right: true,
      width: '110px',
      style: {
        padding: '0',
      },
    },
  ]

  const isDisabled = (item) => !item.comments && !item.files?.length && !item.links?.length

  return (
    <>
      <ModalDetailsCeCo2
        isOpened={modalIsOpen}
        onClose={handleCloseEditItemDetails}
        item={selectedProposalItem}
      />

      <DataTable
        columns={columns}
        data={proposalItems}
        pagination={proposalItems?.length > 10}
        expandableRows
        expandableRowsComponent={OfferItemListExpanded}
        expandableRowDisabled={isDisabled}
      />
      <Flex
        alignItems="center"
        paddingX={3}
        paddingY={3}
        width="100%"
        justifyContent="space-between">
        <Flex alignItems="center">
          <Text as="span" sizeText="display18" color="black" fontWeight="bold">
            Total
          </Text>
        </Flex>
        <Text as="span" sizeText="display18" color="black" fontWeight="bold">
          {getTotals()}
        </Text>
      </Flex>
    </>
  )
}

const OfferItemListExpanded = ({ data: bidItem }) => {
  const [whoIsActive, setWhoIsActive] = useState('')

  const isActive = (tab) => tab === whoIsActive

  const tabItems = []
  if (bidItem?.comments) {
    tabItems.push({
      id: 'comments',
      children: <>Comentario</>,
      isActive: isActive('comments'),
      action: (_, target) => setWhoIsActive(target?.id),
    })
  }
  if (bidItem?.files?.length) {
    tabItems.push({
      id: 'attachments',
      children: <>Adjuntos</>,
      isActive: isActive('attachments'),
      action: (_, target) => setWhoIsActive(target?.id),
    })
  }
  if (bidItem?.links?.length) {
    tabItems.push({
      id: 'links',
      children: <>Enlaces externos</>,
      isActive: isActive('links'),
      action: (_, target) => setWhoIsActive(target?.id),
    })
  }

  useEffect(() => {
    setWhoIsActive(tabItems[0]?.id || '')
  }, [bidItem])

  return (
    <Flex paddingX={4} flexDirection="column" alignItems="baseline" paddingY={4}>
      <ButtonTabs items={tabItems} />
      <Box paddingY={4}>
        {whoIsActive === 'attachments' && <AttachmentList files={bidItem?.files} withDownload />}
        {whoIsActive === 'links' && <Links links={bidItem?.links} />}
        {whoIsActive === 'comments' && (
          <Flex marginY={3} flexWrap="wrap">
            <Text
              sizeText="display14"
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              marginLeft={5}
              style={{
                whiteSpace: 'pre-wrap',
              }}>
              {bidItem?.comments || '-'}
            </Text>
          </Flex>
        )}
      </Box>
    </Flex>
  )
}
