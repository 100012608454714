export class BidItem {
  constructor(
    id,
    description,
    measure,
    unit,
    budgetCost,
    expectedCost,
    position,
    minPrice,
    enviroData,
  ) {
    this.id = id
    this.description = description
    this.measure = measure
    this.unit = unit
    this.budgetCost = budgetCost
    this.expectedCost = expectedCost
    this.position = position
    this.minPrice = minPrice
    this.enviroData = enviroData
  }

  static create({
    id,
    description,
    measure,
    unit,
    budgetCost,
    expectedCost,
    position,
    minPrice,
    enviroData,
  }) {
    return new BidItem(
      id,
      description,
      measure,
      unit,
      budgetCost,
      expectedCost,
      position,
      minPrice,
      enviroData,
    )
  }

  getTotalBudgetCost() {
    return (this.measure / 1000) * (this.budgetCost / 100) || 0
  }

  getTotalExpectedCost() {
    return (this.measure / 1000) * (this.expectedCost / 100) || 0
  }

  getTotalCeco2() {
    return (this.measure / 1000) * (this.enviroData.eCo2 / 100) || 0
  }

  getMinEnvelope(offers) {
    const offerItems = offers.map((offer) => {
      const offerFiltered = offer.offerItems.find((offerItem) => offerItem.itemId === this.id)

      return {
        name: offer.name,
        price: offerFiltered.price,
        total: offerFiltered.price * (this.measure / 1000),
      }
    })

    return offerItems.sort((a, b) => a.price - b.price)[0]
  }
}
