import { useState } from 'react'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { ButtonTabs } from '@proveoeng/uikit/dist/atoms/Button'
import ArrowDown from '@proveoeng/uikit/dist/atoms/Icons/ArrowDown'
import ArrowRight from '@proveoeng/uikit/dist/atoms/Icons/ArrowRight'
import { AttachmentList } from '../../../../common/components/attachmentList/Attachment'
import { Links } from '../../../../common/components/links/Links'

export const OfferBidItemDetail = ({ element }) => {
  const [isOpened, setIsOpened] = useState(false)
  const [whoIsActive, setWhoIsActive] = useState('attachments')

  let tabItems = [
    {
      id: 'attachments',
      children: <>Adjuntos</>,
      isActive: whoIsActive === 'attachments',
      action: (e, target) => setWhoIsActive(target.id),
    },
    {
      id: 'comments',
      children: <>Comentario</>,
      isActive: whoIsActive === 'comments',
      action: (e, target) => setWhoIsActive(target.id),
    },
  ]

  tabItems = element.links?.length
    ? [
        ...tabItems,
        {
          id: 'links',
          children: <>Enlaces externos</>,
          isActive: whoIsActive === 'links',
          action: (_, target) => setWhoIsActive(target.id),
        },
      ]
    : tabItems

  return (
    <>
      <Grid
        gridTemplateAreas={"'bidItem unit measurement description'"}
        gridTemplateColumns="35% 10% 10% 1fr"
        paddingY={5}
        width="100%"
        minHeight="48px">
        <GridItem gridArea="bidItem">
          <Flex alignItems="center" paddingX={3}>
            <Icon
              style={{ cursor: 'pointer' }}
              sizeIcon="display18"
              color="black"
              marginRight={4}
              onClick={() => setIsOpened(!isOpened)}>
              {isOpened ? <ArrowDown /> : <ArrowRight />}
            </Icon>
            <Flex alignItems="center">
              <Text
                as="span"
                marginLeft={1}
                sizeText="display14"
                color="black"
                style={{ cursor: 'pointer' }}
                onClick={() => setIsOpened(!isOpened)}>
                {element?.name}
              </Text>
            </Flex>
          </Flex>
        </GridItem>

        <GridItem gridArea="unit">
          <Text sizeText="display14" color="gray1">
            {element.unit}
          </Text>
        </GridItem>
        <GridItem gridArea="measurement">
          <Text sizeText="display14" color="gray1">
            {element.measurement}
          </Text>
        </GridItem>
        <GridItem gridArea="description">
          <Text
            sizeText="display14"
            color="gray1"
            style={{
              whiteSpace: 'pre-wrap',
            }}>
            {element.description}
          </Text>
        </GridItem>
      </Grid>
      <Grid
        width="100%"
        gridTemplateAreas={"'detail'"}
        gridTemplateColumns="100%"
        borderBottomColor="gray4"
        borderBottomStyle="solid"
        borderBottomWidth="1px">
        <GridItem gridArea="detail">
          {isOpened && (
            <Flex paddingX={2} flexDirection="column" alignItems="baseline" paddingY={2}>
              <ButtonTabs items={tabItems} />
              {whoIsActive === 'attachments' && (
                <>
                  {element.files?.length ? (
                    <AttachmentList withDownload files={element.files} />
                  ) : (
                    <Flex marginY={3} flexWrap="wrap">
                      <Text
                        sizeText="display14"
                        color="gray1"
                        overflow="hidden"
                        fontWeight="medium"
                        marginLeft={5}>
                        No hay archivos adjuntos
                      </Text>
                    </Flex>
                  )}
                </>
              )}
              {whoIsActive === 'comments' && (
                <Text
                  sizeText="display14"
                  marginY={2}
                  marginLeft={2}
                  style={{
                    whiteSpace: 'pre-wrap',
                  }}>
                  {element.comments || '-'}
                </Text>
              )}
              {whoIsActive === 'links' && (
                <Flex marginY={3} flexWrap="wrap">
                  {!element?.links?.length ? (
                    <Text
                      sizeText="display14"
                      color="gray1"
                      overflow="hidden"
                      fontWeight="medium"
                      marginLeft={5}>
                      No hay enlaces externos
                    </Text>
                  ) : (
                    <Links links={element.links} />
                  )}
                </Flex>
              )}
            </Flex>
          )}
        </GridItem>
      </Grid>
    </>
  )
}
