import { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import css from '@styled-system/css'
import { variant } from 'styled-system'

import { Flex, Box } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import CheckboxCircle from '@proveoeng/uikit/dist/atoms/Icons/CheckboxCircle'
import Timer from '@proveoeng/uikit/dist/atoms/Icons/Timer'
import Spam from '@proveoeng/uikit/dist/atoms/Icons/Spam'
import { Button } from '@proveoeng/uikit/dist/atoms/Button/Button'
import { Avatar } from '@proveoeng/uikit/dist/atoms/Avatar'

import ecomaLogo from '../../assets/ECOMA_LOGO.png'
import { deleteLocalStorage } from '../../functions/cookies'

const OptionListStyled = styled(Flex)(
  css({
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'white',
    },
    width: '90%',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    padding: '12px',
    paddingLeft: '34px',
    marginLeft: '3px',
  }),
  variant({
    prop: 'isActive',
    variants: {
      true: {
        boxShadow: '#E4670B -3px 0',
        backgroundColor: 'white',
        marginLeft: '3px',
      },
    },
  }),
  variant({
    prop: 'disabled',
    variants: {
      true: {
        cursor: 'not-allowed',
        pointerEvents: 'none',
      },
    },
  }),
)

function useOutsideClick(ref, action) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        action()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

export const Sidebar2 = ({ placeholder, src, disableOptions, employee, project, errors }) => {
  const history = useHistory()
  const { hash } = useParams()

  const [isHover, setIsHover] = useState('')

  const onMouseEnterHover = (e) => setIsHover(e.target.getAttribute('name'))
  const onMouseOutHover = () => setIsHover('')
  const [showActions, setShowActions] = useState(false)

  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, () => setShowActions(false))

  const profileActions = [
    {
      id: 'profile',
      content: (
        <Text sizeText="display14" color="black" style={{ cursor: 'pointer' }}>
          Tu perfil
        </Text>
      ),
      action: () => {
        history.push('/perfil')
      },
    },
    {
      id: 'company',
      content: (
        <Text sizeText="display14" color="black" style={{ cursor: 'pointer' }}>
          Tu compañía
        </Text>
      ),
      action: () => {
        history.push('/perfil/compania')
      },
    },
    {
      id: 'logout',
      content: (
        <Text sizeText="display14" color="red1" style={{ cursor: 'pointer' }}>
          Cerrar sesión
        </Text>
      ),
      action: () => {
        deleteLocalStorage(
          process.env.REACT_APP_COOKIE_LOGIN_NAME || env.REACT_APP_COOKIE_LOGIN_NAME,
        )
        history.push('/login')
      },
    },
  ]

  const options = [
    {
      name: 'DATOS REQUERIDOS',
      items: [
        {
          id: 'nombre',
          name: 'Nombre',
          isChecked: !!project?.name && !errors?.name,
          isError: !!errors?.name,
        },
        {
          id: 'direccion',
          name: 'Dirección',
          isChecked: project?.address && project?.city && project?.postCode,
          isError: !!errors?.address || !!errors?.city || !!errors?.postCode,
        },
        {
          id: 'fechas',
          name: 'Fechas',
          isChecked: !!project?.start && !!project?.end,
          isError: !!errors?.start || !!errors?.end,
        },
        {
          id: 'datos',
          name: 'Datos',
          isChecked: project?.constructionType && project?.projectType,
          isError: !!errors?.constructionType || !!errors?.projectType,
        },
      ],
    },
    {
      name: 'INFORMACIÓN ADICIONAL',
      items: [
        {
          id: 'costes',
          name: 'Costes',
          isChecked: project?.budget && project?.expectedCosts && project?.kZero,
          isError: !!errors?.budget || !!errors?.expectedCosts || !!errors?.kZero,
        },
        {
          id: 'adjuntos',
          name: 'Adjuntos',
          isChecked: !!project?.files?.length || !!project?.links?.length,
        },
      ],
    },
  ]

  return (
    <Flex
      paddingY={5}
      position="fixed"
      height="100vh"
      width="100%"
      maxWidth="214px"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      style={{ zIndex: '3', maxHeight: '100vh' }}>
      <Flex flexDirection="column">
        <Flex display="flex" width="100%">
          <Flex
            alignItems="center"
            paddingTop={4}
            marginLeft={5}
            marginRight={7}
            style={{ cursor: 'pointer' }}
            onClick={() => history.push('/constructora/proyecto/')}>
            <img
              src={ecomaLogo}
              alt="ecoma logo"
              height="56px"
              width="auto"
              style={{ objectFit: 'fill' }}
            />
          </Flex>
        </Flex>
        <Box padding={5}>
          <Text sizeText="display24" fontWeight="bold" color="black">
            {hash ? 'Edita tu obra' : 'Crea tu obra'}
          </Text>

          <Text sizeText="display12" color="gray1" flexDirection="column">
            ¿Necesitas ayuda?
            <br />
            <a href="mailto:hola@conkau.io" width="100%">
              <Text as="span" color="blue1" sizeText="display12">
                Habla con nosotros
              </Text>
            </a>
          </Text>
        </Box>
        <>
          {options.map((option, index) => (
            <Box width="100%" key={index}>
              <Text sizeText="display12" color="gray2" paddingX={5} paddingY={2}>
                {option.name}
              </Text>
              {option.items.map(({ id, name, isChecked, isError }) => (
                <OptionListStyled
                  padding={4}
                  alignItems="center"
                  width="90%"
                  isActive={history?.location?.pathname?.includes(id)}
                  onClick={() =>
                    history.push(`/constructora/crear-obra/${id}${hash ? `/${hash}` : ''}`)
                  }
                  disabled={disableOptions}>
                  {!isChecked && !isError && (
                    <Icon sizeIcon="display18" color="gray3" marginRight="10px">
                      <Timer width={25} height={25} />
                    </Icon>
                  )}
                  {isChecked && !isError && (
                    <Icon sizeIcon="display18" color="green1" marginRight="10px">
                      <CheckboxCircle width={25} height={25} />
                    </Icon>
                  )}
                  {isError && (
                    <Icon sizeIcon="display18" color="red1" marginRight="10px">
                      <Spam width={25} height={25} />
                    </Icon>
                  )}
                  <Text sizeText="display14" color="black">
                    {name}
                  </Text>
                </OptionListStyled>
              ))}
            </Box>
          ))}
        </>
      </Flex>
      <Flex width="100%" paddingLeft={2} paddingRight={5}>
        <Flex
          ref={wrapperRef}
          alignItems="center"
          justifyContent="center"
          width="100%"
          backgroundColor={isHover === 'your_account' || showActions ? 'blue5' : 'transparent'}
          style={{
            cursor: 'pointer',
            borderRadius: '8px',
          }}
          onClick={() => {
            setShowActions(true)
            if (showActions) setShowActions(false)
          }}
          padding={4}
          onMouseOver={(e) => onMouseEnterHover(e)}
          onMouseOut={() => onMouseOutHover()}
          name="your_account">
          <Avatar src={src} placeholder={placeholder} style={{ pointerEvents: 'none' }} />
          <Text
            sizeText="display16"
            color="black"
            paddingLeft="8px"
            fontWeight="medium"
            style={{ pointerEvents: 'none' }}>
            Tu cuenta
          </Text>
          {showActions && (
            <Box position="relative" backgroundColor="white" style={{ 'z-index': 99 }}>
              <Box
                position="absolute"
                boxShadow="bottom"
                borderRadius={1}
                minWidth="208px"
                backgroundColor="white"
                padding={0}
                margin={0}
                bottom="-30px"
                left="30px">
                {profileActions?.map((action) => {
                  if (employee?.employeeRole !== 'Super' && action.id === 'company') return null

                  return (
                    <>
                      <Button
                        sizeButton="none"
                        colorType="none"
                        paddingBottom={1}
                        padding={4}
                        disabled={action?.isDisabled}
                        action={(e) => {
                          e.preventDefault()
                          action?.action()
                        }}
                        width="100%"
                        justifyContent="flex-start">
                        {action?.content}
                      </Button>
                      <hr className="solid" style={{ border: '1px solid #EDECF0', margin: 0 }} />
                    </>
                  )
                })}
              </Box>
            </Box>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

Sidebar2.propTypes = {
  placeholder: PropTypes.string,
  src: PropTypes.string,
}
Sidebar2.defaultProps = {
  placeholder: null,
  src: null,
}
