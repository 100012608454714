import { Checkbox } from '@proveoeng/uikit/dist/atoms/Checkbox'
import { GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { useFormikContext } from 'formik'

import { CategoriesList, CategoriesListContainer, FormContainer } from '../styles/RegisterStyles'

/**
 * @param {Object} props
 * @param {array} props.categories
 */
export const RegisterDataCategories = ({ categories }) => {
  const { values, setFieldValue } = useFormikContext()

  const handleChange = (categoryId, isCheck) => {
    const selectedCategories = isCheck
      ? [...values.categories, categoryId]
      : values.categories.filter((id) => id !== categoryId)
    setFieldValue('categories', selectedCategories)
  }

  return (
    <FormContainer>
      <GridItem gridArea="description" marginBottom={4}>
        <Text sizeText="display18" color="black" fontWeight="bold" marginBottom={2}>
          Categorías
        </Text>
        <Text sizeText="display14" color="gray" textAlign="justify">
          Selecciona todas las categorías que cubre tu empresa. Cuantas más selecciones, más fácil
          será para las constructoras encontrar tu perfil.
        </Text>
      </GridItem>
      <GridItem gridArea="fields">
        <CategoriesListContainer>
          <CategoriesList>
            {categories?.map((el) => (
              <Checkbox
                key={el.categoryId}
                name={el.categoryId}
                label={el.name}
                action={handleChange}
                ariaLabel={el.name}
                marginY={3}
              />
            ))}
          </CategoriesList>
        </CategoriesListContainer>
      </GridItem>
    </FormContainer>
  )
}
