/* eslint-disable react/no-unescaped-entities */
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Grid } from '@proveoeng/uikit/dist/atoms/Layout/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal/Modal'

export const AcceptOfferModal = ({
  acceptOfferModalOpened,
  setAcceptOfferModalOpened,
  onActionSetOffer,
}) => (
  <Modal
    isOpen={acceptOfferModalOpened}
    id="proposal-items-files"
    onModalClose={() => setAcceptOfferModalOpened(false)}
    title="Empieza a redactar tu propuesta"
    closeWithButton
    maxWidth="808px">
    <Modal.Content>
      <Box margin="0 auto" overflow="auto">
        <Flex flexDirection="column">
          <Text as="span" fontWeight="regular" color="black" sizeText="display16" paddingBottom={4}>
            Puedes empezar a redactar tu propuesta inmediatamente, o guardarla para contestarla más
            adelante. Una vez esté lista, pulsa "Enviar" y añade un mensaje para el contratista.
          </Text>
          <Text as="span" fontWeight="regular" color="black" sizeText="display16">
            Recuerda verificar la fecha límite de presentación de la oferta y ponte en contacto con
            el contratista si tienes cualquier duda.
          </Text>
        </Flex>
      </Box>
    </Modal.Content>
    <Modal.Actions>
      <Flex justifyContent="flex-end">
        <Grid gridTemplateColumns="184px" gridTemplateRows="1fr">
          <Button colorType="orange" fullWidth action={() => onActionSetOffer('Accept')}>
            Hecho
          </Button>
        </Grid>
      </Flex>
    </Modal.Actions>
  </Modal>
)
