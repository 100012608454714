import { useState } from 'react'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { ArrowDown, ArrowRight } from '@proveoeng/uikit/dist/atoms/Icons'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { useComparative } from '../../../common/hooks/useComparative'
import { EnvironmentalCostsSectionSummaryHead } from './EnvironmentalCostsSummaryHead'
import { EnvironmentalCostsSummarySection } from './EnvironmentalCostsSummarySection'
import { EnvironmentalCostsDetailsModal } from './EnvironmentalCostsDetailsModal'

export const EnvironmentalCostsSummary = ({ comparative, tableWidthCSS }) => {
  const [isOpened, setIsOpened] = useState(true)
  const [isDetailsModalOpened, setIsDetailsModalOpened] = useState({ show: false, data: '' })
  const { getOfferDetailsEnviro } = useComparative()

  if (!comparative) {
    return <></>
  }

  const offerColumnsHead = comparative.offers
    .sort((a, b) => (a.position > b.position ? 1 : -1))
    .map((offer) => `offer-${offer.id}`)
    .join(' ')

  const columnsHead = {
    area: `"description ${offerColumnsHead}"`,
    columns: `768px repeat(${comparative.offers.length}, 220px)`,
  }

  const offerColumns = comparative.offers
    .sort((a, b) => (a.position > b.position ? 1 : -1))
    .map((offer) => `offer-k-${offer.id} offer-margin-${offer.id}`)
    .join(' ')

  const columns = {
    area: `"description ${offerColumns}"`,
    columns: `768px repeat(${comparative.offers.length * 2}, 90px 130px)`,
  }

  const getDetails = async (proposalId) => {
    const data = await getOfferDetailsEnviro(proposalId)
    setIsDetailsModalOpened({ show: true, data })
  }

  const setDetailsLink = (offer) => (
    <Text
      color="blue1"
      textAlign="right"
      sizeText="display12"
      style={{ textDecoration: 'underline', cursor: 'pointer' }}
      onClick={() => getDetails(offer?.proposalId)}>
      Ver detalle
    </Text>
  )

  return (
    <Flex
      paddingBottom={6}
      flexDirection="column"
      position="relative"
      style={{ width: tableWidthCSS }}>
      <Flex
        justifyContent="space-between"
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 11,
          backgroundColor: 'white',
        }}
        paddingBottom={4}>
        <Flex
          alignItems="center"
          paddingX={3}
          style={{
            position: 'sticky',
            left: '0px',
          }}>
          <Icon
            style={{ cursor: 'pointer' }}
            sizeIcon="display18"
            color="black"
            marginRight={1}
            onClick={() => setIsOpened(!isOpened)}>
            {isOpened ? <ArrowDown /> : <ArrowRight />}
          </Icon>
          <Flex alignItems="center">
            <Text
              as="span"
              marginLeft={1}
              sizeText="display18"
              color="black"
              style={{ cursor: 'pointer' }}
              onClick={() => setIsOpened(!isOpened)}>
              Resumen
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {isOpened && (
        <>
          <EnvironmentalCostsSectionSummaryHead
            offers={comparative.offers}
            columnsHead={columnsHead}
            tableWidthCSS={tableWidthCSS}
          />
          {comparative?.sections.map((section) => (
            <EnvironmentalCostsSummarySection
              section={section}
              offers={comparative.offers}
              columns={columns}
              tableWidthCSS={tableWidthCSS}
              setDetailsLink={setDetailsLink}
            />
          ))}

          <EnvironmentalCostsDetailsModal
            isOpen={isDetailsModalOpened.show}
            onClose={() => setIsDetailsModalOpened({ show: false, data: '' })}
            data={isDetailsModalOpened.data}
          />
        </>
      )}
    </Flex>
  )
}
