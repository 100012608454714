import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Box, Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'

export const ModalDeleteBidPackage = ({
  deleteModalIsOpened,
  setDeleteModalIsOpened,
  onDeleteBidPackage,
}) => (
  <>
    <Modal
      isOpen={deleteModalIsOpened}
      id="delete-package-modal"
      onModalClose={() => setDeleteModalIsOpened(false)}
      minWidth="300"
      width="390px"
      paddingTop={6}>
      <Modal.Content>
        <Box>
          <Text sizeText="display16">¿Seguro que quieres eliminar esta compra?</Text>
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Grid gridTemplateColumns="1fr 1fr" gridTemplateRows="1fr" gridColumnGap={5}>
            <Button
              colorType="orange"
              action={() => {
                onDeleteBidPackage()
              }}>
              Si
            </Button>
            <Button
              id="cancel-button"
              colorType="black"
              action={() => setDeleteModalIsOpened(false)}>
              No
            </Button>
          </Grid>
        </Flex>
      </Modal.Actions>
    </Modal>
  </>
)
