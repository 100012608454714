import { Image } from '@proveoeng/uikit/dist/atoms/Image/Image'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import ECOMA_CARTEL from '../../../../common/assets/ECOMA_CARTEL.png'

const HomeProjectInfoPage = () => (
  <Flex height="100%" width="100%" flexDirection="column">
    <Image
      src={ECOMA_CARTEL}
      alt="ECOMA CARTEL"
      height="100%"
      width="auto"
      styleImg={{ objectFit: 'contain' }}
    />
  </Flex>
)

export default HomeProjectInfoPage
