import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Checkbox } from '@proveoeng/uikit/dist/atoms/Checkbox'
import { Box, Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Tooltip } from '@proveoeng/uikit/dist/atoms/Tooltip'
import { formatNumber } from '../../../common/functions/comparativeNumbers'
import { getDinamySchema } from '../../../../lib/utils/getDinamySchema'
import { parseToInteger, parseToString } from '../../../../lib/utils/Format'
import { useEditMode } from '../../../common/hooks/useEditMode'
import { SelectUnit } from '../../../common/components/SelectUnit'
import { ItemEditable } from '../../buyer/common/components/ItemEditable'

export const SectionBidItem = ({
  item,
  setItem,
  setOfferItem,
  offers,
  columns,
  handleSelectedBidItems,
  getBidItemOfferDetailsRequest,
}) => {
  const offerItemsName = offers.map((offer) => `offerCost-${offer.id}`)
  const [active, setActive] = useState(false)

  const validationSchema = Yup.object().shape({
    description: Yup.string().required('Campo requerido'),
    unit: Yup.string().required('Campo requerido'),
    measure: Yup.string().required('Campo requerido'),
    budgetCost: Yup.string().nullable(),
    expectedCost: Yup.string().nullable(),
    ...getDinamySchema(offerItemsName),
  })

  const initialValuesOffers = offers.reduce(
    (acc, offer) => ({
      ...acc,
      [`offerCost-${offer.id}`]: parseToString(offer.getItem(item.id)?.price),
    }),
    {},
  )

  const initialValues = {
    description: item.description,
    unit: item.unit,
    measure: parseToString(item.measure || 0, 3),
    budgetCost: parseToString(item.budgetCost),
    expectedCost: parseToString(item.expectedCost),
    minEnvelope: parseToString(item.minEnvelope),
    ...initialValuesOffers,
  }

  const onSubmit = (values) => {
    setItem({
      ...item,
      unit: values.unit,
      description: values.description,
      measure: values.measure ? parseToInteger(values.measure, 3) : 0,
      budgetCost: values.budgetCost ? parseToInteger(values.budgetCost) : 0,
      expectedCost: values.expectedCost ? parseToInteger(values.expectedCost) : 0,
    })
    offers.forEach((offer) => {
      setOfferItem({
        offerId: offer.id,
        itemId: item.id,
        price: parseToInteger(values[`offerCost-${offer.id}`]),
      })
    })
  }

  const { values, errors, handleChange, handleSubmit, submitForm, setFieldValue } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  })

  const { editMode, handleEditMode, handleEditSubmit, ref } = useEditMode({
    onSubmit: submitForm,
  })

  const setDetailsLink = (offer, offerItem) => {
    const hasDetails = offer?.offerItems.find(
      (itemOffer) => itemOffer.itemId === offerItem.id,
    ).proposalItemInfo

    if (!hasDetails) return null

    return (
      <Text
        color="blue1"
        textAlign="right"
        sizeText="display12"
        style={{ textDecoration: 'underline', cursor: 'pointer' }}
        onClick={() => getBidItemOfferDetailsRequest(offer, offerItem)}>
        Ver detalle
      </Text>
    )
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid gridTemplateAreas={columns.area} gridTemplateColumns={columns.columns}>
          <GridItem
            gridArea="checkbox"
            bg="white2"
            paddingY={3}
            style={{
              position: 'sticky',
              left: 0,
              zIndex: 9,
            }}>
            <Flex>
              <Checkbox
                key={`item-${item?.id}`}
                marginLeft={3}
                marginTop={1}
                action={(_, isChecked) => handleSelectedBidItems(item.id, isChecked)}
                defaultChecked={false}
                name={`item-${item?.id}`}
              />
            </Flex>
          </GridItem>

          <ItemEditable
            isTextArea
            gridArea="bidpackage"
            name="description"
            placeholderMessage="Descripción"
            value={values.description}
            handleChange={handleChange}
            error={errors.description}
            onSubmit={submitForm}
            hasBorder
            style={{
              paddingLeft: 10,
              position: 'sticky',
              left: 30,
              zIndex: 9,
            }}>
            {item.description}
          </ItemEditable>

          <ItemEditable
            gridArea="unit"
            name="unit"
            placeholderMessage="Unidad"
            value={values.unit}
            handleChange={handleChange}
            textAlign="right"
            error={errors.unit}
            onSubmit={submitForm}
            hasBorder
            style={{
              position: 'sticky',
              left: 330,
              zIndex: 9,
            }}>
            {item.unit}
          </ItemEditable>

          <GridItem
            gridArea="unit"
            bg="white2"
            {...{
              borderRightColor: 'gray3',
              borderRightStyle: 'solid',
              borderRightWidth: '1px',
            }}
            paddingRight={2}
            paddingY={4}
            ref={ref}
            style={{
              position: 'sticky',
              left: 330,
              zIndex: editMode ? 10 : 9,
            }}>
            <Box textAlign="right">
              {editMode || errors.unit ? (
                <Box paddingX={2} width="180px">
                  <SelectUnit
                    name="unit"
                    required
                    placeholderMessage="Unidad"
                    isSearchable
                    onChange={(_, v) => {
                      if (v) {
                        setFieldValue('unit', v.value)
                        handleEditSubmit()
                      }
                    }}
                    defaultValue={values?.unit}
                  />
                  <Text sizeText="display10" color="red1">
                    {errors.unit}
                  </Text>
                </Box>
              ) : (
                <Text
                  sizeText="display12"
                  color="gray1"
                  overflow="hidden"
                  fontWeight="regular"
                  onClick={handleEditMode}
                  style={{
                    cursor: 'pointer',
                  }}>
                  {item.unit}
                </Text>
              )}
            </Box>
          </GridItem>

          <ItemEditable
            gridArea="measure"
            name="measure"
            isDecimal
            placeholderMessage="Medición"
            value={values.measure}
            handleChange={handleChange}
            textAlign="right"
            error={errors.measure}
            onSubmit={submitForm}
            hasBorder
            numeralDecimalScale={3}
            style={{
              position: 'sticky',
              left: 410,
              zIndex: 9,
            }}>
            {parseToString(item?.measure, 3)}
          </ItemEditable>

          <ItemEditable
            gridArea="budgetCost"
            name="budgetCost"
            isDecimal
            placeholderMessage="Ppto. de venta"
            value={values.budgetCost}
            handleChange={handleChange}
            textAlign="right"
            error={errors.budgetCost}
            onSubmit={submitForm}
            style={{
              position: 'sticky',
              left: 510,
              zIndex: 9,
            }}>
            {!item?.budgetCost ? '-' : parseToString(item?.budgetCost)}
          </ItemEditable>

          <GridItem
            gridArea="budget"
            bg="white2"
            borderRightColor="gray3"
            borderRightStyle="solid"
            borderRightWidth="1px"
            paddingRight={2}
            paddingY={4}
            style={{
              position: 'sticky',
              left: 600,
              zIndex: 9,
            }}>
            <Text
              sizeText="display12"
              textAlign="right"
              color="gray1"
              overflow="hidden"
              fontWeight="regular">
              {!item?.budgetCost ? '-' : formatNumber(item?.getTotalBudgetCost())}
            </Text>
          </GridItem>

          <ItemEditable
            gridArea="expectedCostsUnit"
            name="expectedCost"
            isDecimal
            placeholderMessage="Costo previsto"
            value={values.expectedCost}
            handleChange={handleChange}
            textAlign="right"
            error={errors.expectedCost}
            onSubmit={submitForm}
            style={{
              position: 'sticky',
              left: 730,
              zIndex: 9,
            }}>
            {!item?.expectedCost ? '-' : parseToString(item?.expectedCost)}
          </ItemEditable>

          <GridItem
            gridArea="expectedCosts"
            bg="white2"
            borderRightColor="gray3"
            borderRightStyle="solid"
            borderRightWidth="1px"
            paddingRight={2}
            paddingY={4}
            style={{
              position: 'sticky',
              left: 820,
              zIndex: 9,
            }}>
            <Text
              sizeText="display12"
              textAlign="right"
              color="gray1"
              overflow="hidden"
              fontWeight="regular">
              {!item?.expectedCost ? '-' : formatNumber(item?.getTotalExpectedCost())}
            </Text>
          </GridItem>
          <GridItem
            gridArea="minEnvelopeUnit"
            bg="white2"
            paddingRight={2}
            paddingY={4}
            style={{
              position: 'sticky',
              left: 950,
              zIndex: 9,
            }}>
            <Flex justifyContent="flex-end">
              <Text sizeText="display12" textAlign="right" color="gray1" fontWeight="regular">
                {!item?.getMinEnvelope(offers).price
                  ? '-'
                  : parseToString(item?.getMinEnvelope(offers).price)}
              </Text>
            </Flex>
          </GridItem>

          <GridItem
            gridArea="minEnvelope"
            bg="white2"
            borderRightColor="gray3"
            borderRightStyle="solid"
            borderRightWidth="1px"
            paddingRight={2}
            paddingY={4}
            style={{
              position: 'sticky',
              left: 1040,
              zIndex: 9,
              textAlign: 'right',
            }}>
            <Tooltip
              active={active}
              setActive={setActive}
              content={
                !item?.getMinEnvelope(offers).price
                  ? 'Partida sin precio ofertado'
                  : item?.getMinEnvelope(offers).name
              }>
              <Flex justifyContent="flex-end">
                <Text
                  sizeText="display12"
                  textAlign="right"
                  color="gray1"
                  overflow="hidden"
                  fontWeight="regular">
                  {!item?.getMinEnvelope(offers).price
                    ? '-'
                    : parseToString(item?.getMinEnvelope(offers).total)}
                </Text>
              </Flex>
            </Tooltip>
          </GridItem>

          {offers.map((offer) => (
            <>
              <ItemEditable
                bg="white"
                gridArea={`offer-unit-${offer.id}`}
                name={`offerCost-${offer.id}`}
                isDecimal
                placeholderMessage="Unidad"
                value={values[`offerCost-${offer.id}`]}
                handleChange={handleChange}
                textAlign="right"
                error={errors[`offerCost-${offer.id}`]}
                onSubmit={submitForm}>
                {!offer.getItem(item.id)?.price
                  ? '-'
                  : parseToString(offer.getItem(item.id)?.price)}
              </ItemEditable>

              <GridItem
                gridArea={`offer-total-${offer.id}`}
                bg="white"
                borderRightColor="gray3"
                borderRightStyle="solid"
                borderRightWidth="1px"
                paddingRight={2}
                paddingY={4}>
                <Text
                  sizeText="display12"
                  textAlign="right"
                  color="gray1"
                  overflow="hidden"
                  fontWeight="regular">
                  {!offer.getItemTotal(item) ? '-' : formatNumber(offer.getItemTotal(item))}
                </Text>
                {setDetailsLink(offer, item)}
              </GridItem>
            </>
          ))}
        </Grid>
      </form>
    </>
  )
}
