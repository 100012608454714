export const setLocalStorage = (name, value) => {
  localStorage.setItem(name, value)
}

export const getLocalStorage = (name) => localStorage.getItem(name)

export const deleteLocalStorage = (name) => localStorage.removeItem(name)

export const setSessionStorage = (name, value) => {
  sessionStorage.setItem(name, value)
}

export const getSessionStorage = (name) => sessionStorage.getItem(name)

export const deleteSessionStorage = (name) => sessionStorage.removeItem(name)
