import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useOffer } from '../../../../common/hooks/useOffer'
import { useSeller } from '../../../../common/hooks/useSeller'

import { DetailOfferTemplate } from '../template/DetailOfferTemplate'

const DetailOfferPage = () => {
  const history = useHistory()
  const { hash, offerId } = useParams()
  const {
    data: { employeeId },
  } = useSelector((state) => state.auth)
  const {
    offers: { pending, opened },
  } = useSelector((state) => state.employee)
  const [proposalMatched, setProposalMatched] = useState('')
  const [acceptOfferModalOpened, setAcceptOfferModalOpened] = useState(false)
  const [isModalContactOpened, setIsModalContactOpened] = useState(false)

  const { resetStoreProposal, cancelRequest, getBidPackageOffer, actionBidPackageOffer } =
    useSeller()

  const { getPendingOffers } = useOffer()

  useEffect(() => {
    resetStoreProposal()
    getPendingOffers()

    return () => {
      cancelRequest()
    }
  }, [])

  useEffect(() => {
    if (offerId) {
      getBidPackageOffer(hash, offerId)
    }
  }, [offerId])

  useEffect(() => {
    setProposalMatched(pending.filter((pend) => pend.bidPackageId === hash) || '')
  }, [pending, opened])

  const onActionSetOffer = async (action) => {
    await actionBidPackageOffer(hash, offerId, employeeId, action)
    if (action === 'Reject') {
      history.push('/proveedor/compras-nuevas')
    }
  }

  return (
    <DetailOfferTemplate
      project={opened}
      goBack={() => history.goBack()}
      haveActions={history?.location?.pathname?.includes('compras')}
      onActionSetOffer={onActionSetOffer}
      proposal={proposalMatched[0]}
      acceptOfferModalOpened={acceptOfferModalOpened}
      setAcceptOfferModalOpened={setAcceptOfferModalOpened}
      isModalContactOpened={isModalContactOpened}
      setIsModalContactOpened={setIsModalContactOpened}
      contactData={opened?.contact}
    />
  )
}

export default DetailOfferPage
