import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Card } from '@proveoeng/uikit/dist/atoms/Card'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'

import { Header } from '../Header/Header'
import { Sidebar1 } from '../Sidebar/Sidebar1'

const HeaderContainerStyled = styled(Flex)`
  height: 60px;
  align-items: center;

  @media (max-width: 768px) {
    * {
      justify-content: center;
    }
  }
`

const CardContainerStyled = styled(Box)`
  height: calc(100vh - 131px);
  padding-left: 88px;

  @media (max-width: 1024px) {
    padding-left: 0;
  }
`

const CardStyled = styled(Card)`
  padding: 56px 24px 24px 56px;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  border-radius: 0;
  border-top-left-radius: 8px;
  border-color: white;

  @media (max-width: 768px) {
    padding: 24px;
  }
`

const ActionContainerStyled = styled(Flex)`
  background-color: #fff;
  justify-content: end;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #e6e6e6;
  padding: 12px;
`

const ActionButton = styled(Button)`
  width: 168px;
  margin-left: 16px;

  @media (max-width: 768px) {
    width: 120px;
    margin-left: 8px;
  }
`

/**
 * @param {Object} props
 * @param {Object} props.children
 * @param {void} props.goBack
 * @param {void} props.onSubmit
 * @param {string} props.type
 * @param {number} props.step
 * @param {number} props.totalSteps
 */
export const LayoutRegister = ({ goBack, onSubmit, children, type, step, totalSteps }) => {
  const employee = useSelector((state) => state.employee?.data)
  const { username } = useSelector((state) => state.auth?.data)

  return (
    <>
      <HeaderContainerStyled>
        <Header type={type} />
      </HeaderContainerStyled>
      {username && <Sidebar1 src={employee?.avatar} placeholder={username} />}
      <CardContainerStyled bg="gray4">
        <CardStyled>{children}</CardStyled>
        {onSubmit && (
          <ActionContainerStyled borderTopColor="gray3">
            {step > 0 && (
              <ActionButton
                type="button"
                colorType="transparent"
                sizeButton="medium"
                action={goBack}>
                Volver
              </ActionButton>
            )}
            <ActionButton type="button" colorType="orange" sizeButton="medium" action={onSubmit}>
              {step === undefined && 'Guardar'}
              {step === totalSteps - 1 && 'Crear cuenta'}
              {step < totalSteps - 1 && 'Siguiente'}
            </ActionButton>
          </ActionContainerStyled>
        )}
      </CardContainerStyled>
    </>
  )
}
