import * as Yup from 'yup'

export const editProposalSchema = Yup.object().shape({
  acceptRetentionConditions: Yup.boolean(),
  retention: Yup.string()
    .nullable()
    .when('acceptRetentionConditions', {
      is: false,
      then: (schema) => schema.required('Ingrese el porcentaje de retención'),
    }),
  retentionCriteria: Yup.string().nullable(),
  modifyDeliveryDates: Yup.boolean(),
  start: Yup.date()
    .min(new Date(), 'La fecha de inicio de entrega no puede ser anterior al día de hoy.')
    .when('modifyDeliveryDates', {
      is: true,
      then: (schema) => schema.required('Ingrese la fecha de inicio de entrega'),
    }),
  end: Yup.date()
    .min(
      Yup.ref('start'),
      'La fecha de fin de entrega no puede ser anterior a la fecha de inicio de entrega.',
    )
    .when('modifyDeliveryDates', {
      is: true,
      then: (schema) => schema.required('Ingrese la fecha de fin de entrega'),
    }),
  modifyDeliveryTime: Yup.boolean(),
  deliveryTime: Yup.string()
    .nullable()
    .when('modifyDeliveryTime', {
      is: true,
      then: (schema) => schema.required('Ingrese el plazo de entrega'),
    }),
  acceptPaymentConditions: Yup.boolean(),
  paymentDays: Yup.string()
    .nullable()
    .when('acceptPaymentConditions', {
      is: false,
      then: (schema) => schema.required('Ingrese los días de pago'),
    }),
  paymentConditions: Yup.string().nullable(),
  comments: Yup.string().nullable(),

  // Enviro
  distanceToBuildingSite: Yup.string().nullable(),
  recycledMaterials: Yup.string().nullable(),
  enviroComments: Yup.string().nullable(),
})
