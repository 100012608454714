import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { parseToInteger, parseToString } from '../../../../lib/utils/Format'

export const SummaryOfferImprovementRate = ({ data, offer, improvementRate }) => {
  const getImprovementRate = () => {
    let improvementRateValue = 0
    if (data.getTotalExpectedCost() && offer?.getTotal(data.sections)) {
      improvementRateValue =
        parseToInteger(1 - offer?.getTotal(data.sections) / data.getTotalExpectedCost()) * 100
    } else {
      improvementRateValue = null
    }
    return improvementRateValue
  }

  const getColor = () => {
    if (!improvementRate) return 'gray1'

    const improvementRateFormatted = improvementRate / 100
    const value = getImprovementRate() / 100

    if (value > improvementRateFormatted && value !== 0) {
      return 'green1'
    }
    if (value < improvementRateFormatted && value !== 0) {
      return 'red1'
    }
    return 'gray1'
  }

  return (
    <Text
      textAlign="right"
      color={getColor()}
      overflow="hidden"
      fontWeight="700"
      sizeText="display12">
      {getImprovementRate() ? `${parseToString(getImprovementRate() * 100)}%` : '-'}
    </Text>
  )
}
