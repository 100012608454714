import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'

export const CreateProjectNameTemplate = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
}) => (
  <>
    <Text as="span" sizeText="display32" color="black" marginBottom={3} fontWeight="bold">
      Añade el nombre y la referencia de la obra
    </Text>
    <Field
      label="Nombre"
      required
      sizeText="display16"
      marginBottom={3}
      marginLeft={2}
      errorMessage={touched.name && errors.name}>
      <Input
        placeholderMessage="Dale un nombre a tu obra"
        ariaLabel="Nombre"
        name="name"
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        hasError={touched.name && !!errors.name}
      />
    </Field>
    <Field
      label="Referencia / OT"
      sizeText="display16"
      marginBottom={3}
      marginTop={5}
      marginLeft={2}
      errorMessage={touched.reference && errors.reference}>
      <Input
        placeholderMessage="Pon tu número de referencia"
        name="reference"
        ariaLabel="reference"
        value={values.reference}
        onChange={handleChange}
        onBlur={handleBlur}
        hasError={touched.reference && !!errors.reference}
      />
    </Field>
  </>
)
