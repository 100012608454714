import { ButtonTabs } from '@proveoeng/uikit/dist/atoms/Button'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { EditProposalCostEmission } from './EditProposalCostEmission'
import { EditProposalBidItems } from './EditProposalBidItems'
import { EditProposalConditions } from './EditProposalConditions'
import { EditProposalInfo } from './EditProposalInfo'
import { EnvironmentalStatement } from './EnvironmentalStatement'

export const EditProposal = ({
  tabActive,
  tabs,
  proposal,
  proposalItems,
  bidPackage,
  valuesProposal,
  onHandleChangeProposal,
  errorsData,
  files,
  links,
  onDeleteFile,
  errorsImages,
  onImagesLoad,
  handleChangeLinks,
  handleExportBidPackagePrices,
  openImportModal,
}) => (
  <Flex
    flexDirection="column"
    alignItems="baseline"
    paddingX={0}
    margin="0 auto"
    width="100%"
    height="100%">
    <ButtonTabs items={tabs} />
    <Box paddingY={5} width="100%">
      {tabActive === 'info' && <EditProposalInfo project={proposal?.project} />}

      {tabActive === 'conditions' && (
        <EditProposalConditions
          bidPackage={bidPackage}
          onHandleChangeProposal={onHandleChangeProposal}
          valuesProposal={valuesProposal}
          errorsData={errorsData}
          files={files}
          onDeleteFile={onDeleteFile}
          errorsImages={errorsImages}
          links={links}
          onImagesLoad={onImagesLoad}
          handleChangeLinks={handleChangeLinks}
        />
      )}

      {tabActive === 'bidItems' && (
        <EditProposalBidItems
          proposalItems={proposalItems}
          handleExportBidPackagePrices={handleExportBidPackagePrices}
          openImportModal={openImportModal}
        />
      )}

      {tabActive === 'emissionCost' && <EditProposalCostEmission proposalItems={proposalItems} />}

      {tabActive === 'environmentalStatement' && <EnvironmentalStatement />}
    </Box>
  </Flex>
)
