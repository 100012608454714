import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Box, Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Textarea } from '@proveoeng/uikit/dist/atoms/Textarea'
import { Radiobox } from '@proveoeng/uikit/dist/atoms/Radiobox'

export const CreateProjectDataTemplate = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  setFieldValue,
}) => (
  <>
    <Text as="span" sizeText="display32" color="black" marginBottom={3} fontWeight="bold">
      Necesitamos los principales datos de tu obra
    </Text>
    <Box maxWidth={648}>
      <Field
        label="Cliente"
        sizeText="display16"
        marginBottom={3}
        marginLeft={2}
        errorMessage={touched.customer && errors.customer}>
        <Input
          placeholderMessage="Nombre del cliente"
          ariaLabel="customer"
          name="customer"
          marginBottom={3}
          maxLength={50}
          minLength={3}
          value={values.customer}
          onChange={handleChange}
          onBlur={handleBlur}
          hasError={touched.customer && !!errors.customer}
        />
      </Field>
      <Field
        required
        label="Tipo de cliente"
        sizeText="display16"
        marginBottom={3}
        marginTop={5}
        errorMessage={touched.constructionType && errors.constructionType}>
        <Grid gridTemplateColumns="1fr 1fr" gridGap={2} maxWidth={200}>
          <Radiobox
            hasError={touched.constructionType && !!errors.constructionType}
            onChange={(_, value) => setFieldValue('constructionType', value)}
            items={[
              {
                id: 'Public',
                name: 'constructionType',
                defaultChecked: values.constructionType === 'Public',
                isDisabled: false,
                hasError: false,
                label: 'Publico',
                defaultValue: 'Public',
              },
              {
                id: 'Private',
                name: 'constructionType',
                defaultChecked: values.constructionType === 'Private',
                isDisabled: false,
                hasError: false,
                label: 'Privado',
                defaultValue: 'Private',
              },
            ]}
          />
        </Grid>
      </Field>
      <Field
        required
        label="Tipo de Proyecto"
        sizeText="display16"
        marginBottom={3}
        marginTop={5}
        errorMessage={touched.projectType && errors.projectType}>
        <Grid gridTemplateColumns="1fr 1fr" gridGap={2} maxWidth={200}>
          <Radiobox
            onChange={(_, value) => setFieldValue('projectType', value)}
            hasError={touched.projectType && !!errors.projectType}
            items={[
              {
                id: 'Study',
                name: 'projectType',
                defaultChecked: values?.projectType === 'Study',
                isDisabled: false,
                hasError: false,
                label: 'Estudio',
                defaultValue: 'Study',
              },
              {
                id: 'Execution',
                name: 'projectType',
                defaultChecked: values?.projectType === 'Execution',
                isDisabled: false,
                hasError: false,
                label: 'Ejecución',
                defaultValue: 'Execution',
              },
            ]}
          />
        </Grid>
      </Field>
      <Field
        label="Descripción"
        sizeText="display16"
        marginBottom={3}
        marginTop={5}
        marginLeft={2}
        errorMessage={touched.description && errors.description}>
        <Textarea
          placeholderMessage="Añade una pequeña descripción de tu obra"
          name="description"
          // typeAction={(_, v) => setFieldValue('description', v)}
          onChange={handleChange}
          value={values.description}
          onBlur={handleBlur}
          hasError={touched.description && !!errors.description}
          maxLength="auto"
        />
      </Field>
    </Box>
  </>
)
