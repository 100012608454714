import { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'

import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Info } from '@proveoeng/uikit/dist/atoms/Icons'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Grid } from '@proveoeng/uikit/dist/atoms/Layout/Layout'
import { SelectInput } from '@proveoeng/uikit/dist/atoms/Select'
import { Textarea } from '@proveoeng/uikit/dist/atoms/Textarea'
import { Tooltip } from '@proveoeng/uikit/dist/atoms/Tooltip'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Uploader } from '@proveoeng/uikit/dist/atoms/Uploader'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal/Modal'

import { AttachmentList } from '../../../../common/components/attachmentList/Attachment'
import { LinksList } from '../../../../common/components/links/LinksList'
import { useAttachmentLinks } from '../../../../common/hooks/useAttachmentLinks'
import { errorsImages } from '../../common/config'
import { onImagesLoad } from '../../../../common/functions/data'

const qualityOptions = [
  {
    value: 'VeryHigh',
    label: 'Muy alta',
  },
  {
    value: 'High',
    label: 'Alta',
  },
  {
    value: 'Medium',
    label: 'Media',
  },
  {
    value: 'Low',
    label: 'Baja',
  },
  {
    value: 'VeryLow',
    label: 'Muy baja',
  },
]

export const ModalDetailsCeCo2 = ({ isOpened, onClose, item }) => {
  const { values, errors, touched, handleBlur, setValues } = useFormikContext()

  const commentName = `commentsEnviro-${item?.proposalItemId}`
  const filesName = `filesEnviro-${item?.proposalItemId}`
  const filesToDeleteName = `filesToDeleteEnviro-${item?.proposalItemId}`
  const linksName = `linksEnviro-${item?.proposalItemId}`
  const linksToDeleteName = `linksToDeleteEnviro-${item?.proposalItemId}`
  const qualityName = `dataQuality-${item?.proposalItemId}`

  const [contentIsOpened, setContentIsOpened] = useState(false)

  const [comments, setComments] = useState('')

  const [files, setFiles] = useState(values[filesName] || [])
  const [filesToDelete, setFilesToDelete] = useState([])
  const [quality, setQuality] = useState('')

  const {
    links,
    inputLink,
    setLinks,
    deleteLinksId,
    setInputLink,
    addLink,
    handleAddDescription,
    removeLink,
    verifyUrl,
  } = useAttachmentLinks(values[linksName] || [])

  useEffect(() => {
    reset()
  }, [item])

  const reset = () => {
    setComments(values[commentName])
    setFiles(values[filesName] || [])
    setLinks(values[linksName] || [])
    setQuality(values[qualityName])
  }

  const handleChangeComment = (e) => setComments(e.target.value)
  const handleChangeQuality = (value) => setQuality(value)

  const handleAddFile = (files) => setFiles((prev) => [...prev, ...files])

  const handleDeleteFile = (file) => {
    const updateFiles = file?.fileId
      ? files?.filter((element) => element?.fileId !== file.fileId)
      : files?.filter((element) => element?.name !== file.name)
    setFiles(updateFiles)
    if (file?.fileId) {
      setFilesToDelete((prev) => [...prev, file])
    }
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  const handleSubmit = () => {
    setValues((prev) => ({
      ...prev,
      [commentName]: comments,
      [filesName]: files,
      [filesToDeleteName]: [...(values[filesToDeleteName] || []), ...filesToDelete],
      [linksName]: links,
      [linksToDeleteName]: [...(values[linksToDeleteName] || []), ...deleteLinksId],
      [qualityName]: quality,
    }))
    onClose()
  }

  return (
    <Modal
      isOpen={isOpened}
      id="proposal-items-files"
      onModalClose={handleClose}
      title="Detalles de la partida"
      closeWithButton
      maxWidth="570px">
      <Modal.Content>
        <Box>
          <Field
            label={
              <Flex alignItems="center">
                Calidad
                <Tooltip
                  content={
                    <Box>
                      <Text as="h4" sizeText="display14" color="black" fontWeight="600" mb={2}>
                        Muy alta: procedente de Declaración Ambiental de Producto especifica en
                        vigor
                      </Text>
                      <Text as="h4" sizeText="display14" color="black" fontWeight="600" mb={2}>
                        Alta: procedente de Declaración Ambiental de Producto genérica en vigor, o
                        dato declarado por el fabricante, firmado y fechado en el último año
                      </Text>
                      <Text as="h4" sizeText="display14" color="black" fontWeight="600" mb={2}>
                        Media: procedente de una DAP específica o genérica caducada, o de una
                      </Text>
                      <Text as="h4" sizeText="display14" color="black" fontWeight="600" mb={2}>
                        Baja: base de datos genérica, pública o privada, revisada en el último año y
                        referida a productos fabricados/comercializados en el país
                      </Text>
                      <Text as="h4" sizeText="display14" color="black" fontWeight="600" mb={2}>
                        Muy baja: base de datos genérica, pública o privada, NO revisada en el
                        último año y referida a productos fabricados/comercializados en el país
                      </Text>
                    </Box>
                  }
                  active={contentIsOpened}
                  setActive={setContentIsOpened}
                  direction="right"
                  bgColor="gray4"
                  width="450px">
                  <Icon sizeIcon="display30" color="gray1">
                    <Info />
                  </Icon>
                </Tooltip>
              </Flex>
            }
            sizeText="display16"
            marginBottom={3}
            marginTop={5}
            errorMessage={touched[qualityName] && errors[qualityName]}>
            <SelectInput
              required
              name={qualityName}
              id="quality"
              onChange={(_, v) => handleChangeQuality(v.value)}
              options={qualityOptions}
              placeholderMessage="Seleccionar la opción"
              ariaLabel="turnover"
              hasError={touched[qualityName] && !!errors[qualityName]}
              defaultValue={
                qualityOptions.find((option) => option.value === values[qualityName]) || null
              }
              onBlur={handleBlur}
            />
          </Field>
          <Field
            label="Aclaraciones sobre las emisiones de CO2"
            sizeText="display16"
            marginBottom={3}
            errorMessage={touched[commentName] && errors[commentName]}>
            <Textarea
              placeholderMessage="Escribe tu estimación"
              name="estimate"
              onChange={handleChangeComment}
              value={values[commentName]}
              onBlur={handleBlur}
              hasError={touched[commentName] && !!errors[commentName]}
              maxLength="auto"
            />
          </Field>
          <Box marginBottom={5}>
            <Field
              label="Si quieres adjuntar algún documento de la partida puedes hacerlo aquí."
              color="black"
              sizeText="display16">
              <AttachmentList files={files} withDelete onDeleteFile={handleDeleteFile} />
              <Box>
                <Uploader
                  multiple
                  name="files"
                  marginBottom={3}
                  errors={errorsImages}
                  maxNumFiles={5}
                  onImagesLoad={(files) => onImagesLoad(files, handleAddFile)}
                  descriptionMessage="Arrastra o haz click aquí para subir tus ficheros aquí"
                  accept="all"
                  padding={5}
                />
              </Box>
            </Field>
          </Box>
          <Box marginBottom={5}>
            <LinksList
              links={links}
              inputLink={inputLink}
              setInputLink={setInputLink}
              addLink={addLink}
              handleAddDescription={handleAddDescription}
              removeLink={removeLink}
              verifyUrl={verifyUrl}
            />
          </Box>
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Grid gridTemplateColumns="184px" gridTemplateRows="1fr">
            <Button colorType="orange" fullWidth width="184px" action={handleSubmit}>
              Guardar
            </Button>
          </Grid>
        </Flex>
      </Modal.Actions>
    </Modal>
  )
}
