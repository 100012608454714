import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Comment, FileIcon, LinkIcon } from '@proveoeng/uikit/dist/atoms/Icons'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { ActionMenu } from '@proveoeng/uikit/dist/molecules/ActionsMenu/ActionMenu'
import { DataTable } from '@proveoeng/uikit/dist/molecules/DataTable'

import { currencyFormat, parseToInteger, parseToString } from '../../../../../../lib/utils/Format'

/**
 *
 * @param {{
 *  bidItems: BidItems,
 *  setFormIsOpened: (open: boolean) => void
 *  bidPackage: BidPackage,
 *  onEditBidItem: (bidItemId: string) => void,
 *  onActionBidItem: (bidItemId: string, actionText: string) => void,
 *  setDeleteBidItemModalIsOpened: (open: boolean) => void,
 *  setSelectId: (id: string) => void,
 * }} props
 */
export const BidItemList = ({
  bidItems = [],
  bidPackage,
  onEditBidItem,
  onActionBidItem,
  setDeleteBidItemModalIsOpened,
  setSelectId,
  totalCosts,
}) => {
  const getTotal = (amount, measurement) =>
    parseToString((parseToInteger(amount) * parseToInteger(measurement, 3)) / 1000)

  const columns = [
    {
      name: 'Partida',
      selector: (row) => row.name.toLowerCase(),
      cell: (row) => (
        <Text
          as="span"
          sizeText="display14"
          fontWeight="medium"
          onClick={() => onEditBidItem(row.bidItemId)}
          style={{ cursor: 'pointer' }}>
          {row.name}
        </Text>
      ),
      sortable: true,
      width: '280px',
    },
    {
      name: 'Unidad',
      selector: (row) => row.unit,
      sortable: true,
      width: '92px',
    },
    {
      name: 'Medición',
      selector: (row) => row.measurement,
      sortable: true,
      width: '106px',
      right: true,
    },
    {
      name: (
        <Box marginBottom={2} width="100%">
          <Box marginBottom={2} paddingTop={4}>
            <Text textAlign="right" sizeText="display14" overflow="hidden" fontWeight="600">
              Presupuesto de venta
            </Text>
          </Box>
          <Flex width="100%" justifyContent="space-between">
            <Text
              width="110px"
              textAlign="right"
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              sizeText="display10">
              €/ud.
            </Text>
            <Text
              width="120px"
              textAlign="right"
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              sizeText="display10">
              Importe
            </Text>
          </Flex>
        </Box>
      ),
      selector: (row) => parseToInteger(row.budget),
      cell: (row) => (
        <Flex width="100%" justifyContent="space-between">
          <Box textAlign="right" width="110px">
            {row.budget}
          </Box>
          <Box width="120px" textAlign="right">
            {getTotal(row.budget, row.measurement)}
          </Box>
        </Flex>
      ),
      sortable: true,
      width: '240px',
    },
    {
      name: (
        <Box marginBottom={2} width="100%">
          <Box marginBottom={2} paddingTop={4}>
            <Text textAlign="right" sizeText="display14" overflow="hidden" fontWeight="600">
              Coste previsto
            </Text>
          </Box>
          <Flex width="100%" justifyContent="space-between">
            <Text
              width="110px"
              textAlign="right"
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              sizeText="display10">
              €/ud.
            </Text>
            <Text
              width="120px"
              textAlign="right"
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              sizeText="display10">
              Importe
            </Text>
          </Flex>
        </Box>
      ),
      selector: (row) => parseToInteger(row.expectedCosts),
      cell: (row) => (
        <Flex width="100%" justifyContent="space-between">
          <Box textAlign="right" width="110px">
            {row.expectedCosts}
          </Box>
          <Box width="120px" textAlign="right">
            {getTotal(row.expectedCosts, row.measurement)}
          </Box>
        </Flex>
      ),
      sortable: true,
      width: '240px',
    },
    {
      name: '',
      cell: (row) => (
        <>
          {row.comments && (
            <Flex marginRight={2} alignItems="center">
              <Icon color="gray2">
                <Comment />
              </Icon>
            </Flex>
          )}

          <Flex marginRight={2} alignItems="center">
            <Icon color="gray2">
              <FileIcon />
            </Icon>
            <Text sizeText="display14" color="gray1" marginLeft={1}>
              {row.files?.length || 0}
            </Text>
          </Flex>
          <Flex marginRight={2} alignItems="center">
            <Icon color="gray2">
              <LinkIcon />
            </Icon>
            <Text sizeText="display14" color="gray1" marginLeft={1}>
              {row.links?.length || 0}
            </Text>
          </Flex>
          <ActionMenu
            actions={[
              {
                id: 'edit',
                isDisabled: bidPackage?.state === 'Publish',
                content: (
                  <Text sizeText="display14" color="black" paddingTop={4}>
                    Editar partida
                  </Text>
                ),
                action: () => onEditBidItem(row.bidItemId),
              },
              {
                id: 'copy',
                content: (
                  <Text sizeText="display14" color="black" paddingY={4}>
                    Duplicar partida
                  </Text>
                ),
                action: () => {
                  onActionBidItem(row.bidItemId, 'Copy')
                },
              },
              {
                id: 'delete',
                isDisabled: bidPackage?.state === 'Publish',
                content: (
                  <Text sizeText="display14" color="red1" paddingBottom={4}>
                    Eliminar partida
                  </Text>
                ),
                action: () => {
                  setDeleteBidItemModalIsOpened(true)
                  setSelectId(row.bidItemId)
                },
              },
            ]}
          />
        </>
      ),
      right: true,
      style: {
        padding: '0',
      },
    },
  ]

  return (
    <>
      <DataTable columns={columns} data={bidItems} pagination={bidItems.length > 10} />
      <Flex justifyContent="flex-end" marginTop={4}>
        <Box>
          <Text sizeText="display16" fontWeight="bold" paddingBottom={4} textAlign="right">
            Total presupuesto de venta: {currencyFormat(totalCosts?.budget)}
          </Text>
          <Text sizeText="display16" fontWeight="bold" textAlign="right">
            Total coste previsto: {currencyFormat(totalCosts?.expectedCosts)}
          </Text>
        </Box>
      </Flex>
    </>
  )
}
