/* eslint-disable dot-notation */
import { constants } from '../config'
import { constants as constantsBidPackage } from '../../bidPackages/config'

const initialState = {
  done: false,
  loading: false,
  errorRequest: false,
  error: null,
  images: {},
  data: {
    projects: {
      data: [],
      meta: {},
      filters: {},
    },
    comparatives: {},
    project: {
      files: [],
      links: [],
      managedFilesFinished: false,
      managedLinksFinished: false,
      bidPackages: [],
      bidPackage: {
        proposals: [],
        offers: [],
        proposalLower: {},
        proposalMinimalEnvelope: {},
        files: [],
        links: [],
        bidItem: {
          files: [],
          links: [],
        },
        bidItems: [],
        proposalSelected: {
          files: [],
          links: [],
        },
        invitations: {
          loading: false,
          doneInvitation: false,
        },
      },
      imported: false,
    },
  },
}

export default function setProjectsReducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_PROJECT: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            loading: true,
            done: false,
            created: false,
            managedFilesFinished: false,
            managedLinksFinished: false,
          },
        },
      }
    }

    case constants.GET_ALL_PROJECTS: {
      return {
        ...state,
        data: {
          ...state.data,
          projects: {
            meta: {},
            data: [],
          },
        },
      }
    }

    case constants.CREATE_ATTACHMENT_ALL_PROJECT_SUCCESS: {
      const itemToUpdate = state.data.projects?.data?.findIndex(
        (element) => element?.projectId === action.projectId,
      )
      const updatedProject = {
        ...state.data.projects?.data[itemToUpdate],
        image: action.payload,
      }

      const newState = state.data.projects?.data
      newState.splice(itemToUpdate, 1, updatedProject)

      return {
        ...state,
        data: {
          ...state.data,
          projects: {
            ...state.data.projects,
            data: newState,
          },
        },
      }
    }

    case constants.CREATE_PROJECT_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            ...action.payload,
            created: true,
          },
        },
      }
    }

    case constants.CREATE_ATTACHMENT_PROJECT: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            filesLoading: true,
            filesUpdated: false,
          },
        },
      }
    }

    case constants.CREATE_ATTACHMENT_PROJECT_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            files: [...state.data.project?.files, action.payload],
            filesUpdated: true,
            filesLoading: false,
          },
        },
      }
    }

    case constants.CREATE_LINK_PROJECT: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            filesLoading: true,
            filesUpdated: false,
          },
        },
      }
    }

    case constants.CREATE_LINK_PROJECT_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            links: [...state.data.project?.links, action.payload],
            filesUpdated: true,
            filesLoading: false,
          },
        },
      }
    }

    case constants.SET_STORE_PROJECT: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            ...action.payload,
          },
        },
      }
    }

    case constants.SET_STORE_PROJECTS: {
      return {
        ...state,
        data: {
          ...state.data,
          projects: {
            data: [...state.data.projects?.data, ...action.payload],
          },
        },
      }
    }

    case constantsBidPackage.RESET_STORE_COMPARATIVES: {
      return {
        ...state,
        data: {
          ...state.data,
          comparatives: {},
          comparativesDone: false,
        },
      }
    }

    case constantsBidPackage.GET_BID_PACKAGE: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            loading: true,
            done: false,
            createdBidpackage: false,
            imported: false,
          },
        },
      }
    }

    case constantsBidPackage.GET_BID_PACKAGE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackage: {
              ...state.data.project?.bidPackage,
              ...action.payload,
              bidItems: [...state.data.project?.bidPackage?.bidItems],
            },
            loading: false,
            done: true,
          },
        },
      }
    }

    case constantsBidPackage.GET_BID_PACKAGE_PROPOSAL_LOWEST_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackage: {
              ...state.data.project?.bidPackage,
              proposalLower: action.payload,
              bidItems: [...state.data.project?.bidPackage?.bidItems],
            },
            loading: false,
            done: true,
          },
        },
      }
    }

    case constantsBidPackage.GET_BID_PACKAGE_PROPOSAL_MINIMAL_ENVELOPE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackage: {
              ...state.data.project?.bidPackage,
              proposalMinimalEnvelope: action.payload,
              bidItems: [...state.data.project?.bidPackage?.bidItems],
            },
            loading: false,
            done: true,
          },
        },
      }
    }

    case constantsBidPackage.GET_BID_PACKAGE_PROPOSALS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackage: {
              ...state.data.project?.bidPackage,
              proposals: action.payload,
              bidItems: [...state.data.project?.bidPackage?.bidItems],
            },
            loading: false,
            done: true,
          },
        },
      }
    }

    case constantsBidPackage.GET_BID_PACKAGE_PROPOSAL: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackage: {
              ...state.data.project?.bidPackage,
              proposalSelected: {
                files: [],
              },
            },
            loading: false,
            done: true,
          },
        },
      }
    }

    case constantsBidPackage.GET_BID_PACKAGE_PROPOSAL_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackage: {
              ...state.data.project?.bidPackage,
              proposalSelected: {
                ...state.data.project?.bidPackage?.proposalSelected,
                ...action.payload,
              },
            },
            loading: false,
            done: true,
          },
        },
      }
    }

    case constantsBidPackage.CREATE_ATTACHMENT_BID_PACKAGE_PROPOSAL_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackage: {
              ...state.data.project?.bidPackage,
              proposalSelected: {
                ...state.data.project?.bidPackage?.proposalSelected,
                files: [...state.data.project?.bidPackage?.proposalSelected?.files, action.payload],
              },
            },
            loading: false,
            done: true,
          },
        },
      }
    }

    case constantsBidPackage.CREATE_ATTACHMENT_BID_PACKAGE_PROPOSAL_ITEM_SUCCESS: {
      const itemToUpdate =
        state.data.project?.bidPackage?.proposalSelected?.proposalItems?.findIndex(
          (element) => element?.proposalItemId === action.payload?.proposalItemId,
        )
      let updatedItem = {
        ...state.data.project?.bidPackage?.proposalSelected?.proposalItems[itemToUpdate],
      }

      if (state.data.project?.bidPackage?.proposalSelected?.proposalItems[itemToUpdate].files) {
        updatedItem = {
          ...updatedItem,
          files: [
            ...state.data.project?.bidPackage?.proposalSelected?.proposalItems[itemToUpdate].files,
            action.payload,
          ],
        }
      } else {
        updatedItem = {
          ...updatedItem,
          files: [action.payload],
        }
      }
      const newState = state.data.project?.bidPackage?.proposalSelected?.proposalItems
      newState.splice(itemToUpdate, 1, updatedItem)

      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackage: {
              ...state.data.project?.bidPackage,
              proposalSelected: {
                ...state.data.project?.bidPackage?.proposalSelected,
                proposalItems: newState,
              },
            },
            loading: false,
            done: true,
          },
        },
      }
    }

    case constantsBidPackage.GET_BID_PACKAGE_OFFERS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackage: {
              ...state.data.project?.bidPackage,
              offers: action.payload,
              bidItems: [...state.data.project?.bidPackage?.bidItems],
            },
            loading: false,
            done: true,
          },
        },
      }
    }

    case constantsBidPackage.GET_BID_PACKAGE_COMPARATIVE: {
      return {
        ...state,
        data: {
          ...state.data,
          comparativesDone: false,
        },
      }
    }

    case constantsBidPackage.GET_BID_PACKAGE_COMPARATIVE_SUCCESS: {
      for (let i = 0; i < action?.payload?.proposals.length; i += 1) {
        const item = action?.payload?.proposals[i]
        const proposalItemsSorted = new Array(item?.proposalItems?.length)

        for (let j = 0; j < item?.proposalItems?.length; j += 1) {
          const itemProposal = item?.proposalItems[j]
          const idem = action?.payload?.bidItems?.find(
            (el) => el?.bidItemId === itemProposal?.bidItemId,
          )
          proposalItemsSorted.splice(idem?.position, 1, itemProposal)
        }

        item['proposalItems'] = proposalItemsSorted
      }

      return {
        ...state,
        data: {
          ...state.data,
          comparatives: action.payload,
          comparativesDone: true,
        },
      }
    }

    case constantsBidPackage.CREATE_BID_PACKAGE: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            createdBidPackage: false,
            lastBidPackagesCreated: null,
          },
        },
      }
    }

    case constantsBidPackage.CREATE_BID_PACKAGE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackages: [...state.data.project.bidPackages, action.payload],
            createdBidPackage: true,
            lastBidPackagesCreated: action.payload?.bidPackageId,
          },
        },
      }
    }

    case constantsBidPackage.CLEAR_BID_PACKAGE: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            createdBidPackage: false,
          },
        },
      }
    }

    case constantsBidPackage.CREATE_ATTACHMENT_BID_PACKAGE: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            filesLoading: true,
            filesUpdated: false,
          },
        },
      }
    }

    case constantsBidPackage.CREATE_ATTACHMENT_BID_PACKAGE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackage: {
              ...state.data.project.bidPackage,
              files: [
                ...state.data.project?.bidPackage?.files.filter(
                  ({ fileId }) => fileId !== action.payload.fileId,
                ),
                action.payload,
              ],
            },
            filesUpdated: true,
            filesLoading: false,
          },
        },
      }
    }

    case constantsBidPackage.DELETE_ATTACHMENT_BID_PACKAGE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackage: {
              ...state.data.project?.bidPackage,
              files: [
                ...state.data.project?.bidPackage?.files.filter(
                  ({ fileId }) => fileId !== action.payload,
                ),
              ],
            },
          },
        },
      }
    }

    case constantsBidPackage.CREATE_LINK_BID_PACKAGE: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            filesLoading: true,
            filesUpdated: false,
          },
        },
      }
    }

    case constantsBidPackage.CREATE_LINK_BID_PACKAGE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackage: {
              ...state.data.project.bidPackage,
              links: [...state.data.project?.bidPackage?.links, action.payload],
            },
            filesUpdated: true,
            filesLoading: false,
          },
        },
      }
    }

    case constantsBidPackage.SET_STORE_BID_PACKAGE: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackage: {
              ...state.data.project.bidPackage,
              ...action.payload,
            },
          },
        },
      }
    }

    case constantsBidPackage.SET_STORE_BID_PACKAGES: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            bidPackages: [...state.data.project.bidPackages, action.payload],
          },
        },
      }
    }
    case constantsBidPackage.BID_PACKAGES_FAIL || constantsBidPackage.BID_PACKAGE_FAIL:
      return { ...state, loading: false, errorRequest: true, error: { ...action.error } }

    case constantsBidPackage.GET_BID_ITEM: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            loading: true,
            done: false,
            createdBidItem: false,
            bidPackage: {
              ...state.data.project?.bidPackage,
              bidItem: {
                files: [],
              },
            },
          },
        },
      }
    }

    case constantsBidPackage.GET_BID_ITEM_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackage: {
              ...state.data.project?.bidPackage,
              bidItem: {
                ...action.payload,
              },
            },
            loading: false,
            done: true,
          },
        },
      }
    }

    case constantsBidPackage.CREATE_BID_ITEM: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            createdBidItem: false,
            lastBidItemCreated: null,
            bidPackage: {
              ...state.data.project.bidPackage,
              bidItem: {
                ...state.data.project.bidPackage.bidItem,
              },
            },
          },
        },
      }
    }

    case constantsBidPackage.CREATE_BID_ITEM_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackage: {
              ...state.data.project.bidPackage,
              bidItem: {
                ...state.data.project.bidPackage.bidItem,
                ...action.payload,
              },
            },
            createdBidItem: true,
            lastBidItemCreated: action.payload?.bidItemId,
          },
        },
      }
    }

    case constantsBidPackage.CREATE_ATTACHMENT_BID_ITEM: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            filesLoading: true,
            filesUpdated: false,
          },
        },
      }
    }

    case constantsBidPackage.CREATE_ATTACHMENT_BID_ITEM_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackage: {
              ...state.data.project.bidPackage,
              bidItem: {
                ...state.data.project.bidPackage.bidItem,
                files: [...state.data.project?.bidPackage?.bidItem?.files, action.payload],
              },
            },
            filesUpdated: true,
            filesLoading: false,
          },
        },
      }
    }

    case constantsBidPackage.CREATE_LINK_BID_ITEM: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            filesLoading: true,
            filesUpdated: false,
          },
        },
      }
    }

    case constantsBidPackage.CREATE_LINK_BID_ITEM_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackage: {
              ...state.data.project.bidPackage,
              bidItem: {
                ...state.data.project.bidPackage.bidItem,
                links: [...state.data.project?.bidPackage?.bidItem?.links, action.payload],
              },
            },
            filesUpdated: true,
            filesLoading: false,
          },
        },
      }
    }

    case constantsBidPackage.SET_STORE_BID_ITEM: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackage: {
              ...state.data.project.bidPackage,
              bidItem: {
                ...state.data.project.bidPackage.bidItem,
                ...action.payload,
              },
            },
          },
        },
      }
    }

    case constantsBidPackage.SET_STORE_BID_ITEMS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackage: {
              ...state.data.project.bidPackage,
              bidItems: {
                ...state.data.project.bidPackage.bidItems,
                ...action.payload,
              },
            },
          },
        },
      }
    }
    case constantsBidPackage.GET_ALL_BID_PACKAGES: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackages: [],
          },
        },
      }
    }

    case constantsBidPackage.GET_ALL_BID_PACKAGES_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackages: action.payload,
          },
        },
      }
    }

    case constantsBidPackage.GET_ALL_BID_ITEMS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackage: {
              ...state.data.project.bidPackage,
              loadingBidItems: true,
            },
          },
        },
      }
    }

    case constantsBidPackage.GET_ALL_BID_ITEMS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackage: {
              ...state.data.project.bidPackage,
              bidItems: action.payload,
              loadingBidItems: false,
            },
          },
        },
      }
    }
    case constants.IMPORT_BID_PACKAGES: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            imported: false,
          },
        },
      }
    }
    case constants.IMPORT_BID_PACKAGES_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            imported: action.payload,
          },
        },
      }
    }
    case constantsBidPackage.ACTION_BID_PACKAGE: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackage: {
              ...state.data.project.bidPackage,
              invitations: {
                loading: true,
                doneInvitation: false,
              },
            },
          },
        },
      }
    }
    case constantsBidPackage.ACTION_BID_PACKAGE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            bidPackage: {
              ...state.data.project.bidPackage,
              invitations: {
                loading: false,
                doneInvitation: true,
              },
            },
          },
        },
      }
    }
    case constants.SET_MANAGED_FILES_FINISHED: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            managedFilesFinished: action.payload,
            managedLinksFinished: false,
          },
        },
      }
    }
    case constants.SET_MANAGED_LINKS_FINISHED: {
      return {
        ...state,
        data: {
          ...state.data,
          project: {
            ...state.data.project,
            managedFilesFinished: false,
            managedLinksFinished: action.payload,
          },
        },
      }
    }

    default:
      return state
  }
}
