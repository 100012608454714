import { useHistory } from 'react-router-dom'
import { Box, Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { ActionMenu } from '@proveoeng/uikit/dist/molecules/ActionsMenu/ActionMenu'
import { FormatDate } from '../../../../../common/mappers/FormatDate'

export const ComparativeList = ({ projectId, bidPackageId, comparativesList }) => {
  const history = useHistory()

  const openComparative = async (comparisonId) =>
    history.push(`/constructora/tus-obras/${projectId}/${bidPackageId}/comparativo/${comparisonId}`)

  return (
    <Box paddingBottom={6}>
      <Grid
        gridTemplateAreas={"'name createdAt updatedAt actions'"}
        gridTemplateColumns="1fr 188px 188px 60px"
        bg="gray4"
        paddingY={2}
        borderRadius={2}>
        <GridItem gridArea="name">
          <Text
            sizeText="display14"
            color="gray1"
            overflow="hidden"
            fontWeight="medium"
            marginLeft={5}>
            Nombre
          </Text>
        </GridItem>
        <GridItem
          gridArea="createdAt"
          style={{ alignItems: 'center', justifyContent: 'flex-end' }}
          display="flex"
          paddingRight={4}>
          <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
            Fecha creación
          </Text>
        </GridItem>
        <GridItem
          gridArea="updatedAt"
          style={{ alignItems: 'center', justifyContent: 'flex-end' }}
          display="flex"
          paddingRight={4}>
          <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
            Fecha modificación
          </Text>
        </GridItem>
        <GridItem gridArea="actions" />
      </Grid>
      {comparativesList?.map((el) => (
        <Grid
          gridTemplateAreas={"'name createdAt updatedAt actions'"}
          gridTemplateColumns="1fr 188px 188px 60px"
          paddingY={3}
          borderBottomColor="gray4"
          borderBottomStyle="solid"
          borderBottomWidth="1px">
          <GridItem gridArea="name" style={{ alignItems: 'center' }} display="flex">
            <Text
              sizeText="display14"
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              marginLeft={5}>
              <Flex alignItems="center">
                <Text
                  sizeText="display14"
                  fontWeight="medium"
                  onClick={() => openComparative(el.comparisonId)}
                  style={{ cursor: 'pointer' }}>
                  {el?.title}
                </Text>
              </Flex>
            </Text>
          </GridItem>

          <GridItem
            gridArea="createdAt"
            style={{ alignItems: 'center', justifyContent: 'flex-end' }}
            display="flex"
            paddingRight={4}>
            <Text sizeText="display14" color="gray1" overflow="hidden">
              {FormatDate.hydrate(el?.created).readableReverseDate}
            </Text>
          </GridItem>
          <GridItem
            gridArea="updatedAt"
            style={{ alignItems: 'center', justifyContent: 'flex-end' }}
            display="flex"
            paddingRight={4}>
            <Text sizeText="display14" color="gray1" overflow="hidden">
              {FormatDate.hydrate(el?.updated).readableReverseDate}
            </Text>
          </GridItem>
          <GridItem gridArea="actions">
            <Flex alignItems="center" justifyContent="flex-start">
              <ActionMenu
                actions={[
                  {
                    id: 'edit',
                    content: (
                      <Text sizeText="display14" color="black" paddingTop={4}>
                        Editar
                      </Text>
                    ),
                    action: () => openComparative(el.comparisonId),
                  },
                ]}
              />
            </Flex>
          </GridItem>
        </Grid>
      ))}
    </Box>
  )
}
