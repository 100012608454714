/**
 * @typedef {Object} Filter
 * @property {string | number | Array<string | number>} value
 * @property {string} operator
 */

/**
 * @typedef {Object} Filters
 * @property {Record<string, Filter>}
 */

/**
 * @typedef {Object} Sort
 * @property {string} field
 * @property {'asc' | 'desc'} operator
 */

/**
 * @typedef {Object} Param
 * @property {Filters} filters
 * @property {Sort} sort
 * @property {number} page
 * @property {number} limit
 */

/**
 *
 * @param {Params} param
 */
export const parseParamRequest = ({ filters = {}, page = 1, limit = 10, sort = {} }) => {
  const filterBy = Object.keys(filters).reduce((acc, key) => {
    acc[key] = {
      ...acc[key],
      [filters[key].operator]: filters[key].value,
    }
    return acc
  }, {})

  const orderBy = {
    [sort.field]: sort.operator,
  }

  return { page, limit, filterBy, orderBy }
}
