import { useEffect, useState } from 'react'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'
import { ArrowDown, ArrowRight } from '@proveoeng/uikit/dist/atoms/Icons'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'

import { AttachmentList } from '../../../common/components/attachmentList/Attachment'
import { Links } from '../../../common/components/links/Links'

export const DetailsModal = ({ isOpen, onClose, data }) => {
  const [commentsIsOpened, setCommentsIsOpened] = useState(true)
  const [attachmentsIsOpened, setAttachmentsIsOpened] = useState(true)
  const [linksIsOpened, setLinksIsOpened] = useState(true)

  const getComments = () => {
    const comments = data?.comments?.length ? (
      <Text
        as="span"
        marginLeft={1}
        sizeText="display14"
        color="black"
        fontWeight="medium"
        style={{
          whiteSpace: 'pre-wrap',
        }}>
        {data?.comments}
      </Text>
    ) : (
      <Text as="span" marginLeft={1} sizeText="display14" color="gray1" fontWeight="regular">
        No hay comentarios
      </Text>
    )
    return (
      <Flex paddingX={6} paddingBottom={5}>
        {comments}
      </Flex>
    )
  }

  useEffect(() => {
    if (isOpen) {
      setCommentsIsOpened(true)
      setAttachmentsIsOpened(true)
      setLinksIsOpened(true)
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      id="details-modal"
      onModalClose={onClose}
      title="Detalles de la oferta"
      maxWidth="570px"
      closeWithButton>
      <Modal.Content>
        <Flex margin="0 auto">
          <Flex justifyContent="space-between" flexDirection="column" paddingBottom={4}>
            <Flex alignItems="center" paddingX={3}>
              <Icon
                style={{ cursor: 'pointer' }}
                sizeIcon="display18"
                color="black"
                marginRight={1}
                onClick={() => setCommentsIsOpened(!commentsIsOpened)}>
                {commentsIsOpened ? <ArrowDown /> : <ArrowRight />}
              </Icon>
              <Flex alignItems="center" paddingBottom={2}>
                <Text
                  as="span"
                  sizeText="display18"
                  fontWeight="medium"
                  color="black"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setCommentsIsOpened(!commentsIsOpened)}>
                  Comentarios
                </Text>
              </Flex>
            </Flex>
            {commentsIsOpened && getComments()}
            <Flex alignItems="center" paddingX={3}>
              <Icon
                style={{ cursor: 'pointer' }}
                sizeIcon="display18"
                color="black"
                marginRight={1}
                onClick={() => setAttachmentsIsOpened(!attachmentsIsOpened)}>
                {attachmentsIsOpened ? <ArrowDown /> : <ArrowRight />}
              </Icon>
              <Flex alignItems="center" paddingBottom={2}>
                <Text
                  as="span"
                  sizeText="display18"
                  fontWeight="medium"
                  color="black"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setAttachmentsIsOpened(!attachmentsIsOpened)}>
                  Adjuntos
                </Text>
              </Flex>
            </Flex>
            {attachmentsIsOpened && (
              <Flex paddingX={6} paddingBottom={5}>
                {data?.files?.length ? (
                  <AttachmentList files={data?.files} withDownload />
                ) : (
                  <Text
                    as="span"
                    marginLeft={1}
                    sizeText="display14"
                    color="gray1"
                    fontWeight="regular">
                    No hay archivos adjuntos
                  </Text>
                )}
              </Flex>
            )}
            <Flex alignItems="center" paddingX={3}>
              <Icon
                style={{ cursor: 'pointer' }}
                sizeIcon="display18"
                color="black"
                marginRight={1}
                onClick={() => setLinksIsOpened(!linksIsOpened)}>
                {linksIsOpened ? <ArrowDown /> : <ArrowRight />}
              </Icon>
              <Flex alignItems="center" paddingBottom={2}>
                <Text
                  as="span"
                  sizeText="display18"
                  fontWeight="medium"
                  color="black"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setLinksIsOpened(!linksIsOpened)}>
                  Enlaces externos
                </Text>
              </Flex>
            </Flex>
            {linksIsOpened && (
              <>
                <Flex paddingX={6} paddingBottom={5}>
                  {data?.links?.length ? (
                    <Links links={data?.links} />
                  ) : (
                    <Text
                      as="span"
                      marginLeft={1}
                      sizeText="display14"
                      color="gray1"
                      fontWeight="regular">
                      No hay enlaces externos
                    </Text>
                  )}
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Button colorType="transparent" action={onClose}>
            Hecho
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  )
}
