import { Container, Flex, Box } from '@proveoeng/uikit/dist/atoms/Layout'
import { Card } from '@proveoeng/uikit/dist/atoms/Card'
import { LinkText, Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { InputPassword } from '@proveoeng/uikit/dist/atoms/InputPassword'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
// import Logo from '../../../common/assets/logo'
import ecomaLogo from '../../../common/assets/ECOMA_LOGO.png'
import ecomaBanner from '../../../common/assets/ECOMA_BANNER.png'

export const LoginTemplate = ({
  values,
  errors,
  onSubmit,
  handleChange,
  handleBlur,
  touched,
  isValid,
}) => (
  <Container>
    <Flex alignItems="center" justifyContent="center" flexDirection="column" padding={9}>
      {/* <Logo width="277px" /> */}
      <img
        src={ecomaLogo}
        alt="ecoma logo"
        height="56px"
        width="auto"
        style={{ objectFit: 'fill' }}
      />
      <Card padding={10} maxWidth={674} width="100%" marginY={7}>
        <Text sizeText="display30" color="black" fontWeight="bold" marginBottom={7}>
          Login
        </Text>
        <Box as="form" onSubmit={onSubmit}>
          <Field
            label="Email"
            sizeText="display16"
            marginBottom={3}
            errorMessage={touched.username && errors.username}>
            <Input
              type="email"
              placeholderMessage="Email"
              ariaLabel="username"
              name="username"
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
              hasError={touched.username && !!errors.username}
            />
          </Field>
          <Field
            label="Contraseña"
            sizeText="display16"
            marginBottom={3}
            marginTop={5}
            errorMessage={touched.password && errors.password}>
            <InputPassword
              name="password"
              placeholderMessage="Introduce tu contraseña"
              ariaLabel="contraseña"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              hasError={touched.password && !!errors.password}
            />
          </Field>
          <Flex alignItems="center" justifyContent="center" flexDirection="column" marginTop={8}>
            <Button fullWidth colorType="orange" type="submit" disabled={!isValid}>
              Login
            </Button>
            <LinkText href="/perfil/restablecer" color="blue1" sizeText="display14" marginTop={6}>
              ¿Olvidaste tu contraseña?
            </LinkText>
          </Flex>
        </Box>
      </Card>
      <img
        src={ecomaBanner}
        alt="ecoma banner"
        height="212px"
        width="auto"
        maxWidth="80vw"
        style={{ objectFit: 'fill' }}
      />
      {/* <Text as="a" href="https://www.conkau.io" color="blue1" sizeText="display14">
        Ir a conkau.io
      </Text> */}
    </Flex>
  </Container>
)
