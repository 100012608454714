import { Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

export const SectionSummaryHead = ({ offers, columnsHead, tableWidthCSS }) => (
  <>
    <Grid
      gridTemplateAreas={columnsHead.area}
      gridTemplateColumns={columnsHead.columns}
      style={{ width: tableWidthCSS }}>
      <GridItem
        gridArea="description"
        bg="gray4"
        borderRightWidth="1px"
        borderRightStyle="solid"
        borderRightColor="gray3"
        borderTopLeftRadius={2}
        paddingX={2}
        paddingY={4}
        style={{
          position: 'sticky',
          left: 0,
          zIndex: 9,
        }}
      />
      <GridItem
        gridArea="budget"
        paddingX={2}
        paddingY={4}
        bg="gray4"
        borderRightWidth="1px"
        borderRightStyle="solid"
        borderRightColor="gray3"
        style={{
          position: 'sticky',
          left: 510,
          zIndex: 9,
        }}>
        <Text
          sizeText="display14"
          textAlign="right"
          color="gray1"
          overflow="hidden"
          fontWeight="medium">
          Ppto. de venta
        </Text>
      </GridItem>
      <GridItem
        gridArea="expectedCosts"
        paddingX={2}
        paddingY={4}
        bg="gray4"
        borderRightWidth="1px"
        borderRightStyle="solid"
        borderRightColor="gray3"
        style={{
          position: 'sticky',
          left: 730,
          zIndex: 9,
        }}>
        <Text
          sizeText="display14"
          textAlign="right"
          color="gray1"
          overflow="hidden"
          fontWeight="medium">
          Coste previsto
        </Text>
      </GridItem>
      <GridItem
        gridArea="minEnvelope"
        paddingX={2}
        paddingY={4}
        bg="gray4"
        borderRightWidth="1px"
        borderRightStyle="solid"
        borderRightColor="gray3"
        style={{
          position: 'sticky',
          left: 950,
          zIndex: 9,
        }}>
        <Text
          sizeText="display14"
          textAlign="right"
          color="gray1"
          overflow="hidden"
          fontWeight="medium">
          Envolvente mínima
        </Text>
      </GridItem>
      {offers.map((offer) => (
        <GridItem gridArea={`offer-${offer.id}`} paddingX={2} paddingY={4} bg="gray3">
          <Text
            sizeText="display14"
            textAlign="right"
            color="gray1"
            overflow="hidden"
            fontWeight="medium">
            {offer.name}
          </Text>
        </GridItem>
      ))}
    </Grid>
  </>
)
