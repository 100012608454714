import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Box } from '@proveoeng/uikit/dist/atoms/Layout'
import { MessageWrapper } from './MessageWrapper'

export const MessagesList = ({ buttonAction, messagesList = [], contactMail, employeeEmail }) => (
  <>
    <Box width="184px" marginRight={7} marginBottom={5}>
      <Button fullWidth colorType="transparent" action={() => buttonAction(true)}>
        Nuevo mensaje
      </Button>
    </Box>
    <Box>
      {messagesList?.map((message) => (
        <MessageWrapper
          messageData={message}
          bg={contactMail === message?.receiverEmail ? 'white' : 'white2'}
          employeeEmail={employeeEmail}
        />
      ))}
    </Box>
  </>
)
