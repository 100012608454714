import { v4 as uuidv4 } from 'uuid'
import jwtDecode from 'jwt-decode'
import { FormatDate } from '../../../mappers/FormatDate'

export const AuthMapper = {
  dehydrate({
    name,
    position,
    phone,
    email,
    password,
    companyName,
    postCode,
    address,
    city,
    companyId,
    isSameBillingData,
    billingName,
    billingPostCode,
    billingAddress,
    billingCity,
    taxCode,
    categories,
  }) {
    return {
      companyData: {
        companyId: companyId || uuidv4(),
        name: companyName,
        address,
        postCode,
        city,
        taxCode,
        billingName: isSameBillingData ? companyName : billingName,
        billingAddress: isSameBillingData ? address : billingAddress,
        billingPostCode: isSameBillingData ? postCode : billingPostCode,
        billingCity: isSameBillingData ? city : billingCity,
      },
      categories,
      employeeData: {
        employeeId: uuidv4(),
        name,
        position: position || null,
        phone,
        email,
        password,
      },
    }
  },
  hydrate({ invitedAt, acceptedAt, companyData, employeeData, relationships, ...restData }) {
    return {
      invitedAt: FormatDate.hydrate(invitedAt),
      acceptedAt: FormatDate.hydrate(acceptedAt),
      companyName: companyData?.name,
      companyAddress: companyData?.address,
      companyPostCode: companyData?.postCode,
      companyCity: companyData?.city,
      companyPhone: companyData?.phone,
      companyId: companyData?.companyId,
      scope: companyData?.scope,
      companyDescription: companyData?.description,
      taxCode: companyData?.taxCode,
      billingName: companyData?.billingName,
      billingAddress: companyData?.billingAddress,
      billingPostCode: companyData?.billingPostCode,
      billingCity: companyData?.billingCity,
      ...restData,
      ...employeeData,
      relationships: {
        ...relationships,
      },
    }
  },
}

export const AuthMapperOld = {
  dehydrate({
    name,
    position,
    phone,
    email,
    password,
    companyName,
    companyPostCode,
    companyPhone,
    companyAddress,
    companyCity,
    companyId,
    scope,
    companyDescription,
    isSameBillingData,
    billingName,
    billingPostCode,
    billingAddress,
    billingCity,
    taxCode,
    categories,
  }) {
    return {
      companyData: {
        companyId: companyId || uuidv4(),
        name: companyName,
        address: companyAddress,
        postCode: companyPostCode,
        city: companyCity,
        phone: companyPhone,
        scope,
        description: companyDescription,
        taxCode,
        billingName: isSameBillingData ? companyName : billingName,
        billingAddress: isSameBillingData ? companyAddress : billingAddress,
        billingPostCode: isSameBillingData ? companyPostCode : billingPostCode,
        billingCity: isSameBillingData ? companyCity : billingCity,
        isSameBillingData,
      },
      categories,
      employeeData: {
        employeeId: uuidv4(),
        name,
        position: position || null,
        phone,
        email,
        password,
      },
    }
  },
  hydrate({ invitedAt, acceptedAt, companyData, employeeData, relationships, ...restData }) {
    return {
      invitedAt: FormatDate.hydrate(invitedAt),
      acceptedAt: FormatDate.hydrate(acceptedAt),
      companyName: companyData?.name,
      companyAddress: companyData?.address,
      companyPostCode: companyData?.postCode,
      companyCity: companyData?.city,
      companyPhone: companyData?.phone,
      companyId: companyData?.companyId,
      scope: companyData?.scope,
      companyDescription: companyData?.description,
      taxCode: companyData?.taxCode,
      billingName: companyData?.billingName,
      billingAddress: companyData?.billingAddress,
      billingPostCode: companyData?.billingPostCode,
      billingCity: companyData?.billingCity,
      ...restData,
      ...employeeData,
      relationships: {
        ...relationships,
      },
    }
  },
}

export const TokenMapper = {
  hydrate: (data) => {
    const jwtDecodeData = jwtDecode(data?.token)
    return {
      token: data?.token,
      roles: jwtDecodeData?.roles,
      ...jwtDecodeData?.grants[0],
      username: jwtDecodeData?.username,
    }
  },
}
