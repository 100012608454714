import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Item } from './Item'

export const Items = ({ items }) => (
  <>
    {items.map(({ title, subTitle, children }, index) => (
      <Flex paddingBottom="20px" width="100%">
        <Item key={index} title={title} subTitle={subTitle}>
          {children}
        </Item>
      </Flex>
    ))}
  </>
)
