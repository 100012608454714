/**
 * This function flattens an object recursively, to obtain an object of objects with only one
 * nesting level.
 *
 * @param {Object} nestedMessages - the Object with nested elements.
 * @param {String} prefix - a prefix to use for the new key, initialized to an empty char.
 *
 * @return {Object}
 */

export const flattenMessages = (nestedMessages, prefix = '') => {
  if (!nestedMessages) {
    return {}
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key]
    const prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      Object.assign(messages, { [prefixedKey]: value })
    } else if (typeof value !== 'undefined') {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }

    return messages
  }, {})
}

export const isEmail = (value) => {
  const regExpEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return (value && value.match(regExpEmail)) || false
}

export const removeDuplicateObjectInArrayById = (arr) =>
  arr.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)

export const getShortenedFileNameExtension = (name) => {
  const splittedName = name?.split('.')

  return splittedName ? splittedName[splittedName.length - 1] : 'empty'
}
