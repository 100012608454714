import { v4 as uuidv4 } from 'uuid'
import { parseToInteger } from '../../../../lib/utils/Format'
import { BidItem } from '../bidItem/bidItem'

export class Section {
  constructor(id, name, bidItems) {
    this.id = id
    this.name = name
    this.bidItems = bidItems
  }

  static create({ id, name, bidItems = [] }) {
    const bidItemsParsed = bidItems.map(BidItem.create)
    return new Section(id, name, bidItemsParsed)
  }

  getTotalBudgetCost() {
    return (
      this.bidItems.reduce(
        (prevItem, currentItem) => prevItem + currentItem.getTotalBudgetCost(),
        0,
      ) || 0
    )
  }

  getTotalExpectedCost() {
    return (
      this.bidItems.reduce(
        (prevItem, currentItem) => prevItem + currentItem.getTotalExpectedCost(),
        0,
      ) || 0
    )
  }

  getTotalCeco2() {
    return (
      this.bidItems.reduce((prevItem, currentItem) => prevItem + currentItem.getTotalCeco2(), 0) ||
      0
    )
  }

  addBidItem({ description, measure, unit, ...rest }) {
    const newBidItem = BidItem.create({
      ...rest,
      id: uuidv4(),
      description,
      measure: parseToInteger(measure, 3) / 1000,
      unit,
      budgetCost: null,
      expectedCost: null,
    })
    this.bidItems.push(newBidItem)
  }

  removeBidItems(items) {
    this.bidItems = this.bidItems.filter((bidItem) => !items.includes(bidItem.id))
  }

  getNumberOfBidItems() {
    const bidItemsLength = this.bidItems?.length
    if (bidItemsLength === 0) return `No hay partidas`

    if (bidItemsLength < 2) return `${bidItemsLength} partida`

    return `${bidItemsLength} partidas`
  }

  getTotalMinEnvelope(offers) {
    return (
      this.bidItems.reduce(
        (prevItem, currentItem) => prevItem + currentItem.getMinEnvelope(offers).total,
        0,
      ) || 0
    )
  }
}
