import PropTypes from 'prop-types'
import { SelectInput } from '@proveoeng/uikit/dist/atoms/Select'
import { useUnit } from '../hooks/useUnit'

export const SelectUnit = ({ defaultValue, ...props }) => {
  const { options, loadOptions } = useUnit()

  return (
    <SelectInput
      {...props}
      isAsync
      cacheOptions
      defaultOptions={options}
      defaultValue={{ label: defaultValue, value: defaultValue }}
      loadOptions={loadOptions}
    />
  )
}

SelectInput.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholderMessage: PropTypes.string,
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  hasError: PropTypes.bool,
}

SelectInput.defaultProps = {
  name: 'unit',
  id: 'unit',
  label: 'Unidad',
  required: false,
  placeholderMessage: 'Seleccionar o buscar',
  isSearchable: false,
  onChange: () => {},
  defaultValue: '',
  hasError: false,
}
