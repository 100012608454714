import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useDashboard } from '../../../../common/hooks/useDashboard'
import HomeDashboardTemplate from '../template/HomeDashboardTemplate'

const HomeDashboardPage = () => {
  const { statsUrl, getDashboard } = useDashboard()

  const { employeeRole } = useSelector((state) => state.employee?.data)

  const companyId = useSelector((state) => state.employee?.data?.companyId)

  const isSuper = employeeRole === 'Super'

  const title = isSuper ? 'Estadísticas de tu compañia' : 'Mis estadísticas'

  const [whoIsActive, setWhoIsActive] = useState(isSuper ? 'general' : 'material')

  const getStatsUrlSelected = () => {
    const statsUrlObj = {
      general: statsUrl.generalStatsUrl,
      material: statsUrl.materialStatsUrl,
      provider: statsUrl.providerStatsUrl,
    }

    return statsUrlObj[whoIsActive] || null
  }

  useEffect(() => {
    getDashboard(companyId)
  }, [companyId, whoIsActive])

  const tabItems = isSuper
    ? [
        {
          id: 'general',
          children: <>Obras y Procesos de compra</>,
          isActive: whoIsActive === 'general',
          action: (_, target) => setWhoIsActive(target.id),
        },
        {
          id: 'provider',
          children: <>Proveedores</>,
          isActive: whoIsActive === 'provider',
          action: (_, target) => setWhoIsActive(target.id),
        },
        {
          id: 'material',
          children: <>Materiales</>,
          isActive: whoIsActive === 'material',
          action: (_, target) => setWhoIsActive(target.id),
        },
      ]
    : [
        {
          id: 'material',
          children: <>Materiales</>,
          isActive: whoIsActive === 'material',
          action: (_, target) => setWhoIsActive(target.id),
        },
      ]

  return (
    <HomeDashboardTemplate tabItems={tabItems} title={title} statsUrl={getStatsUrlSelected()} />
  )
}

export default HomeDashboardPage
