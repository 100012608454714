import { useState } from 'react'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Box, Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Card } from '@proveoeng/uikit/dist/atoms/Card'
import { ActionMenu } from '@proveoeng/uikit/dist/molecules/ActionsMenu/ActionMenu'
import { Checkbox } from '@proveoeng/uikit/dist/atoms/Checkbox'
import { Accordion } from '@proveoeng/uikit/dist/atoms/Accordion'

import { ComparativeList } from './ComparativeList'
import { ProposalPaymentDaysTooltip } from './ProposalPaymentDaysTooltip'
import { ProposalCommentsTooltip } from './ProposalCommentsTooltip'
import { ProposalDiscountTooltip } from './ProposalDiscountTooltip'

import { colorState, copyState } from '../../../config'
import { currencyFormat } from '../../../../../../lib/utils/Format'
import { parseEditorMessage } from '../../../../../../lib/utils/parseEditorMessage'
import { ModalSendMessage } from '../../../../../common/components/sendMessage/ModalSendMessage'

export const ProposalsListResume = ({
  bidPackage,
  bidPackageSelected,
  onSelectedBidPackage,
  onCancelProposal,
  onAwardProposal,
  resetProposal,
  proposals,
  sentOffers,
  comparativesList,
  goToProposal,
  goToComparative,
}) => {
  const [itemsChecked, setItemsChecked] = useState([])

  const [sendModalIsOpen, setSendModalIsOpen] = useState(false)
  const [configMessageModal, setConfigMessageModal] = useState({
    title: '',
    subTitle: <></>,
    onSubmit: () => undefined,
    hasCancelButton: true,
  })

  const updateProposal = () => {
    resetProposal()
    onSelectedBidPackage(bidPackage?.bidPackageId)
  }

  const handleAwardProposal = async ({
    proposalId,
    awardedMessage,
    notifyAwarded,
    notAwardedMessage,
    notifyNotAwarded,
  }) => {
    await onAwardProposal({
      proposalId,
      awardedMessage,
      notifyAwarded,
      notAwardedMessage,
      notifyNotAwarded,
    })
    updateProposal()
    setSendModalIsOpen(false)
  }

  const notAwardsAction = (actionData) => {
    setConfigMessageModal({
      title: '¿Quieres enviar notificación a los proveedores no seleccionados?',
      subTitle: (
        <Text>
          Puedes enviar una notificación con un mensaje opcional a los proveedores no seleccionados.
        </Text>
      ),
      onSubmit: ({ message, notify }) => {
        const messageParsed = parseEditorMessage(message)
        handleAwardProposal({
          ...actionData,
          notAwardedMessage: messageParsed,
          notifyNotAwarded: notify,
        })
      },
    })
  }

  const awardsAction = (proposal) => {
    setSendModalIsOpen(true)
    setConfigMessageModal({
      title: '¿Quieres seleccionar a este proveedor?',
      subTitle: (
        <Text>
          Puedes enviar una notificación con un mensaje opcional a{' '}
          <strong>{proposal.sellerName}</strong>
        </Text>
      ),
      hasCancelButton: true,
      hasNotNotifyButton: false,
      hasCheckbox: true,
      onSubmit: ({ message, notify }) => {
        const messageParsed = parseEditorMessage(message)
        notAwardsAction({
          proposalId: proposal.proposalId,
          awardedMessage: messageParsed,
          notifyAwarded: notify,
        })
      },
    })
  }

  const handleCancelProposal = async ({ proposalId, message }) => {
    const messageParsed = parseEditorMessage(message)
    await onCancelProposal(proposalId, { message: messageParsed })
    updateProposal()
    setSendModalIsOpen(false)
  }

  const cancelAction = (proposal) => {
    setSendModalIsOpen(true)
    setConfigMessageModal({
      title: '¿Quieres descartar la propuesta?',
      subTitle: (
        <Text>
          Puedes enviar una notificación con un mensaje opcional a{' '}
          <strong>{proposal.sellerName}</strong>
        </Text>
      ),
      hasNotNotifyButton: false,
      sendNotificationImmediately: true,
      onSubmit: ({ message }) => {
        const messageParsed = parseEditorMessage(message)
        handleCancelProposal({
          proposalId: proposal.proposalId,
          message: messageParsed,
        })
      },
    })
  }

  const actions = (proposal) => [
    {
      id: 'awards',
      content: (
        <Text sizeText="display14" color="black" paddingTop={2}>
          Seleccionar
        </Text>
      ),
      action: () => awardsAction(proposal),
    },
    {
      id: 'cancel',
      content: (
        <Text sizeText="display14" color="black" paddingTop={2}>
          Descartar
        </Text>
      ),
      action: () => cancelAction(proposal),
    },
  ]

  const checkProposal = (id, value) => {
    if (value) {
      setItemsChecked((prev) => [...prev, id])
    } else {
      const updateItems = itemsChecked.filter((element) => element !== id)
      setItemsChecked(updateItems)
    }
  }

  return (
    <>
      <Box paddingX={4} paddingBottom={2}>
        <Flex paddingY={2}>
          <Card bg="gray4" margin={3} padding={4}>
            <Flex flexDirection="column">
              <Text
                as="span"
                fontWeight="medium"
                color="gray"
                sizeText="display12"
                marginBottom={2}>
                Presupuesto de venta
              </Text>
              <Text as="span" fontWeight="bold" color="black" sizeText="display30">
                {!bidPackageSelected?.budget
                  ? '0.00 €'
                  : currencyFormat(bidPackageSelected?.budget)}
              </Text>
            </Flex>
          </Card>
          <Card bg="gray4" margin={3} padding={4}>
            <Flex flexDirection="column">
              <Text
                as="span"
                fontWeight="medium"
                color="gray"
                sizeText="display12"
                marginBottom={2}>
                Coste Previsto
              </Text>
              <Text as="span" fontWeight="bold" color="black" sizeText="display30">
                {!bidPackageSelected?.expectedCosts
                  ? '0.00 €'
                  : currencyFormat(bidPackageSelected?.expectedCosts)}
              </Text>
            </Flex>
          </Card>
        </Flex>
        <Accordion
          isOpened
          customTitle={
            <Flex alignItems="center" justifyContent="space-between" width="100%">
              <Text as="span" marginLeft={1} sizeText="display18" color="black">
                Propuestas
              </Text>
              <Text sizeText="display14" color="black">
                {proposals?.data?.length} propuestas de {sentOffers} ofertas enviadas
              </Text>
            </Flex>
          }
          style={{ alignItems: 'center' }}>
          {!proposals?.data?.length ? (
            <Text as="span" marginLeft={1} sizeText="display14" color="black">
              No hay propuestas recibidas
            </Text>
          ) : (
            <>
              <Grid
                gridTemplateAreas={
                  "'company status offer paymentConditions generalConditions actions'"
                }
                gridTemplateColumns="minmax(250px, 1fr) 1fr 1fr 200px 1fr 60px"
                bg="gray4"
                paddingY={2}
                borderRadius={2}>
                <GridItem gridArea="company">
                  <Text
                    sizeText="display14"
                    color="gray1"
                    overflow="hidden"
                    fontWeight="medium"
                    marginLeft={5}>
                    Empresa
                  </Text>
                </GridItem>
                <GridItem gridArea="status">
                  <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
                    Estado
                  </Text>
                </GridItem>
                <GridItem gridArea="offer">
                  <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
                    Oferta
                  </Text>
                </GridItem>
                <GridItem gridArea="paymentConditions">
                  <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
                    Días de pago
                  </Text>
                </GridItem>
                <GridItem gridArea="generalConditions">
                  <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
                    Condiciones generales
                  </Text>
                </GridItem>
                <GridItem gridArea="actions" />
              </Grid>
              {proposals?.data?.map((el) => (
                <Grid
                  gridTemplateAreas={
                    "'company status offer paymentConditions generalConditions actions'"
                  }
                  gridTemplateColumns="minmax(250px, 1fr) 1fr 1fr 200px 1fr 60px"
                  paddingY={3}
                  borderBottomColor="gray4"
                  borderBottomStyle="solid"
                  borderBottomWidth="1px">
                  <GridItem gridArea="company" style={{ alignItems: 'center' }} display="flex">
                    <Text
                      sizeText="display14"
                      color="gray1"
                      overflow="hidden"
                      fontWeight="medium"
                      marginLeft={5}>
                      <Flex alignItems="center">
                        <Checkbox
                          name={el?.proposalId}
                          sizeText="display14"
                          textAlign="left"
                          fontWeight="medium"
                          action={checkProposal}
                          ariaLabel="companyId"
                          marginY={0}
                        />
                        <Text
                          sizeText="display14"
                          fontWeight="medium"
                          onClick={() => goToProposal(el?.proposalId)}
                          style={{ cursor: 'pointer' }}>
                          {el?.sellerName}
                        </Text>
                      </Flex>
                    </Text>
                  </GridItem>

                  <GridItem gridArea="status" style={{ alignItems: 'center' }} display="flex">
                    <Flex borderRadius={3} bg={colorState[el?.state]} padding={2}>
                      <Text
                        sizeText="display14"
                        color="gray1"
                        overflow="hidden"
                        fontWeight="medium">
                        {copyState[el?.state]}
                      </Text>
                    </Flex>
                  </GridItem>
                  <GridItem
                    gridArea="offer"
                    style={{
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                    display="flex">
                    <Text sizeText="display14" color="gray1" fontWeight="medium">
                      <ProposalDiscountTooltip discount={el?.discount} total={el?.total} />
                    </Text>
                  </GridItem>
                  <GridItem
                    gridArea="paymentConditions"
                    style={{ alignItems: 'center' }}
                    display="flex">
                    <Text sizeText="display14" color="gray1">
                      <ProposalPaymentDaysTooltip
                        paymentDays={el?.paymentDays}
                        paymentConditions={el?.paymentConditions}
                      />
                    </Text>
                  </GridItem>
                  <GridItem
                    gridArea="generalConditions"
                    style={{ alignItems: 'center' }}
                    display="flex">
                    <ProposalCommentsTooltip comments={el?.comments} />
                  </GridItem>
                  <GridItem gridArea="actions">
                    <Flex alignItems="center" justifyContent="flex-start">
                      {(el?.state === 'Awaiting response' ||
                        el?.state === 'Awaiting improvement') && (
                        <ActionMenu actions={actions(el)} />
                      )}
                    </Flex>
                  </GridItem>
                </Grid>
              ))}
            </>
          )}
        </Accordion>

        <Flex justifyContent="flex-end" marginTop={3} marginBottom={6} paddingX={2}>
          <Button
            colorType="orange"
            disabled={
              !Object.entries(itemsChecked).length ||
              (Object.entries(itemsChecked).length > 5 && Object.entries(itemsChecked).length > 0)
            }
            action={() => goToComparative(itemsChecked)}>
            Abrir en comparador
          </Button>
        </Flex>
        <Accordion
          isOpened
          customTitle={
            <Flex alignItems="center" justifyContent="space-between" width="100%">
              <Text as="span" marginLeft={1} sizeText="display18" color="black">
                Comparativos
              </Text>
              <Text sizeText="display14" color="black">
                {`${comparativesList?.length} resultados`}
              </Text>
            </Flex>
          }
          style={{ alignItems: 'center' }}>
          {comparativesList.length ? (
            <ComparativeList
              projectId={bidPackageSelected?.projectId}
              bidPackageId={bidPackageSelected?.bidPackageId}
              comparativesList={comparativesList}
            />
          ) : (
            <Text as="span" marginLeft={1} sizeText="display14" color="black">
              No hay comparativos guardados
            </Text>
          )}
        </Accordion>
      </Box>

      <ModalSendMessage
        {...configMessageModal}
        isOpened={sendModalIsOpen}
        setIsOpened={setSendModalIsOpen}
      />
    </>
  )
}
