import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

export const ContainerProject = ({ children, goToHome, isEdit }) => (
  <Flex height="100%" width="100%" paddingY={7} paddingX={9} flexDirection="column">
    <Flex height="100%" width="100%" flexDirection="column" maxWidth={744}>
      <Text as="span" sizeText="display14" color="gray2" paddingBottom={6}>
        <Text
          as="span"
          sizeText="display14"
          color="blue1"
          marginBottom={7}
          style={{ cursor: 'pointer' }}
          onClick={goToHome}>
          {'<'} Volver
        </Text>{' '}
        | Tus obras /
        <Text as="span" sizeText="display14" color="black">
          {' '}
          {isEdit ? 'Editar obra' : 'Crea obra nueva'}
        </Text>
      </Text>
      {children}
    </Flex>
  </Flex>
)
