import { useState } from 'react'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'
import { Box, Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { Button, ButtonTabs } from '@proveoeng/uikit/dist/atoms/Button'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Info } from '@proveoeng/uikit/dist/atoms/Icons'

import { PreviewBidPackageProjectInfo } from './PreviewBidPackageProjectInfo'
import { PreviewBidPackageBidItems } from './PreviewBidPackageBidItems'
import { PreviewBidPackageInfo } from './PreviewBidPackageInfo'

export const PreviewBidPackage = ({
  isOpened,
  setIsOpened,
  project,
  bidPackage,
  bidItems,
  onPublish,
  isPublished,
}) => {
  const [whoIsActive, setWhoIsActive] = useState('projectInfo')
  return (
    <Modal
      isOpen={isOpened}
      id="preview-modal"
      closeWithButton
      onModalClose={() => setIsOpened(false)}
      title={project?.name}
      width="100%"
      maxWidth="75vw">
      <Modal.Content>
        <Flex
          padding={4}
          borderRadius={2}
          borderStyle="solid"
          borderWidth={1}
          borderColor="gray3"
          bg="white2"
          marginBottom={6}>
          <Box paddingRight={2}>
            <Icon sizeIcon="display18">
              <Info />
            </Icon>
          </Box>
          <Box>
            <Text sizeText="display16">
              Esta es la información del proceso de compra y la obra que se va a compartir con el
              prooveedor. Por favor revisa que esté todo correcto antes de publicarla.
            </Text>
          </Box>
        </Flex>
        <Box margin="0 auto">
          <Flex flexDirection="column" alignItems="baseline">
            <ButtonTabs
              items={[
                {
                  id: 'projectInfo',
                  children: <>Información de la obra</>,
                  isActive: whoIsActive === 'projectInfo',
                  action: (_, target) => setWhoIsActive(target.id),
                },
                {
                  id: 'bidPackageInfo',
                  children: <>Información de la compra</>,
                  isActive: whoIsActive === 'bidPackageInfo',
                  action: (_, target) => setWhoIsActive(target.id),
                },
                {
                  id: 'bidItems',
                  children: <>Partidas</>,
                  isActive: whoIsActive === 'bidItems',
                  action: (_, target) => setWhoIsActive(target.id),
                },
              ]}
            />
            <Box paddingY={5} width="100%">
              {whoIsActive === 'projectInfo' && <PreviewBidPackageProjectInfo project={project} />}
              {whoIsActive === 'bidPackageInfo' && (
                <PreviewBidPackageInfo bidPackage={bidPackage} />
              )}
              {whoIsActive === 'bidItems' && <PreviewBidPackageBidItems bidItems={bidItems} />}
            </Box>
          </Flex>
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Grid gridTemplateColumns="1fr 1fr" gridTemplateRows="1fr" gridColumnGap={5}>
            <Button id="cancel-button" colorType="gray" action={() => setIsOpened(false)}>
              Volver a editar
            </Button>
            <Button
              colorType="orange"
              action={() => {
                onPublish()
              }}>
              {isPublished ? 'Siguiente' : 'Elegir proveedores'}
            </Button>
          </Grid>
        </Flex>
      </Modal.Actions>
    </Modal>
  )
}
