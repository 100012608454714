import { constants } from '../config'

export const addImage = (id, thumb) => ({
  type: constants.ADD_IMAGE,
  payload: { id, thumb },
})

export const removeImage = (id) => ({
  type: constants.REMOVE_IMAGE,
  payload: { id },
})
