import * as Yup from 'yup'

/**
 *
 * @param {string[]} items
 * @returns
 */
export const getDinamySchema = (items, required = false) =>
  items.reduce(
    (acc, item) => ({
      ...acc,
      [item]: required
        ? Yup.string().nullable().required('Campo requerido')
        : Yup.string().nullable(),
    }),
    {},
  )
