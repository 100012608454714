import Proptypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router-dom'
import ResetPasswordPage from '../../../pages/resetPassword/container/ResetPasswordPage'
import { ProfileCompanyPage } from '../../../pages/profile/profileBuyer/container/ProfileCompanyPage'
import { ProfileSellerPage } from '../../../pages/profile/profileSeller/container/ProfileSellerPage'
import { ProfileUserPage } from '../../../pages/profile/profileUser/container/ProfileUserPage'
import { Layout } from '../../../common/components/Layout/Layout'

export const routes = (profilePath) => ({
  path: profilePath,
  pages: {
    profile: {
      path: `${profilePath}`,
      seller: {
        path: `${profilePath}/proveedor`,
      },

      company: {
        path: `${profilePath}/compania`,
      },
      resetPassword: {
        path: `${profilePath}/restablecer`,
        hash: {
          path: `${profilePath}/restablecer/:hash`,
        },
      },
    },
  },
})

const RouterWrapper = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    )}
  />
)

export const ProfileRoutes = ({ match: { url } }) => {
  const applicationRoutes = routes(url)
  return (
    <Switch>
      <Route
        path={[
          applicationRoutes.pages.profile.resetPassword.path,
          applicationRoutes.pages.profile.resetPassword.hash.path,
        ]}
        component={ResetPasswordPage}
        exact
      />
      <RouterWrapper
        layout={Layout}
        path={applicationRoutes.pages.profile.path}
        component={ProfileUserPage}
        exact
      />
      <RouterWrapper
        layout={Layout}
        path={applicationRoutes.pages.profile.seller.path}
        component={ProfileSellerPage}
        exact
      />
      <RouterWrapper
        layout={Layout}
        path={applicationRoutes.pages.profile.company.path}
        component={ProfileCompanyPage}
        exact
      />
      <Redirect to={applicationRoutes.pages.profile.path} />
    </Switch>
  )
}

ProfileRoutes.propTypes = { match: Proptypes.shape().isRequired }
