import { useFormikContext } from 'formik'
import { LayoutRegister } from '../../../common/components/Layout/LayoutRegister'
import { RegisterDataBasic } from '../components/RegisterDataBasic'
import { RegisterDataCategories } from '../components/RegisterDataCategories'
import { RegisterDataCompany } from '../components/RegisterDataCompany'
import { TitleStyled } from '../styles/RegisterStyles'

/**
 *
 * @param {Object} props
 * @param {void} props.goBack
 * @param {void} props.goNext
 * @param {number} props.step
 * @param {number} props.totalSteps
 * @param {void} props.preCheck
 * @param {string | null} props.emailInvitation
 * @param {Array} props.categories
 */
export const RegisterTemplate = ({
  goBack,
  step,
  totalSteps,
  preCheck,
  emailInvitation,
  categories = [],
}) => {
  const { submitForm } = useFormikContext()

  return (
    <LayoutRegister
      type="seller"
      step={step}
      totalSteps={totalSteps}
      goBack={goBack}
      onSubmit={submitForm}>
      <TitleStyled sizeText="display52" color="black">
        Completa tus datos para poder acceder a las ofertas
      </TitleStyled>
      {step === 0 && <RegisterDataBasic preCheck={preCheck} emailInvitation={emailInvitation} />}
      {step === 1 && <RegisterDataCompany preCheck={preCheck} />}
      {step === 2 && <RegisterDataCategories categories={categories} />}
    </LayoutRegister>
  )
}
