export const parseQuality = (type) => {
  const qualities = {
    VeryHigh: 'Muy alta',
    High: 'Alta',
    Medium: 'Media',
    Low: 'Baja',
    VeryLow: 'Muy baja',
  }

  return qualities[type] || null
}
