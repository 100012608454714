import { OfferItem } from './offerItem'

export class Offer {
  constructor({
    id,
    name,
    position,
    offerItems,
    proposalId,
    proposalInfo,
    paymentDays,
    paymentConditions,
    retention,
    retentionCriteria,
    startDate,
    endDate,
    discount,
    enviroData,
  }) {
    this.id = id
    this.name = name
    this.position = position
    this.proposalId = proposalId
    this.proposalInfo = proposalInfo
    this.paymentDays = paymentDays
    this.paymentConditions = paymentConditions
    this.retention = retention
    this.retentionCriteria = retentionCriteria
    this.startDate = startDate
    this.endDate = endDate
    this.offerItems = offerItems
    this.discount = discount
    this.enviroData = enviroData
  }

  static create({
    id,
    name,
    position,
    offerItems,
    proposalId,
    proposalInfo,
    paymentDays,
    paymentConditions,
    retention,
    retentionCriteria,
    startDate,
    endDate,
    discount,
    enviroData,
  }) {
    const offerItemsParsed = offerItems.map((item) => OfferItem.create(item))
    return new Offer({
      id,
      name,
      position,
      offerItems: offerItemsParsed,
      proposalId,
      proposalInfo,
      paymentDays,
      paymentConditions,
      retention,
      retentionCriteria,
      startDate,
      endDate,
      discount,
      enviroData,
    })
  }

  getItem(itemId) {
    return this.offerItems.find((item) => item.itemId === itemId)
  }

  getItemTotal(item) {
    return this.getItem(item.id)?.getTotal(item.measure)
  }

  getTotalItems(items) {
    const totalItemsWithoutDiscount = items.reduce(
      (prevItem, currentItem) => prevItem + this.getItemTotal(currentItem) || 0,
      0,
    )

    return totalItemsWithoutDiscount * (1 - this.discount / 100 / 100)
  }

  getTotal(sections) {
    return sections.reduce(
      (prevSection, currentSection) =>
        prevSection + this.getTotalItems(currentSection.bidItems) || 0,
      0,
    )
  }
}
