import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { TitleStyled } from '../../styles/ProfileStyles'
import { ProfileUser } from '../components/ProfileUser'

/**
 *
 * @param {Object} props
 * @param {void} props.handleSubmitEmployee
 * @param {void} props.onProfileImageChange
 * @param {void} props.onLogoImageChange
 */
export const ProfileUserTemplate = ({
  employee,
  handleSubmitEmployee,
  profileImage,
  onProfileImageChange,
}) => (
  <>
    <Flex height="100%" width="100%" paddingTop={7} flexDirection="column" paddingX={7}>
      <Text sizeText="display14" color="gray2" marginBottom={7}>
        Configuración
        <Text as="span" sizeText="display14" color="black" marginBottom={7} marginLeft={2}>
          Tu perfil
        </Text>
      </Text>
      <TitleStyled sizeText="display52" color="gray">
        Configura tu perfil
      </TitleStyled>
      <ProfileUser
        avatar={profileImage}
        employee={employee}
        onSubmit={handleSubmitEmployee}
        handleChangeAvatar={onProfileImageChange}
      />
    </Flex>
  </>
)
