/**
 * @description Get the filename from the headers of a response.
 * @param {Object} headers
 * @returns string
 */
export const getFileNameFromHeaders = (headers) => {
  /* Getting the file name from the header. */
  const contentDisposition = headers['content-disposition']
  if (!contentDisposition) return ''

  const fileNameUTF8 = contentDisposition.split("filename*=utf-8''")[1]?.split(';')[0]

  if (fileNameUTF8) {
    return decodeURIComponent(fileNameUTF8)
  }

  /* If utf-8 is not available, use the filename. */
  const fileName = contentDisposition.split('filename=')[1]?.split(';')[0]?.replace(/"/g, '')
  if (fileName) {
    return decodeURIComponent(fileName)
  }

  return ''
}
