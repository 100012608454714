import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

import { Button, ButtonTabs } from '@proveoeng/uikit/dist/atoms/Button'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Download, LinkIcon, PlusCircle } from '@proveoeng/uikit/dist/atoms/Icons'
import { InputNumber } from '@proveoeng/uikit/dist/atoms/InputNumber'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { LinkText, Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { DataTable } from '@proveoeng/uikit/dist/molecules/DataTable'

import { currencyFormat, parseToInteger } from '../../../../../lib/utils/Format'
import { OfferImportGuide } from './OfferImportGuide'
import { AttachmentList } from '../../../../common/components/attachmentList/Attachment'
import { ModalEditItemDetails } from './ModalEditItemDetails'

const numberOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

export const EditProposalBidItems = ({
  proposalItems = [],
  handleExportBidPackagePrices,
  openImportModal,
}) => {
  const [total, setTotal] = useState(0)
  const [isModalFilesIsOpened, setIsModalFilesIsOpened] = useState(false)

  const [selectedProposalItem, setSelectedProposalItem] = useState(null)

  const { values, handleChange, handleBlur, errors, touched } = useFormikContext()

  useEffect(() => {
    if (proposalItems?.length) {
      const totalSum = proposalItems.reduce((prev, current) => {
        const value = values[`unitaryPrice-${current.proposalItemId}`]
        if (!value) return prev
        const unitaryPrice = parseToInteger(value) / 100
        const measurement = parseToInteger(current.bidItem?.measurement, 3) / 1000

        return prev + unitaryPrice * measurement
      }, 0)
      setTotal(totalSum)
    }
  }, [values])

  const columns = [
    {
      name: 'Partida',
      selector: (row) => row.bidItem?.name.toLowerCase(),
      cell: (row) => row.bidItem?.name,
      sortable: true,
      minWidth: '200px',
      maxWidth: '320px',
    },
    {
      name: 'Unidad',
      selector: (row) => row.bidItem?.unit,
      sortable: true,
      width: '92px',
    },
    {
      name: 'Medición',
      selector: (row) => row.bidItem?.measurement,
      sortable: true,
      width: '106px',
      right: true,
    },
    {
      name: 'Descripción',
      selector: (row) => row.bidItem?.description,
      cell: (row) => (
        <Text
          as="p"
          sizeText="display14"
          color="black"
          style={{
            whiteSpace: 'pre-wrap',
          }}>
          {row.bidItem?.description || '-'}
        </Text>
      ),
      minWidth: '210px',
      maxWidth: '400px',
    },
    {
      name: 'Precio',
      cell: (row) => {
        const name = `unitaryPrice-${row.proposalItemId}`
        return (
          <InputNumber
            required
            placeholderMessage="0.00"
            ariaLabel="unitaryPrice"
            value={values[name]}
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            hasError={touched[name] && !!errors[name]}
          />
        )
      },
      width: '140px',
    },
    {
      name: 'Importe',
      cell: (row) => (
        <Text sizeText="display14" color="gray1">
          {currencyFormat(
            parseFloat(parseToInteger(row.bidItem.measurement, 3) / 1000) *
              parseFloat(parseToInteger(values[`unitaryPrice-${row.proposalItemId}`]) / 100),
          )}
        </Text>
      ),
      width: '140px',
      right: true,
    },
    {
      name: '',
      cell: (row) => {
        const files = values[`files-${row.proposalItemId}`] || []
        const links = values[`links-${row.proposalItemId}`] || []
        const comments = values[`comments-${row.proposalItemId}`]

        return (
          <>
            {files?.length || links?.length || comments ? (
              <Text
                color="blue1"
                textAlign="right"
                sizeText="display14"
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => handleEditItemDetails(row)}>
                Ver detalles
              </Text>
            ) : (
              <Flex alignItems="center">
                <PlusCircle sizeIcon="display18" />
                <Text
                  color="gray1"
                  sizeText="display14"
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => handleEditItemDetails(row)}>
                  Añadir detalles
                </Text>
              </Flex>
            )}
          </>
        )
      },
      right: true,
      width: '110px',
      style: {
        padding: '0',
      },
    },
  ]

  const handleEditItemDetails = (item) => {
    setSelectedProposalItem(item)
    setIsModalFilesIsOpened(true)
  }

  const handleCloseEditItemDetails = () => {
    setIsModalFilesIsOpened(false)
    setSelectedProposalItem(null)
  }

  return (
    <>
      <Flex marginTop={4} marginBottom={6} alignItems="center">
        <Box mr={4}>
          <Button colorType="white" action={handleExportBidPackagePrices} width="178px">
            <Icon>
              <Download />
            </Icon>{' '}
            Descargar compra
          </Button>
        </Box>
        <Box width="168px">
          <Button colorType="orange" fullWidth action={openImportModal}>
            Importa oferta
          </Button>
        </Box>
        <Box>
          <OfferImportGuide withTooltip />
        </Box>
      </Flex>

      <DataTable
        columns={columns}
        data={proposalItems}
        expandableRows
        expandableRowsComponent={DetailsItem}
      />

      <Flex
        alignItems="center"
        paddingX={3}
        paddingY={3}
        width="100%"
        justifyContent="space-between">
        <Flex alignItems="center">
          <Text as="span" sizeText="display18" color="black" fontWeight="bold">
            Total
          </Text>
        </Flex>
        <Text as="span" sizeText="display18" color="black" fontWeight="bold">
          {parseToInteger(total).toLocaleString('de-DE', numberOptions)} €
        </Text>
      </Flex>

      <ModalEditItemDetails
        item={selectedProposalItem}
        isOpen={isModalFilesIsOpened}
        onClose={handleCloseEditItemDetails}
      />
    </>
  )
}

const DetailsItem = ({ data: { bidItem } }) => {
  const [whoIsActive, setWhoIsActive] = useState('attachments')
  return (
    <Flex padding={3} flexDirection="column" alignItems="baseline" width="100%">
      <Flex paddingX={2} flexDirection="column" alignItems="baseline" paddingY={2}>
        <ButtonTabs
          items={[
            {
              id: 'attachments',
              children: <>Adjuntos</>,
              isActive: whoIsActive === 'attachments',
              action: (_, target) => setWhoIsActive(target.id),
            },
            {
              id: 'comments',
              children: <>Comentario</>,
              isActive: whoIsActive === 'comments',
              action: (_, target) => setWhoIsActive(target.id),
            },
            {
              id: 'links',
              children: <>Enlaces externos</>,
              isActive: whoIsActive === 'links',
              action: (_, target) => setWhoIsActive(target.id),
            },
          ]}
        />
        {whoIsActive === 'attachments' && (
          <>
            {bidItem?.files?.length ? (
              <AttachmentList files={bidItem?.files} withDownload />
            ) : (
              <Flex marginY={3} flexWrap="wrap">
                <Text
                  sizeText="display14"
                  color="gray1"
                  overflow="hidden"
                  fontWeight="medium"
                  marginLeft={5}>
                  No hay archivos adjuntos
                </Text>
              </Flex>
            )}
          </>
        )}
        {whoIsActive === 'comments' && (
          <Text
            sizeText="display14"
            marginY={2}
            marginLeft={2}
            style={{
              whiteSpace: 'pre-wrap',
            }}>
            {bidItem?.comments || '-'}
          </Text>
        )}
        {whoIsActive === 'links' && (
          <Flex marginY={3} flexWrap="wrap">
            {!bidItem?.links?.length && (
              <Text
                sizeText="display14"
                color="gray1"
                overflow="hidden"
                fontWeight="medium"
                marginLeft={5}>
                No hay enlaces externos
              </Text>
            )}
            {bidItem?.links?.map((link, key) => (
              <Box marginTop={5} width="100%">
                <Flex key={key}>
                  <Box marginRight={2}>
                    <Icon color="gray2" sizeIcon="display12">
                      <LinkIcon />
                    </Icon>
                  </Box>
                  <LinkText
                    href={link.url}
                    title={link.url}
                    target="_blank"
                    sizeText="display16"
                    color="blue1"
                    display="inline-block"
                    overflow="hidden"
                    maxWidth="500px"
                    style={{
                      clear: 'both',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}>
                    {link.url}
                  </LinkText>
                </Flex>
                {link?.description && (
                  <Flex marginTop={1} flexDirection="column">
                    <Text sizeText="display14" fontWeight="normal">
                      Descripción
                    </Text>
                    <Text
                      sizeText="display14"
                      style={{
                        whiteSpace: 'pre-wrap',
                      }}>
                      {link.description}
                    </Text>
                  </Flex>
                )}
              </Box>
            ))}
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
