import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Textarea } from '@proveoeng/uikit/dist/atoms/Textarea'
import { SelectInput } from '@proveoeng/uikit/dist/atoms/Select'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

const validationSchema = Yup.object().shape({
  electedSeller: Yup.string().required('Debe selecionar un proveedor'),
  electionReason: Yup.string(),
})

export const ExportModal = ({ offers = [], isOpen, onClose, exportIntegration }) => {
  const [options, setOptions] = useState([])

  const onSubmit = async (values, formikHelpers) => {
    await exportIntegration(values, formikHelpers)
    onClose()
  }

  const { values, errors, isSubmitting, setFieldValue, resetForm, handleSubmit } = useFormik({
    initialValues: {
      electedSeller: '',
      electionReason: '',
    },
    onSubmit,
    validationSchema,
  })

  const onClear = () => {
    resetForm()
    onClose()
  }

  useEffect(() => {
    setOptions(
      offers.map((offer) => ({
        value: offer.sellerTaxCode,
        label: offer.title,
      })),
    )
  }, [offers])

  return (
    <Modal
      isOpen={isOpen}
      id="export-modal"
      onModalClose={onClear}
      closeWithButton
      title="Exportar comparativo"
      maxWidth="570px">
      <Modal.Content>
        <form onSubmit={handleSubmit}>
          <Flex flexDirection="column">
            <Box marginBottom={4}>
              <Field
                label="Proveedor propuesto*"
                sizeText="display16"
                marginBottom={3}
                marginTop={5}>
                <SelectInput
                  isSearchable
                  name="electedSeller"
                  id="seller"
                  onChange={(_, v) => setFieldValue('electedSeller', v.value)}
                  options={options}
                  placeholderMessage="Seleccionar un proveedor propuesto"
                  ariaLabel="electedSeller"
                  hasError={errors.electedSeller}
                  disabled={isSubmitting}
                />
                {errors.electedSeller && (
                  <Box paddingX={2}>
                    <Text sizeText="display10" color="red1">
                      {errors.electedSeller}
                    </Text>
                  </Box>
                )}
              </Field>
            </Box>
            <Box marginBottom={4}>
              <Field label="Motivo de proponer" sizeText="display16" marginBottom={4} marginTop={0}>
                <Textarea
                  placeholderMessage="Explica por qué consideras a este proveedor"
                  onChange={(e) => setFieldValue('electionReason', e.target.value)}
                  name="electionReason"
                  maxLength="auto"
                  defaultValue={values.electionReason}
                  disabled={isSubmitting}
                />
                {errors.electionReason && (
                  <Box paddingX={2}>
                    <Text sizeText="display10" color="red1">
                      {errors.electionReason}
                    </Text>
                  </Box>
                )}
              </Field>
            </Box>
          </Flex>
        </form>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="center">
          <Flex width="100%">
            <Button
              id="cancel-button"
              fullWidth
              marginRight={5}
              colorType="transparent"
              action={onClear}
              type="button">
              Cancelar
            </Button>
            <Button
              fullWidth
              colorType="orange"
              type="submit"
              onClick={handleSubmit}
              disabled={isSubmitting}>
              Exportar
            </Button>
          </Flex>
        </Flex>
      </Modal.Actions>
    </Modal>
  )
}
