import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { parseToString } from '../../../../../lib/utils/Format'
import { FormatDate } from '../../../mappers/FormatDate'
import { PreviewConfirm } from './PreviewConfirm'

export const ContentConfirm = ({
  name,
  categoryName,
  bidEndsAt,
  selectedBidItems,
  seletedLength,
  selectTotalECO2,
}) => (
  <>
    <Box minWidth="800px" minHeight="300px" maxWidth="80vw" overflow="auto">
      <Flex justifyContent="space-between" marginBottom={1}>
        <Box>
          <Text sizeText="display16" color="black" marginBottom={2}>
            Nombre de la compra: <strong>{name}</strong>
          </Text>
          <Text sizeText="display16" color="black" marginBottom={2}>
            Naturaleza: <strong>{categoryName}</strong>
          </Text>
        </Box>
        <Box marginBottom={2} textAlign="end">
          <Text sizeText="display16" color="black">
            Fecha límite para presentar oferta
          </Text>
          <Text sizeText="display16" color="black">
            <strong>{FormatDate.hydrate(bidEndsAt)?.readableStringDate}</strong>
          </Text>
        </Box>
      </Flex>
      <Flex justifyContent="end">
        <Text as="h4" sizeText="display14" color="black" marginBottom={2}>
          {seletedLength} partidas seleccionadas
        </Text>
      </Flex>
      <PreviewConfirm data={selectedBidItems} />
      <Flex
        marginTop={2}
        paddingX={2}
        paddingY={4}
        justifyContent="space-between"
        style={{
          borderTop: '1px solid #D1D7E6',
        }}>
        <Text sizeText="display16" fontWeight="600">
          Totales
        </Text>
        <Flex>
          <Text sizeText="display16" fontWeight="600" width="120px">
            {selectTotalECO2 ? `${parseToString(selectTotalECO2)} kg CO2` : '-'}
          </Text>
        </Flex>
      </Flex>
    </Box>
  </>
)
