import { useState } from 'react'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Tooltip } from '@proveoeng/uikit/dist/atoms/Tooltip'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

import IconWarning from '../../../common/assets/iconWarning'
import { formatNumber } from '../../../common/functions/comparativeNumbers'

const getRetention = (retentionOffer, retentionCriteriaOffer, retentionBuyer) => {
  const [active, setActive] = useState(false)

  if (retentionOffer) {
    return (
      <Tooltip
        active={active}
        setActive={setActive}
        content={retentionCriteriaOffer ?? 'No hay comentarios'}>
        <Flex>
          <Text color="gray1" textAlign="right" sizeText="display12">
            {formatNumber(retentionOffer / 100)} %
          </Text>
          <Box style={{ marginTop: '-4px', marginRight: '-4px' }}>
            <IconWarning width="24px" />
          </Box>
        </Flex>
      </Tooltip>
    )
  }

  return (
    <Text color="gray1" textAlign="right" sizeText="display12">
      {formatNumber(retentionBuyer / 100)} %
    </Text>
  )
}

export const SummaryRetention = ({ offer, comparative }) => {
  const { retention: retentionOffer, retentionCriteria: retentionCriteriaOffer } = offer
  const { retention: retentionBuyer } = comparative

  if (!retentionOffer && !retentionBuyer)
    return (
      <Text color="gray1" textAlign="right" sizeText="display12">
        0,00 %
      </Text>
    )

  return getRetention(retentionOffer, retentionCriteriaOffer, retentionBuyer)
}
