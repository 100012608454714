import { useEffect } from 'react'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Grid } from '@proveoeng/uikit/dist/atoms/Layout/Layout'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal/Modal'

import { useAttachmentLinks } from '../../../../common/hooks/useAttachmentLinks'
import { LinksList } from '../../../../common/components/links/LinksList'

export const ModalEditLinks = ({ data = [], handleChange, modalIsOpened, setModalIsOpened }) => {
  const {
    links,
    inputLink,
    setInputLink,
    setLinks,
    deleteLinksId,
    setDeleteLinksId,
    addLink,
    handleAddDescription,
    removeLink,
    verifyUrl,
  } = useAttachmentLinks([])

  useEffect(() => {
    setLinks(data)
    setDeleteLinksId([])
  }, [modalIsOpened])

  const onSubmit = () => {
    setModalIsOpened(false)
    handleChange(links, deleteLinksId)
  }

  return (
    <Modal
      isOpen={modalIsOpened}
      id="proposal-items-files"
      onModalClose={() => setModalIsOpened(false)}
      title="Añadir enlaces externos"
      closeWithButton
      maxWidth="808px">
      <Modal.Content>
        <Box margin="0 auto" overflow="auto">
          <Flex flexDirection="column">
            <LinksList
              links={links}
              inputLink={inputLink}
              setInputLink={setInputLink}
              addLink={addLink}
              handleAddDescription={handleAddDescription}
              removeLink={removeLink}
              verifyUrl={verifyUrl}
            />
          </Flex>
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr">
            <Button colorType="orange" action={onSubmit}>
              Guardar
            </Button>
          </Grid>
        </Flex>
      </Modal.Actions>
    </Modal>
  )
}
