import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { AttachmentList } from '../../../../common/components/attachmentList/Attachment'
import { Links } from '../../../../common/components/links/Links'

export const PreviewBidPackageInfo = ({ bidPackage }) => (
  <>
    <Flex paddingBottom="20px">
      <Flex minWidth="264px" paddingLeft={2}>
        <Text as="span" sizeText="display18" color="black" fontWeight="600">
          Descripción
        </Text>
      </Flex>
      <Flex paddingLeft={4}>
        <Text
          as="span"
          sizeText="display14"
          color="black"
          style={{
            whiteSpace: 'pre-wrap',
          }}>
          {bidPackage?.description || '-'}
        </Text>
      </Flex>
    </Flex>
    <Flex paddingBottom="20px">
      <Flex minWidth="264px" paddingLeft={2}>
        <Text as="span" sizeText="display18" color="black" fontWeight="600">
          Fechas de entrega
        </Text>
      </Flex>
      <Flex paddingLeft={4}>
        <Text as="span" sizeText="display14" color="black">
          {bidPackage?.start?.readableReverseDate || 'No informada'} -{' '}
          {bidPackage?.end?.readableReverseDate || 'No informada'}
        </Text>
      </Flex>
    </Flex>
    <Flex paddingBottom="20px">
      <Flex minWidth="264px" paddingLeft={2}>
        <Text as="span" sizeText="display18" color="black" fontWeight="600">
          Retención por garantía
        </Text>
      </Flex>
      <Flex paddingLeft={4}>
        <Text as="span" sizeText="display14" color="black">
          {!bidPackage?.retention ? '-' : `${bidPackage?.retention} %`}
        </Text>
      </Flex>
    </Flex>
    <Flex paddingBottom="20px">
      <Flex minWidth="264px" paddingLeft={2}>
        <Text as="span" sizeText="display18" color="black" fontWeight="600">
          Condiciones de pago
        </Text>
      </Flex>
      <Flex paddingLeft={4}>
        <Text as="span" sizeText="display14" color="black">
          <Text as="span" sizeText="display18" color="black">{`${
            bidPackage?.paymentDays || '-'
          } días`}</Text>{' '}
          - {bidPackage?.paymentConditions || 'Forma de pago no informada'}
        </Text>
      </Flex>
    </Flex>
    <Flex paddingBottom="20px">
      <Flex minWidth="264px" paddingLeft={2}>
        <Text as="span" sizeText="display18" color="black" fontWeight="600">
          Condiciones generales
        </Text>
      </Flex>
      <Flex paddingLeft={4}>
        <Text
          as="span"
          sizeText="display14"
          color="black"
          style={{
            whiteSpace: 'pre-wrap',
          }}>
          {bidPackage?.generalConditions || '-'}
        </Text>
      </Flex>
    </Flex>
    <Flex paddingBottom="20px">
      <Flex minWidth="264px" paddingLeft={2}>
        <Text as="span" sizeText="display18" color="black" fontWeight="600">
          Adjuntos y enlaces
        </Text>
      </Flex>
      <Flex paddingLeft={4}>
        <Text as="span" sizeText="display14" color="black">
          <AttachmentList files={bidPackage?.files} withDownload />
          <Links links={bidPackage?.links} />
        </Text>
      </Flex>
    </Flex>
  </>
)
