import { useEffect } from 'react'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { InputNumber } from '@proveoeng/uikit/dist/atoms/InputNumber'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Textarea } from '@proveoeng/uikit/dist/atoms/Textarea'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Uploader } from '@proveoeng/uikit/dist/atoms/Uploader'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import Close from '@proveoeng/uikit/dist/atoms/Icons/Close'
import { LinksList } from '../../../../../common/components/links/LinksList'
import { SelectUnit } from '../../../../../common/components/SelectUnit'
import { AttachmentList } from '../../../../../common/components/attachmentList/Attachment'

export const CreateBidItem = ({
  setData,
  data,
  errorsData,
  onDeleteFile,
  files,
  onImagesLoad,
  errorsImages,
  setFormCreateBidItemIsOpened,
  title,
  bidPackageId,
  onSubmit,
  createdBidItem,
  links = [],
  inputLink,
  setInputLink,
  addLink,
  handleAddDescription,
  removeLink,
  verifyUrl,
  setCreatedBidItem,
}) => {
  let formData

  useEffect(() => {
    setData(formData)
  }, [])

  return (
    <Box
      position="fixed"
      top={0}
      right={0}
      height="100%"
      minHeight="100vh"
      width="475px"
      boxShadow="topInner"
      backgroundColor="white"
      padding={6}
      overflow="auto">
      <Flex justifyContent="space-between" alignItems="baseline">
        <Text sizeText="display30" fontWeight="bold">
          {title}
        </Text>

        <Flex style={{ cursor: 'pointer' }}>
          <Icon
            sizeIcon="display18"
            color="gray1"
            onClick={() => {
              setFormCreateBidItemIsOpened(false)
            }}>
            <Close />
          </Icon>
        </Flex>
      </Flex>
      <Box
        as="form"
        ref={(form) => {
          formData = form
        }}
        onSubmit={() => {
          onSubmit()
          formData.scrollIntoView()
          formData.reset()
        }}>
        <Box display="none">
          <Input defaultValue={bidPackageId} name="bidPackageId" />
        </Box>

        {createdBidItem ? (
          <>
            <Box style={{ flex: 1 }}>
              <Text sizeText="display16" color="black" marginY={3}>
                Ya hemos creado la partida. Ahora puedes editarla para encontrar los mejores
                precios. Si quieres adjuntar algún documento puedes hacerlo aquí.
              </Text>
              <Field label="Documentos" sizeText="display16" marginBottom={3}>
                <AttachmentList files={files} onDeleteFile={onDeleteFile} withDelete />
                <Uploader
                  multiple
                  name="files"
                  marginBottom={3}
                  errors={errorsImages}
                  maxNumFiles={5}
                  onImagesLoad={onImagesLoad}
                  descriptionMessage="Arrastra, o haz click para subir tus documentos aquí"
                  accept="all"
                  padding={5}
                />
              </Field>
              <Box marginTop={7}>
                <LinksList
                  links={links}
                  inputLink={inputLink}
                  setInputLink={setInputLink}
                  addLink={addLink}
                  handleAddDescription={handleAddDescription}
                  removeLink={removeLink}
                  verifyUrl={verifyUrl}
                />
              </Box>
            </Box>
            <Box position="sticky" bottom={0} width="100%" zIndex={3} bg="white">
              <Button
                action={() => {
                  setFormCreateBidItemIsOpened(false)
                  setCreatedBidItem(false)
                  formData.scrollIntoView()
                }}
                fullWidth
                marginTop={5}
                colorType="transparent">
                Dejar para más tarde
              </Button>
              <Button
                action={() => {
                  onSubmit()
                  setFormCreateBidItemIsOpened(false)
                  setCreatedBidItem(false)
                }}
                colorType="orange"
                fullWidth
                marginTop={5}>
                Guardar documentos
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Field label="Código" sizeText="display16" marginBottom={3}>
              <Input
                placeholderMessage="Dale una referencia a la partida"
                ariaLabel="reference"
                action={() => setData(formData)}
                defaultValue={data?.reference}
                name="reference"
                hasError={errorsData?.reference}
              />
            </Field>
            <Field label="Nombre" required sizeText="display16" marginBottom={3}>
              <Input
                required
                placeholderMessage="Dale un nombre a la partida"
                ariaLabel="name"
                action={() => setData(formData)}
                defaultValue={data?.name}
                name="name"
                minLength={2}
                maxLength={50}
                hasError={errorsData?.name}
              />
            </Field>
            <Field label="Descripción" sizeText="display16" marginBottom={3} marginTop={5}>
              <Textarea
                placeholderMessage="Añade una pequeña descripción de la partida"
                name="description"
                typeAction={() => setData(formData)}
                hasError={errorsData?.description}
                defaultValue={data?.description}
                maxLength="auto"
              />
            </Field>
            <Flex>
              <Box width="50%" paddingRight={1}>
                <Field label="Unidad" required sizeText="display16" marginBottom={3} marginTop={5}>
                  <SelectUnit
                    name="unit"
                    required
                    onChange={() => setData(formData)}
                    defaultValue={data?.unit}
                    hasError={errorsData?.unit}
                    isSearchable
                  />
                </Field>
              </Box>
              <Box width="50%" paddingLeft={1}>
                <Field
                  label="Medición"
                  required
                  sizeText="display16"
                  marginBottom={3}
                  marginTop={5}>
                  <InputNumber
                    required
                    name="measurement"
                    placeholderMessage="1.000,000"
                    ariaLabel="measurement"
                    value={data?.measurement}
                    hasError={errorsData?.measurement}
                    onChange={() => setData(formData)}
                    numeralDecimalScale={3}
                  />
                </Field>
              </Box>
            </Flex>
            <Field
              label="Comentarios sobre la partida para el proveedor"
              sizeText="display16"
              marginBottom={3}
              marginTop={5}>
              <Textarea
                placeholderMessage="Añade comentario de la partida"
                name="comments"
                typeAction={() => setData(formData)}
                hasError={errorsData?.comments}
                defaultValue={data?.comments}
              />
            </Field>
            <Flex>
              <Box width="50%" paddingRight={1}>
                <Field
                  label="Presupuesto de venta"
                  sizeText="display16"
                  marginBottom={3}
                  marginTop={5}>
                  <InputNumber
                    ariaLabel="budget"
                    onChange={() => setData(formData)}
                    value={data?.budget}
                    name="budget"
                    hasError={errorsData?.budget}
                  />
                </Field>
              </Box>
              <Box width="50%" paddingLeft={1}>
                <Field label="Coste previsto" sizeText="display16" marginBottom={3} marginTop={5}>
                  <InputNumber
                    ariaLabel="expectedCosts"
                    onChange={() => setData(formData)}
                    value={data?.expectedCosts}
                    name="expectedCosts"
                    hasError={errorsData?.expectedCosts}
                  />
                </Field>
              </Box>
            </Flex>
            <Button
              action={() => {
                onSubmit()
                formData.scrollIntoView()
              }}
              colorType="orange"
              fullWidth
              marginTop={5}>
              Guardar
            </Button>
          </>
        )}
      </Box>
    </Box>
  )
}
