import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

import { AttachmentList } from '../../../../common/components/attachmentList/Attachment'
import { Items } from '../../../../common/components/item/Items'
import { Links } from '../../../../common/components/links/Links'
import { FormatDate } from '../../../../common/mappers/FormatDate'

import { parseToInteger, parseToString } from '../../../../../lib/utils/Format'

/**
 *
 * @param {{ proposal: Proposal, bidPackage: BidPackage }} props
 */
export const ProposalInfo = ({ proposal, bidPackage }) => {
  if (!proposal?.proposalId) return <></>

  const getPaymentDaysCopy = (paymentDays) => (paymentDays ? `${paymentDays} días` : '-')

  const getPaymentDays = () => {
    if (proposal?.paymentDays === null) {
      return getPaymentDaysCopy(bidPackage?.paymentDays)
    }
    return getPaymentDaysCopy(proposal?.paymentDays)
  }

  const items = [
    {
      title: 'Descripción',
      children: (
        <Text
          as="p"
          sizeText="display14"
          color="black"
          style={{
            whiteSpace: 'pre-wrap',
          }}>
          {bidPackage?.description || '-'}
        </Text>
      ),
    },
    {
      title: 'Fechas de entrega ',
      children: (
        <Box>
          <Box>
            <Text as="span" sizeText="display14" color="black">
              {proposal?.deliveryDates.start
                ? FormatDate.hydrate(proposal?.deliveryDates.start).readableReverseDate
                : 'No informada'}{' '}
              -{' '}
              {proposal?.deliveryDates.end
                ? FormatDate.hydrate(proposal?.deliveryDates.end).readableReverseDate
                : 'No informada'}
            </Text>
          </Box>
          {proposal.deliveryTime && (
            <Box marginTop={4}>
              <Text as="p" sizeText="display16" color="black" fontWeight="600" marginBottom={2}>
                El proveedor ha puesto un plazo de entrega:
              </Text>
              <Text as="p" sizeText="display14" color="black">
                {proposal.deliveryTime}
              </Text>
            </Box>
          )}
        </Box>
      ),
    },
    {
      title: 'Retención',
      children: (
        <Box>
          {/* <Text as="p" sizeText="display18" color="black" marginBottom={4}>
            {parseToInteger(bidPackage?.retention)
              ? `${parseToString(parseToInteger(bidPackage?.retention))}%`
              : '-'}
          </Text>
          <Text as="p" sizeText="display16" color="black" fontWeight="600">
            {proposal.retention === 
              ? 'El proveedor ha aceptado la retención.'
              : 'El proveedor ha solicitado una retención diferente:'}
          </Text> */}
          {proposal.retention !== null ? (
            <Text as="p" sizeText="display18" color="black">
              {parseToString(parseToInteger(proposal.retention))}%
            </Text>
          ) : (
            '-'
          )}
        </Box>
      ),
    },
    {
      title: 'Condiciones de pago',
      children: (
        <Box>
          {/* <Flex alignItems="center" marginBottom={4}>
            {!bidPackage?.paymentDays ? (
              <Text as="span" sizeText="display14" color="black">
                No informado
              </Text>
            ) : (
              <Text as="span" sizeText="display18" color="black">
                {bidPackage?.paymentDays} días
              </Text>
            )}
            <Text as="span" sizeText="display14" color="black" paddingX={2}>
              -
            </Text>
            <Text as="span" sizeText="display14" color="black">
              {bidPackage?.paymentConditions || 'Forma de pago no informada'}
            </Text>
          </Flex>
          <Flex>
            <Text as="span" sizeText="display16" color="black" fontWeight="600">
              {!proposal.paymentDays
                ? 'El proveedor ha aceptado las condiciones de pago.'
                : 'El proveedor ha solicitado condiciones de pago diferentes:'}
            </Text>
          </Flex> */}
          <Flex alignItems="center" marginBottom={4}>
            <Text as="span" sizeText="display18" color="black">
              {getPaymentDays()}
            </Text>
            <Text as="span" sizeText="display14" color="black" paddingX={2}>
              -
            </Text>
            <Text as="span" sizeText="display14" color="black">
              {proposal.paymentConditions || 'Forma de pago no informada'}
            </Text>
          </Flex>
        </Box>
      ),
    },
    {
      title: 'Información adicional de la compra',
      children: (
        <Box>
          {!!bidPackage.links?.length || !!bidPackage.files?.length ? (
            <>
              <AttachmentList files={bidPackage.files} withDownload />
              <Links links={bidPackage.links} />
            </>
          ) : (
            '-'
          )}
        </Box>
      ),
    },
    {
      title: 'Condiciones generales',
      children: (
        <Text
          as="p"
          sizeText="display14"
          color="black"
          style={{
            whiteSpace: 'pre-wrap',
          }}>
          {bidPackage.generalConditions || '-'}
        </Text>
      ),
    },
    {
      title: 'Comentarios generales del proveedor',
      children: (
        <Text
          as="p"
          sizeText="display14"
          color="black"
          style={{
            whiteSpace: 'pre-wrap',
          }}>
          {proposal?.comments || '-'}
        </Text>
      ),
    },
    {
      title: 'Documentación adicional',
      children: (
        <Box>
          {!!proposal.links?.length || !!proposal.files?.length ? (
            <>
              <AttachmentList files={proposal?.files} withDownload />
              <Links links={proposal?.links} />
            </>
          ) : (
            '-'
          )}
        </Box>
      ),
    },
  ]
  return (
    <>
      <Items items={items} />
    </>
  )
}
