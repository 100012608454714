import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useCompany } from '../../../../common/hooks/useCompany'
import { useEmployee } from '../../../../common/hooks/useEmployee'
import { useHelpers } from '../../../../common/hooks/useHelpers'
import { ProfileTemplate } from '../template/ProfileSellerTemplate'

export const ProfileSellerPage = () => {
  const { section } = useParams()
  const employee = useSelector((state) => state.employee?.data)
  const { companyId } = useSelector((state) => state.auth?.data)

  const [whoIsActive, setWhoIsActive] = useState(section || 'user-data')
  const [profileImage, setProfileImage] = useState({
    name: '',
    base64: '',
    file: null,
  })
  const [logoImage, setLogoImage] = useState({
    name: '',
    base64: '',
    file: null,
  })

  const {
    data: {
      categories: { data: categories },
    },
    getCategories,
  } = useHelpers()

  const {
    data: { company },
    getCompany,
    setCompanyGeneral,
    setCompanyBilling,
    setCompanyActivity,
    setCompanyCategories,
    setCompanyLogo,
  } = useCompany()

  const { setEmployee, setEmployeeAvatar } = useEmployee()

  useEffect(() => {
    if (companyId) getCompany(companyId)
  }, [companyId])

  useEffect(() => {
    if (employee?.avatar) setProfileImage({ base64: employee?.avatar })
  }, [employee?.avatar])

  useEffect(() => {
    if (company?.logo) setLogoImage({ base64: company?.logo })
  }, [company?.logo])

  const openTabCategory = () => {
    if (!categories.length) getCategories()
    setWhoIsActive('categories')
  }
  const tabs = [
    {
      id: 'user-data',
      children: <>Tus datos</>,
      isActive: whoIsActive === 'user-data',
      action: (e, target) => setWhoIsActive(target.id),
    },

    {
      id: 'company-data',
      children: <>Datos de compañia</>,
      isActive: whoIsActive === 'company-data',
      action: (e, target) => setWhoIsActive(target.id),
    },
    {
      id: 'profile-activity',
      children: <>Perfil y actividad</>,
      isActive: whoIsActive === 'profile-activity',
      action: (e, target) => setWhoIsActive(target.id),
    },
    ...(employee?.type !== 'Seller'
      ? []
      : [
          {
            id: 'categories',
            children: <>Categorías</>,
            isActive: whoIsActive === 'categories',
            action: openTabCategory,
          },
        ]),
  ]

  const handleSubmitEmployee = async (values) => {
    await setEmployee(values)
    if (profileImage.file) {
      await setEmployeeAvatar({ file: profileImage.file })
    }
  }

  const handleSubmitCompany = async (values) => {
    await setCompanyGeneral(companyId, values)

    const billingBody = values.isSameBillingData
      ? values
      : {
          isSameBillingData: values.isSameBillingData,
          taxCode: values.taxCode,
          name: values.billingName,
          address: values.billingAddress,
          city: values.billingCity,
          postCode: values.billingPostCode,
        }

    await setCompanyBilling(companyId, billingBody)
    await getCompany(companyId)
  }

  const handleSubmitCompanyActivity = async (values) => {
    await setCompanyActivity(companyId, values)
    if (logoImage.file) {
      await setCompanyLogo(companyId, { file: logoImage.file })
    }
    await getCompany(companyId)
  }

  const handleSubmitCompanyCategories = async (values) => {
    await setCompanyCategories(companyId, values)
    await getCompany(companyId)
  }

  const onProfileImageChange = (imgs) => setProfileImage(imgs[0])

  const onLogoImageChange = (imgs) => setLogoImage(imgs[0])

  return (
    <ProfileTemplate
      company={company}
      employee={employee}
      tabs={tabs}
      activetab={whoIsActive}
      categories={categories}
      handleSubmitEmployee={handleSubmitEmployee}
      handleSubmitCompany={handleSubmitCompany}
      handleSubmitCompanyActivity={handleSubmitCompanyActivity}
      handleSubmitCompanyCategories={handleSubmitCompanyCategories}
      profileImage={profileImage}
      logoImage={logoImage}
      onProfileImageChange={onProfileImageChange}
      onLogoImageChange={onLogoImageChange}
    />
  )
}
