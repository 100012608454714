import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Box, Flex, GridItem } from '@proveoeng/uikit/dist/atoms/Layout/Layout'
import { ActionMenu } from '@proveoeng/uikit/dist/molecules/ActionsMenu/ActionMenu'
import { Avatar } from '@proveoeng/uikit/dist/atoms/Avatar/Avatar'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { SelectInputMultiple } from '@proveoeng/uikit/dist/atoms/SelectMultiple'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Search } from '@proveoeng/uikit/dist/atoms/Icons'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'

import { CreateEmployee } from './CreateEmployee'
import { EditEmployee } from './EditEmployee'
import { DeleteEmployee } from './DeleteEmployee'
import { useSearchOrderParams } from '../../../../common/hooks/useSearchOrderParams'
import { useForms } from '../../../../common/hooks/forms'
import { useManageEmployees } from '../../../../common/hooks/useManageEmployees'

export const copyEmployeeRole = {
  Super: 'Administrador',
  Supervisor: 'Colaborador',
  Standard: 'Empleado',
}

export const copyEmployeeStatus = {
  Active: 'Activo',
  Fired: 'Desactivado',
}

export const colorEmployeeStatus = {
  Active: 'green1',
  Fired: 'red1',
}

export const ManageEmployees = () => {
  const [formCreateEmployee, setFormCreateEmployee] = useState(false)
  const [formEditEmployee, setFormEditEmployee] = useState({ show: false, data: {} })
  const [formDeleteEmployee, setFormDeleteEmployee] = useState({
    show: false,
    name: null,
    id: null,
  })
  const { values, handleChange } = useForms()
  const [errorsData, setErrorsData] = useState({ init: true })
  const [currentEmployee, setCurrentEmployee] = useState()

  const {
    employees,
    getEmployees,
    createEmployee,
    deleteEmployee,
    updateExistingEmployee,
    actionEmployee,
  } = useManageEmployees()

  const { filters, setFilters, getOptionStates } = useSearchOrderParams({
    copyEmployeeRole,
    defaultOption: 0,
  })
  const [optionsFilter, setOptionsFilter] = useState([])
  const [filteredEmployees, setFilteredEmployees] = useState([])
  const [employeesToShow, setEmployeesToShow] = useState([])

  const [reset, setReset] = useState(false)

  useEffect(() => {
    getEmployees()
  }, [])

  useEffect(() => {
    if (!filters.length && employees.length) {
      const roles = employees.map(({ role }) => role)
      setOptionsFilter(getOptionStates(roles, 'employeeRole'))
      setFilteredEmployees(employees)
      setEmployeesToShow(employees)
    }
  }, [employees])

  useEffect(() => {
    if (!filters.length) {
      setFilteredEmployees(employees)
      setEmployeesToShow(employees)
    } else {
      const filteredEmployeesList = []
      employees.forEach((emp) =>
        filters.forEach((opt) => {
          if (opt.label.includes(copyEmployeeRole[emp?.role])) filteredEmployeesList.push(emp)
        }),
      )
      setFilteredEmployees(filteredEmployeesList)
      setEmployeesToShow(filteredEmployeesList)
      setReset(true)
    }
  }, [filters])

  const handleData = (value) => {
    if (!value) {
      setEmployeesToShow(filteredEmployees)
    }

    const newFilteredEmployees = filteredEmployees.filter(
      (emp) =>
        emp?.name?.toUpperCase().includes(value.toUpperCase()) ||
        emp?.email?.toUpperCase().includes(value.toUpperCase()),
    )
    // setFilteredEmployees(newFilteredEmployees);
    setEmployeesToShow(newFilteredEmployees)
  }

  const onValidate = (fieldsToValidate = []) => {
    const formValidation = fieldsToValidate
    if (errorsData.init) {
      setErrorsData({})
    }
    let errors = {}

    formValidation.map((key) => {
      if (values[key]?.length || values[key] === true) {
        setErrorsData((prev) => ({
          ...prev,
          [key]: false,
        }))
        errors = {
          ...errors,
          [key]: false,
        }
      } else {
        setErrorsData((prev) => ({
          ...prev,
          [key]: true,
        }))

        errors = {
          ...errors,
          [key]: true,
        }
      }
      return false
    })

    if (!Object.values(errors)?.includes(true)) {
      return true
    }

    return false
  }

  const createNewEmployee = async () => {
    const fieldsToValidate = ['name', 'email', 'phone', 'position', 'role']
    if (onValidate(fieldsToValidate)) {
      const body = {
        employeeData: values,
      }

      const res = await createEmployee(uuidv4(), body)

      if (res?.data === 'Created') {
        setFormCreateEmployee(false)
        getEmployees()
        setReset(true)
      }
    }
  }

  const deleteExistingEmployee = async (id = null) => {
    const employeeId = id ?? formDeleteEmployee?.id
    const res = await deleteEmployee(employeeId)

    if (res?.status === 204) {
      setFormEditEmployee({ show: false, data: {} })
      setFormDeleteEmployee({ show: false, name: null, id: null })
      getEmployees()
    }
  }

  const rehireExistingEmployee = async (id = null) => {
    const employeeId = id ?? formDeleteEmployee?.id
    const body = {
      actionName: 'Rehire',
      actionData: {},
    }
    const res = await actionEmployee(employeeId, body)

    if (res?.status === 200) {
      setFormEditEmployee({ show: false, data: {} })
      getEmployees()
      setReset(true)
    }
  }

  const editEmployee = (show, employee = {}) => {
    if (show) {
      setCurrentEmployee(employee)
      setFormEditEmployee({ show: true, data: employee })
    } else {
      setFormEditEmployee({ show: false, data: {} })
    }
  }

  const editExistingEmployeeAPI = async (employeeId, profileBody, roleBody) => {
    await updateExistingEmployee(employeeId, profileBody)
    await actionEmployee(employeeId, roleBody)
  }

  const editExistingEmployee = async (employeeId) => {
    const fieldsToValidate = ['name', 'phone', 'position']
    if (onValidate(fieldsToValidate)) {
      const profileBody = {
        name: values.name,
        phone: values.phone,
        position: values.position,
      }

      const roleBody = {
        actionName: 'ChangeRole',
        actionData: {
          role: values.role,
        },
      }

      editExistingEmployeeAPI(employeeId, profileBody, roleBody).then(() => {
        editEmployee(false)
        getEmployees()
        setReset(true)
      })
    }
  }

  useEffect(() => {
    setCurrentEmployee(values)
  }, [values])

  useEffect(() => {
    if (!formCreateEmployee && !formEditEmployee.show && !formDeleteEmployee.show) {
      setErrorsData({})
      handleChange(false)
    }
  }, [formCreateEmployee, formEditEmployee, formDeleteEmployee])

  const Filter = () => (
    <>
      <Box width="100%">
        <SelectInputMultiple
          options={optionsFilter}
          id="filter"
          label="Tipo de usuario"
          onChange={(_, a) => setTimeout(() => setFilters(a), 0)}
          defaultValue={filters}
        />
      </Box>
    </>
  )

  return (
    <>
      <Flex justifyContent="space-between" marginTop={4}>
        <Flex>
          {/** Search */}
          <Box paddingRight={3} maxWidth="360px">
            <Input
              action={(_, value) => handleData(value)}
              type="search"
              name="searchBar"
              title=""
              reset={reset}
              placeholderMessage="Busca un empleado"
              iconLeft={
                <Icon sizeIcon="display18" color="gray3">
                  <Search />
                </Icon>
              }
            />
          </Box>
          <Filter />
        </Flex>
        <Box width="168px">
          <Button
            sizeButton="medium"
            colorType="transparent"
            action={() => setFormCreateEmployee(true)}
            width="100%">
            Añadir empleado
          </Button>
        </Box>
      </Flex>
      <Flex paddingBottom={5}>
        <Box marginTop={6} width="100%">
          <Grid
            gridTemplateAreas={"'empty avatar name email phone position typeOfUser status actions'"}
            gridTemplateColumns="24px 48px 24% 24% 10% 10% 11% 10% 40px"
            bg="gray4"
            paddingY={4}
            borderRadius={2}>
            <GridItem gridArea="empty" />
            <GridItem gridArea="avatar" />
            <GridItem gridArea="name">
              <Text
                sizeText="display14"
                color="gray1"
                overflow="hidden"
                fontWeight="medium"
                marginLeft={5}>
                Nombre completo
              </Text>
            </GridItem>
            <GridItem gridArea="email">
              <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
                Email
              </Text>
            </GridItem>
            <GridItem gridArea="phone">
              <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
                Teléfono
              </Text>
            </GridItem>
            <GridItem gridArea="position">
              <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
                Cargo
              </Text>
            </GridItem>
            <GridItem gridArea="typeOfUser">
              <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
                Tipo de usuario
              </Text>
            </GridItem>
            <GridItem gridArea="status">
              <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
                Estado
              </Text>
            </GridItem>
            <GridItem gridArea="actions" />
          </Grid>
          {/* Aquí iría el map */}
          {employeesToShow?.map((employee) => (
            <Grid
              key={employee?.employeeId}
              gridTemplateAreas={
                "'empty avatar name email phone position typeOfUser status actions'"
              }
              gridTemplateColumns="24px 48px 24% 24% 10% 10% 11% 10% 40px"
              paddingY={4}
              borderBottomColor="gray4"
              borderBottomStyle="solid"
              borderBottomWidth="1px">
              <GridItem gridArea="empty" />
              <GridItem gridArea="avatar">
                <Avatar
                  // src={employee?.avatar}
                  src={null}
                  borderRadius={3}
                  marginRight={3}
                  placeholder={employee?.name}
                  style={{ alignItems: ' center', cursor: 'pointer' }}
                  onClick={() => editEmployee(true, employee)}
                />
              </GridItem>
              <GridItem
                gridArea="name"
                display="flex"
                style={{ alignItems: ' center', cursor: 'pointer' }}
                onClick={() => editEmployee(true, employee)}
                marginLeft={5}>
                <Text
                  sizeText="display14"
                  color="gray1"
                  overflow="hidden"
                  fontWeight="medium"
                  paddingRight={15}>
                  {employee?.name}
                </Text>
              </GridItem>
              <GridItem gridArea="email" display="flex" style={{ alignItems: ' center' }}>
                <Text sizeText="display14" color="gray1">
                  {employee?.email}
                </Text>
              </GridItem>
              <GridItem gridArea="phone" display="flex" style={{ alignItems: ' center' }}>
                <Text sizeText="display14" color="gray1">
                  {employee?.phone}
                </Text>
              </GridItem>
              <GridItem gridArea="position" display="flex" style={{ alignItems: ' center' }}>
                <Text sizeText="display14" color="gray1">
                  {employee?.position}
                </Text>
              </GridItem>
              <GridItem gridArea="typeOfUser" display="flex" style={{ alignItems: 'center' }}>
                <Text as="span" sizeText="display14" marginLeft={1} color="gray1">
                  {copyEmployeeRole[employee?.employeeRole]}
                </Text>
              </GridItem>
              <GridItem gridArea="status" display="flex" style={{ alignItems: 'center' }}>
                <Flex justifyContent="center" alignItems="center">
                  <Box
                    backgroundColor={colorEmployeeStatus[employee?.state]}
                    width="8px"
                    height="8px"
                    borderRadius={8}
                    marginX={1}
                    minWidth="8px"
                  />
                  <Text as="span" marginLeft={1} color="gray1">
                    {copyEmployeeStatus[employee?.state]}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem gridArea="actions" display="flex" style={{ alignItems: ' center' }}>
                <ActionMenu
                  actions={[
                    {
                      id: 'edit',
                      content: (
                        <Text sizeText="display14" color="black" paddingTop={4}>
                          Editar
                        </Text>
                      ),
                      action: () => {
                        editEmployee(true, employee)
                      },
                    },
                    {
                      id: 'disable',
                      isDisabled: employee?.state === 'Fired' || employee?.employeeRole === 'Super',
                      content: (
                        <Text sizeText="display14" color="red1" paddingY={4}>
                          Desactivar
                        </Text>
                      ),
                      action: () => {
                        setFormDeleteEmployee({
                          show: true,
                          name: employee?.name,
                          id: employee?.employeeId,
                        })
                      },
                    },
                  ]}
                />
              </GridItem>
            </Grid>
          ))}
        </Box>
        <CreateEmployee
          data={values}
          setData={handleChange}
          errorsData={errorsData}
          formCreateEmployee={formCreateEmployee}
          setFormCreateEmployee={setFormCreateEmployee}
          createNewEmployee={createNewEmployee}
        />
        <EditEmployee
          data={values}
          setData={handleChange}
          errorsData={errorsData}
          formEditEmployee={formEditEmployee}
          setFormEditEmployee={setFormEditEmployee}
          editExistingEmployee={editExistingEmployee}
          currentEmployee={currentEmployee}
          setCurrentEmployee={setCurrentEmployee}
          deleteExistingEmployee={deleteExistingEmployee}
          rehireExistingEmployee={rehireExistingEmployee}
        />
        <DeleteEmployee
          isOpen={formDeleteEmployee.show}
          employeeName={formDeleteEmployee.name}
          setFormDeleteEmployee={setFormDeleteEmployee}
          deleteExistingEmployee={deleteExistingEmployee}
        />
      </Flex>
    </>
  )
}
