import { useState } from 'react'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Textarea } from '@proveoeng/uikit/dist/atoms/Textarea'
import { InputNumber } from '@proveoeng/uikit/dist/atoms/InputNumber'

import { useForms } from '../../../common/hooks/forms'
import { parseToInteger } from '../../../../lib/utils/Format'
import { SelectUnit } from '../../../common/components/SelectUnit'

let formData
export const AddItemModal = ({ addBidItem, isOpen, onClose }) => {
  const { values, handleChange, resetValues } = useForms()
  const [errorsData, setErrorsData] = useState({ init: true })

  const onValidate = () => {
    const formValidation = ['description', 'unit', 'measure']
    if (errorsData.init) {
      setErrorsData({})
    }

    let errors = {}

    formValidation.map((key) => {
      if (values[key]?.length || values[key] === true) {
        setErrorsData((prev) => ({
          ...prev,
          [key]: false,
        }))
        errors = {
          ...errors,
          [key]: false,
        }
      } else {
        setErrorsData((prev) => ({
          ...prev,
          [key]: true,
        }))

        errors = {
          ...errors,
          [key]: true,
        }
      }
      return false
    })

    if (!Object.values(errors)?.includes(true)) {
      resetValues()
      return true
    }

    return false
  }

  const onClear = () => {
    onClose()
    setErrorsData({})
  }

  const handleSubmit = () => {
    if (onValidate()) {
      values.measure = parseToInteger(values.measure, 3)

      addBidItem(values)
      onClear()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      id="add-bidItem-modal"
      onModalClose={onClear}
      closeWithButton
      title="Añadir partida"
      maxWidth="570px">
      <Modal.Content overflow="visible">
        <form
          ref={(form) => {
            formData = form
          }}
          onSubmit={handleSubmit}>
          <Flex flexDirection="column">
            <Flex width="100%" flexDirection="column">
              <Flex width="100%" marginRight={2}>
                <Flex flexDirection="column" width="100%">
                  <Field
                    label="Descripción de la partida"
                    required
                    sizeText="display16"
                    marginBottom={4}
                    marginTop={0}>
                    <Textarea
                      required
                      placeholderMessage="Escribe la descripción aquí"
                      typeAction={() => handleChange(formData)}
                      name="description"
                      hasError={errorsData?.description}
                      maxLength="auto"
                    />
                  </Field>
                </Flex>
              </Flex>
              <Flex>
                <Box width="50%" marginRight={5}>
                  <Field
                    label="Unidad"
                    required
                    sizeText="display16"
                    marginBottom={3}
                    marginTop={5}>
                    <SelectUnit
                      isSearchable
                      name="unit"
                      required
                      placeholderMessage="m2, m3, unidad"
                      onChange={() => handleChange(formData)}
                      hasError={errorsData?.unit}
                    />
                  </Field>
                </Box>

                <Box width="50%">
                  <Field
                    label="Medición"
                    required
                    sizeText="display16"
                    marginBottom={3}
                    marginTop={5}>
                    <InputNumber
                      required
                      name="measure"
                      placeholderMessage="1.000,000"
                      hasError={errorsData?.measure}
                      onChange={() => handleChange(formData)}
                      numeralDecimalScale={3}
                    />
                  </Field>
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </form>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="center">
          <Flex width="100%">
            <Button
              id="cancel-button"
              fullWidth
              marginRight={5}
              colorType="transparent"
              action={onClear}
              type="button">
              Cancelar
            </Button>
            <Button fullWidth colorType="orange" action={handleSubmit} type="submit">
              Crear nueva partida
            </Button>
          </Flex>
        </Flex>
      </Modal.Actions>
    </Modal>
  )
}
