import { constants } from '../config'

const initialState = {
  done: false,
  loading: false,
  errorRequest: false,
  error: null,
  data: {},
}

export default function setCompanyReducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_COMPANY:
      return { ...state, loading: true, done: false, errorRequest: false, error: null }
    case constants.GET_COMPANY_SUCCESS: {
      return {
        ...state,
        loading: false,
        done: true,
        data: {
          ...state.data,
          ...action.payload,
        },
      }
    }
    case constants.SET_STORE_COMPANY: {
      return {
        ...state,
        data: {
          ...action.payload,
        },
      }
    }
    case constants.COMPANY_FAIL:
      return { ...state, loading: false, errorRequest: true, error: { ...action.error } }

    default:
      return state
  }
}
