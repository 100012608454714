import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

import { AttachmentList } from '../../../../common/components/attachmentList/Attachment'
import { Links } from '../../../../common/components/links/Links'

export const PreviewBidPackageConditions = ({ data }) => (
  <>
    <Flex paddingBottom="20px">
      <Flex minWidth="264px" paddingLeft={2}>
        <Text as="span" sizeText="display18" color="black" fontWeight="600">
          Descripción
        </Text>
      </Flex>
      <Flex paddingLeft={4}>
        <Text
          as="span"
          sizeText="display14"
          color="black"
          style={{
            whiteSpace: 'pre-wrap',
          }}>
          {data?.bidPackage?.description || '-'}
        </Text>
      </Flex>
    </Flex>
    <Flex paddingBottom="20px">
      <Flex minWidth="264px" paddingLeft={2}>
        <Text as="span" sizeText="display18" color="black" fontWeight="600">
          Fechas de entrega
        </Text>
      </Flex>
      <Flex paddingLeft={4}>
        <Text as="span" sizeText="display14" color="black">
          {data?.bidPackage?.start?.readableReverseDate || 'No informada'} -{' '}
          {data?.bidPackage?.end?.readableReverseDate || 'No informada'}
        </Text>
      </Flex>
    </Flex>
    <Flex paddingBottom="20px">
      <Flex minWidth="264px" paddingLeft={2}>
        <Text as="span" sizeText="display18" color="black" fontWeight="600">
          Retención por garantía
        </Text>
      </Flex>
      <Flex paddingLeft={4}>
        <Text as="span" sizeText="display14" color="black">
          {data?.bidPackage?.retention === null ? '-' : `${data?.bidPackage?.retention} %`}
        </Text>
      </Flex>
    </Flex>
    <Flex paddingBottom="20px">
      <Flex minWidth="264px" paddingLeft={2}>
        <Text as="span" sizeText="display18" color="black" fontWeight="600">
          Condiciones de pago
        </Text>
      </Flex>
      <Flex paddingLeft={4}>
        <Text as="span" sizeText="display14" color="black">
          <Text as="span" sizeText="display18" color="black">{`${
            data?.bidPackage?.paymentDays || '-'
          } días`}</Text>{' '}
          - {data?.bidPackage?.paymentConditions || 'Forma de pago no informada'}
        </Text>
      </Flex>
    </Flex>
    <Flex paddingBottom="20px">
      <Flex minWidth="264px" paddingLeft={2}>
        <Text as="span" sizeText="display18" color="black" fontWeight="600">
          Condiciones generales
        </Text>
      </Flex>
      <Flex paddingLeft={4}>
        <Text
          as="span"
          sizeText="display14"
          color="black"
          style={{
            whiteSpace: 'pre-wrap',
          }}>
          {data?.bidPackage?.generalConditions || '-'}
        </Text>
      </Flex>
    </Flex>
    <Flex paddingBottom="20px">
      <Flex minWidth="264px" paddingLeft={2}>
        <Text as="span" sizeText="display18" color="black" fontWeight="600">
          Adjuntos y enlaces
        </Text>
      </Flex>
      <Flex paddingLeft={4}>
        <Text as="span" sizeText="display14" color="black">
          <AttachmentList files={data?.bidPackage?.files} withDownload />
          <Links links={data?.bidPackage?.links} />
        </Text>
      </Flex>
    </Flex>
  </>
)
