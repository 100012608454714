/* eslint-disable react/no-unescaped-entities */
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Grid } from '@proveoeng/uikit/dist/atoms/Layout/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal/Modal'

export const ModalContact = ({ isModalContactOpened, setIsModalContactOpened, data, title }) => (
  <Modal
    isOpen={isModalContactOpened}
    id="proposal-items-files"
    onModalClose={() => setIsModalContactOpened(false)}
    title={title || 'Detalles del contratista'}
    closeWithButton
    maxWidth="570px">
    <Modal.Content>
      <Flex paddingBottom={4}>
        <Flex minWidth="264px">
          <Text as="span" sizeText="display16" color="gray" fontWeight="600" paddingRight={2}>
            Nombre de la empresa:
          </Text>
          <Text as="span" sizeText="display16" fontWeight="medium" color="black">
            {data?.companyName}
          </Text>
        </Flex>
      </Flex>
      <Flex paddingBottom={4}>
        <Flex minWidth="264px">
          <Text as="span" sizeText="display16" color="gray" fontWeight="600" paddingRight={2}>
            Nombre del empleado:
          </Text>
          <Text as="span" sizeText="display16" fontWeight="medium" color="black">
            {data?.employeeName}
          </Text>
        </Flex>
      </Flex>
      <Flex paddingBottom={4}>
        <Flex minWidth="264px">
          <Text as="span" sizeText="display16" color="gray" fontWeight="600" paddingRight={2}>
            Cargo:
          </Text>
          <Text as="span" sizeText="display16" fontWeight="medium" color="black">
            {data?.employeePosition}
          </Text>
        </Flex>
      </Flex>
      <Flex paddingBottom={4}>
        <Flex minWidth="264px">
          <Text as="span" sizeText="display16" color="gray" fontWeight="600" paddingRight={2}>
            Teléfono:
          </Text>
          <Text as="span" sizeText="display16" fontWeight="medium" color="black">
            {data?.employeePhone}
          </Text>
        </Flex>
      </Flex>
      <Flex paddingBottom={4}>
        <Flex minWidth="264px">
          <Text as="span" sizeText="display16" color="gray" fontWeight="600" paddingRight={2}>
            Dirección de correo:
          </Text>
          <Text as="span" sizeText="display16" fontWeight="medium" color="black">
            {data?.employeeEmail}
          </Text>
        </Flex>
      </Flex>
    </Modal.Content>
    <Modal.Actions>
      <Flex justifyContent="flex-end">
        <Grid gridTemplateColumns="184px" gridTemplateRows="1fr">
          <Button
            colorType="transparent"
            fullWidth
            width="184px"
            action={() => setIsModalContactOpened(false)}>
            Hecho
          </Button>
        </Grid>
      </Flex>
    </Modal.Actions>
  </Modal>
)
