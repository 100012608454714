import { parseToInteger, parseToString } from '../../../../../lib/utils/Format'
import { FormatDate } from '../../../mappers/FormatDate'

const valueToLabel = {
  Execution: 'Ejecución',
  Public: 'Público',
  Private: 'Privado',
  Study: 'Estudio',
}

export const ProjectMapper = {
  hydrateMeta: (meta) => {
    let metaData = {}
    if (meta) {
      metaData = { size: meta?.size || 0, page: meta?.page || 1, total: meta?.total || 1 }
    }
    return metaData
  },
  hydrate: (data) => ({
    projectId: data?.projectId,
    employeeId: data?.employeeId,
    name: data?.name,
    reference: data?.reference || null,
    address: data?.address?.address,
    postCode: data?.address?.postCode,
    city: data?.address?.city,
    start: data?.projectDates?.start,
    province: data?.address?.province,
    startFormatted: FormatDate.hydrate(data?.projectDates?.start),
    end: data?.projectDates?.end,
    endFormatted: FormatDate.hydrate(data?.projectDates?.end),
    customer: data?.customer || null,
    constructionType: data?.constructionType,
    constructionTypeFormatted: valueToLabel[data?.constructionType],
    projectType: data?.projectType,
    projectTypeFormatted: valueToLabel[data?.projectType],
    description: data?.description || null,
    budget: parseToString(data?.budget || 0),
    expectedCosts: parseToString(data?.expectedCosts || 0),
    targetCosts: parseToString(data?.targetCosts || 0),
    bidPackages: data?.bidPackages,
    image: data?.image,
    state: data?.state || null,
    files: data?.files || [],
    links: data?.links || [],
    kZero: data?.kZero ? parseToString(data?.kZero) : null,
  }),
  dehydrate: (data) => ({
    employeeId: data?.employeeId,
    name: data?.name,
    reference: data?.reference || null,
    address: {
      address: data?.address,
      postCode: data?.postCode,
      city: data?.city,
      province: data?.province,
    },
    projectDates: {
      start: data?.start,
      end: data?.end,
    },
    customer: data?.customer || null,
    constructionType: data?.constructionType,
    projectType: data?.projectType,
    description: data?.description || null,
    budget: parseToInteger(data?.budget) || 0,
    expectedCosts: parseToInteger(data?.expectedCosts) || 0,
    targetCosts: parseToInteger(data?.targetCosts) || 0,
    kZero: parseToInteger(data?.kZero) || null,
  }),
}
