import { useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { useHistory } from 'react-router-dom'
import { requestApi } from '../../../lib/request'
import { employeeFails, resetOpenedOffer } from '../schemes/employees'
import { services as servicesProposals } from '../schemes/proposals/config'
import { services as servicesBidPackages } from '../schemes/bidPackages/config'
import { useUtils } from './useUtils'
import {
  resetProposal,
  createAttachmentProposalSuccess,
  createAttachmentProposalItemSuccess,
  proposalsFails,
  manageProposalItemsFilesSuccess,
  manageProposalFilesSuccess,
  deleteAttachmentProposalSuccess,
  deleteAttachmentProposalItemSuccess,
  manageProposalLinkSuccess,
  manageProposalItemsLinkSuccess,
} from '../schemes/proposals'

import {
  actionBidPackageOfferSuccess,
  bidPackageFails,
  getBidPackageOfferSuccess,
} from '../schemes/bidPackages'
import { BidPackageMapper } from '../schemes/bidPackages/mapper'
import { ProjectMapper } from '../schemes/projects/mapper'

export const useSeller = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { setError, setLoading, cancelRequest, getSignal } = useUtils()

  const getBidPackageOffer = async (bidPackageId, offerId) => {
    setLoading(true)
    try {
      const response = await requestApi(
        servicesBidPackages.getBidPackageOffer(bidPackageId, offerId),
        { signal: getSignal() },
      )
      const {
        data: { bidPackage, project, ...rest },
      } = response?.data
      bidPackage.bidPackageId = bidPackageId
      dispatch(
        getBidPackageOfferSuccess({
          ...rest,
          bidPackage: BidPackageMapper.hydrate(bidPackage),
          ...ProjectMapper.hydrate(project),
        }),
      )

      setLoading(false)
    } catch (error) {
      if (error?.message === 'canceled') return
      dispatch(employeeFails(error))
      setError(error)
      setLoading(false)
    }
  }

  const actionBidPackageOffer = async (bidPackageId, offerId, employeeId, actionText) => {
    setLoading(true)
    try {
      let body = {}
      const proposalId = uuidv4()
      if (actionText === 'Accept') {
        body = {
          actionData: {
            employeeId,
            proposalId,
          },
        }
      }
      if (actionText === 'Reject') {
        body = {
          actionData: {
            employeeId,
          },
        }
      }

      if (actionText === 'Cancel') {
        body = {
          actionData: {
            employeeId,
          },
        }
      }
      await requestApi(
        servicesBidPackages.actionBidPackageOffer(bidPackageId, offerId, {
          actionName: actionText,
          ...body,
        }),
        {
          signal: getSignal(),
        },
      )
      dispatch(actionBidPackageOfferSuccess())
      if (actionText === 'Accept') {
        await getBidPackageOffer(bidPackageId, offerId)
        history.push(`/proveedor/tus-aplicaciones/${proposalId}/editar`)
      }
      // TODO:
      // getPendingOffers();

      setLoading(false)
    } catch (error) {
      if (error?.message === 'canceled') return
      dispatch(bidPackageFails(error))
      setError(error)
      setLoading(false)
    }
  }

  const doSyncFilesProposalRequest = async ({ proposalId, file }) => {
    const fileId = uuidv4()
    try {
      await requestApi(
        servicesProposals.createAttachmentProposal(
          proposalId,
          fileId,
          {
            file: file.file,
          },
          {
            signal: getSignal(),
          },
        ),
      )

      dispatch(
        createAttachmentProposalSuccess({
          type: file.type,
          base64: file.base64,
          name: file.name,
          fileId,
        }),
      )

      setLoading(false)
    } catch (error) {
      if (error?.message === 'canceled') return
      dispatch(employeeFails(error))
      setError(error)
      setLoading(false)
    }
  }

  const doSyncFilesProposalItemRequest = async ({ proposalId, proposalItemId, file }) => {
    const fileId = uuidv4()
    try {
      await requestApi(
        servicesProposals.createAttachmentItemProposal(
          proposalId,
          proposalItemId,
          fileId,
          {
            file: file.file,
          },
          {
            signal: getSignal(),
          },
        ),
      )
      dispatch(
        createAttachmentProposalItemSuccess({
          type: file.type,
          base64: file.base64,
          name: file.name,
          fileId,
        }),
      )
    } catch (error) {
      if (error?.message === 'canceled') return
      dispatch(proposalsFails(error))
      setError(error)
      setLoading(false)
    }
  }

  const setFilesProposal = ({ proposalId, files }) => {
    if (files?.length) {
      setLoading(true)
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i]
        if (!file?.fileId) {
          doSyncFilesProposalRequest({ proposalId, file })
        }
      }

      dispatch(manageProposalFilesSuccess(true))
      setLoading(false)
    }
  }

  const setFilesProposalItem = async ({ proposalId, proposalItemId, files }) => {
    if (files?.length) {
      setLoading(true)
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i]
        if (!file?.fileId) {
          doSyncFilesProposalItemRequest({ proposalId, proposalItemId, file })
        }
      }
      dispatch(manageProposalItemsFilesSuccess(true))
      setLoading(false)
    }
  }

  const deleteFileProposal = async ({ proposalId, files }) => {
    if (files?.length) {
      setLoading(true)
      const filesToDelete = []
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i]
        if (file?.fileId) {
          filesToDelete.push(
            requestApi(servicesProposals.deleteAttachmentProposal(proposalId, file?.fileId)),
          )
        }
      }

      try {
        await Promise.all(filesToDelete)
        dispatch(deleteAttachmentProposalSuccess())

        setLoading(false)
      } catch (error) {
        if (error?.message === 'canceled') return
        dispatch(proposalsFails(error))
        setError(error)
        setLoading(false)
      }

      setLoading(false)
    }
  }
  const deleteFileProposalItem = async ({ proposalId, files, proposalItemId }) => {
    if (files?.length) {
      setLoading(true)
      const filesToDelete = []
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i]
        if (file?.fileId) {
          filesToDelete.push(
            requestApi(
              servicesProposals.deleteAttachmentItemProposal(
                proposalId,
                proposalItemId,
                file?.fileId,
              ),
            ),
          )
        }
      }

      try {
        await Promise.all(filesToDelete)
        dispatch(deleteAttachmentProposalItemSuccess())

        setLoading(false)
      } catch (error) {
        if (error?.message === 'canceled') return
        dispatch(proposalsFails(error))
        setError(error)
        setLoading(false)
      }

      setLoading(false)
    }
  }

  const manageProposalFiles = async (proposalId, filesToAdd, filesToRemove, proposalType) => {
    setLoading(true)
    try {
      if (proposalType === 'proposal-items' && Object.entries(filesToAdd).length) {
        for (let i = 0; i < Object.entries(filesToAdd)?.length; i += 1) {
          const idItem = Object.entries(filesToAdd)[i][0]
          const value = Object.entries(filesToAdd)[i][1]
          if (value) {
            setFilesProposalItem({
              proposalId,
              proposalItemId: idItem,
              files: value,
            })
          }
        }
      }

      if (proposalType === 'proposal' && filesToAdd.length) {
        for (let i = 0; i < filesToAdd?.length; i += 1) {
          const value = [filesToAdd[i]]
          if (value) {
            setFilesProposal({ proposalId, files: value })
          }
        }
      }

      if (proposalType === 'proposal-items' && Object.entries(filesToRemove).length) {
        for (let i = 0; i < Object.entries(filesToRemove)?.length; i += 1) {
          const idItem = Object.entries(filesToRemove)[i][0]
          const value = Object.entries(filesToRemove)[i][1]
          if (value) {
            deleteFileProposalItem({
              proposalId,
              files: value,
              proposalItemId: idItem,
            })
          }
        }
      }

      if (proposalType === 'proposal' && filesToRemove.length) {
        for (let i = 0; i < filesToRemove?.length; i += 1) {
          const value = [filesToRemove[i]]
          if (value) {
            deleteFileProposal({
              proposalId,
              files: value,
            })
          }
        }
      }

      setLoading(false)
    } catch (error) {
      if (error?.message === 'canceled') return
      dispatch(employeeFails(error))
      setError(error)
      setLoading(false)
    }
  }

  const setLinksProposal = async (proposalId, linksToAdd = []) => {
    if (!linksToAdd.length) return
    setLoading(true)
    const linksToManage = []

    for (const link of linksToAdd) {
      linksToManage.push(
        requestApi(servicesProposals.createLinkProposal(proposalId, link.linkId, link)),
      )
    }

    try {
      await Promise.all(linksToManage)
      dispatch(manageProposalLinkSuccess(true))
    } catch (error) {
      if (error?.message === 'canceled') return
      dispatch(proposalsFails(error))
      setError(error)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const deleteLinkProposal = async (proposalId, linksToRemove = []) => {
    if (!linksToRemove.length) return
    setLoading(true)
    const linksToManage = []
    for (const linkId of linksToRemove) {
      linksToManage.push(requestApi(servicesProposals.deleteLinkProposal(proposalId, linkId)))
    }

    try {
      await Promise.all(linksToManage)
      dispatch(manageProposalLinkSuccess(true))
    } catch (error) {
      if (error?.message === 'canceled') return
      dispatch(proposalsFails(error))
      setError(error)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const setLinksProposalItem = async (proposalId, proposalItemId, linksToAdd = []) => {
    if (!linksToAdd.length) return
    setLoading(true)
    const linksToManage = []
    for (const link of linksToAdd) {
      linksToManage.push(
        requestApi(
          servicesProposals.createLinkItemProposal(proposalId, proposalItemId, link.linkId, link),
        ),
      )
    }

    try {
      await Promise.all(linksToManage)
      dispatch(manageProposalItemsLinkSuccess(true))
    } catch (error) {
      if (error?.message === 'canceled') return
      dispatch(proposalsFails(error))
      setError(error)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const deleteLinkProposalItem = async (proposalId, proposalItemId, linksToRemove = []) => {
    if (!linksToRemove.length) return
    setLoading(true)
    const linksToManage = []
    for (const linkId of linksToRemove) {
      linksToManage.push(
        requestApi(servicesProposals.deleteLinkItemProposal(proposalId, proposalItemId, linkId)),
      )
    }
    try {
      await Promise.all(linksToManage)
      dispatch(manageProposalItemsLinkSuccess(true))
    } catch (error) {
      if (error?.message === 'canceled') return
      dispatch(proposalsFails(error))
      setError(error)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const manageProposalLinks = (
    proposalId,
    proposalType,
    linksToAdd = [],
    linksToRemove = [],
    proposalItemId,
  ) => {
    if (proposalType === 'proposal') {
      setLinksProposal(proposalId, linksToAdd)
      deleteLinkProposal(proposalId, linksToRemove)
    } else if (proposalType === 'proposal-items') {
      setLinksProposalItem(proposalId, proposalItemId, linksToAdd)
      deleteLinkProposalItem(proposalId, proposalItemId, linksToRemove)
    }
  }

  const resetStoreOpenedOffer = () => dispatch(resetOpenedOffer())
  const resetStoreProposal = () => dispatch(resetProposal())

  return {
    getBidPackageOffer,
    actionBidPackageOffer,
    resetStoreOpenedOffer,
    resetStoreProposal,
    cancelRequest,
    manageProposalFiles,
    manageProposalLinks,
  }
}
