import { format } from 'date-fns'

const now = () => format(new Date(), 'yyyy-MM-dd HH:mm')
export const trakerLogin = (userId, user) =>
  window.hj('identify', userId, {
    employee_name: user.name, // Nombre del usuario
    email: user.email, // Email de la persona que se ha logueado
    type: user.type, // Buyer/Seller
    company_name: user.company_name, // Nombre de la empresa del usuario logueado
    user_rol: user.role, // Standard, Super
    last_login: now(), // Día y hora cuando se ejecuta el comando
  })
