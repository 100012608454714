import { Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { parseToInteger, parseToString } from '../../../../lib/utils/Format'

export const EnvironmentalCostsSectionBidItem = ({
  item,
  offers,
  columns,
  getBidItemOfferDetailsRequest,
}) => {
  const setDetailsLink = (offerItem) => (
    <Text
      color="blue1"
      textAlign="right"
      sizeText="display12"
      style={{ textDecoration: 'underline', cursor: 'pointer' }}
      onClick={() => getBidItemOfferDetailsRequest(offerItem, item)}>
      Ver detalle
    </Text>
  )

  const getTotalOfferItem = (measure, eco2) => {
    if (!measure || !eco2) return '-'

    return `${parseToString((parseToInteger(measure, 3) / 1000) * parseToInteger(eco2))} kg`
  }

  return (
    <Grid gridTemplateAreas={columns.area} gridTemplateColumns={columns.columns}>
      <GridItem
        gridArea="bidItem"
        bg="white"
        paddingY={3}
        paddingX={2}
        style={{
          position: 'sticky',
          left: 0,
          zIndex: 9,
        }}>
        <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="regular">
          {item?.description}
        </Text>
      </GridItem>
      <GridItem
        gridArea="unit"
        bg="white"
        paddingY={3}
        paddingX={2}
        style={{
          position: 'sticky',
          left: 488,
          zIndex: 9,
        }}>
        <Text
          textAlign="left"
          sizeText="display12"
          color="gray1"
          overflow="hidden"
          fontWeight="regular">
          {item?.unit}
        </Text>
      </GridItem>
      <GridItem
        gridArea="measure"
        bg="white"
        paddingY={3}
        paddingX={2}
        style={{
          position: 'sticky',
          left: 576,
          zIndex: 9,
        }}>
        <Text
          textAlign="center"
          sizeText="display12"
          color="gray1"
          overflow="hidden"
          fontWeight="regular">
          {parseToString(item?.measure || 0, 3)}
        </Text>
      </GridItem>
      <GridItem
        gridArea="ceco2"
        bg="white"
        paddingY={3}
        paddingX={2}
        style={{
          position: 'sticky',
          left: 664,
          zIndex: 9,
        }}>
        <Text
          textAlign="left"
          sizeText="display12"
          color="gray1"
          overflow="hidden"
          fontWeight="regular">
          {item?.enviroData?.eCo2 ? `${parseToString(item?.enviroData?.eCo2 || 0, 2)} kg` : '-'}
        </Text>
      </GridItem>
      {offers.map((offer) =>
        offer?.offerItems?.map((offerItem) => {
          if (item?.id !== offerItem?.itemId) return null

          return (
            <>
              <GridItem gridArea={`offer-unit-${offer.id}`} bg="white" paddingY={3} paddingX={2}>
                <Text
                  textAlign="right"
                  sizeText="display12"
                  color="gray1"
                  overflow="hidden"
                  fontWeight="regular">
                  {offerItem?.enviroData?.eCo2
                    ? `${parseToString(offerItem?.enviroData?.eCo2 || 0, 2)} kg`
                    : '-'}
                </Text>
              </GridItem>
              <GridItem
                gridArea={`offer-total-${offer.id}`}
                bg="white"
                paddingRight={2}
                paddingY={4}>
                <Text
                  sizeText="display12"
                  textAlign="right"
                  color="gray1"
                  overflow="hidden"
                  fontWeight="regular">
                  {getTotalOfferItem(item?.measure, offerItem?.enviroData?.eCo2)}
                </Text>
                {offerItem?.enviroData?.enviroDataInfo ? setDetailsLink(offer, offerItem) : null}
              </GridItem>
            </>
          )
        }),
      )}
    </Grid>
  )
}
