import { useEffect } from 'react'
import { useFormik } from 'formik'

import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'
import { Box, Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { TextAreaEditor } from '@proveoeng/uikit/dist/atoms/TextAreaEditor/TextAreaEditor'
import { Checkbox } from '@proveoeng/uikit/dist/atoms/Checkbox'

const initialValues = {
  message: '',
  notify: true,
}

export const ModalSendMessage = ({
  title = 'Enviar mensaje',
  subTitle = 'Mensaje',
  isOpened,
  setIsOpened,
  onSubmit,
  hasCheckbox,
  hasNotNotifyButton = true,
  hasCancelButton,
  sendNotificationImmediately,
}) => {
  const action = async (values, formikHelpers) => {
    await onSubmit(values)
    formikHelpers.resetForm()
  }

  const { values, handleSubmit, setFieldValue, errors, submitForm, resetForm } = useFormik({
    initialValues,
    onSubmit: action,
  })

  const handleChangeMessage = (value) => {
    if (typeof value === 'string') setFieldValue('message', value)
  }

  const notNotify = async () => {
    await setFieldValue('notify', false)
    await submitForm()
    resetForm()
  }

  useEffect(() => {
    resetForm({ values: initialValues })
    setFieldValue('message', '')
  }, [isOpened, onSubmit])

  return (
    <Modal
      key={title}
      isOpen={isOpened}
      id="import-modal"
      onModalClose={() => setIsOpened(false)}
      closeWithButton
      title={title}
      width="80vw"
      maxWidth="926px"
      maxHeight="688px">
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Modal.Content>
          <Box marginBottom={4}>{subTitle}</Box>
          <Field sizeText="display16">
            <TextAreaEditor
              placeholderMessage="Escribe tu mensaje aquí"
              name="description"
              onChange={handleChangeMessage}
              hasError={errors.message}
              defaultValue={values.message}
              maxLength="auto"
              initialValue={values.message}
            />
          </Field>
          {hasCheckbox && (
            <Box marginTop={4}>
              <Checkbox
                ariaLabel="notify"
                action={(_, v) => setFieldValue('notify', !v)}
                defaultChecked={!values.notify}
                name="notify"
                label="No quiero notificar al proveedor"
                fontWeight="medium"
              />
            </Box>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Flex justifyContent={hasCancelButton ? 'space-between' : 'end'}>
            {hasCancelButton && (
              <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr" mr={4}>
                <Button
                  colorType="transparent"
                  type="button"
                  action={() => setIsOpened(false)}
                  width="184px">
                  Cancelar
                </Button>
              </Grid>
            )}
            <Flex>
              {hasNotNotifyButton && (
                <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr" mr={4}>
                  <Button colorType="transparent" type="button" action={notNotify} width="184px">
                    No enviar
                  </Button>
                </Grid>
              )}
              <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr">
                <Button colorType="orange" type="submit" width="184px">
                  {hasNotNotifyButton || sendNotificationImmediately
                    ? 'Enviar notificación'
                    : 'Continuar'}
                </Button>
              </Grid>
            </Flex>
          </Flex>
        </Modal.Actions>
      </form>
    </Modal>
  )
}
