import { useState } from 'react'

import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import {
  Accepted,
  Cancelled,
  Finished,
  Info,
  Message,
  Pending,
  Reject,
  Search,
  Send,
  WarningOutline,
} from '@proveoeng/uikit/dist/atoms/Icons'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { SelectInputMultiple } from '@proveoeng/uikit/dist/atoms/SelectMultiple'
import { Tooltip } from '@proveoeng/uikit/dist/atoms/Tooltip'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { ActionMenu } from '@proveoeng/uikit/dist/molecules/ActionsMenu/ActionMenu'
import { DataTable } from '@proveoeng/uikit/dist/molecules/DataTable'

import { parseEditorMessage } from '../../../../../../lib/utils/parseEditorMessage'
import { Helper } from '../../../../../common/components/Helper/Helper'
import { ModalSendMessage } from '../../../../../common/components/sendMessage/ModalSendMessage'
import { ModalConfirm } from '../../../common/components/ModalConfirm'
import { ModalInviteSeller } from '../../../common/components/ModalInviteSeller'
import { ModalSellerDetails } from '../../../common/components/ModalSellerDetails'
import { SellerMessageModal } from '../../../common/components/SellerMessageModal'
import { offerStatusCopy } from '../../../config'

const icons = {
  Pending: <Send />,
  Accepted: <Accepted />,
  Rejected: <Reject />,
  Cancelled: <Cancelled />,
  Finished: <Finished />,
  Awarded: <Finished />,
  Invited: <Pending />,
}

const notExistEmail = (email) =>
  !email || email?.includes('notienecorreo') || email?.includes('@conkau.io')

const TooltipWrapper = ({ children, type }) => {
  const [active, setActive] = useState(false)
  return (
    <Tooltip
      active={active}
      setActive={setActive}
      bgColor="gray3"
      textColor="black"
      padding={4}
      width="497px"
      content={<Helper type={type} />}>
      {children}
    </Tooltip>
  )
}

export const GuestsList = ({
  guests = [],
  optionsFilter,
  filters,
  setFilters,
  inviteMoreIsOpened,
  setInviteMoreIsOpened,
  bidPackage,
  categories,
  projectProvince,
  handleInviteMore,
  seller,
  handleOpenDetail,
  detailsIsOpened,
  setDetailsIsOpened,
  detailsTabOpen,
  resendInvitation,
  confirmCancelIsOpened,
  setConfirmCancelIsOpened,
  handleCancelOffer,
  proposalsBySeller,
  sendModalIsOpen,
  setSendModalIsOpen,
  handleSendMessage,
  openSendMessageModal,
  messageData,
}) => {
  const [filterText, setFilterText] = useState('')
  const [offerIdSelected, setOfferIdSelected] = useState()
  const [sellerMessageModalIsOpened, setSellerMessageModalIsOpened] = useState(false)
  const [message, setMessage] = useState('')
  const [sellersSelected, setSellersSelected] = useState([])

  const handleChangeMessage = (html) => typeof html === 'string' && setMessage(html)

  const openSellerMessageModal = (sellers) => {
    setSellersSelected(sellers)
    setInviteMoreIsOpened(false)
    setSellerMessageModalIsOpened(true)
  }

  const onSubmitMessage = () => {
    const messageParsed = parseEditorMessage(message)
    handleInviteMore(sellersSelected, messageParsed)
    setSellerMessageModalIsOpened(false)
  }

  const handleOpenConfirmCancel = (offerId) => {
    setOfferIdSelected(offerId)
    setConfirmCancelIsOpened(true)
  }

  const goBackInvite = () => {
    setSellerMessageModalIsOpened(false)
    setInviteMoreIsOpened(true)
  }

  const columns = [
    {
      name: 'Empresa',
      selector: (row) => row.companyName?.toLowerCase() || '',
      cell: (row) =>
        row.companyName ? (
          <Text
            as="span"
            sizeText="display14"
            fontWeight="600"
            onClick={() => handleOpenDetail(row.companyId)}
            style={{ cursor: row.companyId ? 'pointer' : 'default' }}>
            {row.companyName}
          </Text>
        ) : (
          <Flex alignItems="center">
            <Text as="span" sizeText="display14" color="black" fontWeight="400" paddingLeft={1}>
              Empresa pendiente de registrar
            </Text>
          </Flex>
        ),
      sortable: true,
    },
    {
      name: 'Contacto',
      selector: (row) => row.contactName?.toLowerCase() || '',
      cell: (row) => {
        if (row.contactName) {
          if (notExistEmail(row?.contactEmail)) {
            return (
              <TooltipWrapper type="no-mail">
                <Flex justifyContent="center" alignItems="center">
                  <Icon sizeIcon="display24" color="gray1">
                    <Info />
                  </Icon>
                  <Text
                    as="span"
                    sizeText="display14"
                    fontWeight="600"
                    onClick={() => handleOpenDetail(row.companyId, 'contact')}
                    style={{ cursor: row.companyId ? 'pointer' : 'default' }}>
                    {row.contactName}
                  </Text>
                </Flex>
              </TooltipWrapper>
            )
          }

          return (
            <Text
              as="span"
              sizeText="display14"
              fontWeight="600"
              onClick={() => handleOpenDetail(row.companyId, 'contact')}
              style={{ cursor: row.companyId ? 'pointer' : 'default' }}>
              {row.contactName}
            </Text>
          )
        }

        return (
          <TooltipWrapper type="no-contact">
            <Flex alignItems="center">
              <Icon sizeIcon="display18" color="black">
                <WarningOutline />
              </Icon>
              <Text as="span" sizeText="display14" color="black" fontWeight="400" paddingLeft={1}>
                No existen datos de contacto
              </Text>
            </Flex>
          </TooltipWrapper>
        )
      },
      sortable: true,
    },
    {
      name: 'Estado',
      selector: (row) => offerStatusCopy[row.state],
      cell: (row) => (
        <Flex alignItems="center">
          <Icon sizeIcon="display30" color="gray1">
            {icons[row.state] || <Pending />}
          </Icon>
          <Text as="span" sizeText="display14" fontWeight="400" paddingLeft={1}>
            {offerStatusCopy[row.state]}
          </Text>
        </Flex>
      ),
      sortable: true,
    },
    {
      name: 'Última actualización',
      cell: (row) => (
        <Text as="span" sizeText="display14" fontWeight="400">
          {row.lastUpdated}
        </Text>
      ),
      selector: (row) => row.lastUpdated,
      sortable: true,
    },
    {
      name: '',
      width: '150px',
      cell: (row) => (
        <>
          {(row?.state === 'Invited' || row?.state === 'Pending') && (
            <Text
              as="span"
              color="blue1"
              sizeText="display14"
              style={{ cursor: 'pointer' }}
              disabled={!row.invitationEmail}
              onClick={() => {
                const isSellerRegistered = row?.state === 'Pending'
                resendInvitation(row?.invitationId, row?.offerId, isSellerRegistered)
              }}>
              Reenviar invitación
            </Text>
          )}
        </>
      ),
    },
    {
      name: '',
      width: '50px',
      cell: (row) => (
        <>
          {row?.state === 'Accepted' && (
            <Flex
              alignItems="center"
              style={{ cursor: 'pointer' }}
              onClick={() =>
                openSendMessageModal({ proposalId: row?.proposalId, companyName: row?.companyName })
              }>
              <Icon sizeIcon="display24" color="blue1">
                <Message />
              </Icon>
            </Flex>
          )}
        </>
      ),
    },
    {
      name: '',
      button: true,
      width: '60px',
      cell: (row) => (
        <ActionMenu
          actions={[
            {
              id: 'details',
              isDisabled: !row.companyId,
              content: (
                <Text sizeText="display14" color="black" paddingY={2}>
                  Ver detalles del proveedor
                </Text>
              ),
              action: () => handleOpenDetail(row.companyId),
            },
            {
              id: 'cancel',
              content: (
                <Text sizeText="display14" color="red1" paddingY={2}>
                  Cancelar
                </Text>
              ),
              action: () => handleOpenConfirmCancel(row.offerId),
            },
          ]}
        />
      ),
    },
  ]

  const filteredGuests = guests.filter(
    ({ companyName, contact }) =>
      !filterText ||
      (companyName && companyName.toLowerCase().includes(filterText.toLowerCase())) ||
      (contact && contact.toLowerCase().includes(filterText.toLowerCase())),
  )

  return (
    <>
      <Box marginBottom={5}>
        <Flex marginBottom={5} justifyContent="space-between" alignItems="end">
          <Flex>
            <Box width="288px">
              <Input
                name="searchBar"
                type="search"
                value={filterText}
                action={(_, value) => setFilterText(value)}
                placeholderMessage="Buscar por nombre de la empresa, por contacto o por dirección de correo electrónico"
                iconLeft={
                  <Icon sizeIcon="display18" color="gray3">
                    <Search />
                  </Icon>
                }
                marginRigth={2}
                maxWidth="288px"
              />
            </Box>
            <Box marginX={3}>
              <SelectInputMultiple
                options={optionsFilter}
                id="filter"
                label="Estado"
                onChange={(_, a) => setTimeout(() => setFilters(a), 0)}
                defaultValue={filters}
              />
            </Box>
          </Flex>
          <Box>
            <Text as="h4" sizeText="display14" color="black" marginBottom={2} textAlign="end">
              {guests.length} proveedores invitados
            </Text>
            {bidPackage.state !== 'Awarded' && (
              <Button
                sizeButton="medium"
                colorType="transparent"
                width="160px"
                action={() => setInviteMoreIsOpened(true)}>
                Invitar más
              </Button>
            )}
          </Box>
        </Flex>
        <DataTable columns={columns} data={filteredGuests} />
      </Box>

      <ModalInviteSeller
        isOpened={inviteMoreIsOpened}
        setIsOpened={setInviteMoreIsOpened}
        categoryId={bidPackage?.categoryId}
        categories={categories}
        categoryName={
          categories?.find((category) => category.value === bidPackage?.categoryId)?.label
        }
        projectProvince={projectProvince}
        onSubmit={openSellerMessageModal}
        handleOpenDetail={handleOpenDetail}
      />

      <ModalSellerDetails
        isOpen={detailsIsOpened}
        setIsOpened={setDetailsIsOpened}
        seller={seller}
        detailsTabOpen={detailsTabOpen}
        proposalsBySeller={proposalsBySeller}
      />

      <SellerMessageModal
        isOpened={sellerMessageModalIsOpened}
        setIsOpened={setSellerMessageModalIsOpened}
        handleChangeMessage={handleChangeMessage}
        message={message}
        onPublish={onSubmitMessage}
        goBack={goBackInvite}
      />

      <ModalConfirm
        title="Cancelar petición de presupuesto"
        subTitle="Una vez se cancela la petición de presupuesto, el proveedor ya no tendrá acceso al proceso de compra. Puedes volver a enviarle la petición de presupuesto utilizando el botón de Invitar."
        isOpen={confirmCancelIsOpened}
        setIsOpen={setConfirmCancelIsOpened}
        onSubmit={() => handleCancelOffer(offerIdSelected)}
        cancelButtonText="No, volver"
        submitButtonText="Si, cancelar"
      />

      <ModalSendMessage
        title={`Enviar notificación a ${messageData?.companyName}`}
        subTitle={
          <Text>
            <strong>{messageData?.companyName}</strong> recibirá un correo electrónico con el
            siguiente mensaje.
          </Text>
        }
        hasCancelButton={false}
        onSubmit={handleSendMessage}
        isOpened={sendModalIsOpen}
        setIsOpened={setSendModalIsOpen}
      />
    </>
  )
}
