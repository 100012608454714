import Proptypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import LoginPage from '../../../pages/auth/container/LoginPage'

export const routes = (loginPath) => ({
  path: loginPath,
  pages: {
    login: {
      path: `${loginPath}`,
    },
  },
})

export const LoginRoutes = ({ match }) => {
  const applicationRoutes = routes(match.url)
  return (
    <Switch>
      <Route path={applicationRoutes.pages.login.path} component={LoginPage} />
      <Redirect to={applicationRoutes.path} />
    </Switch>
  )
}

LoginRoutes.propTypes = {
  match: Proptypes.shape().isRequired,
}
