/* eslint-disable no-await-in-loop */
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { requestApi } from '../../../lib/request'
import { services as servicesBidPackage } from '../schemes/bidPackages/config'
import {
  actionProposalSuccess,
  createAttachmentProposalItemSuccess,
  createAttachmentProposalSuccess,
  deleteAttachmentProposalItemSuccess,
  deleteAttachmentProposalSuccess,
  getProposalsItemsSuccess,
  getProposalSuccess,
  manageProposalItemsLinkSuccess,
  manageProposalLinkSuccess,
  proposalsFails,
  resetProposal as resetProposalAction,
  setAttachmentProposalBidPackageSuccess,
  setProposalBidPackageSuccess,
  updateProposalItemSuccess,
} from '../schemes/proposals'
import { services } from '../schemes/proposals/config'
// import { awardProposalSuccess } from '../schemes/bidPackages'
import { employeeFails } from '../schemes/employees'
import {
  BidPackageProposalMapper,
  DetailProposalMapper,
  ProposalEnviroMapper,
  ProposalItemEnviroMapper,
  ProposalItemMapper,
} from '../schemes/proposals/mapper'

import { getFileNameFromHeaders } from '../../../lib/utils/getFileNameFromHeaders'
import { useUtils } from './useUtils'

export const useProposal = () => {
  const dispatch = useDispatch()
  const { setAlert, setError, setLoading, getBase64Attachment, getSignal, cancelRequest } =
    useUtils()
  const [proposalsBySeller, setProposalsBySeller] = useState({
    data: [],
    meta: {
      page: 1,
      size: 0,
      total: 0,
    },
  })

  const [proposal, setProposal] = useState({
    bidPackage: {},
    project: {},
  })

  const [proposalItems, setProposalItems] = useState([])

  const getProposalAttachment = async (attachment) => {
    try {
      const base64 = await getBase64Attachment(attachment)

      dispatch(
        createAttachmentProposalSuccess({
          ...attachment,
          base64,
        }),
      )
    } catch (error) {
      if (error?.message === 'canceled') return
      dispatch(employeeFails(error))
      setError(error)
    }
  }

  const getProposalBidPackageAttachment = async (attachment) => {
    try {
      const base64 = await getBase64Attachment(attachment)

      dispatch(
        setAttachmentProposalBidPackageSuccess({
          ...attachment,
          base64,
        }),
      )
    } catch (error) {
      if (error?.message === 'canceled') return
      dispatch(employeeFails(error))
      setError(error)
    }
  }

  const getProposalAttachments = async (data = []) =>
    Promise.all(data?.map(async (el) => getProposalAttachment(el)))

  const getProposalBidPackageAttachments = async (data = []) =>
    Promise.all(
      data?.map(async (el) =>
        getProposalBidPackageAttachment({ fileId: el.fileId, name: el.name, type: el.type }),
      ),
    )

  const getProposal = async (proposalId) => {
    if (!proposalId) return
    setLoading(true)
    try {
      const {
        data: {
          data: { files, bidPackage, ...rest },
        },
      } = await requestApi(services.getProposal(proposalId))

      dispatch(getProposalSuccess(DetailProposalMapper.hydrate(rest)))
      dispatch(setProposalBidPackageSuccess(BidPackageProposalMapper.hydrate(bidPackage)))

      setProposal({
        ...DetailProposalMapper.hydrate(rest),
        files,
        bidPackage: BidPackageProposalMapper.hydrate(bidPackage),
      })

      if (files?.length) getProposalAttachments(files)

      if (bidPackage?.files?.length) getProposalBidPackageAttachments(bidPackage?.files)
    } catch (error) {
      setError(error)
      dispatch(proposalsFails(error))
    } finally {
      setLoading(false)
    }
  }

  const getProposalEnviro = async (proposalId) => {
    if (!proposalId) return null
    setLoading(true)
    try {
      const {
        data: { data },
      } = await requestApi(services.getProposalEnviro(proposalId))
      setProposal((prevState) => ({
        ...prevState,
        ...ProposalEnviroMapper.hydrate(data),
      }))

      return data
    } catch (error) {
      setError(error)
      dispatch(proposalsFails(error))
      return null
    } finally {
      setLoading(false)
    }
  }

  const updateActionProposal = async (proposalId, body) => {
    setLoading(true)
    try {
      await requestApi(services.actionProposal(proposalId, body), {
        signal: getSignal(),
      })

      dispatch(actionProposalSuccess())

      setLoading(false)
    } catch (error) {
      if (error?.message === 'canceled') return
      dispatch(proposalsFails(error))
      setError(error)
      setLoading(false)
    }
  }

  const updateProposalItem = async (id, idItem, body) => {
    setLoading(true)
    try {
      await requestApi(
        services.updateProposalItem(id, idItem, ProposalItemMapper.dehydrate(body)),
        {
          signal: getSignal(),
        },
      )

      dispatch(updateProposalItemSuccess())

      setLoading(false)
    } catch (error) {
      if (error?.message === 'canceled') return
      dispatch(proposalsFails(error))
      setError(error)
      setLoading(false)
    }
  }

  const updateProposal = async ({ id, body, publish, proposalState, message }) => {
    setLoading(true)
    try {
      await requestApi(services.updateProposal(id, DetailProposalMapper.dehydrate(body)), {
        signal: getSignal(),
      })

      if (publish) {
        const messageBody = message ? { message } : {}
        await updateActionProposal(id, {
          actionName: proposalState === 'Awaiting improvement' ? 'SendImprovement' : 'SendProposal',
          actionData: { ...messageBody },
        })
      }
      setLoading(false)
    } catch (error) {
      if (error?.message === 'canceled') return
      dispatch(employeeFails(error))
      setError(error)
      setLoading(false)
    }
  }

  const awardProposal = async (bidPackageId, actionData) => {
    if (!actionData.proposalId) return
    setLoading(true)
    try {
      const body = { actionName: 'Award', actionData }
      await requestApi(servicesBidPackage.actionBidPackage(bidPackageId, body))
      setAlert('success', 'Has seleccionado al proveedor para la compra.')
    } catch (error) {
      setError(error)
      dispatch(proposalsFails(error))
    } finally {
      setLoading(false)
    }
  }

  const rejectProposal = async (proposalId, actionData = {}) => {
    if (!proposalId) return
    setLoading(true)
    try {
      const body = { actionName: 'Reject', actionData }
      await requestApi(services.actionProposal(proposalId, body))
      setAlert('success', 'Has descartado la compra.')
    } catch (error) {
      setError(error)
      dispatch(proposalsFails(error))
    } finally {
      setLoading(false)
    }
  }

  const requestImprovementProposal = async (proposalId, actionData = {}) => {
    if (!proposalId) return
    setLoading(true)
    try {
      const body = { actionName: 'RequestImprovement', actionData }
      await requestApi(services.actionProposal(proposalId, body))
      setAlert('success', 'Has enviado un mensaje al proveedor.')
    } catch (error) {
      setError(error)
      dispatch(proposalsFails(error))
    } finally {
      setLoading(false)
    }
  }

  const resetProposal = () => dispatch(resetProposalAction())

  const getProposalItemsAttachment = async (attachment, proposalItemId) => {
    try {
      const base64 = await getBase64Attachment(attachment)

      dispatch(
        createAttachmentProposalItemSuccess({
          ...attachment,
          base64,
          proposalItemId,
        }),
      )
    } catch (error) {
      if (error?.message === 'canceled') return
      dispatch(employeeFails(error))
      setError(error)
    }
  }

  const getProposalItemsAttachments = async (data = [], proposalItemId) =>
    Promise.all(
      data.map(async (el) => {
        if (el?.fileId) {
          getProposalItemsAttachment(el, proposalItemId)
        }
      }),
    )

  const getProposalItems = async (id) => {
    setLoading(true)
    try {
      const response = await requestApi(services.getProposalItems(id), {
        signal: getSignal(),
      })
      const { data } = response

      dispatch(
        getProposalsItemsSuccess(
          data?.data?.map((el) => {
            const {
              resource: { files, ...rest },
            } = el
            return ProposalItemMapper.hydrate(rest)
          }),
        ),
      )

      setProposalItems(
        data?.data?.map((el) => {
          const { resource } = el
          return ProposalItemMapper.hydrate(resource)
        }),
      )

      const withFiles = data?.data?.filter((el) => el?.resource?.files?.length)

      if (withFiles?.length) {
        for (let i = 0; i < data?.data?.length; i += 1) {
          const files = data?.data[i]?.resource?.files
          const proposalItemId = data?.data[i]?.resource?.proposalItemId
          if (files?.length) getProposalItemsAttachments(files, proposalItemId)
        }
      }

      setLoading(false)
    } catch (error) {
      if (error?.message === 'canceled') return
      dispatch(employeeFails(error))
      setError(error)
      setLoading(false)
    }
  }

  const getProposalItemsEnviro = async (id) => {
    setLoading(true)
    try {
      const response = await requestApi(services.getProposalItemsEnviro(id), {
        signal: getSignal(),
      })
      const { data } = response

      dispatch(
        getProposalsItemsSuccess(
          data?.data?.map((el) => {
            const {
              resource: { files, ...rest },
            } = el
            return ProposalItemMapper.hydrate(rest)
          }),
        ),
      )

      setProposalItems((prevState) =>
        prevState.map((proposalItem) => {
          const proposalItemEnviro = data?.data?.find(
            (el) => el?.resource?.proposalItemId === proposalItem?.proposalItemId,
          )
          return {
            ...proposalItem,
            ...ProposalItemEnviroMapper.hydrate(proposalItemEnviro?.resource),
          }
        }),
      )

      const withFiles = data?.data?.filter((el) => el?.resource?.files?.length)

      if (withFiles?.length) {
        for (let i = 0; i < data?.data?.length; i += 1) {
          const files = data?.data[i]?.resource?.files
          const proposalItemId = data?.data[i]?.resource?.proposalItemId
          if (files?.length) getProposalItemsAttachments(files, proposalItemId)
        }
      }

      setLoading(false)
    } catch (error) {
      if (error?.message === 'canceled') return
      dispatch(employeeFails(error))
      setError(error)
      setLoading(false)
    }
  }

  const getProposalOffer = async (id) => {
    setLoading(true)
    try {
      const response = await requestApi(services.getProposal(id), { signal: getSignal() })
      const {
        data: { files, bidPackage, ...rest },
      } = response?.data
      dispatch(getProposalSuccess(DetailProposalMapper.hydrate(rest)))
      dispatch(setProposalBidPackageSuccess(BidPackageProposalMapper.hydrate(bidPackage)))

      if (files?.length) getProposalAttachments(files)

      if (bidPackage?.files?.length) getProposalBidPackageAttachments(bidPackage?.files)

      setLoading(false)
    } catch (error) {
      if (error?.message === 'canceled') return
      dispatch(employeeFails(error))
      setError(error)
      setLoading(false)
    }
  }

  const setFilesProposal = async ({ proposalId, files }) => {
    if (!proposalId || !files.length) return
    setLoading(true)
    for (const file of files) {
      if (!file.fileId) {
        const fileId = uuidv4()
        try {
          await requestApi(
            services.createAttachmentProposal(
              proposalId,
              fileId,
              { file: file.file },
              {
                signal: getSignal(),
              },
            ),
          )
          dispatch(
            createAttachmentProposalSuccess({
              type: file.type,
              base64: file.base64,
              name: file.name,
              fileId,
            }),
          )
        } catch (error) {
          setError(error)
        }
      }
    }
    setLoading(false)
  }

  const setFileProposalItem = async ({ proposalId, proposalItemId, file }) => {
    if (!file?.fileId) {
      const fileId = uuidv4()
      try {
        await requestApi(
          services.createAttachmentItemProposal(
            proposalId,
            proposalItemId,
            fileId,
            { file: file.file },
            {
              signal: getSignal(),
            },
          ),
        )
        dispatch(
          createAttachmentProposalItemSuccess({
            type: file.type,
            base64: file.base64,
            name: file.name,
            fileId,
          }),
        )
      } catch (error) {
        setError(error)
      }
    }
  }

  const setFilesProposalItem = async ({ proposalId, proposalItemId, files }) => {
    if (!proposalId || !proposalItemId || !files.length) return
    setLoading(true)
    for (const file of files) {
      await setFileProposalItem({ proposalId, proposalItemId, file })
    }
    setLoading(false)
  }

  const deleteFilesProposal = async (proposalId, files = []) => {
    if (!proposalId || !files.length) return
    setLoading(true)
    for (const file of files) {
      if (file.fileId) {
        try {
          await requestApi(services.deleteAttachmentProposal(proposalId, file.fileId))
          dispatch(deleteAttachmentProposalSuccess())
        } catch (error) {
          setError(error)
        }
      }
    }
    setLoading(false)
  }

  const deleteFilesProposalItem = async (proposalId, proposalItemId, files = []) => {
    if (!proposalId || !files.length) return
    setLoading(true)
    for (const file of files) {
      if (file.fileId) {
        try {
          await requestApi(
            services.deleteAttachmentItemProposal(proposalId, proposalItemId, file.fileId),
          )
          dispatch(deleteAttachmentProposalItemSuccess())
        } catch (error) {
          setError(error)
        }
      }
    }
    setLoading(false)
  }

  const setLinksProposal = async (proposalId, links = []) => {
    if (!proposalId || !links.length) return
    setLoading(true)
    for (const link of links) {
      if (!link.linkId) {
        const linkId = uuidv4()
        try {
          await requestApi(services.createLinkProposal(proposalId, linkId, link))
          dispatch(manageProposalLinkSuccess(true))
        } catch (error) {
          setError(error)
        }
      }
    }
    setLoading(false)
  }

  const deleteLinksProposal = async (proposalId, links = []) => {
    if (!proposalId || !links.length) return
    setLoading(true)
    for (const linkId of links) {
      if (linkId) {
        try {
          await requestApi(services.deleteLinkProposal(proposalId, linkId))
          dispatch(manageProposalLinkSuccess())
        } catch (error) {
          setError(error)
        }
      }
    }
    setLoading(false)
  }

  const setLinksProposalItem = async (proposalId, proposalItemId, linksToAdd = []) => {
    if (!proposalId || !linksToAdd.length) return
    setLoading(true)

    for (const link of linksToAdd) {
      if (!link.linkId) {
        const linkId = uuidv4()
        try {
          await requestApi(
            services.createLinkItemProposal(proposalId, proposalItemId, linkId, link),
          )
          dispatch(manageProposalLinkSuccess(true))
        } catch (error) {
          if (error?.message === 'canceled') return
          dispatch(proposalsFails(error))
          setError(error)
          setLoading(false)
        }
      }
    }

    setLoading(false)
  }

  const deleteLinksProposalItem = async (proposalId, proposalItemId, linksToRemove = []) => {
    if (!linksToRemove.length) return
    setLoading(true)
    const linksToManage = []
    for (const linkId of linksToRemove) {
      linksToManage.push(
        requestApi(services.deleteLinkItemProposal(proposalId, proposalItemId, linkId)),
      )
    }
    try {
      await Promise.all(linksToManage)
      dispatch(manageProposalItemsLinkSuccess(true))
    } catch (error) {
      if (error?.message === 'canceled') return
      dispatch(proposalsFails(error))
      setError(error)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const exportFile = async (file, name) => {
    const a = document.createElement('a')
    a.href = URL.createObjectURL(new Blob([file.data], { type: file.data.type }))
    /* Getting the file name from the header.
     * If this is not available, use the file name from params. */
    const fileName = getFileNameFromHeaders(file.headers) || name
    a.download = fileName
    a.click()
    a.remove()
  }

  const exportProposal = async (proposalId, name) => {
    const file = await requestApi(services.exportProposal(proposalId))
    exportFile(file, name)
  }

  const exportBidPackagePrices = async (proposalId, name) => {
    const file = await requestApi(services.exportProposalPrices(proposalId))
    exportFile(file, name)
  }

  const importProposalPrices = async (proposalId, file) => {
    if (!file?.length) return
    setLoading(true)
    try {
      const response = await requestApi(
        services.importProposal(proposalId, { pricesFile: file[0].file }),
        {
          signal: getSignal(),
        },
      )
      exportFile(response, 'Petición de Presupuesto.xlsx')
      setAlert('success', 'Tu oferta se ha importado con éxito')
    } catch (error) {
      if (error?.message === 'canceled') return

      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const getProposalsBySeller = async (sellerId, buyerId) => {
    setLoading(true)
    try {
      const response = await requestApi(services.proposalsbySeller(sellerId, buyerId))
      const data = response?.data
      setProposalsBySeller({
        data: data?.data?.map(({ resource }) => resource),
        meta: data?.meta,
      })
    } catch (error) {
      if (error?.message === 'canceled') return
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const sendMessage = async (proposalId, body = {}) => {
    if (!proposalId) return
    setLoading(true)
    try {
      const messageId = uuidv4()
      await requestApi(services.sendMessage(proposalId, messageId, body))
      setAlert('success', 'Has enviado un mensaje al contratista.')
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const updateProposalEnviro = async (proposalId, data = {}) => {
    if (!proposalId) return
    setLoading(true)
    try {
      const body = ProposalEnviroMapper.dehydrate(data)
      await requestApi(services.updateProposalEnviro(proposalId, body))
      setAlert('success', 'Has actualizado el estado de la propuesta.')
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const createFilesProposalEnviro = async (proposalId, files = []) => {
    if (!proposalId || !files.length) return
    setLoading(true)
    try {
      for (const file of files) {
        if (file.file && !file.fileId) {
          const fileId = uuidv4()
          await requestApi(
            services.createFileProposalEnviro(proposalId, fileId, { file: file.file }),
          )
        }
      }
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const createLinksProposalEnviro = async (proposalId, links = []) => {
    if (!proposalId || !links.length) return
    setLoading(true)
    try {
      for (const link of links) {
        if (!link.linkId) {
          const linkId = uuidv4()
          await requestApi(services.createLinkProposalEnviro(proposalId, linkId, link))
        }
      }
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const deleteFilesProposalEnviro = async (proposalId, filesToDelete = []) => {
    if (!proposalId || !filesToDelete.length) return
    setLoading(true)
    try {
      for (const { fileId } of filesToDelete) {
        if (fileId) await requestApi(services.deleteFileProposalEnviro(proposalId, fileId))
      }
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const deleteLinksProposalEnviro = async (proposalId, linksToDelete = []) => {
    if (!proposalId || !linksToDelete.length) return
    setLoading(true)
    try {
      for (const linkId of linksToDelete) {
        await requestApi(services.deleteLinkProposalEnviro(proposalId, linkId))
      }
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const updateProposalItemEnviro = async (proposalId, proposalItemId, data = {}) => {
    if (!proposalId || !proposalItemId) return
    setLoading(true)
    try {
      const body = ProposalItemEnviroMapper.dehydrate(data)
      await requestApi(services.updateProposalItemEnviro(proposalId, proposalItemId, body))
      setAlert('success', 'Has actualizado el estado de la propuesta.')
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const createFilesProposalItemEnviro = async (proposalId, proposalItemId, files = []) => {
    if (!proposalId || !proposalItemId || !files.length) return
    setLoading(true)
    try {
      for (const file of files) {
        if (file.file && !file.fileId) {
          const fileId = uuidv4()
          await requestApi(
            services.createFileProposalItemEnviro(proposalId, proposalItemId, fileId, {
              file: file.file,
            }),
          )
        }
      }
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const createLinksProposalItemEnviro = async (proposalId, proposalItemId, links = []) => {
    if (!proposalId || !proposalItemId || !links.length) return
    setLoading(true)
    try {
      for (const link of links) {
        if (!link.linkId) {
          const linkId = uuidv4()
          await requestApi(
            services.createLinkProposalItemEnviro(proposalId, proposalItemId, linkId, link),
          )
        }
      }
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const deleteFilesProposalItemEnviro = async (proposalId, proposalItemId, filesToDelete = []) => {
    if (!proposalId || !proposalItemId || !filesToDelete.length) return
    setLoading(true)
    try {
      for (const { fileId } of filesToDelete) {
        if (fileId)
          await requestApi(
            services.deleteFileProposalItemEnviro(proposalId, proposalItemId, fileId),
          )
      }
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const deleteLinksProposalItemEnviro = async (proposalId, proposalItemId, linksToDelete = []) => {
    if (!proposalId || !proposalItemId || !linksToDelete.length) return
    setLoading(true)
    try {
      for (const linkId of linksToDelete) {
        await requestApi(services.deleteLinkProposalItemEnviro(proposalId, proposalItemId, linkId))
      }
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  return {
    data: {
      proposalsBySeller,
      proposal,
      proposalItems,
    },
    enviro: {
      getProposalEnviro,
      getProposalItemsEnviro,
      updateProposalEnviro,
      updateProposalItemEnviro,
      createFilesProposalEnviro,
      createLinksProposalEnviro,
      deleteFilesProposalEnviro,
      deleteLinksProposalEnviro,
      createFilesProposalItemEnviro,
      createLinksProposalItemEnviro,
      deleteFilesProposalItemEnviro,
      deleteLinksProposalItemEnviro,
    },
    getProposal,
    awardProposal,
    rejectProposal,
    requestImprovementProposal,
    updateProposal,
    updateProposalItem,
    resetProposal,
    getProposalItems,
    cancelRequest,
    getProposalOffer,
    setFilesProposal,
    deleteFilesProposal,
    setFilesProposalItem,
    deleteFilesProposalItem,
    setLinksProposal,
    deleteLinksProposal,
    setLinksProposalItem,
    deleteLinkProposalItem: deleteLinksProposalItem,
    exportProposal,
    exportBidPackagePrices,
    importProposalPrices,
    getProposalsBySeller,
    sendMessage,
  }
}
