import { useState } from 'react'
import { useFormikContext } from 'formik'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Box, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Checkbox } from '@proveoeng/uikit/dist/atoms/Checkbox'

import { FormContainer, InputContainer } from '../styles/RegisterStyles'

export const RegisterDataCompany = ({ preCheck = () => undefined }) => {
  const { values, errors, handleBlur, touched, handleChange, setFieldValue, setErrors } =
    useFormikContext()

  const [isLoadingTaxCode, setIsLoadingTaxCode] = useState(false)

  const checkTaxCodeOnBlur = async (e) => {
    handleBlur(e)
    setIsLoadingTaxCode(true)
    const isCheck = await preCheck({ taxCode: values.taxCode })
    if (!isCheck) setErrors({ taxCode: 'La compañía ya está registrada' })
    setIsLoadingTaxCode(false)
  }

  return (
    <>
      <FormContainer>
        <GridItem gridArea="description" marginBottom={4}>
          <Text sizeText="display18" color="black" fontWeight="bold" marginBottom={2}>
            Datos de la compañia
          </Text>
          <Text sizeText="display14" color="gray" textAlign="justify">
            Estos datos nos servirán para poder enviarte las ofertas acorde a tu perfil y
            localización.
          </Text>
        </GridItem>
        <GridItem gridArea="fields">
          <InputContainer>
            <Box>
              <Field
                label="Nombre"
                required
                sizeText="display16"
                marginBottom={3}
                errorMessage={touched.companyName && errors.companyName}>
                <Input
                  type="text"
                  placeholderMessage="Escribe el nombre de la compañia"
                  ariaLabel="companyName"
                  name="companyName"
                  value={values.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.companyName && !!errors.companyName}
                />
              </Field>
            </Box>
            <Box>
              <Field
                label="NIF/CIF/VAT"
                required
                sizeText="display16"
                marginBottom={3}
                errorMessage={touched.taxCode && errors.taxCode}>
                <Input
                  type="text"
                  placeholderMessage="Escribe el NIF/CIF/VAT de la compañia"
                  ariaLabel="taxCode"
                  name="taxCode"
                  value={values.taxCode}
                  onChange={handleChange}
                  onBlur={checkTaxCodeOnBlur}
                  hasError={touched.taxCode && !!errors.taxCode}
                  disabled={isLoadingTaxCode}
                />
              </Field>
            </Box>
          </InputContainer>
          <Box>
            <Field
              label="Dirección"
              required
              sizeText="display16"
              marginBottom={3}
              errorMessage={touched.address && errors.address}>
              <Input
                type="text"
                placeholderMessage="Dirección de la empresa"
                ariaLabel="address"
                name="address"
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                hasError={touched.address && !!errors.address}
              />
            </Field>
          </Box>
          <InputContainer>
            <Box>
              <Field
                label="Cuidad"
                required
                sizeText="display16"
                marginBottom={3}
                errorMessage={touched.city && errors.city}>
                <Input
                  type="text"
                  placeholderMessage="Escribe la cuidad de la compañia"
                  ariaLabel="city"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.city && !!errors.city}
                />
              </Field>
            </Box>
            <Box>
              <Field
                label="Código postal"
                required
                sizeText="display16"
                marginBottom={3}
                errorMessage={touched.postCode && errors.postCode}>
                <Input
                  type="text"
                  placeholderMessage="Escribe el Código postal de la compañia"
                  ariaLabel="postCode"
                  name="postCode"
                  value={values.postCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.postCode && !!errors.postCode}
                />
              </Field>
            </Box>
          </InputContainer>
        </GridItem>
      </FormContainer>
      <FormContainer>
        <GridItem gridArea="description" marginBottom={4}>
          <Text sizeText="display18" color="black" fontWeight="bold" marginBottom={2}>
            Datos de facturación
          </Text>
          <Text sizeText="display14" color="gray" textAlign="justify">
            Completa estos datos si deseas utilizar un nombre o dirección diferente a la anterior
            para temas de facturación.
          </Text>
        </GridItem>
        <GridItem gridArea="fields">
          <Checkbox
            label="Usar los datos de la compañía como datos de facturación"
            action={(_, value) => setFieldValue('isSameBillingData', value)}
            defaultChecked={values.isSameBillingData}
            name="isSameBillingData"
          />
          {!values.isSameBillingData && (
            <>
              <InputContainer>
                <Box>
                  <Field
                    label="Nombre"
                    required
                    sizeText="display16"
                    marginBottom={3}
                    errorMessage={touched.billingName && errors.billingName}>
                    <Input
                      type="text"
                      placeholderMessage="Escribe el nombre de la compañia"
                      ariaLabel="billingName"
                      name="billingName"
                      value={values.billingName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      hasError={touched.billingName && !!errors.billingName}
                    />
                  </Field>
                </Box>
                <Box> </Box>
              </InputContainer>
              <Box>
                <Field
                  label="Dirección"
                  required
                  sizeText="display16"
                  marginBottom={3}
                  errorMessage={touched.billingAddress && errors.billingAddress}>
                  <Input
                    type="text"
                    placeholderMessage="Dirección de la empresa"
                    ariaLabel="billingAddress"
                    name="billingAddress"
                    value={values.billingAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched.billingAddress && !!errors.billingAddress}
                  />
                </Field>
              </Box>
              <InputContainer>
                <Box>
                  <Field
                    label="Cuidad"
                    required
                    sizeText="display16"
                    marginBottom={3}
                    errorMessage={touched.billingCity && errors.billingCity}>
                    <Input
                      type="text"
                      placeholderMessage="Escribe la cuidad de la compañia"
                      ariaLabel="billingCity"
                      name="billingCity"
                      value={values.billingCity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      hasError={touched.billingCity && !!errors.billingCity}
                    />
                  </Field>
                </Box>
                <Box>
                  <Field
                    label="Código postal"
                    required
                    sizeText="display16"
                    marginBottom={3}
                    errorMessage={touched.billingPostCode && errors.billingPostCode}>
                    <Input
                      type="text"
                      placeholderMessage="Escribe el Código postal de la compañia"
                      ariaLabel="billingPostCode"
                      name="billingPostCode"
                      value={values.billingPostCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      hasError={touched.billingPostCode && !!errors.billingPostCode}
                    />
                  </Field>
                </Box>
              </InputContainer>
            </>
          )}
        </GridItem>
      </FormContainer>
    </>
  )
}
