import { Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'

let formData
export const DataBasicTemplate = ({ title, data, setData, errorsData, errorsKeys }) => (
  <Flex height="100%" width="100%" paddingY={11} flexDirection="column" paddingX={14}>
    <Text sizeText="display14" color="gray2" marginBottom={7}>
      Finaliza tu perfil
      <Text as="span" sizeText="display14" color="black" marginBottom={7} marginLeft={2}>
        La compañia
      </Text>
    </Text>
    <Text sizeText="display52" color="black" marginBottom={7} fontWeight="bold" maxWidth={856}>
      {title}
    </Text>
    <Grid
      as="form"
      ref={(form) => {
        formData = form
      }}
      gridTemplateColumns="220px 1fr"
      gridTemplateAreas={[
        "'description' 'fields'",
        "'description' 'fields'",
        "'description fields'",
      ]}
      gridGap={13}>
      <GridItem gridArea="description">
        <Text sizeText="display18" color="black" fontWeight="bold" marginBottom={2}>
          Email y contraseña
        </Text>
        <Text sizeText="display16" color="gray" maxWidth="221px">
          Tu primer paso tiene que ser generar una contraseña para poder acceder a la plataforma
        </Text>
      </GridItem>
      <GridItem gridArea="fields" display="flex">
        <Grid
          gridTemplateColumns="1fr"
          gridTemplateAreas={["'fields1' 'fields2'"]}
          gridGap={13}
          width="100%"
          paddingRight={14}>
          <GridItem gridArea="fields1">
            <Field
              errorMessage={errorsKeys?.email}
              label="Email"
              sizeText="display16"
              marginBottom={3}
              marginTop={0}
              required>
              <Input
                placeholderMessage="nombredelinvitado@gmail.com"
                action={() => setData(formData)}
                defaultValue={data?.email}
                name="email"
                required
                hasError={errorsData?.email}
                ariaLabel="Email"
                type="email"
              />
            </Field>
            <Field
              errorMessage={errorsKeys?.password}
              label="Contraseña"
              required
              sizeText="display16"
              marginBottom={3}
              marginTop={5}>
              <Input
                type="password"
                required
                placeholderMessage="Crea tu primera contraseña"
                action={() => setData(formData)}
                defaultValue={data?.password}
                name="password"
                hasError={errorsData?.password}
                ariaLabel="Contraseña"
                min={6}
                maxLength={80}
              />
            </Field>
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
  </Flex>
)
