import { Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

export const SectionHead = ({ offers, columnsHead, columns }) => (
  <>
    <Grid gridTemplateAreas={columnsHead.area} gridTemplateColumns={columnsHead.columns}>
      <GridItem
        gridArea="checkbox"
        bg="gray4"
        borderTopLeftRadius={2}
        style={{
          position: 'sticky',
          left: 0,
          zIndex: 9,
        }}
      />
      <GridItem
        gridArea="bidpackage"
        bg="gray4"
        borderRightWidth="1px"
        borderRightStyle="solid"
        borderRightColor="gray3"
        paddingX={2}
        paddingY={4}
        style={{
          position: 'sticky',
          left: 30,
          zIndex: 9,
        }}>
        <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
          Partida
        </Text>
      </GridItem>
      <GridItem
        gridArea="unit"
        paddingX={2}
        paddingY={4}
        bg="gray4"
        borderRightWidth="1px"
        borderRightStyle="solid"
        borderRightColor="gray3"
        style={{
          position: 'sticky',
          left: 330,
          zIndex: 9,
        }}>
        <Text
          sizeText="display14"
          textAlign="right"
          color="gray1"
          overflow="hidden"
          fontWeight="medium">
          Unidad
        </Text>
      </GridItem>
      <GridItem
        gridArea="measure"
        bg="gray4"
        paddingX={2}
        paddingY={4}
        borderRightWidth="1px"
        borderRightStyle="solid"
        borderRightColor="gray3"
        style={{
          position: 'sticky',
          left: 410,
          zIndex: 9,
        }}>
        <Text
          sizeText="display14"
          textAlign="right"
          color="gray1"
          overflow="hidden"
          fontWeight="medium">
          Medición
        </Text>
      </GridItem>
      <GridItem
        bg="gray4"
        gridArea="budget"
        paddingX={2}
        paddingY={4}
        borderRightWidth="1px"
        borderRightStyle="solid"
        borderRightColor="gray3"
        style={{
          position: 'sticky',
          left: 510,
          zIndex: 9,
        }}>
        <Text
          sizeText="display14"
          textAlign="right"
          color="gray1"
          overflow="hidden"
          fontWeight="medium">
          Ppto. de venta
        </Text>
      </GridItem>
      <GridItem
        bg="gray4"
        gridArea="expectedCosts"
        paddingX={2}
        paddingY={4}
        borderRightWidth="1px"
        borderRightStyle="solid"
        borderRightColor="gray3"
        style={{
          position: 'sticky',
          left: 730,
          zIndex: 9,
        }}>
        <Text
          sizeText="display14"
          textAlign="right"
          color="gray1"
          overflow="hidden"
          fontWeight="medium">
          Coste previsto
        </Text>
      </GridItem>
      <GridItem
        bg="gray4"
        gridArea="minEnvelope"
        paddingX={2}
        paddingY={4}
        borderRightWidth="1px"
        borderRightStyle="solid"
        borderRightColor="gray3"
        style={{
          position: 'sticky',
          left: 950,
          zIndex: 9,
        }}>
        <Text
          sizeText="display14"
          textAlign="right"
          color="gray1"
          overflow="hidden"
          fontWeight="medium">
          Envolvente mínima
        </Text>
      </GridItem>
      {offers.map((offer) => (
        <GridItem gridArea={`offer-${offer.id}`} paddingX={2} paddingY={4} bg="gray3">
          <Text
            sizeText="display14"
            textAlign="right"
            color="gray1"
            overflow="hidden"
            fontWeight="medium">
            {offer.name}
          </Text>
        </GridItem>
      ))}
    </Grid>
    <Grid gridTemplateAreas={columns.area} gridTemplateColumns={columns.columns}>
      <GridItem
        gridArea="checkbox"
        backgroundColor="white2"
        padding={2}
        style={{
          position: 'sticky',
          left: 0,
          zIndex: 9,
        }}
      />
      <GridItem
        gridArea="bidpackage"
        bg="white2"
        borderRightColor="gray3"
        borderRightStyle="solid"
        borderRightWidth="1px"
        padding={2}
        style={{
          position: 'sticky',
          left: 30,
          zIndex: 9,
        }}
      />
      <GridItem
        gridArea="unit"
        bg="white2"
        borderRightColor="gray3"
        borderRightStyle="solid"
        borderRightWidth="1px"
        paddingRight={2}
        paddingY={4}
        style={{
          position: 'sticky',
          left: 330,
          zIndex: 9,
        }}
      />
      <GridItem
        gridArea="measure"
        bg="white2"
        borderRightColor="gray3"
        borderRightStyle="solid"
        borderRightWidth="1px"
        paddingRight={2}
        paddingY={4}
        style={{
          position: 'sticky',
          left: 410,
          zIndex: 9,
        }}
      />
      <GridItem
        gridArea="budgetCost"
        bg="white2"
        padding={2}
        style={{
          position: 'sticky',
          left: 510,
          zIndex: 9,
        }}>
        <Text
          textAlign="right"
          color="gray1"
          overflow="hidden"
          fontWeight="medium"
          sizeText="display10">
          €/ud.
        </Text>
      </GridItem>
      <GridItem
        gridArea="budget"
        bg="white2"
        borderRightColor="gray3"
        borderRightStyle="solid"
        borderRightWidth="1px"
        padding={2}
        style={{
          position: 'sticky',
          left: 600,
          zIndex: 9,
        }}>
        <Text
          textAlign="right"
          color="gray1"
          overflow="hidden"
          fontWeight="medium"
          sizeText="display10">
          Importe
        </Text>
      </GridItem>
      <GridItem
        gridArea="expectedCostsUnit"
        bg="white2"
        padding={2}
        style={{
          position: 'sticky',
          left: 730,
          zIndex: 9,
        }}>
        <Text
          textAlign="right"
          color="gray1"
          overflow="hidden"
          fontWeight="medium"
          sizeText="display10">
          €/ud.
        </Text>
      </GridItem>
      <GridItem
        gridArea="expectedCosts"
        bg="white2"
        borderRightColor="gray3"
        borderRightStyle="solid"
        borderRightWidth="1px"
        padding={2}
        style={{
          position: 'sticky',
          left: 820,
          zIndex: 9,
        }}>
        <Text
          textAlign="right"
          color="gray1"
          overflow="hidden"
          fontWeight="medium"
          sizeText="display10">
          Importe
        </Text>
      </GridItem>
      <GridItem
        gridArea="minEnvelopeUnit"
        bg="white2"
        padding={2}
        style={{
          position: 'sticky',
          left: 950,
          zIndex: 9,
        }}>
        <Text
          textAlign="right"
          color="gray1"
          overflow="hidden"
          fontWeight="medium"
          sizeText="display10">
          €/ud.
        </Text>
      </GridItem>
      <GridItem
        gridArea="minEnvelope"
        bg="white2"
        borderRightColor="gray3"
        borderRightStyle="solid"
        borderRightWidth="1px"
        padding={2}
        style={{
          position: 'sticky',
          left: 1040,
          zIndex: 9,
        }}>
        <Text
          textAlign="right"
          color="gray1"
          overflow="hidden"
          fontWeight="medium"
          sizeText="display10">
          Importe
        </Text>
      </GridItem>
      {offers.map((offer) => (
        <>
          <GridItem gridArea={`offer-unit-${offer.id}`} bg="white2" padding={2}>
            <Text
              textAlign="right"
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              sizeText="display10">
              €/ud.
            </Text>
          </GridItem>
          <GridItem
            gridArea={`offer-total-${offer.id}`}
            bg="white2"
            borderRightColor="gray3"
            borderRightStyle="solid"
            borderRightWidth="1px"
            padding={2}>
            <Text
              textAlign="right"
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              sizeText="display10">
              Importe
            </Text>
          </GridItem>
        </>
      ))}
    </Grid>
  </>
)
