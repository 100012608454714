import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useOffer } from '../../../../common/hooks/useOffer'
import { useSeller } from '../../../../common/hooks/useSeller'
import { ProposalsTemplate } from '../template/ProposalsTemplate'

const ProposalsPage = () => {
  const history = useHistory()
  const {
    offers: { proposals, proposalsMeta, imagesproposals },
  } = useSelector((state) => state.employee)
  const { cancelRequest, resetStoreOpenedOffer, resetStoreProposal } = useSeller()

  const { getProposalsOffers } = useOffer()

  useEffect(() => {
    getProposalsOffers()
  }, [])

  useEffect(() => {
    resetStoreOpenedOffer()
    resetStoreProposal()

    return () => {
      cancelRequest()
    }
  }, [])

  return (
    <ProposalsTemplate
      history={history}
      applys={proposals}
      proposalsMeta={proposalsMeta}
      imagesproposals={imagesproposals}
    />
  )
}

export default ProposalsPage
