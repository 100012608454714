import { constants } from '../config'
import { constants as constantsBidPackage } from '../../bidPackages/config'

const initialState = {
  done: false,
  loading: false,
  loadingPending: false,
  loadingProposals: false,
  loadingOffersProjects: false,
  errorRequest: false,
  error: null,
  data: {},
  offers: {
    proposals: [],
    pending: [],
    projectOffers: [],
    projectOffersMeta: {},
    opened: {
      files: [],
      bidPackage: {
        files: [],
        bidItems: [],
      },
    },
    imagespending: {},
    imagesproposals: {},
  },
}

export default function setEmployeeReducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_EMPLOYEE:
      return { ...state, loading: true, done: false, errorRequest: false, error: null }
    case constants.GET_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        loading: false,
        done: true,
        data: {
          ...state.data,
          ...action.payload,
        },
      }
    }
    case constants.GET_PENDING_PROPOSALS: {
      return {
        ...state,
        loadingProposals: true,
      }
    }

    case constants.GET_PENDING_PROPOSALS_SUCCESS: {
      return {
        ...state,
        loadingProposals: false,
        offers: {
          ...state.offers,
          proposals: action.payload?.data,
          proposalsMeta: action.payload?.meta,
        },
      }
    }
    case constants.CREATE_ATTACHMENT_PENDING_PROPOSAL_SUCCESS: {
      const itemToUpdate = state.offers.proposals.findIndex(
        (element) => element?.proposalId === action.proposalId,
      )
      const updatedBidPackage = {
        ...state.offers.proposals[itemToUpdate],
        projectImage: action.payload,
      }

      const newState = state.offers.proposals
      newState.splice(itemToUpdate, 1, updatedBidPackage)

      return {
        ...state,
        offers: {
          ...state.offers,
          proposals: newState,
        },
      }
    }

    case constants.CREATE_THUMB_PENDING_PROPOSAL_SUCCESS: {
      const itemToUpdate = state.offers.proposals.findIndex(
        (element) => element?.proposalId === action.proposalId,
      )
      const updatedBidPackage = {
        ...state.offers.proposals[itemToUpdate],
        projectImage: action.payload,
      }

      const newState = state.offers.proposals
      newState.splice(itemToUpdate, 1, updatedBidPackage)

      return {
        ...state,
        offers: {
          ...state.offers,
          proposals: newState,
        },
      }
    }

    case constants.GET_PENDING_OFFERS: {
      return {
        ...state,
        loadingPending: true,
      }
    }
    case constants.GET_PENDING_OFFERS_SUCCESS: {
      return {
        ...state,
        loadingPending: false,
        offers: {
          ...state.offers,
          pending: action.payload?.data,
          pendingMeta: action.payload?.meta,
        },
      }
    }
    case constants.CREATE_ATTACHMENT_PENDING_OFFER_SUCCESS: {
      const itemToUpdate = state.offers.pending.findIndex(
        (element) => element?.bidPackageId === action.bidPackageId,
      )
      const updatedBidPackage = {
        ...state.offers.pending[itemToUpdate],
        projectImage: action.payload,
      }

      const newState = state.offers.pending
      newState.splice(itemToUpdate, 1, updatedBidPackage)

      return {
        ...state,
        offers: {
          ...state.offers,
          pending: newState,
        },
      }
    }

    case constants.CREATE_THUMB_PENDING_OFFER_SUCCESS: {
      const itemToUpdate = state.offers.pending.findIndex(
        (element) => element?.bidPackageId === action.bidPackageId,
      )
      const updatedBidPackage = {
        ...state.offers.pending[itemToUpdate],
        projectImage: action.payload,
      }

      const newState = state.offers.pending
      newState.splice(itemToUpdate, 1, updatedBidPackage)

      return {
        ...state,
        offers: {
          ...state.offers,
          pending: newState,
        },
      }
    }

    case constants.GET_OFFERS_PROJECTS: {
      return {
        ...state,
        loadingOffersProjects: true,
      }
    }
    case constants.GET_OFFERS_PROJECTS_SUCCESS: {
      return {
        ...state,
        loadingOffersProjects: false,
        offers: {
          ...state.offers,
          projectOffers: action.payload?.data,
          projectOffersMeta: action.payload?.meta,
        },
      }
    }

    case constants.CREATE_ATTACHMENT_OFFER_PROJECT_SUCCESS: {
      const itemToUpdate = state.offers.projectOffers.findIndex(
        (element) => element?.projectId === action.projectId,
      )
      const updatedProject = {
        ...state.offers.projectOffers[itemToUpdate],
        image: action.payload,
      }

      const newState = state.offers.projectOffers
      newState.splice(itemToUpdate, 1, updatedProject)

      return {
        ...state,
        offers: {
          ...state.offers,
          projectOffers: newState,
        },
      }
    }

    // SHAME: Esto está aquí, porque aunque los bidpackages/ofertas los estamso gestionando dentro de proyectos,
    // ahora mismo a nivel de esquema viene en empleado, aunque el endpoint está en /bidpackage no se, para el MVP
    // aquí se queda pero dadle vueltas
    case constantsBidPackage.GET_BID_PACKAGE_OFFER_SUCCESS: {
      const { bidPackage, ...rest } = action.payload
      return {
        ...state,
        loading: false,
        done: true,
        offers: {
          ...state.offers,
          opened: {
            ...state.offers.opened,
            ...rest,
            bidPackage: {
              ...state.offers.opened.bidPackage,
              ...bidPackage,
            },
          },
        },
      }
    }
    // SHAME: Esto está aquí, porque aunque los bidpackages/ofertas los estamso gestionando dentro de proyectos,
    // ahora mismo a nivel de esquema viene en empleado, aunque el endpoint está en /bidpackage no se, para el MVP
    // aquí se queda pero dadle vueltas
    case constantsBidPackage.CREATE_ATTACHMENT_PROJECT_OFFER_SUCCESS: {
      const newState = state.offers?.opened?.files
      const itemToUpdate = newState?.findIndex(
        (element) => element?.fileId === action.payload.fileId,
      )

      if (itemToUpdate === -1) {
        newState.push(action.payload)
      } else {
        newState[itemToUpdate] = action.payload
      }

      return {
        ...state,
        loading: false,
        done: true,
        offers: {
          ...state.offers,
          opened: {
            ...state.offers.opened,
            files: newState,
          },
        },
      }
    }
    // SHAME: Esto está aquí, porque aunque los bidpackages/ofertas los estamso gestionando dentro de proyectos,
    // ahora mismo a nivel de esquema viene en empleado, aunque el endpoint está en /bidpackage no se, para el MVP
    // aquí se queda pero dadle vueltas
    case constantsBidPackage.CREATE_ATTACHMENT_BID_PACKAGE_OFFER_SUCCESS: {
      return {
        ...state,
        loading: false,
        done: true,
        offers: {
          ...state.offers,
          opened: {
            ...state.offers.opened,
            bidPackage: {
              ...state.offers.opened.bidPackage,
              files: [...state.offers.opened.bidPackage?.files, action.payload],
            },
          },
        },
      }
    }
    // SHAME: Esto está aquí, porque aunque los bidpackages/ofertas los estamso gestionando dentro de proyectos,
    // ahora mismo a nivel de esquema viene en empleado, aunque el endpoint está en /bidpackage no se, para el MVP
    // aquí se queda pero dadle vueltas
    case constantsBidPackage.CREATE_ATTACHMENT_BID_ITEM_OFFER_SUCCESS: {
      const itemToUpdate = state.offers.opened.bidPackage?.bidItems?.findIndex(
        (element) => element?.bidItemId === action.payload.bidItemId,
      )
      const updatedBidItem = {
        ...state.offers?.opened?.bidPackage.bidItems[itemToUpdate],
        files: state.offers?.opened?.bidPackage.bidItems[itemToUpdate]?.files
          ? [...state.offers?.opened?.bidPackage.bidItems[itemToUpdate]?.files, action.payload]
          : [action.payload],
      }
      const newState = state.offers?.opened?.bidPackage.bidItems
      newState.splice(itemToUpdate, 1, updatedBidItem)
      return {
        ...state,
        offers: {
          ...state.offers,
          opened: {
            ...state.offers?.opened,
            bidPackage: {
              ...state.offers?.opened?.bidPackage,
              bidItems: newState,
            },
          },
        },
      }
    }
    case constants.RESET_OPENED_OFFER: {
      return {
        ...state,
        loading: false,
        done: true,
        offers: {
          ...state.offers,
          opened: {
            files: [],
            bidPackage: {
              files: [],
              bidItems: [],
            },
          },
        },
      }
    }

    case constantsBidPackage.ACTION_BID_PACKAGE_OFFER_SUCCESS: {
      return {
        ...state,
        loading: false,
        done: true,
        offers: {
          ...state.offers,
          opened: {
            files: [],
            bidPackage: {
              files: [],
              bidItems: [],
            },
          },
        },
      }
    }

    case constants.SET_STORE_EMPLOYEE: {
      return {
        ...state,
        data: {
          ...action.payload,
        },
      }
    }
    case constants.EMPLOYEE_FAIL:
      return { ...state, loading: false, errorRequest: true, error: { ...action.error } }

    case constants.ADD_IMAGE: {
      return {
        ...state,
        offers: {
          ...state.offers,
          [`images${action.payload.type}`]: {
            ...state.offers[`images${action.payload.type}`],
            [action.payload.id]: action.payload.thumb,
          },
        },
      }
    }

    case constants.REMOVE_IMAGE: {
      return {
        ...state,
        offers: {
          ...state.offers,
          [`images${action.payload.type}`]: {
            ...state.offers[`images${action.payload.type}`],
            [action.payload.id]: null,
          },
        },
      }
    }

    default:
      return state
  }
}
