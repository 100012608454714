import { useState } from 'react'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Tooltip } from '@proveoeng/uikit/dist/atoms/Tooltip'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

import IconWarning from '../../../common/assets/iconWarning'

const getPaymentDays = (paymentDaysOffer, paymentConditionsOffer, paymentDaysBuyer) => {
  const [active, setActive] = useState(false)

  if (paymentDaysOffer) {
    return (
      <Tooltip
        active={active}
        setActive={setActive}
        content={paymentConditionsOffer ?? 'No hay comentarios'}>
        <Flex>
          <Text color="gray1" textAlign="right" sizeText="display12">
            {paymentDaysOffer} días
          </Text>
          <Box style={{ marginTop: '-4px', marginRight: '-4px' }}>
            <IconWarning width="24px" />
          </Box>
        </Flex>
      </Tooltip>
    )
  }

  return (
    <Text color="gray1" textAlign="right" sizeText="display12">
      {paymentDaysBuyer} días
    </Text>
  )
}

export const SummaryPaymentDays = ({ offer, comparative }) => {
  const { paymentDays: paymentDaysOffer, paymentConditions: paymentConditionsOffer } = offer
  const { paymentDays: paymentDaysBuyer } = comparative

  if (!paymentDaysOffer && !paymentDaysBuyer)
    return (
      <Text color="gray1" textAlign="right" sizeText="display12">
        -
      </Text>
    )

  return getPaymentDays(paymentDaysOffer, paymentConditionsOffer, paymentDaysBuyer)
}
