/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from 'redux'
import i18nProviderReducer from '../i18n/reducer'
import setLoadingReducer from '../../cli/common/states/loading/reducer'
import setAlertReducer from '../../cli/common/states/alerts/reducer'
import setAuthReducer from '../../cli/common/schemes/auth/reducer'
import setCompaniesReducer from '../../cli/common/schemes/companies/reducer'
import setEmployeesReducer from '../../cli/common/schemes/employees/reducer'
import setProjectsReducer from '../../cli/common/schemes/projects/reducer'
import setProposalReducer from '../../cli/common/schemes/proposals/reducer'
import setHelpersReducer from '../../cli/common/schemes/helpers/reducer'
import setImagesReducer from '../../cli/common/states/images/reducer'

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    lang: i18nProviderReducer,
    alert: setAlertReducer,
    loading: setLoadingReducer,
    auth: setAuthReducer,
    helpers: setHelpersReducer,
    employee: setEmployeesReducer,
    company: setCompaniesReducer,
    projects: setProjectsReducer,
    proposals: setProposalReducer,
    images: setImagesReducer,
    ...injectedReducers,
  })
  return rootReducer
}
