const numberOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

export const formatNumber = (value) => Number(value).toLocaleString('de-DE', numberOptions)

export const formatDecimal = (value) => formatNumber(value / 100)

export const multiplyNumbers = (a, b) =>
  ((a / 100) * (b / 100)).toLocaleString('de-DE', numberOptions)
