import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'

export const BidPackageActions = ({
  bidPackage,
  setFormIsOpened,
  onEditBidPackage,
  onCopyBidPackage,
  setCancelNotificationIsOpen,
  setDeleteModalIsOpened,
}) => (
  <Flex marginBottom={7}>
    <Button
      colorType="transparent"
      disabled={bidPackage?.state !== 'Draft' && bidPackage?.state !== 'Published'}
      action={() => {
        setFormIsOpened(true)
        onEditBidPackage()
      }}>
      Editar
    </Button>
    <Button colorType="none" color="blue1" onClick={onCopyBidPackage}>
      Duplicar
    </Button>
    <Button
      colorType="none"
      color="blue1"
      disabled={bidPackage?.state !== 'Published'}
      onClick={() => setCancelNotificationIsOpen(true)}>
      Cancelar
    </Button>
    <Button
      colorType="none"
      color="red1"
      onClick={() => setDeleteModalIsOpened(true)}
      disabled={bidPackage?.state !== 'Draft'}>
      Eliminar
    </Button>
  </Flex>
)
