export const parseToInteger = (num, numberOfDecimals = 2) => {
  if (num === null || num === undefined) return null
  if (typeof num === 'number') return num

  const integerNumber = Number(String(num).replace(/[.]/g, '').replace(/[,]/g, '.'))

  return Math.round(integerNumber * 10 ** numberOfDecimals)
}

export const parseToString = (num, numberOfDecimals = 2) => {
  if (num === null || num === undefined) return null
  const numberOptions = {
    minimumFractionDigits: numberOfDecimals,
    maximumFractionDigits: numberOfDecimals,
  }

  if (typeof num === 'number')
    return (num / 10 ** numberOfDecimals).toLocaleString('de-DE', numberOptions)

  const numberWithDecimals = parseFloat(
    String(num).replace(/[.]/g, '').replace(/[,]/g, '.'),
  ).toFixed(numberOfDecimals)

  return Math.round(numberWithDecimals * 10 ** numberOfDecimals)
}

export const currencyFormat = (num) => {
  if (!num || num === '0.00') return '-'
  const amountParsed =
    typeof num === 'string' ? parseFloat(num.replace(/\./g, '').replace(/,/g, '.')) : num

  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(amountParsed)
}
