import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { useState } from 'react'
import { ModalImportBidItems } from '../../../../../common/components/previewImport/components/ModalImportBidItems'
import { ModalUploadImport } from '../../../../../common/components/uploadImport/ModalUploadImport'

import { BidItemList } from '../components/BidItemList'
import { CreateBidItem } from '../components/CreateBidItem'
import { EditBidItem } from '../components/EditBidItem'
import { ModalDeleteBidItem } from '../components/ModalDeleteBidItem'

export const BidItemListTemplate = ({
  onSubmit,
  setData,
  bidItem,
  errorsData,
  bidPackage,
  onDeleteFile,
  errorsFiles,
  onFilesLoad,
  files,
  onSubmitFiles,
  setFormCreateBidItemIsOpened,
  formCreateBidItemIsOpened,
  createdBidItem,
  formEditBidItemIsOpened,
  setFormEditBidItemIsOpened,
  onDeleteBidItem,
  onEditBidItem,
  onActionBidItem,
  links = [],
  inputLink,
  setInputLink,
  addLink,
  handleAddDescription,
  removeLink,
  verifyUrl,
  setCreatedBidItem,
  bidItems,
  importModalIsOpened,
  setImportModalIsOpened,
  onSubmitImport,
  previewModalIsOpened,
  setPreviewModalIsOpened,
  categories,
  onSubmitPreview,
  previewData,
  openUploadFiles,
  totalCosts,
}) => {
  const [deleteBidItemModalIsOpened, setDeleteBidItemModalIsOpened] = useState(false)
  const [selectId, setSelectId] = useState(null)

  return (
    <>
      <Box>
        <Flex mb={24}>
          <Box>
            <Button
              fullWidth
              width="168px"
              colorType="transparent"
              action={() => setFormCreateBidItemIsOpened(true)}>
              Añadir partida
            </Button>
          </Box>
          <Box ml={5}>
            <Button fullWidth width="168px" colorType="orange" action={openUploadFiles}>
              Importar desde .bc3
            </Button>
          </Box>
        </Flex>
        <BidItemList
          bidItems={bidItems}
          setFormIsOpened={setFormCreateBidItemIsOpened}
          bidPackage={bidPackage}
          onEditBidItem={onEditBidItem}
          onActionBidItem={onActionBidItem}
          setDeleteBidItemModalIsOpened={setDeleteBidItemModalIsOpened}
          setSelectId={setSelectId}
          totalCosts={totalCosts}
        />
      </Box>

      {formCreateBidItemIsOpened && (
        <CreateBidItem
          setData={setData}
          // data={bidItem}
          errorsData={errorsData}
          onDeleteFile={onDeleteFile}
          files={files}
          onImagesLoad={onFilesLoad}
          errorsImages={errorsFiles}
          setFormCreateBidItemIsOpened={setFormCreateBidItemIsOpened}
          title="Rellena la información de la partida"
          onSubmit={onSubmit}
          onSubmitFiles={onSubmitFiles}
          bidPackageId={bidPackage?.bidPackageId}
          createdBidItem={createdBidItem}
          links={links}
          addLink={addLink}
          removeLink={removeLink}
          inputLink={inputLink}
          setInputLink={setInputLink}
          handleAddDescription={handleAddDescription}
          verifyUrl={verifyUrl}
          setCreatedBidItem={setCreatedBidItem}
        />
      )}
      {formEditBidItemIsOpened && (
        <EditBidItem
          key={`EditBidItem-${bidItem?.bidItemId}`}
          setData={setData}
          data={bidItem}
          errorsData={errorsData}
          onDeleteFile={onDeleteFile}
          files={files}
          onImagesLoad={onFilesLoad}
          errorsImages={errorsFiles}
          setFormEditBidItemIsOpened={setFormEditBidItemIsOpened}
          title="Edita la información de la partida"
          onSubmit={onSubmit}
          bidPackageId={bidPackage?.bidPackageId}
          links={links}
          addLink={addLink}
          removeLink={removeLink}
          inputLink={inputLink}
          setInputLink={setInputLink}
          handleAddDescription={handleAddDescription}
          verifyUrl={verifyUrl}
        />
      )}

      <ModalDeleteBidItem
        deleteBidItemModalIsOpened={deleteBidItemModalIsOpened}
        setDeleteBidItemModalIsOpened={setDeleteBidItemModalIsOpened}
        setSelectId={setSelectId}
        onDeleteBidItem={onDeleteBidItem}
        selectId={selectId}
      />

      <ModalUploadImport
        isOpened={importModalIsOpened}
        setIsOpened={setImportModalIsOpened}
        onImagesLoad={onFilesLoad}
        files={files}
        onSubmit={onSubmitImport}
      />

      <ModalImportBidItems
        bidPackage={bidPackage}
        isOpened={previewModalIsOpened}
        setIsOpened={setPreviewModalIsOpened}
        categoriesData={categories}
        onSubmit={onSubmitPreview}
        data={previewData}
      />
    </>
  )
}
