import { ProposalsListResume } from '../components/ProposalsListResume'

export const ProposalsTemplate = ({
  bidPackage,
  onSelectedBidPackage,
  onCancelProposal,
  onAwardProposal,
  resetProposal,
  proposals,
  sentOffers,
  comparativesList,
  goToProposal,
  goToComparative,
}) => (
  <>
    <ProposalsListResume
      key={bidPackage?.bidPackageId}
      bidPackage={bidPackage}
      bidPackageSelected={bidPackage}
      onSelectedBidPackage={onSelectedBidPackage}
      onCancelProposal={onCancelProposal}
      onAwardProposal={onAwardProposal}
      resetProposal={resetProposal}
      proposals={proposals}
      sentOffers={sentOffers}
      comparativesList={comparativesList}
      goToProposal={goToProposal}
      goToComparative={goToComparative}
    />
  </>
)
