import Proptypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import React from 'react'
import FinishBuyerPage from '../../../pages/finish/container/FinishBuyerPage'
import { RegisterPage } from '../../../pages/register/containers/RegisterPage'

export const routes = (finishPath) => ({
  path: finishPath,
  pages: {
    finish: {
      seller: {
        path: `${finishPath}/proveedor`,
        invite: {
          path: `${finishPath}/proveedor/:hash`,
        },
      },
      buyer: {
        path: `${finishPath}/comprador/:step`,
        invite: {
          path: `${finishPath}/comprador/:step/:hash`,
        },
      },
    },
  },
})

export const FinishSignupRoutes = ({ match: { url } }) => {
  const applicationRoutes = routes(url)
  return (
    <Switch>
      <Route path={applicationRoutes.pages.finish.seller.path} component={RegisterPage} exact />
      <Route
        path={applicationRoutes.pages.finish.seller.invite.path}
        component={RegisterPage}
        exact
      />
      <Route path={applicationRoutes.pages.finish.buyer.path} component={FinishBuyerPage} exact />
      <Route
        path={applicationRoutes.pages.finish.buyer.invite.path}
        component={FinishBuyerPage}
        exact
      />
      <Redirect to={applicationRoutes.pages.finish.seller.path} />
    </Switch>
  )
}

FinishSignupRoutes.propTypes = { match: Proptypes.shape().isRequired }
