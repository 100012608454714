import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

export const Helper = ({ type = 'no-mail' }) => (
  <Flex flexDirection="column">
    <Box>
      {type === 'no-mail' && (
        <Text sizeText="display16">
          Este contacto no tiene una dirección de correo electrónico válida. Puedes invitarle al
          proceso pero no recibirá ninguna notificación hasta que no se actualice el email.
        </Text>
      )}
      {type === 'no-contact' && (
        <Text sizeText="display16">
          Esta empresa no tiene ningún contacto asociado. Puedes invitar a la empresa al proceso de
          compra, pero no recibirá ninguna notificación hasta que no se agregue un contacto.
        </Text>
      )}
    </Box>
    <Box paddingTop={4}>
      {type === 'no-mail' && (
        <Text sizeText="display16" fontWeight="bold">
          ¿Cómo puedo actualizar un contacto?
        </Text>
      )}
      {type === 'no-contact' && (
        <Text sizeText="display16" fontWeight="bold">
          ¿Cómo puedo añadir un contacto?
        </Text>
      )}
      <Text sizeText="display16">
        Envía un correo a{' '}
        <a href="mailto:soporte@conkau.io" width="100%">
          <Text as="span" color="blue1" sizeText="display16">
            soporte@conkau.io
          </Text>
        </a>{' '}
        con los datos que quieras actualizar y nos pondremos en contacto contigo cuando la
        información esté actualizada.
      </Text>
    </Box>
  </Flex>
)
