import styled from 'styled-components'
import { Button, ButtonTabs } from '@proveoeng/uikit/dist/atoms/Button'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { ActionMenu } from '@proveoeng/uikit/dist/molecules/ActionsMenu/ActionMenu'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { ArrowDown } from '@proveoeng/uikit/dist/atoms/Icons'
import { ComparatorPricesTemplate } from './ComparatorPricesTemplate'
import { ComparatorEnvironmentalCostsTemplate } from './ComparatorEnvironmentalCostsTemplate'

const ActionMenuStyled = styled(ActionMenu)`
  border: 2px solid;
  &:hover {
    color: #595b67;
  }
`

export const ComparatorTemplate = ({
  saveAndExport,
  handleEditSection,
  projectId,
  addItem,
  setItem,
  setOfferItem,
  addSection,
  removeItems,
  updateImprovementRate,
  comparative,
  offerColumns,
  addSectionModalIsOpened,
  setAddSectionModalIsOpened,
  saveComparatorModalIsOpened,
  exportModalIsOpened,
  handleRemoveSection,
  onSubmitSaveCompative,
  closeRemoveSection,
  setTableWidthCSS,
  history,
  bidPackageId,
  comparativeData,
  setSaveComparatorModalIsOpened,
  exportActions,
  setEditSectionModalIsOpened,
  setRemoveSectionModalIsOpened,
  editSectionModalIsOpened,
  removeSectionModalIsOpened,
  setExportModalIsOpened,
  tabItems,
  activeTab,
}) => (
  <Flex bg="white">
    <Flex
      height="100%"
      width="100%"
      paddingTop={7}
      paddingBottom={5}
      paddingX={8}
      flexDirection="column"
      position="relative">
      <Flex flexDirection="column">
        <Text as="span" sizeText="display14" color="gray2" paddingBottom={6}>
          <Text
            as="span"
            sizeText="display14"
            color="blue1"
            marginBottom={7}
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(`/constructora/tus-obras/${projectId}/${bidPackageId}`)}>
            {'<'} Volver
          </Text>{' '}
          |{' '}
          <Text
            as="span"
            sizeText="display14"
            marginX={1}
            color="gray2"
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => history.push('/constructora/tus-obras')}>
            Tus obras
          </Text>{' '}
          /
          <Text
            as="span"
            sizeText="display14"
            marginX={1}
            color="gray2"
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => history.push(`/constructora/tus-obras/${projectId}`)}>
            {comparativeData?.project}
          </Text>{' '}
          /
          <Text
            as="span"
            sizeText="display14"
            marginX={1}
            color="gray2"
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => history.push(`/constructora/tus-obras/${projectId}/${bidPackageId}`)}>
            {comparativeData?.bidPackageName}
          </Text>{' '}
          /
          <Text as="span" sizeText="display14" marginX={1} color="black">
            Comparativo
          </Text>{' '}
        </Text>
        <Flex justifyContent="space-between" paddingBottom={10}>
          <Flex>
            <Text sizeText="display30" color="black" fontWeight="bold" maxWidth={856}>
              {comparative?.title ?? 'Comparativo'}
            </Text>
          </Flex>
          <Flex>
            <ActionMenuStyled padding={3} actions={exportActions}>
              Descargar comparativo{' '}
              <Icon color="black" sizeIcon="display20">
                <ArrowDown />
              </Icon>
            </ActionMenuStyled>
            <Button
              width="184px"
              colorType="orange"
              onClick={() => setSaveComparatorModalIsOpened(true)}>
              Guardar comparativo
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Flex marginBottom={5}>
        <ButtonTabs items={tabItems} />
      </Flex>

      {activeTab === 0 && (
        <ComparatorPricesTemplate
          setAddSectionModalIsOpened={setAddSectionModalIsOpened}
          comparative={comparative}
          bidPackageId={bidPackageId}
          setTableWidthCSS={setTableWidthCSS}
          updateImprovementRate={updateImprovementRate}
          addItem={addItem}
          setItem={setItem}
          setOfferItem={setOfferItem}
          setEditSectionModalIsOpened={setEditSectionModalIsOpened}
          setRemoveSectionModalIsOpened={setRemoveSectionModalIsOpened}
          removeItems={removeItems}
          offerColumns={offerColumns}
          onSubmitSaveCompative={onSubmitSaveCompative}
          saveComparatorModalIsOpened={saveComparatorModalIsOpened}
          setSaveComparatorModalIsOpened={setSaveComparatorModalIsOpened}
          addSection={addSection}
          addSectionModalIsOpened={addSectionModalIsOpened}
          editSectionModalIsOpened={editSectionModalIsOpened}
          handleEditSection={handleEditSection}
          handleRemoveSection={handleRemoveSection}
          closeRemoveSection={closeRemoveSection}
          removeSectionModalIsOpened={removeSectionModalIsOpened}
          comparativeData={comparativeData}
          exportModalIsOpened={exportModalIsOpened}
          setExportModalIsOpened={setExportModalIsOpened}
          saveAndExport={saveAndExport}
        />
      )}
      {activeTab === 1 && (
        <ComparatorEnvironmentalCostsTemplate
          setAddSectionModalIsOpened={setAddSectionModalIsOpened}
          comparative={comparative}
          bidPackageId={bidPackageId}
          setTableWidthCSS={setTableWidthCSS}
          updateImprovementRate={updateImprovementRate}
          addItem={addItem}
          setItem={setItem}
          setOfferItem={setOfferItem}
          setEditSectionModalIsOpened={setEditSectionModalIsOpened}
          removeItems={removeItems}
          offerColumns={offerColumns}
          onSubmitSaveCompative={onSubmitSaveCompative}
          saveComparatorModalIsOpened={saveComparatorModalIsOpened}
          setSaveComparatorModalIsOpened={setSaveComparatorModalIsOpened}
          addSection={addSection}
          addSectionModalIsOpened={addSectionModalIsOpened}
          editSectionModalIsOpened={editSectionModalIsOpened}
          handleEditSection={handleEditSection}
          handleRemoveSection={handleRemoveSection}
          closeRemoveSection={closeRemoveSection}
          removeSectionModalIsOpened={removeSectionModalIsOpened}
          comparativeData={comparativeData}
          exportModalIsOpened={exportModalIsOpened}
          setExportModalIsOpened={setExportModalIsOpened}
          saveAndExport={saveAndExport}
        />
      )}
    </Flex>
  </Flex>
)
