import { useMemo } from 'react'

import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Search } from '@proveoeng/uikit/dist/atoms/Icons'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { DataTable } from '@proveoeng/uikit/dist/molecules/DataTable'

import { parseToString } from '../../../../../lib/utils/Format'
import { ModalCalcCosts } from '../components/ModalCalcCosts'
import { ModalCreateBidPackage } from '../components/ModalCreateBidPackage'
import { ModalSelectProject } from '../components/ModalSelectProject'

export const Ceco2Template = ({
  catalog: { data, meta },
  handleOpenCalculateCost,
  calcCostModalIsOpen,
  handleCloseCalcCostModal,
  selectProjectModalIsOpen,
  handleOpenSelectProjectModal,
  handleCloseSelectProjectModal,
  handleOpenCreateBidPackageModal,
  createBidPackageModalIsOpen,
  handleCloseCreateBidPackageModal,
  handleGoBackCalcCost,
  handleGoBackSelectProject,
  handlePerRowsChange,
  handlePageChange,
  handleSearch,
  selectedRows,
  handleSelected,
  toggleCleared,
}) => {
  const columns = [
    {
      name: 'Código de referencia',
      selector: (row) => row.reference,
      width: '180px',
      cell: (row) => (
        <Text as="span" sizeText="display14" fontWeight="regular">
          {row.reference}
        </Text>
      ),
    },
    {
      name: 'Partida',
      selector: (row) => row.name,
      minWidth: '300px',
      cell: (row) => (
        <Text as="span" sizeText="display14" fontWeight="regular">
          {row.name}
        </Text>
      ),
    },
    {
      name: 'Descripción',
      selector: (row) => row.description,
      sortable: true,
      minWidth: '300px',
      cell: (row) => (
        <Text as="span" sizeText="display14" fontWeight="regular">
          {row.description}
        </Text>
      ),
    },
    {
      name: 'CeCO2/unidad',
      selector: (row) => row.eCo2,
      width: '150px',
      right: true,
      cell: (row) => (
        <Text as="span" sizeText="display14" fontWeight="regular">
          {parseToString(row.eCo2)} {row.unit}
        </Text>
      ),
    },
  ]

  const contextActions = useMemo(
    () => (
      <Button
        type="button"
        colorType="none"
        sizeButton="medium"
        color="blue1"
        action={() => handleOpenCalculateCost(selectedRows)}>
        Calcular costes
      </Button>
    ),
    [data, selectedRows],
  )

  return (
    <>
      <Box marginBottom={5}>
        <Flex
          marginBottom={5}
          justifyContent="space-between"
          alignItems="end"
          flexDirection="column">
          <Flex width="100%" alignItems="center" justifyContent="space-between">
            <Text
              sizeText="display30"
              color="black"
              marginBottom={5}
              fontWeight="bold"
              maxWidth={856}>
              Costes ambientales estimados por unidad
            </Text>
          </Flex>
          <Flex width="100%">
            <Input
              action={(_, value) => handleSearch(value)}
              name="searchBar"
              type="search"
              value={null}
              placeholderMessage="Buscar por código de referencia o nombre"
              iconLeft={
                <Icon sizeIcon="display18" color="gray3">
                  <Search />
                </Icon>
              }
              marginRight={2}
            />
          </Flex>
        </Flex>
        <DataTable
          title=" "
          columns={columns}
          data={data}
          selectableRows
          onSelectedRowsChange={handleSelected}
          contextActions={contextActions}
          contextMessage={{
            singular: 'partida seleccionada',
            plural: 'partidas seleccionadas',
          }}
          pagination
          paginationServer
          paginationServerOptions={{
            persistSelectedOnPageChange: true,
          }}
          paginationTotalRows={meta?.total}
          clearSelectedRows={toggleCleared}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </Box>

      <ModalCalcCosts
        costs={selectedRows}
        isOpened={calcCostModalIsOpen}
        closeModal={handleCloseCalcCostModal}
        onSubmit={handleOpenSelectProjectModal}
      />

      <ModalSelectProject
        isOpened={selectProjectModalIsOpen}
        closeModal={handleCloseSelectProjectModal}
        onSubmit={handleOpenCreateBidPackageModal}
        goBack={handleGoBackCalcCost}
      />

      <ModalCreateBidPackage
        closeModal={handleCloseCreateBidPackageModal}
        isOpened={createBidPackageModalIsOpen}
        goBack={handleGoBackSelectProject}
      />
    </>
  )
}
