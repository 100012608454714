import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Box, Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Avatar } from '@proveoeng/uikit/dist/atoms/Avatar'

import { currencyFormat } from '../../../../../lib/utils/Format'
import { colorState, copyState } from '../../common/config'

export const ProposalsTemplate = ({ history, applys, proposalsMeta, imagesproposals }) => (
  <Flex height="100%" width="100%" paddingY={5} flexDirection="column">
    <>
      <Flex width="100%" alignItems="center" justifyContent="space-between">
        <Text sizeText="display30" color="black" marginBottom={5} fontWeight="bold" maxWidth={856}>
          Tus propuestas
        </Text>
        {/* TODO: Hacer bien y pintar bien la paginación cuando se ajusten las peticiones de aquí */}
        <Text sizeText="display14" color="black" marginBottom={5}>
          <Text as="span" sizeText="display14" color="black" marginBottom={5} fontWeight="medium">
            {proposalsMeta?.page * proposalsMeta?.total}
          </Text>{' '}
          de {proposalsMeta?.total} resultados
        </Text>
      </Flex>
      <Box width="100%">
        <Grid
          gridTemplateAreas={"'name bidPackage offer state lastReview'"}
          gridTemplateColumns="33% 20% 15% 17% 15%"
          bg="gray4"
          paddingY={2}
          borderRadius={2}>
          <GridItem gridArea="name">
            <Text
              sizeText="display14"
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              marginLeft={5}>
              Obra
            </Text>
          </GridItem>
          <GridItem gridArea="bidPackage">
            <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
              Nombre de la compra
            </Text>
          </GridItem>
          <GridItem gridArea="offer">
            <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
              Oferta
            </Text>
          </GridItem>
          <GridItem gridArea="state">
            <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
              Estado
            </Text>
          </GridItem>
          <GridItem gridArea="lastReview">
            <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
              Fecha última revisión
            </Text>
          </GridItem>
        </Grid>

        {applys?.length
          ? applys?.map((apply) => (
              <Grid
                key={apply.proposalId}
                gridTemplateAreas={"'name bidPackage offer state lastReview'"}
                gridTemplateColumns="33% 20% 15% 17% 15%"
                paddingY={4}
                borderBottomColor="gray4"
                borderBottomStyle="solid"
                borderBottomWidth="1px">
                <GridItem
                  gridArea="name"
                  display="flex"
                  style={{ alignItems: 'center', cursor: 'pointer' }}
                  onClick={() =>
                    apply.state === 'DRAFT'
                      ? history.push(`/proveedor/tus-aplicaciones/${apply.proposalId}/editar`)
                      : history.push(`/proveedor/tus-aplicaciones/${apply.proposalId}`)
                  }>
                  <Avatar
                    src={imagesproposals && imagesproposals[apply?.projectImage]}
                    borderRadius={3}
                    marginRight={3}
                    placeholder={apply.projectName}
                  />
                  <Box>
                    <Text
                      sizeText="display14"
                      color="gray1"
                      overflow="hidden"
                      fontWeight="medium"
                      paddingRight={15}>
                      {apply.projectName}
                    </Text>
                  </Box>
                </GridItem>
                <GridItem gridArea="bidPackage" display="flex" style={{ alignItems: ' center' }}>
                  <Text sizeText="display14" color="gray1" paddingRight={15}>
                    {apply.bidPackageName}
                  </Text>
                </GridItem>
                <GridItem gridArea="offer" display="flex" style={{ alignItems: ' center' }}>
                  <Text sizeText="display14" color="gray1" paddingRight={15}>
                    {currencyFormat(apply.total)}
                  </Text>
                </GridItem>
                <GridItem gridArea="state" display="flex" style={{ alignItems: ' center' }}>
                  <Flex alignItems="center">
                    <Box
                      backgroundColor={colorState[apply.state]}
                      width="8px"
                      height="8px"
                      borderRadius={8}
                      marginX={1}
                      minWidth="8px"
                    />
                    <Text
                      as="span"
                      marginLeft={1}
                      color="gray1"
                      sizeText="display14"
                      paddingRight={15}>
                      {copyState[apply.state]}
                    </Text>
                  </Flex>
                </GridItem>
                <GridItem
                  gridArea="lastReview"
                  display="flex"
                  style={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}>
                  <Text sizeText="display14" color="gray1" paddingRight={15}>
                    {apply.updatedAt?.readableReverseDate}
                  </Text>
                  {(apply.state === 'DRAFT' || apply.state === 'AWAITING IMPROVEMENT') && (
                    <Text
                      sizeText="display12"
                      color="blue1"
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        history.push(`/proveedor/tus-aplicaciones/${apply.proposalId}/editar`)
                      }>
                      Editar aplicación
                    </Text>
                  )}
                </GridItem>
              </Grid>
            ))
          : ''}
      </Box>
    </>
  </Flex>
)
