import { useEffect } from 'react'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal/Modal'
import { Button } from '@proveoeng/uikit/dist/atoms/Button/Button'
import { Box, Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout/Layout'
import { Field } from '@proveoeng/uikit/dist/atoms/Field/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input/Input'
import { Radiobox } from '@proveoeng/uikit/dist/atoms/Radiobox/Radiobox'

export const CreateEmployee = ({
  formCreateEmployee,
  setFormCreateEmployee,
  setData,
  data,
  errorsData,
  createNewEmployee,
}) => {
  let formData

  useEffect(() => {
    setData(formData)
  }, [])

  return (
    <Modal
      isOpen={formCreateEmployee}
      id="create-employee"
      onModalClose={() => setFormCreateEmployee(false)}
      title="Añadir empleado"
      closeWithButton
      maxWidth="808px">
      <Modal.Content>
        <Box margin="0 auto">
          <Flex flexDirection="column">
            <Box
              paddingBottom={5}
              as="form"
              ref={(form) => {
                formData = form
              }}>
              <Flex width="100%" flexDirection="column">
                <Flex width="100%" marginRight={2}>
                  <Flex flexDirection="column">
                    <Field
                      label="Tipo de usuario"
                      required
                      sizeText="display14"
                      marginBottom={4}
                      marginTop={0}
                    />
                    <Grid gridTemplateColumns="1fr 1fr 1fr" gridGap={4} justify-content="center">
                      <Radiobox
                        required
                        onChange={(_, value) => {
                          for (let i = 0; i < formData?.length; i += 1) {
                            if (formData[i]?.value === value) {
                              formData[i].checked = true
                            }
                          }
                          setData(formData)
                        }}
                        hasError={errorsData?.role}
                        items={[
                          {
                            id: 'Super',
                            name: 'role',
                            defaultChecked: data?.role === 'Super',
                            isDisabled: false,
                            hasError: false,
                            label: 'Administrador',
                            defaultValue: 'Super',
                          },
                          {
                            id: 'Standard',
                            name: 'role',
                            defaultChecked: data?.role === 'Standard',
                            isDisabled: false,
                            hasError: false,
                            label: 'Empleado',
                            defaultValue: 'Standard',
                          },
                          {
                            id: 'Supervisor',
                            name: 'role',
                            defaultChecked: data?.role === 'Supervisor',
                            isDisabled: false,
                            hasError: false,
                            label: 'Colaborador',
                            defaultValue: 'Supervisor',
                          },
                        ]}
                        justifyContent="center"
                      />
                    </Grid>
                  </Flex>
                </Flex>
                <Flex>
                  <Box width="50%" marginRight={5}>
                    <Field
                      label="Nombre completo"
                      required
                      sizeText="display14"
                      marginBottom={3}
                      marginTop={5}>
                      <Input
                        placeholderMessage="Añadir el nombre completo"
                        action={() => setData(formData)}
                        defaultValue={data?.name}
                        name="name"
                        hasError={errorsData?.name}
                        ariaLabel="name"
                      />
                    </Field>
                  </Box>

                  <Box width="50%">
                    <Field
                      label="Email"
                      required
                      sizeText="display14"
                      marginBottom={3}
                      marginTop={5}>
                      <Input
                        placeholderMessage="Email"
                        type="email"
                        ariaLabel="email"
                        action={() => setData(formData)}
                        defaultValue={data?.email}
                        name="email"
                        hasError={errorsData?.email}
                      />
                    </Field>
                  </Box>
                </Flex>
                <Flex>
                  <Box width="50%" marginRight={5}>
                    <Field
                      label="Teléfono"
                      required
                      sizeText="display14"
                      marginBottom={3}
                      marginTop={5}>
                      <Input
                        type="tel"
                        placeholderMessage="Añadir el número de teléfono"
                        action={() => setData(formData)}
                        defaultValue={data?.phone}
                        name="phone"
                        hasError={errorsData?.phone}
                        ariaLabel="phone"
                      />
                    </Field>
                  </Box>
                  <Box width="50%">
                    <Field
                      label="Cargo"
                      required
                      sizeText="display14"
                      marginBottom={3}
                      marginTop={5}>
                      <Input
                        placeholderMessage="Añadir el cargo"
                        ariaLabel="position"
                        action={() => setData(formData)}
                        defaultValue={data?.position}
                        name="position"
                        hasError={errorsData?.position}
                      />
                    </Field>
                  </Box>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Grid gridTemplateColumns="184px" gridTemplateRows="1fr">
            <Button
              colorType="orange"
              action={() => {
                createNewEmployee()
              }}>
              Añadir empleado
            </Button>
          </Grid>
        </Flex>
      </Modal.Actions>
    </Modal>
  )
}
