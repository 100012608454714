import { constants } from '../config'

export const createProject = (body) => ({
  type: constants.CREATE_PROJECT,
  body,
})

export const createProjectSuccess = (data) => ({
  type: constants.CREATE_PROJECT_SUCCESS,
  payload: data,
})

export const updateProject = (projectId, body) => ({
  type: constants.UPDATE_PROJECT,
  projectId,
  body,
})

export const updateProjectSuccess = (data) => ({
  type: constants.UPDATE_PROJECT_SUCCESS,
  payload: data,
})

export const actionProject = (projectId, actionText) => ({
  type: constants.ACTION_PROJECT,
  projectId,
  actionText,
})

export const actionProjectSuccess = (data) => ({
  type: constants.ACTION_PROJECT_SUCCESS,
  payload: data,
})

export const importBidPackages = (projectId, file) => ({
  type: constants.IMPORT_BID_PACKAGES,
  projectId,
  file,
})

export const importBidPackagesSuccess = (data) => ({
  type: constants.IMPORT_BID_PACKAGES_SUCCESS,
  payload: data,
})

export const getProject = (id, updateFiles = true) => ({
  type: constants.GET_PROJECT,
  id,
  updateFiles,
})

export const deleteProject = (id) => ({
  type: constants.DELETE_PROJECT,
  id,
})

export const deleteProjectSuccess = (data) => ({
  type: constants.DELETE_PROJECT_SUCCESS,
  payload: data,
})

export const getFileAllProjectsSuccess = (projectId, data) => ({
  type: constants.CREATE_ATTACHMENT_ALL_PROJECT_SUCCESS,
  payload: data,
  projectId,
})

export const createAttachmentProject = (projectId, files) => ({
  type: constants.CREATE_ATTACHMENT_PROJECT,
  projectId,
  files,
})

export const createAttachmentProjectSuccess = (data) => ({
  type: constants.CREATE_ATTACHMENT_PROJECT_SUCCESS,
  payload: data,
})

export const deleteAttachmentProject = (projectId, files) => ({
  type: constants.DELETE_ATTACHMENT_PROJECT,
  projectId,
  files,
})

export const deleteAttachmentProjectSuccess = (data) => ({
  type: constants.DELETE_ATTACHMENT_PROJECT_SUCCESS,
  payload: data,
})

export const createLinkProject = (projectId, links) => ({
  type: constants.CREATE_LINK_PROJECT,
  projectId,
  links,
})

export const createLinkProjectSuccess = (data) => ({
  type: constants.CREATE_LINK_PROJECT_SUCCESS,
  payload: data,
})

export const deleteLinkProject = (projectId, linkIds) => ({
  type: constants.DELETE_LINK_PROJECT,
  projectId,
  linkIds,
})

export const deleteLinkProjectSuccess = (data) => ({
  type: constants.DELETE_LINK_PROJECT_SUCCESS,
  payload: data,
})

export const setStoreProjects = (data) => ({
  type: constants.SET_STORE_PROJECTS,
  payload: data,
})

export const setStoreProject = (data) => ({
  type: constants.SET_STORE_PROJECT,
  payload: data,
})

export const setManagedFilesFinished = (value) => ({
  type: constants.SET_MANAGED_FILES_FINISHED,
  payload: value,
})

export const setManagedLinksFinished = (value) => ({
  type: constants.SET_MANAGED_LINKS_FINISHED,
  payload: value,
})
