/* eslint-disable no-continue */
export const serializeQuery = (form) => {
  if (form) {
    const serialized = []
    for (let i = 0; i < form.elements.length; i += 1) {
      const field = form.elements[i]

      // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
      if (
        !field.name ||
        field.disabled ||
        !field.value ||
        !field.value === '' ||
        field.type === 'file' ||
        field.type === 'reset' ||
        field.type === 'submit' ||
        field.type === 'button'
      )
        continue

      // If a multi-select, get all selections
      if (field.type === 'select-multiple') {
        const values = []

        for (let n = 0; n < field.options.length; n += 1) {
          if (field.options[n].selected) {
            values.push(`${encodeURIComponent(field.options[n].value)}`)
          }
        }
        const multiple = `${encodeURIComponent(field.name)}=${values}`
        serialized.push(`${multiple}`)
      }
      // Convert field data to a query string
      else if (field.type !== 'checkbox' && field.type !== 'radio') {
        serialized.push(`${encodeURIComponent(field.name)}=${encodeURIComponent(field.value)}`)
      } else if (field.type === 'checkbox') {
        if (field.checked)
          serialized.push(
            `${encodeURIComponent(field.name)}=${encodeURIComponent(field.checked || '')}`,
          )
      } else if (field.type === 'radio') {
        if (field.checked) {
          serialized.push(`${encodeURIComponent(field.name)}=${encodeURIComponent(field.value)}`)
        }
      }
    }
    return serialized.join('&')
  }
  return false
}

export const serializeObject = (form) => {
  if (form) {
    let serialized = {}

    for (let i = 0; i < form.elements.length; i += 1) {
      const field = form.elements[i]
      // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
      if (
        !field.name ||
        field.disabled ||
        field.type === 'file' ||
        field.type === 'reset' ||
        field.type === 'submit' ||
        field.type === 'button'
      )
        continue

      // If a multi-select, get all selections
      if (field.type === 'select-multiple') {
        const values = []

        for (let n = 0; n < field.options.length; n += 1) {
          if (field.options[n].selected) {
            values.push(`${encodeURIComponent(field.options[n].value)}`)
          }
        }
        serialized = { ...serialized, [field.name]: values }
      }
      // Convert field data to a query string
      else if (
        field.type !== 'tel' &&
        field.type !== 'checkbox' &&
        field.type !== 'radio' &&
        field.type !== 'email' &&
        field.type !== 'password' &&
        !field.pattern
      ) {
        serialized = { ...serialized, [field.name]: field.value }
      } else if (field.pattern) {
        const regExp = new RegExp(field.pattern, 'g')
        if (field.value.match(regExp)) {
          serialized = { ...serialized, [field.name]: field.value }
        } else {
          serialized = { ...serialized, [field.name]: null }
        }
      } else if (field.type === 'checkbox') {
        serialized = { ...serialized, [field.name]: field.checked }
      } else if (field.type === 'radio') {
        if (field.checked) {
          serialized = { ...serialized, [field.name]: field.value }
        }
      } else if (field.type === 'tel') {
        const regExpTelf = /^\d{9}$/
        if (field.value.match(regExpTelf)) {
          serialized = { ...serialized, [field.name]: field.value }
        } else {
          serialized = { ...serialized, [field.name]: null }
        }
      } else if (field.type === 'email') {
        const regExpEmail = /\b[\w.-]+@[\w.-]+\.\w{2,4}\b/
        if (field.value.match(regExpEmail)) {
          serialized = { ...serialized, [field.name]: field.value }
        } else {
          serialized = { ...serialized, [field.name]: null }
        }
      } else if (field.type === 'password') {
        if (field.value.length > 4 && field.value.length < 80) {
          serialized = { ...serialized, [field.name]: field.value }
        } else {
          serialized = { ...serialized, [field.name]: null }
        }
      }
    }
    return serialized
  }
  return false
}

export const serializeObjectToFormData = (body) => {
  const formData = new FormData()

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in body) {
    formData.append(key, body[key])
  }
  return formData
}

export const getBase64 = (data) =>
  btoa(
    // eslint-disable-next-line no-shadow
    new Uint8Array(data).reduce((data, byte) => data + String.fromCharCode(byte), ''),
  )
