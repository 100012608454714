import { useState } from 'react'

import { Button, ButtonTabs } from '@proveoeng/uikit/dist/atoms/Button'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Tooltip } from '@proveoeng/uikit/dist/atoms/Tooltip'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { colorState, copyState } from '../../../config'
import { PreviewBidPackage } from '../../../common/components/PreviewBidPackage'
import { ModalInviteSeller } from '../../../common/components/ModalInviteSeller'
import { ModalSellerDetails } from '../../../common/components/ModalSellerDetails'
import { SellerMessageModal } from '../../../common/components/SellerMessageModal'

export const BidPackageTemplate = ({
  bidPackage,
  bidItems = [],
  project,
  tabItems = [],
  categories = [],
  handleOpenDetail,
  setDetailsIsOpened,
  proposalsBySeller,
  detailsTabOpen,
  detailsIsOpened,
  goToProject,
  goToHome,
  seller,
  message,
  handleChangeMessage,
  onOpenPublish,
  onPublish,
  publishPreviewModalIsOpened,
  setPublishPreviewModalIsOpened,
  publishModalIsOpened,
  setPublishModalIsOpened,
  setSellerMessageModalIsOpened,
  sellerMessageModalIsOpened,
  onInviteSellers,
  children,
}) => {
  const [active, setActive] = useState(false)
  const isInValid =
    !bidPackage?.name ||
    !bidPackage?.categoryId ||
    !bidPackage?.paymentDays ||
    !bidPackage?.bidEndsAt ||
    (bidPackage?.state !== 'Draft' && bidPackage?.state !== 'Published') ||
    !bidItems?.length

  const goBackInvite = () => {
    setSellerMessageModalIsOpened(false)
    setPublishModalIsOpened(true)
  }

  return (
    <>
      <Flex height="100%" width="100%" flexDirection="column">
        <Flex height="100%" width="100%" flexDirection="column">
          <Text as="span" sizeText="display14" color="gray2">
            <Text
              as="span"
              sizeText="display14"
              color="blue1"
              marginBottom={7}
              style={{ cursor: 'pointer' }}
              onClick={goToProject}>
              {'<'} Volver
            </Text>{' '}
            |{' '}
            <Text
              as="span"
              sizeText="display14"
              marginX={1}
              color="gray2"
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={goToHome}>
              Tus obras
            </Text>{' '}
            /
            <Text
              as="span"
              sizeText="display14"
              marginX={1}
              color="gray2"
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={goToProject}>
              {project?.name}
            </Text>
            /
            <Text as="span" sizeText="display14" color="black" marginLeft={2}>
              {bidPackage?.name}
            </Text>
          </Text>
          <Flex alignItems="center" marginTop={2} marginBottom={2} justifyContent="space-between">
            <Flex alignItems="center">
              <Text as="span" sizeText="display30" color="black" fontWeight="bold" marginRight={4}>
                {bidPackage?.name}
              </Text>
              <Box
                backgroundColor={colorState[bidPackage?.state]}
                width="8px"
                height="8px"
                borderRadius={8}
                marginX={1}
                minWidth="8px"
              />
              <Text as="span" marginLeft={1} color="gray1">
                {copyState[bidPackage?.state]}
              </Text>
            </Flex>
            <Box marginLeft={4}>
              {isInValid ? (
                <Tooltip
                  active={isInValid && active}
                  setActive={setActive}
                  content="Para poder publicar es necesario tener como mínimo una partida. Además los datos de compra deben estar debidamente especificados (Nombre, Naturaleza, Fecha fin de oferta y Días de pago).">
                  <Button
                    colorType="orange"
                    disabled={isInValid}
                    action={() => setPublishPreviewModalIsOpened(true)}>
                    {bidPackage?.state !== 'Published' ? 'Publicar' : 'Notificar cambios'}
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  colorType="orange"
                  disabled={isInValid}
                  action={() => setPublishPreviewModalIsOpened(true)}>
                  {bidPackage?.state !== 'Published' ? 'Publicar' : 'Notificar cambios'}
                </Button>
              )}
            </Box>
          </Flex>
          <Flex marginY={3} justifyContent="space-between">
            {bidPackage?.bidStartsAt && (
              <Text as="span" sizeText="display16" color="gray" fontWeight="400" marginRight={1}>
                Fecha de publicación:{' '}
                <Text as="span" sizeText="display16" color="black" fontWeight="600">
                  {bidPackage?.bidStartsAtFormatted.readableReverseDate}
                </Text>
              </Text>
            )}
            {bidPackage?.bidEndsAt && (
              <Text as="span" sizeText="display16" color="gray" fontWeight="400" marginRight={1}>
                Fecha límite para presentar oferta:{' '}
                <Text as="span" sizeText="display16" color="black" fontWeight="600">
                  {bidPackage?.bidEndsAtFormatted.readableReverseDate}
                </Text>
              </Text>
            )}
          </Flex>
          <Flex paddingY={5}>
            <ButtonTabs items={tabItems} />
          </Flex>
          <Box>{children}</Box>
        </Flex>
      </Flex>

      <PreviewBidPackage
        project={project}
        bidPackage={bidPackage}
        bidItems={bidItems}
        isOpened={publishPreviewModalIsOpened}
        setIsOpened={setPublishPreviewModalIsOpened}
        onPublish={onOpenPublish}
        isPublished={bidPackage?.state === 'Published'}
      />

      <ModalSellerDetails
        isOpen={detailsIsOpened}
        setIsOpened={setDetailsIsOpened}
        seller={seller}
        detailsTabOpen={detailsTabOpen}
        proposalsBySeller={proposalsBySeller}
      />

      <ModalInviteSeller
        isOpened={publishModalIsOpened}
        setIsOpened={setPublishModalIsOpened}
        categoryId={bidPackage?.categoryId}
        categories={categories}
        categoryName={
          categories?.find((category) => category.value === bidPackage?.categoryId)?.label
        }
        projectProvince={project?.province}
        onSubmit={onInviteSellers}
        handleOpenDetail={handleOpenDetail}
      />

      <SellerMessageModal
        isOpened={sellerMessageModalIsOpened}
        setIsOpened={setSellerMessageModalIsOpened}
        handleChangeMessage={handleChangeMessage}
        message={message}
        onPublish={onPublish}
        goBack={goBackInvite}
      />
    </>
  )
}
