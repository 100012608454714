import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { theme } from '@proveoeng/uikit/dist/theme'
import { GlobalStyles } from '@proveoeng/uikit/dist'
import { Provider } from 'react-redux'
import { store } from './lib/store'
import { I18nProvider } from './lib/i18n'
import { translationMessages } from './lib/i18n/i18n'
import ProveoApp from './proveoApp'
import reportWebVitals from './reportWebVitals'

const MOUNT_NODE = document.getElementById('root')

const render = (messages) => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <I18nProvider messages={messages} locale="es">
          <BrowserRouter>
            <Switch>
              <ThemeProvider theme={theme}>
                <GlobalStyles />
                <Route path="/" component={ProveoApp} />
              </ThemeProvider>
            </Switch>
          </BrowserRouter>
        </I18nProvider>
      </Provider>
    </React.StrictMode>,
    MOUNT_NODE,
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// Set default global lang
window.locale = 'es'

if (module.hot) {
  // Hot reloadable React component and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./lib/i18n/i18n'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE)
    render(translationMessages)
  })
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'))
  })
    .then(() =>
      Promise.all([import('intl/locale-data/jsonp/es'), import('intl/locale-data/jsonp/en')]),
    )
    .then(() => render(translationMessages))
    .catch((error) => {
      throw error
    })
} else {
  render(translationMessages)
}
