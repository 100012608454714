/*
 *
 * I18nProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `lib/i18n/i18n`)
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { flattenMessages } from '../../cli/common/functions'

export const I18nProvider = ({ locale = 'es', messages, children }) => {
  // eslint-disable-next-line no-unused-vars
  const [language, setLanguage] = useState(locale)

  return (
    <IntlProvider
      defaultLocale={language}
      locale={language}
      key={language}
      messages={flattenMessages(messages[language])}
      textComponent={React.Fragment}>
      {React.Children.only(children)}
    </IntlProvider>
  )
}

I18nProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.shape({}).isRequired,
  children: PropTypes.element.isRequired,
}

I18nProvider.defaultProps = {
  locale: 'es',
}

export default connect((state) => ({
  locale: state.language.locale,
}))(I18nProvider)
