import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

import { Avatar } from '@proveoeng/uikit/dist/atoms/Avatar'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Search } from '@proveoeng/uikit/dist/atoms/Icons'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Box, Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { LoaderAnimation } from '@proveoeng/uikit/dist/atoms/Loader'
import { Radiobox } from '@proveoeng/uikit/dist/atoms/Radiobox'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'
import { useProject } from '../../../../common/hooks/useProject'

export const ModalSelectProject = ({ isOpened, closeModal, goBack, onSubmit }) => {
  const [listProjects, setListProjects] = useState([])
  const [projectsFiltered, setProjectsFiltered] = useState([])

  const [loading, setLoading] = useState(false)

  const { values, setFieldValue, errors, touched } = useFormikContext()

  const {
    data: { projects },
    getAllProjects,
  } = useProject()

  useEffect(() => {
    const loadProjects = async () => {
      setLoading(true)
      await getAllProjects()
      setLoading(false)
    }
    loadProjects()
    if (isOpened) resetForm()
  }, [isOpened])

  useEffect(() => {
    if (projects) {
      setProjectsMapped(projects.data)
    }
  }, [projects])

  const resetForm = () => setFieldValue('project', '')

  const onClear = () => {
    resetForm()
    closeModal(false)
  }

  const setProjectsMapped = (projectsReceived) => {
    const projectMapped = projectsReceived?.map((item) => ({
      id: item.projectId,
      name: 'duplicate',
      nameLabel: item.name,
      defaultChecked: values?.projectId === item.projectId,
      isDisabled: false,
      hasError: false,
      label: (
        <Flex alignItems="center" marginY={2}>
          <Avatar src={item.thumb} marginRight={2} borderRadius={2} placeholder={item.name} />{' '}
          {item.name}
        </Flex>
      ),
      defaultValue: item.projectId,
    }))
    setListProjects(projectMapped)
  }

  return (
    <Modal
      id="select-project-modal"
      width="95vw"
      maxWidth="575px"
      isOpen={isOpened}
      onModalClose={onClear}
      closeWithButton
      title="Elegir obra">
      <Modal.Content>
        <Box margin="0 auto">
          <Input
            action={(name, value) => {
              setProjectsFiltered(
                listProjects.filter((el) =>
                  el.nameLabel?.toUpperCase().includes(value.toUpperCase()),
                ),
              )
            }}
            type="search"
            placeholderMessage="Buscar una obra"
            iconLeft={
              <Icon sizeIcon="display18" color="gray3">
                <Search />
              </Icon>
            }
          />
          <Box marginY={4} paddingY={5} maxHeight="400px" minHeight="400px" overflowY="auto">
            {!!errors.projectId && touched.projectId && (
              <Text sizeText="display14" color="red1">
                {errors.projectId}
              </Text>
            )}
            {loading ? (
              <Flex alignItems="center" justifyContent="center" paddingTop="140px">
                <LoaderAnimation />
              </Flex>
            ) : (
              <Radiobox
                onChange={(_, value) => setFieldValue('projectId', value)}
                items={(projectsFiltered?.length && projectsFiltered) || listProjects}
              />
            )}
          </Box>
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr" mr={4}>
            <Button colorType="transparent" type="button" action={goBack}>
              Volver
            </Button>
          </Grid>
          <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr">
            <Button
              colorType="orange"
              type="button"
              action={onSubmit}
              disabled={
                !!listProjects && !listProjects?.filter((item) => item?.defaultChecked).length
              }>
              Añadir detalles
            </Button>
          </Grid>
        </Flex>
      </Modal.Actions>
    </Modal>
  )
}
