import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Flex, Box } from '@proveoeng/uikit/dist/atoms/Layout'
import { Button, ButtonTabs } from '@proveoeng/uikit/dist/atoms/Button'
import { Download, Settings, UpdateFile } from '@proveoeng/uikit/dist/atoms/Icons'
import { Info } from '@proveoeng/uikit/dist/organisms/Cards/Info'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'

import { colorState, copyState } from '../../config'
import { ProjectInfo } from '../components/ProjectInfo'
import { BidPackageList } from '../components/BidPackageList'
import { BidPackagesImportGuide } from '../../common/components/BidPackagesImportGuide'

/**
 * @param {{
 *  project: Project,
 *  goToHome: () => void,
 *  tabItems: Array,
 *  activeTab: number,
 *  exportProject: () => void,
 *  bidPackages: Array<BidPackage>,
 *  goToBidPackage: (bidPackageId: string) => void,
 *  goToEditProject: () => void,
 *  openImportModal: () => void,
 * }} props
 */
export const ProjectTemplate = ({
  project,
  tabItems,
  exportProject,
  activeTab,
  bidPackages,
  goToBidPackage,
  openImportModal,
  setFormIsOpened,
  goToEditProject,
  history,
}) => {
  if (!project?.projectId) return <></>
  return (
    <>
      <Flex flexDirection="column">
        <Text as="span" sizeText="display14" color="gray2" paddingBottom={6}>
          <Text
            as="span"
            sizeText="display14"
            color="blue1"
            marginBottom={7}
            style={{ cursor: 'pointer' }}
            onClick={() => history.push('/constructora/tus-obras')}>
            {'<'} Volver
          </Text>{' '}
          |{' '}
          <Text
            as="span"
            sizeText="display14"
            marginX={1}
            color="gray2"
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => history.push('/constructora/tus-obras')}>
            Tus obras
          </Text>{' '}
          /
          <Text as="span" sizeText="display14" color="black">
            {' '}
            {project.name}
          </Text>
        </Text>

        <Flex width="100%" justifyContent="space-between" marginBottom={6}>
          <Flex alignItems="center">
            <Text as="span" sizeText="display32" color="black" fontWeight="bold">
              {project.name}
            </Text>
            <Flex alignItems="center" paddingLeft={4} minWidth="120px">
              <Box
                backgroundColor={colorState[project?.state]}
                width="8px"
                height="8px"
                borderRadius={8}
                minWidth="8px"
              />
              <Text as="span" paddingLeft={2} color="gray1">
                {copyState[project?.state]}
              </Text>
            </Flex>
          </Flex>
          <Flex width="168px">
            <Button fullWidth colorType="white" action={exportProject}>
              <Icon>
                <Download />
              </Icon>{' '}
              Descargar obra
            </Button>
          </Flex>
        </Flex>
        <Flex paddingY={7}>
          <ButtonTabs items={tabItems} />
        </Flex>
        {activeTab === 0 && (
          <>
            <Flex mb={24}>
              <Box>
                <Button fullWidth width="168px" colorType="transparent" action={goToEditProject}>
                  Edita tu obra
                </Button>
              </Box>
            </Flex>
            <Box>
              <ProjectInfo project={project} />
            </Box>
          </>
        )}
        {activeTab === 1 && (
          <>
            {bidPackages.length > 0 ? (
              <Box>
                {!['Cancelled', 'Closed'].includes(project.state) && (
                  <Flex mb={24}>
                    <Box width="168px">
                      <Button colorType="orange" fullWidth action={openImportModal}>
                        Importa compras
                      </Button>
                    </Box>
                    <Box mr={4}>
                      <BidPackagesImportGuide withTooltip />
                    </Box>
                    <Box>
                      <Button
                        fullWidth
                        width="168px"
                        colorType="transparent"
                        action={() => {
                          setFormIsOpened(true)
                        }}>
                        Crear compra
                      </Button>
                    </Box>
                  </Flex>
                )}
                <Box>
                  <BidPackageList bidPackages={bidPackages} goToBidPackage={goToBidPackage} />
                </Box>
              </Box>
            ) : (
              <>
                <Flex>
                  <Info
                    id="import"
                    title="Importa tu compra desde un fichero excel o bc3"
                    icon={
                      <Icon color="gray2" sizeIcon="display30">
                        <UpdateFile />
                      </Icon>
                    }
                    justifyContent="flex-start"
                    description="Importa un fichero excel o bc3. <br /> Lee la guia de importación."
                    height="240px"
                    onClick={openImportModal}
                    style={{ paddingTop: '64px', cursor: 'pointer' }}
                  />
                  <BidPackagesImportGuide withTooltip />
                  <Info
                    id="import"
                    title="Crea compra desde cero"
                    icon={
                      <Icon color="gray2" sizeIcon="display30">
                        <Settings />
                      </Icon>
                    }
                    justifyContent="flex-start"
                    description="También puedes crear una compra a mano."
                    marginLeft={5}
                    height="240px"
                    onClick={() => setFormIsOpened(true)}
                    style={{ paddingTop: '64px', cursor: 'pointer' }}
                  />
                </Flex>
              </>
            )}
          </>
        )}
      </Flex>
    </>
  )
}
