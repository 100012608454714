import { useState } from 'react'

import { Card } from '@proveoeng/uikit/dist/atoms/Card'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { ArrowDown, ArrowRight } from '@proveoeng/uikit/dist/atoms/Icons'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Tooltip } from '@proveoeng/uikit/dist/atoms/Tooltip'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

import { FormatDate } from '../../mappers/FormatDate'

const TooltipWrapper = ({ content, children }) => {
  const [active, setActive] = useState(false)
  const contentParsed = content?.join('\r\n')

  return (
    <Box style={{ display: 'inline-block' }}>
      <Tooltip
        active={active}
        setActive={setActive}
        content={contentParsed}
        bgColor="gray3"
        textColor="black"
        width="auto">
        {children}
      </Tooltip>
    </Box>
  )
}

const formatContactData = (contactData) => {
  if (!contactData) return null

  if (contactData[0].split('<').length === 1) {
    return (
      <Text as="span" fontWeight="medium" color="gray" sizeText="display14" paddingLeft={1}>
        {contactData[0]}{' '}
      </Text>
    )
  }

  const receiverContactName = contactData[0].split('<')[0].trim()
  const receiverContactMail = contactData[0].split('<')[1].trim()

  return (
    <Text as="span" fontWeight="medium" color="gray" sizeText="display14">
      {receiverContactName}{' '}
      <Text
        as="span"
        fontWeight="regular"
        color="gray"
        sizeText="display14">{`<${receiverContactMail}`}</Text>
    </Text>
  )
}

const getReceivers = (receivers = [], receiverContacts = null) => {
  if (!receivers?.length) return null

  const receiversList = receivers?.split('|')
  const receiverContactsList = receiverContacts?.split('|')

  if (receiversList?.length > 1) {
    if (receiverContactsList) {
      return (
        <>
          {formatContactData(receiverContactsList)}
          <Text as="span" fontWeight="regular" color="gray" sizeText="display14" marginLeft={1}>
            <TooltipWrapper content={receiverContactsList}>
              y <u>{receiverContactsList?.length - 1} más</u>
            </TooltipWrapper>
          </Text>
        </>
      )
    }

    return (
      <Text as="span" fontWeight="regular" color="gray" sizeText="display14">
        <TooltipWrapper content={receiversList}>
          <u>{receiversList?.length} proveedores</u>
        </TooltipWrapper>
      </Text>
    )
  }

  if (receiverContactsList?.length) {
    return formatContactData(receiverContactsList)
  }

  return (
    <Text as="span" fontWeight="medium" color="gray" sizeText="display14">
      {receiversList[0]}
    </Text>
  )
}

const EventTitle = ({ eventType, emitter, contactName, receivers }) => {
  const receiversList = getReceivers(receivers)
  const actionText = {
    BidPackageWasOffered: <>ha enviado el presupuesto a {receiversList}</>,
    BidPackageOfferWasAccepted: <>({contactName}) ha aceptado el presupuesto.</>,
    BidPackageOfferWasRejected: <>({contactName}) ha rechazado el presupuesto.</>,
    TenderingMessageWasSentBySeller: <>ha enviado un mensaje.</>,
    TenderingMessageWasSentByBuyer: <>ha enviado un mensaje a {receiversList}</>,
    ProposalWasSent: <>ha enviado una propuesta.</>,
    ProposalWasNotifiedOfBidPackageChanges: <>ha notificado un cambio a {receiversList}</>,
    ProposalWasRejected: <>ha descartado la propuesta de {receiversList}</>,
    ProposalWasAwarded: <>ha seleccionado la propuesta de {receiversList}</>,
    ProposalWasNotAwardedNotified: (
      <>ha enviado una notificación a {receiversList} no seleccionados.</>
    ),
    ProposalWasNotAwardedNotNotified: (
      <>ha cambiado el estado de las propuestas no seleccionadas para {receiversList}</>
    ),
    ProposalWasCanceledByBuyer: <>ha cancelado la compra y se ha notificado a {receiversList}</>,
  }

  return (
    <>
      <Text as="span" fontWeight="medium" color="gray" sizeText="display14">
        {emitter}{' '}
        <Text as="span" fontWeight="regular" color="gray" sizeText="display14">
          {actionText[eventType] || null}
        </Text>
      </Text>
    </>
  )
}

const SimpleCard = ({ children }) => (
  <Card
    bg="white"
    borderRadius={2}
    borderWidth={1}
    borderStyle="solid"
    borderColor="gray3"
    maxHeight="122px"
    overflow="hidden"
    position="relative"
    marginBottom={4}>
    <Flex flexDirection="column" width="100%" padding={4}>
      <Box>{children}</Box>
    </Flex>
  </Card>
)

const AccordionCard = ({ children, message }) => {
  const [active, setActive] = useState(false)

  return (
    <Card
      bg="white"
      borderRadius={2}
      borderWidth={1}
      borderStyle="solid"
      borderColor="gray3"
      maxHeight={active ? '100%' : '122px'}
      overflow={active ? 'auto' : 'hidden'}
      position="relative"
      marginBottom={4}>
      <Flex
        flexDirection="column"
        width="100%"
        paddingTop={4}
        paddingRight={4}
        paddingBottom={4}
        paddingLeft={8}>
        <Box>
          <Box position="absolute" left={4} style={{ marginTop: '2px' }}>
            <Icon
              style={{ cursor: 'pointer' }}
              color="black"
              fontSize="display18"
              onClick={() => setActive(!active)}>
              {active ? <ArrowDown /> : <ArrowRight />}
            </Icon>
          </Box>
          {children}
        </Box>
        <Box marginBottom={2} maxWidth="524px">
          <Text
            as="span"
            fontWeight="medium"
            color="gray"
            sizeText="display14"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </Box>
      </Flex>
      {!active && (
        <Box
          position="absolute"
          background="linear-gradient(0deg, white, transparent)"
          left={0}
          right={0}
          bottom={0}
          height="40px"
        />
      )}
    </Card>
  )
}

export const EventLogsWrapper = ({ eventLogsData }) => {
  const { occurredOn, message, event, emitter, emitterContact, receivers, receiverContacts } =
    eventLogsData
  const contactName = emitterContact.split('<')[0].trim()
  const isEmptyMessage = message === '<p></p>\n' || false

  const emitterParsed = ['TenderingMessageWasSentByBuyer'].includes(event) ? contactName : emitter

  const getFromToContactsParsed = (event, emitter, emitterContact, receivers, receiverContacts) => {
    const fromToContacts = {
      BidPackageWasOffered: [receivers, receiverContacts],
      BidPackageOfferWasAccepted: [receivers, receiverContacts],
      BidPackageOfferWasRejected: [receivers, receiverContacts],
      TenderingMessageWasSentBySeller: [emitter, emitterContact],
      TenderingMessageWasSentByBuyer: [receivers, receiverContacts],
      ProposalWasSent: [emitter, emitterContact],
      ProposalWasNotifiedOfBidPackageChanges: [receivers, receiverContacts],
      ProposalWasRejected: [receivers, receiverContacts],
      ProposalWasAwarded: [receivers, receiverContacts],
      ProposalWasNotAwardedNotified: [receivers, receiverContacts],
      ProposalWasNotAwardedNotNotified: [receivers, receiverContacts],
      ProposalWasCanceledByBuyer: [receivers, receiverContacts],
    }

    return getReceivers(...fromToContacts[event])
  }

  const content = (
    <>
      <Flex justifyContent="space-between">
        <Flex alignItems="center" justifyContent="center">
          <EventTitle
            contactName={contactName}
            emitter={emitterParsed}
            eventType={event}
            receivers={receivers}
          />
        </Flex>
        <Text as="span" fontWeight="regular" color="gray" sizeText="display14">
          {FormatDate.hydrate(occurredOn)?.eventLogsMessagesFormat}
        </Text>
      </Flex>
      {!['BidPackageOfferWasAccepted', 'BidPackageOfferWasRejected'].includes(event) && (
        <Flex alignItems="flex-start" justifyContent="flex-start" marginBottom={message ? 2 : 0}>
          <Text as="span" fontWeight="regular" color="gray" sizeText="display14">
            {['TenderingMessageWasSentBySeller', 'ProposalWasSent'].includes(event) ? (
              <>
                De:{' '}
                {getFromToContactsParsed(
                  event,
                  emitter,
                  emitterContact,
                  receivers,
                  receiverContacts,
                )}
              </>
            ) : (
              <>
                Para:{' '}
                {getFromToContactsParsed(
                  event,
                  emitter,
                  emitterContact,
                  receivers,
                  receiverContacts,
                )}
              </>
            )}
          </Text>
        </Flex>
      )}
    </>
  )

  if (isEmptyMessage || !message) return <SimpleCard>{content}</SimpleCard>
  return <AccordionCard message={message}>{content}</AccordionCard>
}
