import { Box } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { parseToInteger, parseToString } from '../../../../../../lib/utils/Format'

import { AttachmentList } from '../../../../../common/components/attachmentList/Attachment'
import { Items } from '../../../../../common/components/item/Items'
import { Links } from '../../../../../common/components/links/Links'
import { FormatDate } from '../../../../../common/mappers/FormatDate'

/**
 *
 * @param {{ bidPackage: BidPackage, categories: Categories[] }} props
 */
export const BidPackageInfo = ({ bidPackage, categories = [] }) => {
  const getCategory = (categoryId) =>
    categories.find((category) => category.value === categoryId)?.label

  const items = [
    {
      title: 'Naturaleza',
      children: getCategory(bidPackage?.categoryId),
    },
    {
      title: 'Fecha límite para presentar oferta',
      children: bidPackage?.bidEndsAt
        ? FormatDate.hydrate(bidPackage?.bidEndsAt).readableReverseDate
        : '-',
    },
    {
      title: 'Fechas de entrega',
      children: (
        <Box>
          <Box>
            <Text as="span" sizeText="display14" color="black">
              {bidPackage?.deliveryDates?.start
                ? FormatDate.hydrate(bidPackage?.deliveryDates?.start).readableReverseDate
                : 'No informada'}{' '}
              -{' '}
              {bidPackage?.deliveryDates?.end
                ? FormatDate.hydrate(bidPackage?.deliveryDates?.end).readableReverseDate
                : 'No informada'}
            </Text>
          </Box>
        </Box>
      ),
    },
    {
      title: 'Descripción',
      children: (
        <Text
          as="p"
          sizeText="display14"
          color="black"
          style={{
            whiteSpace: 'pre-wrap',
          }}>
          {bidPackage?.description}
        </Text>
      ),
    },
    {
      title: 'Retención',
      children: (
        <Box>
          {bidPackage?.retention ? (
            <Text as="p" sizeText="display16" color="black">
              {parseToString(parseToInteger(bidPackage?.retention))}%
            </Text>
          ) : (
            '-'
          )}
        </Box>
      ),
    },
    {
      title: 'Forma de pago',
      children: bidPackage?.paymentConditions,
    },
    {
      title: 'Días de pago',
      children: (
        <Text as="span" sizeText="display16" color="black">
          {bidPackage?.paymentDays ? `${bidPackage?.paymentDays} días` : 'No informado'}
        </Text>
      ),
    },
    {
      title: 'Condiciones generales para el proveedor',
      children: (
        <Text
          as="p"
          sizeText="display14"
          color="black"
          style={{
            whiteSpace: 'pre-wrap',
          }}>
          {bidPackage?.generalConditions}
        </Text>
      ),
    },
    {
      title: 'Adjuntos y enlaces',
      children: (
        <Box>
          <AttachmentList files={bidPackage?.files} withDownload />
          <Links links={bidPackage?.links} />
        </Box>
      ),
    },
  ]
  return (
    <>
      <Items items={items} />
    </>
  )
}
