// import { useSelector } from "react-redux";
import { Redirect, Route } from 'react-router-dom'

export const PrivateRoute = ({ children, auth, ...rest }) => (
  <Route
    {...rest}
    render={({ location }) => {
      if (auth || location?.pathname?.includes('/perfil/restablecer')) return children

      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      )
    }}
  />
)
