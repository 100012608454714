import { useFormik } from 'formik'

import { Checkbox } from '@proveoeng/uikit/dist/atoms/Checkbox'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Box, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

import {
  ActionButton,
  ActionContainerStyled,
  FormContainer,
  InputContainer,
} from '../../styles/ProfileStyles'
import { profileCompanySchema } from '../../../../common/validationSchemes/profileSchemes'

/**
 *
 * @param {Object} props
 * @param {GeneralData} props.generalData
 * @param {BillingData} props.billingData
 * @param {boolean} props.isSameBillingData
 * @param {void} props.onSubmit
 */
export const ProfileCompany = ({ generalData, billingData, isSameBillingData, onSubmit }) => {
  const initialValues = {
    name: generalData.name || '',
    email: generalData.email || '',
    website: generalData.website || '',
    phone: generalData.phone || '',
    address: generalData.address || '',
    postCode: generalData.postCode || '',
    city: generalData.city || '',
    taxCode: billingData.taxCode || '',
    isSameBillingData: isSameBillingData ?? true,
    billingName: billingData.name || '',
    billingAddress: billingData.address || '',
    billingCity: billingData.city || '',
    billingPostCode: billingData.postCode || '',
  }

  const { values, errors, handleBlur, touched, handleChange, setFieldValue, handleSubmit } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema: profileCompanySchema,
    })

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormContainer>
          <GridItem gridArea="description" marginBottom={4}>
            <Text sizeText="display18" color="black" fontWeight="bold" marginBottom={2}>
              Datos de la compañia
            </Text>
            <Text sizeText="display14" color="gray">
              Estos datos nos servirán para poder enviarte las ofertas acorde a tu perfil y
              localización.
            </Text>
          </GridItem>
          <GridItem gridArea="fields">
            <InputContainer>
              <Box>
                <Field
                  label="Compañia"
                  required
                  sizeText="display16"
                  marginBottom={3}
                  errorMessage={touched.name && errors.name}>
                  <Input
                    type="text"
                    placeholderMessage="Escribe el nombre de la compañia"
                    ariaLabel="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched.name && !!errors.name}
                  />
                </Field>
              </Box>
              <Box>
                <Field
                  label="NIF/CIF/VAT"
                  required
                  sizeText="display16"
                  marginBottom={3}
                  errorMessage={touched.taxCode && errors.taxCode}>
                  <Input
                    disabled
                    type="text"
                    placeholderMessage="Escribe el NIF/CIF/VAT de la compañia"
                    ariaLabel="taxCode"
                    name="taxCode"
                    value={values.taxCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched.taxCode && !!errors.taxCode}
                  />
                </Field>
              </Box>
            </InputContainer>
            <Box>
              <Field
                label="Dirección"
                required
                sizeText="display16"
                marginBottom={3}
                errorMessage={touched.address && errors.address}>
                <Input
                  type="text"
                  placeholderMessage="Escribe la dirección de la compañia"
                  ariaLabel="address"
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.address && !!errors.address}
                />
              </Field>
            </Box>
            <InputContainer>
              <Box>
                <Field
                  label="Cuidad"
                  required
                  sizeText="display16"
                  marginBottom={3}
                  errorMessage={touched.city && errors.city}>
                  <Input
                    type="text"
                    placeholderMessage="Escribe la cuidad de la compañia"
                    ariaLabel="city"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched.city && !!errors.city}
                  />
                </Field>
              </Box>
              <Box>
                <Field
                  label="Código postal"
                  required
                  sizeText="display16"
                  marginBottom={3}
                  errorMessage={touched.postCode && errors.postCode}>
                  <Input
                    type="text"
                    placeholderMessage="Escribe el Código postal de la compañia"
                    ariaLabel="postCode"
                    name="postCode"
                    value={values.postCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched.postCode && !!errors.postCode}
                  />
                </Field>
              </Box>
            </InputContainer>
            <Box>
              <Field
                label="Email"
                sizeText="display16"
                marginBottom={3}
                errorMessage={touched.email && errors.email}>
                <Input
                  type="email"
                  placeholderMessage="Escribe el email de la compañia"
                  ariaLabel="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.email && !!errors.email}
                />
              </Field>
            </Box>
            <InputContainer>
              <Box>
                <Field
                  label="Teléfono de contacto principal"
                  sizeText="display16"
                  marginBottom={3}
                  errorMessage={touched.phone && errors.phone}>
                  <Input
                    type="tel"
                    placeholderMessage="Escribe el teléfono de la compañia"
                    ariaLabel="phone"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched.phone && !!errors.phone}
                  />
                </Field>
              </Box>
              <Box>
                <Field
                  label="Página web"
                  sizeText="display16"
                  marginBottom={3}
                  errorMessage={touched.website && errors.website}>
                  <Input
                    type="text"
                    placeholderMessage="Escribe la Página web de la compañia"
                    ariaLabel="website"
                    name="website"
                    value={values.website}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched.website && !!errors.website}
                  />
                </Field>
              </Box>
            </InputContainer>
          </GridItem>
        </FormContainer>

        <FormContainer mb={6}>
          <GridItem gridArea="description" marginBottom={4}>
            <Text sizeText="display18" color="black" fontWeight="bold" marginBottom={2}>
              Datos de facturación
            </Text>
            <Text sizeText="display14" color="gray">
              Estos datos nos servirán para poder enviarte las ofertas acorde a tu perfil y
              localización.
            </Text>
          </GridItem>
          <GridItem gridArea="fields">
            <Checkbox
              label="Usar los datos de la compañía como datos de facturación"
              action={(_, value) => setFieldValue('isSameBillingData', value)}
              defaultChecked={values.isSameBillingData}
              name="isSameBillingData"
            />
            {!values.isSameBillingData && (
              <>
                <InputContainer>
                  <Box>
                    <Field
                      label="Nombre"
                      required
                      sizeText="display16"
                      marginBottom={3}
                      errorMessage={touched.billingName && errors.billingName}>
                      <Input
                        type="text"
                        placeholderMessage="Escribe el nombre de la compañia"
                        ariaLabel="billingName"
                        name="billingName"
                        value={values.billingName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        hasError={touched.billingName && !!errors.billingName}
                      />
                    </Field>
                  </Box>
                  <Box> </Box>
                </InputContainer>
                <Box>
                  <Field
                    label="Dirección"
                    required
                    sizeText="display16"
                    marginBottom={3}
                    errorMessage={touched.billingAddress && errors.billingAddress}>
                    <Input
                      type="text"
                      placeholderMessage="Dirección de la empresa"
                      ariaLabel="billingAddress"
                      name="billingAddress"
                      value={values.billingAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      hasError={touched.billingAddress && !!errors.billingAddress}
                    />
                  </Field>
                </Box>
                <InputContainer>
                  <Box>
                    <Field
                      label="Cuidad"
                      required
                      sizeText="display16"
                      marginBottom={3}
                      errorMessage={touched.billingCity && errors.billingCity}>
                      <Input
                        type="text"
                        placeholderMessage="Escribe la cuidad de la compañia"
                        ariaLabel="billingCity"
                        name="billingCity"
                        value={values.billingCity}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        hasError={touched.billingCity && !!errors.billingCity}
                      />
                    </Field>
                  </Box>
                  <Box>
                    <Field
                      label="Código postal"
                      required
                      sizeText="display16"
                      marginBottom={3}
                      errorMessage={touched.billingPostCode && errors.billingPostCode}>
                      <Input
                        type="text"
                        placeholderMessage="Escribe el Código postal de la compañia"
                        ariaLabel="billingPostCode"
                        name="billingPostCode"
                        value={values.billingPostCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        hasError={touched.billingPostCode && !!errors.billingPostCode}
                      />
                    </Field>
                  </Box>
                </InputContainer>
              </>
            )}
          </GridItem>
        </FormContainer>
        <ActionContainerStyled borderTopColor="gray3" style={{ zIndex: 2 }}>
          <ActionButton type="submit" colorType="orange" sizeButton="medium">
            Guardar
          </ActionButton>
        </ActionContainerStyled>
      </form>
    </>
  )
}
