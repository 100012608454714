import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'

export const CreateProjectDatesTemplate = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
}) => (
  <>
    <Text as="span" sizeText="display32" color="black" marginBottom={3} fontWeight="bold">
      Introduce las fechas de inicio y fin
    </Text>
    <Field
      label="Fecha de inicio prevista"
      required
      sizeText="display16"
      marginBottom={3}
      marginLeft={2}
      errorMessage={touched.start && errors.start}>
      <Input
        type="date"
        name="start"
        placeholderMessage="Fecha de inicio prevista"
        ariaLabel="start"
        value={values.start}
        onChange={handleChange}
        onBlur={handleBlur}
        hasError={touched.start && !!errors.start}
      />
    </Field>
    <Field
      label="Fecha de finalización prevista"
      sizeText="display16"
      marginBottom={3}
      marginTop={5}
      marginLeft={2}
      required
      errorMessage={touched.end && errors.end}>
      <Input
        type="date"
        placeholderMessage="Fecha de finalización prevista"
        name="end"
        ariaLabel="end"
        min={new Date().toISOString().split('T')[0]}
        value={values.end}
        onChange={handleChange}
        onBlur={handleBlur}
        hasError={touched.end && !!errors.end}
      />
    </Field>
  </>
)
