/* eslint-disable no-await-in-loop */
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { usePagination } from '../../../../common/hooks/pagination/usePagination'
import { useBidPackage } from '../../../../common/hooks/useBidPackage'
import { useEnviro } from '../../../../common/hooks/useEnviro'
import { useUtils } from '../../../../common/hooks/useUtils'
import { Ceco2Template } from '../template/Ceco2Template'
import { validationSchema } from '../utils/validationSchema'
import { parseToInteger } from '../../../../../lib/utils/Format'

let timeOut

const Ceco2Page = () => {
  const { employeeId } = useSelector((state) => state.employee?.data)

  const fromRef = useRef()

  const [calcCostModalIsOpen, setCalcCostModalIsOpen] = useState(false)
  const [selectProjectModalIsOpen, setSelectProjectModalIsOpen] = useState(false)
  const [createBidPackageModalIsOpen, setCreateBidPackageModalIsOpen] = useState(false)

  const { setLoading, setAlert } = useUtils()

  const {
    createBidPackage,
    bidItems: { createBidItem },
    enviro: { updateBidItemEnviro },
  } = useBidPackage()

  const [selectedRows, setSelectedRows] = useState([])
  const [toggleCleared, setToggleCleared] = useState(false)

  const {
    params: { limit, page, filters },
    handlePageChange,
    handlePerRowsChange,
    handleAddFilter,
    handleRemoveFilter,
  } = usePagination()

  const {
    data: { catalog },
    getCatalog,
  } = useEnviro()

  useEffect(() => {
    const loadCatalog = async () => {
      await getCatalog({ limit, page, ...filters })
    }
    loadCatalog()
  }, [limit, page, filters])

  const initialValues = useMemo(() => {
    const dinamyInitialValues = selectedRows.reduce(
      (acc, prev) => ({ ...acc, [`measurement-${prev.reference}`]: 0 }),
      {},
    )
    return {
      projectId: '',
      name: '',
      categoryId: '',
      bidEndsAt: '',
      ...dinamyInitialValues,
    }
  }, [selectedRows])

  const dinamySchema = useMemo(() => {
    const measurementSchema = selectedRows.reduce(
      (acc, prev) => ({
        ...acc,
        [`measurement-${prev.reference}`]: Yup.string()
          .test('moreThanZero', 'Debe ser mayor a 0', (value) => parseToInteger(value, 3) > 0)
          .required('La Medición es requerida'),
      }),
      {},
    )
    return validationSchema.shape({
      ...measurementSchema,
    })
  }, [selectedRows])

  const handleSelected = ({ selectedRows }) => {
    setSelectedRows(selectedRows)
  }

  const handleSearch = (value) => {
    if (value.length < 3) {
      handleRemoveFilter('filterByContent')
      return
    }
    clearTimeout(timeOut)

    timeOut = setTimeout(() => {
      handleAddFilter('filterByContent', value)
    }, 1000)
  }

  const handleOpenCalculateCost = () => setCalcCostModalIsOpen(true)

  const handleCloseCalcCostModal = () => setCalcCostModalIsOpen(false)

  const handleOpenSelectProjectModal = () => {
    handleCloseCalcCostModal()
    setSelectProjectModalIsOpen(true)
  }

  const handleCloseSelectProjectModal = () => setSelectProjectModalIsOpen(false)

  const handleOpenCreateBidPackageModal = () => {
    const form = fromRef.current
    if (form.errors.projectId) {
      form.setTouched({ projectId: true })
      return
    }
    handleCloseSelectProjectModal()
    setCreateBidPackageModalIsOpen(true)
  }

  const handleCloseCreateBidPackageModal = () => setCreateBidPackageModalIsOpen(false)

  const handleGoBackCalcCost = () => {
    handleCloseSelectProjectModal()
    handleOpenCalculateCost()
  }

  const handleGoBackSelectProject = () => {
    handleCloseCreateBidPackageModal()
    setSelectProjectModalIsOpen(true)
  }

  const onSubmit = async (values) => {
    handleCloseCreateBidPackageModal()

    setLoading(true)

    const bidPackageId = await createBidPackage({ ...values, employeeId })

    for (const { eCo2, ...bidItem } of selectedRows) {
      const { [`measurement-${bidItem.reference}`]: measurement } = values

      const bidItemId = await createBidItem(bidPackageId, {
        ...bidItem,
        measurement,
      })

      await updateBidItemEnviro(bidPackageId, bidItemId, eCo2)
    }

    setAlert('success', '¡Felicidades! Nueva compra creada.')

    setLoading(false)

    // reset selected rows
    setToggleCleared(true)
    setTimeout(() => {
      setToggleCleared(false)
    }, 100)
  }

  return (
    <Formik
      innerRef={fromRef}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={dinamySchema}
      enableReinitialize>
      <Form>
        <Ceco2Template
          catalog={catalog}
          handleOpenCalculateCost={handleOpenCalculateCost}
          calcCostModalIsOpen={calcCostModalIsOpen}
          handleCloseCalcCostModal={handleCloseCalcCostModal}
          handleOpenCreateBidPackageModal={handleOpenCreateBidPackageModal}
          selectProjectModalIsOpen={selectProjectModalIsOpen}
          handleOpenSelectProjectModal={handleOpenSelectProjectModal}
          handleCloseSelectProjectModal={handleCloseSelectProjectModal}
          createBidPackageModalIsOpen={createBidPackageModalIsOpen}
          handleCloseCreateBidPackageModal={handleCloseCreateBidPackageModal}
          handleGoBackCalcCost={handleGoBackCalcCost}
          handleGoBackSelectProject={handleGoBackSelectProject}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
          handleSearch={handleSearch}
          selectedRows={selectedRows}
          handleSelected={handleSelected}
          toggleCleared={toggleCleared}
        />
      </Form>
    </Formik>
  )
}

export default Ceco2Page
