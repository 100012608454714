import { useEffect, useState } from 'react'
import { Box, Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Download } from '@proveoeng/uikit/dist/atoms/Icons'

import { DetailProposalBidItem } from './DetailProposalBidItem'
import { parseToInteger } from '../../../../../lib/utils/Format'

export const DetailProposalBidItems = ({ proposal, handleExportBidPackagePrices }) => {
  const [total, setTotal] = useState(0)
  const numberOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }

  useEffect(() => {
    if (proposal?.bidItems?.length) {
      const totalSum = proposal?.bidItems.reduce(
        (previous, key) => previous + parseFloat(parseToInteger(key?.total) / 100),
        0,
      )
      setTotal(totalSum)
    }
  }, [proposal])

  return (
    <>
      <Flex marginTop={4} marginBottom={6} alignItems="center">
        <Box mr={4}>
          <Button colorType="white" action={handleExportBidPackagePrices} width="178px">
            <Icon>
              <Download />
            </Icon>{' '}
            Descargar compra
          </Button>
        </Box>
      </Flex>

      <Grid
        gridTemplateAreas={
          "'empty bidItem unit measurement description price amount attachmentsAndLinks'"
        }
        gridTemplateColumns="56px 188px 90px 120px 1fr 130px 140px 100px"
        bg="gray4"
        width="100%"
        paddingY={4}
        borderRadius={2}>
        <GridItem gridArea="empty" />
        <GridItem gridArea="bidItem">
          <Text
            sizeText="display14"
            color="gray1"
            overflow="hidden"
            fontWeight="medium"
            marginLeft={5}>
            Partida
          </Text>
        </GridItem>
        <GridItem gridArea="unit">
          <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
            Unidades
          </Text>
        </GridItem>
        <GridItem gridArea="measurement">
          <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
            Medición
          </Text>
        </GridItem>
        <GridItem gridArea="description">
          <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
            Descripción
          </Text>
        </GridItem>
        <GridItem gridArea="price">
          <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
            Precio
          </Text>
        </GridItem>
        <GridItem gridArea="amount">
          <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
            Importe
          </Text>
        </GridItem>
        <GridItem gridArea="attachmentsAnd" />
      </Grid>
      {proposal?.bidItems?.map((element) => (
        <DetailProposalBidItem element={element} proposal={proposal} />
      ))}
      <Flex
        alignItems="center"
        paddingX={3}
        paddingY={3}
        width="100%"
        justifyContent="space-between">
        <Flex alignItems="center">
          <Text as="span" sizeText="display18" color="black" fontWeight="bold">
            Total
          </Text>
        </Flex>
        <Text as="span" sizeText="display18" color="black" fontWeight="bold">
          {parseToInteger(total).toLocaleString('de-DE', numberOptions)} €
        </Text>
      </Flex>
    </>
  )
}
