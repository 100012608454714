import { useState } from 'react'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { ArrowDown, ArrowRight, PlusCircle } from '@proveoeng/uikit/dist/atoms/Icons'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { ActionMenu } from '@proveoeng/uikit/dist/molecules/ActionsMenu/ActionMenu'

import { SectionBidItem } from './SectionBidItem'
import { SectionHead } from './SectionHead'
import { SectionTotals } from './SectionTotals'
import { AddItemModal } from './AddItemModal'
import { RemoveItem } from './RemoveItem'
import { DetailsModal } from './DetailsModal'
import { useComparative } from '../../../common/hooks/useComparative'

export const Section = ({
  data,
  addItem,
  setItem,
  setOfferItem,
  offers,
  removeItems,
  defaultSection,
  setRemoveSectionModalIsOpened,
  setEditSectionModalIsOpened,
  tableWidthCSS,
}) => {
  const [isOpened, setIsOpened] = useState(true)
  const [addBidItemModalIsOpened, setAddBidItemModalIsOpened] = useState(false)
  const [isBidItemDetailsModalOpened, setIsBidItemDetailsModalOpened] = useState({
    show: false,
    data: '',
  })
  const { getBidItemOfferDetails } = useComparative()
  const [itemsChecked, setItemsChecked] = useState([])
  const offerColumnsHead = offers
    .sort((a, b) => (a.position > b.position ? 1 : -1))
    .map((offer) => `offer-${offer.id}`)
    .join(' ')

  const columnsHead = {
    area: `"checkbox bidpackage unit measure budget expectedCosts minEnvelope ${offerColumnsHead}"`,
    columns: `30px 300px 80px 100px repeat(${offers.length + 3}, 220px)`,
  }

  const offerColumns = offers
    .sort((a, b) => (a.position > b.position ? 1 : -1))
    .map((offer) => `offer-unit-${offer.id} offer-total-${offer.id}`)
    .join(' ')

  const columns = {
    area: `"checkbox bidpackage unit measure budgetCost budget expectedCostsUnit expectedCosts minEnvelopeUnit minEnvelope ${offerColumns}"`,
    columns: `30px 300px 80px 100px 90px 130px 90px 130px 90px 130px repeat(${
      offers.length * 2
    }, 90px 130px)`,
  }

  const handleSelectedBidItems = (id, value) => {
    if (value) {
      setItemsChecked((prev) => [...prev, id])
    } else {
      setItemsChecked((prev) => prev.filter((item) => item !== id))
    }
  }

  const isDeleteButtonDisabled =
    data?.bidItems.length === itemsChecked.length && data.id === defaultSection

  const addBidItem = (item) => addItem(data.id, item)

  const removeBidItems = () => {
    removeItems(itemsChecked)
    setItemsChecked([])
  }

  const getBidItemOfferDetailsRequest = async (offer, offerItem) => {
    const { proposalId } = offer

    const proposalItem = offer?.offerItems.find((itemOffer) => itemOffer.itemId === offerItem.id)

    const bidItemOfferDetailsData = await getBidItemOfferDetails(
      proposalId,
      proposalItem.proposalItem,
    )

    setIsBidItemDetailsModalOpened({ show: true, data: bidItemOfferDetailsData })
  }

  const BidItemsSections = () =>
    data?.bidItems?.map((item) => (
      <SectionBidItem
        item={item}
        setItem={setItem}
        setOfferItem={setOfferItem}
        handleSelectedBidItems={handleSelectedBidItems}
        itemsChecked={itemsChecked}
        offers={offers}
        columns={columns}
        getBidItemOfferDetailsRequest={getBidItemOfferDetailsRequest}
      />
    ))

  return (
    <Flex
      paddingBottom={6}
      flexDirection="column"
      position="relative"
      style={{ width: tableWidthCSS }}>
      <Flex
        justifyContent="space-between"
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 11,
          backgroundColor: 'white',
        }}
        paddingBottom={4}>
        <Flex
          alignItems="center"
          paddingX={3}
          style={{
            position: 'sticky',
            left: 0,
            zIndex: 9,
          }}>
          <Icon
            style={{ cursor: 'pointer' }}
            sizeIcon="display18"
            color="black"
            marginRight={1}
            onClick={() => setIsOpened(!isOpened)}>
            {isOpened ? <ArrowDown /> : <ArrowRight />}
          </Icon>
          <Flex alignItems="center">
            <Text
              as="span"
              marginLeft={1}
              sizeText="display18"
              color="black"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setIsOpened(!isOpened)
                setItemsChecked([])
              }}>
              {data?.name || 'Comparativo'}
            </Text>
            <Button colorType="none" paddingX={4} paddingY={0}>
              <Icon
                style={{ cursor: 'pointer', fontSize: '18px' }}
                marginRight={1}
                onClick={() => {}}>
                <PlusCircle />
              </Icon>
              <Text
                color="gray1"
                sizeText="display14"
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => setAddBidItemModalIsOpened(true)}>
                Añadir partida
              </Text>
            </Button>
          </Flex>
        </Flex>
        <Flex
          alignItems="center"
          paddingX={3}
          style={{
            position: 'sticky',
            right: 0,
          }}>
          <Text as="span" sizeText="display14" color="black" paddingRight={4}>
            {data?.getNumberOfBidItems()}
          </Text>
          <ActionMenu
            actions={[
              {
                id: 'edit',
                isDisabled: false,
                content: (
                  <Text sizeText="display14" color="black" paddingTop={4}>
                    Editar nombre
                  </Text>
                ),
                action: () =>
                  setEditSectionModalIsOpened({
                    show: true,
                    id: data.id,
                    name: data.name,
                  }),
              },
              {
                id: 'delete',
                isDisabled: data.id === defaultSection,
                content: (
                  <Text sizeText="display14" color="red1" paddingBottom={4}>
                    Borrar sección
                  </Text>
                ),
                action: () => setRemoveSectionModalIsOpened({ show: true, id: data.id }),
              },
            ]}
          />
        </Flex>
      </Flex>
      <AddItemModal
        addBidItem={addBidItem}
        isOpen={addBidItemModalIsOpened}
        onClose={() => setAddBidItemModalIsOpened(false)}
      />
      <DetailsModal
        isOpen={isBidItemDetailsModalOpened.show}
        onClose={() => setIsBidItemDetailsModalOpened({ show: false, data: '' })}
        data={isBidItemDetailsModalOpened.data}
      />

      {isOpened && (
        <>
          <SectionHead offers={offers} columns={columns} columnsHead={columnsHead} />
          <RemoveItem
            itemsChecked={itemsChecked}
            removeBidItems={removeBidItems}
            isDeleteButtonDisabled={isDeleteButtonDisabled}
          />
          {BidItemsSections()}
          <SectionTotals data={data} offers={offers} columns={columns} />
        </>
      )}
    </Flex>
  )
}
