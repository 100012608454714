import { useCallback, useState } from 'react'

const expression =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
const regexUrl = new RegExp(expression)

/**
 * @param {Link[]} attachmentLinks
 */
export const useAttachmentLinks = (attachmentLinks = []) => {
  // all links with description
  const [links, setLinks] = useState(attachmentLinks)

  const [inputLink, setInputLink] = useState('')

  const [deleteLinksId, setDeleteLinksId] = useState([])

  const verifyUrl = (url) => url && url.match(regexUrl)

  const addLink = () => {
    if (!inputLink) return
    if (!verifyUrl(inputLink)) return
    setLinks((prevState) => [
      ...prevState,
      {
        url: inputLink,
        description: '',
      },
    ])
    setInputLink('')
  }

  const handleAddDescription = useCallback(
    (index, value) => {
      setLinks((prevState) =>
        prevState.map((item, key) => (key === index ? { ...item, description: value } : item)),
      )
    },
    [setLinks],
  )

  const removeLink = useCallback(
    (index, value) => {
      if (value.linkId) {
        setDeleteLinksId((prevState) => [...prevState, value.linkId])
      }
      setLinks((prevState) => prevState.filter((_, key) => key !== index))
    },
    [setLinks],
  )

  return {
    links,
    setLinks,
    inputLink,
    setInputLink,
    addLink,
    handleAddDescription,
    removeLink,
    deleteLinksId,
    verifyUrl,
    setDeleteLinksId,
  }
}
