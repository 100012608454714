import { constants } from '../config'

const initialState = {}

export default function setImagesReducer(state = initialState, action) {
  switch (action.type) {
    case constants.ADD_IMAGE: {
      return {
        ...state,
        [action.payload.id]: action.payload.thumb,
      }
    }

    case constants.REMOVE_IMAGE: {
      return {
        ...state,
        [action.payload.id]: null,
      }
    }

    default:
      return state
  }
}
