import { parseToInteger, parseToString } from '../../../../../lib/utils/Format'
import { FormatDate } from '../../../mappers/FormatDate'
import { BidItemMapper } from '../../bidPackages/mapper'

const valueToLabel = {
  Execution: 'Ejecución',
  Public: 'Público',
  Private: 'Privado',
  Study: 'Estudio',
}

const numberOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

export const BidPackageProposalMapper = {
  hydrate: ({
    bidPackageId,
    name,
    bidEndsAt,
    generalConditions,
    paymentDays,
    paymentConditions,
    retention,
    retentionCriteria,
    description,
    contact,
    links,
    deliveryDates,
  }) => ({
    bidPackageId,
    name,
    bidEndsAt,
    bidEndsAtFormatted: FormatDate.hydrate(bidEndsAt),
    generalConditions,
    paymentDays,
    paymentConditions,
    retention: (Number(retention) / 100).toLocaleString('de-DE', numberOptions) || 0,
    retentionCriteria: retentionCriteria || '-',
    description: description || '-',
    contact,
    links,
    start: FormatDate.hydrate(deliveryDates?.start) || null,
    end: FormatDate.hydrate(deliveryDates?.end) || null,
  }),
  dehydrate: ({
    bidPackageId,
    name,
    bidEndsAt,
    generalConditions,
    paymentDays,
    paymentConditions,
    retention,
    retentionCriteria,
    description,
  }) => ({
    bidPackageId,
    name,
    bidEndsAt,
    generalConditions,
    paymentDays,
    paymentConditions,
    retention: retention ? parseToInteger(retention) : null,
    retentionCriteria: retentionCriteria || '-',
    description: description || '-',
  }),
}

export const DetailProposalMapper = {
  hydrate: ({
    stateChangedAt,
    createdAt,
    deliveryDates,
    bidPackage,
    total,
    discount,
    totalDiscounted,
    paymentDays,
    paymentConditions,
    retention,
    retentionCriteria,
    project,
    ...rest
  }) => ({
    stateChangedAt,
    createdAt,
    start: FormatDate.hydrate(deliveryDates?.start) || null,
    end: FormatDate.hydrate(deliveryDates?.end) || null,
    discount: (Number(discount) / 100).toLocaleString('de-DE', numberOptions) || 0,
    total: (Number(total) / 100).toLocaleString('de-DE', numberOptions) || 0,
    totalDiscounted: (Number(totalDiscounted) / 100).toLocaleString('de-DE', numberOptions) || 0,
    stateChangedAtFormatted: FormatDate.hydrate(stateChangedAt),
    createdAtFormatted: FormatDate.hydrate(createdAt),
    paymentDays,
    paymentConditions,
    retention,
    retentionCriteria,
    project: {
      address: project?.address?.address,
      city: project?.address?.city,
      postCode: project?.address?.postCode,
      province: project?.address?.province,
      constructionTypeFormatted: valueToLabel[project?.constructionType],
      projectTypeFormatted: valueToLabel[project?.projectType],
      description: project?.description,
      files: project?.files,
      links: project?.links,
      name: project?.name,
    },
    ...rest,
  }),
  dehydrate: ({
    discount,
    acceptPaymentConditions,
    acceptRetentionConditions,
    modifyDeliveryTime,
    modifyDeliveryDates,
    paymentDays,
    paymentConditions,
    generalConditions,
    retention,
    retentionCriteria,
    comments,
    start,
    end,
    deliveryTime,
  }) => ({
    discount: parseToInteger(discount) || 0,
    paymentDays: acceptPaymentConditions && paymentDays ? null : Number(paymentDays) || null,
    paymentConditions:
      acceptPaymentConditions && paymentConditions ? null : paymentConditions || null,
    generalConditions: generalConditions || null,
    retention: acceptRetentionConditions && retention ? null : parseToInteger(retention),
    retentionCriteria:
      acceptRetentionConditions && retentionCriteria?.length ? null : retentionCriteria,
    deliveryDates: {
      start: modifyDeliveryDates && start?.length > 0 ? start : null,
      end: modifyDeliveryDates && end?.length > 0 ? end : null,
    },
    comments: comments || null,
    deliveryTime: modifyDeliveryTime ? deliveryTime : null,
  }),
}

export const ProposalItemMapper = {
  hydrate: ({
    unitaryPrice,
    totalPrice,
    discount,
    total,
    targetCosts,
    measurement,
    budgetDiff,
    budget,
    bidItem,
    ...rest
  }) => ({
    unitaryPrice: (Number(unitaryPrice || 0) / 100).toLocaleString('de-DE', numberOptions) || 0,
    totalPrice: (Number(totalPrice || 0) / 100).toLocaleString('de-DE', numberOptions) || 0,
    total: (Number(total || 0) / 100).toLocaleString('de-DE', numberOptions) || 0,
    measurement: parseToString(measurement || 0, 3),
    budgetDiff: (Number(budgetDiff || 0) / 100).toLocaleString('de-DE', numberOptions) || 0,
    budget: (Number(budget || 0) / 100).toLocaleString('de-DE', numberOptions) || 0,
    targetCosts: (Number(targetCosts || 0) / 100).toLocaleString('de-DE', numberOptions) || 0,
    discount: (Number(discount || 0) / 100).toLocaleString('de-DE', numberOptions) || 0,
    bidItem: (bidItem && BidItemMapper.hydrate(bidItem)) || {},
    ...rest,
  }),
  dehydrate: ({ unitaryPrice, proposalItemId, comments, ...rest }) => ({
    unitaryPrice: parseToInteger(unitaryPrice) || 0,
    comments: comments || null,
    ...rest,
  }),
}

export const ProposalBidPackageMapper = {
  hydrate: ({
    total,
    expectedCosts,
    expectedCostsDiff,
    k,
    budgetDiff,
    proposalItems,
    budget,
    targetCosts,
    discount,
    ...rest
  }) => ({
    total: (Number(total || 0) / 100).toLocaleString('de-DE', numberOptions) || 0,
    expectedCosts: (Number(expectedCosts || 0) / 100).toLocaleString('de-DE', numberOptions) || 0,
    expectedCostsDiff:
      (Number(expectedCostsDiff || 0) / 100).toLocaleString('de-DE', numberOptions) || 0,
    k: (Number(k || 0) / 100).toLocaleString('de-DE', numberOptions) || 0,
    budgetDiff: (Number(budgetDiff || 0) / 100).toLocaleString('de-DE', numberOptions) || 0,
    proposalItems: proposalItems?.map((item) => ProposalItemMapper.hydrate(item)) || [],
    budget: (Number(budget || 0) / 100).toLocaleString('de-DE', numberOptions) || 0,
    targetCosts: (Number(targetCosts || 0) / 100).toLocaleString('de-DE', numberOptions) || 0,
    discount: (Number(discount || 0) / 100).toLocaleString('de-DE', numberOptions) || 0,
    ...rest,
  }),
}

export const ProposalEnviroMapper = {
  hydrate: ({ distanceToBuildingSite, recycledMaterials, comments, files, links }) => ({
    distanceToBuildingSite: parseToString(distanceToBuildingSite),
    recycledMaterials: parseToString(recycledMaterials),
    commentsEnviro: comments ?? null,
    filesEnviro: files ?? [],
    linksEnviro: links ?? [],
  }),
  dehydrate: ({ distanceToBuildingSite, recycledMaterials, commentsEnviro }) => ({
    distanceToBuildingSite: parseToInteger(distanceToBuildingSite),
    recycledMaterials: parseToInteger(recycledMaterials),
    comments: commentsEnviro ?? null,
  }),
}

export const ProposalItemEnviroMapper = {
  hydrate: ({ comments, dataQuality, eCo2, files, links, proposalItemId }) => ({
    commentsEnviro: comments ?? null,
    dataQuality: dataQuality ?? null,
    eCo2: parseToString(eCo2),
    filesEnviro: files ?? [],
    linksEnviro: links ?? [],
    proposalItemId: proposalItemId ?? null,
  }),
  dehydrate: ({ eCo2, dataQuality, comments }) => ({
    eCo2: parseToInteger(eCo2),
    dataQuality: dataQuality ?? null,
    comments: comments ?? null,
  }),
}
