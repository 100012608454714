import { constants } from '../config'

/* **** setSignUp *** */
export const setSignUp = (body) => ({
  type: constants.SET_AUTH,
  body,
})

export const setSignUpSuccess = (data) => ({
  type: constants.SET_AUTH_SUCCESS,
  payload: data,
})

/* **** checkLogin *** */
export const checkLogin = (body) => ({
  type: constants.CHECK_LOGIN,
  body,
})

export const checkLoginSuccess = (data) => ({
  type: constants.CHECK_LOGIN_SUCCESS,
  payload: data,
})

/* **** getInvitation *** */
export const getInvitation = (id) => ({
  type: constants.GET_INVITATION,
  id,
})
export const getInvitationSuccess = (data) => ({
  type: constants.GET_INVITATION_SUCCESS,
  payload: data,
})

/* **** setInvitation *** */
export const setInvitation = (id, body) => ({
  type: constants.SET_INVITATION,
  id,
  body,
})
export const setInvitationSuccess = (data) => ({
  type: constants.SET_INVITATION_SUCCESS,
  payload: data,
})
/* **** setRestorePassword *** */
export const setRestorePassword = (isAuth, key, body) => ({
  type: constants.SET_RESTORE_PASSWORD,
  isAuth,
  key,
  body,
})

/* **** cancel Invitation *** */
export const cancelInvitation = (id) => ({
  type: constants.CANCEL_INVITATION,
  id,
})
export const cancelInvitationSuccess = () => ({
  type: constants.CANCEL_INVITATION_SUCCESS,
})

export const setStore = (data) => ({
  type: constants.SET_STORE_AUTH,
  payload: data,
})

export const authFails = (error) => ({
  type: constants.AUTH_FAIL,
  error,
})
