import { useState } from 'react'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'
import { useForms } from '../../../common/hooks/forms'

let formData
export const AddSectionModal = ({ isOpen = false, onClose, handleAddSection }) => {
  const { values, handleChange, resetValues } = useForms()
  const [errorsData, setErrorsData] = useState({ init: true })
  const onValidate = () => {
    const formValidation = ['sectionName']
    if (errorsData.init) {
      setErrorsData({})
    }

    let errors = {}

    formValidation.map((key) => {
      if (values[key]?.length || values[key] === true) {
        setErrorsData((prev) => ({
          ...prev,
          [key]: false,
        }))
        errors = {
          ...errors,
          [key]: false,
        }
      } else {
        setErrorsData((prev) => ({
          ...prev,
          [key]: true,
        }))

        errors = {
          ...errors,
          [key]: true,
        }
      }
      return false
    })

    if (!Object.values(errors)?.includes(true)) {
      resetValues()
      return true
    }

    return false
  }

  const handleClose = () => {
    onClose()
    setErrorsData({})
  }

  const handleSubmit = () => {
    if (onValidate()) {
      handleAddSection({ title: values.sectionName })
      handleClose()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      id="add-section-modal"
      onModalClose={handleClose}
      closeWithButton
      title="Añadir sección"
      maxWidth="570px">
      <Modal.Content>
        <form
          ref={(form) => {
            formData = form
          }}
          onSubmit={handleSubmit}>
          <Flex flexDirection="column">
            <Flex width="100%" flexDirection="column">
              <Flex width="100%" marginRight={2}>
                <Flex flexDirection="column" width="100%">
                  <Field
                    label="Nombre de la sección"
                    required
                    sizeText="display16"
                    marginBottom={3}
                    marginTop={5}>
                    <Input
                      required
                      placeholderMessage="Dale un nombre a tu sección"
                      action={() => handleChange(formData)}
                      name="sectionName"
                      hasError={errorsData?.sectionName}
                    />
                  </Field>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </form>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="center">
          <Flex width="100%">
            <Button
              id="cancel-button"
              fullWidth
              marginRight={5}
              colorType="transparent"
              action={handleClose}
              type="button">
              Cancelar
            </Button>
            <Button fullWidth colorType="orange" action={handleSubmit} type="submit">
              Crear nueva sección
            </Button>
          </Flex>
        </Flex>
      </Modal.Actions>
    </Modal>
  )
}
