import { useParams } from 'react-router-dom'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

const typeErrors = {
  403: {
    code: '403',
    title: 'Acceso no permitido',
    description:
      'No tienes acceso para ver este proceso de compra. Por favor contacta a la persona que creó la obra.',
  },
  404: {
    code: '404',
    title: 'Página no encontrada',
    description: 'La página que estás buscando no existe.',
  },
}

export const ErrorPage = () => {
  const { error } = useParams()

  const errorData = typeErrors[error] || typeErrors[403]

  return (
    <Flex justifyContent="center" alignItems="center" height="100%" flexDirection="column">
      <Text as="h1" fontWeight="bold" sizeText="display32" mb={4}>
        {errorData.title}
      </Text>
      <Text>{errorData.description}</Text>
    </Flex>
  )
}
