/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Card } from '@proveoeng/uikit/dist/atoms/Card'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'

import { Sidebar } from '../Sidebar/Sidebar'
import { useMenuItems } from '../../hooks/useMenuItems'

export const Layout = ({ children }) => {
  const employee = useSelector((state) => state.employee?.data)
  const auth = useSelector((state) => state.auth?.data)

  const menuItemsRole = useMenuItems()

  const menuItems = menuItemsRole[employee?.type?.toLowerCase() || 'buyer']

  return (
    <Flex overflow="hidden">
      <Sidebar
        src={employee?.avatar}
        placeholder={auth.username}
        menuItems={menuItems}
        employee={employee}
        data={auth}
        width={214}
      />
      <Flex
        backgroundColor="white"
        width="100%"
        height="100vh"
        maxHeight="calc(100vh - 15px)"
        borderTopLeftRadius={2}
        borderBottomLeftRadius={2}
        position="relative"
        overflow="auto">
        <Card
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          width="100%"
          height="100%"
          paddingY={7}
          paddingX={9}
          borderColor="white"
          overflow="scroll">
          {children}
        </Card>
      </Flex>
    </Flex>
  )
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
}

Layout.defaultProps = {
  location: {
    pathname: '',
  },
}
