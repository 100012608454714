export const constants = {
  SET_AUTH: 'SET_AUTH',
  SET_AUTH_SUCCESS: 'SET_AUTH_SUCCESS',
  CHECK_LOGIN: 'CHECK_LOGIN',
  CHECK_LOGIN_SUCCESS: 'CHECK_LOGIN_SUCCESS',
  GET_INVITATION: 'GET_INVITATION',
  GET_INVITATION_SUCCESS: 'GET_INVITATION_SUCCESS',
  SET_INVITATION: 'SET_INVITATION',
  SET_INVITATION_SUCCESS: 'SET_INVITATION_SUCCESS',
  SET_RESTORE_PASSWORD: 'SET_RESTORE_PASSWORD',
  SET_STORE_AUTH: 'SET_STORE_AUTH',
  AUTH_FAIL: 'AUTH_FAIL',
  CANCEL_INVITATION: 'CANCEL_INVITATION',
  CANCEL_INVITATION_SUCCESS: 'CANCEL_INVITATION_SUCCESS',
}

export const services = {
  setSignUp: (data) => ({
    route: `/signup/seller`,
    method: 'post',
    body: {
      ...data,
    },
  }),
  authCheck: (data) => ({
    route: `/auth_check`,
    method: 'post',
    body: {
      ...data,
    },
  }),
  getInvitation: (id) => ({
    route: `/invitations/${id}`,
    method: 'get',
  }),
  setInvitation: (id, data) => ({
    route: `/invitations/${id}`,
    method: 'post',
    body: {
      ...data,
    },
  }),
  cancelInvitation: (id) => ({
    route: `/invitations/${id}`,
    method: 'delete',
  }),
  setResetPassword: (email) => ({
    route: `/account/${email}/restore_password`,
    method: 'post',
  }),
  setResetPasswordAuth: (authCode, body) => ({
    route: `/account/restore_password`,
    method: 'post',
    body: {
      authCode,
      ...body,
    },
  }),
  authPreCheck: (data) => ({
    route: `/signup/precheck`,
    method: 'post',
    body: data,
  }),
}
