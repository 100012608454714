import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { OfferDetailTemplate } from '../template/OfferDetailTemplate'

import { useBidPackage } from '../../../../common/hooks/useBidPackage'
import { useProposal } from '../../../../common/hooks/useProposal'

import { parseEditorMessage } from '../../../../../lib/utils/parseEditorMessage'
import { useProject } from '../../../../common/hooks/useProject'
import { useUtils } from '../../../../common/hooks/useUtils'
import { useHelpers } from '../../../../common/hooks/useHelpers'
import { parseToInteger } from '../../../../../lib/utils/Format'

const OfferDetailPage = () => {
  const history = useHistory()
  const { hash, offerId, bidPackageId } = useParams()

  const [activeTab, setActiveTab] = useState(1)
  const [sendModalIsOpen, setSendModalIsOpen] = useState(false)
  const [detailsIsOpened, setDetailsIsOpened] = useState(false)
  const [environmentalStatement, setEnvironmentalStatement] = useState(false)
  const { companyId: buyerCompanyId } = useSelector((state) => state.employee?.data)

  const {
    data: { proposalsBySeller },
    getProposalsBySeller,
  } = useProposal()

  const {
    project: {
      bidPackage: { proposalSelected },
    },
  } = useSelector((state) => state.projects?.data)

  const {
    data: { email },
  } = useSelector((state) => state.employee)

  const {
    data: { project },
    getProject,
  } = useProject()

  const {
    data: { seller },
    getSellerByCompany,
  } = useHelpers()

  const { proposalItems } = useSelector((state) => state.proposals?.data)
  const {
    getProposalItems,
    exportProposal,
    enviro: { getProposalItemsEnviro, getProposalEnviro },
  } = useProposal()
  const { setAlert } = useUtils()

  const {
    data: { bidPackage },
    proposals: { getBidPackageProposal },
    getBidPackage,
    getMessagesList,
    messagesList,
    sendMessage,
  } = useBidPackage()

  const exportProject = () =>
    exportProposal(
      proposalSelected?.proposalId,
      `Oferta descargada - ${proposalSelected?.sellerName} - ${project?.name}, ${bidPackage?.name}.xlsx`,
    )

  useEffect(() => {
    if (offerId && bidPackageId) {
      getBidPackageProposal(bidPackageId, offerId)
      if (activeTab === 1) {
        getProposalItems(offerId)
      }
      if (activeTab === 2) {
        getProposalItemsEnviro(offerId)
      }
      if (activeTab === 4) {
        getEnvironmentalStatementData()
      }
    }
  }, [offerId, bidPackageId, activeTab])

  const getEnvironmentalStatementData = async () => {
    setEnvironmentalStatement(await getProposalEnviro(proposalSelected?.proposalId))
  }

  useEffect(() => {
    if (bidPackageId) {
      getBidPackage(bidPackageId)
    }
  }, [bidPackageId])

  useEffect(() => {
    getMessagesList(offerId)
  }, [])

  useEffect(() => {
    if (hash) getProject(hash)
  }, [hash])

  const totalEnviro = proposalItems.reduce(
    (previousValue, currentValue) =>
      previousValue +
      (parseToInteger(currentValue?.eCo2) * parseToInteger(currentValue?.bidItem?.measurement, 3)) /
        1000,
    0,
  )

  const tabItems = [
    {
      id: 'info',
      children: <>Información de la oferta</>,
      isActive: activeTab === 0,
      action: () => setActiveTab(0),
    },
    {
      id: 'proposalBidItems',
      children: <>Partidas</>,
      isActive: activeTab === 1,
      action: () => setActiveTab(1),
    },
    {
      id: 'emissions',
      children: <>Emisiones</>,
      isActive: activeTab === 2,
      action: () => setActiveTab(2),
    },
    {
      id: 'messages',
      children: <>Mensajes</>,
      isActive: activeTab === 3,
      action: () => setActiveTab(3),
    },

    {
      id: 'environmentalStatement',
      children: <>Declaración ambiental</>,
      isActive: activeTab === 4,
      action: () => setActiveTab(4),
    },
  ]

  const handleOpenDetail = async () => {
    if (!proposalSelected?.sellerCompanyId) return
    const resp = await getSellerByCompany(proposalSelected?.sellerCompanyId)
    if (resp) {
      await getProposalsBySeller(proposalSelected?.sellerCompanyId, buyerCompanyId)
      setDetailsIsOpened(true)
    }
  }

  const handleSendMessage = async ({ message }) => {
    const messageParsed = parseEditorMessage(message)
    if (!messageParsed || messageParsed?.trim().length - 7 < 5) {
      setAlert('error', 'El mensaje debe tener al menos 5 caracteres.')
    } else {
      await sendMessage(offerId, {
        message: messageParsed,
        parentMessageId: null,
      })
      getMessagesList(offerId)
    }
    setSendModalIsOpen(false)
  }

  return (
    <OfferDetailTemplate
      history={history}
      bidPackageSelected={bidPackage}
      project={project}
      proposalSelected={proposalSelected}
      exportProject={exportProject}
      proposalItems={proposalItems}
      tabItems={tabItems}
      activeTab={activeTab}
      handleSendMessage={handleSendMessage}
      sendModalIsOpen={sendModalIsOpen}
      setSendModalIsOpen={setSendModalIsOpen}
      messagesList={messagesList}
      employeeEmail={email}
      setDetailsIsOpened={setDetailsIsOpened}
      detailsIsOpened={detailsIsOpened}
      proposalsBySeller={proposalsBySeller}
      seller={seller}
      handleOpenDetail={handleOpenDetail}
      totalEnviro={totalEnviro}
      environmentalStatement={environmentalStatement}
    />
  )
}

export default OfferDetailPage
