import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useEmployee } from '../../../../common/hooks/useEmployee'
import { ProfileUserTemplate } from '../template/ProfileUserTemplate'

export const ProfileUserPage = () => {
  const employee = useSelector((state) => state.employee?.data)

  const [profileImage, setProfileImage] = useState({
    name: '',
    base64: '',
    file: null,
  })

  const { setEmployee, setEmployeeAvatar } = useEmployee()

  useEffect(() => {
    if (employee?.avatar) setProfileImage({ base64: employee?.avatar })
  }, [employee?.avatar])

  const handleSubmitEmployee = async (values) => {
    await setEmployee(values)
    if (profileImage.file) {
      await setEmployeeAvatar({ file: profileImage.file })
    }
  }

  const onProfileImageChange = (imgs) => setProfileImage(imgs[0])

  return (
    <ProfileUserTemplate
      employee={employee}
      handleSubmitEmployee={handleSubmitEmployee}
      profileImage={profileImage}
      onProfileImageChange={onProfileImageChange}
    />
  )
}
