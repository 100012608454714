import { showAlert } from '../../../states/alerts'
import { constants } from '../config'

export const comparativeActions = (dispatch) => {
  const setComparative = (data) => dispatch({ type: constants.CREATE, payload: data })
  const addSection = (section) => {
    const addSectionRes = dispatch({ type: constants.ADD_SECTION, payload: section })

    dispatch(
      showAlert('success', 1, 'Has creado una nueva sección.'),
      setTimeout(() => {
        dispatch(showAlert('', -1, ''))
      }, 5000),
    )

    return addSectionRes
  }
  const addItem = (sectionId, item) => {
    const addItemRes = dispatch({
      type: constants.ADD_ITEM,
      payload: { sectionId, item },
    })

    dispatch(
      showAlert('success', 1, 'Has añadido una partida nueva.'),
      setTimeout(() => {
        dispatch(showAlert('', -1, ''))
      }, 5000),
    )

    return addItemRes
  }
  const editSection = (section) => {
    const editSectionRes = dispatch({ type: constants.EDIT_SECTION, payload: section })

    dispatch(
      showAlert('success', 1, 'Has cambiado el nombre de la sección.'),
      setTimeout(() => {
        dispatch(showAlert('', -1, ''))
      }, 5000),
    )

    return editSectionRes
  }

  const setItem = (item) => {
    const updateItemRes = dispatch({ type: constants.UPDATE_ITEM, payload: item })

    dispatch(
      showAlert('success', 1, 'La partida ha sido editada.'),
      setTimeout(() => {
        dispatch(showAlert('', -1, ''))
      }, 5000),
    )

    return updateItemRes
  }

  const setOfferItem = (offerItem) =>
    dispatch({ type: constants.UPDATE_OFFER_ITEM, payload: offerItem })

  const removeSection = (sectionId) => {
    const removeSectionRes = dispatch({
      type: constants.REMOVE_SECTION,
      payload: { sectionId },
    })

    dispatch(
      showAlert('success', 1, 'Se ha borrado la sección correctamente.'),
      setTimeout(() => {
        dispatch(showAlert('', -1, ''))
      }, 5000),
    )

    return removeSectionRes
  }
  const removeItems = (itemIds) => {
    const removeItemsRes = dispatch({
      type: constants.REMOVE_ITEMS,
      payload: { itemIds },
    })

    dispatch(
      showAlert('success', 1, 'Has borrado la/s partida/s.'),
      setTimeout(() => {
        dispatch(showAlert('', -1, ''))
      }, 5000),
    )

    return removeItemsRes
  }

  const updateImprovementRate = (value) =>
    dispatch({
      type: constants.UPDATE_IMPROVEMENT_RATE,
      payload: value,
    })

  const exportComparative = ({ electedSeller, electionReason }) =>
    dispatch({
      type: constants.EXPORT,
      payload: { electedSeller, electionReason },
    })

  return {
    setComparative,
    addSection,
    addItem,
    editSection,
    setItem,
    setOfferItem,
    removeSection,
    removeItems,
    updateImprovementRate,
    exportComparative,
  }
}
