import { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { NotificationAlert } from '@proveoeng/uikit/dist/molecules/NotificationAlert/NotificationAlert'
import { Loader } from '@proveoeng/uikit/dist/atoms/Loader'

import { OwnerRoutes } from './cli/config/routes'
import { Layout } from './cli/common/components/Layout'
import { getLocalStorage } from './cli/common/functions/cookies'
import { setStore } from './cli/common/schemes/auth'
import { TokenMapper } from './cli/common/schemes/auth/mapper'
// import { useCompany } from './cli/common/hooks/useCompany'
import { useManageEmployees } from './cli/common/hooks/useManageEmployees'

const ProveoApp = ({ location: { pathname }, ...props }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  window.intl = intl
  const alert = useSelector((state) => state.alert)
  const auth = useSelector((state) => state.auth)
  const {
    data: { type },
  } = useSelector((state) => state.employee)
  const loading = useSelector((state) => state.loading)

  const setStoreAuthAPI = useCallback(
    (data) => {
      dispatch(setStore(data))
    },
    [dispatch],
  )

  // const { getCompany } = useCompany()

  const { getEmployee } = useManageEmployees()

  useEffect(() => {
    if (
      !!auth?.data &&
      getLocalStorage(process?.env?.REACT_APP_COOKIE_LOGIN_NAME || env?.REACT_APP_COOKIE_LOGIN_NAME)
    ) {
      setStoreAuthAPI(
        TokenMapper.hydrate({
          token: getLocalStorage(
            process?.env?.REACT_APP_COOKIE_LOGIN_NAME || env?.REACT_APP_COOKIE_LOGIN_NAME,
          ),
        }),
      )
      getEmployee()
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    if (
      getLocalStorage(
        process?.env?.REACT_APP_COOKIE_LOGIN_NAME || env?.REACT_APP_COOKIE_LOGIN_NAME,
      ) &&
      ((type === 'Buyer' && pathname?.includes('/proveedor')) ||
        (type === 'Seller' && pathname?.includes('/constructora')))
    )
      history.push('/')
  }, [type, pathname])

  return (
    <Layout {...props}>
      <OwnerRoutes
        type={type}
        auth={getLocalStorage(
          process?.env?.REACT_APP_COOKIE_LOGIN_NAME || env?.REACT_APP_COOKIE_LOGIN_NAME,
        )}
      />
      <NotificationAlert
        isOpenedAlert={alert?.show}
        type={alert?.colorType}
        message={alert?.message}
      />
      {loading?.show ? <Loader /> : null}
    </Layout>
  )
}

export default ProveoApp

ProveoApp.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape().isRequired,
}
