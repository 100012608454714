import { Flex, Box, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Image } from '@proveoeng/uikit/dist/atoms/Image/Image'
import { LinkText } from '@proveoeng/uikit/dist/atoms/Typography/Typography'
import { InputPassword } from '@proveoeng/uikit/dist/atoms/InputPassword'
import Logo from '../../../common/assets/logo'

// color logos
import logo1 from '../../../common/assets/register/color/logo1.png'
import logo2 from '../../../common/assets/register/color/logo2.png'
import logo3 from '../../../common/assets/register/color/logo3.png'
import logo4 from '../../../common/assets/register/color/logo4.png'
import logo5 from '../../../common/assets/register/color/logo5.png'

// b&w logos
import logoSeller1 from '../../../common/assets/register/bandw/logoSeller1.png'
import logoSeller2 from '../../../common/assets/register/bandw/logoSeller2.png'
import logoSeller3 from '../../../common/assets/register/bandw/logoSeller3.png'
import logoSeller4 from '../../../common/assets/register/bandw/logoSeller4.png'
import logoSeller5 from '../../../common/assets/register/bandw/logoSeller5.png'
import logoSeller6 from '../../../common/assets/register/bandw/logoSeller6.png'
import logoSeller7 from '../../../common/assets/register/bandw/logoSeller7.png'

export const RegisterSellerTemplate = ({
  values,
  handleChange,
  onSubmit,
  errors,
  isValid,
  handleBlur,
  touched,
}) => (
  <Grid>
    <Text
      as="a"
      href="https://www.conkau.io/#proveedor"
      sizeText="display14"
      color="gray"
      style={{ position: 'absolute', top: '24px', left: '24px', margin: 0 }}>
      {'< Volver a conkau.io'}
    </Text>
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      paddingY={10}
      paddingX={9}>
      <Grid
        gridTemplateColumns="60% 40%"
        gridTemplateAreas={'"info form"'}
        backgroundColor="white"
        borderRadius="8px"
        width="100%"
        maxWidth="1300px"
        style={{ boxShadow: '0px 8px 16px rgba(97, 44, 5, 0.05)', borderRadius: '8px' }}>
        <GridItem
          gridArea="info"
          backgroundColor="rgba(244, 217, 197, .2)"
          borderRadius="8px 0 0 8px">
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            width="100%"
            paddingY={7}
            paddingX={9}>
            <Logo width="277px" />
            <Text sizeText="display24" color="black" fontWeight="medium" marginTop={5}>
              Vende tus servicios y materiales de construcción a las mejores constructoras del país
            </Text>
            <Text sizeText="display18" color="black" fontWeight="regular" marginTop={2}>
              Regístrate y comienza a conseguir más ventas y clientes. <br />
              Es gratis, sencillo y eficiente.
            </Text>
            <Flex flexDirection="column" width="100%">
              <Text sizeText="display18" color="black" fontWeight="medium" marginTop={8}>
                Formamos parte del mejor ecosistema ConTech
              </Text>
              <Flex marginTop={4} flexWrap="wrap">
                <Image
                  src={logo1}
                  alt="logo EU"
                  height={32}
                  width={49}
                  marginRight={5}
                  styleImg={{ objectFit: 'contain' }}
                />
                <Image
                  src={logo2}
                  alt="logo Gob"
                  height={32}
                  width={108}
                  marginRight={5}
                  styleImg={{ objectFit: 'contain' }}
                />
                <Image
                  src={logo5}
                  alt="logo Ptec"
                  height={32}
                  width={84}
                  styleImg={{ objectFit: 'contain' }}
                />
              </Flex>
              <Flex flexWrap="wrap">
                <Image
                  src={logo3}
                  alt="logo Metabuilding"
                  height={32}
                  width={187}
                  marginRight={5}
                  marginTop={5}
                  styleImg={{ objectFit: 'contain' }}
                />
                <Image
                  src={logo4}
                  alt="logo Adigital"
                  height={32}
                  width={127}
                  marginRight={5}
                  marginTop={5}
                  styleImg={{ objectFit: 'contain' }}
                />
              </Flex>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem gridArea="form" backgroundColor="white" borderRadius="0 8px 8px 0">
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            width="100%"
            paddingY={7}
            paddingX={9}>
            <Text sizeText="display40" color="black" fontWeight="bold" marginBottom={5}>
              Regístrate
            </Text>
            <Box as="form" onSubmit={onSubmit} width="100%">
              <Field
                errorMessage={touched.email && errors?.email}
                label="Email"
                sizeText="display16"
                marginBottom={3}>
                <Input
                  placeholderMessage="Email"
                  name="email"
                  type="email"
                  ariaLabel="email"
                  value={values.email}
                  onChange={handleChange}
                  hasError={touched.email && !!errors?.email}
                  onBlur={handleBlur}
                />
              </Field>
              <Field
                errorMessage={touched.password && errors?.password}
                label="Contraseña"
                sizeText="display16"
                marginBottom={3}
                marginTop={5}>
                <InputPassword
                  placeholderMessage="Introduce tu contraseña"
                  value={values.password}
                  onChange={handleChange}
                  name="password"
                  ariaLabel="contraseña"
                  hasError={touched.password && !!errors?.password}
                  onBlur={handleBlur}
                />
              </Field>
              <Flex
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                marginTop={7}>
                <Button fullWidth type="submit" colorType="orange" disabled={!isValid}>
                  Crear cuenta
                </Button>
                <Text as="span" sizeText="display14" color="black" marginTop={5}>
                  Ya tienes una cuenta?{' '}
                  <LinkText
                    href="/login"
                    color="blue1"
                    sizeText="display14"
                    marginTop={6}
                    style={{ textDecoration: 'underline' }}>
                    Iniciar sesión desde aquí
                  </LinkText>
                </Text>
              </Flex>
            </Box>
          </Flex>
        </GridItem>
      </Grid>
      <Flex
        flexDirection="column"
        paddingY={7}
        paddingLeft={[0, 0, 0, 10]}
        width="100%"
        maxWidth="1300px">
        <Text as="span" sizeText="display18" color="black" marginBottom={4}>
          Ellos (y cientos más) ya confían en nosotros
        </Text>
        <Flex width="100%" flexWrap="wrap">
          <Image
            src={logoSeller1}
            alt="logo seller 1"
            height={32}
            width={70}
            marginRight={5}
            style={{ opacity: 0.4 }}
            styleImg={{ objectFit: 'contain' }}
          />
          <Image
            src={logoSeller2}
            alt="logo seller 2"
            height={32}
            width={110}
            marginRight={5}
            style={{ opacity: 0.4 }}
            styleImg={{ objectFit: 'contain' }}
          />
          <Image
            src={logoSeller3}
            alt="logo seller 3"
            height={32}
            width={116}
            marginRight={5}
            style={{ opacity: 0.4 }}
            styleImg={{ objectFit: 'contain' }}
          />
          <Image
            src={logoSeller4}
            alt="logo seller 4"
            height={32}
            width={61}
            marginRight={5}
            style={{ opacity: 0.4 }}
            styleImg={{ objectFit: 'contain' }}
          />
          <Image
            src={logoSeller5}
            alt="logo seller 5"
            height={32}
            width={68}
            marginRight={5}
            style={{ opacity: 0.4 }}
            styleImg={{ objectFit: 'contain' }}
          />
          <Image
            src={logoSeller6}
            alt="logo seller 6"
            height={32}
            width={219}
            marginRight={5}
            style={{ opacity: 0.4 }}
            styleImg={{ objectFit: 'contain' }}
          />
          <Image
            src={logoSeller7}
            alt="logo seller 7"
            height={32}
            width={286}
            style={{ opacity: 0.4 }}
            styleImg={{ objectFit: 'contain' }}
          />
        </Flex>
      </Flex>
    </Flex>
  </Grid>
)
