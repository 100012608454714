import { useEffect, useState } from 'react'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import ArrowDown from '@proveoeng/uikit/dist/atoms/Icons/ArrowDown'
import ArrowRight from '@proveoeng/uikit/dist/atoms/Icons/ArrowRight'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout/Layout'
import { ButtonTabs } from '@proveoeng/uikit/dist/atoms/Button/Button'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal/Modal'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Textarea } from '@proveoeng/uikit/dist/atoms/Textarea/Textarea'
import { InputNumber } from '@proveoeng/uikit/dist/atoms/InputNumber'

import { currencyFormat, parseToInteger, parseToString } from '../../../../../lib/utils/Format'
import { AttachmentList } from '../../../../common/components/attachmentList/Attachment'
import { Links } from '../../../../common/components/links/Links'

export const DetailProposalBidItem = ({ element }) => {
  const [whoIsActive, setWhoIsActive] = useState('description')
  const [isOpened, setIsOpened] = useState(false)
  const [isModalFilesIsOpened, setIsModalFilesIsOpened] = useState(false)
  const [unitaryPrice, setUnitaryPrice] = useState(element?.unitaryPrice || '')

  useEffect(() => {
    if (element?.unitaryPrice) setUnitaryPrice(parseToString(parseToInteger(element?.unitaryPrice)))
  }, [element])

  return (
    <>
      <Grid
        gridTemplateAreas={
          "'bidItem unit measurement description price amount attachmentsAndLinks' 'sections sections sections sections sections sections sections'"
        }
        gridTemplateColumns="244px 90px 120px 1fr 130px 140px 100px"
        paddingY={5}
        width="100%"
        borderBottomColor="gray4"
        borderBottomStyle="solid"
        borderBottomWidth="1px">
        <Box display="none">
          <Input
            placeholderMessage=""
            ariaLabel="proposalItemId"
            action={() => {}}
            defaultValue={element?.proposalItemId}
            name="proposalItemId"
          />
        </Box>
        <GridItem
          gridArea="bidItem"
          display="flex"
          style={{ alignItems: 'flex-start', marginRight: '20px' }}>
          <Flex alignItems="center" paddingX={3}>
            <Icon
              style={{ cursor: 'pointer' }}
              sizeIcon="display18"
              color="black"
              marginRight={4}
              onClick={() => setIsOpened(!isOpened)}>
              {isOpened ? <ArrowDown /> : <ArrowRight />}
            </Icon>
            <Flex alignItems="center">
              <Text
                as="span"
                marginLeft={1}
                sizeText="display14"
                color="black"
                fontWeight="regular"
                style={{ cursor: 'pointer' }}
                onClick={() => setIsOpened(!isOpened)}>
                {element?.bidItem?.name}
              </Text>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem
          gridArea="unit"
          display="flex"
          style={{ alignItems: 'flex-start', marginRight: '20px' }}>
          <Text sizeText="display14" color="gray1">
            {element?.bidItem?.unit}
          </Text>
        </GridItem>
        <GridItem
          gridArea="measurement"
          display="flex"
          style={{ alignItems: 'flex-start', marginRight: '20px' }}>
          <Text sizeText="display14" color="gray1">
            {element?.bidItem?.measurement}
          </Text>
        </GridItem>
        <GridItem
          gridArea="description"
          display="flex"
          style={{ alignItems: 'flex-start', marginRight: '20px' }}>
          <Text
            sizeText="display14"
            color="gray1"
            style={{
              whiteSpace: 'pre-wrap',
            }}>
            {element?.bidItem?.description || '-'}
          </Text>
        </GridItem>
        <GridItem
          gridArea="price"
          display="flex"
          style={{ alignItems: 'flex-start', marginRight: '20px' }}>
          <InputNumber
            required
            placeholderMessage="0.00"
            ariaLabel="unitaryPrice"
            onChange={() => {}}
            value={unitaryPrice}
            name="unitaryPrice"
            disabled
          />
        </GridItem>
        <GridItem
          gridArea="amount"
          display="flex"
          style={{ alignItems: 'flex-start', marginRight: '20px' }}>
          <Text sizeText="display14" color="gray1">
            {currencyFormat(element?.total)}
          </Text>
        </GridItem>
        <GridItem
          gridArea="attachmentsAndLinks"
          display="flex"
          style={{ alignItems: 'flex-start' }}>
          {(!!element?.comments || !!element?.files?.length || !!element?.links?.length) && (
            <Text
              color="blue1"
              textAlign="right"
              sizeText="display14"
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => setIsModalFilesIsOpened(true)}>
              Ver detalles
            </Text>
          )}
        </GridItem>
        <GridItem gridArea="sections" display="flex" style={{ alignItems: 'center' }}>
          <Flex
            padding={3}
            flexDirection="column"
            alignItems="baseline"
            width="100%"
            style={{ display: isOpened ? 'flex' : 'none' }}>
            <Flex paddingX={2} flexDirection="column" alignItems="baseline" paddingY={2}>
              <ButtonTabs
                items={[
                  {
                    id: 'description',
                    children: <>Descripción</>,
                    isActive: whoIsActive === 'description',
                    action: (_, target) => setWhoIsActive(target.id),
                  },
                  {
                    id: 'attachments',
                    children: <>Adjuntos</>,
                    isActive: whoIsActive === 'attachments',
                    action: (_, target) => setWhoIsActive(target.id),
                  },
                  {
                    id: 'comments',
                    children: <>Comentario</>,
                    isActive: whoIsActive === 'comments',
                    action: (_, target) => setWhoIsActive(target.id),
                  },
                  element?.bidItem?.links?.length
                    ? {
                        id: 'links',
                        children: <>Enlaces externos</>,
                        isActive: whoIsActive === 'links',
                        action: (_, target) => setWhoIsActive(target.id),
                      }
                    : {},
                ]}
              />
              {whoIsActive === 'description' && (
                <Text
                  sizeText="display14"
                  marginY={2}
                  marginLeft={2}
                  style={{
                    whiteSpace: 'pre-wrap',
                  }}>
                  {element?.bidItem?.description || '-'}
                </Text>
              )}
              {whoIsActive === 'attachments' && (
                <>
                  {element?.bidItem?.files?.length ? (
                    <AttachmentList files={element?.bidItem?.files} withDownload />
                  ) : (
                    <Flex marginY={3} flexWrap="wrap">
                      <Text
                        sizeText="display14"
                        color="gray1"
                        overflow="hidden"
                        fontWeight="medium"
                        marginLeft={5}>
                        No hay archivos adjuntos
                      </Text>
                    </Flex>
                  )}
                </>
              )}
              {whoIsActive === 'comments' && (
                <Text
                  sizeText="display14"
                  marginY={2}
                  marginLeft={2}
                  style={{
                    whiteSpace: 'pre-wrap',
                  }}>
                  {element?.bidItem?.comments || '-'}
                </Text>
              )}
              {whoIsActive === 'links' && (
                <>
                  {!element?.bidItem?.links?.length ? (
                    <Flex marginY={3} flexWrap="wrap">
                      <Text
                        sizeText="display14"
                        color="gray1"
                        overflow="hidden"
                        fontWeight="medium"
                        marginLeft={5}>
                        No hay enlaces externos
                      </Text>
                    </Flex>
                  ) : (
                    <Links links={element?.bidItem?.links} />
                  )}
                </>
              )}
            </Flex>
          </Flex>
        </GridItem>
        <Modal
          isOpen={isModalFilesIsOpened}
          id="proposal-items-files"
          onModalClose={() => setIsModalFilesIsOpened(false)}
          title="Detalles de la partida"
          closeWithButton
          maxWidth="808px">
          <Modal.Content>
            <Box margin="0 auto" overflow="auto" width="100%">
              <Flex marginBottom={3} width="100%" flexDirection="column">
                <Field
                  label="Aclaraciones sobre la partida"
                  required
                  sizeText="display16"
                  marginBottom={3}
                  marginTop={5}>
                  <Textarea
                    placeholderMessage="Escribe las aclaraciones aquí"
                    name="comments"
                    defaultValue={element?.comments}
                    maxLength="auto"
                    disabled
                  />
                </Field>
              </Flex>
              <AttachmentList files={element?.files} withDownload />
              <Links links={element?.links} />
            </Box>
          </Modal.Content>
          <Modal.Actions>
            <Flex justifyContent="flex-end">
              <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr">
                <Button
                  colorType="orange"
                  action={() => {
                    setIsModalFilesIsOpened(false)
                  }}>
                  Cerrar
                </Button>
              </Grid>
            </Flex>
          </Modal.Actions>
        </Modal>
      </Grid>
    </>
  )
}
