export const catalogMapper = {
  hydrate({ name, description, eCo2, reference, unit }) {
    return {
      name,
      description,
      eCo2,
      reference,
      unit,
    }
  },
}
