import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { ResetPasswordTemplate } from '../template/ResetPasswordTemplate'
import { deleteLocalStorage } from '../../../common/functions/cookies'
import { useAuth } from '../../../common/hooks/useAuth'

const initialValues = {
  newPassword: '',
  email: '',
}

const validationSchemaEmail = Yup.object().shape({
  email: Yup.string().required('Debe ingresar un email').email('Debe ingresar un email válido'),
})

const validationSchemaPassword = Yup.object().shape({
  newPassword: Yup.string()
    .required('Debe ingresar una contraseña')
    .min(6, 'Debe tener al menos 6 caracteres')
    .max(80, 'Debe tener máximo 80 caracteres'),
})

const ResetPasswordPage = () => {
  const { hash } = useParams()

  const { setRestorePassword, setRestorePasswordAuth } = useAuth()

  useEffect(() => {
    deleteLocalStorage(process.env.REACT_APP_COOKIE_LOGIN_NAME || env.REACT_APP_COOKIE_LOGIN_NAME)
  }, [])

  const onSubmit = (values) => {
    if (hash) {
      setRestorePasswordAuth(hash, values)
    } else {
      setRestorePassword(values?.email)
    }
  }

  const { values, handleChange, handleSubmit, handleBlur, touched, errors, isValid } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: hash ? validationSchemaPassword : validationSchemaEmail,
  })

  return (
    <ResetPasswordTemplate
      onSubmit={handleSubmit}
      errors={errors}
      values={values}
      handleChange={handleChange}
      hash={hash}
      isValid={isValid}
      handleBlur={handleBlur}
      touched={touched}
    />
  )
}
export default ResetPasswordPage
