import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'

import { ProjectTemplate } from '../template/ProjectTemplate'

import { useBidPackage } from '../../../../common/hooks/useBidPackage'
import { useHelpers } from '../../../../common/hooks/useHelpers'
import { useProject } from '../../../../common/hooks/useProject'

import { ModalImportBidPackage } from '../../../../common/components/previewImport/components/ModalImportBidPackage'
import { ModalUploadImport } from '../../../../common/components/uploadImport/ModalUploadImport'
import { onImagesLoad } from '../../../../common/functions/data'
import { useAttachmentLinks } from '../../../../common/hooks/useAttachmentLinks'
import { useUtils } from '../../../../common/hooks/useUtils'
import { CreateBidPackage } from '../components/CreateBidPackages'

import { errorsImages } from '../../config'
import { CreateBidPackageAttachment } from '../components/CreateBidPackagesAttachment'

const initialValues = {
  name: '',
  categoryId: '',
  description: '',
  bidEndsAt: '',
  start: '',
  end: '',
  retention: '',
  paymentConditions: '',
  paymentDays: '',
  generalConditions: '',
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('El nombre es requerido')
    .min(3, 'El nombre debe tener al menos 3 caracteres')
    .max(50, 'El nombre debe tener máximo 50 caracteres'),
  categoryId: Yup.string().required('La naturaleza es requerida'),
  description: Yup.string(),
  bidEndsAt: Yup.date()
    .required('La fecha límite para presentar oferta es requerida')
    .min(new Date(), 'La fecha límite para presentar oferta no puede ser anterior al día de hoy.'),
  start: Yup.date()
    // .required('La fecha de inicio de entrega es requerida')
    .min(new Date(), 'La fecha de inicio de entrega no puede ser anterior al día de hoy.'),
  end: Yup.date()
    // .required('La fecha de fin de entrega es requerida')
    .min(
      Yup.ref('start'),
      'La fecha de fin de entrega no puede ser anterior a la fecha de inicio de entrega.',
    ),
  retention: Yup.string(),
  paymentConditions: Yup.string(),
  paymentDays: Yup.string().required('Los días de pago son requeridos'),
  generalConditions: Yup.string(),
})

// Refactored
const ProjectPage = () => {
  const history = useHistory()
  const { hash } = useParams()

  const { employeeId } = useSelector((state) => state.employee?.data)

  const {
    data: { bidPackages, project },
    getProject,
    exportProject,
    setImportBidPackages,
    getNewBidPackageId,
    setPreviewBidPackages,
  } = useProject()

  const {
    createBidPackage,
    setFilesBidPackage,
    setLinksBidPackage,
    bidItems: { createBidItems },
  } = useBidPackage()

  const {
    data: { categories },
    getCategories,
  } = useHelpers()

  const { setAlert } = useUtils()

  const {
    links,
    inputLink,
    setLinks,
    setInputLink,
    addLink,
    handleAddDescription,
    removeLink,
    verifyUrl,
    setDeleteLinksId,
  } = useAttachmentLinks([])

  const [activeTab, setActiveTab] = useState(1)
  const [formIsOpened, setFormIsOpened] = useState(false)
  const [files, setFiles] = useState([])

  const [previewModalIsOpened, setPreviewModalIsOpened] = useState(false)
  const [importModalIsOpened, setImportModalIsOpened] = useState(false)
  const [previewData, setPreviewData] = useState({})
  const [createdBidPackageId, setCreatedBidPackageId] = useState()

  useEffect(() => {
    const loadCategoryData = async () => {
      await getCategories()
    }
    loadCategoryData()
  }, [])

  useEffect(() => {
    const loadProjectData = async () => {
      await getProject(hash)
    }
    if (hash) {
      loadProjectData()
    }
  }, [hash])

  useEffect(() => {
    setCreatedBidPackageId(null)
  }, [formIsOpened])

  const tabItems = [
    {
      id: 'info',
      children: <>Información de la obra</>,
      isActive: activeTab === 0,
      action: () => setActiveTab(0),
    },
    {
      id: 'bidPackages',
      children: <>Compras</>,
      isActive: activeTab === 1,
      action: () => setActiveTab(1),
    },
  ]

  const goToBidPackage = (bidPackageId) =>
    history.push(`/constructora/tus-obras/${project.projectId}/${bidPackageId}/partidas`)

  const goToEditProject = () => history.push(`/constructora/crear-obra/nombre/${project.projectId}`)

  const openImportModal = () => {
    setFiles([])
    setImportModalIsOpened(true)
  }

  const handleExportProject = () => exportProject(project)

  const onSubmit = async (values, formikHelpers) => {
    const bidPackageId = await createBidPackage({
      ...values,
      projectId: project.projectId,
      employeeId,
    })
    setLinks([])
    setDeleteLinksId([])
    setCreatedBidPackageId(bidPackageId)
    formikHelpers.resetForm()
    await getProject(project.projectId)
  }

  const { values, handleSubmit, setFieldValue, errors, handleBlur, handleChange, touched } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit,
    })

  const onSubmitFiles = async () => {
    await setFilesBidPackage(createdBidPackageId, files)
    await setLinksBidPackage(createdBidPackageId, links)
    setFormIsOpened(false)
  }

  const onDeleteFile = (file) => {
    const updateFiles = file?.fileId
      ? files.filter((element) => element.fileId !== file.fileId)
      : files.filter((element) => element.name !== file.name)
    setFiles(updateFiles)
  }

  /**
   *
   * @param {object} bidPackage
   * @param {Preview[]} bidItems
   */
  const onSubmitPreview = async (bidPackage, bidItems) => {
    const bidPackageId = await createBidPackage({
      ...bidPackage,
      employeeId,
      projectId: project.projectId,
    })
    console.log({ bidItems })
    if (bidPackageId) {
      const body = bidItems.map((item) => ({
        name: item.summary,
        description: item.description,
        reference: item.code,
        unit: item.unit,
        budget: item.priceCi,
        expectedCosts: item.price,
        measurement: item.measurement,
        eCo2: item.eCo2,
      }))
      await createBidItems(bidPackageId, body)
      await getProject(project.projectId)
    }
  }

  const importFromXlsx = async (file) => {
    const oldBidPackages = bidPackages
    await setImportBidPackages(project.projectId, file)
    const newBidPackages = (await getProject(project.projectId)).bidPackages
    setFiles([])
    if (!newBidPackages || newBidPackages.length - oldBidPackages.length !== 1) return
    const newBidPackageId = getNewBidPackageId(oldBidPackages, newBidPackages)
    if (newBidPackageId) goToBidPackage(newBidPackageId)
  }

  const importFromPreview = async (file) => {
    const data = await setPreviewBidPackages(project.projectId, file)
    if (data) {
      setPreviewData(data)
      setPreviewModalIsOpened(true)
    }
  }

  const onSubmitBidPackageImport = async () => {
    const file = [files.at(-1)]
    const excelExtension = ['xlsx', 'xls', 'csv']
    const extension = `${file[0].name.split('.').pop()}`
    if (extension === 'bc3') {
      await importFromPreview(file)
      return
    }
    if (excelExtension.includes(extension)) {
      await importFromXlsx(file)
      return
    }

    setAlert('error', 'Tipo de documento no soportado')
  }

  const categoriesOptions = categories?.data?.map(({ categoryId, name }) => ({
    value: categoryId,
    label: name,
  }))

  return (
    <>
      <ProjectTemplate
        history={history}
        project={project}
        exportProject={handleExportProject}
        tabItems={tabItems}
        activeTab={activeTab}
        bidPackages={bidPackages}
        goToBidPackage={goToBidPackage}
        setFormIsOpened={setFormIsOpened}
        openImportModal={openImportModal}
        goToEditProject={goToEditProject}
      />

      {formIsOpened && !createdBidPackageId && (
        <CreateBidPackage
          setFormIsOpened={setFormIsOpened}
          onSubmit={handleSubmit}
          values={values}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          categoriesOptions={categoriesOptions}
        />
      )}

      {formIsOpened && createdBidPackageId && (
        <CreateBidPackageAttachment
          setFormIsOpened={setFormIsOpened}
          onDeleteFile={onDeleteFile}
          errorsImages={errorsImages}
          onSubmitFiles={onSubmitFiles}
          links={links}
          inputLink={inputLink}
          setInputLink={setInputLink}
          addLink={addLink}
          handleAddDescription={handleAddDescription}
          removeLink={removeLink}
          verifyUrl={verifyUrl}
          files={files}
          onImagesLoad={(imgs) => onImagesLoad(imgs, setFiles, files)}
        />
      )}

      <ModalUploadImport
        isOpened={importModalIsOpened}
        setIsOpened={setImportModalIsOpened}
        onImagesLoad={(imgs) => onImagesLoad(imgs, setFiles, files)}
        files={files}
        onSubmit={onSubmitBidPackageImport}
      />

      <ModalImportBidPackage
        isOpened={previewModalIsOpened}
        setIsOpened={setPreviewModalIsOpened}
        categoriesData={categories?.data}
        onSubmit={onSubmitPreview}
        data={previewData}
      />
    </>
  )
}

export default ProjectPage
