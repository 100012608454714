export const constants = {
  GET_PROPOSAL: 'GET_PROPOSAL',
  GET_PROPOSAL_SUCCESS: 'GET_PROPOSAL_SUCCESS',
  UPDATE_PROPOSAL: 'UPDATE_PROPOSAL',
  UPDATE_PROPOSAL_SUCCESS: 'UPDATE_PROPOSAL_SUCCESS',
  GET_PROPOSAL_ITEMS: 'GET_PROPOSAL_ITEMS',
  GET_PROPOSAL_ITEMS_SUCCESS: 'GET_PROPOSAL_ITEMS_SUCCESS',
  GET_PROPOSAL_ITEM: 'GET_PROPOSAL_ITEM',
  GET_PROPOSAL_ITEM_SUCCESS: 'GET_PROPOSAL_ITEM_SUCCESS',
  UPDATE_PROPOSAL_ITEM: 'UPDATE_PROPOSAL_ITEM',
  UPDATE_PROPOSAL_ITEM_SUCCESS: 'UPDATE_PROPOSAL_ITEM_SUCCESS',
  CREATE_ATTACHMENT_PROPOSAL: 'CREATE_ATTACHMENT_PROPOSAL',
  CREATE_ATTACHMENT_PROPOSAL_SUCCESS: 'CREATE_ATTACHMENT_PROPOSAL_SUCCESS',
  DELETE_ATTACHMENT_PROPOSAL: 'DELETE_ATTACHMENT_PROPOSAL',
  DELETE_ATTACHMENT_PROPOSAL_SUCCESS: 'DELETE_ATTACHMENT_PROPOSAL_SUCCESS',
  CREATE_ATTACHMENT_PROPOSAL_ITEM: 'CREATE_ATTACHMENT_PROPOSAL_ITEM',
  CREATE_ATTACHMENT_PROPOSAL_ITEM_SUCCESS: 'CREATE_ATTACHMENT_PROPOSAL_ITEM_SUCCESS',
  CREATE_ATTACHMENT_PROPOSAL_BID_PACKAGE_SUCCESS: 'CREATE_ATTACHMENT_PROPOSAL_BID_PACKAGE_SUCCESS',
  DELETE_ATTACHMENT_PROPOSAL_ITEM: 'DELETE_ATTACHMENT_PROPOSAL_ITEM',
  DELETE_ATTACHMENT_PROPOSAL_ITEM_SUCCESS: 'DELETE_ATTACHMENT_PROPOSAL_ITEM_SUCCESS',
  ACTION_PROPOSAL: 'ACTION_PROPOSAL',
  ACTION_PROPOSAL_SUCCESS: 'ACTION_PROPOSAL_SUCCESS',
  SET_PROPOSAL_BID_PACKAGE_SUCCESS: 'SET_PROPOSAL_BID_PACKAGE_SUCCESS',
  SET_STORE_PROPOSAL: 'SET_STORE_PROPOSAL',
  RESET_STORE_PROPOSAL: 'RESET_STORE_PROPOSAL',
  PROPOSAL_FAIL: 'PROPOSAL_FAIL',
  CANCEL_PROPOSAL: 'CANCEL_PROPOSAL',
  CANCEL_PROPOSAL_SUCCESS: 'CANCEL_PROPOSAL_SUCCESS',
  REQUEST_IMPROVEMENT_PROPOSAL: 'REQUEST_IMPROVEMENT_PROPOSAL',
  REQUEST_IMPROVEMENT_PROPOSAL_SUCCESS: 'REQUEST_IMPROVEMENT_PROPOSAL_SUCCESS',
  MANAGE_ATTACHEMENT_PROPOSAL_ITEMS: 'MANAGE_ATTACHEMENT_PROPOSAL_ITEMS',
  MANAGE_ATTACHEMENT_PROPOSAL: 'MANAGE_ATTACHEMENT_PROPOSAL',
  MANAGE_ATTACHEMENT_PROPOSAL_SUCCESS: 'MANAGE_ATTACHEMENT_PROPOSAL_SUCCESS',
  MANAGE_ATTACHEMENT_PROPOSAL_ITEMS_SUCCESS: 'MANAGE_ATTACHEMENT_PROPOSAL_ITEMS_SUCCESS',
  MANAGE_LINK_PROPOSAL_ITEMS_SUCCESS: 'MANAGE_LINK_PROPOSAL_ITEMS_SUCCESS',
  MANAGE_LINK_PROPOSAL_SUCCESS: 'MANAGE_LINK_PROPOSAL_SUCCESS',
  MANAGE_LINK_PROPOSAL: 'MANAGE_LINK_PROPOSAL',
}

export const services = {
  getProposal: (proposalId) => ({
    route: `/proposals/${proposalId}`,
    method: 'get',
  }),
  updateProposal: (proposalId, body) => ({
    route: `/proposals/${proposalId}`,
    method: 'post',
    body,
  }),
  getProposalItems: (proposalId) => ({
    route: `/proposals/${proposalId}/proposal-items`,
    method: 'get',
  }),
  getProposalItem: (proposalId, proposalItemId) => ({
    route: `/proposals/${proposalId}/proposal-items/${proposalItemId}`,
    method: 'get',
  }),
  updateProposalItem: (proposalId, proposalItemId, body) => ({
    route: `/proposals/${proposalId}/proposal-items/${proposalItemId}`,
    method: 'post',
    body,
  }),
  createAttachmentProposal: (proposalId, fileId, body) => ({
    route: `/proposals/${proposalId}/files/${fileId}`,
    method: 'post',
    headers: {
      accept: '*/*',
    },
    formData: true,
    body,
  }),
  createAttachmentItemProposal: (proposalId, proposalItemId, fileId, body) => ({
    route: `/proposals/${proposalId}/proposal-items/${proposalItemId}/files/${fileId}`,
    method: 'post',
    headers: {
      accept: '*/*',
    },
    formData: true,
    body,
  }),
  deleteAttachmentProposal: (proposalId, fileId) => ({
    route: `/proposals/${proposalId}/files/${fileId}`,
    method: 'delete',
  }),
  deleteAttachmentItemProposal: (proposalId, proposalItemId, fileId) => ({
    route: `/proposals/${proposalId}/proposal-items/${proposalItemId}/files/${fileId}`,
    method: 'delete',
  }),
  createLinkProposal: (proposalId, linkId, body) => ({
    route: `/proposals/${proposalId}/links/${linkId}`,
    method: 'post',
    body,
  }),
  createLinkItemProposal: (proposalId, proposalItemId, linkId, body) => ({
    route: `/proposals/${proposalId}/proposal-items/${proposalItemId}/links/${linkId}`,
    method: 'post',
    body,
  }),
  deleteLinkProposal: (proposalId, linkId) => ({
    route: `/proposals/${proposalId}/links/${linkId}`,
    method: 'delete',
  }),
  deleteLinkItemProposal: (proposalId, proposalItemId, linkId) => ({
    route: `/proposals/${proposalId}/proposal-items/${proposalItemId}/links/${linkId}`,
    method: 'delete',
  }),
  actionProposal: (proposalId, body) => ({
    route: `/proposals/${proposalId}/action`,
    method: 'post',
    body,
  }),
  exportProposal: (proposalId) => ({
    route: `/proposal/${proposalId}/export?format=xlsx`,
    method: 'get',
    responseType: 'arraybuffer',
    headers: {
      accept: '*/*',
    },
    formData: true,
  }),
  exportProposalPrices: (proposalId) => ({
    route: `/proposal/${proposalId}/prices/download`,
    method: 'get',
    responseType: 'arraybuffer',
    headers: {
      accept: '*/*',
    },
    formData: true,
  }),
  importProposal: (proposalId, body) => ({
    route: `/proposal/${proposalId}/prices/upload`,
    method: 'post',
    responseType: 'arraybuffer',
    headers: {
      accept: '*/*',
    },
    formData: true,
    body,
  }),
  proposalsbySeller: (sellerId, buyerId) => ({
    route: `/proposals/from/${sellerId}/for/${buyerId}`,
    method: 'get',
  }),
  getMessagesList: (proposalId) => ({
    route: `/proposals/${proposalId}/messages`,
    method: 'get',
  }),
  sendMessage: (proposalId, messageId, body) => ({
    route: `/proposals/${proposalId}/messages/${messageId}`,
    method: 'put',
    body,
  }),
  // Enviro
  getProposalEnviro: (proposalId) => ({
    route: `/enviro/proposals/${proposalId}`,
    method: 'get',
  }),
  getProposalItemsEnviro: (proposalId) => ({
    route: `/enviro/proposals/${proposalId}/proposal-items`,
    method: 'get',
  }),
  getProposalItemEnviro: (proposalId, proposalItemId) => ({
    route: `/enviro/proposals/${proposalId}/proposal-items/${proposalItemId}`,
    method: 'get',
  }),
  updateProposalEnviro: (proposalId, body) => ({
    route: `/enviro/proposals/${proposalId}`,
    method: 'post',
    body,
  }),
  updateProposalItemEnviro: (proposalId, proposalItemId, body) => ({
    route: `/enviro/proposals/${proposalId}/proposal-items/${proposalItemId}`,
    method: 'post',
    body,
  }),
  createFileProposalEnviro: (proposalId, fileId, body) => ({
    route: `/enviro/proposals/${proposalId}/files/${fileId}`,
    method: 'post',
    headers: {
      accept: '*/*',
    },
    formData: true,
    body,
  }),
  createFileProposalItemEnviro: (proposalId, proposalItemId, fileId, body) => ({
    route: `/enviro/proposals/${proposalId}/proposal-items/${proposalItemId}/files/${fileId}`,
    method: 'post',
    headers: {
      accept: '*/*',
    },
    formData: true,
    body,
  }),
  deleteFileProposalEnviro: (proposalId, fileId) => ({
    route: `/enviro/proposals/${proposalId}/files/${fileId}`,
    method: 'delete',
  }),
  deleteFileProposalItemEnviro: (proposalId, proposalItemId, fileId) => ({
    route: `/enviro/proposals/${proposalId}/proposal-items/${proposalItemId}/files/${fileId}`,
    method: 'delete',
  }),
  createLinkProposalEnviro: (proposalId, linkId, body) => ({
    route: `/enviro/proposals/${proposalId}/links/${linkId}`,
    method: 'post',
    body,
  }),
  createLinkProposalItemEnviro: (proposalId, proposalItemId, linkId, body) => ({
    route: `/enviro/proposals/${proposalId}/proposal-items/${proposalItemId}/links/${linkId}`,
    method: 'post',
    body,
  }),
  deleteLinkProposalEnviro: (proposalId, linkId) => ({
    route: `/enviro/proposals/${proposalId}/links/${linkId}`,
    method: 'delete',
  }),
  deleteLinkProposalItemEnviro: (proposalId, proposalItemId, linkId) => ({
    route: `/enviro/proposals/${proposalId}/proposal-items/${proposalItemId}/links/${linkId}`,
    method: 'delete',
  }),
}
