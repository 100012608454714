import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { useAuth } from '../../../common/hooks/useAuth'
import { useHelpers } from '../../../common/hooks/useHelpers'
import { registerSellerSchemas } from '../../../common/validationSchemes/registerSellerScheme'
import { RegisterTemplate } from '../template/RegisterTemplate'

const initialValues = {
  name: '',
  phone: '',
  email: '',
  password: '',
  companyName: '',
  taxCode: '',
  address: '',
  city: '',
  postCode: '',
  billingName: '',
  billingAddress: '',
  billingCity: '',
  billingPostCode: '',
  isSameBillingData: true,
  categories: [],
}

export const RegisterPage = () => {
  const history = useHistory()
  const { hash } = useParams()

  const {
    data: { email, type },
  } = useSelector((state) => state.auth)

  const TotalSteps = type?.toLowerCase() === 'offer' ? 2 : 3

  const emailInvitation = hash && email ? email : null

  const [step, setStep] = useState(0)

  const { setSignUp, preCheck, setInvitation } = useAuth()

  const {
    data: {
      categories: { data: categories },
    },
    getCategories,
  } = useHelpers()

  useEffect(() => {
    if (type?.toLowerCase() !== 'offer') getCategories()
  }, [])

  const goBack = () => setStep((prevState) => prevState - 1)

  const goNext = () => {
    if (step < TotalSteps - 1) setStep((prevState) => prevState + 1)
  }

  const onSubmit = async (values, formikHelpers) => {
    // If is not the last step, go to next step
    if (step < TotalSteps - 1) {
      formikHelpers.setTouched({})
      goNext()
      return
    }

    // Else call the sign up action
    try {
      if (hash) {
        await setInvitation({ id: hash, body: values })
      } else {
        await setSignUp({ body: values })
      }
      history.push('/proveedor/compras-nuevas')
      // eslint-disable-next-line no-empty
    } catch {}
  }

  // Using Formik context
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={registerSellerSchemas[step]}>
      <Form>
        <RegisterTemplate
          step={step}
          totalSteps={TotalSteps}
          goBack={goBack}
          preCheck={preCheck}
          emailInvitation={emailInvitation}
          categories={categories}
        />
      </Form>
    </Formik>
  )
}
