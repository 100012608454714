import { useState, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Box, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { InputPassword } from '@proveoeng/uikit/dist/atoms/InputPassword'

import { FormContainer } from '../styles/RegisterStyles'

/**
 * @param {Object} props
 * @param {void} props.preCheck
 * @param {string | null} props.emailInvitation
 */
export const RegisterDataBasic = ({ preCheck = () => undefined, emailInvitation }) => {
  const { values, errors, handleBlur, touched, handleChange, setErrors, setFieldValue } =
    useFormikContext()

  const [isLoadingEmail, setIsLoadingEmail] = useState(false)

  useEffect(() => {
    if (emailInvitation) {
      setFieldValue('email', emailInvitation)
    }
  }, [emailInvitation])

  const checkEmailOnBlur = async (e) => {
    handleBlur(e)
    setIsLoadingEmail(true)
    const isCheck = await preCheck({ email: values.email })
    if (!isCheck) setErrors({ email: 'El email ya está registrado' })
    setIsLoadingEmail(false)
  }

  return (
    <FormContainer>
      <GridItem gridArea="description" marginBottom={4}>
        <Text sizeText="display18" color="black" fontWeight="bold" marginBottom={2}>
          Tus datos
        </Text>
        <Text sizeText="display14" color="gray" textAlign="justify">
          Estos datos servirán para poder acceder a la plataforma y ver las oferta disponibles.
        </Text>
      </GridItem>
      <GridItem gridArea="fields">
        <Box>
          <Field
            label="Nombre"
            required
            sizeText="display16"
            marginBottom={3}
            errorMessage={touched.name && errors.name}>
            <Input
              type="text"
              placeholderMessage="Escribe el nombre de la compañia"
              ariaLabel="name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              hasError={touched.name && !!errors.name}
            />
          </Field>
        </Box>
        <Box>
          <Field
            label="Telefono"
            required
            sizeText="display16"
            marginBottom={3}
            errorMessage={touched.phone && errors.phone}>
            <Input
              type="tel"
              placeholderMessage="Escribe tu teléfono "
              ariaLabel="phone"
              name="phone"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              hasError={touched.phone && !!errors.phone}
            />
          </Field>
        </Box>
        <Box>
          <Field
            label="Email"
            required
            sizeText="display16"
            marginBottom={3}
            errorMessage={touched.email && errors.email}>
            <Input
              disabled={!!emailInvitation || isLoadingEmail}
              type="email"
              placeholderMessage="Escribe tu email"
              ariaLabel="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={checkEmailOnBlur}
              hasError={touched.email && !!errors.email}
            />
          </Field>
        </Box>
        <Box>
          <Field
            label="Contraseña"
            required
            sizeText="display16"
            marginBottom={3}
            marginTop={5}
            errorMessage={touched.password && errors.password}>
            <InputPassword
              name="password"
              placeholderMessage="Escribe una nueva contraseña"
              ariaLabel="contraseña"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              hasError={touched.password && !!errors.password}
            />
          </Field>
        </Box>
      </GridItem>
    </FormContainer>
  )
}
