import { useState } from 'react'

/**
 * @typedef {Object} Meta
 * @property {number} total
 * @property {number} size
 * @property {number} page
 */

/**
 * @typedef {Object} Filter
 * @property {string | number | Array<string | number>} value
 * @property {string} operator
 */

/**
 * @typedef {Object} Filters
 * @property {Record<string, Filter>}
 */

/**
 * @typedef {Object} Sort
 * @property {string} field
 * @property {'asc' | 'desc'} operator
 */

export const Operators = {
  eq: 'eq',
  neq: 'neq',
  gt: 'gt',
  gte: 'gte',
  lt: 'lt',
  lte: 'lte',
  in: 'in',
  not_in: 'not_in',
  is_null: 'is_null',
  is_not_null: 'is_not_null',
  starts_with: 'starts_with',
  ends_with: 'ends_with',
  contains: 'contains',
}

/**
 *
 * @param {Object} props
 * @param {Meta} props.meta
 * @param {number} props.defaultPage
 * @param {number} props.defaultLimit
 * @param {Sort} props.defaultSort
 *
 * @returns
 */
export const usePagination = (
  { defaultPage, defaultLimit, defaultSort } = {
    defaultPage: 1,
    defaultLimit: 10,
    defaultSort: {
      field: 'created_at',
      operator: 'desc',
    },
  },
) => {
  const [page, setPage] = useState(defaultPage)
  const [limit, setLimit] = useState(defaultLimit)
  const [sort, setSort] = useState(defaultSort)
  const [filters, setFilters] = useState({})

  const handleNextPage = () => {
    setPage((prevState) => prevState + 1)
  }

  const handlePrevPage = () => {
    if (page > 1) setPage((prevState) => prevState - 1)
  }

  const handlePageChange = (page) => setPage(page)

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage)
    setPage(page)
  }

  const reset = () => {
    setPage(defaultPage)
    setLimit(defaultLimit)
    setSort(defaultSort)
    setFilters({})
  }

  /**
   *
   * @param {Filter} filter
   */
  const handleAddFilter = (field, filter) => {
    setFilters((prevState) => ({ ...prevState, [field]: filter }))
  }

  const handleRemoveFilter = (field) =>
    setFilters((prevState) => {
      const { [field]: filterToRemove, ...rest } = prevState
      return rest
    })

  const handleSort = (field, operator) => {
    setSort({ field, operator })
  }

  return {
    params: {
      page,
      filters,
      limit,
      sort,
    },
    handleNextPage,
    handlePrevPage,
    handlePageChange,
    handlePerRowsChange,
    handleAddFilter,
    handleRemoveFilter,
    handleSort,
    reset,
  }
}
