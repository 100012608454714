import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { ArrowDown, ArrowRight } from '@proveoeng/uikit/dist/atoms/Icons'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'
import { useState } from 'react'

import { AttachmentList } from '../../../../common/components/attachmentList/Attachment'
import { Links } from '../../../../common/components/links/Links'

const EmissionsDetailsWrapper = ({ title, children }) => {
  const [isOpened, setIsOpened] = useState(true)

  return (
    <Box paddingBottom={4}>
      <Flex alignItems="center">
        <Icon
          style={{ cursor: 'pointer' }}
          sizeIcon="display18"
          color="black"
          marginRight={4}
          onClick={() => setIsOpened(!isOpened)}>
          {isOpened ? <ArrowDown /> : <ArrowRight />}
        </Icon>
        <Flex alignItems="center">
          <Text
            as="span"
            marginLeft={1}
            sizeText="display18"
            color="black"
            fontWeight="medium"
            style={{ cursor: 'pointer' }}
            onClick={() => setIsOpened(!isOpened)}>
            {title}
          </Text>
        </Flex>
      </Flex>
      {isOpened && children}
    </Box>
  )
}

const FilesAndLinks = ({ files, links }) => {
  const hasFiles = !!files?.length
  const hasLinks = !!links?.length

  return (
    <Box paddingLeft={7} paddingTop={3}>
      {!hasFiles && !hasLinks && (
        <Text sizeText="display14" color="gray1" fontWeight="regular" marginBottom={4}>
          No hay ficheros o enlaces externos
        </Text>
      )}
      {hasFiles && <AttachmentList files={files} withDownload />}
      {hasLinks && <Links links={links} />}
    </Box>
  )
}

export const ModalEmissionsDetails = ({ isOpen, setIsOpen, comments, files = [], links = [] }) => (
  <>
    <Modal
      isOpen={isOpen}
      id="details-emissions-modal"
      onModalClose={() => setIsOpen(false)}
      closeWithButton
      minWidth="300px"
      width="570px"
      title="Detalles de la partida">
      <Modal.Content>
        <EmissionsDetailsWrapper title="Aclaraciones sobre las emisiones de CO2">
          <Box paddingLeft={7} paddingTop={3}>
            <Text
              sizeText="display14"
              color={comments ? 'black' : 'gray1'}
              fontWeight="medium"
              marginBottom={4}>
              {comments || 'No hay aclaraciones.'}
            </Text>
          </Box>
        </EmissionsDetailsWrapper>
        <EmissionsDetailsWrapper title="Ficheros y enlaces externos">
          <FilesAndLinks files={files} links={links} />
        </EmissionsDetailsWrapper>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Box width="184px">
            <Button fullWidth colorType="transparent" action={() => setIsOpen(false)}>
              Hecho
            </Button>
          </Box>
        </Flex>
      </Modal.Actions>
    </Modal>
  </>
)
