import { Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

export const EnvironmentalCostsSectionHead = ({ offers, columnsHead, columns }) => (
  <>
    <Grid gridTemplateAreas={columnsHead.area} gridTemplateColumns={columnsHead.columns}>
      <GridItem
        gridArea="bidItem"
        bg="gray4"
        paddingX={2}
        paddingY={4}
        style={{
          position: 'sticky',
          left: 0,
          zIndex: 9,
        }}>
        <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
          Partida
        </Text>
      </GridItem>
      <GridItem
        gridArea="unit"
        paddingX={2}
        paddingY={4}
        bg="gray4"
        style={{
          position: 'sticky',
          left: 488,
          zIndex: 9,
        }}>
        <Text
          sizeText="display14"
          textAlign="left"
          color="gray1"
          overflow="hidden"
          fontWeight="medium">
          Unidad
        </Text>
      </GridItem>
      <GridItem
        gridArea="measure"
        bg="gray4"
        paddingX={2}
        paddingY={4}
        style={{
          position: 'sticky',
          left: 576,
          zIndex: 9,
        }}>
        <Text
          sizeText="display14"
          textAlign="center"
          color="gray1"
          overflow="hidden"
          fontWeight="medium">
          Medición
        </Text>
      </GridItem>
      <GridItem
        bg="gray4"
        gridArea="ceco2"
        paddingX={2}
        paddingY={4}
        style={{
          position: 'sticky',
          left: 664,
          zIndex: 9,
        }}>
        <Text
          sizeText="display14"
          textAlign="left"
          color="gray1"
          overflow="hidden"
          fontWeight="medium">
          CeCO2
        </Text>
      </GridItem>
      {offers.map((offer) => (
        <GridItem gridArea={`offer-${offer.id}`} paddingX={2} paddingY={4} bg="gray3">
          <Text
            sizeText="display14"
            textAlign="right"
            color="gray1"
            overflow="hidden"
            fontWeight="medium">
            {offer.name}
          </Text>
        </GridItem>
      ))}
    </Grid>
    <Grid gridTemplateAreas={columns.area} gridTemplateColumns={columns.columns}>
      <GridItem
        gridArea="bidItem"
        bg="white2"
        padding={2}
        style={{
          position: 'sticky',
          left: 0,
          zIndex: 9,
        }}
      />
      <GridItem
        gridArea="unit"
        bg="white2"
        paddingRight={2}
        paddingY={4}
        style={{
          position: 'sticky',
          left: 488,
          zIndex: 9,
        }}
      />
      <GridItem
        gridArea="measure"
        bg="white2"
        paddingRight={2}
        paddingY={4}
        style={{
          position: 'sticky',
          left: 576,
          zIndex: 9,
        }}
      />
      <GridItem
        gridArea="ceco2"
        bg="white2"
        paddingY={2}
        paddingRight={2}
        style={{
          position: 'sticky',
          left: 664,
          zIndex: 9,
        }}>
        <Text textAlign="right" color="gray1" overflow="hidden" sizeText="display10">
          Estimado por unidad
        </Text>
      </GridItem>

      {offers.map((offer) => (
        <>
          <GridItem gridArea={`offer-unit-${offer.id}`} bg="white2" padding={2}>
            <Text textAlign="right" color="gray1" overflow="hidden" sizeText="display10">
              CeCO2/unidad
            </Text>
          </GridItem>
          <GridItem gridArea={`offer-total-${offer.id}`} bg="white2" padding={2}>
            <Text textAlign="right" color="gray1" overflow="hidden" sizeText="display10">
              CeCO2 total
            </Text>
          </GridItem>
        </>
      ))}
    </Grid>
  </>
)
