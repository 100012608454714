/* eslint-disable no-unused-vars */
import { Box, Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { DataTable } from '@proveoeng/uikit/dist/molecules/DataTable'
import { useFormikContext } from 'formik'
import { parseToInteger, parseToString } from '../../../../../../lib/utils/Format'
import { ItemEditable } from '../../../common/components/ItemEditable'

const calcTotal = (calc, measurement) => parseToInteger(calc) * (measurement / 1000)

const getBidItemTotal = (calc, measurement) => {
  if (!calc || !measurement) return '-'
  return `${parseToString(calcTotal(calc, measurement))} kg`
}

export const Ceco2 = ({ bidItemsEnviro = [], onSubmit }) => {
  const { values, handleChange, errors, submitForm } = useFormikContext()

  const handleOnSubmit = async (bidItemId, value) => {
    await submitForm()
    if (!errors[`eCo2-${bidItemId}`]) {
      onSubmit(bidItemId, value)
    }
  }

  const getTotal = (data) => {
    if (!data || !data?.length) return '-'

    const result = data.reduce(
      (prevItem, currentItem) =>
        prevItem + calcTotal(values[`eCo2-${currentItem.bidItemId}`], currentItem.measurement),
      0,
    )

    if (!result) return '-'
    return `${parseToString(result)} kg CO2`
  }

  const columns = [
    {
      name: 'Nombre',
      selector: (row) => row.name.toLowerCase(),
      cell: (row) => <Text sizeText="display14">{row.name}</Text>,
      sortable: true,
      width: '420px',
    },
    {
      name: 'Medición',
      selector: (row) => row.measurement,
      cell: (row) => <Text sizeText="display13">{parseToString(row.measurement, 3)}</Text>,
      sortable: true,
      width: '144px',
    },
    {
      name: (
        <Box marginBottom={2} width="100%">
          <Box marginBottom={2} paddingTop={4}>
            <Text textAlign="right" sizeText="display14" overflow="hidden" fontWeight="600">
              CeCo2
            </Text>
          </Box>
          <Flex width="100%" justifyContent="space-between">
            <Text
              width="110px"
              textAlign="right"
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              sizeText="display10">
              Por unidad
            </Text>
            <Text
              width="110px"
              textAlign="right"
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              sizeText="display10">
              Total estimado
            </Text>
          </Flex>
        </Box>
      ),
      cell: (row) => {
        const name = `eCo2-${row.bidItemId}`
        return (
          <Flex width="100%" justifyContent="space-between">
            <Box textAlign="right" width="110px">
              <ItemEditable
                isDecimal
                key={name}
                name={name}
                placeholderMessage="0,00"
                value={values[name]}
                handleChange={handleChange}
                textAlign="right"
                error={errors[name]}
                onSubmit={() => handleOnSubmit(row.bidItemId, values[name])}>
                <Text
                  sizeText="display13"
                  style={{
                    cursor: 'pointer',
                  }}>
                  {values[name] ? `${values[name]} kg` : '-'}
                </Text>
              </ItemEditable>
            </Box>
            <Box width="120px" textAlign="right">
              <Box paddingY={4}>
                <Text sizeText="display13">{getBidItemTotal(values[name], row.measurement)}</Text>
              </Box>
            </Box>
          </Flex>
        )
      },
      width: '220px',
    },
  ]

  return (
    <>
      <DataTable columns={columns} data={bidItemsEnviro.data} />
      <Grid gridTemplateAreas="'name ceco2Total empty'" gridTemplateColumns="560px 220px 1fr">
        <GridItem gridArea="name" bg="white" padding={4}>
          <Text
            sizeText="display16"
            color="black"
            overflow="hidden"
            fontWeight="medium"
            textAlign="left">
            Total
          </Text>
        </GridItem>
        <GridItem gridArea="ceco2Total" bg="white" paddingY={4} paddingX={2}>
          <Text
            sizeText="display16"
            color="black"
            overflow="hidden"
            fontWeight="medium"
            textAlign="right">
            {getTotal(bidItemsEnviro.data)}
          </Text>
        </GridItem>
        <GridItem gridArea="empty" bg="white" paddingY={4} paddingX={2} />
      </Grid>
    </>
  )
}
