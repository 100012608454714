import { useState } from 'react'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { TextAreaEditor } from '@proveoeng/uikit/dist/atoms/TextAreaEditor/TextAreaEditor'
import { ModalExitConfirmation } from '../../../../common/components/modalExitConfirmation/ModalExitConfirmation'

export const SellerMessageModal = ({
  isOpened,
  setIsOpened,
  onPublish,
  handleChangeMessage,
  goBack,
}) => {
  const [modalExitConfirmationIsOpened, setModalExitConfirmationIsOpened] = useState(false)
  const handleClose = () => {
    setModalExitConfirmationIsOpened(true)
  }

  const handleConfirmClose = () => {
    setIsOpened(false)
    setModalExitConfirmationIsOpened(false)
  }

  return (
    <>
      <Modal
        isOpen={isOpened}
        id="seller-message-modal"
        closeWithButton
        onModalClose={handleClose}
        title="¿Quieres añadir un mensaje a proveedores?"
        width="80vw"
        maxWidth="926px">
        <Modal.Content>
          <form onSubmit={() => {}}>
            <Flex flexDirection="column">
              <Flex width="100%" flexDirection="column">
                <Flex width="100%" marginRight={2}>
                  <Flex flexDirection="column" width="100%" height="435px">
                    <TextAreaEditor
                      placeholderMessage="Escribe tu mensaje aquí"
                      onChange={handleChangeMessage}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </form>
        </Modal.Content>
        <Modal.Actions>
          <Flex justifyContent="center">
            <Flex width="100%" justifyContent="flex-end">
              <Button colorType="transparent" action={goBack} width="150px" marginRight={4}>
                Volver
              </Button>
              <Button fullWidth maxWidth="184px" colorType="orange" action={onPublish}>
                Enviar notificación
              </Button>
            </Flex>
          </Flex>
        </Modal.Actions>
      </Modal>
      <ModalExitConfirmation
        isOpened={modalExitConfirmationIsOpened}
        handleConfirm={handleConfirmClose}
        handleClose={() => setModalExitConfirmationIsOpened(false)}
      />
    </>
  )
}
