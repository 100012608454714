import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  projectId: Yup.string().required('Seleccione una obra'),
  name: Yup.string()
    .min(3, 'El nombre debe tener minimo 3 caracteres')
    .max(50, 'El nombre debe tener maximo 50 caracteres')
    .required('Introduce un nombre'),
  categoryId: Yup.string().required('Selecciona una categoria'),
  bidEndsAt: Yup.date()
    .required('Introduce una fecha de finalización')
    .min(new Date(), 'La fecha de finalización debe ser mayor que la actual'),
})
