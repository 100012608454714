import { constants } from '../config'

/* **** getEmployee *** */
export const getEmployee = () => ({
  type: constants.GET_EMPLOYEE,
})

export const getEmployeeSuccess = (data) => ({
  type: constants.GET_EMPLOYEE_SUCCESS,
  payload: data,
})
/* **** getPendingOffersEmployee *** */
export const getPendingOffersEmployee = (loadFiles) => ({
  type: constants.GET_PENDING_OFFERS,
  payload: { loadFiles },
  loadFiles,
})

export const getPendingOffersEmployeeSuccess = (data) => ({
  type: constants.GET_PENDING_OFFERS_SUCCESS,
  payload: data,
})

export const getFilePendingOffersSuccess = (bidPackageId, data) => ({
  type: constants.CREATE_ATTACHMENT_PENDING_OFFER_SUCCESS,
  payload: data,
  bidPackageId,
})

export const getThumbPendingOffersSuccess = (bidPackageId, data) => ({
  type: constants.CREATE_THUMB_PENDING_OFFER_SUCCESS,
  payload: data,
  bidPackageId,
})

/* **** getProposalOffersEmployee *** */
export const getProposalOffersEmployee = (loadFiles) => ({
  type: constants.GET_PENDING_PROPOSALS,
  loadFiles,
})

export const getProposalOffersEmployeeSuccess = (data) => ({
  type: constants.GET_PENDING_PROPOSALS_SUCCESS,
  payload: data,
})

export const getFilePendingProposalSuccess = (proposalId, data) => ({
  type: constants.CREATE_ATTACHMENT_PENDING_PROPOSAL_SUCCESS,
  payload: data,
  proposalId,
})

export const getThumbPendingProposalSuccess = (proposalId, data) => ({
  type: constants.CREATE_THUMB_PENDING_PROPOSAL_SUCCESS,
  payload: data,
  proposalId,
})

/* **** getOffersProjects *** */
export const getOffersProjects = () => ({
  type: constants.GET_OFFERS_PROJECTS,
})

export const getOffersProjectsSuccess = (data) => ({
  type: constants.GET_OFFERS_PROJECTS_SUCCESS,
  payload: data,
})

export const getFileOffersProjectsSuccess = (projectId, data) => ({
  type: constants.CREATE_ATTACHMENT_OFFER_PROJECT_SUCCESS,
  payload: data,
  projectId,
})

/* **** setEmployeeData *** */
export const setEmployeeData = (body) => ({
  type: constants.SET_EMPLOYEE_DATA,
  body,
})

export const setEmployeeDataSuccess = (data) => ({
  type: constants.SET_EMPLOYEE_DATA_SUCCESS,
  payload: data,
})

export const resetOpenedOffer = () => ({
  type: constants.RESET_OPENED_OFFER,
})
/* **** setInvitationEmployee *** */
export const setInvitationEmployee = (id, body) => ({
  type: constants.SET_EMPLOYEE_INVITATION,
  id,
  body,
})

export const setInvitationEmployeeSuccess = (data) => ({
  type: constants.SET_EMPLOYEE_INVITATION_SUCCESS,
  payload: data,
})

/* **** sendInvitation *** */
export const sendInvitation = (id) => ({
  type: constants.SEND_INVITATION,
  id,
})

export const sendInvitationSuccess = (data) => ({
  type: constants.SEND_INVITATION_SUCCESS,
  payload: data,
})

/* **** setEmployeeAvatar *** */
export const setEmployeeAvatar = (body) => ({
  type: constants.SET_EMPLOYEE_AVATAR,
  body,
})

export const setStore = (data) => ({
  type: constants.SET_STORE_EMPLOYEE,
  payload: data,
})

export const employeeFails = (error) => ({
  type: constants.EMPLOYEE_FAIL,
  error,
})

export const addImage = (id, thumb, type) => ({
  type: constants.ADD_IMAGE,
  payload: { id, thumb, type },
})

export const removeImage = (id, type) => ({
  type: constants.REMOVE_IMAGE,
  payload: { id, type },
})
