import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { DataTable } from '@proveoeng/uikit/dist/molecules/DataTable'

import { FormatDate } from '../../../../common/mappers/FormatDate'
import { colorState, copyState } from '../../config'

/**
 *
 * @param {{
 *  bidPackages: BidPackages,
 *  goToBidPackage: (bidPackageId: string) => void,
 *  setImportModalIsOpened: (open: boolean) => void
 *  setFormIsOpened: (open: boolean) => void
 * }} props
 */
export const BidPackageList = ({ bidPackages, goToBidPackage }) => {
  const columns = [
    {
      name: 'Nombre de la compra',
      selector: (row) => row.name.toLowerCase(),
      cell: (row) => (
        <Text
          as="span"
          sizeText="display14"
          fontWeight="medium"
          onClick={() => goToBidPackage(row.bidPackageId)}
          style={{ cursor: 'pointer' }}>
          {row.name}
        </Text>
      ),
      sortable: true,
      minWidth: '210px',
    },
    {
      name: 'Naturaleza',
      selector: (row) => row.categoryName,
      cell: (row) => (
        <Text as="p" sizeText="display14" paddingY={2}>
          {row.categoryName}
        </Text>
      ),
      sortable: true,
      width: '152px',
    },
    {
      name: 'Estado',
      selector: (row) => copyState[row.state],
      cell: (row) => (
        <>
          <Flex alignItems="center">
            <Box
              backgroundColor={colorState[row.state]}
              width="8px"
              height="8px"
              borderRadius={8}
              minWidth="8px"
            />
            <Text as="span" paddingLeft={1} color="gray1" sizeText="display14">
              {copyState[row.state]}
            </Text>
          </Flex>
        </>
      ),
      sortable: true,
      width: '132px',
    },
    {
      name: 'Fecha de publicación',
      cell: (row) =>
        row.bidStartsAt ? FormatDate.hydrate(row.bidStartsAt).readableReverseDate : '-',
      selector: (row) => row.bidStartsAt,
      sortable: true,
      right: true,
      width: '182px',
    },
    {
      name: 'Ofertas',
      selector: (row) => row.proposalsCount.active + row.proposalsCount.inactive,
      cell: (row) => row.proposalsCount.active + row.proposalsCount.inactive || '-',
      sortable: true,
      right: true,
      width: '132px',
    },
    {
      name: (
        <Text sizeText="display14" overflow="hidden" fontWeight="600">
          Fecha límite para presentar oferta
        </Text>
      ),
      selector: (row) =>
        row.bidEndsAt ? FormatDate.hydrate(row.bidEndsAt).readableReverseDate : '-',
      sortable: true,
      minWidth: '260px',
      right: true,
    },
  ]

  return (
    <DataTable
      columns={columns}
      data={bidPackages}
      defaultSortFieldId={4}
      defaultSortAsc={false}
      pagination={bidPackages.length > 10}
    />
  )
}
