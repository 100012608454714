/* eslint-disable no-await-in-loop */
import { Form, Formik } from 'formik'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { parseToString } from '../../../../../lib/utils/Format'
import { parseEditorMessage } from '../../../../../lib/utils/parseEditorMessage'
import { onImagesLoad } from '../../../../common/functions/data'
import { useProposal } from '../../../../common/hooks/useProposal'
import { useUtils } from '../../../../common/hooks/useUtils'
import { ApplyOfferTemplate } from '../template/ApplyOfferTemplate'
import { editProposalSchema } from '../utils/editProposalSchema'
import { proposalInitialValues } from '../utils/proposalInitialValues'

const ApplyOfferPage = () => {
  const history = useHistory()
  const { hash } = useParams()
  const location = useLocation()

  const formRef = useRef(null)

  const { setAlert } = useUtils()

  const [tabActive, setTabActive] = useState('conditions')

  const [isModalSendMessageOpened, setIsModalSendMessageOpened] = useState(false)
  const [isModalContactOpened, setIsModalContactOpened] = useState(false)
  const [isModalSendProposalOpened, setIsModalSendProposalOpened] = useState(false)
  const [isModalExitConfirmationOpened, setIsModalExitConfirmationOpened] = useState(false)
  const [filesToImport, setFilesToImport] = useState([])
  const [importModalIsOpened, setImportModalIsOpened] = useState(false)
  const [isOptionalMessage, setIsOptionalMessage] = useState(false)
  const {
    data: {
      proposal: { bidPackage, ...proposal },
      proposalItems,
    },
    enviro: {
      getProposalEnviro,
      getProposalItemsEnviro,
      updateProposalEnviro,
      updateProposalItemEnviro,
      createFilesProposalEnviro,
      createLinksProposalEnviro,
      deleteFilesProposalEnviro,
      deleteLinksProposalEnviro,
      createFilesProposalItemEnviro,
      createLinksProposalItemEnviro,
      deleteFilesProposalItemEnviro,
      deleteLinksProposalItemEnviro,
    },
    getProposal,
    updateProposal,
    updateProposalItem,
    sendMessage,
    getProposalItems,
    exportBidPackagePrices,
    importProposalPrices,
    setFilesProposal,
    setFilesProposalItem,
    deleteFilesProposal,
    deleteFilesProposalItem,
    setLinksProposal,
    setLinksProposalItem,
    deleteLinkProposalItem,
    deleteLinksProposal,
  } = useProposal()

  useEffect(() => {
    const getData = async () => {
      await getProposal(hash)
      await getProposalItems(hash)
      await getProposalEnviro(hash)
      await getProposalItemsEnviro(hash)
    }
    if (hash) {
      getData()
    }
  }, [hash])

  const initialValues = useMemo(() => {
    const initialValuesItems = proposalItems.reduce(
      (prev, item) => ({
        ...prev,
        [`comments-${item?.proposalItemId}`]: item.comments,
        [`files-${item?.proposalItemId}`]: item.files,
        [`links-${item?.proposalItemId}`]: item.links,
        [`unitaryPrice-${item?.proposalItemId}`]: item.unitaryPrice,
        // Enviro
        [`commentsEnviro-${item?.proposalItemId}`]: item.commentsEnviro,
        [`filesEnviro-${item?.proposalItemId}`]: item.filesEnviro,
        [`linksEnviro-${item?.proposalItemId}`]: item.linksEnviro,
        [`eCo2-${item?.proposalItemId}`]: item.eCo2,
        [`dataQuality-${item?.proposalItemId}`]: item.dataQuality,
      }),
      {},
    )
    return {
      ...proposalInitialValues,
      modifyDeliveryTime: !!proposal?.deliveryTime,
      modifyDeliveryDates: !!proposal?.start || !!proposal?.end,
      acceptRetentionConditions: !proposal?.retention,
      acceptPaymentConditions: !proposal?.paymentDays,
      retention: parseToString(proposal?.retention ?? 0),
      start: proposal?.start?.readableDate,
      end: proposal?.end?.readableDate,
      deliveryTime: proposal?.deliveryTime,
      retentionCriteria: proposal?.retentionCriteria,
      paymentConditions: proposal?.paymentConditions,
      paymentDays: proposal?.paymentDays,
      comments: proposal?.comments,
      files: proposal?.files,
      links: proposal?.links,
      // Enviro
      distanceToBuildingSite: proposal?.distanceToBuildingSite,
      recycledMaterials: proposal?.recycledMaterials,
      commentsEnviro: proposal?.commentsEnviro,
      filesEnviro: proposal?.filesEnviro,
      linksEnviro: proposal?.linksEnviro,
      ...initialValuesItems,
    }
  }, [proposal])

  const tabs = [
    {
      id: 'info',
      children: <>Información de la obra</>,
      isActive: tabActive === 'info',
      action: (_, target) => setTabActive(target.id),
    },
    {
      id: 'conditions',
      children: <>Condiciones de la oferta</>,
      isActive: tabActive === 'conditions',
      action: (_, target) => setTabActive(target.id),
    },
    {
      id: 'bidItems',
      children: <>Partidas</>,
      isActive: tabActive === 'bidItems',
      action: (_, target) => setTabActive(target.id),
    },
    {
      id: 'emissionCost',
      children: <>Emisiones</>,
      isActive: tabActive === 'emissionCost',
      action: (_, target) => setTabActive(target.id),
    },
    {
      id: 'environmentalStatement',
      children: <>Declaración ambiental</>,
      isActive: tabActive === 'environmentalStatement',
      action: (_, target) => setTabActive(target.id),
    },
  ]

  const isPublished = useMemo(() => location?.state?.status === 'published', [location])

  const goBack = () => history.push('/proveedor/compras-nuevas')

  const goToProposalPreview = () =>
    history.push(`/proveedor/tus-aplicaciones/${proposal?.proposalId}`)

  const onValidate = async () => {
    const form = formRef?.current
    if (!form) return false
    const validate = await form.validateForm()
    const errors = Object.values(validate)
    if (errors.length) {
      form.setTouched({ ...form.touched, ...validate })
      setAlert('error', errors[0])
      return false
    }
    return true
  }

  const handleOpenMessage = async () => {
    const isValid = await onValidate()
    if (!isValid) return
    setIsModalSendProposalOpened(true)
  }

  const handleSendMessage = async ({ message }) => {
    const messageParsed = parseEditorMessage(message)
    if (!messageParsed) {
      setAlert('error', 'El mensaje debe tener al menos 5 caracteres.')
      return
    }
    await sendMessage(proposal.proposalId, {
      message: messageParsed,
      parentMessageId: null,
    })
    setIsModalSendProposalOpened(false)
  }

  const handleExportBidPackagePrices = () =>
    exportBidPackagePrices(hash, 'Petición de Presupuesto.xlsx')

  const openImportModal = () => {
    setFilesToImport([])
    setImportModalIsOpened(true)
  }

  const handleSubmitImport = async () => {
    const file = [filesToImport.at(-1)]
    const excelExtension = ['xlsx']
    const extension = `${file[0].name.split('.').pop()}`
    if (!excelExtension.includes(extension)) {
      setAlert('error', 'Tipo de documento no soportado')
      return
    }
    await importProposalPrices(hash, file)
    await getProposal(hash)
    await getProposalItems(hash)
  }

  const onSubmit = async (values) => {
    if (values.publish && !values.message && !isOptionalMessage) {
      setAlert('error', 'El mensaje debe tener al menos 5 caracteres.')
      return
    }

    // update proposal items
    for (const { proposalItemId } of proposalItems) {
      const files = values[`files-${proposalItemId}`] || []
      const filesToDelete = values[`filesToDelete-${proposalItemId}`] || []
      const links = values[`links-${proposalItemId}`] || []
      const linksToDelete = values[`linksToDelete-${proposalItemId}`] || []
      const comments = values[`comments-${proposalItemId}`]
      const unitaryPrice = values[`unitaryPrice-${proposalItemId}`]

      const filesEnviro = values[`filesEnviro-${proposalItemId}`] || []
      const filesEnviroToDelete = values[`filesEnviroToDelete-${proposalItemId}`] || []
      const linksEnviro = values[`linksEnviro-${proposalItemId}`] || []
      const linksEnviroToDelete = values[`linksEnviroToDelete-${proposalItemId}`] || []
      const commentsEnviro = values[`commentsEnviro-${proposalItemId}`]
      const eCo2 = values[`eCo2-${proposalItemId}`]
      const dataQuality = values[`dataQuality-${proposalItemId}`]

      await updateProposalItem(hash, proposalItemId, {
        comments,
        unitaryPrice,
      })

      await setFilesProposalItem({
        proposalId: hash,
        proposalItemId,
        files,
      })

      await deleteFilesProposalItem(hash, proposalItemId, filesToDelete)
      await setLinksProposalItem(hash, proposalItemId, links)
      await deleteLinkProposalItem(hash, proposalItemId, linksToDelete)

      await createFilesProposalItemEnviro(hash, proposalItemId, filesEnviro)
      await deleteFilesProposalItemEnviro(hash, proposalItemId, filesEnviroToDelete)
      await createLinksProposalItemEnviro(hash, proposalItemId, linksEnviro)
      await deleteLinksProposalItemEnviro(hash, proposalItemId, linksEnviroToDelete)
      await updateProposalItemEnviro(hash, proposalItemId, {
        comments: commentsEnviro,
        eCo2,
        dataQuality,
      })
    }

    // save proposal
    await updateProposal({
      id: hash,
      body: values,
      publish: values.publish,
      proposalState: proposal?.data?.state,
      message: values.message,
    })
    // save files
    await setFilesProposal({ proposalId: hash, files: values.files })
    // delete files
    await deleteFilesProposal(hash, values.filesToDelete)
    // save links
    await setLinksProposal(hash, values.links)
    // delete links
    await deleteLinksProposal(hash, values.linksToDelete)

    // Save enviro data
    await updateProposalEnviro(hash, {
      distanceToBuildingSite: values.distanceToBuildingSite,
      recycledMaterials: values.recycledMaterials,
      commentsEnviro: values.commentsEnviro,
    })

    // Save files enviro
    await createFilesProposalEnviro(hash, values.filesEnviro)
    await deleteFilesProposalEnviro(hash, values.filesEnviroToDelete)
    // Save links enviro
    await createLinksProposalEnviro(hash, values.linksEnviro)
    await deleteLinksProposalEnviro(hash, values.linksEnviroToDelete)

    history.push('/proveedor/tus-aplicaciones')
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={editProposalSchema}
      enableReinitialize
      innerRef={formRef}
      onSubmit={onSubmit}>
      <Form>
        <ApplyOfferTemplate
          goBack={goBack}
          bidPackage={bidPackage}
          proposal={proposal}
          proposalItems={proposalItems}
          isPublished={isPublished}
          isModalSendMessageOpened={isModalSendMessageOpened}
          setIsModalSendMessageOpened={setIsModalSendMessageOpened}
          handleSendMessage={handleSendMessage}
          handleOpenMessage={handleOpenMessage}
          isModalContactOpened={isModalContactOpened}
          setIsModalContactOpened={setIsModalContactOpened}
          isModalSendProposalOpened={isModalSendProposalOpened}
          setIsModalSendProposalOpened={setIsModalSendProposalOpened}
          isModalExitConfirmationOpened={isModalExitConfirmationOpened}
          setIsModalExitConfirmationOpened={setIsModalExitConfirmationOpened}
          goToProposalPreview={goToProposalPreview}
          tabActive={tabActive}
          tabs={tabs}
          handleExportBidPackagePrices={handleExportBidPackagePrices}
          openImportModal={openImportModal}
          importModalIsOpened={importModalIsOpened}
          setImportModalIsOpened={setImportModalIsOpened}
          onFileToImportLoad={(imgs) => onImagesLoad(imgs, setFilesToImport, filesToImport)}
          filesToImport={filesToImport}
          handleSubmitImport={handleSubmitImport}
          setIsOptionalMessage={setIsOptionalMessage}
        />
      </Form>
    </Formik>
  )
}

export default ApplyOfferPage
