export const CompanyMapper = {
  hydrate({
    companyId,
    logo,
    generalData,
    billingData,
    activityData,
    type,
    categories,
    isSameBillingData,
    tenderingData,
  }) {
    return {
      companyId,
      logo,
      generalData: {
        name: generalData?.name,
        email: generalData?.email || '',
        phone: generalData?.phone || '',
        website: generalData?.website || '',
        address: generalData?.address?.address,
        city: generalData?.address?.city,
        postCode: generalData?.address?.postCode,
        fax: generalData?.fax || '',
      },
      billingData: {
        ...billingData,
        address: generalData?.address?.address,
        city: generalData?.address?.city,
        postCode: generalData?.address?.postCode,
      },
      activityData,
      type,
      categories,
      isSameBillingData,
      tenderingData: {
        ...tenderingData,
        financialCostsRatio: tenderingData?.financialCostsRatio?.rate
          ? (Number(tenderingData?.financialCostsRatio?.rate) / 100).toLocaleString('de-DE', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : null,
      },
    }
  },
  dehydrateGeneral({ name, address, postCode, city, phone, website, email, fax }) {
    return {
      generalData: {
        name,
        address: {
          address,
          postCode,
          city,
        },
        phone,
        website: website || null,
        email: email || null,
        fax: fax || null,
      },
    }
  },
  dehydrateActivity({ description, creationYear, turnover, scope, staff }) {
    return {
      activityData: {
        description,
        scope: scope || null,
        turnover: turnover || null,
        staff: staff || null,
        creationYear: creationYear ? Number(creationYear) : null,
      },
    }
  },
  dehydrateBilling({ name, taxCode, isSameBillingData, postCode, phone, address, city }) {
    const setDataAddress = () => {
      if (name) {
        return {
          name,
          phone,
          address: {
            postCode,
            address,
            city,
          },
        }
      }
      return {}
    }
    return {
      billingData: {
        ...setDataAddress(),
        taxCode,
      },
      isSameBillingData,
    }
  },
}
