import { useState } from 'react'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { LinkText } from '@proveoeng/uikit/dist/atoms/Typography'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography/Typography'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Tooltip } from '@proveoeng/uikit/dist/atoms/Tooltip'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import Close from '@proveoeng/uikit/dist/atoms/Icons/Close'
import Help from '@proveoeng/uikit/dist/atoms/Icons/Help'
import LinkIcon from '@proveoeng/uikit/dist/atoms/Icons/Link'

export const LinksList = ({
  links = [],
  inputLink,
  setInputLink,
  addLink,
  handleAddDescription,
  removeLink,
  verifyUrl,
}) => {
  const [openTooltip, setOpenTooltip] = useState(false)

  return (
    <>
      <Flex alignItems="center">
        <Text as="span" sizeText="display16" color="black" fontWeight="regular">
          También puedes compartir información utilizando enlaces a recursos externos
        </Text>
        <Tooltip
          content="Asegúrate de agregar un enlace público válido"
          active={openTooltip}
          setActive={setOpenTooltip}
          bgColor="gray4"
          textColor="black"
          disableHover>
          <Button colorType="none" sizeButton="none" action={() => {}}>
            <Icon sizeIcon="display30">
              <Help />
            </Icon>
          </Button>
        </Tooltip>
      </Flex>

      <Flex maxWidth="500px" marginTop={4}>
        <Input
          placeholderMessage="Añadir tu enlace aqui"
          ariaLabel="Url"
          name="url"
          value={inputLink}
          action={(_, value) => setInputLink(value)}
          hasError={inputLink && !verifyUrl(inputLink)}
          autocomplete="off"
        />
        <Button
          sizeButton="medium"
          marginLeft={5}
          action={addLink}
          disabled={!inputLink || !verifyUrl(inputLink)}>
          Añadir
        </Button>
      </Flex>
      <Box marginTop={3} marginLeft={4}>
        {links.map((link, key) => (
          <Box marginTop={5} width="100%">
            <Flex key={key}>
              <Box marginRight={2}>
                <Icon color="gray2" sizeIcon="display12">
                  <LinkIcon />
                </Icon>
              </Box>
              <LinkText
                href={link.url}
                title={link.url}
                target="_blank"
                sizeText="display16"
                color="blue1"
                display="inline-block"
                overflow="hidden"
                maxWidth="500px"
                style={{
                  clear: 'both',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}>
                {link.url}
              </LinkText>
              <Box>
                <Box
                  backgroundColor="gray3"
                  borderRadius={6}
                  padding={1}
                  onClick={() => removeLink(key, link)}
                  marginLeft={2}>
                  <Icon color="gray2" sizeIcon="display12">
                    <Close />
                  </Icon>
                </Box>
              </Box>
            </Flex>
            <Flex maxWidth="500px" marginTop={16}>
              {link.linkId ? (
                <Text
                  sizeText="display14"
                  style={{
                    whiteSpace: 'pre-wrap',
                  }}>
                  {link.description}
                </Text>
              ) : (
                <Input
                  placeholderMessage="Añade una descripción"
                  maxLength={50}
                  name="description"
                  minLength={2}
                  value={link.description}
                  action={(_, value) => handleAddDescription(key, value)}
                  autocomplete="off"
                />
              )}
            </Flex>
          </Box>
        ))}
      </Box>
    </>
  )
}
