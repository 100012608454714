import { useState } from 'react'
import { requestApi } from '../../../lib/request'
import { parseParamRequest } from '../../../lib/utils/parseParamsRequest'
import { services } from '../schemes/helpers/config'
import { CategorySeller } from '../schemes/helpers/mapper'
import { useUtils } from './useUtils'

export const useHelpers = () => {
  const { setError, setLoading, getSignal, cancelRequest } = useUtils()

  const [categories, setCategories] = useState({
    data: [],
    meta: {},
  })

  const [sellers, setSellers] = useState({
    data: [],
    meta: {},
  })
  const [seller, setSeller] = useState({})

  // Refactored
  const getCategories = async () => {
    setLoading(true)
    try {
      const { data } = await requestApi(services.getCategories())
      setCategories(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)

      setError(error)
    }
  }

  // Refactored
  const getSellerCategories = async (categoryId, params = {}) => {
    const paramsRequest = parseParamRequest(params)
    try {
      const {
        data: { data, meta },
      } = await requestApi(services.getSellerCategories(categoryId, paramsRequest))
      setSellers({
        meta,
        data: data.map((element) => CategorySeller.hydrate(element.resource)),
      })
    } catch (error) {
      setError(error)
    }
  }

  const getSellerByCompany = async (companyId) => {
    setLoading(true)
    try {
      const { data } = await requestApi(services.getSellerByCompany(companyId))
      setSeller(data)
      return true
    } catch (error) {
      if (error?.message !== 'canceled') setError(error)
      return false
    } finally {
      setLoading(false)
    }
  }

  const getSellers = async (params = {}) => {
    setLoading(true)
    try {
      const paramsRequest = parseParamRequest(params)
      const {
        data: { data, meta },
      } = await requestApi(services.getSellers(paramsRequest, { signal: getSignal() }))
      setSellers({
        meta,
        data: data.map((element) => CategorySeller.hydrate(element.resource)),
      })
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  return {
    data: {
      categories,
      sellers,
      seller,
    },
    getCategories,
    getSellerCategories,
    getSellerByCompany,
    getSellers,
    cancelRequest,
  }
}
