import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { SelectInput } from '@proveoeng/uikit/dist/atoms/Select'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

import { EventLogsWrapper } from './EventLogsWrapper'

const optionsActivity = {
  BidPackageWasOffered: 'Presupuesto enviado',
  BidPackageOfferWasAccepted: 'Presupuesto aceptado',
  BidPackageOfferWasRejected: 'Presupuesto rechazado',
  TenderingMessageWasSentBySeller: 'Mensaje recibido',
  TenderingMessageWasSentByBuyer: 'Mensaje enviado',
  ProposalWasSent: 'Propuesta recibida',
  ProposalWasNotifiedOfBidPackageChanges: 'Cambios notificados',
  ProposalWasRejected: 'Propuesta descartada',
  ProposalWasAwarded: 'Propuesta seleccionada',
  ProposalWasNotAwarded: 'Propuesta no seleccionada',
  ProposalWasCanceledByBuyer: 'Propuesta cancelada',
}

const optionsFilter = Object.entries(optionsActivity).map(([key, value]) => ({
  value: key,
  label: value,
}))

const optionAll = {
  value: '',
  label: 'Todos',
}

export const EventLogsList = ({ eventLogsList = [], handleSelectFilter, guests = [] }) => {
  const optionsGuests = guests.map((guest) => ({
    value: guest.companyName || guest?.invitationEmail,
    label: guest.companyName || guest?.invitationEmail,
  }))

  return (
    <Box>
      <Flex marginY={4}>
        <Box width="225px" marginRight={4}>
          <SelectInput
            isSearchable
            isClearable
            options={[optionAll, ...optionsGuests]}
            id="activityFilter"
            label="Proveedores"
            placeholderMessage="Proveedores"
            onChange={(_, value) => handleSelectFilter('filterByProvider', value)}
          />
        </Box>
        <Box width="225px">
          <SelectInput
            isSearchable
            isClearable
            options={[optionAll, ...optionsFilter]}
            id="activityFilter"
            label="Actividad"
            placeholderMessage="Actividad"
            onChange={(_, value) => handleSelectFilter('filterByActivity', value)}
          />
        </Box>
      </Flex>
      {!eventLogsList.length && (
        <Box mt={8}>
          <Text as="h4" fontWeight="medium" color="gray" sizeText="display18" textAlign="center">
            No hay datos
          </Text>
        </Box>
      )}
      {eventLogsList?.map((eventLog) => (
        <EventLogsWrapper eventLogsData={eventLog} />
      ))}
    </Box>
  )
}
