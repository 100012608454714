import { Form, Formik } from 'formik'
import { useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { parseToInteger, parseToString } from '../../../../../../lib/utils/Format'
import { useBidPackage } from '../../../../../common/hooks/useBidPackage'
import { useUtils } from '../../../../../common/hooks/useUtils'
import { EmissionsCalculatorTemplate } from '../template/EmissionsCalculatorTemplate'

const EmissionsCalculatorPage = () => {
  const { bidPackageId } = useParams()
  const {
    enviro: {
      data: { bidItemsEnviro },
      getBidItemsEnviro,
      updateBidItemEnviro,
    },
  } = useBidPackage()

  const { setAlert } = useUtils()

  const formRef = useRef()

  useEffect(() => {
    getBidItemsEnviro(bidPackageId)
  }, [bidPackageId])

  // better to use onSubmit from Formik
  const onSubmit = async (bidItemId, oldValue) => {
    const { values } = formRef.current
    const value = values[`eCo2-${bidItemId}`]
    if (value === oldValue) return
    const valueParsed = parseToInteger(value)
    if (Number.isNaN(valueParsed) || valueParsed < 0) {
      setAlert('error', 'El valor debe ser un número positivo')
      return
    }
    await updateBidItemEnviro(bidPackageId, bidItemId, valueParsed || null)
  }

  const initialValues = useMemo(
    () =>
      bidItemsEnviro.data.reduce(
        (prev, { bidItemId, eCo2 }) => ({
          ...prev,
          [`eCo2-${bidItemId}`]: parseToString(eCo2),
        }),
        {},
      ),
    [bidItemsEnviro],
  )

  return (
    <Formik onSubmit={() => {}} initialValues={initialValues} innerRef={formRef} enableReinitialize>
      <Form>
        <EmissionsCalculatorTemplate bidItemsEnviro={bidItemsEnviro} onSubmit={onSubmit} />
      </Form>
    </Formik>
  )
}

export default EmissionsCalculatorPage
