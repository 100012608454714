import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { RegisterSellerTemplate } from '../template/RegisterSellerTemplate'
import { setStore } from '../../../common/schemes/auth'
import { deleteLocalStorage } from '../../../common/functions/cookies'

const initialValues = {
  email: '',
  password: '',
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Debe ingresar un email').email('Debe ingresar un email válido'),
  password: Yup.string()
    .required('Debe ingresar una contraseña')
    .min(6, 'Debe tener al menos 6 caracteres')
    .max(80, 'Debe tener máximo 80 caracteres'),
})

const RegisterSellerPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const setStoreAPI = useCallback((data) => dispatch(setStore(data)), [dispatch])

  useEffect(() => {
    setStoreAPI({})
    deleteLocalStorage(
      process?.env?.REACT_APP_COOKIE_LOGIN_NAME || env?.REACT_APP_COOKIE_LOGIN_NAME,
    )
  }, [])

  const onSubmit = (values) => {
    setStoreAPI(values)
    history.push('/finalizar/proveedor')
  }

  const { values, handleChange, handleSubmit, errors, isValid, handleBlur, touched } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  })

  return (
    <RegisterSellerTemplate
      onSubmit={handleSubmit}
      errors={errors}
      values={values}
      handleChange={handleChange}
      isValid={isValid}
      handleBlur={handleBlur}
      touched={touched}
    />
  )
}

export default RegisterSellerPage

RegisterSellerPage.propTypes = {}

RegisterSellerPage.defaultProps = {}
