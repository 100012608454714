import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import css from '@styled-system/css'

const MainStyled = styled('main')(
  css({
    backgroundColor: 'gray4',
    height: '100%',
    minHeight: '100vh',
  }),
)

export const Layout = (props) => {
  const { children } = props

  return <MainStyled>{children}</MainStyled>
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  history: PropTypes.shape({}).isRequired,
}

Layout.defaultProps = {
  location: {
    pathname: '',
  },
  children: null,
}
