import { constants } from '../config'

/* **** getCompany *** */
export const getCompany = (id) => ({
  type: constants.GET_COMPANY,
  id,
})

export const getCompanySuccess = (data) => ({
  type: constants.GET_COMPANY_SUCCESS,
  payload: data,
})

/* **** setCompanyGeneral *** */
export const setCompanyGeneral = (id, body) => ({
  type: constants.SET_COMPANY_GENERAL,
  id,
  body,
})

export const setCompanyGeneralSuccess = (data) => ({
  type: constants.SET_COMPANY_GENERAL_SUCCESS,
  payload: data,
})

/* **** setCompanyGeneral *** */
export const setCompanyBilling = (id, body) => ({
  type: constants.SET_COMPANY_BILLING,
  id,
  body,
})

export const setCompanyBillingSuccess = (data) => ({
  type: constants.SET_COMPANY_BILLING_SUCCESS,
  payload: data,
})

/* **** setCompanyGeneral *** */
export const setCompanyActivity = (id, body) => ({
  type: constants.SET_COMPANY_ACTIVITY,
  id,
  body,
})

export const setCompanyActivitySuccess = (data) => ({
  type: constants.SET_COMPANY_ACTIVITY_SUCCESS,
  payload: data,
})

/* **** setCompanyCategories *** */
export const setCompanyCategories = (id, body) => ({
  type: constants.SET_COMPANY_CATEGORIES,
  id,
  body,
})

export const setCompanyCategoriesSuccess = (data) => ({
  type: constants.SET_COMPANY_CATEGORIES_SUCCESS,
  payload: data,
})

/* **** setCompanyLogo *** */
export const setCompanyLogo = (id, body) => ({
  type: constants.SET_COMPANY_LOGO,
  id,
  body,
})

export const setCompanyLogoSuccess = (data) => ({
  type: constants.SET_COMPANY_LOGO_SUCCESS,
  payload: data,
})

export const setCompanyTendering = (id, rate) => ({
  type: constants.SET_COMPANY_TENDERING,
  id,
  rate,
})

export const setStore = (data) => ({
  type: constants.SET_STORE_COMPANY,
  payload: data,
})

export const companyFails = (error) => ({
  type: constants.COMPANY_FAIL,
  error,
})
