import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useCompany } from '../../../../common/hooks/useCompany'
import { ProfileCompanyTemplate } from '../template/ProfileCompanyTemplate'

export const ProfileCompanyPage = () => {
  const { companyId } = useSelector((state) => state.auth?.data)

  const [whoIsActive, setWhoIsActive] = useState('company')

  const [logoImage, setLogoImage] = useState({
    name: '',
    base64: '',
    file: null,
  })

  const {
    data: { company },
    getCompany,
    setCompanyGeneral,
    setCompanyBilling,
    setCompanyActivity,
    setCompanyLogo,
    setCompanyTendering,
  } = useCompany()

  useEffect(() => {
    if (companyId) getCompany(companyId)
  }, [companyId])

  useEffect(() => {
    if (company?.logo) setLogoImage({ base64: company?.logo })
  }, [company?.logo])

  const tabs = [
    {
      id: 'company',
      children: <>Datos de la compañía</>,
      isActive: whoIsActive === 'company',
      action: (e, target) => setWhoIsActive(target.id),
    },
    {
      id: 'employees',
      children: <>Empleados</>,
      isActive: whoIsActive === 'employees',
      action: (e, target) => setWhoIsActive(target.id),
    },
  ]

  const handleSubmitCompany = async (values) => {
    await setCompanyGeneral(companyId, values)

    const billingBody = values.isSameBillingData
      ? values
      : {
          isSameBillingData: values.isSameBillingData,
          taxCode: values.taxCode,
          name: values.billingName,
          address: values.billingAddress,
          city: values.billingCity,
          postCode: values.billingPostCode,
        }

    await setCompanyBilling(companyId, billingBody)
  }

  const handleSubmitCompanyActivity = async (values) => {
    await setCompanyActivity(companyId, values)
    if (logoImage.file) {
      await setCompanyLogo(companyId, { file: logoImage.file })
    }
    await setCompanyTendering(companyId, values.financialCostsRatio)
  }

  const onLogoImageChange = (imgs) => setLogoImage(imgs[0])

  const handleSubmit = async (values) => {
    await handleSubmitCompanyActivity(values)
    await handleSubmitCompany(values)
    await getCompany(companyId)
  }

  return (
    <ProfileCompanyTemplate
      company={company}
      tabs={tabs}
      activetab={whoIsActive}
      handleSubmit={handleSubmit}
      logoImage={logoImage}
      onLogoImageChange={onLogoImageChange}
    />
  )
}
