import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Box, Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import styled from 'styled-components'

export const FormContainer = styled(Grid)`
  margin-top: 42px;
  grid-template-columns: 264px 602px;
  grid-template-areas: 'description fields';
  grid-gap: 48px;
  @media (max-width: 1024px) {
    display: block;
  }
`

export const TitleStyled = styled(Text)`
  font-weight: bold;
  max-width: 856px;
  margin-bottom: 32px;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }
`

export const InputContainer = styled(Box)`
  display: flex;
  & > div {
    flex: 1;
    margin-right: 24px;
  }

  & > div:last-child {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    display: block;
    & > div {
      margin-right: 0;
    }
  }
`

export const CategoriesListContainer = styled(Box)`
  width: 100%;
  max-height: 45vh;
  overflow-y: auto;
  overflow-x: hidden;
`

export const CategoriesList = styled(Box)`
  column-count: 2;
  max-width: 100%;
  @media (max-width: 768px) {
    column-count: 1;
  }
`
export const ActionContainerStyled = styled(Flex)`
  background-color: #fff;
  justify-content: end;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #e6e6e6;
  width: calc(100% - 88px);
  padding: 12px;
  margin-top: 32px;
  position: absolute;
  bottom: 0;
  right: 15px;
  z-index: 1;
  @media (max-width: 768px) {
    width: 100%;
  }
`
export const ActionContainerStickyStyled = styled(Flex)`
  background-color: #fff;
  justify-content: end;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #e6e6e6;
  padding: 12px;
  margin-top: 32px;
  z-index: 1;
  position: sticky;
  bottom: -40px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const ActionButton = styled(Button)`
  width: 168px;
  margin-left: 16px;

  @media (max-width: 768px) {
    width: 120px;
    margin-left: 8px;
  }
`
