import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Campo requerido'),
})

const initialValues = {
  name: '',
}

export const SaveComparatorModal = ({ isOpen = false, onClose, handleSaveComparator }) => {
  const { resetForm, handleSubmit, handleChange, errors, values, isSubmitting } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSaveComparator,
  })

  const handleClose = () => {
    onClose()
    resetForm()
  }

  return (
    <Modal
      isOpen={isOpen}
      id="save-comparator-modal"
      onModalClose={handleClose}
      closeWithButton
      title="Guardar comparativo"
      maxWidth="570px">
      <Modal.Content>
        <form onSubmit={handleSubmit}>
          <Flex flexDirection="column">
            <Flex width="100%" flexDirection="column">
              <Flex width="100%" marginRight={2}>
                <Flex flexDirection="column" width="100%">
                  <Field
                    label="Nombre del comparativo"
                    required
                    sizeText="display16"
                    marginBottom={3}
                    marginTop={5}>
                    <Input
                      required
                      placeholderMessage="Nombre del comparativo"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      hasError={errors.name}
                      disabled={isSubmitting}
                    />
                  </Field>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </form>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="center">
          <Flex width="100%">
            <Button
              id="cancel-button"
              fullWidth
              marginRight={5}
              colorType="transparent"
              action={handleClose}>
              Cancelar
            </Button>
            <Button fullWidth colorType="orange" action={handleSubmit} disabled={isSubmitting}>
              Guardar
            </Button>
          </Flex>
        </Flex>
      </Modal.Actions>
    </Modal>
  )
}
