/**
 * Create the store with dynamic reducers
 */

import { createStore, compose } from 'redux'
// import logger from 'redux-logger';
import createReducer from '../reducers'

const configureStore = (initialState = {}) => {
  let composeEnhancers = compose

  const NODE_ENV = process?.env?.NODE_ENV || env?.NODE_ENV

  if (NODE_ENV !== 'production' && typeof window === 'object') {
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    // apply logger middleware if not in PROD
    // middlewares = [...middlewares, logger];
  }

  const store = createStore(createReducer(), initialState, composeEnhancers())

  store.injectedReducers = {} // Reducer registry
  if (module.hot) {
    module.hot.accept('../reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers))
    })
  }

  return store
}

const store = configureStore({})
export { store }
