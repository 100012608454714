import { useState } from 'react'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Help } from '@proveoeng/uikit/dist/atoms/Icons'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Tooltip } from '@proveoeng/uikit/dist/atoms/Tooltip'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { formatNumber } from '../../../common/functions/comparativeNumbers'

export const DiscountNumber = ({ discount }) => {
  const [active, setActive] = useState(false)

  if (discount)
    return (
      <Flex alignItems="flex-start" justifyContent="center">
        <Text
          textAlign="right"
          color="black"
          overflow="hidden"
          fontWeight="regular"
          sizeText="display12">
          {`${formatNumber(discount / 100)} %`}
        </Text>
        <Tooltip
          active={active}
          setActive={setActive}
          content="El precio incluye el % de descuento comercial">
          <Flex alignItems="center" marginTop="-6px" marginRight="-6px">
            <Icon sizeIcon="display24">
              <Help />
            </Icon>
          </Flex>
        </Tooltip>
      </Flex>
    )

  return null
}
