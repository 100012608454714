import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import Settings from '@proveoeng/uikit/dist/atoms/Icons/Settings'
import DuplicateFile from '@proveoeng/uikit/dist/atoms/Icons/DuplicateFile'
import { Info } from '@proveoeng/uikit/dist/organisms/Cards/Info'
import { ModalDuplicateProject } from '../components/ModalDuplicateProject'

export const CreateProjectTemplate = ({
  projects,
  goToCreateNew,
  isOpenedPopupDuplicate,
  onSubmitDuplication,
  goToHome,
  handleOpenPopupDuplicate,
  handleClosePopupDuplicate,
}) => (
  <Flex height="100%" width="100%" paddingY={7} paddingX={9} flexDirection="column">
    <Flex height="100%" width="100%" flexDirection="column" maxWidth={936}>
      <Text as="span" sizeText="display14" color="gray2" paddingBottom={6}>
        <Text
          as="span"
          sizeText="display14"
          color="blue1"
          marginBottom={7}
          style={{ cursor: 'pointer' }}
          onClick={goToHome}>
          {'<'} Volver
        </Text>{' '}
        | Tus obras /
        <Text as="span" sizeText="display14" color="black">
          {' '}
          Crea obra nueva
        </Text>
      </Text>
      <Text
        sizeText="display52"
        color="black"
        marginBottom={5}
        fontWeight="bold"
        style={{ lineHeight: '80px' }}>
        Crea tu obra desde cero o utiliza uno de nuestros atajos
      </Text>
      <Flex>
        <Info
          id="import"
          title="Crea una obra desde un importador o desde cero"
          icon={
            <Icon color="gray2" sizeIcon="display30">
              <Settings />
            </Icon>
          }
          justifyContent="flex-start"
          description="Importa un fichero excel. <br />También puedes crear una obra a tu medida desde cero si lo prefieres."
          height="240px"
          onClick={() => goToCreateNew()}
          style={{ paddingTop: '64px', cursor: 'pointer' }}
        />

        <Info
          id="import"
          title="Duplica una obra"
          icon={
            <Icon color="gray2" sizeIcon="display30">
              <DuplicateFile />
            </Icon>
          }
          justifyContent="flex-start"
          description="Duplica uno de tus proyectos existentes"
          marginLeft={5}
          height="240px"
          onClick={handleOpenPopupDuplicate}
          style={{ paddingTop: '64px', cursor: 'pointer' }}
        />
      </Flex>
      <ModalDuplicateProject
        onSubmitDuplication={onSubmitDuplication}
        isOpened={isOpenedPopupDuplicate}
        onClose={handleClosePopupDuplicate}
        projects={projects}
      />
    </Flex>
  </Flex>
)
