import { useState } from 'react'
import { serializeObject } from '../functions/serialize'

export const useForms = (callback) => {
  const [values, setValues] = useState([])

  const handleSubmit = (event) => {
    if (event) event.preventDefault()
    callback(values)
  }

  const handleChange = (form) => {
    setValues(serializeObject(form))
  }

  const resetValues = () => {
    setValues({})
  }

  return {
    handleChange,
    handleSubmit,
    values,
    resetValues,
  }
}
