import { getParamsSerializer } from '../../../../lib/request'

export const services = {
  getCatalog: (params = {}) => ({
    route: `/enviro/catalog`,
    method: 'get',
    params,
    paramsSerializer: getParamsSerializer,
  }),
  createCatalog: (body) => ({
    route: `/enviro/catalog`,
    method: 'post',
    body,
  }),
}
