import { FormatDate } from '../../../mappers/FormatDate'

const numberOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

export const OfferPendingMapper = {
  hydrate({
    bidPackageId,
    projectName,
    projectImage,
    buyerName,
    bidEndsAt,
    paymentDays,
    paymentConditions,
    categoryName,
    offerId,
  }) {
    return {
      bidPackageId,
      projectName,
      projectImage,
      buyerName,
      bidEndsAt: FormatDate.hydrate(bidEndsAt),
      paymentDays,
      paymentConditions,
      categoryName,
      offerId,
    }
  },
}

export const OfferProposalMapper = {
  hydrate({
    proposalId,
    bidPackageId,
    projectName,
    projectImage,
    total,
    updatedAt,
    state,
    bidPackageName,
    ...rest
  }) {
    return {
      proposalId,
      bidPackageId,
      projectName,
      projectImage,
      total: ((total || 0) / 100).toLocaleString('de-DE', numberOptions),
      updatedAt: FormatDate.hydrate(updatedAt),
      state: state?.toUpperCase(),
      bidPackageName,
      ...rest,
    }
  },
}

export const EmployeeMapper = {
  hydrate({ employeeRole, ...rest }) {
    return {
      role: employeeRole,
      ...rest,
    }
  },
}

export const ProfileEmployeeMapper = {
  hydrate({ name, phone, position }) {
    return {
      name,
      phone,
      position: position || null,
    }
  },
}
