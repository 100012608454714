import { v4 as uuidv4 } from 'uuid'

// SHAME: Verás que en la linia 51, en createInvitation() se ha hardcodeado un body que no hace falta, pero si no lo pasas la API peta
// SHAME: No es necesario porque el diseño no lo requiere ni nada lo requiere, pero... desde BE no se quiere eliminar por si acaso en un futuro
// SHAME: Alguien quisiera que se usara... Total, que ahí está, un body harcoded a null mu' bonico, no te asustes.

export const constants = {
  GET_PENDING_OFFERS: 'GET_PENDING_OFFERS',
  GET_PENDING_OFFERS_SUCCESS: 'GET_PENDING_OFFERS_SUCCESS',
  CREATE_ATTACHMENT_PENDING_OFFER: 'CREATE_ATTACHMENT_PENDING_OFFER',
  CREATE_ATTACHMENT_PENDING_OFFER_SUCCESS: 'CREATE_ATTACHMENT_PENDING_OFFER_SUCCESS',
  GET_OFFERS_PROJECTS: 'GET_OFFERS_PROJECTS',
  GET_OFFERS_PROJECTS_SUCCESS: 'GET_OFFERS_PROJECTS_SUCCESS',
  CREATE_ATTACHMENT_OFFER_PROJECT: 'CREATE_ATTACHMENT_OFFER_PROJECT',
  CREATE_ATTACHMENT_OFFER_PROJECT_SUCCESS: 'CREATE_ATTACHMENT_OFFER_PROJECT_SUCCESS',
  GET_PENDING_PROPOSALS: 'GET_PENDING_PROPOSALS',
  GET_PENDING_PROPOSALS_SUCCESS: 'GET_PENDING_PROPOSALS_SUCCESS',
  CREATE_ATTACHMENT_PENDING_PROPOSAL: 'CREATE_ATTACHMENT_PENDING_PROPOSAL',
  CREATE_ATTACHMENT_PENDING_PROPOSAL_SUCCESS: 'CREATE_ATTACHMENT_PENDING_PROPOSAL_SUCCESS',
  GET_EMPLOYEE: 'GET_EMPLOYEE',
  GET_EMPLOYEE_SUCCESS: 'GET_EMPLOYEE_SUCCESS',
  SET_EMPLOYEE_DATA: 'SET_EMPLOYEE_DATA',
  SET_EMPLOYEE_DATA_SUCCESS: 'SET_EMPLOYEE_DATA_SUCCESS',
  SET_STORE_EMPLOYEE: 'SET_STORE_EMPLOYEE',
  SET_EMPLOYEE_AVATAR: 'SET_EMPLOYEE_AVATAR',
  SET_EMPLOYEE_INVITATION: 'SET_EMPLOYEE_INVITATION',
  SET_EMPLOYEE_INVITATION_SUCCESS: 'SET_EMPLOYEE_INVITATION_SUCCESS',
  SEND_INVITATION: 'SEND_INVITATION',
  SEND_INVITATION_SUCCESS: 'SEND_INVITATION_SUCCESS',
  EMPLOYEE_FAIL: 'EMPLOYEE_FAIL',
  RESET_OPENED_OFFER: 'RESET_OPENED_OFFER',
  CREATE_THUMB_PENDING_OFFER_SUCCESS: 'CREATE_THUMB_PENDING_OFFER_SUCCESS',
  CREATE_THUMB_PENDING_PROPOSAL_SUCCESS: 'CREATE_THUMB_PENDING_PROPOSAL_SUCCESS',
  ADD_IMAGE: 'ADD_IMAGE',
  REMOVE_IMAGE: 'REMOVE_IMAGE',
}

export const services = {
  getEmployee: () => ({
    route: `/employees/current`,
    method: 'get',
  }),
  postEmployeeData: (body) => ({
    route: `/employees/current/profile`,
    method: 'post',
    body,
  }),
  postEmployeeAvatar: (body) => ({
    route: `/employees/current/profile/avatar`,
    method: 'post',
    headers: {
      accept: '*/*',
    },
    formData: true,
    body,
  }),
  createInvitation: (employeeId, body) => ({
    route: `/employees/${employeeId}/invitations/${uuidv4()}`,
    method: 'put',
    body: {
      ...body,
      companyData: {
        name: null,
        address: null,
        postCode: null,
        city: null,
        phone: null,
        scope: null,
        description: null,
        taxCode: null,
        billingName: null,
        billingAddress: null,
        billingPostCode: null,
        billingCity: null,
        billingPhone: null,
      },
    },
  }),
  sendInvitation: (id) => ({
    route: `/invitations/send/${id}`,
    method: 'post',
  }),
  getPendingOffers: () => ({
    route: `/employees/current/offers/pending`,
    method: 'get',
  }),
  getProposalsOffers: () => ({
    route: `/employees/current/proposals`,
    method: 'get',
  }),

  getExistingEmployee: (employeeId) => ({
    route: `/employees/${employeeId}`,
    method: 'get',
  }),

  updateExistingEmployee: (employeeId, body) => ({
    route: `/employees/${employeeId}/profile`,
    method: 'post',
    body,
  }),

  updateAvatarExistingEmployee: (employeeId, body) => ({
    route: `/employees/${employeeId}/profile/avatar`,
    method: 'post',
    headers: {
      accept: '*/*',
    },
    formData: true,
    body,
  }),
}
