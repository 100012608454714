import { Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { currencyFormat } from '../../../../lib/utils/Format'
import { formatDecimal, formatNumber } from '../../../common/functions/comparativeNumbers'

export const SummaryK = ({ data, columns, tableWidthCSS }) => {
  const getK = (offer) =>
    data.getTotalBudgetCost() && offer?.getTotal(data.sections)
      ? (data.getTotalBudgetCost() / offer?.getTotal(data.sections)).toFixed(2)
      : 0

  const getMargin = (offer) =>
    data.getTotalBudgetCost() && offer?.getTotal(data.sections)
      ? data.getTotalBudgetCost() - offer?.getTotal(data.sections)
      : 0

  const getKColor = (value) => {
    if (data?.kZero === null) return 'gray1'

    if (parseFloat(value) > Math.round(parseFloat(data?.kZero / 100))) {
      return 'green1'
    }
    if (parseFloat(value) < Math.round(parseFloat(data?.kZero / 100))) {
      return 'red1'
    }
    return 'gray1'
  }

  const getMarginColor = (value) => {
    if (data?.marginZero === null) return 'gray1'

    if (parseFloat(value) > 0) {
      return 'green1'
    }
    if (parseFloat(value) < 0) {
      return 'red1'
    }
    return 'gray1'
  }

  return (
    <>
      <Grid
        gridTemplateAreas={columns.area}
        gridTemplateColumns={columns.columns}
        style={{ width: tableWidthCSS }}>
        <GridItem
          gridArea="description"
          bg="white2"
          borderRightColor="gray3"
          borderRightStyle="solid"
          borderRightWidth="1px"
          padding={2}
          paddingTop={4}
          paddingLeft="30px"
          style={{
            position: 'sticky',
            left: 0,
            zIndex: 9,
          }}>
          <Flex justifyContent="space-between">
            <Flex width="50%">
              <Text color="gray1" overflow="hidden" fontWeight="700" sizeText="display14">
                K de paso y Margen de venta
              </Text>
            </Flex>
            <Flex width="50%" justifyContent="flex-end">
              <Text
                color="gray1"
                overflow="hidden"
                sizeText="display14"
                textAlign="right"
                fontWeight="600"
                paddingRight={1}>
                K de margen 0:
              </Text>
              <Text color="gray1" fontWeight="700" sizeText="display14" textAlign="right">
                {data?.kZero === null ? '-' : formatDecimal(data?.kZero)}
              </Text>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem
          gridArea="budgetK"
          bg="white2"
          padding={2}
          paddingY={4}
          style={{
            position: 'sticky',
            left: 510,
            zIndex: 9,
          }}
        />
        <GridItem
          gridArea="budget"
          bg="white2"
          borderRightColor="gray3"
          borderRightStyle="solid"
          borderRightWidth="1px"
          padding={2}
          paddingY={4}
          style={{
            position: 'sticky',
            left: 600,
            zIndex: 9,
          }}>
          <Text
            textAlign="right"
            color="gray1"
            overflow="hidden"
            fontWeight="700"
            sizeText="display12">
            -
          </Text>
        </GridItem>
        <GridItem
          gridArea="expectedCostsK"
          bg="white2"
          padding={2}
          paddingY={4}
          style={{
            position: 'sticky',
            left: 730,
            zIndex: 9,
          }}>
          <Text
            textAlign="right"
            color="gray1"
            overflow="hidden"
            fontWeight="700"
            sizeText="display12">
            {formatNumber(data.getTotalExpectedCostK())}
          </Text>
        </GridItem>
        <GridItem
          gridArea="expectedCosts"
          bg="white2"
          borderRightColor="gray3"
          borderRightStyle="solid"
          borderRightWidth="1px"
          padding={2}
          paddingY={4}
          style={{
            position: 'sticky',
            left: 820,
            zIndex: 9,
          }}>
          <Text
            textAlign="right"
            color="gray1"
            overflow="hidden"
            fontWeight="700"
            sizeText="display12">
            {currencyFormat(data.getTotalExpectedCostDiff())}
          </Text>
        </GridItem>
        <GridItem
          gridArea="minEnvelopeK"
          bg="white2"
          padding={2}
          paddingY={4}
          style={{
            position: 'sticky',
            left: 950,
            zIndex: 9,
          }}>
          <Text
            textAlign="right"
            color={getKColor(data.getTotalMinEnvelopeK())}
            overflow="hidden"
            fontWeight="700"
            sizeText="display12">
            {formatNumber(data.getTotalMinEnvelopeK())}
          </Text>
        </GridItem>
        <GridItem
          gridArea="minEnvelope"
          bg="white2"
          borderRightColor="gray3"
          borderRightStyle="solid"
          borderRightWidth="1px"
          padding={2}
          paddingY={4}
          style={{
            position: 'sticky',
            left: 1040,
            zIndex: 9,
          }}>
          <Text
            textAlign="right"
            color={getMarginColor(data.getTotalMinEnvelopeDiff())}
            overflow="hidden"
            fontWeight="700"
            sizeText="display12">
            {currencyFormat(data.getTotalMinEnvelopeDiff())}
          </Text>
        </GridItem>

        {data.offers.map((offer) => (
          <>
            <GridItem gridArea={`offer-k-${offer.id}`} bg="white" padding={2} paddingTop={4}>
              <Text
                textAlign="right"
                color={getKColor(getK(offer))}
                overflow="hidden"
                fontWeight="700"
                sizeText="display12">
                {formatNumber(getK(offer)) || '-'}
              </Text>
            </GridItem>
            <GridItem
              gridArea={`offer-margin-${offer.id}`}
              bg="white"
              borderRightColor="gray3"
              borderRightStyle="solid"
              borderRightWidth="1px"
              padding={2}
              paddingTop={4}>
              <Text
                textAlign="right"
                color={getMarginColor(getMargin(offer))}
                overflow="hidden"
                fontWeight="700"
                sizeText="display12">
                {data.getTotalBudgetCost() && offer?.getTotal(data.sections)
                  ? currencyFormat(data.getTotalBudgetCost() - offer?.getTotal(data.sections))
                  : '-'}
              </Text>
            </GridItem>
          </>
        ))}
      </Grid>
    </>
  )
}
