import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { LoginTemplate } from '../template/LoginTemplate'
import { useAuth } from '../../../common/hooks/useAuth'
import { getLocalStorage } from '../../../common/functions/cookies'

const validationSchema = Yup.object().shape({
  username: Yup.string().required('Debe ingresar un email').email('Debe ingresar un email válido'),
  password: Yup.string()
    .required('Debe ingresar una contraseña')
    .min(6, 'Debe tener al menos 6 caracteres')
    .max(80, 'Debe tener máximo 80 caracteres'),
})

const LoginPage = () => {
  const history = useHistory()
  const location = useLocation()

  const {
    data: { employeeType },
  } = useSelector((state) => state.auth)

  const { checkLogin } = useAuth()

  const goToHome = (type) => {
    let defaultHomePage = '/'
    if (type === 'Buyer') {
      defaultHomePage = '/constructora/tus-obras'
    } else if (type === 'Seller') {
      defaultHomePage = '/proveedor/compras-nuevas'
    }
    const { from } = location.state || { from: { pathname: defaultHomePage } }
    history.replace(from)
  }

  const onSubmit = async (data) => {
    const loginData = await checkLogin(data)

    goToHome(loginData?.employeeType)
  }

  const { values, errors, handleBlur, touched, handleSubmit, handleChange, isValid } = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit,
    validationSchema,
  })

  useEffect(() => {
    if (
      getLocalStorage(process?.env?.REACT_APP_COOKIE_LOGIN_NAME || env?.REACT_APP_COOKIE_LOGIN_NAME)
    ) {
      goToHome(employeeType)
    }
  }, [])

  return (
    <LoginTemplate
      values={values}
      errors={errors}
      handleChange={handleChange}
      onSubmit={handleSubmit}
      handleBlur={handleBlur}
      touched={touched}
      isValid={isValid}
    />
  )
}

export default LoginPage
