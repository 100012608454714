import { useState } from 'react'

import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon/Icon'
import Help from '@proveoeng/uikit/dist/atoms/Icons/Help'
import { Box, Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { Tooltip } from '@proveoeng/uikit/dist/atoms/Tooltip'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography/Typography'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'

export const OfferImportGuide = ({ withTooltip }) => {
  const [importGuideOpened, setImportGuideOpened] = useState(false)
  const [contentIsOpened, setContentIsOpened] = useState(false)

  const Content = () => (
    <Box>
      <Text as="h4" sizeText="display18" color="black" fontWeight="medium" mb={2}>
        ¿Cómo importar mi oferta?
      </Text>
      <Text sizeText="display14" color="black" fontWeight="regular">
        ¡Es muy fácil! Solo necesitas descargar la compra y rellenar los precios.
      </Text>
      <Box mt={4} mb={2}>
        <Button colorType="transparent" action={() => setImportGuideOpened(true)}>
          Guía de importación
        </Button>
      </Box>
    </Box>
  )

  return (
    <>
      {withTooltip ? (
        <Tooltip
          content={<Content />}
          active={contentIsOpened}
          setActive={setContentIsOpened}
          disableHover
          bgColor="gray4"
          width="450px">
          <Button colorType="none" sizeButton="none">
            <Icon sizeIcon="display30">
              <Help />
            </Icon>
          </Button>
        </Tooltip>
      ) : (
        <Content />
      )}
      <Modal
        isOpen={importGuideOpened}
        id="import-guide-modal"
        onModalClose={() => setImportGuideOpened(false)}
        closeWithButton
        title="Guía de importación"
        maxWidth="808px">
        <Modal.Content>
          <Box paddingBottom={8}>
            <Text as="p" sizeText="display16">
              Para importar tus precios en la plataforma, hay que descargar la plantilla con todas
              las partidas. Tienes que rellenar la plantilla con los precios que quieres ofertar y
              después subirla. Los precios se pueden editar después.
            </Text>
          </Box>
        </Modal.Content>
        <Modal.Actions>
          <Flex justifyContent="flex-end">
            <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr">
              <Button colorType="transparent" action={() => setImportGuideOpened(false)}>
                Hecho
              </Button>
            </Grid>
          </Flex>
        </Modal.Actions>
      </Modal>
    </>
  )
}
