import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Box, Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'

export const ModalDeleteBidItem = ({
  deleteBidItemModalIsOpened,
  setDeleteBidItemModalIsOpened,
  setSelectId,
  onDeleteBidItem,
  selectId,
}) => (
  <>
    <Modal
      isOpen={deleteBidItemModalIsOpened}
      id="delete-package-modal"
      onModalClose={() => {
        setDeleteBidItemModalIsOpened(false)
        setSelectId(null)
      }}
      minWidth="300"
      width="390px"
      paddingTop={6}>
      <Modal.Content>
        <Box>
          <Text sizeText="display16">¿Seguro que quieres eliminar esta partida?</Text>
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Grid gridTemplateColumns="1fr 1fr" gridTemplateRows="1fr" gridColumnGap={5}>
            <Button
              colorType="orange"
              action={() => {
                onDeleteBidItem(selectId)
                setDeleteBidItemModalIsOpened(false)
              }}>
              Si
            </Button>
            <Button
              id="cancel-button"
              colorType="black"
              action={() => {
                setDeleteBidItemModalIsOpened(false)
                setSelectId(null)
              }}>
              No
            </Button>
          </Grid>
        </Flex>
      </Modal.Actions>
    </Modal>
  </>
)
