import { Box } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

import { AttachmentList } from '../../../../common/components/attachmentList/Attachment'
import { Items } from '../../../../common/components/item/Items'
import { Links } from '../../../../common/components/links/Links'
import { FormatDate } from '../../../../common/mappers/FormatDate'

/**
 *
 * @param {{ project: Project }} props
 */
export const ProjectInfo = ({ project }) => {
  const items = [
    {
      title: 'Referencia / OT',
      children: project.reference,
    },
    {
      title: 'Fechas de inicio y fin previstas',
      children: `${project.start && FormatDate.hydrate(project.start).readableReverseDate} - ${
        project.end && FormatDate.hydrate(project.end).readableReverseDate
      }`,
    },
    {
      title: 'Dirección',
      children: `${project.address}, ${project.city}, ${project.postCode}`,
    },
    {
      title: 'Cliente',
      children: project.customer,
    },
    {
      title: 'Tipo de cliente',
      children: project.constructionTypeFormatted,
    },
    {
      title: 'Tipo de proyecto',
      children: project.projectTypeFormatted,
    },
    {
      title: 'Descripción',
      children: (
        <Text
          as="p"
          sizeText="display14"
          color="black"
          style={{
            whiteSpace: 'pre-wrap',
          }}>
          {project.description || '-'}
        </Text>
      ),
    },
    {
      title: 'Adjuntos y enlaces',
      children: (
        <Box>
          <AttachmentList files={project.files} withDownload />
          <Links links={project.links} />
        </Box>
      ),
    },
  ]
  return (
    <>
      <Items items={items} />
    </>
  )
}
