import { useEffect } from 'react'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { SelectInput } from '@proveoeng/uikit/dist/atoms/Select'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Textarea } from '@proveoeng/uikit/dist/atoms/Textarea'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Uploader } from '@proveoeng/uikit/dist/atoms/Uploader'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import Close from '@proveoeng/uikit/dist/atoms/Icons/Close'
import { InputNumber } from '@proveoeng/uikit/dist/atoms/InputNumber'

import { LinksList } from '../../../../../common/components/links/LinksList'
import { paymentDaysOptions } from '../../../config'
import { AttachmentList } from '../../../../../common/components/attachmentList/Attachment'

export const EditBidPackage = ({
  setData,
  data,
  errorsData,
  onDeleteFile,
  files,
  onImagesLoad,
  errorsImages,
  setFormIsOpened,
  title,
  onSubmit,
  categories,
  links = [],
  inputLink,
  setInputLink,
  addLink,
  handleAddDescription,
  removeLink,
  verifyUrl,
}) => {
  let formData

  useEffect(() => {
    setData(formData)
  }, [])

  return (
    <>
      <Box
        position="fixed"
        top={0}
        right={0}
        height="100%"
        minHeight="100vh"
        width="475px"
        boxShadow="topInner"
        backgroundColor="white"
        padding={6}
        overflow="auto">
        <Flex justifyContent="space-between" alignItems="baseline">
          <Text sizeText="display30" fontWeight="bold">
            {title}
          </Text>
          <Flex style={{ cursor: 'pointer' }}>
            <Icon
              sizeIcon="display18"
              color="gray1"
              onClick={() => {
                setFormIsOpened(false)
              }}>
              <Close />
            </Icon>
          </Flex>
        </Flex>
        <Box
          as="form"
          ref={(form) => {
            formData = form
          }}
          onSubmit={() => {
            onSubmit()
            formData.scrollIntoView()
          }}>
          <Field label="Nombre" required sizeText="display16" marginBottom={3}>
            <Input
              required
              placeholderMessage="Da un nombre a tu proceso de compra"
              maxLength={50}
              ariaLabel="Nombre"
              action={() => setData(formData)}
              defaultValue={data?.name}
              name="name"
              minLength={2}
              hasError={errorsData?.name}
            />
          </Field>
          <Field label="Naturaleza" required sizeText="display16" marginBottom={3} marginTop={5}>
            <SelectInput
              isSearchable
              required
              name="categoryId"
              id="categoryId"
              onChange={() => setTimeout(() => setData(formData), 0)}
              options={categories}
              placeholderMessage="Selecciona a que naturaleza pertenece esta compra"
              ariaLabel="turnover"
              hasError={errorsData?.categoryId}
              defaultValue={
                data?.categoryId && categories.find((el) => el.value === data?.categoryId)
              }
            />
          </Field>
          <Field label="Descripción" sizeText="display16" marginBottom={3} marginTop={5}>
            <Textarea
              placeholderMessage="Describe las características de este proceso de compra para los proveedores"
              name="description"
              typeAction={() => setData(formData)}
              hasError={errorsData?.description}
              defaultValue={data?.description}
              maxLength="auto"
            />
          </Field>
          <Flex>
            <Flex
              width="100%"
              paddingRight={1}
              justifyContent="space-between"
              flexDirection="column">
              <Field
                required
                label="Fecha límite para presentar oferta"
                sizeText="display16"
                marginBottom={3}
                marginTop={5}>
                <Input
                  required
                  type="date"
                  ariaLabel="bidEndsAt"
                  action={() => setData(formData)}
                  defaultValue={data?.bidEndsAt}
                  name="bidEndsAt"
                  hasError={errorsData?.bidEndsAt}
                  min={new Date().toISOString().split('T')[0]}
                />
              </Field>
            </Flex>
          </Flex>
          <Flex>
            <Flex
              width="50%"
              paddingRight={1}
              justifyContent="space-between"
              flexDirection="column">
              <Field
                label="Fecha inicio de entrega"
                sizeText="display16"
                marginBottom={3}
                marginTop={5}>
                <Input
                  type="date"
                  ariaLabel="start"
                  action={() => setData(formData)}
                  defaultValue={data?.deliveryDates?.start}
                  name="start"
                  hasError={errorsData?.start}
                  min={new Date().toISOString().split('T')[0]}
                />
              </Field>
            </Flex>
            <Flex
              width="50%"
              paddingRight={1}
              justifyContent="space-between"
              flexDirection="column">
              <Field
                label="Fecha fin de entrega"
                sizeText="display16"
                marginBottom={3}
                marginTop={5}>
                <Input
                  type="date"
                  ariaLabel="end"
                  action={() => setData(formData)}
                  defaultValue={data?.deliveryDates?.end}
                  name="end"
                  hasError={errorsData?.end}
                  min={data?.start || new Date().toISOString().split('T')[0]}
                />
              </Field>
            </Flex>
          </Flex>
          <Field label="Retención (%)" sizeText="display16" marginBottom={3} marginTop={5}>
            <InputNumber
              name="retention"
              placeholderMessage="Indica el % de retención por garantía"
              ariaLabel="Retención"
              onChange={() => setData(formData)}
              value={data?.retention}
              hasError={errorsData?.retention}
            />
          </Field>
          <Field label="Forma de pago" sizeText="display16" marginBottom={3} marginTop={5}>
            <Input
              placeholderMessage="Escribe aquí tus condiciones de pago"
              ariaLabel="Nombre"
              action={() => setData(formData)}
              defaultValue={data?.paymentConditions}
              name="paymentConditions"
              hasError={errorsData?.paymentConditions}
            />
          </Field>
          <Field label="Días de pago" required sizeText="display16" marginBottom={3} marginTop={5}>
            <SelectInput
              isSearchable
              required
              name="paymentDays"
              id="paymentDays"
              onChange={() => setTimeout(() => setData(formData), 0)}
              options={paymentDaysOptions}
              placeholderMessage="Seleccionar los días de pago"
              ariaLabel="turnover"
              hasError={errorsData?.paymentDays}
              defaultValue={
                data?.paymentDays && paymentDaysOptions.find((el) => el.value === data?.paymentDays)
              }
            />
          </Field>
          <Field
            label="Condiciones generales para el proveedor"
            sizeText="display16"
            marginBottom={3}
            marginTop={5}>
            <Textarea
              placeholderMessage="Describe las condiciones de este proceso de compra para los proveedores. Podrás adjuntar archivos en el siguiente paso."
              name="generalConditions"
              typeAction={() => setData(formData)}
              hasError={errorsData?.generalConditions}
              defaultValue={data?.generalConditions}
              maxLength="auto"
            />
          </Field>
          <Field label="Documentos" sizeText="display16" marginBottom={3} marginTop={5}>
            <AttachmentList files={files} withDelete onDeleteFile={onDeleteFile} />
            <Uploader
              multiple
              name="files"
              marginBottom={3}
              errors={errorsImages}
              maxNumFiles={5}
              onImagesLoad={onImagesLoad}
              descriptionMessage="Arrastra, o haz click para subir tus documentos aquí"
              accept="all"
              padding={5}
            />
          </Field>
          <Box marginTop={7}>
            <LinksList
              links={links}
              inputLink={inputLink}
              setInputLink={setInputLink}
              addLink={addLink}
              handleAddDescription={handleAddDescription}
              removeLink={removeLink}
              verifyUrl={verifyUrl}
            />
          </Box>
          <Button
            action={() => {
              onSubmit()
              formData.scrollIntoView()
            }}
            colorType="orange"
            fullWidth
            marginTop={5}>
            Guardar
          </Button>
        </Box>
      </Box>
    </>
  )
}
