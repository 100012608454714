import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { ButtonTabs } from '@proveoeng/uikit/dist/atoms/Button'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Button } from '@proveoeng/uikit/dist/atoms/Button/Button'
import { Card } from '@proveoeng/uikit/dist/atoms/Card'
import { OfferItemList } from '../components/OfferItemList'
import { ProposalInfo } from '../components/ProposalInfo'

import { currencyFormat, parseToInteger } from '../../../../../lib/utils/Format'
import { ModalSendMessage } from '../../../../common/components/sendMessage/ModalSendMessage'
import { colorState, copyState } from '../../config'
import { MessagesList } from '../../common/components/MessagesList'
import { ModalSellerDetails } from '../../common/components/ModalSellerDetails'
import { OfferEmissions } from '../components/OfferEmissions'
import { OfferEnvironmentalStatement } from '../components/OfferEnvironmentalStatement'

/**
 * @param {{
 *  bidPackageSelected: BidPackage,
 *  proposalSelected: Proposal,
 *  proposalItems: ProposalItem[],
 *  exportProject: () => void,
 *  tabItems: Array,
 *  activeTab: number,
 *  project: object,
 *  history: () => void,
 * }} props
 */
export const OfferDetailTemplate = ({
  proposalSelected,
  bidPackageSelected,
  exportProject,
  proposalItems,
  tabItems,
  activeTab,
  handleSendMessage,
  sendModalIsOpen,
  setSendModalIsOpen,
  project,
  history,
  messagesList,
  employeeEmail,
  setDetailsIsOpened,
  detailsIsOpened,
  proposalsBySeller,
  seller,
  handleOpenDetail,
  totalEnviro,
  environmentalStatement,
}) => {
  const parseFloatNumber = (value) => parseToInteger(value) / 100

  const setDiscount = () =>
    currencyFormat(
      parseFloatNumber(proposalSelected?.total) *
        (1 - parseFloatNumber(proposalSelected?.discount) / 100),
    )

  return (
    <Flex height="100%" width="100%" flexDirection="column">
      <Flex justifyContent="space-between" width="100%" alignItems="center">
        <Text as="span" sizeText="display14" color="gray2">
          <Text
            as="span"
            sizeText="display14"
            color="blue1"
            marginBottom={7}
            style={{ cursor: 'pointer' }}
            onClick={() =>
              history.push(
                `/constructora/tus-obras/${project?.projectId}/${bidPackageSelected?.bidPackageId}`,
              )
            }>
            {'<'} Volver
          </Text>{' '}
          |{' '}
          <Text
            as="span"
            sizeText="display14"
            marginX={1}
            color="gray2"
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => history.push('/constructora/tus-obras')}>
            Tus obras
          </Text>{' '}
          /
          <Text
            as="span"
            sizeText="display14"
            marginX={1}
            color="gray2"
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => history.push(`/constructora/tus-obras/${project?.projectId}`)}>
            {project?.name}
          </Text>{' '}
          /
          <Text
            as="span"
            sizeText="display14"
            marginX={1}
            color="gray2"
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() =>
              history.push(
                `/constructora/tus-obras/${project?.projectId}/${bidPackageSelected?.bidPackageId}`,
              )
            }>
            {bidPackageSelected?.name}
          </Text>{' '}
          /
          <Text as="span" sizeText="display14" color="black" marginX={1}>
            Propuesta recibida
          </Text>
        </Text>
      </Flex>
      <Flex width="100%" alignItems="center" justifyContent="space-between" paddingTop={7}>
        <Flex alignItems="center">
          <Text as="span" sizeText="display32" color="black" fontWeight="bold">
            {bidPackageSelected.name}
          </Text>
          <Flex alignItems="center" paddingLeft={4} minWidth="120px">
            <Box
              backgroundColor={colorState[bidPackageSelected?.state]}
              width="8px"
              height="8px"
              borderRadius={8}
              minWidth="8px"
            />
            <Text as="span" paddingLeft={2} color="gray1">
              {copyState[bidPackageSelected?.state]}
            </Text>
          </Flex>
        </Flex>
        <Flex flexDirection="column">
          <Box width="184px" marginRight={7}>
            <Button fullWidth colorType="transparent" action={exportProject}>
              Descargar oferta
            </Button>
          </Box>
        </Flex>
      </Flex>
      <Box width="100%">
        <Flex>
          <Flex paddingY={2} flexWrap="wrap" maxWidth="100%">
            <Card bg="gray4" margin={3} marginLeft={0} padding={4} minWidth="240px">
              <Flex flexDirection="column">
                <Text
                  as="span"
                  fontWeight="medium"
                  color="gray"
                  sizeText="display12"
                  marginBottom={2}>
                  Oferta
                </Text>
                <Text as="span" fontWeight="bold" color="black" sizeText="display30">
                  {setDiscount()}
                </Text>
              </Flex>
            </Card>
            <Card bg="gray4" margin={3} padding={4} minWidth="240px">
              <Flex flexDirection="column">
                <Text
                  as="span"
                  fontWeight="medium"
                  color="gray"
                  sizeText="display12"
                  marginBottom={2}>
                  Ppto. de venta
                </Text>
                <Text as="span" fontWeight="bold" color="black" sizeText="display30">
                  {currencyFormat(proposalSelected?.budget)}
                </Text>
              </Flex>
            </Card>
            <Card bg="gray4" margin={3} padding={4} minWidth="240px">
              <Flex flexDirection="column">
                <Text
                  as="span"
                  fontWeight="medium"
                  color="gray"
                  sizeText="display12"
                  marginBottom={2}>
                  Coste Previsto
                </Text>
                <Text as="span" fontWeight="bold" color="black" sizeText="display30">
                  {currencyFormat(proposalSelected?.expectedCosts)}
                </Text>
              </Flex>
            </Card>
          </Flex>
        </Flex>
        <Flex marginBottom={5}>
          <Text as="span" sizeText="display16" color="gray" paddingRight={2}>
            Proveedor: <strong>{proposalSelected?.sellerName}</strong>
          </Text>
          <Text
            as="span"
            fontWeight="medium"
            color="blue1"
            sizeText="display16"
            style={{ cursor: 'pointer' }}
            onClick={() => handleOpenDetail()}>
            Ver datos de contacto
          </Text>
        </Flex>

        <Flex marginBottom={5}>
          <ButtonTabs items={tabItems} />
        </Flex>

        {activeTab === 0 && (
          <ProposalInfo proposal={proposalSelected} bidPackage={bidPackageSelected} />
        )}
        {activeTab === 1 && (
          <OfferItemList proposalItems={proposalItems} total={proposalSelected?.total} />
        )}
        {activeTab === 2 && <OfferEmissions proposalItems={proposalItems} total={totalEnviro} />}
        {activeTab === 3 && (
          <MessagesList
            buttonAction={setSendModalIsOpen}
            messagesList={messagesList}
            contactMail={proposalSelected?.contact?.employeeEmail}
            employeeEmail={employeeEmail}
          />
        )}
        {activeTab === 4 && (
          <OfferEnvironmentalStatement
            proposal={proposalSelected}
            environmentalStatement={environmentalStatement}
          />
        )}
      </Box>
      <ModalSendMessage
        title={`Enviar notificación a ${proposalSelected?.sellerName}`}
        subTitle={
          <Text>
            <strong>{proposalSelected?.sellerName}</strong> recibirá un correo electrónico con el
            siguiente mensaje.
          </Text>
        }
        hasCancelButton={false}
        onSubmit={handleSendMessage}
        isOpened={sendModalIsOpen}
        setIsOpened={setSendModalIsOpen}
      />

      <ModalSellerDetails
        isOpen={detailsIsOpened}
        setIsOpened={setDetailsIsOpened}
        seller={seller}
        proposalsBySeller={proposalsBySeller}
      />
    </Flex>
  )
}
