import { Container, Flex, Box } from '@proveoeng/uikit/dist/atoms/Layout'
import { Card } from '@proveoeng/uikit/dist/atoms/Card'
import { LinkText, Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { InputPassword } from '@proveoeng/uikit/dist/atoms/InputPassword'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import Logo from '../../../common/assets/logo'

export const ResetPasswordTemplate = ({
  onSubmit,
  errors,
  values,
  handleChange,
  hash,
  isValid,
  handleBlur,
  touched,
}) => (
  <Container>
    <Flex alignItems="center" justifyContent="center" flexDirection="column" padding={9}>
      <Logo width="277px" />
      <Card padding={10} maxWidth={674} width="100%" marginY={7}>
        <Box as="form" onSubmit={onSubmit}>
          {hash ? (
            <Field
              label="Nueva contraseña"
              sizeText="display16"
              marginBottom={3}
              errorMessage={touched.newPassword && errors?.newPassword}>
              <InputPassword
                placeholderMessage="Escribe una nueva contraseña"
                ariaLabel="password"
                value={values.newPassword}
                onChange={handleChange}
                name="newPassword"
                hasError={touched.newPassword && !!errors?.newPassword}
                onBlur={handleBlur}
              />
            </Field>
          ) : (
            <Field
              label="Email"
              sizeText="display16"
              marginBottom={3}
              errorMessage={touched.email && errors?.email}>
              <Input
                type="email"
                name="email"
                placeholderMessage="Email"
                ariaLabel="Email"
                onChange={handleChange}
                value={values.email}
                onBlur={handleBlur}
                hasError={touched.email && !!errors?.email}
              />
            </Field>
          )}
          <Flex alignItems="center" justifyContent="center" flexDirection="column" marginTop={6}>
            <Button
              fullWidth
              colorType="orange"
              type="submit"
              disabled={!isValid || (!values?.newPassword && !values?.email)}>
              Recuperar la contraseña
            </Button>
            <LinkText href="/login" color="blue1" sizeText="display14" marginTop={6}>
              ¿Volver al Sign in?
            </LinkText>
          </Flex>
        </Box>
      </Card>
      <Text as="a" href="https://www.conkau.io" color="blue1" sizeText="display14">
        Ir a conkau.io
      </Text>
    </Flex>
  </Container>
)
