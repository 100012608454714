import { useDispatch } from 'react-redux'
import { requestApi } from '../../../lib/request'
import { services } from '../schemes/employees/config'
import { services as bidPackageServices } from '../schemes/bidPackages/config'
import {
  getEmployeeSuccess,
  sendInvitationSuccess,
  setInvitationEmployeeSuccess,
} from '../schemes/employees'
import { services as servicesHelper } from '../schemes/helpers/config'
import { useUtils } from './useUtils'
import { getBase64 } from '../functions/serialize'
import { ProfileEmployeeMapper } from '../schemes/employees/mapper'

export const useEmployee = () => {
  const dispatch = useDispatch()
  const { setAlert, setError, setLoading } = useUtils()

  const getFileEmployee = async (avatar) => {
    try {
      const response = await requestApi(servicesHelper.getFile(avatar))
      const { data, headers } = response
      dispatch(
        getEmployeeSuccess({
          avatar: `data:${headers['content-type']};base64,${getBase64(data)}`,
        }),
      )
    } catch (error) {
      setError(error)
    }
  }

  const getEmployee = async () => {
    setLoading(true)
    try {
      const {
        data: {
          data: { avatar, ...rest },
        },
      } = await requestApi(services.getEmployee())

      if (avatar) {
        getFileEmployee(avatar)
      }
      setLoading(false)
      dispatch(getEmployeeSuccess({ ...rest }))
      return rest
    } catch (error) {
      setLoading(false)
      setError(error)
      return false
    }
  }

  const setEmployee = async (data) => {
    setLoading(true)
    try {
      await requestApi(services.postEmployeeData(ProfileEmployeeMapper.hydrate(data)))
      await getEmployee()
      setAlert('success', 'Guardado correctamente')
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const setEmployeeAvatar = async (data) => {
    setLoading(true)
    try {
      await requestApi(services.postEmployeeAvatar(data))
      await getEmployee()
      setAlert('success', 'Guardado correctamente')
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const createInvitation = async (id, body) => {
    setLoading(true)
    try {
      const { data } = await requestApi(services.createInvitation(id, body))
      dispatch(setInvitationEmployeeSuccess(data))
      setAlert('success', 'Invitación enviada correctamente')
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const sendInvitation = async (id, bidPackageId, offerId, isSellerRegistered) => {
    setLoading(true)
    try {
      const invitationRequest = !isSellerRegistered
        ? services.sendInvitation(id)
        : bidPackageServices.actionBidPackageOffer(bidPackageId, offerId, {
            actionName: 'Notify',
            actionData: {},
          })
      const {
        data: { data },
      } = await requestApi(invitationRequest)
      dispatch(sendInvitationSuccess(data))
      setAlert(
        'success',
        !isSellerRegistered
          ? 'Invitación enviada correctamente'
          : 'Invitación reenviada correctamente',
      )
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  return {
    getEmployee,
    setEmployee,
    setEmployeeAvatar,
    createInvitation,
    sendInvitation,
  }
}
