import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useAuth } from '../../../common/hooks/useAuth'
import { deleteLocalStorage } from '../../../common/functions/cookies'

const InvitationPage = () => {
  const history = useHistory()
  const { hash } = useParams()

  const { getInvitation } = useAuth()

  useEffect(() => {
    deleteLocalStorage(
      process?.env?.REACT_APP_COOKIE_LOGIN_NAME || env?.REACT_APP_COOKIE_LOGIN_NAME,
    )
  }, [])

  useEffect(() => {
    if (hash) handleInvitation()
  }, [hash])

  const redirect = (type) => {
    const typeRoute = {
      buyer: `/finalizar/comprador/datos-de-usuario/${hash}`,
      seller: `/finalizar/proveedor/${hash}`,
      offer: `/finalizar/proveedor/${hash}`,
    }
    if (type) {
      history.push(typeRoute[type.toLowerCase()])
    }
  }

  const handleInvitation = async () => {
    const resp = await getInvitation(hash)
    if (resp) redirect(resp.type)
    else window.location.assign('https://www.conkau.io/')
  }

  return <>Cargando</>
}
export default InvitationPage

InvitationPage.propTypes = {}

InvitationPage.defaultProps = {}
