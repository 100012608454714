import { useEffect, useState } from 'react'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'
import { useForms } from '../../../common/hooks/forms'

let formData
export const EditSectionModal = ({ isOpen = false, name, onClose, handleEditSection }) => {
  const { values, handleChange, resetValues } = useForms()
  const [errorsData, setErrorsData] = useState({ init: true })

  useEffect(() => {
    if (isOpen) handleChange(formData)
  }, [isOpen])

  const onValidate = () => {
    const formValidation = ['sectionName']
    if (errorsData.init) {
      setErrorsData({})
    }

    let errors = {}

    formValidation.map((key) => {
      if (values[key]?.length || values[key] === true) {
        setErrorsData((prev) => ({
          ...prev,
          [key]: false,
        }))
        errors = {
          ...errors,
          [key]: false,
        }
      } else {
        setErrorsData((prev) => ({
          ...prev,
          [key]: true,
        }))

        errors = {
          ...errors,
          [key]: true,
        }
      }
      return false
    })

    if (!Object.values(errors)?.includes(true)) {
      resetValues()
      return true
    }

    return false
  }

  const handleClose = () => {
    onClose()
    setErrorsData({})
  }

  const handleSubmit = () => {
    if (onValidate()) {
      handleEditSection({ title: values.sectionName })
      onClose()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      id="edit-section-modal"
      onModalClose={handleClose}
      closeWithButton
      title="Editar sección"
      maxWidth="570px">
      <Modal.Content>
        <Box
          margin="0 auto"
          as="form"
          ref={(form) => {
            formData = form
          }}>
          <Flex flexDirection="column">
            <Flex width="100%" flexDirection="column">
              <Flex width="100%" marginRight={2}>
                <Flex flexDirection="column" width="100%">
                  <Field
                    label="Nombre de la sección"
                    required
                    sizeText="display16"
                    marginBottom={3}
                    marginTop={5}>
                    <Input
                      required
                      placeholderMessage="Dale un nombre a tu sección"
                      action={() => handleChange(formData)}
                      defaultValue={name}
                      name="sectionName"
                      hasError={errorsData?.sectionName}
                    />
                  </Field>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="center">
          <Flex width="100%">
            <Button
              id="cancel-button"
              fullWidth
              marginRight={5}
              colorType="transparent"
              action={handleClose}>
              Cancelar
            </Button>
            <Button fullWidth colorType="orange" action={handleSubmit}>
              Guardar
            </Button>
          </Flex>
        </Flex>
      </Modal.Actions>
    </Modal>
  )
}
