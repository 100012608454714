import { useEffect, useState } from 'react'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal/Modal'
import { Button } from '@proveoeng/uikit/dist/atoms/Button/Button'
import { Box, Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout/Layout'
import { Field } from '@proveoeng/uikit/dist/atoms/Field/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input/Input'
import { Radiobox } from '@proveoeng/uikit/dist/atoms/Radiobox/Radiobox'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography/Typography'

export const EditEmployee = ({
  formEditEmployee,
  setFormEditEmployee,
  setData,
  errorsData,
  editExistingEmployee,
  currentEmployee,
  data,
  deleteExistingEmployee,
  rehireExistingEmployee,
}) => {
  let formData
  const [email, setEmail] = useState(null)
  const [employeeId, setEmployeeId] = useState(null)
  const [employeeState, setEmployeeState] = useState(null)
  const [employeeRole, setEmployeeRole] = useState(null)

  useEffect(() => {
    setData(formData)
  }, [])

  useEffect(() => {
    setEmail(formEditEmployee?.data?.email)
    setEmployeeId(formEditEmployee?.data?.employeeId)
    setEmployeeState(formEditEmployee?.data?.state)
    setEmployeeRole(formEditEmployee?.data?.employeeRole)
  }, [formEditEmployee])

  return (
    <Modal
      isOpen={formEditEmployee.show}
      id="edit-employee"
      onModalClose={() => {
        setFormEditEmployee({ show: false, data: {} })
      }}
      title="Editar empleado"
      closeWithButton
      maxWidth="808px">
      <Modal.Content>
        <Box margin="0 auto" overflow="auto">
          <Flex flexDirection="column">
            <Box
              as="form"
              ref={(form) => {
                formData = form
              }}>
              <Flex width="100%" flexDirection="column">
                <Flex width="100%" marginRight={2}>
                  <Flex flexDirection="column">
                    <Field
                      label="Tipo de usuario"
                      required
                      sizeText="display14"
                      marginBottom={4}
                      marginTop={0}
                    />
                    <Grid gridTemplateColumns="1fr 1fr 1fr" gridGap={4} justify-content="center">
                      <Radiobox
                        required
                        onChange={(_, value) => {
                          for (let i = 0; i < formData?.length; i += 1) {
                            if (formData[i]?.value === value) {
                              formData[i].checked = true
                            }
                          }
                          setData(formData)
                        }}
                        hasError={errorsData?.role}
                        items={[
                          {
                            id: 'Super',
                            name: 'role',
                            defaultChecked: currentEmployee?.role === 'Super',
                            isDisabled: false,
                            hasError: false,
                            label: 'Administrador',
                            defaultValue: 'Super',
                          },
                          {
                            id: 'Standard',
                            name: 'role',
                            defaultChecked: currentEmployee?.role === 'Standard',
                            isDisabled: false,
                            hasError: false,
                            label: 'Empleado',
                            defaultValue: 'Standard',
                          },
                          {
                            id: 'Supervisor',
                            name: 'role',
                            defaultChecked: currentEmployee?.role === 'Supervisor',
                            isDisabled: false,
                            hasError: false,
                            label: 'Colaborador',
                            defaultValue: 'Supervisor',
                          },
                        ]}
                        justifyContent="center"
                      />
                    </Grid>
                  </Flex>
                </Flex>
                <Flex>
                  <Box width="50%" marginRight={5}>
                    <Field
                      label="Nombre completo"
                      required
                      sizeText="display14"
                      marginBottom={3}
                      marginTop={5}>
                      <Input
                        placeholderMessage="Añadir el nombre completo"
                        action={() => setData(formData)}
                        defaultValue={currentEmployee?.name}
                        name="name"
                        hasError={errorsData?.name}
                        ariaLabel="name"
                      />
                    </Field>
                  </Box>

                  <Box width="50%">
                    <Field
                      label="Email"
                      required
                      sizeText="display14"
                      marginBottom={3}
                      marginTop={5}>
                      <Input
                        placeholderMessage="Email"
                        type="email"
                        ariaLabel="email"
                        action={() => setData(formData)}
                        defaultValue={email}
                        name="email"
                        hasError={errorsData?.email}
                        disabled
                      />
                    </Field>
                  </Box>
                </Flex>
                <Flex>
                  <Box width="50%" marginRight={5}>
                    <Field
                      label="Teléfono"
                      required
                      sizeText="display14"
                      marginBottom={3}
                      marginTop={5}>
                      <Input
                        type="tel"
                        placeholderMessage="Añadir el número de teléfono"
                        action={() => setData(formData)}
                        defaultValue={currentEmployee?.phone}
                        name="phone"
                        hasError={errorsData?.phone}
                        ariaLabel="phone"
                      />
                    </Field>
                  </Box>
                  <Box width="50%">
                    <Field
                      label="Cargo"
                      required
                      sizeText="display14"
                      marginBottom={3}
                      marginTop={5}>
                      <Input
                        placeholderMessage="Añadir el cargo"
                        ariaLabel="position"
                        action={() => setData(formData)}
                        defaultValue={currentEmployee?.position}
                        name="position"
                        hasError={errorsData?.position}
                      />
                    </Field>
                  </Box>
                </Flex>
                {(employeeRole !== 'Super' ||
                  (employeeRole === 'Super' && employeeState === 'Fired')) && (
                  <Flex marginTop={5}>
                    <Text
                      sizeText="display14"
                      color={employeeState === 'Active' ? 'red1' : 'gray1'}
                      style={{ cursor: 'pointer' }}
                      onClick={
                        employeeState === 'Active'
                          ? () => deleteExistingEmployee(employeeId)
                          : () => rehireExistingEmployee(employeeId)
                      }>
                      {employeeState === 'Active' ? 'Desactivar usuario' : 'Reactivar usuario'}
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Grid gridTemplateColumns="184px" gridTemplateRows="1fr">
            <Button
              disabled={!data}
              colorType="orange"
              action={() => editExistingEmployee(employeeId)}>
              Guardar cambios
            </Button>
          </Grid>
        </Flex>
      </Modal.Actions>
    </Modal>
  )
}
