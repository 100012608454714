export const constants = {
  GET_PROJECT: 'GET_PROJECT',
  CREATE_PROJECT: 'CREATE_PROJECT',
  CREATE_PROJECT_SUCCESS: 'CREATE_PROJECT_SUCCESS',
  UPDATE_PROJECT: 'UPDATE_PROJECT',
  UPDATE_PROJECT_SUCCESS: 'UPDATE_PROJECT_SUCCESS',
  DELETE_PROJECT: 'DELETE_PROJECT',
  DELETE_PROJECT_SUCCESS: 'DELETE_PROJECT_SUCCESS',
  GET_ALL_PROJECTS: 'GET_ALL_PROJECTS',
  CREATE_ATTACHMENT_ALL_PROJECT: 'CREATE_ATTACHMENT_ALL_PROJECT',
  CREATE_ATTACHMENT_ALL_PROJECT_SUCCESS: 'CREATE_ATTACHMENT_ALL_PROJECT_SUCCESS',
  CREATE_ATTACHMENT_PROJECT: 'CREATE_ATTACHMENT_PROJECT',
  CREATE_ATTACHMENT_PROJECT_SUCCESS: 'CREATE_ATTACHMENT_PROJECT_SUCCESS',
  DELETE_ATTACHMENT_PROJECT: 'DELETE_ATTACHMENT_PROJECT',
  DELETE_ATTACHMENT_PROJECT_SUCCESS: 'DELETE_ATTACHMENT_PROJECT_SUCCESS',
  CREATE_LINK_PROJECT: 'CREATE_LINK_PROJECT',
  CREATE_LINK_PROJECT_SUCCESS: 'CREATE_LINK_PROJECT_SUCCESS',
  DELETE_LINK_PROJECT: 'DELETE_LINK_PROJECT',
  DELETE_LINK_PROJECT_SUCCESS: 'DELETE_LINK_PROJECT_SUCCESS',
  ACTION_PROJECT: 'ACTION_PROJECT',
  ACTION_PROJECT_SUCCESS: 'ACTION_PROJECT_SUCCESS',
  IMPORT_BID_PACKAGES: 'IMPORT_BID_PACKAGES',
  IMPORT_BID_PACKAGES_SUCCESS: 'IMPORT_BID_PACKAGES_SUCCESS',
  SET_STORE_PROJECT: 'SET_STORE_PROJECT',
  SET_STORE_PROJECTS: 'SET_STORE_PROJECTS',
  PROJECTS_FAIL: 'PROJECTS_FAIL',
  PROJECT_FAIL: 'PROJECT_FAIL',
  SET_MANAGED_FILES_FINISHED: 'SET_MANAGED_FILES_FINISHED',
  SET_MANAGED_LINKS_FINISHED: 'SET_MANAGED_LINKS_FINISHED',
  EXPORT_PROJECT: 'EXPORT_PROJECT',
}

export const services = {
  getProject: (projectId) => ({
    route: `/projects/${projectId}`,
    method: 'get',
  }),
  createProject: (projectId, body) => ({
    route: `/projects/${projectId}`,
    method: 'put',
    body,
  }),
  updateProject: (projectId, body) => ({
    route: `/projects/${projectId}`,
    method: 'post',
    body,
  }),
  deleteProject: (projectId) => ({
    route: `/projects/${projectId}`,
    method: 'delete',
  }),
  // SHAME: Por algún motivo ahora resulta que en BE si quieres saber todos los proyectos
  // que existen van en un endpoint dentro del esquema de employees... lo dejo aquí por va a
  // ser una liada si no, pero todas las acciones de un proyecto van en /projects menos el getAll
  // que es en /employees
  getAll: (params = {}) => ({
    route: `/employees/current/projects`,
    method: 'get',
    params,
  }),
  createAttachmentProject: (projectId, fileId, body) => ({
    route: `/projects/${projectId}/files/${fileId}`,
    method: 'post',
    headers: {
      accept: '*/*',
    },
    formData: true,
    body,
  }),
  deleteAttachmentProject: (projectId, fileId) => ({
    route: `/projects/${projectId}/files/${fileId}`,
    method: 'delete',
  }),
  createLinkProject: (projectId, linkId, body) => ({
    route: `/projects/${projectId}/links/${linkId}`,
    method: 'post',
    body,
  }),
  deleteLinkProject: (projectId, linkId) => ({
    route: `/projects/${projectId}/links/${linkId}`,
    method: 'delete',
  }),
  actionProject: (projectId, body) => ({
    route: `/projects/${projectId}/action`,
    method: 'post',
    body,
  }),
  importBidPackages: (projectId, body) => ({
    route: `/projects/${projectId}/import`,
    method: 'post',
    headers: {
      accept: '*/*',
    },
    formData: true,
    body,
  }),
  previewBidPackage: (projectId, body) => ({
    route: `/projects/${projectId}/import-preview`,
    method: 'post',
    headers: {
      accept: '*/*',
    },
    formData: true,
    body,
  }),
  exportProject: (projectId) => ({
    route: `/projects/${projectId}/export?format=xlsx`,
    method: 'get',
    responseType: 'arraybuffer',
    headers: {
      accept: '*/*',
    },
    formData: true,
  }),
}
