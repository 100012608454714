import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'

import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { Loader } from '@proveoeng/uikit/dist/atoms/Loader'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'

import { ContentConfirm } from './ContentConfirm'
import { ContentSelection } from './ContentSelection'
import { getTotalMeasurement } from './utils'

const titles = ['Selecciona las partidas', 'Revisa tu compra']

export const ModalImportBidItems = ({
  data,
  bidPackage,
  isOpened,
  setIsOpened,
  categoriesData = [],
  onSubmit,
}) => {
  const [info, setInfo] = useState()
  const [step, setStep] = useState(0)
  const [selectedBidItems, setSelectedBidItems] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setInfo(data)
  }, [data])

  useEffect(() => {
    reset()
  }, [isOpened])

  const reset = () => {
    setStep(0)
    setSelectedBidItems({})
    setInfo(data)
  }

  const seletedLength = useMemo(
    () => Object.values(selectedBidItems).flat()?.length || 0,
    [selectedBidItems],
  )

  const categoryName = useMemo(
    () => categoriesData?.find(({ categoryId }) => categoryId === bidPackage?.categoryId)?.name,
    [bidPackage, categoriesData],
  )

  const importBidItems = async () => {
    setLoading(true)
    const bidItemsToSave = Object.values(selectedBidItems).map((items) => ({
      ...items[0],
      measurement: getTotalMeasurement(items),
    }))
    await onSubmit(bidItemsToSave)
    setLoading(false)
    setStep(4)
  }

  const RenderSelection = (
    <>
      <Modal.Content>
        <ContentSelection
          name={bidPackage?.name}
          categoryName={categoryName}
          bidEndsAt={bidPackage?.bidEndsAt}
          info={info}
          setInfo={setInfo}
          selectedBidItems={selectedBidItems}
          setSelectedBidItems={setSelectedBidItems}
          seletedLength={seletedLength}
        />
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr" mr={4}>
            <Button colorType="transparent" type="button" action={() => setIsOpened(false)}>
              Cancelar
            </Button>
          </Grid>
          <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr">
            <Button
              colorType="orange"
              type="button"
              action={() => setStep(1)}
              disabled={!seletedLength}>
              Revisar partidas
            </Button>
          </Grid>
        </Flex>
      </Modal.Actions>
    </>
  )

  const RenderConfirm = (
    <>
      <Modal.Content>
        <ContentConfirm
          name={bidPackage?.name}
          categoryName={categoryName}
          bidEndsAt={bidPackage?.bidEndsAt}
          selectedBidItems={selectedBidItems}
          seletedLength={seletedLength}
        />
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr" mr={4}>
            <Button colorType="transparent" type="button" action={() => setStep(0)}>
              Volver a partidas
            </Button>
          </Grid>
          <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr">
            <Button colorType="orange" type="button" action={importBidItems}>
              importar partidas
            </Button>
          </Grid>
        </Flex>
      </Modal.Actions>
    </>
  )

  return (
    <Modal
      isOpen={isOpened}
      id="import-modal-biditems"
      onModalClose={() => setIsOpened(false)}
      closeWithButton
      maxWidth="90vw"
      title={titles[step]}>
      {loading && <Loader />}
      {step === 0 && RenderSelection}
      {step === 1 && RenderConfirm}
    </Modal>
  )
}

ModalImportBidItems.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  isOpened: PropTypes.bool.isRequired,
  setIsOpened: PropTypes.bool.isRequired,
  categoriesData: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func,
}

ModalImportBidItems.defaultProps = {
  data: null,
  categoriesData: [],
  onSubmit: () => {},
}
