import { useEffect } from 'react'
import { Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'

let formData
export const DataContactTemplate = ({ title, setData, data, errorsData, errorsKeys }) => {
  useEffect(() => {
    setData(formData)
  }, [])

  return (
    <Flex height="100%" width="100%" paddingY={11} flexDirection="column" paddingX={[4, 4, 7, 14]}>
      <Text sizeText="display14" color="gray2" marginBottom={7}>
        Finaliza tu perfil
        <Text as="span" sizeText="display14" color="black" marginBottom={7} marginLeft={2}>
          Tu perfil
        </Text>
      </Text>
      <Text sizeText="display52" color="black" marginBottom={7} fontWeight="bold" maxWidth={856}>
        {title}
      </Text>
      <Grid
        gridTemplateColumns={['1fr', '1fr', '220px 1fr']}
        gridTemplateAreas={[
          "'description' 'fields'",
          "'description' 'fields'",
          "'description fields'",
        ]}
        gridGap={[7, 7, 7, 13]}>
        <GridItem gridArea="description">
          <Text sizeText="display18" color="black" fontWeight="bold" marginBottom={2}>
            Datos de contacto
          </Text>
          <Text sizeText="display16" color="gray" maxWidth="221px">
            Estos datos nos servirán para poder enviarte las ofertas de tus proveedores.
          </Text>
        </GridItem>
        <GridItem
          as="form"
          ref={(form) => {
            formData = form
          }}
          gridArea="fields"
          display="flex">
          <Grid
            gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}
            gridTemplateAreas={[
              "'fields1' 'fields2'",
              "'fields1' 'fields2'",
              "'fields1 fields1' 'fields2 fields2'",
            ]}
            gridColumnGap={[3, 3, 3, 13]}
            width="100%"
            paddingRight={14}>
            <GridItem gridArea="fields1">
              <Field
                errorMessage={errorsKeys?.name}
                required
                label="Nombre completo"
                sizeText="display16"
                marginBottom={3}
                marginTop={0}>
                <Input
                  placeholderMessage="Escribe tu nombre"
                  action={() => setData(formData)}
                  ariaLabel="Nombre"
                  defaultValue={data?.name}
                  name="name"
                  required
                  hasError={errorsData?.name}
                  pattern="^.{3,100}$"
                />
              </Field>
              <Field
                errorMessage={errorsKeys?.phone}
                required
                label="Teléfono"
                sizeText="display16"
                marginBottom={3}
                marginTop={5}>
                <Input
                  placeholderMessage="Introduce tu teléfono"
                  action={() => setData(formData)}
                  ariaLabel="teléfono"
                  name="phone"
                  pattern="^.{9,25}$"
                  required
                  defaultValue={data?.phone}
                  hasError={errorsData?.phone}
                />
              </Field>
            </GridItem>
            <GridItem gridArea="fields2">
              <Field
                errorMessage={errorsKeys?.position}
                label="Cargo"
                sizeText="display16"
                marginBottom={3}
                marginTop={5}>
                <Input
                  placeholderMessage="Escribe tu cargo"
                  action={() => setData(formData)}
                  ariaLabel="cargo"
                  name="position"
                  defaultValue={data?.position}
                  pattern="^.{5,40}$"
                />
              </Field>
              <Field
                required
                errorMessage={errorsKeys?.email}
                label="Email"
                sizeText="display16"
                marginBottom={3}
                marginTop={5}>
                <Input
                  type="email"
                  placeholderMessage="Introduce tu email"
                  action={() => setData(formData)}
                  ariaLabel="email"
                  name="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  validate
                  defaultValue={data?.email}
                  hasError={errorsData?.email}
                  disabled
                />
              </Field>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Flex>
  )
}
