import { useEffect, useState } from 'react'

import { services } from '../schemes/helpers/config'
import { requestApi } from '../../../lib/request'
import { useUtils } from './useUtils'

// TODO: Move to useUtil in refactor
export const useUnit = () => {
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(true)

  const { setAlert } = useUtils()

  useEffect(() => {
    const loadData = async () => {
      try {
        const {
          data: { data },
        } = await requestApi(services.getUnits())
        const resp = data.map((option) => ({
          value: option.label,
          label: `${option.label} (${option.title})`,
        }))
        setOptions(resp)
        setLoading(false)
        return resp
      } catch (error) {
        setLoading(false)
        setAlert('success', 'Comparativo guardado con éxito')
        return []
      }
    }
    loadData()
  }, [])

  const loadOptions = async (inputValue) =>
    new Promise((resolver) => {
      if (!inputValue) resolver(options)
      const filtered = options.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase()),
      )

      resolver(filtered)
    })

  return { options, loadOptions, loading }
}
