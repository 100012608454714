/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Accordion } from '@proveoeng/uikit/dist/atoms/Accordion'

/**
 * @name RecursiveAccordion
 * @param {object} props
 * @param {Preview} props.item
 * @param {boolean} props.isOpened
 */
const RecursiveAccordion = (props) => {
  const {
    item: { summary, children, isBidItem },
    isOpened,
  } = props

  return (
    <Accordion
      title={summary}
      isExpandabled={!!children.length && !isBidItem}
      isOpened={!!isOpened}>
      {!isBidItem && (
        <>
          {children.map((child) => (
            <RecursiveAccordion key={child?.code} item={child} />
          ))}
        </>
      )}
    </Accordion>
  )
}

export const Preview = ({ data }) => (
  <Flex
    width="100%"
    paddingTop={7}
    paddingBottom={5}
    paddingX={8}
    flexDirection="column"
    position="relative">
    {data && <RecursiveAccordion item={data} isOpened />}
  </Flex>
)
