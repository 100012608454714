import { ButtonTabs } from '@proveoeng/uikit/dist/atoms/Button'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { ManageEmployees } from '../components/ManageEmployees'
import { ProfileCompanyForm } from '../components/ProfileCompanyForm'
import { TitleStyled } from '../../styles/ProfileStyles'

/**
 *
 * @param {Object} props
 * @param {Company} props.company
 * @param {Array} props.tabs
 * @param {string} props.activeTab
 * @param {void} props.handleSubmit
 * @param {void} props.onLogoImageChange
 */
export const ProfileCompanyTemplate = ({
  company,
  tabs = [],
  activetab,
  handleSubmit,
  logoImage,
  onLogoImageChange,
}) => (
  <>
    <Flex height="100%" width="100%" paddingTop={7} flexDirection="column" paddingX={7}>
      <Text sizeText="display14" color="gray2" marginBottom={7}>
        Configuración
        <Text as="span" sizeText="display14" color="black" marginBottom={7} marginLeft={2}>
          Tu perfil
        </Text>
      </Text>
      <TitleStyled sizeText="display52" color="gray">
        Configura tu compañía
      </TitleStyled>
      <Flex>
        <ButtonTabs items={tabs} />
      </Flex>
      {activetab === 'company' && (
        <ProfileCompanyForm
          avatar={logoImage}
          company={company}
          handleChangeAvatar={onLogoImageChange}
          onSubmit={handleSubmit}
        />
      )}
      {activetab === 'employees' && <ManageEmployees />}
    </Flex>
  </>
)
