import { Button } from '@proveoeng/uikit/dist/atoms/Button/Button'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Box } from '@proveoeng/uikit/dist/atoms/Layout/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { parseEditorMessage } from '../../../../../lib/utils/parseEditorMessage'
import { ModalExitConfirmation } from '../../../../common/components/modalExitConfirmation/ModalExitConfirmation'
import { ModalSendMessage } from '../../../../common/components/sendMessage/ModalSendMessage'
import { ModalUploadImport } from '../../../../common/components/uploadImport/ModalUploadImport'
import { ModalContact } from '../../common/components/ModalContact'
import { EditProposal } from '../components/EditProposal'

export const ApplyOfferTemplate = ({
  goBack,
  proposal = {},
  proposalItems = [],
  bidPackage,
  openContactModal,
  handleOpenMessage,
  isPublished,
  handleSendMessage,
  isModalContactOpened,
  setIsModalContactOpened,
  isModalSendMessageOpened,
  setIsModalSendMessageOpened,
  isModalSendProposalOpened,
  setIsModalSendProposalOpened,
  isModalExitConfirmationOpened,
  setIsModalExitConfirmationOpened,
  goToProposalPreview,
  tabActive,
  tabs,
  handleExportBidPackagePrices,
  openImportModal,
  importModalIsOpened,
  setImportModalIsOpened,
  onFileToImportLoad,
  filesToImport,
  handleSubmitImport,
  setIsOptionalMessage,
}) => {
  const { handleSubmit, setFieldValue } = useFormikContext()

  useEffect(() => {
    setIsOptionalMessage(false)
  }, [])

  const handleSendProposal = async ({ message }) => {
    setIsModalSendProposalOpened(false)
    const messageParsed = parseEditorMessage(message)
    setFieldValue('message', messageParsed)
    setFieldValue('publish', true)
    setIsOptionalMessage(true)
    handleSubmit()
  }

  return (
    <>
      <Flex height="100%" width="100%" paddingY={4} flexDirection="column" paddingX={[0, 0, 0, 0]}>
        <Text as="span" sizeText="display14" color="gray2">
          <Text
            as="span"
            sizeText="display14"
            color="blue1"
            marginBottom={7}
            style={{ cursor: 'pointer' }}
            onClick={goBack}>
            {'< '} Volver
          </Text>{' '}
          | Tus propuestas /
          <Text as="span" sizeText="display14" color="black" marginLeft={2}>
            {bidPackage?.name}
          </Text>
        </Text>
        <Flex justifyContent="space-between" width="100%" alignItems="center">
          <Text
            as="h3"
            sizeText="display30"
            color="black"
            fontWeight="bold"
            marginTop={7}
            marginBottom={5}>
            {bidPackage?.name}
          </Text>
          <Flex
            alignItems="flex-end"
            justifyContent="flex-end"
            width="100%"
            flex={1}
            flexDirection="row">
            <Box>
              {isPublished ? (
                <Button
                  width="184px"
                  action={() => setIsModalExitConfirmationOpened(true)}
                  colorType="transparent"
                  type="button"
                  marginRight={5}>
                  Cancelar
                </Button>
              ) : (
                <Button
                  width="184px"
                  action={handleSubmit}
                  colorType="transparent"
                  type="button"
                  marginRight={5}>
                  Guardar
                </Button>
              )}
            </Box>
            <Box>
              <Button width="184px" action={handleOpenMessage} colorType="orange" fullWidth>
                Enviar propuesta
              </Button>
            </Box>
          </Flex>
        </Flex>
        {bidPackage?.contact?.companyName && (
          <>
            <Flex justifyContent="space-between">
              <Flex flexDirection="column">
                <Flex>
                  <Text
                    fontWeight="regular"
                    color="gray"
                    sizeText="display16"
                    paddingBottom={2}
                    paddingRight={2}>
                    Contratista:{' '}
                    <Text as="span" fontWeight="medium" color="black" sizeText="display16">
                      {bidPackage?.contact?.companyName}
                    </Text>
                  </Text>
                  <Text
                    as="span"
                    fontWeight="medium"
                    color="blue1"
                    sizeText="display16"
                    style={{ cursor: 'pointer' }}
                    onClick={openContactModal}>
                    Ver datos de contacto
                  </Text>
                </Flex>
                <Text fontWeight="regular" color="gray" sizeText="display16" paddingBottom={5}>
                  Obra:{' '}
                  <Text as="span" fontWeight="medium" color="black" sizeText="display16">
                    {proposal?.project.name}
                  </Text>
                </Text>
              </Flex>
              <Flex flexDirection="column">
                <Text fontWeight="regular" color="gray" sizeText="display16" paddingBottom={2}>
                  Fecha límite para presentar oferta:
                </Text>
                <Text
                  as="span"
                  fontWeight="medium"
                  color="black"
                  sizeText="display18"
                  textAlign="right">
                  {bidPackage?.bidEndsAtFormatted?.readableReverseDate || '-'}
                </Text>
              </Flex>
            </Flex>
            {isPublished && (
              <Box width="184px" marginRight={7} marginBottom={6}>
                <Button
                  fullWidth
                  colorType="transparent"
                  type="button"
                  action={() => setIsModalSendMessageOpened(true)}>
                  Enviar mensaje
                </Button>
              </Box>
            )}
          </>
        )}

        <EditProposal
          proposal={proposal}
          tabs={tabs}
          tabActive={tabActive}
          bidPackage={bidPackage}
          proposalItems={proposalItems}
          handleExportBidPackagePrices={handleExportBidPackagePrices}
          openImportModal={openImportModal}
        />
      </Flex>

      <ModalContact
        isModalContactOpened={isModalContactOpened}
        setIsModalContactOpened={setIsModalContactOpened}
        data={bidPackage?.contact}
      />

      <ModalSendMessage
        isOpened={isModalSendProposalOpened}
        setIsOpened={setIsModalSendProposalOpened}
        title="¿Quieres enviar un mensaje a la contratista?"
        subTitle={
          <>
            Puedes enviar una notificación con un mensaje opcional{' '}
            <strong>{bidPackage?.contact?.companyName}</strong>.
          </>
        }
        hasNotNotifyButton={false}
        onSubmit={handleSendProposal}
      />

      <ModalSendMessage
        title={`Enviar notificación a ${bidPackage?.contact?.companyName}`}
        subTitle={
          <Text>
            <strong>{bidPackage?.contact?.companyName}</strong> recibirá un correo electrónico con
            el siguiente mensaje.
          </Text>
        }
        hasCancelButton={false}
        onSubmit={handleSendMessage}
        isOpened={isModalSendMessageOpened}
        setIsOpened={setIsModalSendMessageOpened}
      />

      <ModalExitConfirmation
        isOpened={isModalExitConfirmationOpened}
        handleClose={() => setIsModalExitConfirmationOpened(false)}
        handleConfirm={goToProposalPreview}
      />

      <ModalUploadImport
        type="offer"
        isOpened={importModalIsOpened}
        setIsOpened={setImportModalIsOpened}
        onImagesLoad={onFileToImportLoad}
        files={filesToImport}
        onSubmit={handleSubmitImport}
      />
    </>
  )
}
