import { constants } from '../config'

const initialState = {
  doneAuth: false,
  doneSetInvitation: false,
  doneGetInvitation: false,
  doneCancelInvitation: false,
  loading: false,
  errorRequest: false,
  error: null,
  data: {},
}

export default function setCompanyReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SET_AUTH:
      return { ...state, loading: true, doneAuth: false, errorRequest: false, error: null }
    case constants.SET_AUTH_SUCCESS: {
      return {
        ...state,
        loading: false,
        doneAuth: true,
        data: {
          ...action.payload,
        },
      }
    }
    case constants.CHECK_LOGIN:
      return { ...state, loading: true, doneAuth: false, errorRequest: false, error: null }
    case constants.CHECK_LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        doneAuth: true,
        data: {
          ...action.payload,
        },
      }
    }
    case constants.GET_INVITATION:
      return {
        ...state,
        loading: true,
        doneGetInvitation: false,
        errorRequest: false,
        error: null,
      }
    case constants.GET_INVITATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        doneGetInvitation: true,
        data: {
          ...action.payload,
        },
      }
    }
    case constants.SET_INVITATION:
      return {
        ...state,
        loading: true,
        doneSetInvitation: false,
        errorRequest: false,
        error: null,
      }
    case constants.SET_INVITATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        doneSetInvitation: true,
      }
    }
    case constants.CANCEL_INVITATION:
      return {
        ...state,
        loading: true,
        doneCancelInvitation: false,
      }
    case constants.CANCEL_INVITATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        doneCancelInvitation: true,
      }
    }
    case constants.SET_STORE_AUTH: {
      return {
        ...state,
        data: {
          ...action.payload,
        },
      }
    }
    case constants.AUTH_FAIL:
      return { ...state, loading: false, errorRequest: true, error: { ...action.error } }

    default:
      return state
  }
}
