import { Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

import { currencyFormat, parseToString } from '../../../../lib/utils/Format'
import { DiscountNumber } from './DiscountNumber'

export const SectionTotals = ({ data, offers, columns }) => (
  <>
    <Grid gridTemplateAreas={columns.area} gridTemplateColumns={columns.columns}>
      <GridItem
        gridArea="checkbox"
        backgroundColor="white2"
        paddingY={3}
        style={{
          position: 'sticky',
          left: 0,
          zIndex: 9,
        }}
      />
      <GridItem
        gridArea="bidpackage"
        bg="white2"
        borderRightColor="gray3"
        borderRightStyle="solid"
        borderRightWidth="1px"
        padding={4}
        style={{
          position: 'sticky',
          left: 30,
          zIndex: 9,
        }}>
        <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="bold">
          Total {data?.name}
        </Text>
      </GridItem>
      <GridItem
        gridArea="unit"
        bg="white2"
        paddingRight={2}
        paddingY={4}
        borderRightColor="gray3"
        borderRightStyle="solid"
        borderRightWidth="1px"
        style={{
          position: 'sticky',
          left: 330,
          zIndex: 9,
        }}
      />
      <GridItem
        gridArea="measure"
        bg="white2"
        paddingRight={2}
        paddingY={4}
        borderRightColor="gray3"
        borderRightStyle="solid"
        borderRightWidth="1px"
        style={{
          position: 'sticky',
          left: 410,
          zIndex: 9,
        }}
      />
      <GridItem
        gridArea="budgetCost"
        bg="white2"
        paddingRight={2}
        paddingY={4}
        style={{
          position: 'sticky',
          left: 510,
          zIndex: 9,
        }}
      />
      <GridItem
        gridArea="budget"
        bg="white2"
        borderRightColor="gray3"
        borderRightStyle="solid"
        borderRightWidth="1px"
        paddingRight={2}
        paddingY={4}
        style={{
          position: 'sticky',
          left: 600,
          zIndex: 9,
        }}>
        <Text
          textAlign="right"
          color="gray1"
          overflow="hidden"
          fontWeight="bold"
          sizeText="display12">
          {currencyFormat(data.getTotalBudgetCost())}
        </Text>
      </GridItem>
      <GridItem
        gridArea="expectedCostsUnit"
        bg="white2"
        paddingRight={2}
        paddingY={4}
        style={{
          position: 'sticky',
          left: 730,
          zIndex: 9,
        }}
      />
      <GridItem
        gridArea="expectedCosts"
        bg="white2"
        borderRightColor="gray3"
        borderRightStyle="solid"
        borderRightWidth="1px"
        paddingRight={2}
        paddingY={4}
        style={{
          position: 'sticky',
          left: 820,
          zIndex: 9,
        }}>
        <Text
          textAlign="right"
          color="gray1"
          overflow="hidden"
          fontWeight="bold"
          sizeText="display12">
          {currencyFormat(data.getTotalExpectedCost())}
        </Text>
      </GridItem>
      <GridItem
        gridArea="minEnvelopeUnit"
        bg="white2"
        paddingRight={2}
        paddingY={4}
        style={{
          position: 'sticky',
          left: 950,
          zIndex: 9,
        }}
      />
      <GridItem
        gridArea="minEnvelope"
        bg="white2"
        borderRightColor="gray3"
        borderRightStyle="solid"
        borderRightWidth="1px"
        paddingRight={2}
        paddingY={4}
        style={{
          position: 'sticky',
          left: 1040,
          zIndex: 9,
        }}>
        <Text
          textAlign="right"
          color="gray1"
          overflow="hidden"
          fontWeight="bold"
          sizeText="display12">
          {parseToString(data?.getTotalMinEnvelope(offers))} €
        </Text>
      </GridItem>
      {offers.map((offer) => (
        <>
          <GridItem gridArea={`offer-unit-${offer.id}`} bg="white2" paddingY={4}>
            {data.bidItems.length > 0 && <DiscountNumber discount={offer?.discount} />}
          </GridItem>
          <GridItem
            gridArea={`offer-total-${offer.id}`}
            bg="white2"
            borderRightColor="gray3"
            borderRightStyle="solid"
            borderRightWidth="1px"
            paddingRight={2}
            paddingY={4}>
            <Text
              textAlign="right"
              color="gray1"
              overflow="hidden"
              fontWeight="bold"
              sizeText="display12">
              {currencyFormat(offer.getTotalItems(data.bidItems))}
            </Text>
          </GridItem>
        </>
      ))}
    </Grid>
  </>
)
