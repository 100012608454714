import { useDispatch, useSelector } from 'react-redux'

import { useHistory } from 'react-router-dom'
import { requestApi } from '../../../lib/request'
import { getBase64 } from '../functions/serialize'
import { showAlert } from '../states/alerts'
import { showLoading } from '../states/loading'
import { services as servicesHelper } from '../schemes/helpers/config'
import { deleteLocalStorage } from '../functions/cookies'

import { addImage } from '../states/images'

export const useUtils = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const images = useSelector((state) => state.images)

  const controller = new AbortController()

  const getSignal = () => controller.signal

  const cancelRequest = () => controller.abort()

  const setLoading = (value) => dispatch(showLoading(value ? 1 : -1))

  const setAlert = (type, message) => {
    dispatch(showAlert(type, 1, message))
    setTimeout(() => {
      dispatch(showAlert('', -1, ''))
    }, 5000)
  }

  const setError = (error) => {
    if (!error) return
    const { message, detail, code, title } = error

    if (message === 'canceled') return

    if (code === 401 && title !== 'InvalidCredentialsException') {
      deleteLocalStorage(process.env.REACT_APP_COOKIE_LOGIN_NAME || env.REACT_APP_COOKIE_LOGIN_NAME)
      history.push('/login')
    }

    if (code === 403) {
      history.push('/constructora/error/403')
    }

    if (code === 404) {
      history.push('/constructora/error/404')
    }

    setAlert('error', message || detail || 'Algo ha salido mal.')
  }

  /**
   *
   * @param {Attachment} attachment
   * @returns {Promise<string>}
   */
  const getBase64Attachment = async (attachment) => {
    const { fileId, type } = attachment

    if (type?.includes('image')) {
      const { data } = await requestApi(servicesHelper.getThumb(fileId), {
        signal: getSignal(),
      })

      return `data:${type};base64,${getBase64(data)}`
    }
    return ''
  }

  // Refactored
  const getThumb = async (imageId) => {
    try {
      // Verify if thumb exists in project
      let thumb = ''
      if (images[imageId]) {
        thumb = images[imageId]
      } else {
        const { data, headers } = await requestApi(servicesHelper.getThumb(imageId), {
          signal: getSignal(),
        })
        thumb = `data:${headers['content-type']};base64,${getBase64(data)}`
        dispatch(addImage(imageId, thumb))
      }
      return thumb
    } catch (error) {
      if (error?.message !== 'canceled') {
        setError(error)
      }
      return null
    }
  }

  return {
    setLoading,
    setAlert,
    setError,
    getSignal,
    cancelRequest,
    getBase64Attachment,
    getThumb,
  }
}
