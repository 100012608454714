export const onImagesLoad = (receivedImages, setImages, prevImages) => {
  const imgErrors = receivedImages?.filter((info) =>
    Object.prototype.hasOwnProperty.call(info, 'error'),
  )

  if (!imgErrors.length) {
    if (prevImages) {
      setImages([...prevImages, ...receivedImages])
    } else {
      setImages(receivedImages)
    }
  }
}

export const onImagesItemLoad = (receivedImages, id, setImagesItem, prevImages) => {
  const imgErrors = receivedImages?.filter((info) =>
    Object.prototype.hasOwnProperty.call(info, 'error'),
  )

  if (!imgErrors.length) {
    if (prevImages) {
      if (id in prevImages) {
        setImagesItem((prev) => {
          if (prev) {
            return {
              ...prev,
              [id]: [...prev[id], ...receivedImages],
            }
          }
          return {
            ...prev,
            [id]: receivedImages,
          }
        })
      } else {
        setImagesItem((prev) => ({
          ...prev,
          [id]: receivedImages,
        }))
      }
    } else {
      setImagesItem((prev) => ({
        ...prev,
        [id]: receivedImages,
      }))
    }
  }
}

export const setErrorsAlerts = (errorInputs) => {
  let errorMessage = ''
  const listMessages = {}
  if (errorInputs) {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const errorInputsKey in errorInputs) {
      if (typeof errorInputs[errorInputsKey] === 'object') {
        const messages = errorInputs[errorInputsKey]
        if (typeof messages === 'object') {
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const message in messages) {
            const sons = messages[message]
            if (typeof sons === 'object') {
              // eslint-disable-next-line no-loop-func
              Object.keys(sons).forEach((element) => {
                listMessages[message] = element
                errorMessage += `${element} <br/>`
              })
            } else {
              // eslint-disable-next-line prefer-destructuring
              listMessages[message] = sons
              errorMessage += `${sons} <br/>`
            }
          }
        } else {
          errorMessage += `${messages} <br/>`
        }
      }
    }
    return {
      listMessages,
      message: errorMessage,
    }
  }
  return {}
}

export const setErrorsInputs = (errorInputs) => {
  const errorKeys = []
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const errorInputsKey in errorInputs) {
    errorKeys.push(errorInputsKey)
  }
  return errorKeys
}
