import { Checkbox } from '@proveoeng/uikit/dist/atoms/Checkbox'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Info, WarningOutline } from '@proveoeng/uikit/dist/atoms/Icons'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { LoaderAnimation } from '@proveoeng/uikit/dist/atoms/Loader'
import { Tooltip } from '@proveoeng/uikit/dist/atoms/Tooltip'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { DataTable } from '@proveoeng/uikit/dist/molecules/DataTable'
import { useState } from 'react'
import { Helper } from '../../../../common/components/Helper/Helper'

const TooltipWrapper = ({ children, type }) => {
  const [active, setActive] = useState(false)
  return (
    <Tooltip
      active={active}
      setActive={setActive}
      bgColor="gray3"
      textColor="black"
      padding={4}
      width="497px"
      content={<Helper type={type} />}>
      {children}
    </Tooltip>
  )
}

export const SellerTable = ({
  sellers,
  handleSort,
  meta,
  handlePageChange,
  handlePerRowsChange,
  onSelectedRowsChange,
  loading,
  handleOpenDetail,
}) => {
  const notExistEmail = (email) =>
    !email || email?.includes('notienecorreo') || email?.includes('@conkau.io')

  const openDetail = (companyId, tabOpen = 'company') => {
    if (!companyId) return
    handleOpenDetail(companyId, tabOpen)
  }

  const columns = [
    {
      name: '',
      maxWidth: '40px',
      minWidth: '40px',
      cell: (row) => (
        <Checkbox
          defaultChecked={row.isSelected}
          action={(_, value) => onSelectedRowsChange(row.companyId, value)}
        />
      ),
    },
    {
      name: 'Empresa',
      selector: (row) => row.name?.toLowerCase() || '',
      cell: (row) => (
        <Text
          as="span"
          sizeText="display14"
          fontWeight="600"
          onClick={() => openDetail(row.companyId)}
          style={{ cursor: row.companyId ? 'pointer' : 'default' }}>
          {row.name || '-'}
        </Text>
      ),
      sortable: true,
      sortField: 'name',
      width: '250px',
    },
    {
      name: 'Contacto',
      selector: (row) => row.contact?.employeeName?.toLowerCase() || '',
      cell: (row) => {
        if (row.contact?.employeeName) {
          return (
            <>
              {notExistEmail(row?.contact?.employeeEmail) ? (
                <TooltipWrapper type="no-mail">
                  <Flex justifyContent="center" alignItems="center">
                    <Icon sizeIcon="display24" color="gray1">
                      <Info />
                    </Icon>
                    <Text
                      as="span"
                      sizeText="display14"
                      fontWeight="600"
                      onClick={() => openDetail(row.companyId, 'contact')}
                      style={{ cursor: row.companyId ? 'pointer' : 'default' }}>
                      {row.contact.employeeName}
                    </Text>
                  </Flex>
                </TooltipWrapper>
              ) : (
                <Text
                  as="span"
                  sizeText="display14"
                  fontWeight="600"
                  onClick={() => openDetail(row.companyId, 'contact')}
                  style={{ cursor: row.companyId ? 'pointer' : 'default' }}>
                  {row.contact.employeeName}
                </Text>
              )}
            </>
          )
        }

        return (
          <TooltipWrapper type="no-contact">
            <Flex alignItems="center">
              <Icon sizeIcon="display18" color="black">
                <WarningOutline />
              </Icon>
              <Text as="span" sizeText="display14" color="black" fontWeight="400" paddingLeft={1}>
                No existen datos de contacto
              </Text>
            </Flex>
          </TooltipWrapper>
        )
      },
      sortable: true,
      sortField: 'contact_employeeName',
      width: '250px',
    },
    {
      name: 'Provincia',
      selector: (row) => row.province?.toLowerCase() || '',
      cell: (row) => row.province || '-',
      sortable: true,
      sortField: 'address_province',
    },
    {
      name: 'Facturación',
      selector: (row) => row.turnover || '',
      cell: (row) => row.turnover || '-',
      // sortable: true,
      sortField: 'turnover',
    },
    {
      name: 'Empleados',
      selector: (row) => row.staff || '',
      cell: (row) => row.staff || '-',
      // sortable: true,
      sortField: 'staff',
    },
    {
      name: 'Tipo',
      selector: (row) => (row.sellerSubtypes?.length ? row.sellerSubtypes.join(', ') : '-'),
      cell: (row) => (row.sellerSubtypes?.length ? row.sellerSubtypes.join(', ') : '-'),
      sortable: true,
      sortField: 'sellerSubtypes',
    },
  ]

  return (
    <DataTable
      columns={columns}
      data={sellers}
      onSort={({ sortField }, direction) => handleSort(sortField, direction)}
      sortServer
      pagination
      paginationServer
      paginationTotalRows={meta?.total}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      progressPending={loading}
      progressComponent={
        <Box marginY={10}>
          <LoaderAnimation />
        </Box>
      }
    />
  )
}
