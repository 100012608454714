import { useState } from 'react'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Help } from '@proveoeng/uikit/dist/atoms/Icons'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Tooltip } from '@proveoeng/uikit/dist/atoms/Tooltip'
import { parseToInteger, currencyFormat } from '../../../../../../lib/utils/Format'

const setTooltipContent = (discount) =>
  `El precio incluye un ${(parseToInteger(discount, 2) / 100).toFixed(2)}% de descuento comercial`

const getTotalWithDiscount = (total, discount) => {
  const totalNumber = parseToInteger(total, 2) / 100
  const discountNumber = parseToInteger(discount, 2) / 100
  const discountAmount = (totalNumber * discountNumber) / 100

  return currencyFormat(totalNumber - discountAmount)
}

export const ProposalDiscountTooltip = ({ discount, total }) => {
  const [discountConditions, setDiscountConditions] = useState(false)

  if (discount === '0,00' || total === '0,00') {
    if (total === '0,00') {
      return '0,00 €'
    }
    return currencyFormat(total)
  }

  return (
    <Tooltip
      active={discountConditions}
      content={setTooltipContent(discount)}
      setActive={setDiscountConditions}>
      <Flex justifyContent="center" alignItems="center">
        {getTotalWithDiscount(total, discount)}
        <Icon sizeIcon="display18" color="gray1">
          <Help />
        </Icon>
      </Flex>
    </Tooltip>
  )
}
