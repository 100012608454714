import { useHistory, useLocation } from 'react-router-dom'
import { isProduction } from '../../../lib/utils/isProduction'

export const useMenuItems = () => {
  const history = useHistory()
  const location = useLocation()

  const isMatch = (path) => location.pathname.includes(path)

  const goTo = (path) => history.push(path)

  const itemsDemo = [
    // {
    //   id: 'listings',
    //   children: 'Listings',
    //   isActive: isMatch('listings'),
    //   action: () => goTo('/constructora/listings'),
    // },
    {
      id: 'projectInfo',
      children: 'Información del proyecto',
      isActive: location.pathname === '/constructora/proyecto/',
      action: () => goTo('/constructora/proyecto/'),
    },
    {
      id: 'dashboard',
      children: 'Métricas',
      isActive: location.pathname === '/constructora/',
      action: () => goTo('/constructora/'),
    },
  ]

  return {
    buyer: [
      ...(isProduction() ? [] : itemsDemo),
      {
        id: 'projects',
        children: 'Tus obras',
        isActive: isMatch('tus-obras'),
        action: () => goTo('/constructora/tus-obras'),
      },
      {
        id: 'ceco2',
        children: 'Costes ambientales',
        isActive: isMatch('costes-ambientales'),
        action: () => goTo('/constructora/costes-ambientales'),
      },
    ],
    seller: [
      {
        id: 'new',
        children: 'Nuevas oportunidades',
        isActive: isMatch('compras-nuevas'),
        action: () => goTo('/proveedor/compras-nuevas'),
      },
      {
        id: 'apply',
        children: 'Tus propuestas',
        isActive: isMatch('tus-aplicaciones'),
        action: () => goTo('/proveedor/tus-aplicaciones'),
      },
    ],
  }
}
