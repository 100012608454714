import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { parseEditorMessage } from '../../../../../../lib/utils/parseEditorMessage'

import { useBidPackage } from '../../../../../common/hooks/useBidPackage'
import { useHelpers } from '../../../../../common/hooks/useHelpers'
import { useProject } from '../../../../../common/hooks/useProject'
import { useProposal } from '../../../../../common/hooks/useProposal'
import { BidPackageTemplate } from '../template/BidPackageTemplate'

const BidPackageContainer = ({ page: Page }) => {
  const history = useHistory()
  const { hash, bidPackageId } = useParams()
  const { path } = useRouteMatch()

  const [detailsIsOpened, setDetailsIsOpened] = useState(false)
  const [detailsTabOpen, setDetailsTabOpen] = useState('company')
  const [categoriesSelected, setCategoriesSelected] = useState([])
  const [message, setMessage] = useState('')

  const [publishModalIsOpened, setPublishModalIsOpened] = useState(false)
  const [publishPreviewModalIsOpened, setPublishPreviewModalIsOpened] = useState(false)
  const [sellerMessageModalIsOpened, setSellerMessageModalIsOpened] = useState(false)

  const {
    data: { project },
    getProject,
  } = useProject()

  const {
    data: { bidPackage, bidItems },
    getBidPackage,
    actionBidPackage,
    bidItems: { getAllBidItems },
  } = useBidPackage()

  const {
    data: { seller, categories },
    getCategories,
    getSellerByCompany,
  } = useHelpers()

  const {
    data: { proposalsBySeller },
    getProposalsBySeller,
  } = useProposal()

  const { companyId: buyerCompanyId } = useSelector((state) => state.employee?.data)

  const isActive = (route) => path.includes(route)

  const isBidPackageInfo = path.split('/').at(-1).includes(':bidPackageId')

  const goTo = (route) => history.push(`/constructora/tus-obras/${hash}/${bidPackageId}${route}`)

  const tabItems = [
    {
      id: 'info',
      children: <>Información de la compra</>,
      isActive: isBidPackageInfo,
      action: () => goTo('/'),
    },
    {
      id: 'bidItems',
      children: <>Partidas</>,
      isActive: isActive('partidas'),
      action: () => goTo('/partidas'),
    },
    {
      id: 'guests',
      children: <>Invitados</>,
      isActive: isActive('invitados'),
      action: () => goTo('/invitados'),
      disabled: bidPackage?.state === 'Draft',
      tooltip: {
        active: bidPackage?.state === 'Draft',
        content: 'Puedes acceder a los invitados después de publicar la compra',
      },
    },
    {
      id: 'proposals',
      children: <>Propuestas recibidas</>,
      isActive: isActive('propuestas'),
      action: () => goTo('/propuestas'),
      disabled: bidPackage?.state === 'Draft',
      tooltip: {
        active: bidPackage?.state === 'Draft',
        content: 'Puedes acceder a las propuestas recibidas después de publicar la compra',
      },
    },
  ]

  tabItems.push(
    {
      id: 'history',
      children: <>Historial</>,
      isActive: isActive('historial'),
      action: () => goTo('/historial'),
    },
    {
      id: 'emissionsCalculator',
      children: <>Cálculo de emisiones</>,
      isActive: isActive('calculo-emisiones'),
      action: () => goTo('/calculo-emisiones'),
    },
  )

  useEffect(() => {
    getProject(hash)
  }, [hash])

  useEffect(() => {
    if (bidPackageId && bidPackageId !== !bidPackage?.bidPackageId) {
      getBidPackage(bidPackageId)
      getAllBidItems(bidPackageId)
    }
  }, [bidPackageId])

  const goToProject = () => history.push(`/constructora/tus-obras/${hash}`)

  const goToHome = () => history.push('/constructora/tus-obras')

  const handleChangeMessage = (val) => {
    if (typeof val === 'string') setMessage(val)
  }

  const categoriesFormatted = categories.data.map((el) => ({
    value: el?.categoryId,
    label: el?.name,
  }))

  const handleOpenDetail = async (companyId, tabOpen = 'company') => {
    if (!companyId) return
    const resp = await getSellerByCompany(companyId)
    if (resp) {
      await getProposalsBySeller(companyId, buyerCompanyId)
      setDetailsIsOpened(true)
      setDetailsTabOpen(tabOpen)
    }
  }

  const publishBidPackage = async () => {
    const messageParsed = parseEditorMessage(message)
    await actionBidPackage(bidPackageId, 'Publish', { categoriesSelected, message: messageParsed })
    await getBidPackage(bidPackageId)
    setMessage('')
  }

  const notifyBidPackage = async () => {
    const messageParsed = parseEditorMessage(message)
    await actionBidPackage(bidPackageId, 'NotifyChanges', messageParsed)
    setMessage('')
    await getBidPackage(bidPackageId)
  }

  const openModalInvite = async () => {
    await getCategories()
    setPublishModalIsOpened(true)
  }

  const onOpenPublish = () => {
    if (bidPackage?.state !== 'Published') {
      openModalInvite()
    } else {
      setSellerMessageModalIsOpened(true)
    }
    setPublishPreviewModalIsOpened(false)
  }

  const onInviteSellers = (sellersSelected) => {
    setCategoriesSelected(sellersSelected)
    if (setSellerMessageModalIsOpened) setSellerMessageModalIsOpened(true)
    setPublishModalIsOpened(false)
  }

  const onPublish = async () => {
    setSellerMessageModalIsOpened(false)
    if (bidPackage?.state === 'Draft') {
      await publishBidPackage()
    } else {
      await notifyBidPackage()
    }
  }

  return (
    <BidPackageTemplate
      bidPackage={bidPackage}
      bidItems={bidItems}
      project={project}
      tabItems={tabItems}
      categories={categoriesFormatted}
      handleOpenDetail={handleOpenDetail}
      setDetailsIsOpened={setDetailsIsOpened}
      proposalsBySeller={proposalsBySeller}
      detailsTabOpen={detailsTabOpen}
      detailsIsOpened={detailsIsOpened}
      goToProject={goToProject}
      goToHome={goToHome}
      seller={seller}
      message={message}
      handleChangeMessage={handleChangeMessage}
      publishPreviewModalIsOpened={publishPreviewModalIsOpened}
      setPublishPreviewModalIsOpened={setPublishPreviewModalIsOpened}
      publishModalIsOpened={publishModalIsOpened}
      setPublishModalIsOpened={setPublishModalIsOpened}
      setSellerMessageModalIsOpened={setSellerMessageModalIsOpened}
      sellerMessageModalIsOpened={sellerMessageModalIsOpened}
      onOpenPublish={onOpenPublish}
      onPublish={onPublish}
      onInviteSellers={onInviteSellers}>
      <Page
        project={project}
        bidPackage={bidPackage}
        getBidPackage={getBidPackage}
        bidItems={bidItems}
        getAllBidItems={getAllBidItems}
      />
    </BidPackageTemplate>
  )
}

export default BidPackageContainer
