import { BidPackageActions } from '../components/BidPackageActions'
import { BidPackageInfo } from '../components/BidPackageInfo'
import { ModalDeleteBidPackage } from '../components/ModalDeleteBidPackage'
import { EditBidPackage } from '../components/EditBidPackage'
import { ModalSendMessage } from '../../../../../common/components/sendMessage/ModalSendMessage'

export const BidPackageInfoTemplate = ({
  bidPackage,
  formIsOpened,
  setFormIsOpened,
  categories,
  onEditBidPackage,
  deleteModalIsOpened,
  setDeleteModalIsOpened,
  onDeleteBidPackage,
  onCopyBidPackage,
  onCancelBidPackage,
  setCancelNotificationIsOpen,
  cancelNotificationIsOpen,
  setData,
  errorsData,
  onDeleteFile,
  files,
  onImagesLoad,
  errorsImages,
  onSubmit,
  links,
  addLink,
  removeLink,
  inputLink,
  setInputLink,
  handleAddDescription,
  verifyUrl,
}) => (
  <>
    <BidPackageActions
      bidPackage={bidPackage}
      setFormIsOpened={setFormIsOpened}
      onEditBidPackage={onEditBidPackage}
      onCopyBidPackage={onCopyBidPackage}
      setCancelNotificationIsOpen={setCancelNotificationIsOpen}
      setDeleteModalIsOpened={setDeleteModalIsOpened}
    />
    <BidPackageInfo bidPackage={bidPackage} categories={categories} />
    <ModalDeleteBidPackage
      deleteModalIsOpened={deleteModalIsOpened}
      setDeleteModalIsOpened={setDeleteModalIsOpened}
      onDeleteBidPackage={onDeleteBidPackage}
    />
    {formIsOpened && (
      <EditBidPackage
        setData={setData}
        data={bidPackage}
        errorsData={errorsData}
        onDeleteFile={onDeleteFile}
        files={files}
        onImagesLoad={onImagesLoad}
        errorsImages={errorsImages}
        setFormIsOpened={setFormIsOpened}
        title="Edita la información básica de esta compra"
        onSubmit={onSubmit}
        categories={categories}
        links={links}
        addLink={addLink}
        removeLink={removeLink}
        inputLink={inputLink}
        setInputLink={setInputLink}
        handleAddDescription={handleAddDescription}
        verifyUrl={verifyUrl}
      />
    )}
    <ModalSendMessage
      title="¿Quieres enviar notificaciones a los proveedores participantes?"
      subTitle="Puedes enviar una notificación con un mensaje opcional."
      isOpened={cancelNotificationIsOpen}
      setIsOpened={setCancelNotificationIsOpen}
      onSubmit={onCancelBidPackage}
    />
  </>
)
