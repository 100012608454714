import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Box, Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Avatar } from '@proveoeng/uikit/dist/atoms/Avatar'
import { ActionMenu } from '@proveoeng/uikit/dist/molecules/ActionsMenu/ActionMenu'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { SelectInput } from '@proveoeng/uikit/dist/atoms/Select'
import { SelectInputMultiple } from '@proveoeng/uikit/dist/atoms/SelectMultiple'

import { ModalDeleteProject } from '../components/ModalDeleteProject'
import { parseToInteger, currencyFormat } from '../../../../../lib/utils/Format'
import { colorState, copyState } from '../../config'

export const ProjectListTemplate = ({
  projects,
  onActionProject,
  optionsOrder,
  optionsFilter,
  setOrder,
  order,
  filters,
  setFilters,
  whoIsActive,
  deleteProjectModalIsOpened,
  selectProjectToDelete,
  closeModal,
  handleDeleteProject,
  goToCreateProject,
  goToProjectDetails,
  goToProjectOffer,
}) => {
  const FilterAndSort = () => (
    <>
      <Flex width="100%" alignItems="center" justifyContent="space-between" marginBottom={5}>
        <Box width="50%">
          <SelectInputMultiple
            options={optionsFilter}
            id="filter"
            label="Estado"
            onChange={(_, a) => setTimeout(() => setFilters(a), 0)}
            defaultValue={filters}
          />
        </Box>
        <Flex alignItems="center">
          Ordenar por
          <Box width="250px" marginLeft={5}>
            <SelectInput
              isSearchable
              options={optionsOrder}
              id="order"
              label="Ordenar por"
              onChange={(_, a) => setTimeout(() => setOrder(a), 0)}
              defaultValue={order}
            />
          </Box>
        </Flex>
      </Flex>
    </>
  )

  return (
    <Flex height="100%" width="100%" flexDirection="column">
      {whoIsActive === 'projects' && (
        <>
          <Flex width="100%" alignItems="center" justifyContent="space-between" marginBottom={5}>
            <Text sizeText="display30" color="black" fontWeight="bold" maxWidth={856}>
              Tus obras
            </Text>
            <Box width="200px">
              <Button colorType="orange" action={goToCreateProject} fullWidth>
                Crear obra
              </Button>
            </Box>
          </Flex>

          <FilterAndSort />
          <Flex justifyContent="flex-end">
            {/* TODO: Hacer bien y pintar bien la paginación cuando se ajusten las peticiones de aquí */}
            <Text sizeText="display14" color="black" marginBottom={5}>
              <Text
                as="span"
                sizeText="display14"
                color="black"
                marginBottom={5}
                fontWeight="medium">
                {projects?.meta?.page * projects?.meta?.total}
              </Text>{' '}
              de {projects?.meta?.total} resultados
            </Text>
          </Flex>
          <Box width="100%">
            <Grid
              gridTemplateAreas={"'name init finish budget state actions'"}
              gridTemplateColumns="35% 15% 15% 15% 15% 5%"
              bg="gray4"
              paddingY={2}
              borderRadius={2}>
              <GridItem gridArea="name">
                <Text
                  sizeText="display14"
                  color="gray1"
                  overflow="hidden"
                  fontWeight="medium"
                  marginLeft={5}>
                  Nombre
                </Text>
              </GridItem>
              <GridItem gridArea="init">
                <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
                  Fecha de inicio
                </Text>
              </GridItem>
              <GridItem gridArea="finish">
                <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
                  Fecha final
                </Text>
              </GridItem>
              <GridItem gridArea="budget">
                <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
                  Presupuesto de venta
                </Text>
              </GridItem>
              <GridItem gridArea="state">
                <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
                  Estado
                </Text>
              </GridItem>
              <GridItem gridArea="actions" />
            </Grid>

            {projects?.data?.map((project) => (
              <Grid
                key={project?.projectId}
                gridTemplateAreas={"'name init finish budget state actions'"}
                gridTemplateColumns="35% 15% 15% 15% 15% 5%"
                paddingY={4}
                borderBottomColor="gray4"
                borderBottomStyle="solid"
                borderBottomWidth="1px">
                <GridItem
                  gridArea="name"
                  display="flex"
                  style={{ alignItems: ' center', cursor: 'pointer' }}
                  onClick={() => goToProjectDetails(project?.projectId)}>
                  <Avatar
                    src={project?.thumb}
                    borderRadius={3}
                    marginRight={3}
                    placeholder={project?.name}
                  />
                  <Box>
                    <Text
                      sizeText="display14"
                      color="gray1"
                      overflow="hidden"
                      fontWeight="medium"
                      paddingRight={15}>
                      {project?.name}
                    </Text>
                    <Text
                      sizeText="display14"
                      color="gray1"
                      overflow="hidden"
                      fontWeight="medium"
                      paddingRight={15}>
                      {project?.address}
                    </Text>
                  </Box>
                </GridItem>
                <GridItem gridArea="init" display="flex" style={{ alignItems: ' center' }}>
                  <Text sizeText="display14" color="gray1">
                    {project?.startFormatted?.readableReverseDate}
                  </Text>
                </GridItem>
                <GridItem gridArea="finish" display="flex" style={{ alignItems: ' center' }}>
                  <Text sizeText="display14" color="gray1">
                    {project?.endFormatted?.readableReverseDate}
                  </Text>
                </GridItem>
                <GridItem gridArea="budget" display="flex" style={{ alignItems: ' center' }}>
                  <Text sizeText="display14" color="gray1">
                    {currencyFormat(parseToInteger(project?.budget, 2) / 100)}
                  </Text>
                </GridItem>
                <GridItem gridArea="state" display="flex" style={{ alignItems: 'center' }}>
                  <Box
                    backgroundColor={colorState[project?.state]}
                    width="8px"
                    height="8px"
                    borderRadius={8}
                    marginX={1}
                    minWidth="8px"
                  />
                  <Text as="span" marginLeft={1} color="gray1">
                    {copyState[project?.state]}
                  </Text>
                </GridItem>
                <GridItem gridArea="actions" display="flex" style={{ alignItems: ' center' }}>
                  <ActionMenu
                    actions={[
                      {
                        id: 'edit',
                        isDisabled: project?.state !== 'Open',
                        content: (
                          <Text sizeText="display14" color="black" paddingTop={4}>
                            Editar
                          </Text>
                        ),
                        action: () => goToProjectDetails(project?.projectId),
                      },

                      {
                        id: 'copy',
                        content: (
                          <Text sizeText="display14" color="black" paddingY={4}>
                            Duplicar
                          </Text>
                        ),
                        action: () => onActionProject(project?.projectId, 'Copy'),
                      },
                      {
                        id: 'delete',
                        isDisabled: project?.state === 'Publish' || project?.state === 'Closed',
                        content: (
                          <Text sizeText="display14" color="black" paddingBottom={4}>
                            Eliminar
                          </Text>
                        ),
                        action: () => selectProjectToDelete(project?.projectId),
                      },
                      {
                        id: 'close',
                        content:
                          project.state === 'Closed' ? (
                            <Text sizeText="display14" color="black" paddingBottom={4}>
                              Reabrir proyecto
                            </Text>
                          ) : (
                            <Text sizeText="display14" color="black" paddingBottom={4}>
                              Cerrar
                            </Text>
                          ),
                        action: () =>
                          onActionProject(
                            project?.projectId,
                            project.state === 'Closed' ? 'Reopen' : 'Close',
                          ),
                      },
                    ]}
                  />
                </GridItem>
              </Grid>
            ))}
          </Box>
          <ModalDeleteProject
            isOpened={deleteProjectModalIsOpened}
            closeModal={closeModal}
            handleDeleteProject={handleDeleteProject}
          />
        </>
      )}
      {whoIsActive === 'offers' && (
        <>
          <Flex width="100%" alignItems="center" justifyContent="space-between">
            <Text
              sizeText="display30"
              color="black"
              marginBottom={5}
              fontWeight="bold"
              maxWidth={856}>
              Ofertas recibidas
            </Text>
          </Flex>
          <FilterAndSort />
          <Flex justifyContent="flex-end">
            {/* TODO: Hacer bien y pintar bien la paginación cuando se ajusten las peticiones de aquí */}
            <Text sizeText="display14" color="black" marginBottom={5}>
              <Text
                as="span"
                sizeText="display14"
                color="black"
                marginBottom={5}
                fontWeight="medium">
                {projects?.meta?.page * projects?.meta?.total}
              </Text>{' '}
              de {projects?.meta?.total} resultados
            </Text>
          </Flex>
          <Box width="100%">
            <Grid
              gridTemplateAreas={"'name init finish budget state actions'"}
              gridTemplateColumns="40% 15% 15% 15% 15%"
              bg="gray4"
              paddingY={2}
              borderRadius={2}>
              <GridItem gridArea="name">
                <Text
                  sizeText="display14"
                  color="gray1"
                  overflow="hidden"
                  fontWeight="medium"
                  marginLeft={5}>
                  Nombre
                </Text>
              </GridItem>
              <GridItem gridArea="init">
                <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
                  Fecha de inicio
                </Text>
              </GridItem>
              <GridItem gridArea="finish">
                <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
                  Fecha final
                </Text>
              </GridItem>
              <GridItem gridArea="budget">
                <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
                  Presupuesto de venta
                </Text>
              </GridItem>
              <GridItem gridArea="state">
                <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
                  Estado
                </Text>
              </GridItem>
              <GridItem gridArea="actions" />
            </Grid>

            {projects?.data?.map((project) => (
              <Grid
                key={project?.projectId}
                gridTemplateAreas={"'name init finish budget state actions'"}
                gridTemplateColumns="40% 15% 15% 15% 15%"
                paddingY={4}
                borderBottomColor="gray4"
                borderBottomStyle="solid"
                borderBottomWidth="1px">
                <GridItem
                  gridArea="name"
                  display="flex"
                  style={{ alignItems: ' center', cursor: 'pointer' }}
                  onClick={() => goToProjectOffer(project?.projectId)}>
                  <Avatar
                    src={project?.thumb}
                    borderRadius={3}
                    marginRight={3}
                    placeholder={project.name}
                  />
                  <Box>
                    <Text
                      sizeText="display14"
                      color="gray1"
                      overflow="hidden"
                      fontWeight="medium"
                      paddingRight={15}>
                      {project.name}
                    </Text>
                    <Text
                      sizeText="display14"
                      color="gray1"
                      overflow="hidden"
                      fontWeight="medium"
                      paddingRight={15}>
                      {project.address}
                    </Text>
                  </Box>
                </GridItem>
                <GridItem gridArea="init" display="flex" style={{ alignItems: ' center' }}>
                  <Text sizeText="display14" color="gray1">
                    {project.startFormatted?.readableReverseDate}
                  </Text>
                </GridItem>
                <GridItem gridArea="finish" display="flex" style={{ alignItems: ' center' }}>
                  <Text sizeText="display14" color="gray1">
                    {project.endFormatted?.readableReverseDate}
                  </Text>
                </GridItem>
                <GridItem gridArea="budget" display="flex" style={{ alignItems: ' center' }}>
                  <Text sizeText="display14" color="gray1">
                    {currencyFormat(parseToInteger(project?.budget, 2) / 100)}
                  </Text>
                </GridItem>
                <GridItem gridArea="state" display="flex" style={{ alignItems: 'center' }}>
                  <Box
                    backgroundColor={colorState[project?.state]}
                    width="8px"
                    height="8px"
                    borderRadius={8}
                    marginX={1}
                    minWidth="8px"
                  />
                  <Text as="span" marginLeft={1} color="gray1">
                    {copyState[project?.state]}
                  </Text>
                </GridItem>
                <GridItem gridArea="actions" display="flex" style={{ alignItems: ' center' }} />
              </Grid>
            ))}
          </Box>
        </>
      )}
    </Flex>
  )
}
