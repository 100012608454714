/* eslint-disable no-use-before-define */
import { useEffect, useRef, useState } from 'react'

export const useEditMode = ({ onSubmit, defaultMode = false }) => {
  const [editMode, setEditMode] = useState(false)
  const ref = useRef(null)

  const handleEditSubmit = () => {
    onSubmit()
    setEditMode(false)
    removeAllEventListeners()
  }

  /**
   * If clicked on outside of element
   */
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      handleEditSubmit()
    }
  }

  // If enter key is pressed or esc key is pressed
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      onSubmit()
      setEditMode(false)
      removeAllEventListeners()
    }
  }

  const removeAllEventListeners = () => {
    document.removeEventListener('mousedown', handleClickOutside)
    document.removeEventListener('keydown', handleKeyDown)
  }

  const handleEditMode = () => {
    setEditMode(true)
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleKeyDown)
  }

  useEffect(
    () => () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    },
    [],
  )

  useEffect(() => {
    if (defaultMode) handleEditMode()
  }, [defaultMode])

  return {
    editMode,
    handleEditMode,
    handleEditSubmit,
    ref,
  }
}
