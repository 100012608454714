import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Checkbox } from '@proveoeng/uikit/dist/atoms/Checkbox'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { SelectInput } from '@proveoeng/uikit/dist/atoms/Select'
import { Textarea } from '@proveoeng/uikit/dist/atoms/Textarea'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { InputNumber } from '@proveoeng/uikit/dist/atoms/InputNumber'

import { parseToString } from '../../../../../lib/utils/Format'
import { paymentDaysOptions } from '../../../buyer/config'
import { AttachmentList } from '../../../../common/components/attachmentList/Attachment'
import { Links } from '../../../../common/components/links/Links'

export const DetailProposalConditions = ({ proposal, bidPackage }) => {
  if (!proposal) return <></>

  return (
    <>
      <Flex
        flexDirection="column"
        alignItems="baseline"
        paddingX={0}
        margin="0 auto"
        width="100%"
        height="100%">
        <Flex paddingBottom="40px" width="100%">
          <Flex minWidth="264px" maxWidth="264px" paddingLeft={2}>
            <Text as="span" sizeText="display18" color="black" fontWeight="600">
              Descripción
            </Text>
          </Flex>
          <Flex paddingLeft={4}>
            <Text
              as="span"
              sizeText="display14"
              color="black"
              style={{
                whiteSpace: 'pre-wrap',
              }}>
              {bidPackage?.description || '-'}
            </Text>
          </Flex>
        </Flex>
        <Flex paddingBottom="40px" width="100%">
          <Flex flexDirection="column">
            <Flex minWidth="264px" maxWidth="264px" paddingLeft={2} flexDirection="column">
              <Text as="span" sizeText="display18" color="black" fontWeight="600" paddingBottom={2}>
                Fechas y plazo de entrega
              </Text>
              <Text as="span" sizeText="display14" color="gray1">
                Fechas indicadas por el contratista para el inicio y fin de entrega del material o
                servicio.
              </Text>
            </Flex>
          </Flex>
          <Flex paddingLeft={4} flexDirection="column" width="100%">
            <Flex paddingBottom={4}>
              <Text as="span" sizeText="display14" color="black">
                {bidPackage?.start?.readableReverseDate || 'No informada'} -{' '}
                {bidPackage?.end?.readableReverseDate || 'No informada'}
              </Text>
            </Flex>
            <Flex flexDirection="column">
              <Flex paddingBottom={4} flexDirection="column">
                <Checkbox
                  ariaLabel="modifyDeliveryDates"
                  action={() => {}}
                  defaultChecked={
                    !!(proposal?.start?.readableDate?.length || proposal?.end?.readableDate?.length)
                  }
                  name="modifyDeliveryDates"
                  label="Modificar fechas de entrega"
                  fontWeight="medium"
                  isDisabled
                />
                {!!(
                  proposal?.start?.readableDate?.length || proposal?.end?.readableDate?.length
                ) && (
                  <Flex>
                    <Flex flexDirection="column" paddingRight={5}>
                      <Field
                        label="Fecha inicio de entrega"
                        color="gray"
                        sizeText="display14"
                        marginBottom={3}
                        fontWeight="medium">
                        <Box width="184px">
                          <Input
                            type="date"
                            ariaLabel="start"
                            action={() => {}}
                            defaultValue={proposal?.start?.readableDate}
                            name="start"
                            disabled
                          />
                        </Box>
                      </Field>
                    </Flex>
                    <Flex flexDirection="column">
                      <Field
                        label="Fecha fin de entrega"
                        color="gray"
                        sizeText="display14"
                        marginBottom={3}
                        fontWeight="medium">
                        <Box width="184px">
                          <Input
                            type="date"
                            ariaLabel="end"
                            action={() => {}}
                            defaultValue={proposal?.end?.readableDate}
                            name="end"
                            disabled
                          />
                        </Box>
                      </Field>
                    </Flex>
                  </Flex>
                )}
              </Flex>
              <Flex flexDirection="column">
                <Checkbox
                  ariaLabel="modifyDeliveryTime"
                  action={() => {}}
                  defaultChecked={!!proposal?.deliveryTime}
                  name="modifyDeliveryTime"
                  label="Informar el plazo de entrega"
                  fontWeight="medium"
                  isDisabled
                />
                {!!proposal?.deliveryTime && (
                  <Field
                    label="Plazo de entrega"
                    color="gray"
                    sizeText="display14"
                    marginBottom={3}
                    fontWeight="medium"
                    style={{ paddingTop: 4 }}>
                    <Box width="184px">
                      <Input
                        required
                        ariaLabel="deliveryTime"
                        action={() => {}}
                        defaultValue={proposal?.deliveryTime}
                        name="deliveryTime"
                        placeholderMessage="Pon el plazo"
                        style={{ width: '184px' }}
                        disabled
                        maxLength="200"
                      />
                    </Box>
                  </Field>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex paddingBottom="40px" width="100%">
          <Flex minWidth="264px" maxWidth="264px" paddingLeft={2} flexDirection="column">
            <Text as="span" sizeText="display18" color="black" fontWeight="600" paddingBottom={2}>
              Retención
            </Text>
            <Text as="span" sizeText="display14" color="gray1">
              % de retención indicado por el contratista. Si no estás de acuerdo con la retención,
              puedes indicarlo desmarcando la aceptación y añadiendo tus comentarios.
            </Text>
          </Flex>
          <Flex paddingLeft={4} flexDirection="column" width="100%">
            <Flex paddingBottom={4}>
              <Text as="span" sizeText="display14" color="black">
                {bidPackage?.retention === null ? '-' : `${bidPackage?.retention} %`}
              </Text>
            </Flex>
            <Flex flexDirection="column" marginBottom={5}>
              <Flex paddingBottom={4}>
                <Checkbox
                  ariaLabel="acceptRetentionConditions"
                  action={() => {}}
                  defaultChecked={!(proposal?.retention || proposal?.retentionCriteria)}
                  name="acceptRetentionConditions"
                  label="Acepto la retención"
                  fontWeight="medium"
                  isDisabled
                />
              </Flex>
              {!!(proposal?.retention || proposal?.retentionCriteria) && (
                <Flex paddingTop={5}>
                  <Flex flexDirection="column" paddingRight={5}>
                    <Text
                      required
                      fontWeight="medium"
                      color="gray"
                      sizeText="display14"
                      marginBottom={2}>
                      % de retención*{' '}
                    </Text>
                    <Box width="184px">
                      <InputNumber
                        placeholderMessage="Pon tu % de retención"
                        ariaLabel="retention"
                        onChange={() => {}}
                        value={parseToString(proposal?.retention)}
                        name="retention"
                        disabled
                      />
                    </Box>
                  </Flex>
                  <Flex flexDirection="column" width="100%">
                    <Text fontWeight="medium" color="gray" sizeText="display14" marginBottom={2}>
                      Comentario de retención{' '}
                    </Text>
                    <Input
                      placeholderMessage="Pon tu comentario sobre la forma de pago"
                      ariaLabel="retentionCriteria"
                      action={() => {}}
                      defaultValue={proposal?.retentionCriteria}
                      name="retentionCriteria"
                      disabled
                    />
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex paddingBottom="40px" width="100%">
          <Flex minWidth="264px" maxWidth="264px" paddingLeft={2} flexDirection="column">
            <Text as="span" sizeText="display18" color="black" fontWeight="600" paddingBottom={2}>
              Condiciones de pago
            </Text>
            <Text as="span" sizeText="display14" color="gray1">
              Condiciones de pago ofertadas por el contratista. Si quieres proponer una alternativa,
              puedes indicarlo desmarcando la aceptación e introduciendo unas nuevas condiciones de
              pago.
            </Text>
          </Flex>
          <Flex paddingLeft={4} flexDirection="column" width="100%">
            <Flex paddingBottom={4}>
              <Text as="span" sizeText="display14" color="black">
                <Text as="span" sizeText="display18" color="black">{`${
                  bidPackage?.paymentDays || '-'
                } días`}</Text>{' '}
                - {bidPackage?.paymentConditions || 'Forma de pago no informada'}
              </Text>
            </Flex>
            <Flex flexDirection="column" marginBottom={5}>
              <Flex>
                <Checkbox
                  ariaLabel="acceptPaymentConditions"
                  action={() => {}}
                  defaultChecked={!(proposal?.paymentDays || proposal?.paymentConditions)}
                  name="acceptPaymentConditions"
                  label="Acepto las condiciones de pago del contratista"
                  fontWeight="medium"
                  isDisabled
                />
              </Flex>
              {!!(proposal?.paymentDays || proposal?.paymentConditions) && (
                <Flex paddingTop={5}>
                  <Flex flexDirection="column" paddingRight={5}>
                    <Text fontWeight="medium" color="gray" sizeText="display14" marginBottom={2}>
                      Días de pago*{' '}
                    </Text>
                    <Box width="184px">
                      <SelectInput
                        isSearchable
                        name="paymentDays"
                        id="paymentDays"
                        onChange={() => setTimeout(() => {}, 0)}
                        options={paymentDaysOptions}
                        placeholderMessage="Seleccionar"
                        ariaLabel="turnover"
                        defaultValue={
                          proposal?.paymentDays &&
                          paymentDaysOptions.find(
                            (el) => el.value === Number(proposal?.paymentDays),
                          )
                        }
                        isDisabled
                      />
                    </Box>
                  </Flex>
                  <Flex flexDirection="column" width="100%">
                    <Text fontWeight="medium" color="gray" sizeText="display14" marginBottom={2}>
                      Condiciones de pago{' '}
                    </Text>
                    <Input
                      placeholderMessage="Pon tu comentario sobre la forma de pago"
                      ariaLabel="paymentConditions"
                      action={() => {}}
                      defaultValue={proposal?.paymentConditions}
                      name="paymentConditions"
                      disabled
                    />
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex paddingBottom="40px" width="100%">
          <Flex minWidth="264px" paddingLeft={2}>
            <Text as="span" sizeText="display18" color="black" fontWeight="600">
              Información adicional
            </Text>
          </Flex>
          <Flex paddingLeft={4} flexDirection="column" width="100%">
            <Text as="span" sizeText="display14" color="black">
              <AttachmentList withDownload files={proposal?.bidPackage?.files} />
              <Links links={proposal?.bidPackage?.links} />
            </Text>
          </Flex>
        </Flex>
        <Flex paddingBottom="40px" width="100%">
          <Flex minWidth="264px" maxWidth="264px" paddingLeft={2} flexDirection="column">
            <Text as="span" sizeText="display18" color="black" fontWeight="600" paddingBottom={2}>
              Condiciones generales
            </Text>
            <Text as="span" sizeText="display14" color="gray1">
              Estas son las condiciones generales de la oferta que ha indicado el contratista.
            </Text>
          </Flex>
          <Flex paddingLeft={4} flexDirection="column" width="100%">
            <Flex paddingBottom={4}>
              <Text
                as="span"
                sizeText="display14"
                color="black"
                style={{
                  whiteSpace: 'pre-wrap',
                }}>
                {bidPackage?.generalConditions || '-'}
              </Text>
            </Flex>
            <Flex flexDirection="column" width="100%" paddingBottom={3} paddingRight={3}>
              <Text fontWeight="medium" color="gray" sizeText="display14" marginBottom={2}>
                Comentarios generales de la oferta para la constructora{' '}
              </Text>
              <Textarea
                placeholderMessage="Pon tus comentarios generales"
                name="comments"
                typeAction={() => {}}
                defaultValue={proposal?.comments}
                maxLength="auto"
                disabled
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex paddingBottom="40px" width="100%">
          <Flex minWidth="264px" maxWidth="264px" paddingLeft={2} flexDirection="column">
            <Text as="span" sizeText="display18" color="black" fontWeight="600" paddingBottom={2}>
              Documentación adicional
            </Text>
            <Text as="span" sizeText="display14" color="gray1">
              Contienen información adicional sobre la petición de presupuesto.
            </Text>
          </Flex>
          <Flex paddingLeft={4} flexDirection="column" width="100%">
            <Text as="span" sizeText="display14" color="black">
              <AttachmentList withDownload files={proposal?.files} />
              <Links links={proposal?.links} />
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
