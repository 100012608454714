import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { ArrowDown, ArrowRight } from '@proveoeng/uikit/dist/atoms/Icons'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { SectionSummaryHead } from './SummaryHead'
import { SummaryTotal } from './SummaryTotal'
import { SummarySection } from './SummarySection'
import { SummaryK } from './SummaryK'
import { formatDecimal } from '../../../common/functions/comparativeNumbers'
import { DetailsModal } from './DetailsModal'
import { useComparative } from '../../../common/hooks/useComparative'
import { SummaryPaymentDays } from './SummaryPaymentDays'
import { SummaryRetention } from './SummaryRetention'
import { SummaryOfferImprovementRate } from './SummaryOfferImprovementRate'
import { parseToInteger } from '../../../../lib/utils/Format'
import { SummaryOfferAmount } from './SummaryOfferAmount'
import { SummaryDiscount } from './SummaryDiscount'

export const Summary = ({ comparative, bidPackageId, tableWidthCSS, updateImprovementRate }) => {
  const [isOpened, setIsOpened] = useState(true)
  const [isDetailsModalOpened, setIsDetailsModalOpened] = useState({ show: false, data: '' })
  const { getOfferDetails } = useComparative()

  const validationSchema = Yup.object().shape({
    improvementRate: Yup.string(),
  })

  const initialValues = {
    improvementRate: formatDecimal(comparative.improvementRate),
  }

  const onSubmit = ({ improvementRate }) =>
    updateImprovementRate(parseToInteger(improvementRate, 2) / 100)

  const { values, errors, handleChange, handleSubmit, submitForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  if (!comparative) {
    return <></>
  }

  const offerColumnsHead = comparative.offers
    .sort((a, b) => (a.position > b.position ? 1 : -1))
    .map((offer) => `offer-${offer.id}`)
    .join(' ')

  const columnsHead = {
    area: `"description budget expectedCosts minEnvelope ${offerColumnsHead}"`,
    columns: `510px repeat(${comparative.offers.length + 3}, 220px)`,
  }

  const offerColumns = comparative.offers
    .sort((a, b) => (a.position > b.position ? 1 : -1))
    .map((offer) => `offer-k-${offer.id} offer-margin-${offer.id}`)
    .join(' ')

  const columns = {
    area: `"description budgetK budget expectedCostsK expectedCosts minEnvelopeK minEnvelope ${offerColumns}"`,
    columns: `510px 90px 130px 90px 130px 90px 130px repeat(${
      comparative.offers.length * 2
    }, 90px 130px)`,
  }

  const offerImprovementRateColumns = comparative.offers
    .sort((a, b) => (a.position > b.position ? 1 : -1))
    .map((offer) => `offer-improvement-rate-${offer.id}`)
    .join(' ')

  const discountColumns = {
    area: `"description budgetK budget expectedCostsK expectedCosts minEnvelopeK minEnvelope ${offerImprovementRateColumns}"`,
    columns: `510px 90px 130px 90px 130px 90px 130px repeat(${comparative.offers.length}, 220px)`,
  }

  const getDetails = async (proposalId) => {
    const data = await getOfferDetails(bidPackageId, proposalId)
    setIsDetailsModalOpened({ show: true, data })
  }

  const setDetailsLink = (offer) => {
    if (!offer?.proposalInfo)
      return (
        <Text color="gray1" textAlign="right" sizeText="display12">
          -
        </Text>
      )

    return (
      <Text
        color="blue1"
        textAlign="right"
        sizeText="display12"
        style={{ textDecoration: 'underline', cursor: 'pointer' }}
        onClick={() => getDetails(offer?.proposalId)}>
        Ver detalle
      </Text>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <Flex
        paddingBottom={6}
        flexDirection="column"
        position="relative"
        style={{ width: tableWidthCSS }}>
        <Flex
          justifyContent="space-between"
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 11,
            backgroundColor: 'white',
          }}
          paddingBottom={4}>
          <Flex
            alignItems="center"
            paddingX={3}
            style={{
              position: 'sticky',
              left: '0px',
            }}>
            <Icon
              style={{ cursor: 'pointer' }}
              sizeIcon="display18"
              color="black"
              marginRight={1}
              onClick={() => setIsOpened(!isOpened)}>
              {isOpened ? <ArrowDown /> : <ArrowRight />}
            </Icon>
            <Flex alignItems="center">
              <Text
                as="span"
                marginLeft={1}
                sizeText="display18"
                color="black"
                style={{ cursor: 'pointer' }}
                onClick={() => setIsOpened(!isOpened)}>
                Resumen Comparativo
              </Text>
            </Flex>
          </Flex>
        </Flex>
        {isOpened && (
          <>
            <SectionSummaryHead
              offers={comparative.offers}
              columnsHead={columnsHead}
              tableWidthCSS={tableWidthCSS}
            />
            {comparative?.sections.map((section) => (
              <SummarySection
                section={section}
                offers={comparative.offers}
                columns={columns}
                tableWidthCSS={tableWidthCSS}
              />
            ))}
            <SummaryTotal
              description="Importe"
              data={comparative}
              columns={columns}
              tableWidthCSS={tableWidthCSS}
            />
            <SummaryK data={comparative} columns={columns} tableWidthCSS={tableWidthCSS} />
            <SummaryDiscount
              data={comparative}
              columns={discountColumns}
              tableWidthCSS={tableWidthCSS}
              values={values}
              handleChange={handleChange}
              errors={errors}
              submitForm={submitForm}
            />

            <Grid
              gridTemplateAreas={`"empty info ${offerColumnsHead}"`}
              gridTemplateColumns={`950px 220px repeat(${comparative.offers.length}, 220px)`}
              style={{ width: tableWidthCSS }}>
              <GridItem
                gridArea="empty"
                backgroundColor="white2"
                padding={2}
                style={{
                  position: 'sticky',
                  left: 0,
                  zIndex: 9,
                }}
              />
              <GridItem
                gridArea="info"
                backgroundColor="white2"
                borderRightColor="gray3"
                borderRightStyle="solid"
                borderRightWidth="1px"
                padding={2}
                paddingTop={4}
                paddingLeft={4}
                style={{
                  position: 'sticky',
                  left: 950,
                  zIndex: 9,
                }}>
                <Text color="gray1" overflow="hidden" fontWeight="medium" sizeText="display12">
                  Información adicional
                </Text>
              </GridItem>
              {comparative.offers.map((offer) => (
                <GridItem
                  gridArea={`offer-${offer.id}`}
                  backgroundColor="white"
                  borderRightColor="gray3"
                  borderRightStyle="solid"
                  borderRightWidth="1px"
                  padding={2}
                  paddingTop={4}>
                  {setDetailsLink(offer)}
                </GridItem>
              ))}
            </Grid>
            <DetailsModal
              isOpen={isDetailsModalOpened.show}
              onClose={() => setIsDetailsModalOpened({ show: false, data: '' })}
              data={isDetailsModalOpened.data}
            />
            <Grid
              gridTemplateAreas={`"empty info ${offerColumnsHead}"`}
              gridTemplateColumns={`950px 220px repeat(${comparative.offers.length}, 220px)`}
              style={{ width: tableWidthCSS }}>
              <GridItem
                gridArea="empty"
                backgroundColor="white2"
                padding={2}
                style={{
                  position: 'sticky',
                  left: 0,
                  zIndex: 9,
                }}
              />
              <GridItem
                gridArea="info"
                backgroundColor="white2"
                borderRightColor="gray3"
                borderRightStyle="solid"
                borderRightWidth="1px"
                padding={2}
                paddingLeft={4}
                style={{
                  position: 'sticky',
                  left: 950,
                  zIndex: 9,
                }}>
                <Text color="gray1" overflow="hidden" fontWeight="medium" sizeText="display12">
                  Forma de pago
                </Text>
              </GridItem>
              {comparative.offers.map((offer) => (
                <GridItem
                  gridArea={`offer-${offer.id}`}
                  backgroundColor="white"
                  borderRightColor="gray3"
                  borderRightStyle="solid"
                  borderRightWidth="1px"
                  padding={2}>
                  <Flex justifyContent="flex-end">
                    <SummaryPaymentDays offer={offer} comparative={comparative} />
                  </Flex>
                </GridItem>
              ))}
            </Grid>
            <Grid
              gridTemplateAreas={`"empty info ${offerColumnsHead}"`}
              gridTemplateColumns={`950px 220px repeat(${comparative.offers.length}, 220px)`}
              style={{ width: tableWidthCSS }}>
              <GridItem
                gridArea="empty"
                backgroundColor="white2"
                padding={2}
                style={{
                  position: 'sticky',
                  left: 0,
                  zIndex: 9,
                }}
              />
              <GridItem
                gridArea="info"
                backgroundColor="white2"
                borderRightColor="gray3"
                borderRightStyle="solid"
                borderRightWidth="1px"
                padding={2}
                paddingLeft={4}
                style={{
                  position: 'sticky',
                  left: 950,
                  zIndex: 9,
                }}>
                <Text color="gray1" overflow="hidden" fontWeight="medium" sizeText="display12">
                  Retención
                </Text>
              </GridItem>
              {comparative.offers.map((offer) => (
                <GridItem
                  gridArea={`offer-${offer.id}`}
                  backgroundColor="white"
                  borderRightColor="gray3"
                  borderRightStyle="solid"
                  borderRightWidth="1px"
                  padding={2}>
                  <Text color="gray1" textAlign="right" sizeText="display12">
                    <SummaryRetention offer={offer} comparative={comparative} />
                  </Text>
                </GridItem>
              ))}
            </Grid>
            <Grid
              gridTemplateAreas={`"empty info ${offerColumns}"`}
              gridTemplateColumns={`950px 220px repeat(${comparative.offers.length * 2}, 110px)`}
              style={{ width: tableWidthCSS }}>
              <GridItem
                gridArea="empty"
                backgroundColor="white2"
                borderBottomColor="gray3"
                borderBottomStyle="solid"
                borderBottomWidth="1px"
                padding={2}
                style={{
                  position: 'sticky',
                  left: 0,
                  zIndex: 9,
                }}
              />
              <GridItem
                gridArea="info"
                backgroundColor="white2"
                borderRightColor="gray3"
                borderRightStyle="solid"
                borderRightWidth="1px"
                borderBottomColor="gray3"
                borderBottomStyle="solid"
                borderBottomWidth="1px"
                padding={2}
                paddingLeft={4}
                style={{
                  position: 'sticky',
                  left: 950,
                  zIndex: 9,
                }}>
                <Text color="gray1" overflow="hidden" fontWeight="medium" sizeText="display12">
                  Entrega
                </Text>
              </GridItem>
              {comparative.offers.map((offer) => (
                <>
                  <GridItem
                    gridArea={`offer-k-${offer.id}`}
                    backgroundColor="white"
                    padding={2}
                    borderBottomColor="gray3"
                    borderBottomStyle="solid"
                    borderBottomWidth="1px">
                    <Text color="gray1" textAlign="right" sizeText="display12">
                      {offer?.startDate?.readableReverseDateDotted ?? '-'}
                    </Text>
                  </GridItem>
                  <GridItem
                    gridArea={`offer-margin-${offer.id}`}
                    backgroundColor="white"
                    borderRightColor="gray3"
                    borderRightStyle="solid"
                    borderRightWidth="1px"
                    borderBottomColor="gray3"
                    borderBottomStyle="solid"
                    borderBottomWidth="1px"
                    padding={2}>
                    <Text color="gray1" textAlign="right" sizeText="display12">
                      {offer?.endDate?.readableReverseDateDotted ?? '-'}
                    </Text>
                  </GridItem>
                </>
              ))}
            </Grid>
            {false && (
              <Grid
                gridTemplateAreas={`"empty info improvementRate ${offerImprovementRateColumns}"`}
                gridTemplateColumns={`950px 120px 100px repeat(${
                  comparative.offers.length * 2
                }, 110px)`}
                style={{ width: tableWidthCSS }}>
                {comparative.offers.map((offer) => (
                  <>
                    <GridItem
                      gridArea={`offer-improvement-rate-${offer.id}`}
                      backgroundColor="white"
                      borderBottomColor="gray3"
                      borderBottomStyle="solid"
                      borderBottomWidth="1px"
                      padding={2}>
                      <Flex justifyContent="flex-end">
                        <SummaryOfferImprovementRate
                          data={comparative}
                          offer={offer}
                          improvementRate={comparative?.improvementRate}
                        />
                      </Flex>
                    </GridItem>
                    <GridItem
                      gridArea={`offer-amount-${offer.id}`}
                      backgroundColor="white"
                      borderBottomColor="gray3"
                      borderBottomStyle="solid"
                      borderBottomWidth="1px"
                      borderRightColor="gray3"
                      borderRightStyle="solid"
                      borderRightWidth="1px"
                      padding={2}>
                      <Flex justifyContent="flex-end">
                        <SummaryOfferAmount
                          data={comparative}
                          offer={offer}
                          improvementRate={comparative?.improvementRate}
                        />
                      </Flex>
                    </GridItem>
                  </>
                ))}
              </Grid>
            )}
          </>
        )}
      </Flex>
    </form>
  )
}
