import PropTypes from 'prop-types'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'
import { Box, Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Uploader } from '@proveoeng/uikit/dist/atoms/Uploader/Uploader'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography/Typography'

export const ModalUploadImport = ({
  errorsImages,
  isOpened,
  setIsOpened,
  onImagesLoad,
  files,
  onSubmit,
  type = 'bidPackage',
}) => (
  <Modal
    isOpen={isOpened}
    id="import-modal"
    onModalClose={() => setIsOpened(false)}
    closeWithButton
    title={type === 'bidPackage' ? 'Importa tu obra' : 'Importa tu oferta'}
    maxWidth="808px">
    <Modal.Content>
      <Box margin="0 auto">
        <Uploader
          name="files"
          marginBottom={3}
          errors={errorsImages}
          maxNumFiles={1}
          onImagesLoad={onImagesLoad}
          descriptionMessage="Arrastra, o haz click para subir tu documento aquí"
          accept="all"
          padding={5}
        />
        {files?.length !== 0 && (
          <Flex marginBottom={3} flexWrap="wrap">
            <Text sizeText="display12" color="gray2" paddingX={4} paddingY={4}>
              {files[files.length - 1]?.name || ''}
            </Text>
          </Flex>
        )}
      </Box>
    </Modal.Content>
    <Modal.Actions>
      <Flex justifyContent="flex-end">
        <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr">
          <Button
            colorType="orange"
            disabled={!files?.length}
            action={() => {
              onSubmit()
              setIsOpened(false)
            }}>
            {type === 'bidPackage' ? 'Crea procesos de compra' : 'Importar oferta'}
          </Button>
        </Grid>
      </Flex>
    </Modal.Actions>
  </Modal>
)

ModalUploadImport.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  setIsOpened: PropTypes.bool.isRequired,
}
