import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Box } from '@proveoeng/uikit/dist/atoms/Layout/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal/Modal'

export const ModalExitConfirmation = ({ isOpened, handleClose, handleConfirm }) => (
  <Modal
    isOpen={isOpened}
    id="exit-confirmation-modal"
    closeWithButton
    onModalClose={handleClose}
    width="390px"
    title="¿Seguro que quieres salir?">
    <Modal.Content>
      <Box paddingTop={4}>
        <Text sizeText="display16" fontWeight="regular">
          Las invitaciones no se enviarán si sales en este momento.
        </Text>
      </Box>
    </Modal.Content>
    <Modal.Actions>
      <Flex justifyContent="center">
        <Flex width="100%" justifyContent="flex-end">
          <Box marginRight={5}>
            <Button fullWidth colorType="transparent" action={handleConfirm}>
              Salir
            </Button>
          </Box>
          <Box>
            <Button fullWidth colorType="orange" action={handleClose}>
              Volver a editar
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Modal.Actions>
  </Modal>
)
