import { useEffect, useState } from 'react'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Box, Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { Textarea } from '@proveoeng/uikit/dist/atoms/Textarea'
import { Uploader } from '@proveoeng/uikit/dist/atoms/Uploader'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'
import { useFormikContext } from 'formik'
import { AttachmentList } from '../../../../common/components/attachmentList/Attachment'
import { LinksList } from '../../../../common/components/links/LinksList'
import { useAttachmentLinks } from '../../../../common/hooks/useAttachmentLinks'
import { errorsImages } from '../../common/config'
import { onImagesLoad } from '../../../../common/functions/data'

export const ModalEditItemDetails = ({ isOpen, onClose, item }) => {
  const { values, errors, touched, handleBlur, setValues } = useFormikContext()

  const inputName = `comments-${item?.proposalItemId}`
  const filesName = `files-${item?.proposalItemId}`
  const filesToDeleteName = `filesToDelete-${item?.proposalItemId}`
  const linksName = `links-${item?.proposalItemId}`
  const linksToDeleteName = `linksToDelete-${item?.proposalItemId}`

  const [comments, setComments] = useState('')

  const [files, setFiles] = useState(values[filesName] || [])
  const [filesToDelete, setFilesToDelete] = useState([])

  const {
    links,
    inputLink,
    setLinks,
    deleteLinksId,
    setInputLink,
    addLink,
    handleAddDescription,
    removeLink,
    verifyUrl,
  } = useAttachmentLinks(values[linksName] || [])

  useEffect(() => {
    reset()
  }, [item])

  const reset = () => {
    setComments(values[inputName])
    setFiles(values[filesName] || [])
    setLinks(values[linksName] || [])
  }

  const handleChangeComment = (e) => setComments(e.target.value)

  const handleAddFile = (files) => setFiles((prev) => [...prev, ...files])

  const handleDeleteFile = (file) => {
    const updateFiles = file?.fileId
      ? files?.filter((element) => element?.fileId !== file.fileId)
      : files?.filter((element) => element?.name !== file.name)
    setFiles(updateFiles)
    if (file?.fileId) {
      setFilesToDelete((prev) => [...prev, file])
    }
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  const handleSubmit = () => {
    setValues((prev) => ({
      ...prev,
      [inputName]: comments,
      [filesName]: files,
      [filesToDeleteName]: [...(values[filesToDeleteName] || []), ...filesToDelete],
      [linksName]: links,
      [linksToDeleteName]: [...(values[linksToDeleteName] || []), ...deleteLinksId],
    }))
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      id="proposal-items-files"
      onModalClose={handleClose}
      title="Detalles de la partida"
      closeWithButton
      maxWidth="808px">
      <Modal.Content>
        <Box margin="0 auto" overflow="auto">
          <Flex flexDirection="column">
            <Box marginBottom={4}>
              <Field
                label="Aclaraciones sobre la partida"
                required
                sizeText="display16"
                marginBottom={3}
                marginTop={5}>
                <Textarea
                  placeholderMessage="Escribe las aclaraciones aquí"
                  name={inputName}
                  onChange={handleChangeComment}
                  onBlur={handleBlur}
                  hasError={!!errors[inputName] && touched[inputName]}
                  maxLength="auto"
                  defaultValue={comments}
                />
              </Field>
            </Box>
            <Box marginBottom={5}>
              <Field
                label="Si quieres adjuntar algún documento de la partida puedes hacerlo aquí."
                color="black"
                sizeText="display16">
                <AttachmentList files={files} withDelete onDeleteFile={handleDeleteFile} />
                <Box>
                  <Uploader
                    multiple
                    name="files"
                    marginBottom={3}
                    errors={errorsImages}
                    maxNumFiles={5}
                    onImagesLoad={(files) => onImagesLoad(files, handleAddFile)}
                    descriptionMessage="Arrastra o haz click aquí para subir tus ficheros aquí"
                    accept="all"
                    padding={5}
                  />
                </Box>
              </Field>
            </Box>
            <Box marginBottom={5}>
              <LinksList
                links={links}
                inputLink={inputLink}
                setInputLink={setInputLink}
                addLink={addLink}
                handleAddDescription={handleAddDescription}
                removeLink={removeLink}
                verifyUrl={verifyUrl}
              />
            </Box>
          </Flex>
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr">
            <Button fullWidth width="184px" colorType="orange" action={handleSubmit}>
              Guardar
            </Button>
          </Grid>
        </Flex>
      </Modal.Actions>
    </Modal>
  )
}
