import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import LinkIcon from '@proveoeng/uikit/dist/atoms/Icons/Link'
import { Image } from '@proveoeng/uikit/dist/atoms/Image/Image'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import company3 from '../../../../common/assets/listings/logoAislantum.png'
import company1 from '../../../../common/assets/listings/logoHilti.png'
import company6 from '../../../../common/assets/listings/logoLafargeholcim.png'
import company5 from '../../../../common/assets/listings/logoPavimdur.png'
import company2 from '../../../../common/assets/listings/logoSanha.png'
import company4 from '../../../../common/assets/listings/logoTecemac.png'

const HomeListingsPage = () => (
  <Flex height="100%" width="100%" flexDirection="column">
    <Flex width="100%" alignItems="center" justifyContent="space-between" marginBottom={5}>
      <Text sizeText="display30" color="black" fontWeight="bold" maxWidth={856}>
        Listings
      </Text>
    </Flex>
    <Flex paddingBottom={6}>
      <Flex
        position="relative"
        flexDirection="column"
        justifyContent="space-between"
        minWidth="360px"
        width="33%"
        paddingX={5}
        paddingY={6}
        marginRight={5}
        style={{
          backgroundColor: '#fafafa',
          border: '2px solid #D1D7E6',
          borderRadius: '4px',
        }}>
        <Flex width="100%" justifyContent="center" alignItems="center" paddingBottom={4}>
          <Box width="80%">
            <Image
              src={company1}
              alt="company1_logo"
              height={40}
              width={185}
              styleImg={{ objectFit: 'contain' }}
            />
          </Box>
          <Flex width="20%" justifyContent="flex-end">
            <Icon
              color="gray2"
              sizeIcon="display12"
              onClick={() => {
                window.open('https://www.hilti.com/#nav/products', '_blank').focus()
              }}
              style={{ cursor: 'pointer' }}>
              <LinkIcon />
            </Icon>
          </Flex>
        </Flex>
        <Flex width="100%" height="48px">
          <Text sizeText="display16" color="gray1">
            Máquinas varias, Instalación de mobiliario y equipamiento urbano y de edificios y 2 más
          </Text>
        </Flex>
      </Flex>
      <Flex
        position="relative"
        flexDirection="column"
        justifyContent="space-between"
        minWidth="360px"
        width="33%"
        paddingX={5}
        paddingY={6}
        marginRight={5}
        style={{
          backgroundColor: '#fafafa',
          border: '2px solid #D1D7E6',
          borderRadius: '4px',
        }}>
        <Flex width="100%" justifyContent="center" alignItems="center" paddingBottom={4}>
          <Box width="80%">
            <Image
              src={company2}
              alt="company2_logo"
              width={87}
              height={40}
              styleImg={{ objectFit: 'contain' }}
            />
          </Box>
          <Flex width="20%" justifyContent="flex-end">
            <Icon
              color="gray2"
              sizeIcon="display12"
              onClick={() => {
                window.open('https://www.sanha.com/es/catalogo-de-productos/', '_blank').focus()
              }}
              style={{ cursor: 'pointer' }}>
              <LinkIcon />
            </Icon>
          </Flex>
        </Flex>
        <Flex width="100%" height="48px">
          <Text sizeText="display16" color="gray1">
            Acero corrugado, Acero estructural, Tuberías, válvulas y accesorios y 3 más
          </Text>
        </Flex>
      </Flex>
      <Flex
        position="relative"
        flexDirection="column"
        minWidth="360px"
        justifyContent="space-between"
        width="33%"
        paddingX={5}
        paddingY={6}
        marginRight={5}
        style={{
          backgroundColor: '#fafafa',
          border: '2px solid #D1D7E6',
          borderRadius: '4px',
        }}>
        <Flex width="100%" justifyContent="center" alignItems="center" paddingBottom={2}>
          <Box width="80%">
            <Image
              src={company3}
              alt="company3_logo"
              height={40}
              width={280}
              styleImg={{ objectFit: 'contain' }}
            />
          </Box>
          <Flex width="20%" justifyContent="flex-end">
            <Icon
              color="gray2"
              sizeIcon="display12"
              onClick={() => {
                window.open('https://www.aislantium.es/', '_blank').focus()
              }}
              style={{ cursor: 'pointer' }}>
              <LinkIcon />
            </Icon>
          </Flex>
        </Flex>
        <Flex width="100%" height="48px">
          <Text sizeText="display16" color="gray1">
            Impermeabilizaciones, Aislantes, Otros servicios
          </Text>
        </Flex>
      </Flex>
    </Flex>
    <Flex>
      <Flex
        position="relative"
        flexDirection="column"
        justifyContent="space-between"
        minWidth="360px"
        width="33%"
        paddingX={5}
        paddingY={6}
        marginRight={5}
        style={{
          backgroundColor: '#fafafa',
          border: '2px solid #D1D7E6',
          borderRadius: '4px',
        }}>
        <Flex width="100%" justifyContent="center" alignItems="center" paddingBottom={4}>
          <Box width="80%">
            <Image
              src={company4}
              alt="company4_logo"
              height={40}
              width={156}
              styleImg={{ objectFit: 'contain' }}
            />
          </Box>
          <Flex width="20%" justifyContent="flex-end">
            <Icon
              color="gray2"
              sizeIcon="display12"
              onClick={() => {
                window.open('https://www.tecemac.com/pavimentos-industriales-2/', '_blank').focus()
              }}
              style={{ cursor: 'pointer' }}>
              <LinkIcon />
            </Icon>
          </Flex>
        </Flex>
        <Flex width="100%" height="48px">
          <Text sizeText="display16" color="gray1">
            Impermeabilizaciones, Pavimentos y revestimientos
          </Text>
        </Flex>
      </Flex>
      <Flex
        position="relative"
        flexDirection="column"
        justifyContent="space-between"
        minWidth="360px"
        width="33%"
        paddingX={5}
        paddingY={6}
        marginRight={5}
        style={{
          backgroundColor: '#fafafa',
          border: '2px solid #D1D7E6',
          borderRadius: '4px',
        }}>
        <Flex width="100%" justifyContent="center" alignItems="center" paddingBottom={4}>
          <Box width="80%">
            <Image
              src={company5}
              alt="company5_logo"
              height={40}
              width={143}
              styleImg={{ objectFit: 'contain' }}
            />
          </Box>
          <Flex width="20%" justifyContent="flex-end">
            <Icon
              color="gray2"
              sizeIcon="display12"
              onClick={() => {
                window.open('https://pavimdur.com/pavimentos/', '_blank').focus()
              }}
              style={{ cursor: 'pointer' }}>
              <LinkIcon />
            </Icon>
          </Flex>
        </Flex>
        <Flex width="100%" height="48px">
          <Text sizeText="display16" color="gray1">
            Pavimentos y revestimientos, Otros materiales
          </Text>
        </Flex>
      </Flex>
      <Flex
        position="relative"
        flexDirection="column"
        minWidth="360px"
        justifyContent="space-between"
        width="33%"
        paddingX={5}
        paddingY={6}
        marginRight={5}
        style={{
          backgroundColor: '#fafafa',
          border: '2px solid #D1D7E6',
          borderRadius: '4px',
        }}>
        <Flex width="100%" justifyContent="center" alignItems="center" paddingBottom={2}>
          <Box width="80%">
            <Image
              src={company6}
              alt="company6_logo"
              height={40}
              width={143}
              styleImg={{ objectFit: 'contain' }}
            />
          </Box>
          <Flex width="20%" justifyContent="flex-end">
            <Icon
              color="gray2"
              sizeIcon="display12"
              onClick={() => {
                window.open('https://www.lafargeholcim.es/cementos', '_blank').focus()
              }}
              style={{ cursor: 'pointer' }}>
              <LinkIcon />
            </Icon>
          </Flex>
        </Flex>
        <Flex width="100%" height="48px">
          <Text sizeText="display16" color="gray1">
            Cimentaciones y sistemas contención, Hormigones y morteros preparados
          </Text>
        </Flex>
      </Flex>
    </Flex>
  </Flex>
)

export default HomeListingsPage
