import { useState } from 'react'
import { copyState, offerStatusCopy } from '../../pages/buyer/config'
// import { copyEmployeeRole } from '../../pages/profile/components/ManageEmployees';

export const useSearchOrderParams = ({
  optionsOrder,
  defaultOption = 0,
  copyEmployeeRole = {},
} = {}) => {
  /** @type {[Filter, Function]} */
  const [filters, setFilters] = useState([])
  /** @type {[Order, Function]} */
  const [order, setOrder] = useState(optionsOrder ? optionsOrder[defaultOption] : null)

  /**
   * Get state name (Translated)
   * @param {string} state
   * @param {string} type
   */
  const getNameState = (state, type) => {
    const stateName = {
      proposal: copyState[state],
      offer: offerStatusCopy[state],
      employeeRole: copyEmployeeRole[state],
    }
    return stateName[type] || state
  }

  /**
   * Transform array states to options
   * @param {string[]} states
   * @param {string} type
   */
  const getOptionStates = (states, type) => {
    // Create states array without duplicate
    const allStates = [...new Set(states)]
    return allStates.map((state) => {
      const count = states.filter((value) => value === state).length
      return {
        label: `${getNameState(state, type)} (${count})`,
        value: { field: 'state', contains: 'in', search: state },
      }
    })
  }

  const resetOrder = () => setOrder(optionsOrder ? optionsOrder[defaultOption] : null)

  const resetfilters = () => setFilters([])

  return {
    optionsOrder,
    filters,
    order,
    setFilters,
    setOrder,
    getOptionStates,
    resetOrder,
    resetfilters,
  }
}
