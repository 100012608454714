import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { DataContactTemplate } from '../template/DataContactTemplate'
import { DataCompanyTemplate } from '../template/DataCompanyTemplate'
import { DataBasicTemplate } from '../template/DataBasicTemplate'

import { Layout2 } from '../../../common/components/Layout/Layout2'
import { useForms } from '../../../common/hooks/forms'
import { useAuth } from '../../../common/hooks/useAuth'

const FinishBuyerPage = () => {
  const history = useHistory()
  const { data, errorRequest, error } = useSelector((state) => state.auth)
  const [dataSaved, setDataSaved] = useState(data)
  const [errorsData, setErrorsData] = useState({ init: true })
  const { step, hash } = useParams()

  const { values, handleChange } = useForms()

  const { setSignUp, setInvitationOld: setInvitation } = useAuth()

  useEffect(() => {
    if (errorRequest && error?.keys?.length) {
      for (let i = 0; i < error?.keys?.length; i += 1) {
        const item = error?.keys[i]
        setErrorsData((prev) => ({
          ...prev,
          [item]: true,
        }))
        return true
      }
    }
    return false
  }, [errorRequest, step])

  const routesGo = {
    'datos-de-contacto': `/finalizar/comprador/datos-de-la-empresa${hash ? `/${hash}` : ''}`,
    'datos-de-la-empresa': '/perfil',
    'datos-de-usuario': `/finalizar/comprador/datos-de-contacto${hash ? `/${hash}` : ''}`,
  }

  useEffect(() => {
    setDataSaved((prev) => ({ ...prev, ...values }))
  }, [values])

  const onSubmit = async () => {
    setDataSaved((prev) => ({ ...prev, ...values }))
    if (routesGo[step] !== '/perfil') {
      history.push(routesGo[step])
    } else {
      if (hash) {
        await setInvitation({ id: hash, body: dataSaved })
      } else {
        await setSignUp({ body: dataSaved })
      }
      history.push('/perfil')
    }
  }

  const onValidate = () => {
    document?.forms[0]?.checkValidity()
    const formValidation = {
      'datos-de-contacto': ['name', 'phone'],
      'datos-de-la-empresa': [
        'companyAddress',
        'companyCity',
        'companyDescription',
        'companyName',
        'companyPhone',
        'companyPostCode',
        'scope',
        'taxCode',
      ],
      'datos-de-usuario': ['email', 'password'],
    }

    if (errorsData.init) {
      setErrorsData({})
    }
    let errors = {}

    formValidation[step].map((key) => {
      if (values[key]?.length || values[key] === true) {
        setErrorsData((prev) => ({
          ...prev,
          [key]: false,
        }))
        errors = {
          ...errors,
          [key]: false,
        }
      } else {
        setErrorsData((prev) => ({
          ...prev,
          [key]: true,
        }))

        errors = {
          ...errors,
          [key]: true,
        }
      }
      return true
    })

    if (!Object.values(errors)?.includes(true)) {
      onSubmit()
    }
    return false
  }

  return (
    <Layout2
      routesGo={routesGo}
      step={step}
      history={history}
      onValidate={() => onValidate()}
      hasError={Object.values(errorsData)?.includes(true)}
      errorsKeys={error?.listMessages}>
      {step === 'datos-de-usuario' && (
        <DataBasicTemplate
          title="Finaliza tu perfil para poder empezar a crear licitaciones"
          data={values}
          setData={handleChange}
          errorsData={errorsData}
          errorsKeys={error?.listMessages}
        />
      )}
      {step === 'datos-de-contacto' && (
        <DataContactTemplate
          title="Finaliza tu perfil para poder empezar a crear licitaciones"
          data={dataSaved}
          setData={handleChange}
          errorsData={errorsData}
          errorsKeys={error?.listMessages}
        />
      )}
      {step === 'datos-de-la-empresa' && (
        <DataCompanyTemplate
          title="Finaliza tu perfil para poder empezar a crear licitaciones"
          data={dataSaved || values}
          setData={handleChange}
          errorsData={errorsData}
          errorsKeys={error?.listMessages}
          values={values}
        />
      )}
    </Layout2>
  )
}

export default FinishBuyerPage

FinishBuyerPage.propTypes = {}

FinishBuyerPage.defaultProps = {}
