import { useEffect, useState } from 'react'
import { Image } from '@proveoeng/uikit/dist/atoms/Image'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Tooltip } from '@proveoeng/uikit/dist/atoms/Tooltip'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import Close from '@proveoeng/uikit/dist/atoms/Icons/Close'

import { downloadFile } from '../../functions/downloadFile'
import { getShortenedFileNameExtension } from '../../functions'
import { useUtils } from '../../hooks/useUtils'

const TooltipWrapper = ({ content, children }) => {
  const [active, setActive] = useState(false)
  return (
    <Tooltip
      active={active}
      setActive={setActive}
      content={content}
      bgColor="gray3"
      textColor="black"
      width="auto">
      {children}
    </Tooltip>
  )
}

const AttachmentImage = ({ file, handleDownload, withDownload }) => {
  const { getThumb } = useUtils()
  const [image, setImage] = useState('')

  useEffect(() => {
    const loadImage = async () => {
      const thumb = await getThumb(file?.fileId)
      setImage(thumb)
    }
    if (file.base64) setImage(file.base64)
    else if (file?.fileId) loadImage()
  }, [])

  return (
    <Image
      key={file?.name}
      src={image}
      alt={file?.name}
      width={80}
      height={80}
      borderRadius={2}
      styleImg={{
        objectFit: 'contain',
        cursor: withDownload ? 'pointer' : 'default',
      }}
      onClick={() => handleDownload(file)}
    />
  )
}

export const AttachmentList = ({
  files = [],
  withDelete,
  withDownload,
  onDeleteFile = () => {},
}) => {
  const handleDownload = (file) => {
    if (withDownload) downloadFile(file)
  }
  return (
    <Flex marginBottom={3} flexWrap="wrap">
      {files.map((file, i) => (
        <TooltipWrapper content={file?.name} key={i}>
          {withDelete && (
            <Box
              position="absolute"
              top={1}
              right={3}
              zIndex={1}
              backgroundColor="gray3"
              borderRadius={6}
              padding={1}
              onClick={() => onDeleteFile(file)}>
              <Icon color="red1" fontWeight="bold" sizeIcon="display16">
                <Close />
              </Icon>
            </Box>
          )}
          {file?.type?.includes('image') ? (
            <Flex position="relative" marginRight={2}>
              <AttachmentImage
                file={file}
                withDownload={withDownload}
                handleDownload={handleDownload}
              />
            </Flex>
          ) : (
            <Flex
              position="relative"
              backgroundColor="gray4"
              width={80}
              height={80}
              borderRadius={2}
              marginRight={2}
              alignItems="flex-end"
              justifyContent="flex-end"
              paddingRight={2}
              color="gray1"
              style={{
                textTransform: 'uppercase',
                cursor: withDownload ? 'pointer' : 'default',
              }}
              onClick={() => handleDownload(file)}>
              .{getShortenedFileNameExtension(file?.name)}
            </Flex>
          )}
        </TooltipWrapper>
      ))}
    </Flex>
  )
}
