/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { requestApi } from '../../../lib/request'
import { getBidPackageProposalSuccess } from '../schemes/bidPackages'
import { services } from '../schemes/bidPackages/config'
import { BidItemMapper, BidPackageMapper } from '../schemes/bidPackages/mapper'
import { services as servicesProposal } from '../schemes/proposals/config'

import { isEmail } from '../functions'

import { ProposalBidPackageMapper } from '../schemes/proposals/mapper'
import { useUtils } from './useUtils'

const removeEmails = (data) => data?.filter((el) => !isEmail(el))
const getEmails = (data) => data?.filter((el) => isEmail(el))

export const useBidPackage = () => {
  const dispatch = useDispatch()
  const { setAlert, setError, setLoading, getSignal } = useUtils()

  const [bidPackage, setBidPackage] = useState({
    proposals: [],
    offers: [],
    proposalLower: {},
    proposalMinimalEnvelope: {},
    files: [],
    links: [],
  })
  const [bidPackageLowest, setBidPackageLowest] = useState()
  const [bidPackageProposals, setBidPackageProposals] = useState()
  const [bidItem, setBidItem] = useState()
  const [bidItems, setBidItems] = useState()
  const [messagesList, setMessagesList] = useState()
  const [eventLogsList, setEventLogsList] = useState()

  // Enviro
  const [bidItemsEnviro, setBidItemsEnviro] = useState({
    data: [],
    meta: {},
  })

  const createBidPackage = async (data) => {
    setLoading(true)
    try {
      const bidPackageId = uuidv4()
      const body = BidPackageMapper.dehydrate(data)
      await requestApi(services.createBidPackage(bidPackageId, body))
      setAlert('success', '¡Felicidades! Nueva compra creada')
      return bidPackageId
    } catch (error) {
      setError(error)
      return false
    } finally {
      setLoading(false)
    }
  }

  /**
   * @param {string} bidPackageId
   */

  const getBidPackage = async (bidPackageId) => {
    if (!bidPackageId) return
    setLoading(true)
    try {
      const {
        data: { data },
      } = await requestApi(services.getBidPackage(bidPackageId))

      setBidPackage(BidPackageMapper.hydrate(data))
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const updateBidPackage = async (bidPackageId, data) => {
    setLoading(true)
    try {
      const body = BidPackageMapper.dehydrate(data)
      await requestApi(services.updateBidPackage(bidPackageId, body))
      setAlert('success', '¡Felicidades! Compra actualizada')
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const actionBidPackage = async (bidPackageId, actionText, data) => {
    let body = {}
    let alert = ''
    if (actionText === 'Copy') {
      body = {
        actionData: {
          bidPackageId: uuidv4(),
        },
      }
      alert = 'Compra clonada con éxito'
    }

    if ((actionText === 'Publish' || actionText === 'Offer') && data) {
      let categoriesSelected = data
      let message = null

      if (!Array.isArray(data)) {
        categoriesSelected = data?.categoriesSelected
        message = data?.message
      }
      const companiesData = removeEmails(categoriesSelected)
      const invitationData = getEmails(categoriesSelected)
      let messageParsed = null

      if (!message || message?.length === 0) {
        messageParsed = null
      } else {
        messageParsed = message
      }

      body = {
        actionData: {
          companies: companiesData,
          invitations: invitationData,
          message: messageParsed,
        },
      }
      alert =
        actionText === 'Publish' ? 'Compra publicada con éxito' : 'Invitaciones enviadas con éxito'
    }

    if (actionText === 'NotifyChanges') {
      const messageParsed = !data?.length ? null : data

      body = {
        actionData: {
          message: messageParsed,
        },
      }

      alert = 'Notificación enviada con éxito'
    }

    if (actionText === 'Cancel') {
      const message = data?.message || null
      body = {
        actionData: {
          message,
          notify: true,
        },
      }
      alert = 'Compra cancelada con éxito'
    }

    setLoading(true)
    try {
      await requestApi(
        services.actionBidPackage(bidPackageId, {
          actionName: actionText,
          ...body,
        }),
      )
      setAlert('success', alert)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const deleteBidPackage = async (bidPackageId) => {
    setLoading(true)
    try {
      await requestApi(services.deleteBidPackage(bidPackageId))
      setAlert('success', 'Compra eliminada correctamente')
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  /**
   *
   * @param {string} bidPackageId
   * @param {array} files
   */

  const setFilesBidPackage = async (bidPackageId, files) => {
    if (!bidPackageId || !files.length) return
    setLoading(true)
    for (const file of files) {
      if (!file.fileId) {
        const fileId = uuidv4()
        try {
          await requestApi(
            services.createAttachmentBidPackage(bidPackageId, fileId, {
              file: file.file,
            }),
          )
        } catch (error) {
          setError(error)
        }
      }
    }
    setLoading(false)
  }

  const deleteFilesBidPackage = async (bidPackageId, files) => {
    if (!bidPackageId || !files.length) return
    setLoading(true)
    for (const file of files) {
      if (file.fileId) {
        try {
          await requestApi(services.deleteAttachmentBidPackage(bidPackageId, file.fileId))
        } catch (error) {
          setError(error)
        }
      }
    }
    await getBidPackage(bidPackageId)
    setLoading(false)
  }

  const setLinksBidPackage = async (bidPackageId, links) => {
    if (!bidPackageId || !links.length) return
    setLoading(true)
    for (const link of links) {
      if (!link.linkId) {
        const linkId = uuidv4()
        try {
          await requestApi(services.createLinkBidPackage(bidPackageId, linkId, link))
        } catch (error) {
          setError(error)
        }
      }
    }
    setLoading(false)
  }

  const deleteLinksBidPackage = async (bidPackageId, links = []) => {
    if (!bidPackageId || !links.length) return
    setLoading(true)
    for (const linkId of links) {
      if (linkId) {
        try {
          await requestApi(services.deleteLinkBidPackage(bidPackageId, linkId))
        } catch (error) {
          setError(error)
        }
      }
    }
    await getBidPackage(bidPackageId)
    setLoading(false)
  }

  // ------- BidItems ------- //

  /**
   * @param {string} bidPackageId
   */

  const getAllBidItems = async (bidPackageId) => {
    if (!bidPackageId) return
    setLoading(true)
    try {
      const { data } = await requestApi(services.getAllItems(bidPackageId))
      const res = data?.data?.map(({ resource }) => BidItemMapper.hydrate(resource))
      setBidItems(res)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  /**
   *
   * @param {string} bidPackageId
   * @param {any} data
   *
   * @returns {string} bidItemId
   */

  const createBidItem = async (bidPackageId, data) => {
    if (!bidPackageId) return
    setLoading(true)
    try {
      const bidItemId = uuidv4()
      const body = BidItemMapper.dehydrate(data)
      await requestApi(services.createBidItem(bidPackageId, bidItemId, body))
      setBidItem({ ...data, bidItemId })

      return bidItemId
    } catch (error) {
      setError(error)
      return
    } finally {
      setLoading(false)
    }
  }

  /**
   *
   * @param {string} bidPackageId
   * @param {array} data
   */

  const createBidItems = async (bidPackageId, data) => {
    if (!bidPackageId) return
    setLoading(true)
    for (const item of data) {
      try {
        const bidItemId = uuidv4()
        const body = BidItemMapper.dehydrate(item)
        await requestApi(services.createBidItem(bidPackageId, bidItemId, body))
        await updateBidItemEnviro(bidPackageId, bidItemId, item.eCo2)
      } catch (error) {
        setError(error)
      }
    }
    setAlert('success', '¡Felicidades! Nuevas partidas creadas')
    setLoading(false)
  }

  const getBidItem = async (bidPackageId, bidItemId) => {
    if (!bidPackageId || !bidItemId) return
    setLoading(true)
    try {
      const {
        data: { data },
      } = await requestApi(services.getBidItem(bidPackageId, bidItemId))
      setBidItem(BidItemMapper.hydrate(data))
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const deleteBidItem = async (bidPackageId, bidItemId) => {
    if (!bidPackageId || !bidItemId) return
    setLoading(true)
    try {
      await requestApi(services.deleteBidItem(bidPackageId, bidItemId))
      setAlert('success', 'Partida eliminada correctamente')
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const updateBidItem = async (bidPackageId, bidItemId, data) => {
    if (!bidPackageId || !bidItemId) return
    setLoading(true)
    try {
      const body = BidItemMapper.dehydrate(data)
      await requestApi(services.updateBidItem(bidPackageId, bidItemId, body))
      await getAllBidItems(bidPackageId)
      await getBidPackage(bidPackageId)
      setAlert('success', 'Partida actualizada correctamente')
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const actionBidItem = async (bidPackageId, bidItemId, actionText) => {
    let body = {}
    setLoading(true)
    if (actionText === 'Copy') {
      body = {
        actionData: {
          bidItemId: uuidv4(),
        },
      }
    }
    try {
      await requestApi(
        services.actionBidItem(bidPackageId, bidItemId, {
          actionName: actionText,
          ...body,
        }),
      )

      await getAllBidItems(bidPackageId)
      await getBidPackage(bidPackageId)
      setAlert('success', 'Actualizado')
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const setFilesBidItem = async (bidPackageId, bidItemId, files) => {
    if (!bidPackageId || !bidItemId || !files.length) return
    setLoading(true)
    for (const file of files) {
      if (!file.fileId) {
        const fileId = uuidv4()
        try {
          await requestApi(
            services.createAttachmentBidItem(bidPackageId, bidItemId, fileId, {
              file: file.file,
            }),
          )
        } catch (error) {
          setError(error)
        }
      }
    }
    setLoading(false)
  }

  const deleteFilesBidItem = async (bidPackageId, bidItemId, files) => {
    if (!files.length) return
    setLoading(true)
    for (const file of files) {
      if (file.fileId) {
        try {
          await requestApi(services.deleteAttachmentBidItem(bidPackageId, bidItemId, file.fileId))
        } catch (error) {
          setError(error)
        }
      }
    }
    setLoading(false)
  }

  const setLinksBidItem = async (bidPackageId, bidItemId, links) => {
    if (!bidPackageId || !bidItemId || !links.length) return
    setLoading(true)
    for (const link of links) {
      if (!link.linkId) {
        const linkId = uuidv4()
        try {
          await requestApi(services.createLinkBidItem(bidPackageId, bidItemId, linkId, link))
        } catch (error) {
          setError(error)
        }
      }
    }
    setLoading(false)
  }

  const deleteLinksBidItem = async (bidPackageId, bidItemId, links) => {
    if (!links.length) return
    setLoading(true)
    for (const linkId of links) {
      if (linkId) {
        try {
          await requestApi(services.deleteLinkBidItem(bidPackageId, bidItemId, linkId))
        } catch (error) {
          setError(error)
        }
      }
    }
    setLoading(false)
  }

  const getBidPackageLowest = async (bidPackageId) => {
    if (!bidPackageId) return
    setLoading(true)
    try {
      const {
        data: { data },
      } = await requestApi(services.getBidPackageLowest(bidPackageId))
      setBidPackageLowest(ProposalBidPackageMapper.hydrate(data))
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const getBidPackageProposals = async (bidPackageId) => {
    if (!bidPackageId) return
    setLoading(true)
    try {
      const {
        data: { data, meta },
      } = await requestApi(services.getBidPackageProposals(bidPackageId))
      setBidPackageProposals({
        data: data?.map((el) => ProposalBidPackageMapper.hydrate(el?.resource)),
        meta,
      })
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const getBidPackageProposal = async (bidPackageId, proposalId) => {
    if (!bidPackageId || !proposalId) return
    setLoading(true)
    try {
      const {
        data: { data },
      } = await requestApi(services.getBidPackageProposal(bidPackageId, proposalId))
      dispatch(getBidPackageProposalSuccess(ProposalBidPackageMapper.hydrate(data)))
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const sendMessage = async (proposalId, body = {}) => {
    if (!proposalId) return
    setLoading(true)
    try {
      const messageId = uuidv4()
      await requestApi(servicesProposal.sendMessage(proposalId, messageId, body))
      setAlert('success', 'Has enviado un mensaje al proveedor.')
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const getMessagesList = async (proposalId) => {
    setLoading(true)
    try {
      const {
        data: { data },
      } = await requestApi(servicesProposal.getMessagesList(proposalId), {
        signal: getSignal(),
      })

      setMessagesList(data)
      setLoading(false)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const getEventLogsList = async (bidPackageId, params = {}) => {
    setLoading(true)
    try {
      const {
        data: { data },
      } = await requestApi(services.getBidPackageEvents(bidPackageId, params), {
        signal: getSignal(),
      })

      setEventLogsList(data)
      setLoading(false)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const getBidItemsEnviro = async (bidPackageId) => {
    if (!bidPackageId) return
    setLoading(true)
    try {
      const {
        data: { data, meta },
      } = await requestApi(services.getBidItemsEnviro(bidPackageId))
      setBidItemsEnviro({
        data: data.map((el) => el.resource),
        meta,
      })
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const updateBidItemEnviro = async (bidPackageId, bidItemId, eCo2) => {
    if (!bidPackageId || !bidItemId || eCo2 === undefined) return
    const body = {
      eCo2,
    }
    try {
      await requestApi(services.updateBidItemEnviro(bidPackageId, bidItemId, body))
    } catch (error) {
      setError(error)
    }
  }

  return {
    data: {
      bidPackage,
      bidPackageLowest,
      bidPackageProposals,
      bidItems,
      bidItem,
    },
    createBidPackage,
    getBidPackage,
    updateBidPackage,
    actionBidPackage,
    deleteBidPackage,
    setFilesBidPackage,
    deleteFilesBidPackage,
    setLinksBidPackage,
    deleteLinksBidPackage,
    getBidPackageLowest,
    sendMessage,
    getMessagesList,
    messagesList,
    getEventLogsList,
    eventLogsList,
    bidItems: {
      createBidItem,
      getBidItem,
      getAllBidItems,
      updateBidItem,
      deleteBidItem,
      actionBidItem,
      setFilesBidItem,
      deleteFilesBidItem,
      setLinksBidItem,
      deleteLinksBidItem,
      createBidItems,
    },
    proposals: {
      getBidPackageProposals,
      getBidPackageProposal,
    },
    enviro: {
      data: {
        bidItemsEnviro,
      },
      getBidItemsEnviro,
      updateBidItemEnviro,
    },
  }
}
