import { getParamsSerializer } from '../../../../lib/request'

export const constants = {
  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
  GET_SELLER_CATEGORIES: 'GET_SELLER_CATEGORIES',
  GET_SELLER_CATEGORIES_SUCCESS: 'GET_SELLER_CATEGORIES_SUCCESS',
  GET_FILE: 'GET_FILE',
  GET_FILE_SUCCESS: 'GET_FILE_SUCCESS',
  HELPERS_FAIL: 'HELPERS_FAIL',
}

export const services = {
  getCategories: () => ({
    route: `/categories`,
    method: 'get',
  }),
  getSellerCategories: (categoryId, params = {}) => ({
    route: `/categories/${categoryId}/sellers`,
    method: 'get',
    params,
    paramsSerializer: getParamsSerializer,
  }),
  getSellers: (params = {}) => ({
    route: `/sellers/find`,
    method: 'get',
    params,
    paramsSerializer: getParamsSerializer,
  }),
  getFile: (fileId) => ({
    route: `/files/${fileId}`,
    method: 'get',
    responseType: 'arraybuffer',
  }),
  getThumb: (fileId) => ({
    route: `/files/${fileId}?thumb`,
    method: 'get',
    responseType: 'arraybuffer',
  }),
  getUnits: () => ({
    route: `/units`,
    method: 'get',
  }),
  getSellerByCompany: (companyId) => ({
    route: `/sellers/company/${companyId}/public-profile`,
    method: 'get',
  }),
}
