import { constants } from '../config'

const initialState = {
  show: 0,
  percent: null,
}

export default function setLoadingReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SHOW_LOADING: {
      const nextShowValue = state.show + action.value
      return {
        ...state,
        show: nextShowValue > 0 ? nextShowValue : false,
        percent: action.percent,
      }
    }
    default:
      return state
  }
}
