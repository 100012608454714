import { useState } from 'react'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { ButtonTabs } from '@proveoeng/uikit/dist/atoms/Button'
import { DetailProposalInfo } from './DetailProposalInfo'
import { DetailProposalConditions } from './DetailProposalConditions'
import { DetailProposalBidItems } from './DetailProposalBidItems'

export const DetailProposal = ({ bidPackage, proposal, handleExportBidPackagePrices }) => {
  const [whoIsActive, setWhoIsActive] = useState('info')

  return (
    <Flex
      flexDirection="column"
      alignItems="baseline"
      paddingX={0}
      margin="0 auto"
      width="100%"
      height="100%">
      <ButtonTabs
        items={[
          {
            id: 'info',
            children: <>Información de la obra</>,
            isActive: whoIsActive === 'info',
            action: (e, target) => setWhoIsActive(target.id),
          },
          {
            id: 'conditions',
            children: <>Condiciones de la oferta</>,
            isActive: whoIsActive === 'conditions',
            action: (e, target) => setWhoIsActive(target.id),
          },
          {
            id: 'bidItems',
            children: <>Partidas</>,
            isActive: whoIsActive === 'bidItems',
            action: (e, target) => setWhoIsActive(target.id),
          },
        ]}
      />
      <Box paddingY={5} width="100%">
        {whoIsActive === 'info' && <DetailProposalInfo proposal={proposal} />}
        {whoIsActive === 'conditions' && (
          <DetailProposalConditions bidPackage={bidPackage} proposal={proposal} />
        )}
        {whoIsActive === 'bidItems' && (
          <DetailProposalBidItems
            proposal={proposal}
            handleExportBidPackagePrices={handleExportBidPackagePrices}
          />
        )}
      </Box>
    </Flex>
  )
}
