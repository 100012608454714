import { Avatar } from '@proveoeng/uikit/dist/atoms/Avatar'
import { Box, Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

export const OffersTemplate = ({ history, offers, pendingMeta, imagespending }) => (
  <Flex height="100%" width="100%" paddingY={5} flexDirection="column">
    <>
      <Flex width="100%" alignItems="center" justifyContent="space-between">
        <Text sizeText="display30" color="black" marginBottom={5} fontWeight="bold" maxWidth={856}>
          Nuevas oportunidades
        </Text>
        {/* TODO: Hacer bien y pintar bien la paginación cuando se ajusten las peticiones de aquí */}
        <Text sizeText="display14" color="black" marginBottom={5}>
          <Text as="span" sizeText="display14" color="black" marginBottom={5} fontWeight="medium">
            {pendingMeta?.page * pendingMeta?.total}
          </Text>{' '}
          de {pendingMeta?.total} resultados
        </Text>
      </Flex>
      <Box width="100%">
        <Grid
          gridTemplateAreas={"'thumbnail name finish buyer paymentConditions categories'"}
          gridTemplateColumns="7% 25% 16% 16% 16% 20%"
          bg="gray4"
          paddingY={2}
          borderRadius={2}>
          <GridItem gridArea="name">
            <Text
              sizeText="display14"
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              paddingRight={15}>
              Nombre
            </Text>
          </GridItem>
          <GridItem gridArea="finish">
            <Text
              sizeText="display14"
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              paddingRight={15}>
              Fecha límite recepción de ofertas
            </Text>
          </GridItem>
          <GridItem gridArea="buyer">
            <Text
              sizeText="display14"
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              paddingRight={15}>
              Contratista
            </Text>
          </GridItem>
          <GridItem gridArea="paymentConditions">
            <Text
              sizeText="display14"
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              paddingRight={15}>
              Días de pago
            </Text>
          </GridItem>
          <GridItem gridArea="categories">
            <Text
              sizeText="display14"
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              paddingRight={15}>
              Categorias
            </Text>
          </GridItem>
        </Grid>

        {offers?.length
          ? offers?.map((offer) => (
              <Grid
                key={offer.bidPackageId}
                gridTemplateAreas={"'thumbnail name finish buyer paymentConditions categories'"}
                gridTemplateColumns="7% 25% 16% 16% 16% 20%"
                paddingY={4}
                borderBottomColor="gray4"
                borderBottomStyle="solid"
                borderBottomWidth="1px">
                <GridItem
                  gridArea="thumbnail"
                  display="flex"
                  style={{
                    alignItems: 'center',
                    cursor: 'pointer',
                    justifyContent: 'center',
                  }}
                  onClick={() => {
                    history.push(`/proveedor/compras-nuevas/${offer.bidPackageId}/${offer.offerId}`)
                  }}>
                  <Avatar
                    src={imagespending && imagespending[offer?.projectImage]}
                    borderRadius={3}
                    placeholder={offer.projectName}
                  />
                </GridItem>
                <GridItem
                  gridArea="name"
                  display="flex"
                  style={{ alignItems: ' center', cursor: 'pointer' }}
                  onClick={() => {
                    history.push(`/proveedor/compras-nuevas/${offer.bidPackageId}/${offer.offerId}`)
                  }}>
                  <Box>
                    <Text
                      sizeText="display14"
                      color="gray1"
                      overflow="hidden"
                      fontWeight="medium"
                      paddingRight={15}>
                      {offer.projectName}
                    </Text>
                  </Box>
                </GridItem>
                <GridItem gridArea="finish" display="flex" style={{ alignItems: ' center' }}>
                  <Text sizeText="display14" color="gray1" paddingRight={15}>
                    {offer.bidEndsAt?.readableStringDate}
                  </Text>
                </GridItem>
                <GridItem gridArea="buyer" display="flex" style={{ alignItems: ' center' }}>
                  <Text sizeText="display14" color="gray1" paddingRight={15}>
                    {offer.buyerName}
                  </Text>
                </GridItem>
                <GridItem
                  gridArea="paymentConditions"
                  display="flex"
                  style={{ alignItems: ' center' }}
                  paddingRight={15}>
                  <Text sizeText="display14" color="gray1" paddingRight={15}>
                    {offer.paymentDays || '-'}
                  </Text>
                </GridItem>
                <GridItem
                  gridArea="categories"
                  display="flex"
                  style={{ alignItems: ' center' }}
                  paddingRight={15}>
                  <Text as="span" sizeText="display14" color="gray1">
                    {offer?.categoryName}
                  </Text>
                </GridItem>
              </Grid>
            ))
          : ''}
      </Box>
    </>
  </Flex>
)
