import { Button } from '@proveoeng/uikit/dist/atoms/Button/Button'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Box } from '@proveoeng/uikit/dist/atoms/Layout/Layout'
import { PreviewBidPackage } from '../components/PreviewBidPackage'
import { AcceptOfferModal } from '../components/AcceptOfferModal'
import { ModalContact } from '../../common/components/ModalContact'

export const DetailOfferTemplate = ({
  project,
  goBack,
  onActionSetOffer,
  haveActions,
  proposal,
  acceptOfferModalOpened,
  setAcceptOfferModalOpened,
  isModalContactOpened,
  setIsModalContactOpened,
  contactData,
}) => (
  <Flex height="100%" width="100%" paddingY={4} flexDirection="column" margin="0 auto">
    <Text as="span" sizeText="display14" color="gray2">
      <Text
        as="span"
        sizeText="display14"
        color="blue1"
        marginBottom={7}
        style={{ cursor: 'pointer' }}
        onClick={() => goBack()}>
        {'< '} Volver
      </Text>{' '}
      | {haveActions ? 'Nuevas oportunidades' : 'Tus compras'} /
      <Text as="span" sizeText="display14" color="black">
        {proposal?.categoryName}
      </Text>
    </Text>
    <Flex justifyContent="space-between" width="100%" alignItems="center">
      <Text
        as="h3"
        sizeText="display30"
        color="black"
        fontWeight="bold"
        paddingTop={7}
        paddingBottom="18px">
        {proposal?.categoryName}
      </Text>
      {haveActions && (
        <Flex
          alignItems="flex-end"
          justifyContent="flex-end"
          width="100%"
          flex={1}
          flexDirection="row">
          <Box>
            <Button
              width="184px"
              colorType="transparent"
              marginRight={5}
              fullWidth
              action={() => onActionSetOffer('Reject')}>
              No me interesa
            </Button>
          </Box>
          <Box>
            <Button
              width="184px"
              colorType="orange"
              fullWidth
              action={() => setAcceptOfferModalOpened(true)}>
              Me interesa
            </Button>
          </Box>
        </Flex>
      )}
    </Flex>
    {proposal?.buyerName && (
      <>
        <Flex justifyContent="space-between">
          <Flex flexDirection="column">
            <Flex>
              <Text
                fontWeight="regular"
                color="gray"
                sizeText="display16"
                paddingBottom={2}
                paddingRight={2}>
                Contratista:{' '}
                <Text as="span" fontWeight="medium" color="black" sizeText="display16">
                  {proposal?.buyerName}
                </Text>
              </Text>
              <Text
                as="span"
                fontWeight="medium"
                color="blue1"
                sizeText="display16"
                style={{ cursor: 'pointer' }}
                onClick={() => setIsModalContactOpened(true)}>
                Ver datos de contacto
              </Text>
            </Flex>
            <Text fontWeight="regular" color="gray" sizeText="display16" paddingBottom={5}>
              Obra:{' '}
              <Text as="span" fontWeight="medium" color="black" sizeText="display16">
                {proposal?.projectName}
              </Text>
            </Text>
          </Flex>
          <Flex flexDirection="column">
            <Text fontWeight="regular" color="gray" sizeText="display16" paddingBottom={2}>
              Fecha límite para presentar oferta:
            </Text>
            <Text
              as="span"
              fontWeight="medium"
              color="black"
              sizeText="display18"
              textAlign="right">
              {proposal?.bidEndsAt?.readableReverseDate}
            </Text>
          </Flex>
        </Flex>
      </>
    )}

    <PreviewBidPackage data={project} />

    <AcceptOfferModal
      acceptOfferModalOpened={acceptOfferModalOpened}
      setAcceptOfferModalOpened={setAcceptOfferModalOpened}
      onActionSetOffer={onActionSetOffer}
    />
    <ModalContact
      isModalContactOpened={isModalContactOpened}
      setIsModalContactOpened={setIsModalContactOpened}
      data={contactData}
    />
  </Flex>
)
