import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { AddSectionModal } from '../components/AddSectionModal'
import { ComparatorTotal } from '../components/ComparatorTotal'
import { EditSectionModal } from '../components/EditSectionModal'
import { ExportModal } from '../components/ExportModal'
import { RemoveSectionModal } from '../components/RemoveSectionModal'
import { SaveComparatorModal } from '../components/SaveComparatorModal'
import { Section } from '../components/Section'
import { Summary } from '../components/Summary'

export const ComparatorPricesTemplate = ({
  setAddSectionModalIsOpened,
  comparative,
  bidPackageId,
  setTableWidthCSS,
  updateImprovementRate,
  addItem,
  setItem,
  setOfferItem,
  setEditSectionModalIsOpened,
  setRemoveSectionModalIsOpened,
  removeItems,
  offerColumns,
  onSubmitSaveCompative,
  saveComparatorModalIsOpened,
  setSaveComparatorModalIsOpened,
  addSection,
  addSectionModalIsOpened,
  editSectionModalIsOpened,
  handleEditSection,
  handleRemoveSection,
  closeRemoveSection,
  removeSectionModalIsOpened,
  comparativeData,
  exportModalIsOpened,
  setExportModalIsOpened,
  saveAndExport,
}) => (
  <>
    <Flex alignSelf="flex-start" position="sticky" left="0px" paddingBottom={6}>
      <Button
        width="184px"
        colorType="transparent"
        onClick={() => setAddSectionModalIsOpened(true)}>
        Añadir sección
      </Button>
    </Flex>

    <Flex flexDirection="column" style={{ overflow: 'auto' }} marginRight={2}>
      <Summary
        comparative={comparative}
        bidPackageId={bidPackageId}
        tableWidthCSS={setTableWidthCSS(1170)}
        updateImprovementRate={updateImprovementRate}
      />
      {comparative?.sections.map((section) => (
        <Section
          addItem={addItem}
          setItem={setItem}
          setOfferItem={setOfferItem}
          data={section}
          offers={comparative.offers}
          defaultSection={comparative.defaultSection}
          setEditSectionModalIsOpened={setEditSectionModalIsOpened}
          setRemoveSectionModalIsOpened={setRemoveSectionModalIsOpened}
          removeItems={removeItems}
          tableWidthCSS={setTableWidthCSS(1170)}
        />
      ))}
      <ComparatorTotal
        comparative={comparative}
        offerColumns={offerColumns}
        width={setTableWidthCSS(1170)}
      />
    </Flex>
    <SaveComparatorModal
      handleSaveComparator={onSubmitSaveCompative}
      isOpen={saveComparatorModalIsOpened}
      onClose={() => {
        setSaveComparatorModalIsOpened(false)
      }}
    />
    <AddSectionModal
      handleAddSection={addSection}
      isOpen={addSectionModalIsOpened}
      onClose={() => {
        setAddSectionModalIsOpened(false)
      }}
    />
    <EditSectionModal
      name={editSectionModalIsOpened.name}
      handleEditSection={handleEditSection}
      isOpen={editSectionModalIsOpened.show}
      onClose={() => {
        setEditSectionModalIsOpened({ show: false, id: '', name: '' })
      }}
    />
    <RemoveSectionModal
      removeSection={handleRemoveSection}
      onClose={closeRemoveSection}
      isOpen={removeSectionModalIsOpened.show}
    />
    <ExportModal
      offers={comparativeData?.offers}
      isOpen={exportModalIsOpened}
      onClose={() => setExportModalIsOpened(false)}
      exportIntegration={saveAndExport}
    />
  </>
)
