import { parseToInteger, parseToString } from '../../../../../lib/utils/Format'
import { FormatDate } from '../../../mappers/FormatDate'

const numberOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

export const BidItemMapper = {
  hydrate: (data, index) => ({
    ...data,
    position: index,
    bidItemId: data?.bidItemId,
    budget: (Number(data?.budget) / 100).toLocaleString('de-DE', numberOptions) || 0,
    comments: data?.comments || null,
    description: data?.description || null,
    budgetOverTotal:
      (Number(data?.budgetOverTotal) / 100).toLocaleString('de-DE', numberOptions) || null,
    expectedCosts: (Number(data?.expectedCosts) / 100).toLocaleString('de-DE', numberOptions) || 0,
    measurement: parseToString(data?.measurement || 0, 3),
    name: data?.name || null,
    reference: data?.reference || null,
    targetCosts: (Number(data?.targetCosts) / 100).toLocaleString('de-DE', numberOptions) || 0,
    unit: data?.unit || null,
    state: data?.state || null,
    budgetTotal: (Number(data?.budgetTotal) / 100).toLocaleString('de-DE', numberOptions) || 0,
    expectedCostsTotal:
      (Number(data?.expectedCostsTotal) / 100).toLocaleString('de-DE', numberOptions) || 0,
    targetCostsTotal:
      (Number(data?.targetCostsTotal) / 100).toLocaleString('de-DE', numberOptions) || 0,
  }),
  dehydrate: (data) => {
    const id = data?.bidPackageId ? { bidPackageId: data?.bidPackageId } : {}
    return {
      ...id,
      budget: parseToInteger(data?.budget) || 0,
      comments: data?.comments,
      description: data?.description,
      expectedCosts: parseToInteger(data?.expectedCosts) || 0,
      measurement: parseToInteger(data?.measurement || 0, 3) || 0,
      name: data?.name,
      reference: data?.reference || null,
      targetCosts: parseToInteger(data?.targetCosts) || 0,
      unit: data?.unit,
    }
  },
}

export const BidPackageMapper = {
  hydrate: (data) => ({
    ...data,
    bidPackageId: data?.bidPackageId,
    bidEndsAt: data?.bidEndsAt,
    bidEndsAtFormatted: FormatDate.hydrate(data?.bidEndsAt),
    bidStartsAt: data?.bidStartsAt,
    bidStartsAtFormatted: FormatDate.hydrate(data?.bidStartsAt),
    start: FormatDate.hydrate(data?.deliveryDates?.start) || null,
    end: FormatDate.hydrate(data?.deliveryDates?.end) || null,
    categoryId: data?.categoryId,
    description: data?.description,
    employeeId: data?.employeeId,
    projectId: data?.projectId,
    name: data?.name,
    budgetOverTotal:
      (Number(data?.budgetOverTotal) / 100).toLocaleString('de-DE', numberOptions) || null,
    generalConditions: data?.generalConditions,
    paymentDays: Number(data?.paymentDays),
    paymentConditions: data?.paymentConditions,
    purchasePlanEndsAt: data?.purchasePlanEndsAt,
    purchasePlanEndsAtFormatted: FormatDate.hydrate(data?.purchasePlanEndsAt),
    retention: (Number(data?.retention) / 100).toLocaleString('de-DE', numberOptions) || null,
    retentionCriteria: data?.retentionCriteria,
    state: data?.state,
    expectedCostsDiff:
      (Number(data?.expectedCostsDiff) / 100).toLocaleString('de-DE', numberOptions) || 0,
    targetCostsDiff:
      (Number(data?.targetCostsDiff) / 100).toLocaleString('de-DE', numberOptions) || 0,
    targetCosts: (Number(data?.targetCosts) / 100).toLocaleString('de-DE', numberOptions) || 0,
    expectedCosts: (Number(data?.expectedCosts) / 100).toLocaleString('de-DE', numberOptions) || 0,
    budget: (Number(data?.budget) / 100).toLocaleString('de-DE', numberOptions) || 0,
    bidItems: data?.bidItems?.map((el) => BidItemMapper.hydrate(el)) || [],
    expectedCostsK:
      (Number(data?.expectedCostsK) / 100).toLocaleString('de-DE', numberOptions) || 0,
    targetCostsK: (Number(data?.targetCostsK) / 100).toLocaleString('de-DE', numberOptions) || 0,
  }),
  dehydrate: (data) => ({
    bidEndsAt: data?.bidEndsAt || null,
    categoryId: data?.categoryId || null,
    description: data?.description || null,
    employeeId: data?.employeeId,
    projectId: data?.projectId,
    name: data?.name,
    generalConditions: data?.generalConditions || null,
    paymentDays: Number(data?.paymentDays) || null,
    paymentConditions: data?.paymentConditions || null,
    purchasePlanEndsAt: data?.purchasePlanEndsAt || null,
    retention: parseToInteger(data?.retention) || 0,
    retentionCriteria: data?.retentionCriteria || null,
    deliveryDates: {
      start: data?.start || null,
      end: data?.end || null,
    },
  }),
}
