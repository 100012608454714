import { v4 as uuidv4 } from 'uuid'
import { constants } from '../config'
import {
  comparativeMapper,
  comparativeOfferItemMapper,
  comparativeSectionItemMapper,
  comparativeSectionMapper,
} from '../mapper'

export const initialStateComparative = {
  id: '',
  kZero: 0,
  improvementRate: 0,
  comments: null,
  title: '',
  bidPackageId: '',
  project: '',
  legalConditions: null,
  paymentConditions: '',
  paymentDays: null,
  retention: 0,
  retentionCriteria: '',
  sections: [],
  defaultSection: '',
  offers: [],
}

export const comparativeReduce = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case constants.CREATE:
      return {
        ...state,
        ...comparativeMapper.hydrate(payload),
      }
    case constants.ADD_SECTION: {
      const newSection = comparativeSectionMapper.hydrate({
        ...payload,
        position: state.sections.length,
        id: uuidv4(),
        items: [],
      })

      return {
        ...state,
        sections: [...state.sections, newSection],
      }
    }

    case constants.EDIT_SECTION: {
      const { id, title } = payload
      return {
        ...state,
        sections: state.sections.map((section) => {
          if (section.id === id) {
            return {
              ...section,
              title,
            }
          }
          return section
        }),
      }
    }

    case constants.ADD_ITEM: {
      const {
        sectionId,
        item: { description, measure, unit },
      } = payload
      const itemId = uuidv4()
      const newItem = comparativeSectionItemMapper.hydrate({
        id: itemId,
        description,
        measure,
        unit,
      })
      const newOfferItem = comparativeOfferItemMapper.hydrate({
        id: uuidv4(),
        item: itemId,
        price: 0,
      })
      return {
        ...state,
        sections: state.sections.map((section) => {
          if (section.id === sectionId) {
            return {
              ...section,
              items: [...section.items, newItem],
            }
          }
          return section
        }),
        offers: state.offers.map((offer) => ({
          ...offer,
          items: [...offer.items, newOfferItem],
        })),
      }
    }

    case constants.UPDATE_ITEM: {
      const newItems = (items) => items.map((item) => (item.id === payload.id ? payload : item))
      const newSections = (sections) =>
        sections.map((section) => ({ ...section, items: newItems(section.items) }))
      return {
        ...state,
        sections: newSections(state.sections),
      }
    }

    case constants.UPDATE_OFFER_ITEM: {
      const { offerId, itemId, price } = payload

      /**
       * If the offer id matches the offer id we're looking for, then map over the items and if the
       * item id matches the item id we're looking for, then return a new object with the price updated
       */
      const newOffers = state.offers.map((offer) => {
        if (offer.id === offerId) {
          return {
            ...offer,
            items: offer.items.map((offerItem) =>
              offerItem.item === itemId ? { ...offerItem, price } : offerItem,
            ),
          }
        }
        return offer
      })
      return {
        ...state,
        offers: newOffers,
      }
    }

    case constants.REMOVE_SECTION: {
      const { sectionId } = payload

      // Delete offer items that are associated with the section
      const itemIds = state.sections
        .find((section) => section.id === sectionId)
        .items.map((item) => item.id)

      const newOffers = state.offers.map((offer) => ({
        ...offer,
        items: offer.items.filter((offerItem) => !itemIds.includes(offerItem.item)),
      }))

      return {
        ...state,
        sections: state.sections.filter((section) => section.id !== sectionId),
        offers: newOffers,
      }
    }

    case constants.REMOVE_ITEMS: {
      const { itemIds } = payload
      // Delete offer items that are associated with the section
      const newOffers = state.offers.map((offer) => ({
        ...offer,
        items: offer.items.filter((offerItem) => !itemIds.includes(offerItem.item)),
      }))

      const newSections = state.sections.map((section) => ({
        ...section,
        items: section.items.filter((item) => !itemIds.includes(item.id)),
      }))

      return {
        ...state,
        sections: newSections,
        offers: newOffers,
      }
    }

    case constants.UPDATE_IMPROVEMENT_RATE: {
      return {
        ...state,
        improvementRate: payload || 0,
      }
    }

    case constants.EXPORT: {
      return {
        ...state,
        electedSeller: payload.electedSeller,
        electionReason: payload.electionReason,
      }
    }

    default:
      return state
  }
}
