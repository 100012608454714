import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { ProposalsTemplate } from '../template/ProposalsTemplate'

import { useBidPackage } from '../../../../../common/hooks/useBidPackage'
import { useComparative } from '../../../../../common/hooks/useComparative'
import { useOffer } from '../../../../../common/hooks/useOffer'
import { useProposal } from '../../../../../common/hooks/useProposal'
import { useUtils } from '../../../../../common/hooks/useUtils'

const ProposalsPage = ({ bidPackage }) => {
  const history = useHistory()
  const { hash: projectId, bidPackageId } = useParams()

  const {
    data: { bidPackageProposals },
    proposals: { getBidPackageProposals },
    getBidPackage,
  } = useBidPackage()

  const { rejectProposal, awardProposal, resetProposal } = useProposal()

  const {
    data: { comparativesList },
    getComparativesList,
  } = useComparative()

  const { setLoading } = useUtils()

  const {
    data: { bidPackageAllOffers: offers },
    getBidPackageAllOffers,
  } = useOffer()

  useEffect(() => {
    if (bidPackageId) {
      getBidPackageProposals(bidPackageId)
      getBidPackageAllOffers(bidPackageId)
      getComparativesList(bidPackageId)
    }
  }, [bidPackageId])

  const onSelectedBidPackage = async (id) => {
    setLoading(true)
    await getBidPackage(id)
    await getBidPackageProposals(id)
    await getBidPackageAllOffers(id)
    await getComparativesList(id)
    setLoading(false)
  }

  const awardProposalBidPackage = (actionData) => awardProposal(bidPackageId, actionData)

  const goToProposal = (proposalId) =>
    history.push(
      `/constructora/ofertas/${projectId}/${bidPackage?.bidPackageId}/oferta/${proposalId}`,
    )

  const goToComparative = (itemsChecked) =>
    history.push({
      pathname: `/constructora/tus-obras/${projectId}/${bidPackage?.bidPackageId}/comparativo`,
      state: {
        proposalIds: itemsChecked,
      },
    })

  return (
    <ProposalsTemplate
      bidPackage={bidPackage}
      onSelectedBidPackage={onSelectedBidPackage}
      onCancelProposal={rejectProposal}
      onAwardProposal={awardProposalBidPackage}
      resetProposal={resetProposal}
      proposals={bidPackageProposals}
      sentOffers={offers?.length}
      comparativesList={comparativesList}
      goToProposal={goToProposal}
      goToComparative={goToComparative}
    />
  )
}

export default ProposalsPage
