import { parseToInteger } from '../../../../lib/utils/Format'

export const constants = {
  GET_COMPANY: 'GET_COMPANY',
  GET_COMPANY_SUCCESS: 'GET_COMPANY_SUCCESS',
  SET_COMPANY_GENERAL: 'SET_COMPANY_GENERAL',
  SET_COMPANY_GENERAL_SUCCESS: 'SET_COMPANY_GENERAL_SUCCESS',
  SET_COMPANY_BILLING: 'SET_COMPANY_BILLING',
  SET_COMPANY_BILLING_SUCCESS: 'SET_COMPANY_BILLING_SUCCESS',
  SET_COMPANY_ACTIVITY: 'SET_COMPANY_ACTIVITY',
  SET_COMPANY_ACTIVITY_SUCCESS: 'SET_COMPANY_ACTIVITY_SUCCESS',
  SET_COMPANY_CATEGORIES: 'SET_COMPANY_CATEGORIES',
  SET_COMPANY_CATEGORIES_SUCCESS: 'SET_COMPANY_CATEGORIES_SUCCESS',
  SET_COMPANY_LOGO: 'SET_COMPANY_LOGO',
  SET_COMPANY_LOGO_SUCCESS: 'SET_COMPANY_LOGO_SUCCESS',
  SET_STORE_COMPANY: 'SET_STORE_COMPANY',
  SET_COMPANY_TENDERING: 'SET_COMPANY_TENDERING',
  COMPANY_FAIL: 'COMPANY_FAIL',
}

export const services = {
  getCompany: (companyId) => ({
    route: `/companies/${companyId}/profile`,
    method: 'get',
  }),
  postCompanyGeneralData: (companyId, body) => ({
    route: `/companies/${companyId}/profile/general-data`,
    method: 'post',
    body,
  }),
  postCompanyBillingData: (companyId, body) => ({
    route: `/companies/${companyId}/profile/billing-data`,
    method: 'post',
    body,
  }),
  postCompanyActivityData: (companyId, body) => ({
    route: `/companies/${companyId}/profile/activity-data`,
    method: 'post',
    body,
  }),
  postCompanyCategoriesData: (companyId, body) => ({
    route: `/companies/${companyId}/profile/categories`,
    method: 'post',
    body,
  }),
  postCompanyLogo: (companyId, body) => ({
    route: `/companies/${companyId}/profile/logo`,
    method: 'post',
    headers: {
      accept: '*/*',
    },
    formData: true,
    body,
  }),
  postCompanyTendering: (companyId, rate) => ({
    route: `/companies/${companyId}/profile/tendering-data`,
    method: 'post',
    body: {
      tenderingData: {
        financialCostsRatio: {
          rate: parseToInteger(rate),
          period: 30,
        },
      },
    },
  }),
  getEmployees: (companyId) => ({
    route: `/companies/${companyId}/employees`,
    method: 'get',
  }),
  createEmployee: (companyId, employeeId, body) => ({
    route: `/companies/${companyId}/employees/${employeeId}`,
    method: 'put',
    body,
  }),
  deleteEmployee: (companyId, employeeId) => ({
    route: `/companies/${companyId}/employees/${employeeId}`,
    method: 'delete',
  }),
  actionEmployee: (companyId, employeeId, body) => ({
    route: `/companies/${companyId}/employees/${employeeId}/action`,
    method: 'post',
    body,
  }),
  stats: (companyId) => ({
    route: `/companies/${companyId}/stats`,
    method: 'get',
  }),
}
