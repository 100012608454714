import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { AttachmentList } from '../../../../common/components/attachmentList/Attachment'
import { Links } from '../../../../common/components/links/Links'

export const DetailProposalInfo = ({ proposal }) => (
  <>
    <Flex paddingBottom="20px">
      <Flex minWidth="264px" paddingLeft={2}>
        <Text as="span" sizeText="display18" color="black" fontWeight="600">
          Dirección
        </Text>
      </Flex>
      <Flex paddingLeft={4}>
        <Text as="span" sizeText="display14" color="black">
          {`${proposal?.project?.address}, ${proposal?.project?.city}, ${proposal?.project?.province}, ${proposal?.project?.postCode}`}
        </Text>
      </Flex>
    </Flex>
    <Flex paddingBottom="20px">
      <Flex minWidth="264px" paddingLeft={2}>
        <Text as="span" sizeText="display18" color="black" fontWeight="600">
          Tipo de cliente
        </Text>
      </Flex>
      <Flex paddingLeft={4}>
        <Text as="span" sizeText="display14" color="black">
          {proposal?.project?.constructionTypeFormatted}
        </Text>
      </Flex>
    </Flex>
    <Flex paddingBottom="20px">
      <Flex minWidth="264px" paddingLeft={2}>
        <Text as="span" sizeText="display18" color="black" fontWeight="600">
          Tipo de proyecto
        </Text>
      </Flex>
      <Flex paddingLeft={4}>
        <Text as="span" sizeText="display14" color="black">
          {proposal?.project?.projectTypeFormatted}
        </Text>
      </Flex>
    </Flex>
    <Flex paddingBottom="20px">
      <Flex minWidth="264px" paddingLeft={2}>
        <Text as="span" sizeText="display18" color="black" fontWeight="600">
          Descripción
        </Text>
      </Flex>
      <Flex paddingLeft={4}>
        <Text
          as="span"
          sizeText="display14"
          color="black"
          style={{
            whiteSpace: 'pre-wrap',
          }}>
          {proposal?.project?.description || '-'}
        </Text>
      </Flex>
    </Flex>
    <Flex paddingBottom="20px">
      <Flex minWidth="264px" paddingLeft={2}>
        <Text as="span" sizeText="display18" color="black" fontWeight="600">
          Adjuntos y enlaces
        </Text>
      </Flex>
      <Flex paddingLeft={4}>
        <Text as="span" sizeText="display14" color="black">
          <AttachmentList files={proposal?.project?.files} withDownload />
          <Links links={proposal?.project?.links} />
        </Text>
      </Flex>
    </Flex>
  </>
)
