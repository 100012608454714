import { Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { OfferBidItemDetail } from './OfferBidItemDetail'

export const PreviewBidPackageBidItems = ({ data }) => (
  <>
    <Grid
      gridTemplateAreas={"'empty bidItem unit measurement description'"}
      gridTemplateColumns="5% 30% 10% 10% 1fr"
      bg="gray4"
      width="100%"
      paddingY={2}
      borderRadius={2}
      height="48px">
      <GridItem gridArea="empty" />
      <GridItem gridArea="bidItem" style={{ display: 'flex', alignItems: 'center' }}>
        <Text
          sizeText="display14"
          color="gray1"
          overflow="hidden"
          fontWeight="medium"
          marginLeft={5}>
          Partida
        </Text>
      </GridItem>
      <GridItem gridArea="unit" style={{ display: 'flex', alignItems: 'center' }}>
        <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
          Unidades
        </Text>
      </GridItem>
      <GridItem gridArea="measurement" style={{ display: 'flex', alignItems: 'center' }}>
        <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
          Medición
        </Text>
      </GridItem>
      <GridItem gridArea="description" style={{ display: 'flex', alignItems: 'center' }}>
        <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="medium">
          Descripción
        </Text>
      </GridItem>
    </Grid>
    {data?.bidPackage?.bidItems?.map((element) => (
      <OfferBidItemDetail key={element.bidItemId} element={element} />
    ))}
  </>
)
