import { useState } from 'react'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

export const RemoveItem = ({ itemsChecked, removeBidItems, isDeleteButtonDisabled }) => {
  const [isOpen, setIsOpened] = useState(false)

  const getItemsChecked = () =>
    itemsChecked.length === 1
      ? `${itemsChecked.length} partida seleccionada`
      : `${itemsChecked.length} partidas seleccionadas`

  const onOpen = () => setIsOpened(true)

  const onClose = () => setIsOpened(false)

  const onRemove = () => {
    removeBidItems()
    onClose()
  }

  return (
    <>
      {itemsChecked.length > 0 && (
        <Flex
          borderTopWidth="2px"
          borderLeftWidth="2px"
          borderBottomWidth="2px"
          borderRightWidth="2px"
          borderColor="gray3"
          borderStyle="solid"
          alignItems="center">
          <Flex
            padding={4}
            style={{
              position: 'sticky',
              left: 0,
            }}>
            <Text sizeText="display14" color="gray2" overflow="hidden" fontWeight="regular">
              {getItemsChecked()}
            </Text>
          </Flex>
          <Flex
            padding={4}
            style={{
              position: 'sticky',
              left: '174px',
            }}>
            <Button
              sizeButton="none"
              colorType="none"
              backgroundColor="transparent"
              disabled={isDeleteButtonDisabled}
              action={onOpen}>
              <Text sizeText="display16" color="red1" overflow="hidden" fontWeight="medium">
                Borrar
              </Text>
            </Button>
          </Flex>
        </Flex>
      )}
      <Modal
        isOpen={isOpen}
        id="remove-bidItem-modal"
        onModalClose={onClose}
        minWidth="300"
        width="390px"
        paddingTop={6}>
        <Modal.Content>
          <Box>
            <Text sizeText="display16" fontWeight="medium" paddingBottom={2}>
              ¿Seguro que quieres borrar la partida con su información?
            </Text>
          </Box>
        </Modal.Content>
        <Modal.Actions>
          <Flex justifyContent="center">
            <Flex width="100%">
              <Button fullWidth marginRight={5} colorType="orange" action={onRemove}>
                Sí, borrar
              </Button>
              <Button id="cancel-button" fullWidth colorType="black" action={onClose}>
                No, volver
              </Button>
            </Flex>
          </Flex>
        </Modal.Actions>
      </Modal>
    </>
  )
}
