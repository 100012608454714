import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { HistoryTemplate } from '../template/HistoryTemplate'

import { usePagination } from '../../../../../common/hooks/pagination/usePagination'
import { useBidPackage } from '../../../../../common/hooks/useBidPackage'
import { useOffer } from '../../../../../common/hooks/useOffer'

const HistoryPage = () => {
  const { bidPackageId } = useParams()
  const { getEventLogsList, eventLogsList } = useBidPackage()

  const {
    data: { bidPackageAllOffers: offers },
    getBidPackageAllOffers,
  } = useOffer()

  const {
    params: { filters: filterEvents },
    handleAddFilter,
    handleRemoveFilter,
    reset,
  } = usePagination()

  const [guests, setGuests] = useState([])

  useEffect(() => {
    // reset event filter when change tab
    if (Object.keys(filterEvents).length) reset()
    getBidPackageAllOffers(bidPackageId)
  }, [])

  useEffect(() => {
    if (bidPackageId) {
      getEventLogsList(bidPackageId, filterEvents)
    }
  }, [bidPackageId, filterEvents])

  useEffect(() => {
    // get guests list from offers
    const guestsList = offers.map(({ resource }) => ({
      companyName: resource.companyName,
      invitationEmail: resource.invitationEmail,
    }))
    setGuests(guestsList)
  }, [offers])

  const handleSelectFilter = (key, value) => {
    if (!value?.value) {
      handleRemoveFilter(key)
      return
    }
    handleAddFilter(key, value.value)
  }

  // const getAllEventLogs = (poposalId) => getEventLogsList(proposalId)

  return (
    <HistoryTemplate
      guests={guests}
      eventLogsList={eventLogsList}
      handleSelectFilter={handleSelectFilter}
    />
  )
}

export default HistoryPage
