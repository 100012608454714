import Proptypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'

import { Layout } from '../../../common/components/Layout/Layout'
import OffersPage from '../../../pages/seller/offers/container/OffersPage'
import ProposalsPage from '../../../pages/seller/proposals/container/ProposalsPage'
import DetailProposalPage from '../../../pages/seller/detailProposal/container/DetailProposalPage'
import DetailOfferPage from '../../../pages/seller/detailOffer/container/DetailOfferPage'
import ApplyOfferPage from '../../../pages/seller/applyOffer/container/ApplyOfferPage'

export const routes = (sellerPath) => ({
  path: sellerPath,
  pages: {
    seller: {
      bidPackages: {
        path: `${sellerPath}/compras-nuevas`,
        details: {
          path: `${sellerPath}/compras-nuevas/:hash/:offerId`,
        },
      },
      apply: {
        path: `${sellerPath}/tus-aplicaciones`,
        pathHash: `${sellerPath}/tus-aplicaciones/:hash`,
        edit: {
          path: `${sellerPath}/tus-aplicaciones/:hash/editar`,
        },
      },
    },
  },
})

const RouterWrapper = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    )}
  />
)

export const SellerRoutes = ({ match: { url } }) => {
  const applicationRoutes = routes(url)
  return (
    <Switch>
      <RouterWrapper
        layout={Layout}
        path={[applicationRoutes.pages.seller.bidPackages.path]}
        component={OffersPage}
        exact
      />
      <RouterWrapper
        layout={Layout}
        path={[applicationRoutes.pages.seller.apply.path]}
        component={ProposalsPage}
        exact
      />
      <RouterWrapper
        layout={Layout}
        path={applicationRoutes.pages.seller.bidPackages.details.path}
        component={DetailOfferPage}
        exact
      />
      <RouterWrapper
        layout={Layout}
        path={applicationRoutes.pages.seller.apply.edit.path}
        component={ApplyOfferPage}
        exact
      />
      <RouterWrapper
        layout={Layout}
        path={applicationRoutes.pages.seller.apply.pathHash}
        component={DetailProposalPage}
        exact
      />
      <Redirect to={applicationRoutes.pages.seller.bidPackages.path} />
    </Switch>
  )
}

SellerRoutes.propTypes = { match: Proptypes.shape().isRequired }
