import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { GuestsTemplate } from '../template/GuestsTemplate'

import { parseEditorMessage } from '../../../../../../lib/utils/parseEditorMessage'

import { FormatDate } from '../../../../../common/mappers/FormatDate'

import { useBidPackage } from '../../../../../common/hooks/useBidPackage'
import { useEmployee } from '../../../../../common/hooks/useEmployee'
import { useHelpers } from '../../../../../common/hooks/useHelpers'
import { useOffer } from '../../../../../common/hooks/useOffer'
import { useProposal } from '../../../../../common/hooks/useProposal'
import { useSearchOrderParams } from '../../../../../common/hooks/useSearchOrderParams'
import { useUtils } from '../../../../../common/hooks/useUtils'

const GuestPage = ({ bidPackage, project }) => {
  const { bidPackageId } = useParams()

  const { employeeId } = useSelector((state) => state.auth.data)
  const { companyId: buyerCompanyId } = useSelector((state) => state.employee?.data)

  const [inviteMoreIsOpened, setInviteMoreIsOpened] = useState(false)
  const [detailsIsOpened, setDetailsIsOpened] = useState(false)
  const [detailsTabOpen, setDetailsTabOpen] = useState('company')
  const [confirmCancelIsOpened, setConfirmCancelIsOpened] = useState(false)
  const [sendModalIsOpen, setSendModalIsOpen] = useState(false)
  const [messageData, setMessageData] = useState({ proposalId: null, companyName: null })

  const [guests, setGuests] = useState([])
  const [optionsFilter, setOptionsFilter] = useState([])

  const {
    proposals: { getBidPackageProposals },
    actionBidPackage,
    sendMessage,
  } = useBidPackage()

  const {
    data: { proposalsBySeller },
    getProposalsBySeller,
  } = useProposal()

  const {
    data: { seller, categories },
    getCategories,
    getSellerByCompany,
  } = useHelpers()

  const { sendInvitation } = useEmployee()

  const {
    data: { bidPackageAllOffers: offers },
    getBidPackageAllOffers,
    cancelOffer,
  } = useOffer()

  const { filters, setFilters, getOptionStates } = useSearchOrderParams()
  const { setAlert } = useUtils()

  useEffect(() => {
    getCategories()
  }, [])

  useEffect(() => {
    if (bidPackageId) {
      getBidPackageProposals(bidPackageId)
    }
  }, [bidPackageId])

  useEffect(() => {
    getBidPackageAllOffers(bidPackageId, { filters })
  }, [bidPackageId, filters])

  useEffect(() => {
    // get guests list from offers
    const guestsList = offers.map(({ resource }) => {
      const {
        offerId,
        invitationId,
        state,
        companyName,
        companyId,
        contactName,
        contactEmail,
        lastUpdate,
        invitationEmail,
        proposalId,
      } = resource
      return {
        invitationId,
        companyName,
        state: !companyId && state === 'Pending' ? 'Invited' : state,
        lastUpdated: FormatDate.hydrate(lastUpdate).readableReverseDate,
        offerId,
        contactName:
          contactName?.includes('notienecorreo') || contactName?.includes('@conkau.io')
            ? 'No disponible'
            : contactName,
        companyId,
        invitationEmail,
        contactEmail,
        proposalId,
      }
    })
    setGuests(guestsList)

    if (!filters.length) {
      const states = offers?.map((offer) => offer.resource.state)
      setOptionsFilter(getOptionStates(states, 'offer'))
    }
  }, [offers])

  const resendInvitation = async (id, offerId, isSellerRegistered) => {
    await sendInvitation(id, bidPackageId, offerId, isSellerRegistered)
    getBidPackageAllOffers(bidPackageId)
  }

  const handleOpenDetail = async (companyId, tabOpen = 'company') => {
    if (!companyId) return
    const resp = await getSellerByCompany(companyId)
    if (resp) {
      await getProposalsBySeller(companyId, buyerCompanyId)
      setDetailsIsOpened(true)
      setDetailsTabOpen(tabOpen)
    }
  }

  const handleCancelOffer = async (offerId) => {
    setConfirmCancelIsOpened(false)
    await cancelOffer(bidPackageId, offerId, employeeId)
    await getBidPackageAllOffers(bidPackageId, { filters })
  }

  const handleInviteMore = async (categoriesSelected, message) => {
    const messageBody = message ? { message } : {}
    await actionBidPackage(bidPackageId, 'Offer', { categoriesSelected, ...messageBody })
    await getBidPackageAllOffers(bidPackageId)
  }

  const handleSendMessage = async ({ message }) => {
    const messageParsed = parseEditorMessage(message)

    if (!messageParsed) {
      setAlert('error', 'El mensaje debe tener al menos 5 caracteres.')
    } else {
      await sendMessage(messageData?.proposalId, {
        message: messageParsed,
        parentMessageId: null,
      })
    }
    setSendModalIsOpen(false)
  }

  const openSendMessageModal = ({ proposalId, companyName }) => {
    setMessageData({ proposalId, companyName })
    setSendModalIsOpen(true)
  }

  const categoriesFormatted = categories.data.map((el) => ({
    value: el?.categoryId,
    label: el?.name,
  }))

  return (
    <GuestsTemplate
      bidPackage={bidPackage}
      guests={guests}
      categories={categoriesFormatted}
      projectProvince={project?.province}
      resendInvitation={resendInvitation}
      optionsFilter={optionsFilter}
      filters={filters}
      setFilters={setFilters}
      inviteMoreIsOpened={inviteMoreIsOpened}
      setInviteMoreIsOpened={setInviteMoreIsOpened}
      handleInviteMore={handleInviteMore}
      seller={seller}
      handleOpenDetail={handleOpenDetail}
      detailsIsOpened={detailsIsOpened}
      setDetailsIsOpened={setDetailsIsOpened}
      detailsTabOpen={detailsTabOpen}
      confirmCancelIsOpened={confirmCancelIsOpened}
      setConfirmCancelIsOpened={setConfirmCancelIsOpened}
      handleCancelOffer={handleCancelOffer}
      proposalsBySeller={proposalsBySeller}
      sendModalIsOpen={sendModalIsOpen}
      setSendModalIsOpen={setSendModalIsOpen}
      handleSendMessage={handleSendMessage}
      openSendMessageModal={openSendMessageModal}
      messageData={messageData}
    />
  )
}

export default GuestPage
