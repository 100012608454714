import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'

export const RemoveSectionModal = ({ isOpen = false, onClose, removeSection }) => (
  <Modal
    isOpen={isOpen}
    id="remove-section-modal"
    onModalClose={onClose}
    minWidth="300"
    width="390px"
    paddingTop={6}>
    <Modal.Content>
      <Box>
        <Text sizeText="display16" fontWeight="medium" paddingBottom={2}>
          ¿Seguro que quieres borrar la sección con las partidas incluidas?
        </Text>
      </Box>
    </Modal.Content>
    <Modal.Actions>
      <Flex justifyContent="center">
        <Flex width="100%">
          <Button fullWidth marginRight={5} colorType="orange" action={removeSection}>
            Sí, borrar
          </Button>
          <Button id="cancel-button" fullWidth colorType="black" action={onClose}>
            No, volver
          </Button>
        </Flex>
      </Flex>
    </Modal.Actions>
  </Modal>
)
