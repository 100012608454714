import Proptypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import RegisterSellerPage from '../../../pages/auth/container/RegisterSellerPage'
import InvitationPage from '../../../pages/auth/container/InvitationPage'
import { RegisterPage } from '../../../pages/register/containers/RegisterPage'

export const routes = (registerPath) => ({
  path: registerPath,
  pages: {
    register: {
      path: `${registerPath}`,
      seller: {
        path: `${registerPath}/proveedor`,
      },
      invitation: {
        path: `${registerPath}/:hash`,
      },
    },
  },
})

export const RegisterRoutes = ({ match: { url } }) => {
  const applicationRoutes = routes(url)
  return (
    <Switch>
      <Route path={applicationRoutes.pages.register.path} component={RegisterPage} exact />
      <Route
        path={applicationRoutes.pages.register.seller.path}
        component={RegisterSellerPage}
        exact
      />
      <Route
        path={applicationRoutes.pages.register.invitation.path}
        component={InvitationPage}
        exact
      />
      <Redirect to={applicationRoutes.pages.register.seller.path} />
    </Switch>
  )
}

RegisterRoutes.propTypes = { match: Proptypes.shape().isRequired }
