import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Ceco2 } from '../components/Ceco2'

export const EmissionsCalculatorTemplate = ({ bidItemsEnviro, onSubmit }) => (
  <Flex
    width="100%"
    minWidth="800px"
    minHeight="300px"
    maxWidth="90vw"
    overflow="auto"
    flexDirection="column"
    position="relative">
    <Ceco2 bidItemsEnviro={bidItemsEnviro} onSubmit={onSubmit} />
  </Flex>
)
