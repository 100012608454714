import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Close } from '@proveoeng/uikit/dist/atoms/Icons'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Uploader } from '@proveoeng/uikit/dist/atoms/Uploader'
import { AttachmentList } from '../../../../common/components/attachmentList/Attachment'

import { LinksList } from '../../../../common/components/links/LinksList'

export const CreateBidPackageAttachment = ({
  setFormIsOpened,
  onDeleteFile,
  errorsImages,
  onSubmitFiles,
  links = [],
  inputLink,
  setInputLink,
  addLink,
  handleAddDescription,
  removeLink,
  verifyUrl,
  files,
  onImagesLoad,
}) => (
  <Box
    position="fixed"
    top={0}
    right={0}
    height="100%"
    minHeight="100vh"
    width="475px"
    boxShadow="topInner"
    backgroundColor="white"
    padding={6}
    overflow="auto">
    <Flex justifyContent="space-between">
      <Text sizeText="display30" fontWeight="bold">
        Rellena la información básica de esta compra
      </Text>
      <Flex>
        <Icon sizeIcon="display18" color="gray1" onClick={() => setFormIsOpened(false)}>
          <Close />
        </Icon>
      </Flex>
    </Flex>
    <Flex as="form" onSubmit={onSubmitFiles} height="100%" flexDirection="column">
      <>
        <Box style={{ flex: 1, paddingBottom: 40 }}>
          <Text sizeText="display16" color="black" marginY={3}>
            Ya hemos creado tu proceso de compra. Ahora puedes editarlo y publicarlo para encontrar
            los mejores precios. Si quieres adjuntar algún documento puedes hacerlo aquí.
          </Text>
          <Field label="Documentos" sizeText="display16" marginBottom={3}>
            <AttachmentList files={files} withDelete onDeleteFile={onDeleteFile} />
            <Uploader
              multiple
              name="files"
              marginBottom={3}
              errors={errorsImages}
              maxNumFiles={5}
              onImagesLoad={onImagesLoad}
              descriptionMessage="Arrastra, o haz click para subir tus documentos aquí"
              accept="all"
              padding={5}
            />
          </Field>
          <Box marginTop={7}>
            <LinksList
              links={links}
              inputLink={inputLink}
              setInputLink={setInputLink}
              addLink={addLink}
              handleAddDescription={handleAddDescription}
              removeLink={removeLink}
              verifyUrl={verifyUrl}
            />
          </Box>
        </Box>
        <Box position="sticky" bottom={0} width="100%" zIndex={3} bg="white">
          <Button
            action={() => setFormIsOpened(false)}
            type="button"
            colorType="transparent"
            fullWidth
            marginTop={5}>
            Dejar para más tarde
          </Button>
          <Button type="submit" colorType="orange" fullWidth marginTop={5}>
            Guardar documentos para el proveedor
          </Button>
        </Box>
      </>
    </Flex>
  </Box>
)
