import { useState } from 'react'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { PlusCircle } from '@proveoeng/uikit/dist/atoms/Icons'
import { Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

import { SummaryOfferImprovementRate } from './SummaryOfferImprovementRate'
import { parseToInteger, parseToString } from '../../../../lib/utils/Format'
import { ItemEditable } from '../../buyer/common/components/ItemEditable'

export const SummaryDiscount = ({
  data,
  columns,
  tableWidthCSS,
  values,
  handleChange,
  errors,
  submitForm,
}) => {
  const [itemDiscount, setItemDiscount] = useState(false)

  return (
    <>
      <Grid
        gridTemplateAreas={columns.area}
        gridTemplateColumns={columns.columns}
        style={{ width: tableWidthCSS }}>
        <GridItem
          gridArea="description"
          bg="white2"
          borderRightColor="gray3"
          borderRightStyle="solid"
          borderRightWidth="1px"
          borderBottomColor="gray3"
          borderBottomStyle="solid"
          borderBottomWidth="1px"
          padding={2}
          paddingTop={4}
          paddingLeft="30px"
          style={{
            position: 'sticky',
            left: 0,
            zIndex: 9,
          }}>
          <Flex justifyContent="space-between">
            <Flex>
              <Text
                color="gray1"
                overflow="hidden"
                fontWeight="600"
                sizeText="display14"
                paddingRight={1}>
                Dto. objetivo (%)
              </Text>

              {!itemDiscount && (
                <Button
                  colorType="none"
                  paddingX={4}
                  paddingY={0}
                  onClick={() => setItemDiscount(true)}>
                  <Icon style={{ cursor: 'pointer', fontSize: '18px' }} marginRight={1}>
                    <PlusCircle />
                  </Icon>
                  <Text
                    color="gray1"
                    sizeText="display14"
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                    Añadir
                  </Text>
                </Button>
              )}

              {itemDiscount && (
                <ItemEditable
                  gridArea="improvementRate"
                  name="improvementRate"
                  isDecimal
                  placeholderMessage="0,00"
                  value={values.improvementRate}
                  handleChange={handleChange}
                  error={errors.improvementRate}
                  onSubmit={submitForm}
                  padding={2}
                  paddingLeft={4}
                  defaultMode
                  style={{
                    position: 'sticky',
                    left: 1070,
                    zIndex: 9,
                    maxWidth: '83px',
                    padding: '0 0 0 4px',
                  }}>
                  <Text color="gray1" overflow="hidden" fontWeight="400" sizeText="display14">
                    {parseToString(parseToInteger(data?.improvementRate) * 100)}
                  </Text>
                </ItemEditable>
              )}
            </Flex>
            <Flex justifyContent="flex-end">
              <Text
                color="gray1"
                overflow="hidden"
                fontWeight="600"
                sizeText="display14"
                textAlign="right"
                paddingRight={1}>
                Dto. objetivo (€)
              </Text>
              <Text color="gray1" fontWeight="700" sizeText="display14" textAlign="right">
                {parseToString(data?.getTotalExpectedCost() * data?.improvementRate) || '0,00'} €
              </Text>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem
          gridArea="budgetK"
          bg="white2"
          padding={2}
          paddingY={4}
          borderBottomColor="gray3"
          borderBottomStyle="solid"
          borderBottomWidth="1px"
          style={{
            position: 'sticky',
            left: 510,
            zIndex: 9,
          }}
        />
        <GridItem
          gridArea="budget"
          bg="white2"
          borderRightColor="gray3"
          borderRightStyle="solid"
          borderRightWidth="1px"
          borderBottomColor="gray3"
          borderBottomStyle="solid"
          borderBottomWidth="1px"
          padding={2}
          paddingY={4}
          style={{
            position: 'sticky',
            left: 600,
            zIndex: 9,
          }}
        />
        <GridItem
          gridArea="expectedCostsK"
          bg="white2"
          padding={2}
          paddingY={4}
          borderBottomColor="gray3"
          borderBottomStyle="solid"
          borderBottomWidth="1px"
          style={{
            position: 'sticky',
            left: 730,
            zIndex: 9,
          }}
        />
        <GridItem
          gridArea="expectedCosts"
          bg="white2"
          borderRightColor="gray3"
          borderRightStyle="solid"
          borderRightWidth="1px"
          borderBottomColor="gray3"
          borderBottomStyle="solid"
          borderBottomWidth="1px"
          padding={2}
          paddingY={4}
          style={{
            position: 'sticky',
            left: 820,
            zIndex: 9,
          }}
        />
        <GridItem
          gridArea="minEnvelopeK"
          bg="white2"
          padding={2}
          paddingY={4}
          borderBottomColor="gray3"
          borderBottomStyle="solid"
          borderBottomWidth="1px"
          style={{
            position: 'sticky',
            left: 950,
            zIndex: 9,
          }}
        />
        <GridItem
          gridArea="minEnvelope"
          bg="white2"
          borderRightColor="gray3"
          borderRightStyle="solid"
          borderRightWidth="1px"
          borderBottomColor="gray3"
          borderBottomStyle="solid"
          borderBottomWidth="1px"
          padding={2}
          paddingY={4}
          style={{
            position: 'sticky',
            left: 1040,
            zIndex: 9,
          }}
        />
        {data.offers.map((offer) => (
          <GridItem
            gridArea={`offer-improvement-rate-${offer.id}`}
            backgroundColor="white"
            borderBottomColor="gray3"
            borderBottomStyle="solid"
            borderBottomWidth="1px"
            borderRightColor="gray3"
            borderRightStyle="solid"
            borderRightWidth="1px"
            padding={2}
            paddingY={4}>
            <Flex justifyContent="flex-end">
              <SummaryOfferImprovementRate
                data={data}
                offer={offer}
                improvementRate={data?.improvementRate}
              />
            </Flex>
          </GridItem>
        ))}
      </Grid>
    </>
  )
}
