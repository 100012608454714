import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Info } from '@proveoeng/uikit/dist/atoms/Icons'
import { Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Tooltip } from '@proveoeng/uikit/dist/atoms/Tooltip'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { useState } from 'react'
import { parseToInteger, parseToString } from '../../../../lib/utils/Format'

export const EnvironmentalCostsSummarySection = ({
  section,
  offers,
  columns,
  tableWidthCSS,
  setDetailsLink,
}) => {
  if (!section) {
    return <></>
  }

  const TooltipWrapper = ({ children }) => {
    const [active, setActive] = useState(false)
    return (
      <Tooltip
        active={active}
        setActive={setActive}
        bgColor="gray3"
        textColor="black"
        padding={4}
        width="497px"
        content="Aproximación indirecta al impacto ambiental a partir del parámetro distancia a obra.">
        {children}
      </Tooltip>
    )
  }

  const getTotal = (offer) => {
    let total = 0
    section?.bidItems?.forEach((bidItem) => {
      offer?.offerItems?.forEach((offerItem) => {
        if (bidItem.id === offerItem.itemId) {
          total +=
            parseToInteger(offerItem?.enviroData?.eCo2) * (parseToInteger(bidItem.measure) / 1000)
        }
      })
    })
    return total ? `${parseToString(total)} kg` : '-'
  }

  return (
    <>
      <Grid
        gridTemplateAreas={columns.area}
        gridTemplateColumns={columns.columns}
        style={{ width: tableWidthCSS }}>
        <GridItem
          backgroundColor="white"
          gridArea="description"
          padding={2}
          paddingTop={4}
          paddingLeft="30px"
          style={{
            position: 'sticky',
            left: 0,
            zIndex: 9,
          }}>
          <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="600">
            Declaración ambiental
          </Text>
        </GridItem>
        {offers.map((offer) => (
          <>
            <GridItem gridArea={`offer-k-${offer.id}`} bg="white" />
            <GridItem gridArea={`offer-margin-${offer.id}`} bg="white" padding={2} paddingTop={4}>
              <Text textAlign="right" color="black" overflow="hidden" sizeText="display12">
                {offer?.enviroData?.enviroDataInfo ? setDetailsLink(offer) : '-'}
              </Text>
            </GridItem>
          </>
        ))}
      </Grid>
      <Grid
        gridTemplateAreas={columns.area}
        gridTemplateColumns={columns.columns}
        style={{ width: tableWidthCSS }}>
        <GridItem
          backgroundColor="white"
          gridArea="description"
          padding={2}
          paddingTop={4}
          paddingLeft="30px"
          style={{
            position: 'sticky',
            left: 0,
            zIndex: 9,
          }}>
          <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="600">
            Uso de materiales reciclados
          </Text>
        </GridItem>
        {offers.map((offer) => (
          <>
            <GridItem gridArea={`offer-k-${offer.id}`} bg="white" />
            <GridItem gridArea={`offer-margin-${offer.id}`} bg="white" padding={2} paddingTop={4}>
              <Text textAlign="right" color="black" overflow="hidden" sizeText="display12">
                {offer?.enviroData?.recycledMaterials
                  ? `${parseToString(offer?.enviroData?.recycledMaterials)} kg`
                  : '-'}
              </Text>
            </GridItem>
          </>
        ))}
      </Grid>
      <Grid
        gridTemplateAreas={columns.area}
        gridTemplateColumns={columns.columns}
        style={{ width: tableWidthCSS }}>
        <GridItem
          backgroundColor="white"
          gridArea="description"
          padding={2}
          paddingTop={4}
          paddingLeft="30px"
          style={{
            position: 'sticky',
            left: 0,
            zIndex: 9,
          }}>
          <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="600">
            Impacto ambiental
          </Text>
        </GridItem>
        {offers.map((offer) => (
          <>
            <GridItem gridArea={`offer-k-${offer.id}`} bg="white" />
            <GridItem gridArea={`offer-margin-${offer.id}`} bg="white" padding={2} paddingTop={4}>
              <Text textAlign="right" color="black" overflow="hidden" sizeText="display12">
                {getTotal(offer)}
              </Text>
            </GridItem>
          </>
        ))}
      </Grid>
      <Grid
        gridTemplateAreas={columns.area}
        gridTemplateColumns={columns.columns}
        style={{ width: tableWidthCSS }}>
        <GridItem
          backgroundColor="white"
          gridArea="description"
          padding={2}
          paddingTop={4}
          paddingLeft="30px"
          paddingBottom="40px"
          style={{
            position: 'sticky',
            left: 0,
            zIndex: 9,
          }}>
          <Flex alignItems="center">
            <Text sizeText="display14" color="gray1" overflow="hidden" fontWeight="600">
              Impacto del transporte
            </Text>
            <TooltipWrapper>
              <Flex justifyContent="center" alignItems="center">
                <Icon sizeIcon="display24" color="gray1">
                  <Info />
                </Icon>
              </Flex>
            </TooltipWrapper>
          </Flex>
        </GridItem>
        {offers.map((offer) => (
          <>
            <GridItem gridArea={`offer-k-${offer.id}`} bg="white" />
            <GridItem gridArea={`offer-margin-${offer.id}`} bg="white" padding={2} paddingTop={4}>
              <Flex flexDirection="column">
                <Text
                  textAlign="right"
                  color="black"
                  overflow="hidden"
                  sizeText="display12"
                  paddingBottom={2}>
                  {offer?.enviroData?.distanceToBuildingSite
                    ? `${parseToString(offer?.enviroData?.distanceToBuildingSite)} km`
                    : '-'}
                </Text>
              </Flex>
            </GridItem>
          </>
        ))}
      </Grid>
    </>
  )
}
