import { constants } from '../config'

/* **** getProposal *** */
export const getProposal = (id) => ({
  type: constants.GET_PROPOSAL,
  id,
})

export const getProposalSuccess = (data) => ({
  type: constants.GET_PROPOSAL_SUCCESS,
  payload: data,
})

/* **** updateProposal *** */
export const updateProposal = (id, body, actionPublish) => ({
  type: constants.UPDATE_PROPOSAL,
  id,
  body,
  actionPublish,
})

export const updateProposalSuccess = (data) => ({
  type: constants.UPDATE_PROPOSAL_SUCCESS,
  payload: data,
})

/* **** getProposalsItems *** */
export const getProposalsItems = (id) => ({
  type: constants.GET_PROPOSAL_ITEMS,
  id,
})

export const getProposalsItemsSuccess = (data) => ({
  type: constants.GET_PROPOSAL_ITEMS_SUCCESS,
  payload: data,
})

/* **** getProposalItem *** */
export const getProposalItem = (id, idItem) => ({
  type: constants.GET_PROPOSAL_ITEM,
  id,
  idItem,
})

export const getProposalItemSuccess = (data) => ({
  type: constants.GET_PROPOSAL_ITEM_SUCCESS,
  payload: data,
})

/* **** updateProposalItem *** */
export const updateProposalItem = (id, idItem, body) => ({
  type: constants.UPDATE_PROPOSAL_ITEM,
  id,
  idItem,
  body,
})

export const updateProposalItemSuccess = (data) => ({
  type: constants.UPDATE_PROPOSAL_ITEM_SUCCESS,
  payload: data,
})

/* **** createAttachmentProposal *** */
export const createAttachmentProposal = (proposalId, files, body) => ({
  type: constants.CREATE_ATTACHMENT_PROPOSAL,
  proposalId,
  files,
  body,
})

export const createAttachmentProposalSuccess = (data) => ({
  type: constants.CREATE_ATTACHMENT_PROPOSAL_SUCCESS,
  payload: data,
})

/* **** createAttachmentProposalItem *** */
export const createAttachmentProposalItem = (proposalId, proposalItemId, files, body) => ({
  type: constants.CREATE_ATTACHMENT_PROPOSAL_ITEM,
  proposalId,
  proposalItemId,
  files,
  body,
})

export const createAttachmentProposalItemSuccess = (data) => ({
  type: constants.CREATE_ATTACHMENT_PROPOSAL_ITEM_SUCCESS,
  payload: data,
})

/* **** deleteAttachmentProposalBidItem *** */
export const deleteAttachmentProposal = (proposalId, files) => ({
  type: constants.DELETE_ATTACHMENT_PROPOSAL,
  proposalId,
  files,
})

export const deleteAttachmentProposalSuccess = (data) => ({
  type: constants.DELETE_ATTACHMENT_PROPOSAL_SUCCESS,
  payload: data,
})

/* **** deleteAttachmentProposalItem *** */
export const deleteAttachmentProposalItem = (proposalId, files, proposalItemId) => ({
  type: constants.DELETE_ATTACHMENT_PROPOSAL_ITEM,
  proposalId,
  proposalItemId,
  files,
})

export const deleteAttachmentProposalItemSuccess = (data) => ({
  type: constants.DELETE_ATTACHMENT_PROPOSAL_ITEM_SUCCESS,
  payload: data,
})

/* **** actionProposal *** */
export const actionProposal = (proposalId, body) => ({
  type: constants.ACTION_PROPOSAL,
  proposalId,
  body,
})

export const actionProposalSuccess = (data) => ({
  type: constants.ACTION_PROPOSAL_SUCCESS,
  payload: data,
})

/* **** setAttachmentProposalBidPackage *** */
export const setAttachmentProposalBidPackageSuccess = (data) => ({
  type: constants.CREATE_ATTACHMENT_PROPOSAL_BID_PACKAGE_SUCCESS,
  payload: data,
})

export const setProposalBidPackageSuccess = (data) => ({
  type: constants.SET_PROPOSAL_BID_PACKAGE_SUCCESS,
  payload: data,
})

export const resetProposal = () => ({
  type: constants.RESET_STORE_PROPOSAL,
})

export const setStore = (data) => ({
  type: constants.SET_STORE_PROPOSAL,
  payload: data,
})

export const proposalsFails = (error) => ({
  type: constants.PROPOSAL_FAIL,
  error,
})

export const cancelProposal = (proposalId, body) => ({
  type: constants.CANCEL_PROPOSAL,
  proposalId,
  body,
})

export const cancelProposalSuccess = (data) => ({
  type: constants.CANCEL_PROPOSAL_SUCCESS,
  payload: data,
})

export const requestImprovementProposal = (proposalId, body) => ({
  type: constants.REQUEST_IMPROVEMENT_PROPOSAL,
  proposalId,
  body,
})

export const requestImprovementProposalSuccess = (data) => ({
  type: constants.REQUEST_IMPROVEMENT_PROPOSAL_SUCCESS,
  payload: data,
})

export const manageProposalItemsFiles = (proposalId, filesToAdd, filesToRemove) => ({
  type: constants.MANAGE_ATTACHEMENT_PROPOSAL_ITEMS,
  proposalId,
  filesToAdd,
  filesToRemove,
})

export const manageProposalFiles = (proposalId, filesToAdd, filesToRemove, proposalType) => ({
  type: constants.MANAGE_ATTACHEMENT_PROPOSAL,
  proposalId,
  filesToAdd,
  filesToRemove,
  proposalType,
})

export const manageProposalFilesSuccess = (data) => ({
  type: constants.MANAGE_ATTACHEMENT_PROPOSAL_SUCCESS,
  payload: data,
})

export const manageProposalItemsFilesSuccess = (data) => ({
  type: constants.MANAGE_ATTACHEMENT_PROPOSAL_ITEMS_SUCCESS,
  payload: data,
})

export const manageProposalItemsLink = (proposalId, linksToAdd, linksToRemove) => ({
  type: constants.MANAGE_LINK_PROPOSAL_ITEMS,
  proposalId,
  linksToAdd,
  linksToRemove,
})

export const manageProposalLink = (
  proposalId,
  linksToAdd,
  linksToRemove,
  proposalType,
  proposalItemId,
) => ({
  type: constants.MANAGE_LINK_PROPOSAL,
  proposalId,
  linksToAdd,
  linksToRemove,
  proposalType,
  proposalItemId,
})

export const manageProposalLinkSuccess = (data) => ({
  type: constants.MANAGE_LINK_PROPOSAL_SUCCESS,
  payload: data,
})

export const manageProposalItemsLinkSuccess = (data) => ({
  type: constants.MANAGE_LINK_PROPOSAL_ITEMS_SUCCESS,
  payload: data,
})
