import * as React from 'react'

function SvgIconWarning(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      {...props}>
      <path
        stroke="#868DA1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 18.667a6.667 6.667 0 100-13.334 6.667 6.667 0 000 13.334zM12 9.333V12M12 14.667h.007"
      />
    </svg>
  )
}

export default SvgIconWarning
