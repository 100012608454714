import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'

export const CreateProjectDirectionTemplate = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
}) => (
  <>
    <Text as="span" sizeText="display32" color="black" marginBottom={3} fontWeight="bold">
      Pon la localización de tu obra
    </Text>

    <Field
      errorMessage={touched.address && errors.address}
      label="Dirección"
      required
      sizeText="display16"
      marginBottom={3}
      marginLeft={2}>
      <Input
        placeholderMessage="Dirección de la obra"
        ariaLabel="address"
        name="address"
        value={values.address}
        onChange={handleChange}
        onBlur={handleBlur}
        hasError={touched.address && !!errors.address}
      />
    </Field>

    <Flex width="100%">
      <Box width="100%" marginRight={2}>
        <Field
          errorMessage={touched.city && errors.city}
          label="Ciudad"
          required
          sizeText="display16"
          marginBottom={3}
          marginTop={5}
          marginLeft={2}>
          <Input
            placeholderMessage="Ciudad de la obra"
            name="city"
            ariaLabel="city"
            value={values.city}
            onChange={handleChange}
            onBlur={handleBlur}
            hasError={touched.city && !!errors.city}
          />
        </Field>
      </Box>
      <Box width="100%" marginLeft={2}>
        <Field
          errorMessage={touched.postCode && errors.postCode}
          label="Código postal"
          required
          sizeText="display16"
          marginBottom={3}
          marginTop={5}
          marginLeft={2}>
          <Input
            type="text"
            placeholderMessage="Código postal de la obra"
            name="postCode"
            ariaLabel="postCode"
            value={values.postCode}
            onChange={handleChange}
            onBlur={handleBlur}
            hasError={touched.postCode && !!errors.postCode}
          />
        </Field>
      </Box>
    </Flex>
  </>
)
