import { constants } from '../config'

const initialState = {
  doneCategories: false,
  loading: false,
  errorRequest: false,
  error: null,
  categories: {},
}

export default function setHelpersReducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_CATEGORIES:
      return {
        ...state,
        loading: true,
        doneCategories: false,
        errorRequest: false,
        error: null,
      }
    case constants.GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        doneCategories: true,
        categories: {
          ...action.payload,
        },
      }
    }
    case constants.GET_SELLER_CATEGORIES:
      return {
        ...state,
        loading: true,
        doneProviderCategories: false,
        errorRequest: false,
        error: null,
      }
    case constants.GET_SELLER_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: true,
        doneProviderCategories: true,
        sellerCategories: action.payload,
      }
    case constants.HELPERS_FAIL:
      return { ...state, loading: false, errorRequest: true, error: { ...action.error } }

    default:
      return state
  }
}
