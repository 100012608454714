import PropTypes from 'prop-types'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Card } from '@proveoeng/uikit/dist/atoms/Card'
import { useSelector } from 'react-redux'
import { Sidebar } from '../Sidebar/Sidebar'
import { Sidebar2 } from '../Sidebar/Sidebar2'

export const LayoutCreate = (props) => {
  const {
    children,
    project,
    errors,
    history,
    onSubmit,
    menuItems,
    actionLayout,
    disableOptions,
    hideActionLayout,
  } = props
  const employee = useSelector((state) => state.employee?.data)
  const auth = useSelector((state) => state.auth?.data)

  return (
    <Flex overflow="hidden">
      {actionLayout && (
        <Sidebar2
          src={employee?.avatar}
          placeholder={auth.username}
          employee={employee}
          disableOptions={disableOptions}
          project={project}
          errors={errors}
        />
      )}
      {!actionLayout && (
        <Sidebar
          src={employee?.avatar}
          placeholder={auth.username}
          history={history}
          menuItems={menuItems}
          employee={employee}
          data={auth}
          disableOptions={disableOptions}
        />
      )}
      <Flex
        backgroundColor="white"
        marginLeft={214}
        width="100%"
        height="100vh"
        maxHeight="calc(100vh - 15px)"
        borderTopLeftRadius={2}
        borderBottomLeftRadius={2}
        position="relative"
        overflow="auto">
        <Card
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          width="100%"
          height="100%"
          paddingY={0}
          paddingX={0}
          borderColor="white"
          overflow="scroll">
          {children}
        </Card>
        {!hideActionLayout && actionLayout && (
          <Flex
            justifyContent="flex-end"
            borderTopStyle="solid"
            borderTopWidth={1}
            borderTopColor="gray3"
            paddingY={4}
            paddingX={6}
            style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
            {actionLayout(onSubmit)}
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

LayoutCreate.propTypes = {
  children: PropTypes.element.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
}

LayoutCreate.defaultProps = {
  location: {
    pathname: '',
  },
}
