import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'

import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Box, Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { Loader } from '@proveoeng/uikit/dist/atoms/Loader'
import { SelectInput } from '@proveoeng/uikit/dist/atoms/Select'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'

import { Preview } from './Preview'

import { ContentConfirm } from './ContentConfirm'
import { ContentSelection } from './ContentSelection'
import { getTotalMeasurement } from './utils'

const titles = [
  'Importa tus compras desde .BC3',
  'Rellena la información',
  'Selecciona las partidas',
  'Revisa tu compra',
  'El proceso de compra se ha creado correctamente',
]

const initialValues = {
  name: '',
  categoryId: '',
  bidEndsAt: '',
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'El nombre debe tener minimo 3 caracteres')
    .max(50, 'El nombre debe tener maximo 50 caracteres')
    .required('Introduce un nombre'),
  categoryId: Yup.string().required('Selecciona una categoria'),
  bidEndsAt: Yup.date()
    .required('Introduce una fecha de finalización')
    .min(new Date(), 'La fecha de finalización debe ser mayor que la actual'),
})

export const ModalImportBidPackage = ({
  data,
  isOpened,
  setIsOpened,
  categoriesData = [],
  onSubmit,
}) => {
  const [info, setInfo] = useState()
  const [step, setStep] = useState(0)
  const [selectedBidItems, setSelectedBidItems] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setInfo(data)
  }, [data])

  const {
    values,
    handleChange,
    errors,
    handleSubmit,
    setFieldValue,
    resetForm,
    handleBlur,
    touched,
  } = useFormik({
    initialValues,
    onSubmit: () => setStep(2),
    validationSchema,
  })

  useEffect(() => {
    setInfo(data)
  }, [data])

  useEffect(() => {
    if (!isOpened) {
      reset()
    }
  }, [isOpened])

  const reset = () => {
    setStep(0)
    resetForm()
    setSelectedBidItems({})
    setInfo(data)
  }

  const importBidItems = async () => {
    setLoading(true)
    const bidItemsToSave = Object.values(selectedBidItems).map((items) => ({
      ...items[0],
      measurement: getTotalMeasurement(items),
    }))
    await onSubmit(values, bidItemsToSave)
    setLoading(false)
    setStep(4)
  }

  const seletedLength = useMemo(
    () => Object.values(selectedBidItems).flat()?.length || 0,
    [selectedBidItems],
  )

  const selectedTotal = useMemo(
    () =>
      Object.values(selectedBidItems)
        .flat()
        .reduce((acc, item) => acc + item.amountCi, 0),
    [selectedBidItems],
  )

  const selectTotalECO2 = useMemo(
    () =>
      Object.values(selectedBidItems)
        .flat()
        .reduce((acc, item) => acc + item.eCo2 || 0, 0),
    [selectedBidItems],
  )
  const categoriesOptions = useMemo(
    () =>
      categoriesData.map(({ categoryId, name }) => ({
        value: categoryId,
        label: name,
      })),
    [categoriesData],
  )

  const categoryName = useMemo(
    () => categoriesData?.find(({ categoryId }) => categoryId === values.categoryId)?.name,
    [values, categoriesData],
  )

  const RenderPreview = (
    <Modal.Content>
      <Button colorType="orange" action={() => setStep(1)}>
        Crear nueva compra
      </Button>
      <Box minWidth="1000px">
        <Preview data={data} />
      </Box>
    </Modal.Content>
  )

  const RenderForm = (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <Modal.Content>
        <Field
          label="Nombre de la compra"
          required
          sizeText="display16"
          marginBottom={3}
          errorMessage={touched.name && errors.name}>
          <Input
            required
            placeholderMessage="Da un nombre a tu proceso de compra"
            ariaLabel="Nombre"
            name="name"
            hasError={touched.name && !!errors.name}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Field>
        <Field
          label="Naturaleza"
          required
          sizeText="display16"
          marginBottom={3}
          marginTop={5}
          errorMessage={touched.categoryId && errors.categoryId}>
          <SelectInput
            isSearchable
            required
            name="categoryId"
            id="categoryId"
            onChange={(_, v) => setFieldValue('categoryId', v.value)}
            options={categoriesOptions}
            placeholderMessage="Selecciona a que naturaleza pertenece esta compra"
            ariaLabel="turnover"
            hasError={touched.categoryId && !!errors.categoryId}
            defaultValue={{ value: values.categoryId }}
            onBlur={handleBlur}
          />
        </Field>

        <Field
          required
          label="Fecha límite para presentar oferta"
          sizeText="display16"
          marginBottom={3}
          marginTop={5}
          errorMessage={touched.bidEndsAt && errors.bidEndsAt}>
          <Input
            required
            type="date"
            name="bidEndsAt"
            ariaLabel="bidEndsAt"
            placeholderMessage="Fecha límite para presentar oferta"
            hasError={touched.bidEndsAt && !!errors.bidEndsAt}
            value={values.bidEndsAt}
            onChange={handleChange}
            min={new Date().toISOString().split('T')[0]}
            onBlur={handleBlur}
          />
        </Field>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr" mr={4}>
            <Button colorType="transparent" type="button" action={() => setStep(0)}>
              Volver
            </Button>
          </Grid>
          <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr">
            <Button colorType="orange" type="submit">
              Seleccionar partidas
            </Button>
          </Grid>
        </Flex>
      </Modal.Actions>
    </form>
  )

  const RenderSelection = (
    <>
      <Modal.Content>
        <ContentSelection
          name={values.name}
          categoryName={categoryName}
          bidEndsAt={values.bidEndsAt}
          info={info}
          setInfo={setInfo}
          selectedBidItems={selectedBidItems}
          itemsSelected={seletedLength}
          selectedTotal={selectedTotal}
          selectTotalECO2={selectTotalECO2}
          setSelectedBidItems={setSelectedBidItems}
          seletedLength={seletedLength}
        />
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr" mr={4}>
            <Button colorType="transparent" type="button" action={() => setStep(1)}>
              Volver a detalles
            </Button>
          </Grid>
          <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr">
            <Button
              colorType="orange"
              type="button"
              action={() => setStep(3)}
              disabled={seletedLength === 0}>
              Revisar y crear compra
            </Button>
          </Grid>
        </Flex>
      </Modal.Actions>
    </>
  )

  const RenderConfirm = (
    <>
      <Modal.Content>
        <ContentConfirm
          name={values?.name}
          categoryName={categoryName}
          bidEndsAt={values?.bidEndsAt}
          selectedBidItems={selectedBidItems}
          seletedLength={seletedLength}
          selectTotalECO2={selectTotalECO2}
        />
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr" mr={4}>
            <Button colorType="transparent" type="button" action={() => setStep(2)}>
              Volver a partidas
            </Button>
          </Grid>
          <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr">
            <Button colorType="orange" type="button" action={importBidItems}>
              Crear compra
            </Button>
          </Grid>
        </Flex>
      </Modal.Actions>
    </>
  )

  const RenderSuccess = (
    <>
      <Modal.Content>
        <Box>
          <Text sizeText="display16" color="black" marginBottom={2}>
            Una vez termines el proceso de importación, podrás empezar a pedir presupuestos a los
            proveedores.
          </Text>
          <Text sizeText="display16" color="black" marginBottom={2}>
            Puedes seguir creando procesos de compra o seguir más tarde. Ten en cuenta que tendrás
            que volver a importar el fichero bc3.
          </Text>
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr" mr={4}>
            <Button colorType="transparent" type="button" action={() => setIsOpened(false)}>
              Hecho
            </Button>
          </Grid>
          <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr">
            <Button colorType="orange" type="button" action={reset}>
              Crear más compras
            </Button>
          </Grid>
        </Flex>
      </Modal.Actions>
    </>
  )

  return (
    <Modal
      isOpen={isOpened}
      id="import-modal-bidpackage"
      onModalClose={() => setIsOpened(false)}
      closeWithButton
      title={titles[step]}>
      {loading && <Loader />}
      {step === 0 && RenderPreview}
      {step === 1 && RenderForm}
      {step === 2 && RenderSelection}
      {step === 3 && RenderConfirm}
      {step === 4 && RenderSuccess}
    </Modal>
  )
}

ModalImportBidPackage.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  isOpened: PropTypes.bool.isRequired,
  setIsOpened: PropTypes.bool.isRequired,
  categoriesData: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func,
}

ModalImportBidPackage.defaultProps = {
  data: null,
  categoriesData: [],
  onSubmit: () => {},
}
