import { constants } from '../config'

export const getAllBidPackages = (bidPackageId) => ({
  type: constants.GET_ALL_BID_PACKAGES,
  bidPackageId,
})

export const getAllBidPackagesSuccess = (data) => ({
  type: constants.GET_ALL_BID_PACKAGES_SUCCESS,
  payload: data,
})

export const createBidPackage = (body) => ({
  type: constants.CREATE_BID_PACKAGE,
  body,
})

export const createBidPackageSuccess = (data) => ({
  type: constants.CREATE_BID_PACKAGE_SUCCESS,
  payload: data,
})

export const clearBidPackageCreated = () => ({
  type: constants.CLEAR_BID_PACKAGE,
})

export const updateBidPackage = (bidPackageId, body, files, links, deleteFiles, deleteLinks) => ({
  type: constants.UPDATE_BID_PACKAGE,
  bidPackageId,
  body,
  files,
  links,
  deleteFiles,
  deleteLinks,
})

export const updateBidPackageSuccess = (data) => ({
  type: constants.UPDATE_BID_PACKAGE_SUCCESS,
  payload: data,
})

export const getBidPackage = (id) => ({
  type: constants.GET_BID_PACKAGE,
  id,
})

export const getBidPackageSuccess = (data) => ({
  type: constants.GET_BID_PACKAGE_SUCCESS,
  payload: data,
})

export const deleteBidPackage = (projectId, bidPackageId, callback) => ({
  type: constants.DELETE_BID_PACKAGE,
  projectId,
  bidPackageId,
  callback,
})

export const deleteBidPackageSuccess = (data) => ({
  type: constants.DELETE_BID_PACKAGE_SUCCESS,
  payload: data,
})

export const createAttachmentBidPackage = (bidPackageId, files) => ({
  type: constants.CREATE_ATTACHMENT_BID_PACKAGE,
  bidPackageId,
  files,
})

export const createAttachmentBidPackageSuccess = (data) => ({
  type: constants.CREATE_ATTACHMENT_BID_PACKAGE_SUCCESS,
  payload: data,
})

export const deleteAttachmentBidPackage = (bidPackageId, files) => ({
  type: constants.DELETE_ATTACHMENT_BID_PACKAGE,
  bidPackageId,
  files,
})

export const deleteAttachmentBidPackageSuccess = (fileId) => ({
  type: constants.DELETE_ATTACHMENT_BID_PACKAGE_SUCCESS,
  payload: fileId,
})

export const createLinkBidPackage = (bidPackageId, links) => ({
  type: constants.CREATE_LINK_BID_PACKAGE,
  bidPackageId,
  links,
})

export const createLinkBidPackageSuccess = (data) => ({
  type: constants.CREATE_LINK_BID_PACKAGE_SUCCESS,
  payload: data,
})

export const deleteLinkBidPackage = (bidPackageId, linkIds) => ({
  type: constants.DELETE_LINK_BID_PACKAGE,
  bidPackageId,
  linkIds,
})

export const deleteLinkBidPackageSuccess = (data) => ({
  type: constants.DELETE_LINK_BID_PACKAGE_SUCCESS,
  payload: data,
})

export const actionBidPackage = (bidPackageId, actionText, data, callback) => ({
  type: constants.ACTION_BID_PACKAGE,
  bidPackageId,
  actionText,
  data,
  callback,
})

export const actionBidPackageSuccess = () => ({
  type: constants.ACTION_BID_PACKAGE_SUCCESS,
})

export const setStoreBidPackages = (data) => ({
  type: constants.SET_STORE_BID_PACKAGES,
  payload: data,
})

export const setStoreBidPackage = (data) => ({
  type: constants.SET_STORE_BID_PACKAGE,
  payload: data,
})

export const bidPackageFails = (error) => ({
  type: constants.BID_PACKAGE_FAIL,
  error,
})

export const bidPackagesFails = (error) => ({
  type: constants.BID_PACKAGES_FAIL,
  error,
})

export const createBidItemSuccess = (data) => ({
  type: constants.CREATE_BID_ITEM_SUCCESS,
  payload: data,
})
export const getAllBidItems = (bidPackageId) => ({
  type: constants.GET_ALL_BID_ITEMS,
  bidPackageId,
})

export const getAllBidItemsSuccess = (data) => ({
  type: constants.GET_ALL_BID_ITEMS_SUCCESS,
  payload: data,
})

export const updateBidItem = (
  bidPackageId,
  bidItemId,
  body,
  files,
  links,
  deleteFiles,
  deleteLinks,
) => ({
  type: constants.UPDATE_BID_ITEM,
  bidPackageId,
  bidItemId,
  body,
  files,
  links,
  deleteFiles,
  deleteLinks,
})

export const updateBidItemSuccess = (data) => ({
  type: constants.UPDATE_BID_ITEM_SUCCESS,
  payload: data,
})

export const getBidItem = (id, bidItemId) => ({
  type: constants.GET_BID_ITEM,
  id,
  bidItemId,
})

export const getBidItemSuccess = (data) => ({
  type: constants.GET_BID_ITEM_SUCCESS,
  payload: data,
})

export const deleteBidItem = (bidPackageId, bidItemId) => ({
  type: constants.DELETE_BID_ITEM,
  bidPackageId,
  bidItemId,
})

export const deleteBidItemSuccess = (data) => ({
  type: constants.DELETE_BID_ITEM_SUCCESS,
  payload: data,
})

export const createAttachmentBidItem = (bidPackageId, bidItemId, files) => ({
  type: constants.CREATE_ATTACHMENT_BID_ITEM,
  bidPackageId,
  bidItemId,
  files,
})

export const createAttachmentBidItemSuccess = (data) => ({
  type: constants.CREATE_ATTACHMENT_BID_ITEM_SUCCESS,
  payload: data,
})

export const createLinkBidItem = (bidPackageId, bidItemId, links) => ({
  type: constants.CREATE_LINK_BID_ITEM,
  bidPackageId,
  bidItemId,
  links,
})

export const createLinkBidItemSuccess = (data) => ({
  type: constants.CREATE_LINK_BID_ITEM_SUCCESS,
  payload: data,
})

export const actionBidItem = (bidPackageId, bidItemId, actionText) => ({
  type: constants.ACTION_BID_ITEM,
  bidPackageId,
  bidItemId,
  actionText,
})

export const actionBidItemSuccess = (data) => ({
  type: constants.ACTION_BID_ITEM_SUCCESS,
  payload: data,
})

export const deleteAttachmentBidItem = (bidPackageId, bidItemId, files) => ({
  type: constants.DELETE_ATTACHMENT_BID_ITEM,
  bidPackageId,
  bidItemId,
  files,
})

export const deleteAttachmentBidItemSuccess = (data) => ({
  type: constants.DELETE_ATTACHMENT_BID_ITEM_SUCCESS,
  payload: data,
})

export const deleteLinkBidItem = (bidPackageId, bidItemId, linkIds) => ({
  type: constants.DELETE_LINK_BID_ITEM,
  bidPackageId,
  bidItemId,
  linkIds,
})

export const deleteLinkBidItemSuccess = (data) => ({
  type: constants.DELETE_LINK_BID_ITEM_SUCCESS,
  payload: data,
})

export const setStoreBidItems = (data) => ({
  type: constants.SET_STORE_BID_ITEM,
  payload: data,
})

export const setStoreBidItem = (data) => ({
  type: constants.SET_STORE_BID_ITEM,
  payload: data,
})

export const getBidPackageOffer = (bidPackageId, offerId, loadFiles) => ({
  type: constants.GET_BID_PACKAGE_OFFER,
  bidPackageId,
  offerId,
  loadFiles,
})

export const getBidPackageOfferSuccess = (data) => ({
  type: constants.GET_BID_PACKAGE_OFFER_SUCCESS,
  payload: data,
})

export const actionBidPackageOffer = (bidPackageId, offerId, employeeId, actionText) => ({
  type: constants.ACTION_BID_PACKAGE_OFFER,
  bidPackageId,
  offerId,
  employeeId,
  actionText,
})

export const actionBidPackageOfferSuccess = (data) => ({
  type: constants.ACTION_BID_PACKAGE_OFFER_SUCCESS,
  payload: data,
})

export const setAttachmentPackageOfferSuccess = (data) => ({
  type: constants.CREATE_ATTACHMENT_BID_PACKAGE_OFFER_SUCCESS,
  payload: data,
})

export const setAttachmentPackageProposalSuccess = (data) => ({
  type: constants.CREATE_ATTACHMENT_BID_PACKAGE_PROPOSAL_SUCCESS,
  payload: data,
})
export const setAttachmentPackageProposalItemSuccess = (data) => ({
  type: constants.CREATE_ATTACHMENT_BID_PACKAGE_PROPOSAL_ITEM_SUCCESS,
  payload: data,
})

export const setAttachmentProjectOfferSuccess = (data) => ({
  type: constants.CREATE_ATTACHMENT_PROJECT_OFFER_SUCCESS,
  payload: data,
})

export const setAttachmentItemOfferSuccess = (data) => ({
  type: constants.CREATE_ATTACHMENT_BID_ITEM_OFFER_SUCCESS,
  payload: data,
})

export const getBidPackageLowest = (id) => ({
  type: constants.GET_BID_PACKAGE_PROPOSAL_LOWEST,
  id,
})

export const getBidPackageLowestSuccess = (data) => ({
  type: constants.GET_BID_PACKAGE_PROPOSAL_LOWEST_SUCCESS,
  payload: data,
})

export const getBidPackageMinimalEnvelope = (id) => ({
  type: constants.GET_BID_PACKAGE_PROPOSAL_MINIMAL_ENVELOPE,
  id,
})

export const getBidPackageMinimalEnvelopeSuccess = (data) => ({
  type: constants.GET_BID_PACKAGE_PROPOSAL_MINIMAL_ENVELOPE_SUCCESS,
  payload: data,
})

export const getBidPackageProposals = (id) => ({
  type: constants.GET_BID_PACKAGE_PROPOSALS,
  id,
})

export const getBidPackageProposalsSuccess = (data) => ({
  type: constants.GET_BID_PACKAGE_PROPOSALS_SUCCESS,
  payload: data,
})

export const getBidPackageAllOffers = (id, params) => ({
  type: constants.GET_BID_PACKAGE_OFFERS,
  id,
  params,
})

export const getBidPackageAllOffersSuccess = (data) => ({
  type: constants.GET_BID_PACKAGE_OFFERS_SUCCESS,
  payload: data,
})

export const getBidPackageComparative = (bidPackageId, query) => ({
  type: constants.GET_BID_PACKAGE_COMPARATIVE,
  bidPackageId,
  query,
})

export const getBidPackageComparativeSuccess = (data) => ({
  type: constants.GET_BID_PACKAGE_COMPARATIVE_SUCCESS,
  payload: data,
})

export const getBidPackageProposal = (bidPackageId, proposalId) => ({
  type: constants.GET_BID_PACKAGE_PROPOSAL,
  bidPackageId,
  proposalId,
})

export const getBidPackageProposalSuccess = (data) => ({
  type: constants.GET_BID_PACKAGE_PROPOSAL_SUCCESS,
  payload: data,
})

export const getBidPackageProposalItems = (bidPackageId, proposalId) => ({
  type: constants.GET_BID_PACKAGE_PROPOSAL_ITEMS,
  bidPackageId,
  proposalId,
})

export const getBidPackageProposalItemsSuccess = (data) => ({
  type: constants.GET_BID_PACKAGE_PROPOSAL_ITEMS_SUCCESS,
  payload: data,
})

export const resetComparatives = () => ({
  type: constants.RESET_STORE_COMPARATIVES,
})

export const awardProposal = (bidPackageId, body) => ({
  type: constants.AWARD_PROPOSAL,
  bidPackageId,
  body,
})

export const awardProposalSuccess = (data) => ({
  type: constants.AWARD_PROPOSAL_SUCCESS,
  payload: data,
})
