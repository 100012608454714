import { useFormik } from 'formik'

import { Checkbox } from '@proveoeng/uikit/dist/atoms/Checkbox'
import { GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

import {
  ActionButton,
  ActionContainerStyled,
  CategoriesList,
  CategoriesListContainer,
  FormContainer,
} from '../../styles/ProfileStyles'

export const ProfileCategories = ({ categories, selectedCategories, onSubmit }) => {
  const initialValues = {
    categories: selectedCategories
      .map((category) => category.categoryId)
      .filter((category) => category),
  }

  const { values, setFieldValue, handleSubmit } = useFormik({ initialValues, onSubmit })

  const handleChange = (categoryId, isCheck) => {
    const selectedCategories = isCheck
      ? [...values.categories, categoryId]
      : values.categories.filter((id) => id !== categoryId)
    setFieldValue('categories', selectedCategories)
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <GridItem gridArea="description" marginBottom={4}>
          <Text sizeText="display18" color="black" fontWeight="bold" marginBottom={2}>
            Categorías
          </Text>
          <Text sizeText="display14" color="gray">
            Estos datos nos servirán para poder enviarte las ofertas acorde a tu perfil y
            localización.
          </Text>
        </GridItem>
        <GridItem gridArea="fields">
          <CategoriesListContainer>
            <CategoriesList>
              {categories?.map((el) => (
                <Checkbox
                  key={el.categoryId}
                  name={el.categoryId}
                  label={el.name}
                  action={handleChange}
                  ariaLabel={el.name}
                  marginY={3}
                  defaultChecked={values.categories.includes(el.categoryId)}
                />
              ))}
            </CategoriesList>
          </CategoriesListContainer>
        </GridItem>
      </FormContainer>
      <ActionContainerStyled borderTopColor="gray3" style={{ zIndex: 2 }}>
        <ActionButton type="submit" colorType="orange" sizeButton="medium">
          Guardar
        </ActionButton>
      </ActionContainerStyled>
    </form>
  )
}
