import { useEffect, useState } from 'react'

import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Card } from '@proveoeng/uikit/dist/atoms/Card'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import Close from '@proveoeng/uikit/dist/atoms/Icons/Close'
import Search from '@proveoeng/uikit/dist/atoms/Icons/Search'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Box, Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { SelectInput } from '@proveoeng/uikit/dist/atoms/Select'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography/Typography'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'

import { provinces } from '../../../../../lib/utils/provincesList'
import { sellerSubtypes } from '../../../../../lib/utils/sellerSubtypes'
import { staffList } from '../../../../../lib/utils/staffList'
import { turnoverList } from '../../../../../lib/utils/turnoverList'
import { isEmail } from '../../../../common/functions'
import { usePagination } from '../../../../common/hooks/pagination/usePagination'
import { useHelpers } from '../../../../common/hooks/useHelpers'
import { SellerTable } from './SellerTable'

let timeOut

export const ModalInviteSeller = ({
  isOpened,
  setIsOpened,
  categories = [],
  categoryId,
  categoryName,
  projectProvince,
  handleOpenDetail,
  onSubmit,
}) => {
  const {
    data: {
      sellers: { data: sellers, meta },
    },
    getSellers,
  } = useHelpers()

  const [loading, setLoading] = useState(false)

  const [sellersSelectedId, setSellersSelectedId] = useState([])

  const {
    params: { filters, limit, page, sort },
    handleAddFilter,
    handleSort,
    handleRemoveFilter,
    handlePageChange,
    handlePerRowsChange,
    reset: resetPagination,
  } = usePagination()

  const [inviteButtonDisabled, setInviteButtonDisabled] = useState(true)

  const [reset, setReset] = useState(false)
  const [emailsInvitationToSave, setEmailsInvitationToSave] = useState([])
  const [emailsInvitation, setEmailsInvitation] = useState([])

  useEffect(() => {
    // turn off input reset
    if (reset) {
      setReset(false)
      handleRemoveFilter('name')
    }
  }, [reset])

  useEffect(() => {
    const loadData = async () => {
      setLoading(true)
      await getSellers({ filters, limit, page, sort })
      setLoading(false)
    }
    if (isOpened) loadData()
  }, [filters, limit, page, sort])

  useEffect(() => {
    if (projectProvince && isOpened)
      handleAddFilter('address_province', {
        value: projectProvince,
        operator: 'in',
      })
  }, [projectProvince, isOpened])

  useEffect(() => {
    if (categoryId && isOpened)
      handleAddFilter('categoryId', {
        value: [categoryId],
        operator: 'in',
      })
  }, [categoryId, isOpened])

  const handleReset = () => {
    setEmailsInvitation([])
    setEmailsInvitationToSave([])
    resetPagination()
    setSellersSelectedId([])
  }

  const handleSubmit = async () => {
    await onSubmit([...sellersSelectedId, ...emailsInvitation])
    // handleReset()
  }

  const handleCancel = () => {
    handleReset()
    setIsOpened(false)
  }

  const handleSelectFilter = (key, value) => {
    if (!value.length) {
      handleRemoveFilter(key)
      return
    }
    const selected = value.map((el) => el.value)
    handleAddFilter(key, {
      value: selected,
      operator: 'in',
    })
  }

  const arrayUnique = (array) => Array.from(new Set(array))

  /**
   * Split all values separated by ',' or ';'.
   * @param {string} data
   * @returns {string[]}
   */
  const dataSplitted = (data) => data.split(/[,;]+/)

  const handleSearch = (value) => {
    if (value.length < 3) {
      handleRemoveFilter('name')
      return
    }
    clearTimeout(timeOut)

    timeOut = setTimeout(() => {
      handleAddFilter('name', { value, operator: 'contains' })
    }, 1000)

    const listOfValues = dataSplitted(value)
    const emailsToSave = listOfValues.map((el) => el.trim())
    const allValidEmails = emailsToSave.every(isEmail)
    setInviteButtonDisabled(!allValidEmails || !listOfValues.length)
    setEmailsInvitationToSave(emailsToSave)
  }

  const inviteSellers = () => {
    const newEmails = emailsInvitationToSave.filter(
      (email) => !sellers.some((seller) => seller.email === email),
    )
    const alreadySaved = sellers
      .filter((seller) => emailsInvitationToSave.includes(seller.email))
      .map(({ value }) => value)

    setInviteButtonDisabled(true)
    setEmailsInvitation((prevState) => arrayUnique([...prevState, ...newEmails]))
    setSellersSelectedId((prevState) => arrayUnique([...prevState, ...alreadySaved]))
    setEmailsInvitationToSave([])
    setReset(true)
  }

  // remove invite email
  const removeInviteEmail = (email) =>
    setEmailsInvitation((prevState) => prevState.filter((el) => el !== email))

  const handleSelectSeller = (companyId, isChecked) => {
    setSellersSelectedId((prevState) => {
      if (isChecked) return arrayUnique([...prevState, companyId])
      return prevState.filter((el) => el !== companyId)
    })
  }

  const sellersRender = sellers.map((seller) => ({
    ...seller,
    isSelected: sellersSelectedId.includes(seller.companyId),
  }))

  const RenderEmailsInvitationItem = ({ email }) => (
    <Card
      id={email}
      width="100%"
      padding={4}
      marginBottom={3}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      style={{
        cursor: 'pointer',
        boxShadow: '0px 0px 3px #E4670B',
      }}
      borderColor="#E4670B">
      <Text
        sizeText="display18"
        color="black"
        fontWeight="600"
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}>
        {email}
      </Text>
      <Icon sizeIcon="display18" color="gray1" onClick={() => removeInviteEmail(email)}>
        <Close />
      </Icon>
    </Card>
  )

  return (
    <Modal
      isOpen={isOpened}
      id="ask-reminders-modal"
      onModalClose={handleCancel}
      title="Pedir presupuesto a proveedores"
      width="100%"
      maxWidth="90vw"
      minHeight="90%">
      <Modal.Content>
        <Box margin="0 auto" paddingBottom="80px">
          <Flex marginBottom={4}>
            <Box width="50%" marginRight={4}>
              <SelectInput
                isMulti
                isSearchable
                label="Provincia"
                placeholder="Selecciona una provincia"
                options={provinces.map((province) => ({ value: province, label: province }))}
                onChange={(_, value) => handleSelectFilter('address_province', value)}
                maxMenuHeight={200}
                defaultValue={[
                  {
                    label: projectProvince,
                    value: projectProvince,
                  },
                ]}
              />
            </Box>
            <Box width="50%">
              <SelectInput
                isMulti
                isSearchable
                label="Categoría"
                placeholder="Selecciona una categoría"
                options={categories}
                onChange={(_, value) => handleSelectFilter('categoryId', value)}
                maxMenuHeight={200}
                defaultValue={[
                  {
                    label: categoryName,
                    value: categoryId,
                  },
                ]}
              />
            </Box>
          </Flex>
          <Flex>
            <Box paddingRight={3} width="100%">
              <Input
                action={(_, value) => handleSearch(value)}
                type="search"
                name="searchBar"
                title=""
                placeholderMessage="Buscar por nombre o invitar proveedores"
                reset={reset}
                defaultValue=""
                iconLeft={
                  <Icon sizeIcon="display18" color="gray3">
                    <Search />
                  </Icon>
                }
              />
            </Box>
            <Box>
              <Button
                colorType="orange"
                sizeButton="medium"
                disabled={inviteButtonDisabled}
                action={inviteSellers}
                width="180px">
                Invitar
              </Button>
            </Box>
          </Flex>
          <Flex paddingTop={5} justifyContent="space-between">
            <Flex>
              <Box width="200px" marginRight={4}>
                <SelectInput
                  isMulti
                  multiStyle="default"
                  placeholder="Tipo"
                  label="Tipo"
                  options={sellerSubtypes}
                  onChange={(_, value) => handleSelectFilter('sellerSubtypes', value)}
                />
              </Box>
              <Box width="200px" marginRight={4}>
                <SelectInput
                  isMulti
                  multiStyle="default"
                  placeholder="Facturación"
                  label="Facturación"
                  options={turnoverList}
                  onChange={(_, value) => handleSelectFilter('turnover', value)}
                />
              </Box>
              <Box width="200px">
                <SelectInput
                  isMulti
                  multiStyle="default"
                  placeholder="Empleados"
                  label="Empleados"
                  options={staffList}
                  onChange={(_, value) => handleSelectFilter('staff', value)}
                />
              </Box>
            </Flex>

            <Flex justifyContent="center" alignItems="center">
              <Text sizeText="display14" color="gray2">
                {sellersSelectedId.length || 0} proveedores seleccionados
              </Text>
            </Flex>
          </Flex>

          {!!emailsInvitation.length && (
            <Box paddingTop={5}>
              <Text>Invitados por correo ({emailsInvitation.length})</Text>
              <Grid
                gridTemplateColumns="1fr 1fr 1fr"
                gridTemplateRows="1fr"
                gridColumnGap={5}
                marginTop={3}>
                {emailsInvitation.map((email, index) => (
                  <Box key={index}>
                    <RenderEmailsInvitationItem email={email} />
                  </Box>
                ))}
              </Grid>
            </Box>
          )}

          <Box paddingTop={5} minHeight="calc(80vh - 350px)">
            <SellerTable
              sellers={sellersRender}
              handleSort={handleSort}
              meta={meta}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
              onSelectedRowsChange={handleSelectSeller}
              loading={loading}
              handleOpenDetail={handleOpenDetail}
            />
          </Box>
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Grid gridTemplateColumns="1fr 1fr" gridTemplateRows="1fr" gridColumnGap={5}>
            <Button id="cancel-button" colorType="transparent" width="180px" action={handleCancel}>
              Cancelar
            </Button>
            <Button
              colorType="orange"
              width="180px"
              disabled={!(sellersSelectedId.length + emailsInvitation.length)}
              action={handleSubmit}>
              Siguiente
            </Button>
          </Grid>
        </Flex>
      </Modal.Actions>
    </Modal>
  )
}
