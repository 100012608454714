import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getParams, requestApi } from '../../../lib/request'
import { services } from '../schemes/bidPackages/config'
import { ProposalBidPackageMapper } from '../schemes/proposals/mapper'
import { useUtils } from './useUtils'

import { services as servicesHelper } from '../schemes/helpers/config'
import { services as servicesEmployees } from '../schemes/employees/config'
import { getBase64 } from '../functions/serialize'
import {
  addImage,
  employeeFails,
  getPendingOffersEmployeeSuccess,
  getProposalOffersEmployeeSuccess,
  getThumbPendingOffersSuccess,
} from '../schemes/employees'

import { OfferPendingMapper, OfferProposalMapper } from '../schemes/employees/mapper'

export const useOffer = () => {
  const dispatch = useDispatch()
  const { setError, setLoading, getSignal } = useUtils()

  const { imagespending } = useSelector((state) => state.employee.offers)

  const [bidPackageAllOffers, setBidPackageAllOffers] = useState([])

  /**
   *
   * @param {string} bidPackageId
   * @param {Param} params
   */
  const getBidPackageAllOffers = async (bidPackageId, params = {}) => {
    if (!bidPackageId) return
    setLoading(true)
    try {
      const paramsRequest = getParams(params)
      const {
        data: { data },
      } = await requestApi(services.getBidPackageAllOffers(bidPackageId, paramsRequest))
      // dispatch(
      //   getBidPackageAllOffersSuccess(data?.map((el) => ProposalBidPackageMapper.hydrate(el))),
      // )
      setBidPackageAllOffers(data?.map((el) => ProposalBidPackageMapper.hydrate(el)))
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const getThumbPendingOffer = async (bidPackageId, imageId) => {
    try {
      let thumb = ''
      if (imagespending[imageId]) {
        thumb = imagespending[imageId].thumb
      } else {
        const { data, headers } = await requestApi(servicesHelper.getThumb(imageId), {
          signal: getSignal(),
        })
        thumb = `data:${headers['content-type']};base64,${getBase64(data)}`
        dispatch(addImage(imageId, thumb, 'pending'))
      }

      dispatch(getThumbPendingOffersSuccess(bidPackageId, imageId))
    } catch (error) {
      if (error?.message === 'canceled') return
      dispatch(employeeFails(error))
      setError(error)
    }
  }

  const getThumbsPendingOffers = async (data = []) =>
    Promise.all(
      data
        .filter((el) => el?.projectImage)
        .map(async (el) => getThumbPendingOffer(el.bidPackageId, el.projectImage)),
    )

  const getPendingOffers = async () => {
    setLoading(true)
    try {
      const response = await requestApi(servicesEmployees.getPendingOffers(), {
        signal: getSignal(),
      })
      const { data, meta } = response?.data
      const res = data.map((el) => OfferPendingMapper.hydrate(el.resource))
      dispatch(
        getPendingOffersEmployeeSuccess({
          data: res,
          meta,
        }),
      )
      setLoading(false)
      await getThumbsPendingOffers(res)
      return true
    } catch (error) {
      if (error?.message === 'canceled') return null
      dispatch(employeeFails(error))
      setError(error)
      setLoading(false)
    }
    return false
  }

  const getProposalsOffers = async () => {
    setLoading(true)
    try {
      const response = await requestApi(servicesEmployees.getProposalsOffers(), {
        signal: getSignal(),
      })
      const { data, meta } = response?.data
      const res = data.map((el) => OfferProposalMapper.hydrate(el.resource))
      dispatch(
        getProposalOffersEmployeeSuccess({
          data: res,
          meta,
        }),
      )

      setLoading(false)
      // await getThumbsProposalsOffers(res);
      return true
    } catch (error) {
      if (error?.message === 'canceled') return null
      dispatch(employeeFails(error))
      setError(error)
      setLoading(false)
    }
    return false
  }

  const cancelOffer = async (bidPackageId, offerId, employeeId) => {
    setLoading(true)
    try {
      const body = {
        actionName: 'Cancel',
        actionData: {
          employeeId,
        },
      }
      await requestApi(services.actionBidPackageOffer(bidPackageId, offerId, body))
    } catch (error) {
      if (error?.message === 'canceled') return
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  return {
    data: { bidPackageAllOffers },
    getBidPackageAllOffers,
    getPendingOffers,
    getProposalsOffers,
    cancelOffer,
  }
}
