import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Box } from '@proveoeng/uikit/dist/atoms/Layout'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Uploader } from '@proveoeng/uikit/dist/atoms/Uploader'

import { LinksList } from '../../../../common/components/links/LinksList'
import { AttachmentList } from '../../../../common/components/attachmentList/Attachment'

export const CreateProjectAttachmentsTemplate = ({
  data,
  onImagesLoad,
  errorsImages,
  onDeleteFile,
  links = [],
  addLink,
  removeLink,
  inputLink,
  setInputLink,
  handleAddDescription,
  verifyUrl,
}) => (
  <>
    <Text as="span" sizeText="display32" color="black" marginBottom={3} fontWeight="bold">
      Sube las fotos o ficheros que necesites
    </Text>
    <Box>
      <Field
        label="Utiliza esta sección para compartir fotos útiles como el acceso de la obra, documentos informativos de la obra como el contrato tipo, solicitud de documentación, etc..."
        sizeText="display16"
        marginBottom={3}>
        <AttachmentList files={data} withDelete onDeleteFile={onDeleteFile} />

        <Uploader
          multiple
          name="files"
          marginBottom={3}
          errors={errorsImages}
          maxNumFiles={5}
          onImagesLoad={onImagesLoad}
          descriptionMessage="Arrastra, o haz click para subir tus documentos aquí"
          accept="all"
          padding={5}
        />
      </Field>
    </Box>
    <Box marginTop={7}>
      <LinksList
        links={links}
        inputLink={inputLink}
        setInputLink={setInputLink}
        addLink={addLink}
        handleAddDescription={handleAddDescription}
        removeLink={removeLink}
        verifyUrl={verifyUrl}
      />
    </Box>
  </>
)
