import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'

export const ModalConfirm = ({
  title = '¿Quieres continuar con esta acción?',
  subTitle = '',
  isOpen,
  setIsOpen,
  onSubmit,
  cancelButtonText = 'Cancelar',
  submitButtonText = 'Confirmar',
}) => (
  <>
    <Modal isOpen={isOpen} id="confirm-modal" width="390px" title={title}>
      <Modal.Content>
        <Box marginBottom={8}>
          <Text sizeText="display16" color="black">
            {subTitle}
          </Text>
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="end">
          <Button
            colorType="transparent"
            action={() => setIsOpen(false)}
            width="150px"
            marginRight={4}>
            {cancelButtonText}
          </Button>
          <Button colorType="orange" action={onSubmit} width="150px">
            {submitButtonText}
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  </>
)
