import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

export const Item = ({ title, subTitle = '', children }) => (
  <>
    <Flex minWidth="225px" maxWidth="225px" paddingLeft={2} flexDirection="column">
      <Text as="span" sizeText="display18" color="black" fontWeight="600" paddingBottom={2}>
        {title}
      </Text>
      {subTitle && (
        <Text as="span" sizeText="display14" color="gray1">
          {subTitle}
        </Text>
      )}
    </Flex>
    <Flex paddingLeft={6} paddingTop={1}>
      {!children || typeof children === 'string' ? (
        <Text as="span" sizeText="display14" color="black">
          {children || '-'}
        </Text>
      ) : (
        <>{children}</>
      )}
    </Flex>
  </>
)
