/* eslint-disable no-await-in-loop */
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { useHistory } from 'react-router-dom'
import { getParams, requestApi } from '../../../lib/request'
import { setManagedFilesFinished, setStoreProject } from '../schemes/projects'
import { services } from '../schemes/projects/config'
import { ProjectMapper } from '../schemes/projects/mapper'
import { useUtils } from './useUtils'

export const useProject = () => {
  const dispatch = useDispatch()
  const { setAlert, setError, setLoading, cancelRequest, getSignal, getThumb } = useUtils()
  const history = useHistory()

  const [projects, setProjects] = useState({
    data: [],
    meta: {},
  })

  const [project, setProject] = useState()
  const [bidPackages, setBidPackages] = useState([])

  // Refactored
  const getThumbAllProjects = async (data = []) =>
    Promise.all(
      data
        .filter((project) => project?.image)
        .map(async (project) => ({
          thumb: await getThumb(project?.image),
          projectId: project?.projectId,
        })),
    )

  // Refactored
  const getAllProjects = async (params = {}, waitToLoadAllImages = false) => {
    setLoading(true)
    const paramsRequest = getParams(params)
    try {
      const response = await requestApi(services.getAll(paramsRequest, { signal: getSignal() }))
      const { data, meta } = response?.data
      const res = data?.map((el) => ProjectMapper.hydrate(el.resource))
      const metaFormatted = ProjectMapper.hydrateMeta(meta)

      // TODO: apply pagination
      setProjects({ data: res, meta: metaFormatted })
      if (!waitToLoadAllImages) setLoading(false)

      const thumbs = await getThumbAllProjects(res)
      const resFormatted = res.map((el) => {
        const thumb = thumbs.find((t) => t?.projectId === el?.projectId)
        return { ...el, thumb: thumb?.thumb }
      })
      setProjects({ data: resFormatted, meta: metaFormatted })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (error?.message === 'canceled') return

      setError(error)
    }
  }

  // Refactored
  const getProject = async (id) => {
    setLoading(true)
    try {
      const response = await requestApi(services.getProject(id))
      const { data } = response?.data
      const { bidPackages, ...project } = ProjectMapper.hydrate(data)
      setProject(project)
      setBidPackages(bidPackages)
      setLoading(false)
      return project
    } catch (error) {
      setLoading(false)
      setError(error)
    }
    return null
  }

  const actionProject = async (projectId, action) => {
    try {
      let body = {}
      if (action === 'Copy') {
        body = {
          actionData: {
            projectId: uuidv4(),
          },
        }
      }
      const response = await requestApi(
        services.actionProject(projectId, {
          actionName: action,
          ...body,
        }),
      )
      if (response && response.status >= 200 && response.status < 300) {
        // TODO: compare router is '/constructora/crear-obra'
        if (action === 'Copy') history.push(`/constructora/tus-obras/${body.actionData.projectId}`)

        await getAllProjects()
      } else {
        throw response
      }
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const deleteProject = async (projectId) => {
    try {
      setLoading(true)
      const response = await requestApi(services.deleteProject(projectId))
      if (response && response.status >= 200 && response.status < 300) {
        await getAllProjects()
        setAlert('success', 'Obra eliminada correctamente.')
      } else {
        throw response
      }
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const setLinksProject = async (projectId, links = []) => {
    if (!links.length) return
    setLoading(true)
    let isUpdated = false
    for (const link of links) {
      if (!link.linkId) {
        const linkId = uuidv4()
        try {
          await requestApi(services.createLinkProject(projectId, linkId, link))
        } catch (error) {
          setAlert('error', 1, error?.message || error?.detail || 'Algo ha salido mal.')
        }
        isUpdated = true
      }
    }
    if (isUpdated) setAlert('success', 'Enlaces actualizados correctamente.')
    setLoading(false)
  }

  const deleteLinksProject = async (projectId, linkIds = []) => {
    if (!linkIds.length) return
    setLoading(true)
    for (const linkId of linkIds) {
      try {
        await requestApi(services.deleteLinkProject(projectId, linkId))
      } catch (error) {
        setError(error)
      }
    }
    setAlert('success', 'Enlaces eliminado correctamente.')
    setLoading(false)
  }

  const setFilesProject = async (projectId, files = []) => {
    if (!files.length) return
    setLoading(true)
    let isUpdated = false
    for (const file of files) {
      if (!file.fileId) {
        const fileId = uuidv4()
        try {
          await requestApi(services.createAttachmentProject(projectId, fileId, { file: file.file }))
        } catch (error) {
          setError(error)
        }
        isUpdated = true
      }
    }
    if (isUpdated) setAlert('success', 'Archivos actualizado correctamente.')
    setLoading(false)
  }

  const deleteFilesProject = async (projectId, files = []) => {
    if (!files.length) return
    setLoading(true)
    for (const { fileId } of files) {
      if (fileId) {
        try {
          await requestApi(services.deleteAttachmentProject(projectId, fileId))
        } catch (error) {
          setError(error)
        }
      }
    }
    dispatch(setManagedFilesFinished(true))
    setAlert('success', 'Archivos eliminado correctamente.')
    setLoading(false)
  }

  const exportFile = async (id, name) => {
    setLoading(true)
    try {
      const file = await requestApi(services.exportProject(id))
      const a = document.createElement('a')
      a.href = URL.createObjectURL(new Blob([file.data], { type: file.data.type }))
      a.download = name
      a.click()
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const exportProject = async ({ projectId, name }) => exportFile(projectId, `${name}-project.xls`)

  /**
   *
   * @param {array} oldBP
   * @param {array} newBP
   * @returns
   */
  const getNewBidPackageId = (oldBP, newBP) =>
    newBP.find((nbp) => !oldBP.find((obp) => nbp.bidPackageId === obp.bidPackageId)).bidPackageId

  const setPreviewBidPackages = async (projectId, file) => {
    if (!file?.length) return null
    setLoading(true)
    try {
      const {
        data: { data },
      } = await requestApi(services.previewBidPackage(projectId, { file: file[0].file }), {
        signal: getSignal(),
      })
      setLoading(false)
      return data
    } catch (error) {
      if (error?.message === 'canceled') return null

      setError(error)
      setLoading(false)
      return null
    }
  }

  const setImportBidPackages = async (projectId, file) => {
    if (!file?.length) return
    setLoading(true)
    try {
      await requestApi(services.importBidPackages(projectId, { file: file[0].file }), {
        signal: getSignal(),
      })
    } catch (error) {
      if (error?.message === 'canceled') return

      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const createProject = async (project) => {
    setLoading(true)
    try {
      const projectId = uuidv4()
      const body = ProjectMapper.dehydrate(project)
      await requestApi(services.createProject(projectId, body))
      setLoading(false)
      setAlert('success', 'Obra creada correctamente.')
      return projectId
    } catch (error) {
      setLoading(false)
      setError(error)
      throw error
    }
  }

  const updateProject = async (projectId, project) => {
    setLoading(true)
    try {
      const body = ProjectMapper.dehydrate(project)
      await requestApi(services.updateProject(projectId, body))
      setAlert('success', 'Obra actualizada correctamente.')
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError(error)
      throw error
    }
  }

  const storeProject = (data) => dispatch(setStoreProject(data))

  return {
    data: {
      project,
      projects,
      bidPackages,
    },
    getAllProjects,
    deleteProject,
    actionProject,
    getProject,
    setLinksProject,
    deleteLinksProject,
    setFilesProject,
    deleteFilesProject,
    cancelRequest,
    exportProject,
    setPreviewBidPackages,
    setImportBidPackages,
    getNewBidPackageId,
    createProject,
    updateProject,
    storeProject,
  }
}
