import { Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

export const EnvironmentalCostsProductionPhaseTotal = ({
  offers,
  totalColumns,
  tableWidthCSS,
  getTotal,
}) => (
  <>
    <Grid
      gridTemplateAreas={`"description ${totalColumns}"`}
      gridTemplateColumns={`768px repeat(${offers?.length}, 220px)`}
      style={{ width: tableWidthCSS }}>
      <GridItem
        gridArea="description"
        bg="white2"
        padding={3}
        style={{
          position: 'sticky',
          left: 0,
          zIndex: 9,
        }}>
        <Text color="gray1" overflow="hidden" fontWeight="600" sizeText="display18">
          Totales producción
        </Text>
      </GridItem>
      {offers.map((offer) => (
        <>
          <GridItem gridArea={`total-${offer.id}`} bg="white2" padding={2} paddingTop={4}>
            <Text
              textAlign="right"
              color="gray1"
              overflow="hidden"
              fontWeight="600"
              sizeText="display14">
              {getTotal(offer)}
            </Text>
          </GridItem>
        </>
      ))}
    </Grid>
  </>
)
