import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { DetailProposal } from '../components/DetailProposal'
import { ModalContact } from '../../common/components/ModalContact'
import { ModalSendMessage } from '../../../../common/components/sendMessage/ModalSendMessage'

export const DetailProposalTemplate = ({
  goBack,
  proposal,
  isModalContactOpened,
  setIsModalContactOpened,
  goToEditProposal,
  handleExportBidPackagePrices,
  sendModalIsOpen,
  setSendModalIsOpen,
  handleSendMessage,
}) => (
  <Flex>
    <Flex height="100%" width="100%" paddingY={4} flexDirection="column" margin="0 auto">
      <Text as="span" sizeText="display14" color="gray2">
        <Text
          as="span"
          sizeText="display14"
          color="blue1"
          marginBottom={7}
          style={{ cursor: 'pointer' }}
          onClick={() => goBack()}>
          {'< '} Volver
        </Text>{' '}
        | Tus propuestas /
        <Text as="span" sizeText="display14" color="black" marginLeft={2}>
          {proposal?.bidPackage?.name}
        </Text>
      </Text>
      <Flex justifyContent="space-between" alignItems="center">
        <Text
          as="h3"
          sizeText="display30"
          color="black"
          fontWeight="bold"
          marginLeft={2}
          marginTop={7}
          marginBottom={5}>
          {proposal?.bidPackage?.name}
        </Text>
        {(proposal?.state === 'Awaiting response' ||
          proposal?.state === 'Awaiting improvement') && (
          <Box width="195px">
            <Button
              id="proposal-modify"
              colorType="transparent"
              fullWidth
              action={goToEditProposal}>
              Modifica tu propuesta
            </Button>
          </Box>
        )}
      </Flex>
      {proposal?.bidPackage?.contact?.companyName && (
        <>
          <Flex justifyContent="space-between">
            <Flex flexDirection="column">
              <Flex>
                <Text
                  fontWeight="regular"
                  color="gray"
                  sizeText="display16"
                  paddingBottom={2}
                  paddingRight={2}>
                  Contratista:{' '}
                  <Text as="span" fontWeight="medium" color="black" sizeText="display16">
                    {proposal?.bidPackage?.contact?.companyName}
                  </Text>
                </Text>
                <Text
                  as="span"
                  fontWeight="medium"
                  color="blue1"
                  sizeText="display16"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setIsModalContactOpened(true)}>
                  Ver datos de contacto
                </Text>
              </Flex>
              <Text fontWeight="regular" color="gray" sizeText="display16" paddingBottom={5}>
                Obra:{' '}
                <Text as="span" fontWeight="medium" color="black" sizeText="display16">
                  {proposal?.project?.name}
                </Text>
              </Text>
            </Flex>
            <Flex flexDirection="column">
              <Text fontWeight="regular" color="gray" sizeText="display16" paddingBottom={2}>
                Fecha límite para presentar oferta:
              </Text>
              <Text
                as="span"
                fontWeight="medium"
                color="black"
                sizeText="display18"
                textAlign="right">
                {proposal?.bidPackage?.bidEndsAtFormatted?.readableReverseDate || '-'}
              </Text>
            </Flex>
          </Flex>
          <Box width="184px" marginRight={7} marginBottom={6}>
            <Button fullWidth colorType="transparent" action={() => setSendModalIsOpen(true)}>
              Enviar mensaje
            </Button>
          </Box>
        </>
      )}
      <DetailProposal
        bidPackage={proposal?.bidPackage}
        proposal={proposal}
        handleExportBidPackagePrices={handleExportBidPackagePrices}
      />

      <ModalContact
        isModalContactOpened={isModalContactOpened}
        setIsModalContactOpened={setIsModalContactOpened}
        data={proposal?.bidPackage?.contact}
      />

      <ModalSendMessage
        title={`Enviar notificación a ${proposal?.bidPackage?.contact?.companyName}`}
        subTitle={
          <Text>
            <strong>{proposal?.bidPackage?.contact?.companyName}</strong> recibirá un correo
            electrónico con el siguiente mensaje.
          </Text>
        }
        hasCancelButton={false}
        onSubmit={handleSendMessage}
        isOpened={sendModalIsOpen}
        setIsOpened={setSendModalIsOpen}
      />
    </Flex>
  </Flex>
)
