import { GuestsList } from '../components/GuestsList'

export const GuestsTemplate = ({
  bidPackage,
  guests,
  categories,
  projectProvince,
  resendInvitation,
  optionsFilter,
  filters,
  setFilters,
  inviteMoreIsOpened,
  setInviteMoreIsOpened,
  handleInviteMore,
  seller,
  handleOpenDetail,
  detailsIsOpened,
  setDetailsIsOpened,
  detailsTabOpen,
  confirmCancelIsOpened,
  setConfirmCancelIsOpened,
  handleCancelOffer,
  proposalsBySeller,
  sendModalIsOpen,
  setSendModalIsOpen,
  handleSendMessage,
  openSendMessageModal,
  messageData,
}) => (
  <>
    <GuestsList
      guests={guests}
      resendInvitation={resendInvitation}
      optionsFilter={optionsFilter}
      filters={filters}
      setFilters={setFilters}
      categories={categories}
      inviteMoreIsOpened={inviteMoreIsOpened}
      setInviteMoreIsOpened={setInviteMoreIsOpened}
      bidPackage={bidPackage}
      projectProvince={projectProvince}
      handleInviteMore={handleInviteMore}
      seller={seller}
      handleOpenDetail={handleOpenDetail}
      detailsIsOpened={detailsIsOpened}
      setDetailsIsOpened={setDetailsIsOpened}
      detailsTabOpen={detailsTabOpen}
      confirmCancelIsOpened={confirmCancelIsOpened}
      setConfirmCancelIsOpened={setConfirmCancelIsOpened}
      handleCancelOffer={handleCancelOffer}
      proposalsBySeller={proposalsBySeller}
      sendModalIsOpen={sendModalIsOpen}
      setSendModalIsOpen={setSendModalIsOpen}
      handleSendMessage={handleSendMessage}
      openSendMessageModal={openSendMessageModal}
      messageData={messageData}
    />
  </>
)
