import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { EnvironmentalCostsSection } from '../components/EnvironmentalCostsSection'
import { EnvironmentalCostsSummary } from '../components/EnvironmentalCostsSummary'

export const ComparatorEnvironmentalCostsTemplate = ({
  comparative,
  bidPackageId,
  setTableWidthCSS,
  updateImprovementRate,
  addItem,
  setItem,
  setOfferItem,
  removeItems,
  offerColumns,
}) => (
  <>
    <Flex flexDirection="column" style={{ overflow: 'auto' }} marginRight={2}>
      <EnvironmentalCostsSummary
        comparative={comparative}
        bidPackageId={bidPackageId}
        tableWidthCSS={setTableWidthCSS(768)}
        updateImprovementRate={updateImprovementRate}
        offerColumns={offerColumns}
      />

      {comparative?.sections.map((section) => (
        <EnvironmentalCostsSection
          addItem={addItem}
          setItem={setItem}
          setOfferItem={setOfferItem}
          data={section}
          offers={comparative.offers}
          defaultSection={comparative.defaultSection}
          removeItems={removeItems}
          tableWidthCSS={setTableWidthCSS(768)}
        />
      ))}
    </Flex>
  </>
)
