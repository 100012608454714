export const constants = {
  CREATE: 'CREATE',
  ADD_SECTION: 'ADD_SECTION',
  ADD_ITEM: 'ADD_ITEM',
  EDIT_SECTION: 'EDIT_SECTION',
  UPDATE_ITEM: 'UPDATE_ITEM',
  UPDATE_OFFER_ITEM: 'UPDATE_OFFER_ITEM',
  REMOVE_SECTION: 'REMOVE_SECTION',
  REMOVE_ITEMS: 'REMOVE_ITEMS',
  UPDATE_IMPROVEMENT_RATE: 'UPDATE_IMPROVEMENT_RATE',
  EXPORT: 'EXPORT',
}

export const services = {
  getComparatives: (bidpackageId, proposalsQuery) => ({
    route: `/bid-packages/${bidpackageId}/proposals/compare?${proposalsQuery}`,
    method: 'get',
  }),
  saveComparative: ({ bidPackageId, comparisonId, snapshotId, title, data }) => ({
    route: `/bid-packages/${bidPackageId}/comparisons/${comparisonId}/v/${snapshotId}`,
    method: 'put',
    body: {
      ...data,
      title,
    },
  }),
  exportComparative: (bidPackageId, comparisonId, snapshotId) => ({
    route: `/bid-packages/${bidPackageId}/integrations/comparisons/${comparisonId}/export?v=${snapshotId}`,
    method: 'get',
    responseType: 'arraybuffer',
    headers: {
      accept: '*/*',
    },
    formData: true,
  }),
  donwloadComparative: (bidPackageId, comparisonId, enviroData) => ({
    route: `/bid-packages/${bidPackageId}/comparisons/${comparisonId}/download?enviroData=${enviroData}`,
    method: 'get',
    responseType: 'arraybuffer',
    headers: {
      accept: '*/*',
    },
    formData: true,
  }),
  getComparativesList: (bidpackageId) => ({
    route: `/bid-packages/${bidpackageId}/comparisons`,
    method: 'get',
  }),
  getComparative: (bidpackageId, comparisonId, snapshotId) => {
    const queryString = snapshotId ? `?v=${snapshotId}` : ''
    return {
      route: `/bid-packages/${bidpackageId}/comparisons/${comparisonId}${queryString}`,
      method: 'get',
    }
  },
}
